import { csfAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';
import { defaultModelFeatures } from '../../modelDefaults';
import { LegacyModelConfig } from '..';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { nist2ColorTable } from 'assessments/utils/nist2ColorTable';
import terms from 'assessments/utils/Dictionary/terms_csf';

const title = 'NIST CSF 2.0 (FILIPINI)';

export const nist2FilipiniConfig: LegacyModelConfig = {
  icon,
  features: {
    ...defaultModelFeatures,
    reports: {
      mil1: false,
      full: true,
      board: true,
      boardAppendix: true,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    topOfDomain: 100,
  },
  shortTitle: title,
  factories: [
    {
      label: title,
      filterLabel: title,
      requiredLicenseOption: ModelScope.Full,
      icon,
      sortKey: title,
      modelUuid: LegacyModelIdEnum.Nist2Filipini,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.CSF_FULL,
    },
  ],
  terms,
  colorTable: nist2ColorTable,
};
