import { combineReducers } from 'redux';

import { appReducer } from './app';
import { assessmentsReducer } from './assessments';
import { benchmarksReducer } from './benchmarks';
import { bitsightReducer } from './bitsight';
import { coworkersReducer } from './coworkers';
import { subsidiaryReducer } from './subsidiaries';

import { journalReducer } from './journal';
import { levelHistoryReducer } from './levelHistory';
import { linksReducer } from './links';
import { loadedReducer } from './loaded';
import { milestonesReducer } from './milestones';
import { pendingReducer } from './pendingUpdates';
import { responsesReducer } from './responses';
import { statusHistoryReducer } from './statusHistory';
import { tagsReducer } from './tags';
import { userGroupsReducer } from './userGroups';
import { modelReducer } from './models/modelReducer';

const rootReducer = combineReducers({
  app: appReducer,
  assessments: assessmentsReducer,
  benchmarks: benchmarksReducer,
  bitsight: bitsightReducer,
  coworkers: coworkersReducer,
  journal: journalReducer,
  levelHistory: levelHistoryReducer,
  licensedModels: modelReducer,
  links: linksReducer,
  loaded: loadedReducer,
  milestones: milestonesReducer,
  pendingUpdates: pendingReducer,
  responses: responsesReducer,
  statusHistory: statusHistoryReducer,
  subsidiaries: subsidiaryReducer,
  tags: tagsReducer,
  userGroups: userGroupsReducer,
});

export interface IAssessmentState {
  app: ReturnType<typeof appReducer>;
  assessments: ReturnType<typeof assessmentsReducer>;
  benchmarks: ReturnType<typeof benchmarksReducer>;
  bitsight: ReturnType<typeof bitsightReducer>;
  coworkers: ReturnType<typeof coworkersReducer>;
  journal: ReturnType<typeof journalReducer>;
  levelHistory: ReturnType<typeof levelHistoryReducer>;
  licensedModels: ReturnType<typeof modelReducer>;
  links: ReturnType<typeof linksReducer>;
  loaded: ReturnType<typeof loadedReducer>;
  milestones: ReturnType<typeof milestonesReducer>;
  pendingUpdates: ReturnType<typeof pendingReducer>;
  responses: ReturnType<typeof responsesReducer>;
  statusHistory: ReturnType<typeof statusHistoryReducer>;
  subsidiaries: ReturnType<typeof subsidiaryReducer>;
  tags: ReturnType<typeof tagsReducer>;
  userGroups: ReturnType<typeof userGroupsReducer>;
}

export default rootReducer;
