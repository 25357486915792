import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { useApolloNetworkStatus } from '../../../setupApolloClient';
import { SpinnerComponent, DURATION } from './LoadingScreen';

const SmallSpinnerContainer = styled.div<{ show?: boolean }>`
  position: absolute;
  top: 10px;
  right: 8px;
  opacity: 0;
  visibility: hidden;
  /* this is to be higher than a very old, likely arbitrary decision
  to set the profile dropdown z-index to 9999
  */
  z-index: 10000;

  ${(props) =>
    props.show &&
    `
    animation: ${DURATION * 2}ms fadeIn;
    animation-fill-mode: forwards;
    @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  `}
`;

export const GlobalLoadingIndicator = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const status = useApolloNetworkStatus();

  useEffect(() => {
    setShowSpinner(status.numPendingQueries + status.numPendingMutations > 0);
  }, [status]);

  return (
    <SmallSpinnerContainer data-test="pending-spinny" show={showSpinner}>
      <SpinnerComponent size="1x" />
    </SmallSpinnerContainer>
  );
};
