import { csfAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';
import { defaultModelFeatures } from '../../modelDefaults';
import { LegacyModelConfig } from '..';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { nist2ColorTable } from 'assessments/utils/nist2ColorTable';

const title = 'NIST CSF 2.0 (CMMI)';

export const nist2CmmiConfig: LegacyModelConfig = {
  icon,
  features: {
    ...defaultModelFeatures,
    reports: {
      mil1: false,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  shortTitle: title,
  factories: [
    {
      label: title,
      filterLabel: title,
      icon: icon,
      sortKey: title,
      modelUuid: LegacyModelIdEnum.Nist2Cmmi,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.CMMICSF_FULL,
    },
  ],
  colorTable: nist2ColorTable,
};
