import { merge } from 'lodash';
import makeTrashable from 'trashable';

import {
  appaTheme as assessmentAppa,
  defaultTheme as assessmentDefault,
  nrecaTheme as assessmentNreca,
  riverstoneTheme as assessmentRiverstone,
  netfriendsTheme as assessmentNetfriends,
  archerTheme as assessmentArcher,
  limerockTheme as assessmentLimerock,
  guidepointTheme as assessmentGuidepoint,
  pinnacleTheme as assessmentPinnacle,
  criTheme as assessmentCri,
  nmppTheme as assessmentNmpp,
  mlsTheme as assessmentMls,
  alliantTheme as assessmentAlliant,
} from 'assessments/themes';
import {
  appaTheme as commonAppa,
  defaultTheme as commonDefault,
  nrecaTheme as commonNreca,
  riverstoneTheme as commonRiverstone,
  netfriendsTheme as commonNetfriends,
  archerTheme as commonArcher,
  limerockTheme as commonLimerock,
  guidepointTheme as commonGuidepoint,
  pinnacleTheme as commonPinnacle,
  canadaTheme as commonCanada,
  demoTheme as commonDemo,
  criTheme as commonCri,
  nmppTheme as commonNmpp,
  mlsTheme as commonMls,
  alliantTheme as commonAlliant,
} from 'common/themes';
import { defaultTheme as insuranceDefault } from 'insurance/themes';

const defaultTheme = merge(commonDefault, assessmentDefault, insuranceDefault);

const appaTheme = merge({}, defaultTheme, commonAppa, assessmentAppa);

const riverstoneTheme = merge(
  {},
  defaultTheme,
  commonRiverstone,
  assessmentRiverstone
);

const netfriendsTheme = merge(
  {},
  defaultTheme,
  commonNetfriends,
  assessmentNetfriends
);

const archerTheme = merge({}, defaultTheme, commonArcher, assessmentArcher);

const limerockTheme = merge(
  {},
  defaultTheme,
  commonLimerock,
  assessmentLimerock
);

const nrecaTheme = merge({}, defaultTheme, commonNreca, assessmentNreca);

const guidepointTheme = merge(
  {},
  defaultTheme,
  commonGuidepoint,
  assessmentGuidepoint
);

const pinnacleTheme = merge(
  {},
  defaultTheme,
  commonPinnacle,
  assessmentPinnacle
);

const canadaTheme = merge({}, defaultTheme, commonCanada, assessmentDefault);

const demoTheme = merge({}, defaultTheme, commonDemo, assessmentDefault);

const criTheme = merge({}, defaultTheme, commonCri, assessmentCri);

const nmppTheme = merge({}, defaultTheme, commonNmpp, assessmentNmpp);

const mlsTheme = merge({}, defaultTheme, commonMls, assessmentMls);

const alliantTheme = merge({}, defaultTheme, commonAlliant, assessmentAlliant);

export type IThemeInterface = typeof defaultTheme;

const isRiverstone = window.location.hostname.startsWith('riverstone');
const isAPPA = window.location.hostname.startsWith('publicpower');
const isNRECA = window.location.hostname.startsWith('nreca');
const isNetfriends = window.location.hostname.startsWith('netfriends');
const isArcher = window.location.hostname.startsWith('archer');
const isLimerock = window.location.hostname.startsWith('lrpartners');
const isGuidepoint = window.location.hostname.startsWith('guidepoint');
const isPinnacle = window.location.hostname.startsWith('pbsnow');
const isCanada = window.location.hostname.startsWith('ca-');
const isDemo = window.location.hostname.startsWith('demo-');
const isCri = window.location.hostname.startsWith('cri');
const isNMPP = window.location.hostname.startsWith('nmpp');
const isMLS = window.location.hostname.startsWith('mls');
const isAlliant = window.location.hostname.startsWith('alliant');

makeTrashable(
  isRiverstone
    ? import(
        /* webpackChunkName: "Riverstone-scss" */ './theming/riverstoneBlueprintStyles'
      )
    : isAPPA
    ? import(
        /* webpackChunkName: "Appa-scss" */ './theming/appaBlueprintStyles'
      )
    : isNetfriends
    ? import(
        /* webpackChunkName: "netfriends-scss" */ './theming/netfriendsBlueprintStyles'
      )
    : isArcher
    ? import(
        /* webpackChunkName: "archer-scss" */ './theming/archerBlueprintStyles'
      )
    : isLimerock
    ? import(
        /* webpackChunkName: "limerock-scss" */ './theming/limerockBlueprintStyles'
      )
    : isGuidepoint
    ? import(
        /* webpackChunkName: "guidepoint-scss" */ './theming/guidepointStyles'
      )
    : isPinnacle
    ? import(/* webpackChunkName: "pinnacle-scss" */ './theming/pinnacleStyles')
    : isCri
    ? import(/* webpackChunkName: "cri-scss" */ './theming/criBlueprintStyles')
    : isNMPP
    ? import(
        /* webpackChunkName: "nmpp-scss" */ './theming/nmppBlueprintStyles'
      )
    : isMLS
    ? import(/* webpackChunkName: "mls-scss" */ './theming/mlsBlueprintStyles')
    : isAlliant
    ? import(
        /* webpackChunkName: "alliant-scss" */ './theming/alliantBlueprintStyles'
      )
    : import(/* webpackChunkName: "Default-scss" */ './theming/blueprintStyles')
);

export const getTheme = (): IThemeInterface => {
  if (isRiverstone) {
    return riverstoneTheme;
  }
  if (isAPPA) {
    return appaTheme;
  }
  if (isNRECA) {
    return nrecaTheme;
  }
  if (isNetfriends) {
    return netfriendsTheme;
  }
  if (isArcher) {
    return archerTheme;
  }
  if (isLimerock) {
    return limerockTheme;
  }
  if (isGuidepoint) {
    return guidepointTheme;
  }
  if (isPinnacle) {
    return pinnacleTheme;
  }
  if (isCanada) {
    return canadaTheme;
  }
  if (isDemo) {
    return demoTheme;
  }
  if (isCri) {
    return criTheme;
  }
  if (isNMPP) {
    return nmppTheme;
  }
  if (isMLS) {
    return mlsTheme;
  }
  if (isAlliant) {
    return alliantTheme;
  }
  return defaultTheme;
};

export {
  appaTheme,
  defaultTheme,
  riverstoneTheme,
  netfriendsTheme,
  archerTheme,
  limerockTheme,
  guidepointTheme,
  pinnacleTheme,
  criTheme,
  nmppTheme,
  mlsTheme,
  alliantTheme,
};

/**
 * The below section augments the default styled-components theme with our type
 * info to allow autocomplete/typing of props.theme.XXX
 */
declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */

  export interface DefaultTheme extends IThemeInterface {}
  /* eslint-enable @typescript-eslint/no-empty-interface */
}
