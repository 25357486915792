// This file holds the theme elements that are common across all modules.
import { defaultTheme } from '../default';
import clientLogo from './Logo2.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';
import guidepointBackground from './Splash_Welcome_Background-image1.jpg';

export const GUIDEPOINT_PRIMARY = '#3333FF';
export const GUIDEPOINT_SECONDARY = '#10BFEA';
export const GUIDEPOINT_TERTIARY = '#0F0F59';
export const GUIDEPOINT_BLACK = '#0F0F27';
export const GUIDEPOINT_WHITE = '#FFFFFF';
export const guidepointTheme = {
  ...defaultTheme,
  title: 'Axio360',
  name: 'guidepoint',
  poweredBy: false,
  tocPattern: guidepointBackground,
  homepage: 'https://www.guidepointsecurity.com/',
  clientName: 'GuidePoint',
  clientNameLong: 'GuidePoint Security LLC',
  clientLogo,
  logoHeight: 35,
  authLogo: clientLogo,
  poweredByLogo: '',
  authPoweredByLogo: authPoweredByLogo,
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: guidepointBackground,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    primaryButton: GUIDEPOINT_PRIMARY,
    primaryButtonText: GUIDEPOINT_WHITE,
    authBackground: defaultTheme.colors.darkGray,
    minimalButtonTextColor: GUIDEPOINT_BLACK,
  },
};
