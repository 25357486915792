import { IModelDimension } from 'assessments/reducers/models/model';

export const defaultDimensionKey = 'default';

type DimensionKey = string;
type PracticeLevelValue = number;
type PracticeLevelCredit = number;
type PracticeLevelCreditMap = Map<PracticeLevelValue, PracticeLevelCredit>;
type DimensionCreditMap = Map<DimensionKey, PracticeLevelCreditMap>;

type DimensionMaxCreditMap = Map<DimensionKey, number>;

export const getPracticeLevelCreditMaps = (
  hasDimensions: boolean,
  dimensions: IModelDimension[]
): { creditMap: DimensionCreditMap; maxCreditMap: DimensionMaxCreditMap } => {
  const maxCreditMap: DimensionMaxCreditMap = new Map();

  const creditMap = hasDimensions
    ? dimensions.reduce<DimensionCreditMap>((currentMap, dimension) => {
        const existingSubMap = currentMap.get(dimension.key) ?? new Map();

        dimension.practiceLevels.forEach((practiceLevel) => {
          const currentDimensionMaxCredit =
            maxCreditMap.get(dimension.key) ?? Number.MIN_VALUE;
          if (practiceLevel.credit > currentDimensionMaxCredit) {
            maxCreditMap.set(dimension.key, practiceLevel.credit);
          }

          existingSubMap.set(practiceLevel.value, practiceLevel.credit);
        });

        currentMap.set(dimension.key, existingSubMap);

        return currentMap;
      }, new Map() as DimensionCreditMap)
    : dimensions[0]?.practiceLevels.reduce<DimensionCreditMap>(
        (currentMap, practiceLevel) => {
          const practiceLevelCreditMap = currentMap.get(defaultDimensionKey);

          const currentDimensionMaxCredit =
            maxCreditMap.get(defaultDimensionKey) ?? Number.MIN_VALUE;

          if (practiceLevel.credit > currentDimensionMaxCredit) {
            maxCreditMap.set(defaultDimensionKey, practiceLevel.credit);
          }

          practiceLevelCreditMap?.set(
            practiceLevel.value,
            practiceLevel.credit
          );

          return currentMap;
        },
        new Map([
          [defaultDimensionKey, new Map() as PracticeLevelCreditMap],
        ]) as DimensionCreditMap
      );

  return { creditMap, maxCreditMap };
};
