import {
  fullAssessmentIcon,
  qlAssessmentIcon,
} from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelConfig } from '..';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import terms from 'assessments/utils/Dictionary/terms_c2m2';
import wizardSchema from 'assessments/models/c2m2-wizard-schema';

export const c2m2Config: LegacyModelConfig = {
  icon: fullAssessmentIcon,
  features: {
    bitsight: true,
    targetProfiles: true,
    convertToFull: true,
    referenceAssessments: true,
    milestones: true,
    wizard: true,
    hasMilScoping: false,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: true,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: true,
      derivedCSF: true,
    },
    deprecated: {
      effectiveDate: new Date(2023, 6, 1),
      suggestedVersion: 'C2M2 v2.1',
    },
  },
  scoring: {
    useDropout: true,
    topOfDomain: 100,
  },
  shortTitle: 'C2M2 Assessment',
  factories: [
    {
      label: 'C2M2 Full Assessment',
      filterLabel: 'C2M2 Full',
      requiredLicenseOption: ModelScope.Full,
      icon: fullAssessmentIcon,
      sortKey: 'C2M2-0',
      modelUuid: LegacyModelIdEnum.C2M2,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      description:
        'The C2M2 is designed to assist organizations in identifying specific areas to strengthen their cybersecurity program, prioritize cybersecurity actions and investments, and maintain the desired level of security throughout the organization.',
      dashboardType: DashboardType.C2M2_FULL,
    },
    {
      label: 'C2M2 From Excel',
      filterLabel: 'C2M2 Full',
      requiredLicenseOption: ModelScope.Full,
      icon: fullAssessmentIcon,
      sortKey: 'C2M2-1',
      modelUuid: LegacyModelIdEnum.C2M2,
      modelScope: ModelScope.Full,
      requiresImporter: true,
      dashboardType: DashboardType.C2M2_FULL,
    },
    {
      label: 'C2M2 Foundations Assessment',
      filterLabel: 'C2M2 Foundations',
      requiredLicenseOption: ModelScope.Quicklaunch,
      icon: qlAssessmentIcon,
      sortKey: 'C2M2-2',
      modelUuid: LegacyModelIdEnum.C2M2,
      modelScope: ModelScope.Quicklaunch,
      requiresImporter: false,
      description:
        'The C2M2 Foundations assessment is consists of the foundational(MIL1) C2M2 practices.   These practices are presented in a Wizard view initially. This foundational assessment can be expanded to a full C2M2 assessment at a later time (license permitting).',
      dashboardType: DashboardType.C2M2_QL,
    },
  ],
  terms,
  wizardSchema,
};
