import { Iterable, Map as ImmutableMap, OrderedMap } from 'immutable';
import { groupBy as _groupBy, pick as _pick, sortBy as _sortBy } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import {
  areAllDimensionLevelsSet,
  areAllPracticeDimensionLevelsSet,
  createModelOrderSortFn,
} from '../utils/modelUtils';

import { levelIsUnset, notEmpty } from 'common/utils/typeGuards';
import { ITag } from 'common/databaseTypes';
import { IRootState } from '../../reducers';
import { IMilestone, IResponse, IResponseDimension } from '../databaseTypes';
import { IModelDimension, IModelState } from '../reducers/models/model';
import { getPracticeMap } from 'assessments/selectors/model';
import { score } from '../score/score';

import { unTargetedId } from '../views/AssessmentPlanning/constants';
import { scoreAndInfo } from '../views/Dashboard/DashboardMain/shared/dataProcessing';
import { getReferenceAssessmentsInfo, getSelectedPractice } from './app';
import {
  getDimensions,
  getDomains,
  getImmutableDimensions,
  getModel,
  getModelDimensionMinMaxMap,
  getPracticeCount,
  getPracticeCountMap,
  getPracticeIdToModelOrderMap,
  getHasVariableResponseLevels,
} from './model';
import {
  getAllMilestones,
  getAllResponses,
  getAllTags,
  getCoworkers,
  getResponses,
} from '.';
import { getModelScope, getSelectedAssessment } from './assessment';
import { ModelScope } from 'common/graphql/graphql-hooks';
import {
  defaultDimensionKey,
  getPracticeLevelCreditMaps,
} from 'assessments/utils/creditComparison';

const isNAResponse = (targetLevel?: number) => {
  // these are the only two values for targetLevel that are considered NA
  return targetLevel === 0 || targetLevel === -2;
};
const responseLevelForDimension = (response: IResponse, dimensionKey: string) =>
  response.dimensions?.find(
    (responseDimension) => dimensionKey === responseDimension.key
  )?.level;

export interface LevelCount {
  level: number | undefined;
  count: number;
}

export const getResponseLevelCounts = createSelector(
  [getResponses],
  (responses) =>
    responses
      .filter((res) => typeof res.level === 'number')
      .groupBy((res) => res.level)
      .sortBy((_collection, key) => key)
      .map<LevelCount>((collection, key) => ({
        level: key,
        count: collection?.size ?? 0,
      }))
);
export const getResponseDimensionLevelCounts = createSelector(
  [getImmutableDimensions, getResponses],
  (dimensions, responses) =>
    OrderedMap<string, Iterable<number | undefined, LevelCount>>(
      dimensions.map((dimension) => [
        dimension.key,
        responses
          .filter(
            (response) =>
              typeof responseLevelForDimension(response, dimension.key)
          )
          .groupBy((response) =>
            responseLevelForDimension(response, dimension.key)
          )
          .sortBy((_collection, key) => key)
          .map((collection, key) => ({
            level: key,
            count: collection?.size ?? 0,
          })),
      ])
    )
);
export interface IPlanningPracticeInfo {
  id: string;
  targetDate: string;
  scores?: ReturnType<typeof scoreAndInfo>;
  items: IPlanningPracticeItem[];
}

export interface IPlanningPracticeItem extends IResponse {
  fqn: string;
  practiceText: string;
}

export const getResponsesWithoutNA = createSelector(
  [getResponses],
  (responses) =>
    responses.filter((response) => !isNAResponse(response.targetLevel))
);

export const getTargetPlanningOutput = createSelector(
  [
    getResponsesWithoutNA,
    getPracticeMap,
    getModel,
    getPracticeIdToModelOrderMap,
    getModelScope,
    getModelDimensionMinMaxMap,
  ],
  (
    responses,
    practiceMap,
    model,
    modelOrder,
    modelScope,
    modelDimensionMinMax
  ) => {
    if (!model) {
      return [];
    }
    const currentDay = moment.utc().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const hasDimensions = !!modelDimensionMinMax.size;
    const modelOrderSortFn = createModelOrderSortFn(modelOrder, '_practice_id');

    const {
      creditMap: dimensionCreditMap,
      maxCreditMap: dimensionMaxCreditMap,
    } = getPracticeLevelCreditMaps(
      hasDimensions,
      hasDimensions
        ? model.data.dimensionSchema?.dimensions ?? []
        : ([
            {
              key: 'default',
              text: 'default',
              practiceLevels: model.data.practiceLevels,
            },
          ] as IModelDimension[])
    );

    const sortedColumnsWithItems: IPlanningPracticeInfo[] = _sortBy(
      responses
        .filter((response) => {
          if (hasDimensions) {
            return (
              response.dimensions?.some((dimension) => {
                const maxDimensionCredit =
                  dimensionMaxCreditMap.get(dimension.key) ?? Number.MIN_VALUE;

                const isLevelDefined = typeof dimension.level !== 'undefined';

                const isTargetLevelDefined =
                  typeof dimension.targetLevel !== 'undefined';

                const currentCredit =
                  typeof dimension.level !== 'undefined'
                    ? dimensionCreditMap
                        ?.get(dimension.key)
                        ?.get(dimension.level)
                    : undefined;
                const targetCredit =
                  typeof dimension.targetLevel !== 'undefined'
                    ? dimensionCreditMap
                        ?.get(dimension.key)
                        ?.get(dimension.targetLevel)
                    : undefined;

                const isCurrentCreditLessThanMaxCredit =
                  currentCredit !== maxDimensionCredit;

                const isCurentCreditLessThanTargetCredit =
                  typeof dimension.targetLevel !== 'undefined' &&
                  typeof dimension.level !== 'undefined' &&
                  typeof currentCredit !== 'undefined' &&
                  typeof targetCredit !== 'undefined' &&
                  currentCredit < targetCredit &&
                  isCurrentCreditLessThanMaxCredit;

                return (
                  isCurentCreditLessThanTargetCredit ||
                  (isTargetLevelDefined && !isLevelDefined) ||
                  (isLevelDefined &&
                    !isTargetLevelDefined &&
                    isCurrentCreditLessThanMaxCredit)
                );
              }) ?? true
            );
          } else {
            const isLevelDefined = typeof response.level !== 'undefined';
            const isTargetLevelDefined =
              typeof response.targetLevel !== 'undefined';

            const currentCredit =
              typeof response.level !== 'undefined'
                ? dimensionCreditMap
                    ?.get(defaultDimensionKey)
                    ?.get(response.level)
                : undefined;
            const targetCredit =
              typeof response.targetLevel !== 'undefined'
                ? dimensionCreditMap
                    ?.get(defaultDimensionKey)
                    ?.get(response.targetLevel)
                : undefined;

            const maxCredit =
              dimensionMaxCreditMap.get(defaultDimensionKey) ??
              Number.MIN_VALUE;

            const isCurrentCreditLessThanMaxCredit =
              currentCredit !== maxCredit;

            const isCurentCreditLessThanTargetCredit =
              typeof response.targetLevel !== 'undefined' &&
              typeof response.level !== 'undefined' &&
              typeof currentCredit !== 'undefined' &&
              typeof targetCredit !== 'undefined' &&
              currentCredit < targetCredit &&
              isCurrentCreditLessThanMaxCredit;

            return (
              isCurentCreditLessThanTargetCredit ||
              (isTargetLevelDefined && !isLevelDefined) ||
              (isLevelDefined &&
                !isTargetLevelDefined &&
                isCurrentCreditLessThanMaxCredit)
            );
          }
        })
        .groupBy((response) => {
          const hasValidTargetDate = typeof response.targetDate === 'string';
          const responseHasTargetLevel = hasDimensions
            ? response.dimensions?.some(
                (dimension) => !levelIsUnset(dimension.targetLevel)
              ) ?? false
            : !levelIsUnset(response.targetLevel);
          return hasValidTargetDate && responseHasTargetLevel
            ? moment
                .utc(response.targetDate)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toISOString()
            : null;
        })
        .reduce<IPlanningPracticeInfo[]>((accum, val, key) => {
          if (!accum) {
            return [];
          }
          const isUntargetted = typeof key !== 'string';
          const columnItems = val
            ? val
                .valueSeq()
                .map((response) => {
                  const { fqn, text } = practiceMap.get(response._practice_id, {
                    fqn: 'no FQN',
                    text: 'no text found',
                  });
                  return {
                    ...response,
                    fqn,
                    practiceText: text,
                    targetDate: isUntargetted
                      ? currentDay.toISOString()
                      : response.targetDate,
                  };
                })
                .toArray()
                .sort(modelOrderSortFn)
            : [];

          const column = isUntargetted
            ? {
                id: unTargetedId,
                targetDate: '',
                items: columnItems,
              }
            : { id: key || '', targetDate: key || '', items: columnItems };

          accum.push(column);
          return accum;
        }, []),
      'targetDate'
    );

    // guarantee empty date column since its possible everything is targeted
    if (sortedColumnsWithItems[0]?.targetDate) {
      sortedColumnsWithItems.unshift({
        id: unTargetedId,
        targetDate: '',
        items: [],
      });
    }
    // Models with dimensions must calculate a new dimensions array
    if (hasDimensions) {
      const blankDimensionObject: IResponseDimension[] = modelDimensionMinMax
        .entrySeq()
        .toArray()
        .map((dimension) => {
          return { key: dimension[0], level: 0, targetLevel: 0 };
        });
      return createColumnDataForDimensionedModel(
        sortedColumnsWithItems,
        model,
        modelScope,
        responses,
        blankDimensionObject
      );
    }
    // Default logic, no dimensions, using 'level' and 'targetLevel' keys
    return sortedColumnsWithItems.reduce<{
      columns: IPlanningPracticeInfo[];
      responseAccum: ReturnType<typeof getResponses>;
    }>(
      (accum, column) => {
        if (column.targetDate) {
          accum.responseAccum = accum.responseAccum.withMutations(
            (mutableMap) => {
              column.items.forEach((resp) => {
                const { level } = mutableMap.get(resp._practice_id, {
                  level: 0,
                });
                mutableMap.set(resp._practice_id, {
                  ...resp,
                  level: Math.max(level || 0, resp.targetLevel || 0),
                });
              });
            }
          );
        }
        accum.columns.push({
          ...column,
          scores: scoreAndInfo(
            model,
            //@ts-expect-error scoreAndInfo causes app-wide issues
            accum.responseAccum,
            modelScope,
            'level',
            null,
            null
          ),
        });
        return accum;
      },
      { columns: [], responseAccum: responses }
    ).columns;
  }
);

/**
 * Dynamically create a new dimensions array for a column.
 * We loop through dimensions and calculate new level values
 */
const createColumnDataForDimensionedModel = (
  sortedColumnsWithItems: IPlanningPracticeInfo[],
  model: IModelState,
  modelScope: ModelScope,
  responses: ImmutableMap<string, IResponse>,
  blankDimensionObject: IResponseDimension[]
) => {
  return sortedColumnsWithItems.reduce<{
    columns: IPlanningPracticeInfo[];
    responseAccum: ReturnType<typeof getResponses>;
  }>(
    (accum, column) => {
      if (column.targetDate) {
        accum.responseAccum = accum.responseAccum.withMutations(
          (mutableMap) => {
            // for each response, create a new dimensions object
            column.items.forEach((resp) => {
              // get dimensions from the accumulator if it exists,
              // or create a blank dimension array
              const { dimensions: currentDimensions } = mutableMap.get(
                resp._practice_id,
                {
                  dimensions: blankDimensionObject,
                }
              );
              mutableMap.set(resp._practice_id, {
                ...resp,
                dimensions:
                  // for each dimension, set the level as the greater
                  // of the level or target level or 0
                  currentDimensions?.map((dimension) => {
                    return {
                      key: dimension.key,
                      level: Math.max(
                        dimension.level ?? 0,
                        dimension.targetLevel ?? 0
                      ),
                      targetLevel: dimension.targetLevel ?? 0,
                    };
                  }) ?? blankDimensionObject,
              });
            });
          }
        );
      }
      accum.columns.push({
        ...column,
        scores: scoreAndInfo(
          model,
          //@ts-expect-error scoreAndInfo causes app-wide issues
          accum.responseAccum,
          modelScope,
          'level',
          null,
          null
        ),
      });
      return accum;
    },
    { columns: [], responseAccum: responses }
  ).columns;
};

/*
 * The guts of this function are pulled out so that they can be
 * tested individually.
 */
export const futureScoresFromResponses = (
  model: IModelState,
  responses: ImmutableMap<string, IResponse>,
  modelScope: ModelScope | null = null
) => {
  // Filter out just the responses with a target level set and
  // covert to an array so that we can use lodash.  Then use
  // lodash to group by target date.
  const targetedResponses = _groupBy(
    responses
      .filter((resp) => resp.targetLevel || resp.targetLevel === 0)
      .valueSeq()
      .toArray(),
    (resp) => resp.targetDate || '[No Date]'
  );
  // Take a copy of responses that will be mutated within the below .map
  // Not taking a copy result in mutating state - see bug#10929
  let activeResponses = ImmutableMap<string, IResponse>(responses.toJS());
  // We need a sorted list of keys so that we traverse the grouped
  // targets in date order.
  return Object.keys(targetedResponses)
    .sort()
    .map((theDate) => {
      const objectToMerge = targetedResponses[theDate].reduce((accum, itm) => {
        accum[itm._practice_id] = itm;
        return accum;
      }, {});
      activeResponses = activeResponses.mergeWith(
        (oldItem: IResponse, newItem: IResponse) => {
          oldItem.level = newItem.targetLevel;
          return oldItem;
        },
        objectToMerge
      );
      //@ts-expect-error Invalid Overload Call
      const theScore = score(model, activeResponses, modelScope, 'level');
      return {
        date:
          theDate === '[No Date]' ? ('[No Date]' as const) : new Date(theDate),
        currentScore: null as number | null,
        targetScore: null as number | null,
        projectedScore: theScore.score,
      };
    });
};

export const getFutureScores = createSelector(
  [getResponses, getModel, getModelScope],
  (responses, model, modelScope) => {
    if (!model) {
      return [];
    }
    return futureScoresFromResponses(model, responses, modelScope);
  }
);

export const makeGetPracticeResponse = () =>
  createSelector(
    [getResponses, (_state: IRootState, props: { id: string }) => props.id],
    (allResponses, selectedPracticeId) => allResponses.get(selectedPracticeId)
  );

interface IBaseTotal {
  levelCount: number;
  targetCount: number;
  totalCount: number;
}

export interface IObjectiveTotal extends IBaseTotal {
  id: string;
}

export interface IDomainTotal extends IBaseTotal {
  id: string;
  objectives: ImmutableMap<string, IObjectiveTotal>;
}

/** Return any responses that are not implemented */
export const getNAResponses = createSelector([getResponses], (responses) =>
  responses.filter((response) => isNAResponse(response.targetLevel))
);

export const getNAResponseIds = createSelector([getNAResponses], (responses) =>
  responses.keySeq().toArray()
);

export const getNAResponseIdsIfNecessary = createSelector(
  [getNAResponseIds, getSelectedAssessment],
  (responseIds, assessment) =>
    assessment?.hideNAPractices ?? false ? responseIds : []
);

export const getTotalsFromResponses = createSelector(
  [
    getAllResponses,
    getResponses,
    getDomains,
    getPracticeCountMap,
    getPracticeCount,
    getNAResponseIdsIfNecessary,
    getDimensions,
    getHasVariableResponseLevels,
  ],
  (
    allResponses,
    responses,
    domains,
    practiceCounts,
    totalPractices,
    nAResponseIds,
    dimensions,
    hasVariableResponseLevels
  ) => {
    if (!domains) {
      return ImmutableMap<number | ImmutableMap<string, IDomainTotal>>({});
    }
    let totalLevelCount = 0;
    let totalTargetCount = 0;
    const hasDimensions = !!dimensions.length;

    return ImmutableMap<number | ImmutableMap<string, IDomainTotal>>({
      domains: ImmutableMap(
        domains.map((domain) => {
          const nAPracticesPerDomain = nAResponseIds.filter((respId) =>
            domain?.id ? respId.includes(domain?.id) : ''
          );
          const getTotalDomainCount = () => {
            if (!nAResponseIds.length) {
              return practiceCounts?.get(domain.id)
                ? practiceCounts.get(domain.id)
                : 0;
            }
            return (
              (practiceCounts?.get(domain?.id) ?? 0) -
              nAPracticesPerDomain.length
            );
          };
          let domainLevelCount = 0;
          let domainTargetCount = 0;
          return [
            domain.id,
            {
              id: domain.id,
              totalCount: getTotalDomainCount(),
              objectives: ImmutableMap(
                domain.objectives.map((objective) => {
                  const nAPracticesPerObjective = nAResponseIds.filter(
                    (respId) =>
                      objective?.id ? respId.includes(objective?.id) : ''
                  );
                  const getTotalObjectiveCount = () => {
                    if (!nAResponseIds.length) {
                      return practiceCounts?.get(objective.id)
                        ? practiceCounts.get(objective.id)
                        : 0;
                    }
                    return (
                      (practiceCounts?.get(objective?.id) ?? 0) -
                      nAPracticesPerObjective.length
                    );
                  };
                  const counts = objective.practices.reduce(
                    (accum, practice) => {
                      const isNAPractice = !!nAResponseIds.find(
                        (respId) => respId === practice?.id
                      );
                      const response = responses.get(practice.id);

                      const disabledDimensions =
                        practice.disabledDimensions ?? [];
                      const responseLevel =
                        hasVariableResponseLevels && practice.dimensions
                          ? areAllPracticeDimensionLevelsSet(
                              practice.dimensions, // Use practice-specific dimensions
                              response,
                              'level',
                              practice.disabledDimensions ?? [] // Pass any practice-specific disabled dimensions
                            )
                            ? 1 // If all dimensions levels are set correctly, assign 1
                            : -1 // If not, assign -1 indicating an incomplete or incorrect level setting
                          : hasDimensions // Fallback to general dimensions if variable levels aren't used
                          ? areAllDimensionLevelsSet(
                              dimensions,
                              response,
                              'level',
                              disabledDimensions
                            )
                            ? 1
                            : -1
                          : response?.level ?? -1; // Default to directly using the response level if no dimensions are applicable

                      accum.level +=
                        !isNAPractice && responseLevel !== -1 ? 1 : 0;

                      const responseTargetLevel =
                        hasVariableResponseLevels && practice.dimensions
                          ? areAllDimensionLevelsSet(
                              practice.dimensions,
                              response,
                              'targetLevel',
                              practice.disabledDimensions ?? []
                            )
                            ? 1
                            : -1
                          : hasDimensions
                          ? areAllDimensionLevelsSet(
                              dimensions,
                              response,
                              'targetLevel',
                              disabledDimensions
                            )
                            ? 1
                            : -1
                          : response?.targetLevel ?? -1;

                      accum.target +=
                        !isNAPractice && responseTargetLevel !== -1 ? 1 : 0;
                      return accum;
                    },
                    { level: 0, target: 0 }
                  );
                  domainLevelCount += counts.level;
                  domainTargetCount += counts.target;
                  totalLevelCount += counts.level;
                  totalTargetCount += counts.target;
                  return [
                    objective.id,
                    {
                      id: objective.id,
                      levelCount: counts.level,
                      targetCount: counts.target,
                      totalCount: getTotalObjectiveCount(),
                    },
                  ] as [string, IObjectiveTotal];
                })
              ),
              levelCount: domainLevelCount,
              targetCount: domainTargetCount,
            },
          ] as [string, IDomainTotal];
        })
      ),
      levelCount: totalLevelCount,
      targetCount: totalTargetCount,
      totalCount: totalPractices - nAResponseIds.length,
    });
  }
);

export const getIsIncomplete = createSelector(
  [getTotalsFromResponses],
  (assessmentProgress) => {
    const totalCount = assessmentProgress.get('totalCount', 0) as number;
    const levelCount = assessmentProgress.get('levelCount', 0) as number;
    return totalCount - levelCount !== 0;
  }
);

const getResponse = (
  allResponses: ImmutableMap<string, ImmutableMap<string, IResponse>>,
  allMilestones: ImmutableMap<string, ImmutableMap<string, IMilestone>>,
  assessmentId: string,
  milestoneId: string | null | undefined,
  practiceId: string
) => {
  if (milestoneId) {
    return allMilestones.getIn(
      [assessmentId, milestoneId, 'responses', practiceId],
      {}
    ) as IResponse;
  } else {
    return allResponses.getIn([assessmentId, practiceId], {}) as IResponse;
  }
};

export const getReferenceAssessmentResponsesKeyedByPractice = createSelector(
  [
    getPracticeMap,
    getAllResponses,
    getAllMilestones,
    getReferenceAssessmentsInfo,
  ],
  (practiceMap, allResponses, allMilestones, referenceAssessmentsInfo) =>
    practiceMap.map((practice) =>
      referenceAssessmentsInfo.map((info) => {
        const response = getResponse(
          allResponses,
          allMilestones,
          info.id,
          info.milestoneId,
          practice.id
        );
        return {
          name: info.name,
          id: info.id + (info.milestoneId || '') + practice.id,
          level: response.level,
          targetLevel: response.targetLevel,
          targetDate: response.targetDate,
          isTargetProfile: info.targetProfile,
          isMilestone: !!info.milestoneId,
        };
      })
    )
);

export const makeGetPracticeReferenceAssessmentResponses = () =>
  createSelector(
    [
      getReferenceAssessmentResponsesKeyedByPractice,
      (_state: any, props: { id: string }) => props.id,
    ],
    (allReferenceAssessmentResponses, selectedPracticeId) =>
      allReferenceAssessmentResponses.get(selectedPracticeId)
  );

const lookupScopeTagName = (
  id: string,
  scopeTagMap: ImmutableMap<string, ITag>
) => {
  const scopeTagObj = scopeTagMap.get(id);
  return scopeTagObj ? scopeTagObj.name : '';
};

/**
 * Returns the responses for the selected assessment with the practices grouped by
 * assessment rather than by practice (ie all of assessment X's responses are together
 * rather than all RM.1.a's being together) along with all reference assessments
 * and replaces the owner id with the owner object and the scope tag ids with the
 * scope tag names.
 */
export const getReferenceAssessmentResponsesKeyedByAssessment = createSelector(
  [getAllResponses, getReferenceAssessmentsInfo, getCoworkers, getAllTags],
  (allResponses, assessmentsInfo, coworkers, allScopeTags) => {
    return assessmentsInfo
      .map((info) => {
        if (info.milestoneId) {
          return null; // don't include milestones in report (for now)
        }
        const scopetags = info.scopetags
          ? info.scopetags.map((x) => lookupScopeTagName(x, allScopeTags))
          : [];
        const owner = info.owner
          ? _pick(coworkers.get(info.owner), ['name', 'email'])
          : '';
        return {
          scope: info.name,
          scopetags,
          owner,
          targetProfile: info.targetProfile,
          responses: allResponses.get(info.id),
        };
      })
      .filter(notEmpty); // filtering out falsy (milestones)
  }
);

/**
 * Return the selected response if it exists, else empty object
 * @returns Response or {}
 */
export const getSelectedResponse = createSelector(
  [getResponses, getSelectedPractice],
  (responses, selectedPractice) => responses.get(selectedPractice)
);

export const getSelectedResponseLinksIds = createSelector(
  [getSelectedResponse],
  (response) => (response && response.links ? response.links : [])
);
