import { Button, FormGroup } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { Link } from 'react-router-dom';

import Logger from 'common/utils/Logger';
import { FlashMessage } from 'common/components/Dialogs/shared';
import { useUser } from 'common/utils/userContext';
import { validatePassword } from './RegisterForm';
import { AuthForm, AuthPage, LoginInput } from './shared';
import { IThemeInterface } from '../../themes';
import { withTheme } from 'styled-components/macro';

const initialFormValues = { password: '', confirmPassword: '' };

type FormState = typeof initialFormValues;

export const NewPasswordRequiredPage = (
  props: RouteComponentProps<{}, StaticContext, { email?: string }> & {
    theme: IThemeInterface;
  }
) => {
  const { forceNewPassword } = useUser();
  const [error, setError] = useState('');
  const emailInCognito = props.location.state && props.location.state.email;
  if (!emailInCognito) {
    Logger.error('emailInCognito arg not supplied');
    return <Redirect to="/" />;
  }

  const onSubmit = async (
    { password, confirmPassword }: FormState,
    { setSubmitting }: FormikHelpers<FormState>
  ) => {
    const passwordError = validatePassword(password, confirmPassword);
    if (passwordError) {
      setError(passwordError);
      setSubmitting(false);
    } else {
      setError('');
      try {
        await forceNewPassword(password);
        props.history.replace({
          pathname: '/authentication',
          search: `email=${emailInCognito}`,
        });
      } catch (err: any) {
        setError(err.message);
        setSubmitting(false);
      }
    }
  };

  return (
    <AuthPage
      welcomeText="Reset Password"
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<FormState> initialValues={initialFormValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, isValid, handleSubmit, handleChange }) => (
          <AuthForm onSubmit={handleSubmit}>
            <FormGroup>
              <LoginInput
                name="email"
                type="email"
                defaultValue={emailInCognito}
                disabled
                large
              />
            </FormGroup>
            <FormGroup>
              <LoginInput
                name="password"
                type="password"
                autoComplete="new-password"
                value={values.password}
                onChange={handleChange}
                placeholder="Password"
                disabled={isSubmitting}
                required
                large
              />
            </FormGroup>
            <FormGroup helperText="Passwords should be at least 12 characters and must match">
              <LoginInput
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                value={values.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm Password"
                disabled={isSubmitting}
                required
                large
              />
            </FormGroup>
            <Button
              text="Change Password"
              type="submit"
              disabled={isSubmitting || !isValid}
              fill
              large
            />
            {!!error && (
              <FlashMessage type="error">
                {error} {<Link to="/authentication">Return to Login.</Link>}
              </FlashMessage>
            )}
          </AuthForm>
        )}
      </Formik>
    </AuthPage>
  );
};

export const NewPasswordRequiredForm = withTheme(NewPasswordRequiredPage);
