import { IModelFeatures } from './model';
import { fullAssessmentIcon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { PracticeLevelHelpTextType } from 'common/databaseTypes';

export const defaultModelFeatures: IModelFeatures = {
  bitsight: false,
  targetProfiles: true,
  convertToFull: false,
  referenceAssessments: true,
  milestones: true,
  wizard: false,
  hasMilScoping: false,
  hasImportResponses: true,
  hasTierScoping: false,
  reports: {
    mil1: false,
    full: false,
    board: false,
    boardAppendix: false,
    comparison: false,
    derivedCSF: false,
  },
};

export const defaultAssessmentIcon = fullAssessmentIcon;

export const defaultModelFlags = {
  hasQuestionnaire: false,
  requiresAcknowledge: false,
  singletonAssessment: false,
};

export const defaultModelTabs = {
  activity: true,
  link: true,
  help: true,
  advice: true,
};

export const defaultPracticeLevelHelpTextType =
  PracticeLevelHelpTextType.PRACTICE;
