import moment from 'moment';
import { ILicense } from './databaseTypes';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';

const environmentToken = (hostname: string) =>
  (hostname || '').toLocaleLowerCase().split(/[.-]/)[0];

const rpa = { key: LegacyModelIdEnum.Rpa, options: [ModelScope.Full] };
const csf = { key: LegacyModelIdEnum.Csf, options: [ModelScope.Full] };

const c2m2v2 = {
  key: LegacyModelIdEnum.C2M2V2,
  options: [ModelScope.Full],
};

const c2m2v2_1 = {
  key: LegacyModelIdEnum.C2M2V2V1,
  options: [ModelScope.Full, ModelScope.Quicklaunch],
};

// default free/non-corp user license models
// (ideally this would live in theme)
const modelsForEnvironment = {
  default: [c2m2v2_1, c2m2v2, csf, rpa],
  publicpower: [c2m2v2, rpa],
  //sans uses V1 still (but without QL)
  sans: [{ key: LegacyModelIdEnum.C2M2, options: [ModelScope.Full] }],
  csf: [csf, rpa],
  nreca: [],
  riverstone: [],
  alliant: [],
};

const getDefaultIndividualLicensedModelsForUrl = (): Array<{
  key: string;
  options: ModelScope[];
}> => {
  return (
    modelsForEnvironment[environmentToken(window.location.hostname)] ||
    modelsForEnvironment.default
  );
};

export const getDefaultIndividualLicenseForUrl = (): ILicense => {
  // This function is only called for single users. Single users visiting the riverstone page should be treated as expired until they associate with a company
  const models = getDefaultIndividualLicensedModelsForUrl();
  const assessmentExpiryOffset = models.length === 0 ? -1 : 1;
  const license = {
    models,
    features: [],
    // single  users have an infinite license to assessments, and no access to insurance or quant
    modules: {
      // using moment for readability - vanilla equiv is `new Date(new Date().setFullYear(new Date().getFullYear() + 1))`
      assessment: moment().add(assessmentExpiryOffset, 'y').toISOString(),
    },
  };
  return license;
};

export const isLicenseValid = (expiryDate?: string) =>
  expiryDate ? new Date(expiryDate) > new Date() : false;
