import { desaturate, transparentize } from 'polished';

export const defaultTheme = {
  borderRadius: '3px',
  font: 'Lato, arial, system-ui, sans-serif',
  colors: {
    axioBlue: '#2866b5',
    charcoal: '#2c2d30',
    columnGray: 'rgba(255, 255, 255, 0.55)',
    gray: 'lightgray',
    lightGray: '#f1f1f1',
    mediumGray: '#999',
    lightAxioBlue: desaturate(0, transparentize(0.8, '#2866b5')),
  },
};
