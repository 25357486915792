import { criTheme as baseTheme, CRI_PURPLE } from 'common/themes/cri';
import defaultTheme from './default';

const LOGO_WIDTH = 96;

export default {
  ...defaultTheme,
  poweredBy: false,
  hasOwnIntroScreen: false,
  authBackground: baseTheme.backgroundImage,
  logoWidth: LOGO_WIDTH + 'px',
  hasIntroTour: false,
  colors: {
    authButtonBackground: '#e1e1e1',
    authButtonTextColor: CRI_PURPLE,
  },
};
