import { createSelector } from 'reselect';

import { getLoading } from '.';

/** Our expected state is a map.  If there are ANY properties hanging off the map, we know that we
 * should show loading
 */
export const shouldShowLoading = createSelector(
  [getLoading],
  (loading) => loading.count() > 0
);

/** Immediately show the loading spinner by setting MAJOR_LOADING in loading store state
 */
export const shouldNotDelay = createSelector([getLoading], (loading) =>
  loading.has('MAJOR_LOADING')
);

export const getResponsesLoading = createSelector([getLoading], (loading) =>
  loading.has('get_responses')
);
