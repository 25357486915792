import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { components, GroupTypeBase } from 'react-select';
import { OptionProps } from 'react-select/src/components/Option';
import { Styles } from 'react-select/src/styles';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import styled from 'styled-components/macro';

import { NumberInput } from 'common/components/AxioNumberInput';
import { resolveIconFromString, labelValueObjectBuilder } from '../../utils';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;
  & > button {
    margin-right: 1rem;
  }
  & table {
    margin-left: -2px;
  }
  && > * {
    flex: 1;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const newMarker = 'new-';

export const FancyCurrencyInput = styled(NumberInput)`
  display: flex;
  min-width: 50px;
  max-width: 100px;
  height: 34px;
  padding-left: 10px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: initial;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :focus {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 10px 0 rgba(73, 107, 125, 0.3);
  }
  ::placeholder {
    font-weight: normal;
    font-size: 0.8rem;
    font-style: italic;
  }
  &.error {
    border-color: ${(props) => props.theme.colors.errorRed};
    box-shadow: ${(props) => `0 0 8px 0 ${props.theme.colors.errorRed}`};
    color: ${(props) => props.theme.colors.errorRed};
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  & svg {
    color: ${(props) => props.theme.colors.axioBlue || 'grey'};
  }
`;

type ICoverageType = ReturnType<typeof labelValueObjectBuilder>;

export const Option = (props: OptionProps<ICoverageType, false>) => (
  <OptionContainer>
    <components.Option {...props}>
      <FontAwesomeIcon icon={resolveIconFromString(props.label)} fixedWidth />
      &nbsp;&nbsp;
      {props.label}
    </components.Option>
  </OptionContainer>
);

const SingleValueContainer = styled.div`
  & svg {
    color: ${(props) => props.theme.colors.axioBlue || 'grey'};
  }
`;

export const SingleValue = (props: SingleValueProps<ICoverageType>) => {
  const { children, data } = props;
  return (
    <SingleValueContainer>
      <FontAwesomeIcon
        icon={resolveIconFromString(data.value || '')}
        size="lg"
        fixedWidth
      />
      &nbsp;&nbsp;
      {children}
    </SingleValueContainer>
  );
};

export const selectStyle:
  | Partial<Styles<any, false, GroupTypeBase<any>>>
  | undefined = {
  valueContainer: () => ({
    fontSize: '.85rem',
    padding: '1px 8px',
  }),
  option: (_, state) => ({ color: state.isDisabled ? 'grey' : 'initial' }),
  container: () => ({
    fontFamily: 'inherit',
    minHeight: '39px',
  }),
  control: (_, state) => ({
    height: '30px',
    borderStyle: 'none',
    borderRadius: '3px',
    borderBottomRightRadius: state.selectProps.menuIsOpen ? 0 : '3px',
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? 0 : '3px',
    boxShadow:
      state.selectProps.menuIsOpen || state.isFocused
        ? '0 0 0 1px #137cbd,0 0 0 3px rgba(19,124,189,.3),inset 0 1px 1px rgba(16,22,26,.2)'
        : '0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);',
  }),
  indicatorsContainer: () => ({
    height: '100%',
    margin: 0,
  }),
  dropdownIndicator: () => ({
    boxSizing: 'content-box',
    width: '20px',
    padding: '5px',
  }),
};
