import {
  Dimension,
  DimensionAggregationMethod,
  Domain,
  ModelFieldsFragment,
  ModelPracticeLevel,
  Objective,
  Practice,
} from 'common/graphql/graphql-hooks';
import {
  IModelDimension,
  IModelDomain,
  IModelFile,
  IModelLevel,
  IModelObjective,
  IModelPractice,
  IModelPracticeLegacyFields,
} from './model';

export const modelTransformer = (
  data: ModelFieldsFragment,
  shortTitleOverride?: string
): IModelFile => {
  const title = data.title;
  const shortTitle = shortTitleOverride ?? data.title;
  const version = data.version;

  const domains = data.domains.map<IModelDomain>((domain: Domain) => {
    return {
      id: domain.legacyIdentifier,
      fqn: domain.fqn,
      name: domain.name,
      purpose: domain.description ?? undefined,
      shortTitle: domain.legacyShortName ?? domain.name,
      title: domain.legacyName ?? domain.name,

      objectives: domain.objectives.map<IModelObjective>(
        (objective: Objective) => {
          return {
            id: objective.legacyIdentifier,
            fqn: objective.fqn,
            name: objective.name,
            text: objective.description ?? undefined,
            title: objective.legacyName ?? objective.name,

            practices: objective.practices.map<IModelPractice>(
              (practice: Practice) => ({
                id: practice.legacyIdentifier,
                domain_id: domain.legacyIdentifier,
                fqn: practice.fqn,
                name: practice.name,
                objective_id: objective.legacyIdentifier,
                text: practice.text,
                weight: practice.weight,
                dimensions:
                  (practice.dimensions &&
                    practice.dimensions.map<IModelDimension>(
                      (dimension: Dimension) => {
                        return {
                          key: dimension.key,
                          text: dimension.name,
                          practiceLevels: dimension.practiceLevels.map<IModelLevel>(
                            (level: ModelPracticeLevel) => {
                              return {
                                level: level.value,
                                text: level.legacyIdentifier,
                                title: level.name,
                                value: level.value,
                                credit: level.credit,
                                description: level.description ?? '',
                              };
                            }
                          ),
                        };
                      }
                    )) ||
                  undefined,

                ...getLegacyPracticeFieldsForPractice(practice),
              })
            ),
          };
        }
      ),
    };
  });

  const dimensions: Dimension[] = data.dimensions;
  const dimensionCount = dimensions.length;
  const isModelMultiDimensional = dimensionCount > 1;

  const practiceLevels = isModelMultiDimensional
    ? []
    : dimensions.flatMap((dimension: Dimension) => {
        return dimension.practiceLevels.map<IModelLevel>(practiceLevelMapper);
      });

  const dimensionSchema = isModelMultiDimensional
    ? {
        dimensions: data.dimensions.map((dimension) => {
          return {
            key: dimension.key,
            text: dimension.name,
            practiceLevels: dimension.practiceLevels.map<IModelLevel>(
              practiceLevelMapper
            ),
          };
        }),
        aggregationMethod: getDimensionAggregationMethod(data),
      }
    : undefined;

  const decimalPlaces = data.scoring?.decimalPlaces;

  return {
    title,
    shortTitle,
    version,
    practiceLevels,
    domains,
    dimensionSchema,
    decimalPlaces,
  };
};

const getDimensionAggregationMethod = (
  model: ModelFieldsFragment | null
): 'average' | 'sum' | 'min' | 'max' => {
  if (!model?.scoring) {
    return 'average';
  }

  const { aggregationMethod } = model.scoring;

  switch (aggregationMethod) {
    case DimensionAggregationMethod.Sum:
      return 'sum';
    case DimensionAggregationMethod.Min:
      return 'min';
    case DimensionAggregationMethod.Max:
      return 'max';
    case DimensionAggregationMethod.Average:
    default:
      return 'average';
  }
};

const getLegacyPracticeFieldsForPractice = ({
  legacyFields,
}: Practice): IModelPracticeLegacyFields => {
  if (!legacyFields) return {};

  const {
    disabledDimensions,
    hints,
    implementationGroups,
    management,
    mil,
    nist800171,
    priority,
    recommendationId,
    since,
    tiers,
    title,
  } = legacyFields;

  return {
    disabledDimensions: disabledDimensions?.length
      ? disabledDimensions
      : undefined,
    hints: hints?.length ? hints : undefined,
    implementationGroups: implementationGroups?.length
      ? implementationGroups
      : undefined,
    management: management ?? undefined,
    mil: mil ?? undefined,
    nist_800_171: nist800171 ?? undefined,
    priority: priority ?? undefined,
    recommendation_id: recommendationId ?? undefined,
    since: since ?? undefined,
    tiers: tiers ?? undefined,
    title: title ?? undefined,
  };
};

const practiceLevelMapper = (level: ModelPracticeLevel): IModelLevel => ({
  credit: level.credit,
  description: level.description ?? undefined,
  text: level.legacyIdentifier,
  title: level.name,
  value: level.value,
});
