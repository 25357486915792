import { ISO27001Icon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';

export const iso27001Config: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: false,
    convertToFull: false,
    referenceAssessments: false,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  factories: [
    {
      label: 'ISO 27001 Controls',
      filterLabel: 'ISO 27001',
      requiredLicenseOption: ModelScope.Full,
      icon,
      sortKey: LegacyModelIdEnum.Iso27001,
      modelUuid: LegacyModelIdEnum.Iso27001,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.ISO27001,
    },
  ],
  shortTitle: 'ISO 27001',
};
