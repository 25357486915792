import { desaturate, transparentize } from 'polished';

import {
  PINNACLE_BLACK,
  PINNACLE_PRIMARY,
  PINNACLE_TERTIARY,
  PINNACLE_WHITE,
} from 'common/themes/pinnacle';
import pinnacleBackground from 'common/themes/pinnacle/Welcome-Screen-Pinnacle-Option2.png';
import defaultTheme from './default';
import PinnacleToc from 'common/themes/pinnacle/Module-Splash-Option-2.png';

export default {
  ...defaultTheme,
  poweredBy: false,
  poweredByBackground: true,
  tocPattern: PinnacleToc,
  authBackground: pinnacleBackground,
  colors: {
    ...defaultTheme.colors,
    headerBackground: 'transparent',
    headerText: PINNACLE_BLACK,
    menuSubtext: 'lightgray',
    nameplate: PINNACLE_BLACK,
    qlBackground: PINNACLE_BLACK,
    tocExpand: PINNACLE_TERTIARY,
    primaryButton: PINNACLE_PRIMARY,
    primaryButtonText: PINNACLE_WHITE,
    avatarColor: PINNACLE_PRIMARY,
    avatarTextColor: PINNACLE_WHITE,
    avatarNameplateColor: PINNACLE_BLACK,
    tocCollapseColor: PINNACLE_PRIMARY,
    qlAnswerHover: desaturate(0, transparentize(0.5, PINNACLE_PRIMARY)),
    qlAnswerText: PINNACLE_WHITE,
    qlAnswerBackground: PINNACLE_PRIMARY,
    taglineColor: PINNACLE_TERTIARY,
  },
};
