import { FieldFunctionOptions, FieldPolicy } from '@apollo/client';

import { typedReadField } from '../cacheUtils/helpers';
import { Company, SearchCompaniesResultFragment } from '../graphql-hooks';

export const searchCompaniesPolicy: FieldPolicy<
  SearchCompaniesResultFragment[]
> = {
  read: (existing, context) =>
    existing
      ? [...existing].sort((a, b) =>
          readCompanyName(context, a).localeCompare(readCompanyName(context, b))
        )
      : undefined,
};

const readCompanyName = (
  context: FieldFunctionOptions,
  company: SearchCompaniesResultFragment
) => typedReadField<Company, 'name'>(context, 'name', company);
