import { NMPP_GREEN } from 'common/themes/nmpp';
import defaultTheme from './default';
import authBackgroundImage from 'common/themes/nmpp/NMPP_Energy_Axio_platform_welcome_background_color.jpg';

const LOGO_WIDTH = 96;

export default {
  ...defaultTheme,
  poweredBy: true,
  poweredByBackground: true,
  hasOwnIntroScreen: false,
  authBackground: authBackgroundImage,
  logoWidth: LOGO_WIDTH + 'px',
  hasIntroTour: false,
  colors: {
    authButtonBackground: NMPP_GREEN,
    authButtonTextColor: 'white',
  },
};
