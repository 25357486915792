import { Dispatch } from 'redux';

import { IUser } from 'common/databaseTypes';
import { IRootState } from '../../reducers';
import { fetchUserCoworkers, updateItemMessage } from './persistence';

export const USER_DOMAIN = 'users';

// IUserObjectInDb matches the user in mongo e.g. employer:ObjectedId, not embedded company
interface IUserObjectInDb
  extends Pick<IUser, Exclude<keyof IUser, 'employer'>> {
  employer?: string;
}

/**
 * Updates a User item with the backend.
 * @param {object} user : The user being updated
 *
 * @param {string} argItemId : An optional string containing the item id if you
 *                      haven't provided it in the item itself.  When this is called
 *                      by the patch conflict, we no longer have the id on the object
 *                      and have to pass it in.
 *
 * @param {string} argEtag : An optional string containing the item's etag if you
 *                    haven't provided it in the item itself.  When this is called
 *                     by the patch conflict, we no longer have the id on the object
 *                     and have to pass it in.
 */
export function updateUserMessage(
  user: Partial<IUserObjectInDb>,
  argItemId?: string | null,
  argEtag?: string | null,
  afterAction?: any
) {
  return updateItemMessage(
    USER_DOMAIN,
    { ...user, new: false },
    argItemId,
    argEtag,
    {},
    afterAction,
    true,
    // You can't change your email address once it's set.
    (value, key) => key === 'email'
  );
}

export const updateUser = (user: Partial<IUserObjectInDb>) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  // If the user (which is just a patch) contains a company, it
  // means that the user was setting their potential employer
  // and we need to go ahead and grab the full employer record
  // and the coworkers after the patch succeeds.
  const afterAction = user.employer ? fetchUserCoworkers() : null;
  updateUserMessage(user, null, null, afterAction)(dispatch, getState);
};
