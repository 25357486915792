import React from 'react';
import Immutable, {
  Collection,
  fromJS,
  List,
  Map,
  OrderedMap,
} from 'immutable';

import { ModelScope } from 'assessments/databaseTypes';
import { PracticeLevelHelpTextType } from 'common/databaseTypes';
import Logger from 'common/utils/Logger';
import { notEmpty } from 'common/utils/typeGuards';
import { isDomainNode, isObjectiveNode } from '../../utils/typeGuards';
import { INavigationArgs } from '../../actions/navigation';
import { Dictionary, isEmpty, keyBy, mapValues } from 'lodash';
import { IWizardSchema } from 'assessments/qlTypes';
import { IQuestionnaireSchema } from 'assessments/questionnaireTypes';
import {
  calcMilCounts,
  calcMilCountsMap,
  calcMilDomains,
  calcMilPracticeLists,
  calcMilRange,
  calcPracticeCountsPerGrouping,
  calcTierCounts,
  calcTierCountsMap,
  calcTierDomains,
  calcTierPracticeLists,
  calcTiersArray,
} from './modelHelpers';

export interface IModelLevel {
  credit: number;
  description?: string;
  text: string;
  title: string;
  value: number;
}

export interface IModelPractice extends IModelPracticeLegacyFields {
  id: string;
  domain_id?: string;
  fqn: string;
  name: string;
  objective_id?: string;
  text: string;
  weight?: number;
  dimensions?: IModelDimension[];
}

export interface IModelPracticeLegacyFields {
  disabledDimensions?: string[];
  hints?: string[];
  implementationGroups?: number[];
  management?: boolean;
  mil?: number;
  nist_800_171?: string;
  priority?: number;
  recommendation_id?: string;
  since?: string;
  tiers?: number[];
  title?: string;
}

export interface IModelObjective {
  id: string;
  fqn: string;
  name: string;
  title: string;
  text?: string;
  practices: IModelPractice[];
}

export interface IModelDomain {
  id: string;
  fqn: string;
  name: string;
  shortTitle: string;
  purpose?: string;
  title: string;
  objectives: IModelObjective[];
}

export enum DimensionAggregationMethod {
  AVERAGE = 'average',
  MAX = 'max',
  MIN = 'min',
  SUM = 'sum',
}

export interface IModelDimensionSchemaRaw {
  dimensions: IModelDimension[];
  aggregationMethod: string;
}

export interface IModelDimensionSchema {
  dimensions: IModelDimension[];
  aggregationMethod: DimensionAggregationMethod;
  dimensionMap: Map<string, IModelDimension>;
  levelCreditMap: Map<string, Dictionary<number>>;
  minMaxLevelMap: Map<string, { min: number; max: number }>;
}

export interface IModelDimension {
  key: string;
  text: string;
  name?: string;
  practiceLevels: IModelLevel[];
}

export interface IModelFile {
  title: string;
  shortTitle: string;
  version: string;
  newVersionDates?: string[];
  practiceLevels: IModelLevel[];
  domains: IModelDomain[];
  dimensionSchema?: IModelDimensionSchemaRaw;
  decimalPlaces?: number;
}

export interface IAssessmentFactory {
  label: string;
  filterLabel: string;
  icon: React.ReactNode;
  sortKey: string;
  modelUuid: string;
  modelScope: ModelScope;
  requiredLicenseOption?: ModelScope;
  requiresImporter: boolean;
  description?: string;
  qualifier?: string;
  hasQuestionnaire?: boolean;
  dashboardType: string;
}

export type ModelTermLookupKey =
  | 'domain'
  | 'objective'
  | 'objectives'
  | 'practice'
  | 'practices'
  | string;

export type ITermsMap = Map<ModelTermLookupKey, [string, string]>;

export interface IScoringOptions {
  offset?: number; //this value is added to the score
  useScaling: boolean;
  useDropout: boolean;
  bottomOfScale: number;
  topOfScale: number;
  bottomOfDomain: number;
  topOfDomain: number;
}

export interface IModelColorTableEntry {
  selectedForeground: string;
  foreground: string;
  background: string;
}

export interface IModelFeatures {
  bitsight: boolean;
  targetProfiles: boolean;
  referenceAssessments: boolean;
  convertToFull: boolean;
  milestones: boolean;
  wizard: boolean;
  hasMilScoping: boolean;
  hasImportResponses: boolean;
  hasTierScoping: boolean;
  reports: {
    mil1: boolean;
    full: boolean;
    board: boolean;
    boardAppendix: boolean;
    comparison: boolean;
    derivedCSF: boolean;
  };
  deprecated?: {
    effectiveDate: Date;
    suggestedVersion: string;
  };
}
export interface IFilteredCountsMap {
  [key: number]: Immutable.Map<string, number>;
}

export interface IFilteredPracticeLists {
  [key: number]: List<IModelPractice> | undefined;
}

export interface IFilteredDomains {
  [key: number]: IModelDomain[];
}

export interface IModelState {
  data: Readonly<IModelFile>;
  terms: Readonly<Record<string, string>>;
  modelTermsMap: ITermsMap; //domain, objective, practice, etc
  practiceComponentMap: Map<string, React.ReactElement>;
  modelUuid: string;
  milRange: { min: number; max: number };
  milCounts: { [milLevel: string]: number | null };
  milCountsMap: IFilteredCountsMap;
  milPracticeLists: IFilteredPracticeLists;
  milDomains: IFilteredDomains;
  tierCounts: { [tier: string]: number | null };
  tierCountsMap: IFilteredCountsMap;
  tierPracticeLists: IFilteredPracticeLists;
  tierDomains: IFilteredDomains;
  practiceCount: number;
  practiceCountsByObjective: Map<string, number>;
  practiceList: List<IModelPractice>;
  practiceMap: Map<string, IModelPractice>;
  practiceIdToModelOrderMap: Map<string, number>;
  fqnToIdMap: Map<string, string>;
  managementCount?: number;
  orderedDomains: string[];
  orderedDomainsAndObjectives: string[];
  domainsMap: Map<string, IModelDomain>;
  objectiveMap: Map<string, IModelObjective>;
  features: IModelFeatures;
  scoring: IScoringOptions;
  practiceDimensionsMap?: Map<string, IModelDimension[]>;
  practiceDimensionTextMap?: Map<string, IModelDimension>;
  hasTabs: {
    activity: boolean;
    link: boolean;
    help: boolean;
    advice: boolean;
  };
  requiresAcknowledge: boolean;
  singletonAssessment: boolean;
  practiceLevelHelpTextType: PracticeLevelHelpTextType;
  licenseKey: string;
  factories: IAssessmentFactory[];
  modelColors: Map<string, IModelColorTableEntry> | {};
  wizardSchema?: IWizardSchema;
  questionnaireSchema?: IQuestionnaireSchema;
  dimensionSchema?: IModelDimensionSchema;
  hasQuestionnaire?: boolean;
  /*
   * Most models have a Domain->Objective->Practice heirarchy (three-level),
   * but some models have a Domain->Practice heirarchy (two-level).
   * This flag is used to expose which heirarchy this model uses.
   */
  isTwoLevelModel: boolean;
}

//hack to support 2 level models
export const getIsHidden = (
  node: IModelDomain | IModelObjective | IModelPractice,
  nAResponseIds: string[]
) => {
  if (!nAResponseIds.length) {
    return false;
  }
  const checkPractice = (practice: IModelPractice) =>
    nAResponseIds.includes(practice.id);
  const checkObjective = (objective: IModelObjective) =>
    hasPractices(objective) &&
    objective.practices.every((practice) => checkPractice(practice));
  const checkDomain = (domain: IModelDomain) =>
    domain.objectives.every((objective) => checkObjective(objective));

  if (isDomainNode(node)) {
    return checkDomain(node);
  }
  if (isObjectiveNode(node)) {
    return checkObjective(node);
  }
  return checkPractice(node);
};

export const hasPractices = (objective: IModelObjective) => {
  return objective.practices.length > 0;
};

export const hasMils = (model: IModelState | undefined) => {
  return !!model && model.milRange && model.milRange.max !== model.milRange.min;
};

export const hasBoardReport = (model: IModelState | undefined) => {
  return !!model && model.features.reports.board;
};

export const hasMil1Report = (model: IModelState | undefined) => {
  return !!model && model.features.reports.mil1;
};

export const hasFullReport = (model: IModelState | undefined) => {
  return !!model && model.features.reports.full;
};

export const hasComparisonReport = (model: IModelState | undefined) => {
  return !!model && model.features.reports.comparison;
};

export const hasDerivedCSFReport = (model: IModelState | undefined) => {
  return !!model && model.features.reports.derivedCSF;
};

export const supportsBitsight = (model: IModelState | undefined) => {
  return !!model && model.features.bitsight;
};

export const supportsTargetProfiles = (model: IModelState | undefined) => {
  return !!model && model.features.targetProfiles;
};

export const supportsConvertToFull = (model: IModelState | undefined) => {
  return !!model && model.features.convertToFull;
};

export const supportsToggleWizardView = (model: IModelState | undefined) => {
  return !!model && model.features.wizard;
};

export const supportsMilestones = (model: IModelState | undefined) => {
  return !!model && model.features.milestones;
};

export const supportsMilScoping = (model: IModelState | undefined) => {
  return !!model?.features.hasMilScoping;
};

export const supportsReferenceAssessments = (
  model: IModelState | undefined
) => {
  return !!model && model.features.referenceAssessments;
};

export const lookupDefinition = (state: IModelState, termUrl: string) => {
  const [dictionaryToUse, word] = decodeURI(termUrl).split('/');

  return dictionaryToUse === 'practice'
    ? lookupPracticeDefinition(state, word)
    : lookupTermDefinition(state, word);
};

export const lookupTermDefinition = (state: IModelState, term: string) => {
  if (!term) {
    return 'no term specified';
  }
  const definition = state.terms[term];
  return !definition
    ? 'No definition available.'
    : definition.startsWith('>>> ')
    ? lookupTermDefinition(state, definition.substr(4))
    : definition;
};

export const lookupPracticeDefinition = (state: IModelState, id: string) => {
  if (!id) {
    return 'no term specified';
  }

  const practice = state.practiceMap.get(id);
  return typeof practice !== 'undefined'
    ? practice.text
    : 'no definition available';
};

export const createPracticeDimensionsMap = (
  modelData
): Map<string, IModelDimension[]> => {
  return modelData.domains.reduce((acc, domain) => {
    domain.objectives.forEach((objective) => {
      objective.practices.forEach((practice) => {
        const dimensions =
          practice.dimensions && practice.dimensions.length > 0
            ? practice.dimensions
            : modelData.dimensionSchema && modelData.dimensionSchema.dimensions
            ? modelData.dimensionSchema.dimensions
            : [];
        acc = acc.set(practice.id, dimensions);
      });
    });
    return acc;
  }, Map());
};

export const createDimensionTextMap = (
  modelData
): Map<string, IModelDimension> => {
  return modelData.domains.reduce((acc, domain) => {
    domain.objectives.forEach((objective) => {
      objective.practices.forEach((practice) => {
        const dimensions =
          practice.dimensions && practice.dimensions.length > 0
            ? practice.dimensions
            : modelData.dimensionSchema && modelData.dimensionSchema.dimensions
            ? modelData.dimensionSchema.dimensions
            : [];
        dimensions.forEach((dimension) => {
          acc = acc.set(dimension.text, dimension);
        });
      });
    });
    return acc;
  }, Map<string, IModelDimension[]>());
};

export const getLevels = (state: IModelState) => state.data.practiceLevels;

export const creditForLevel = (state: IModelState, dimension = 0) => {
  const practiceLevels =
    state.data.dimensionSchema?.dimensions?.[dimension]?.practiceLevels ??
    state.data.practiceLevels;
  const practiceLevelsKeyedByValue = keyBy(practiceLevels, 'value');
  return (level: number) => {
    if (!notEmpty(level)) {
      return 0;
    }
    const practiceLevel = practiceLevelsKeyedByValue[level];
    return practiceLevel?.credit ?? 0;
  };
};

export const maxLevelCredit = (state: IModelState, dimension = 0) => {
  const practiceLevels =
    state.data.dimensionSchema?.dimensions?.[dimension]?.practiceLevels ??
    state.data.practiceLevels;
  const maxLevel = practiceLevels.reduce<IModelLevel>((prev, next) => {
    return prev.value > next.value ? prev : next;
  }, practiceLevels[dimension]);
  return maxLevel?.credit ?? 0;
};

export const getPracticeCount = (
  state: IModelState,
  domainId?: string,
  milModelValue?: number | null
) => {
  const countSource = milModelValue
    ? state.milCountsMap[milModelValue]
    : state.practiceCountsByObjective;
  if (domainId) {
    const x = countSource.get(domainId);
    if (!x && x !== 0) {
      Logger.warn(`undefined/null countsource[] for ${domainId}`);
      return 0;
    }
    return x;
  }
  return milModelValue
    ? state.milCounts[milModelValue.toString()] || 0
    : state.practiceCount;
};

export const getMilRange = (state: IModelState) => {
  return state.milRange;
};

const dataElement = (model: IModelState, milModelValue: number | null) => {
  if (milModelValue && milModelValue in model.milDomains) {
    return model.milDomains[milModelValue];
  }
  return model.data.domains;
};

const nextPrevDomainByIndex = (
  currentIndex: number,
  model: IModelState,
  milModelValue: number | null,
  direction: 1 | -1,
  nAResponseIds?: string[]
) => {
  const domains = dataElement(model, milModelValue) || [];
  const nextIndex = (ndx) => {
    const newIndex = ndx + direction;
    return newIndex >= domains.length
      ? 0
      : newIndex < 0
      ? domains.length - 1
      : newIndex;
  };
  let index = currentIndex;
  let isVisible;

  const getIsVisibleForNewDomain = (newIndex: number) => {
    const newDomainFQN = domains[newIndex].fqn;
    const practicesInNewDomain = model.practiceList.filter(
      (practice) => practice?.id.includes(newDomainFQN) ?? false
    );
    const domainContainsNonHiddenPractices = practicesInNewDomain.some(
      (practice) => !nAResponseIds?.includes(practice?.id ?? '')
    );
    return !nAResponseIds || domainContainsNonHiddenPractices;
  };

  do {
    index = nextIndex(index);
    isVisible = getIsVisibleForNewDomain(index);
  } while (!isVisible && index !== currentIndex);

  return domains[index];
};

const nextPrevObjective = (
  practiceId: string,
  model: IModelState,
  milModelValue: number | null,
  direction: 1 | -1,
  nAResponseIds?: string[]
): [string, string] => {
  const practiceMap = model.practiceMap;
  const practice = practiceMap.get(practiceId);
  if (!practice) {
    Logger.error(
      `Could not find key '${practiceId}' in practiceMap for model: '${model.modelUuid}'`
    );
    return ['', ''];
  }

  const domainId = practice.domain_id;
  const objectiveId = practice.objective_id;

  const domains = dataElement(model, milModelValue) || [];

  const domainIndex = domains.findIndex((d) => d.id === domainId);
  if (domainIndex === -1) {
    Logger.error(
      `Could not find domain with id: '${domainId}' in model: '${model.modelUuid}'`
    );
    return ['', ''];
  }

  const domain = domains[domainIndex];

  const objectiveIndex = domain.objectives.findIndex(
    (objective) => objective.id === objectiveId
  );
  if (objectiveIndex === -1) {
    Logger.error(
      `Could not find objective with id: '${objectiveId}' in model: '${model.modelUuid}'`
    );
    return ['', ''];
  }

  const nextIndex = (newNdx: [number, number]): [number, number] => {
    const [argDomainIndex, argObjectiveIndex] = newNdx;
    const newDomain = domains[argDomainIndex];
    const newObjectiveIndex = argObjectiveIndex + direction;
    if (
      newObjectiveIndex >= newDomain.objectives.length ||
      newObjectiveIndex < 0
    ) {
      const bumpedDomain = nextPrevDomainByIndex(
        argDomainIndex,
        model,
        milModelValue,
        direction,
        nAResponseIds
      );
      const newDomainIndex = domains.findIndex(
        (d) => d.name === bumpedDomain.name
      );
      const newObjIndex =
        direction > 0 ? 0 : bumpedDomain.objectives.length - 1;

      return [newDomainIndex, newObjIndex];
    }
    return [argDomainIndex, newObjectiveIndex];
  };
  let newIndexes: [number, number] = [domainIndex, objectiveIndex];
  let isVisible;
  let obj;
  do {
    newIndexes = nextIndex(newIndexes);
    obj = domains[newIndexes[0]].objectives[newIndexes[1]];
    isVisible =
      !nAResponseIds ||
      obj?.practices?.some(
        (objectivePractice) => !nAResponseIds.includes(objectivePractice.id)
      );
  } while (
    !isVisible &&
    (newIndexes[0] !== domainIndex || newIndexes[1] !== objectiveIndex)
  );

  return [obj.id, obj.fqn];
};

export const fqnFromId = (model: IModelState, id: string) => {
  return model.practiceMap.get(id, {
    fqn: id,
    name: '',
    text: '',
  } as IModelPractice).fqn;
};

export const nextPrevPractice = (
  practiceId: string,
  model: IModelState,
  milModelValue: number | null, //better to pass filter fn?
  direction: 1 | -1,
  nAResponseIds?: string[] //not really a model thing - filter fn!
): [string, string] => {
  const getPracticeList = (modelArg, milValue) => {
    if (milValue === 1 && milValue in modelArg.milPracticeLists) {
      return modelArg.milPracticeLists[milValue];
    } else {
      return modelArg.practiceList;
    }
  };
  const list = getPracticeList(model, milModelValue);

  const indexOfCurrentPractice = (list as List<
    Pick<IModelPractice, 'id'>
  >).findIndex((p) => p?.id === practiceId);

  if (indexOfCurrentPractice === -1) {
    return [practiceId, fqnFromId(model, practiceId)];
  }

  const getNextIndex = (currentIndex: number) => {
    //advance
    const newIndex = currentIndex + direction;
    //if off the end ...
    if (newIndex === list.size) {
      return 0;
    }
    //if off the beginning ...
    if (newIndex < 0) {
      return list.size - 1;
    }

    return newIndex;
  };

  let index = indexOfCurrentPractice;
  let name: string;
  do {
    index = getNextIndex(index);
    name = list.getIn([index, 'id'], 'Unknown');
  } while (nAResponseIds?.includes(name) && index !== indexOfCurrentPractice);

  return [name, list.getIn([index, 'fqn'])];
};

export const fullPracticeId = (
  partialId: string,
  model: IModelState,
  milModelValue: number | null = null
) => {
  if (!partialId.startsWith('.')) {
    Logger.error(
      `Unexpected ID format for id ${partialId}.  All IDs should start with dot.`
    );
    return '.' + partialId;
  }

  const practice = getPracticeFromEntityIdentifier(
    partialId,
    model,
    milModelValue
  );

  return practice ? practice.id : partialId;
};

export const getPracticeIdFromAssessmentDetailUrlHash = (
  hashValue: string,
  model: IModelState
): string | undefined => {
  const fqnFromHash = hashValue.slice(1);

  if (!fqnFromHash) return undefined;

  const practice = getPracticeFromEntityFQN(fqnFromHash, model);

  return practice?.id;
};

export const getPracticeFromEntityFQN = (
  fqn: string,
  model: IModelState,
  milModelValue: number | null = null
): IModelPractice | undefined => {
  const id = model.fqnToIdMap.get(fqn);

  if (!id) return undefined;

  return getPracticeFromEntityIdentifier(id, model, milModelValue);
};

const isPracticeApplicableToMil = (practice: IModelPractice, mil: number) =>
  typeof practice.mil === 'number' ? mil >= practice.mil : true;

export const getPracticeFromEntityIdentifier = (
  id: string,
  model: IModelState,
  milModelValue: number | null = null
): IModelPractice | undefined => {
  const { practice, objective, domain } = getEntityFromIdentifier(id, model);

  if (practice) return practice;

  if (objective) {
    if (!milModelValue) return objective.practices[0];

    return objective.practices.find((p) =>
      isPracticeApplicableToMil(p, milModelValue)
    );
  }

  if (domain) {
    if (!milModelValue) return domain.objectives[0].practices[0];

    return domain.objectives.reduce<IModelPractice | undefined>(
      (foundPractice, domainObjective) =>
        foundPractice
          ? foundPractice
          : domainObjective.practices.find((p) =>
              isPracticeApplicableToMil(p, milModelValue)
            ),
      undefined
    );
  }

  return undefined;
};

export const getEntityFromIdentifier = (id: string, model: IModelState) => ({
  practice: model.practiceMap.get(id),
  objective: model.objectiveMap.get(id),
  domain: model.domainsMap.get(id),
});

export const getFirstPracticeId = (
  model: IModelState,
  milModelValue: number | null
) => {
  const domains = dataElement(model, milModelValue) || [];
  const domain = domains[0];
  const objective = domain.objectives[0];
  const practice = objective.practices[0];
  return practice.id;
};

export const navDomain = (props: INavigationArgs): [string, string] => {
  const currentIndex = getCurrentIndex(props);

  const newDomain = nextPrevDomainByIndex(
    currentIndex,
    props.model,
    props.milModelValue,
    props.direction,
    props.nAResponseIds
  );

  return [newDomain.id, newDomain.fqn];
};

const getCurrentIndex = (props: INavigationArgs) => {
  const practice = getPracticeFromEntityIdentifier(
    props.selectedPracticeId,
    props.model
  );
  const domains = dataElement(props.model, props.milModelValue) || [];
  return domains.findIndex((domain) =>
    domain.id.endsWith(practice?.domain_id ?? props.selectedPracticeId)
  );
};

export const nextObjective = (props: INavigationArgs) => {
  return nextPrevObjective(
    props.selectedPracticeId,
    props.model,
    props.milModelValue,
    1,
    props.nAResponseIds
  );
};

export const prevObjective = (props: INavigationArgs) => {
  return nextPrevObjective(
    props.selectedPracticeId,
    props.model,
    props.milModelValue,
    -1,
    props.nAResponseIds
  );
};

export const nextPractice = (
  practiceId: string,
  model: IModelState,
  milModelValue: number | null,
  nAResponseIds?: string[]
) => {
  return nextPrevPractice(practiceId, model, milModelValue, 1, nAResponseIds);
};

export const prevPractice = (
  practiceId: string,
  model: IModelState,
  milModelValue: number | null,
  nAResponseIds?: string[]
) => {
  return nextPrevPractice(practiceId, model, milModelValue, -1, nAResponseIds);
};

/**
 * Here are the functions that support created the reduced
 * versions of the various models we support.
 */
export const makeOrderedDomains = (data: IModelFile) => {
  return data.domains.map((d) => d.id);
};

export const makeOrderedDomainsAndObjectives = (data: IModelFile) => {
  return data.domains.reduce(
    (accum, d) => accum.concat(d.objectives.map((o) => o.id)),
    [] as string[]
  );
};

export const createFQNToIDMap = (data: IModelFile) => {
  return Map<string, string>(
    data.domains.reduce((domainAccum, d) => {
      d.objectives.reduce((objectiveAccum, o) => {
        o.practices.reduce((practiceAccum, p) => {
          practiceAccum.push([p.fqn, p.id]);
          return practiceAccum;
        }, objectiveAccum);
        objectiveAccum.push([o.fqn, o.id]);
        return objectiveAccum;
      }, domainAccum);
      domainAccum.push([d.fqn, d.id]);
      return domainAccum;
    }, [] as Array<[string, string]>)
  );
};

interface InitArgs {
  modelUuid: string;
  practiceLevelHelpTextType: PracticeLevelHelpTextType;
  data: IModelFile;
  terms: Record<string, string>;
  practiceComponentMap: {
    [PracticeId: string]: React.ReactElement;
  };
  orderedDomains: string[];
  orderedDomainsAndObjectives: string[];
  scoring: IScoringOptions;
  modelTermsMap: ITermsMap;
  hasTabs: {
    activity: boolean;
    link: boolean;
    help: boolean;
    advice: boolean;
  };
  requiresAcknowledge: boolean;
  features: IModelFeatures;
  singletonAssessment: boolean;
  licenseKey: string;
  factories: IAssessmentFactory[];
  modelColors: Map<string, IModelColorTableEntry> | {};
  wizardSchema?: IWizardSchema;
  questionnaireSchema?: IQuestionnaireSchema;
  dimensionSchema?: IModelDimensionSchema;
  hasQuestionnaire?: boolean;
}

export interface FilterDomainPracticesArgs {
  domains: any;
  filterFn: (IModelPractice) => boolean;
  removeChildlessObjectives?: boolean;
  removeChildlessDomains?: boolean;
}

export function filterDomainPractices({
  domains,
  filterFn,
  removeChildlessObjectives = true,
  removeChildlessDomains = true,
}: FilterDomainPracticesArgs): any[] {
  if (!domains) {
    return domains;
  }
  return domains.reduce((filteredDomains, d) => {
    const objectives = d.get('objectives').reduce((filteredObjectives, o) => {
      const practices = o.get('practices').reduce((filteredPractices, p) => {
        if (!filterFn(p)) {
          return filteredPractices;
        }
        return filteredPractices.concat({
          ...p.toJS(),
        });
      }, []);
      if (isEmpty(practices) && removeChildlessObjectives) {
        return filteredObjectives;
      }
      return filteredObjectives.concat({
        ...o.toJS(),
        practices,
      });
    }, []);
    if (isEmpty(objectives) && removeChildlessDomains) {
      return filteredDomains;
    }
    return filteredDomains.concat({
      ...d.toJS(),
      objectives,
    });
  }, []);
}

export function filterModel(
  model: Immutable.Map<keyof IModelFile, any>,
  filterFn: (IModelPractice) => boolean,
  removeChildlessObjectives = true
) {
  const domains = model.get('domains');
  return filterDomainPractices({
    domains,
    filterFn,
    removeChildlessObjectives,
  });
}

function calcOrderedPracticeList(
  model0: Immutable.Map<keyof IModelFile, any>,
  filterFn: null | ((practice: any) => boolean) = null
) {
  // Create just a flat, sequential list of practices for navigating from one to the next
  return model0
    .get('domains')
    .map((x) =>
      x.get('objectives').map((y) => {
        const practices = filterFn
          ? y.get('practices').filter(filterFn)
          : y.get('practices');

        return practices.map((z) => ({
          ...z.toJS(),
          id: z.get('id'),
          name: z.get('name'),
          fqn: z.get('fqn'),
          objective_id: y.get('id'),
          domain_id: x.get('id'),
        }));
      })
    )
    .flatten() as IModelState['practiceList'];
}

function calcManagementCount(
  practiceMap: Immutable.OrderedMap<string, IModelPractice>
) {
  return (
    practiceMap?.reduce<number>(
      (accum: any, item) => (item?.management ? accum + 1 : accum),
      0
    ) ?? 0
  );
}

function calcPracticeIdToModelOrderMap(
  practiceList: Immutable.List<IModelPractice>
) {
  const practiceIndexPairsList = practiceList.map(
    (practice, i) => [practice?.id ?? '', i] as [string, number]
  );
  return Map<string, number>(practiceIndexPairsList);
}

function calcPracticeMap(practiceList: Immutable.List<IModelPractice>) {
  const practiceKeyValueList = practiceList
    .map((x) => [x?.id ?? '', x] as [string, IModelPractice])
    .flatten() as Collection.Keyed<string, IModelPractice>;

  return OrderedMap<string, IModelPractice>(practiceKeyValueList);
}

const hasVariableResponse = (data) => {
  return data.domains.some((domain) =>
    domain.objectives.some((objective) =>
      objective.practices.some(
        (practice) => practice?.dimensions && practice.dimensions.length > 0
      )
    )
  );
};

export function init({
  data,
  dimensionSchema,
  factories,
  features,
  hasQuestionnaire,
  hasTabs,
  licenseKey,
  modelColors,
  modelTermsMap,
  modelUuid,
  orderedDomains,
  orderedDomainsAndObjectives,
  practiceComponentMap,
  practiceLevelHelpTextType,
  questionnaireSchema,
  requiresAcknowledge,
  scoring,
  singletonAssessment,
  terms,
  wizardSchema,
}: InitArgs): {
  managementCount: number;
  practiceCountsByObjective: Immutable.Map<string, number>;
  singletonAssessment: boolean;
  data: Readonly<IModelFile>;
  domainsMap: Immutable.Map<string, IModelDomain>;
  objectiveMap: Immutable.Map<string, IModelObjective>;
  milPracticeLists: any;
  tierPracticeLists: any;
  milRange: { min: number; max: number };
  practiceDimensionsMap?: Map<string, IModelDimension[]>;
  practiceDimensionTextMap?: Map<string, IModelDimension>;
  practiceMap: Immutable.OrderedMap<string, IModelPractice>;
  practiceComponentMap: Immutable.Map<string, React.ReactElement>;
  orderedDomains: string[];
  factories: IAssessmentFactory[];
  practiceList: Immutable.List<IModelPractice>;
  features: IModelFeatures;
  practiceLevelHelpTextType: PracticeLevelHelpTextType;
  terms: Readonly<Record<string, string>>;
  orderedDomainsAndObjectives: string[];
  fqnToIdMap: Immutable.Map<string, string>;
  scoring: IScoringOptions;
  requiresAcknowledge: boolean;
  modelTermsMap: Immutable.Map<string, [string, string]>;
  modelUuid: string;
  practiceIdToModelOrderMap: Immutable.Map<string, number>;
  licenseKey: string;
  hasTabs: { activity: boolean; link: boolean; help: boolean; advice: boolean };
  milCounts: {};
  practiceCount: number;
  modelColors: Immutable.Map<string, IModelColorTableEntry> | {};
  milDomains: any;
  milCountsMap: any; // List<Immutable.Map<string, number>>
  tierDomains: any;
  tierCounts: {};
  tierCountsMap: any;
  wizardSchema?: IWizardSchema;
  questionnaireSchema?: IQuestionnaireSchema;
  dimensionSchema?: IModelDimensionSchema;
  hasQuestionnaire?: boolean;
  isTwoLevelModel: boolean;
} {
  const model0 = fromJS(data) as Map<keyof IModelFile, any>;
  const fqnToIdMap = createFQNToIDMap(data);
  const practiceList = calcOrderedPracticeList(model0);
  const practiceIdToModelOrderMap = calcPracticeIdToModelOrderMap(practiceList);
  const practiceMap = calcPracticeMap(practiceList);
  const {
    totalCount,
    groupedCounts: practiceCountsByObjective,
  } = calcPracticeCountsPerGrouping(data);
  const managementCount = calcManagementCount(practiceMap);

  const practiceDimensionsMap: Map<
    string,
    IModelDimension[]
  > = hasVariableResponse(data) ? createPracticeDimensionsMap(data) : Map();

  const practiceDimensionTextMap: Map<
    string,
    IModelDimension
  > = hasVariableResponse(data) ? createDimensionTextMap(data) : Map();

  const milRange = calcMilRange(practiceMap);
  const milRangeMax = milRange.max > 0 ? milRange.max : 1;
  const milArray = Array.from(Array(milRangeMax).keys()).map((m) => m + 1);
  const milCounts = calcMilCounts(practiceMap, milRange);
  const milCountsMap = calcMilCountsMap(data, milArray);
  const milDomains = calcMilDomains(model0.get('domains'), milArray);
  const milPracticeLists = calcMilPracticeLists(model0, milArray);

  const tierArray = Object.keys(calcTiersArray(practiceMap)).map((t) =>
    parseInt(t)
  );
  const tierDomains = calcTierDomains(model0.get('domains'), tierArray);

  const tierPracticeLists = calcTierPracticeLists(model0, tierArray);
  const tierCounts = calcTierCounts(practiceMap, tierArray);
  const tierCountsMap = calcTierCountsMap(data, tierArray);

  const isTwoLevelModel = data.domains.every((d) => d.objectives.length === 1);

  const objectiveMap = Map<string, IModelObjective>(
    data.domains
      .map((d) => d.objectives)
      .flat()
      .map((o) => [o.id, o] as [string, IModelObjective])
  );

  return {
    // state2
    data: Object.freeze(data),
    terms: Object.freeze(terms),
    practiceList,
    practiceIdToModelOrderMap,
    milPracticeLists,
    tierPracticeLists,
    practiceMap,
    practiceComponentMap: Map<string, React.ReactElement>(
      practiceComponentMap as any
    ),
    modelUuid,
    milRange,
    practiceCount: totalCount,
    practiceCountsByObjective,
    practiceDimensionsMap,
    practiceDimensionTextMap,
    practiceLevelHelpTextType,
    milCounts,
    milCountsMap,
    milDomains,
    tierCounts,
    tierCountsMap,
    tierDomains,
    managementCount,
    orderedDomains,
    orderedDomainsAndObjectives,
    fqnToIdMap,
    modelTermsMap,
    domainsMap: Map<string, IModelDomain>(
      data.domains.map((d) => [d.id, d] as [string, IModelDomain])
    ),
    objectiveMap,
    scoring,
    hasTabs,
    requiresAcknowledge,
    features,
    singletonAssessment,
    licenseKey,
    factories,
    modelColors,
    wizardSchema,
    questionnaireSchema,
    dimensionSchema,
    hasQuestionnaire,
    isTwoLevelModel,
  };
}

export const mapLevelCredit = (practicelevels: IModelLevel[]) =>
  mapValues(keyBy(practicelevels, 'value'), 'credit');

const unwrapDimensionAggregationMethod = (
  rawMethod: string
): DimensionAggregationMethod => {
  switch (rawMethod) {
    case DimensionAggregationMethod.MAX:
      return DimensionAggregationMethod.MAX;
    case DimensionAggregationMethod.MIN:
      return DimensionAggregationMethod.MIN;
    case DimensionAggregationMethod.SUM:
      return DimensionAggregationMethod.SUM;
    case DimensionAggregationMethod.AVERAGE:
    default:
      return DimensionAggregationMethod.AVERAGE;
  }
};

export const generateDimensionSchema = (
  rawDimensionSchema?: IModelDimensionSchemaRaw
): IModelDimensionSchema | undefined => {
  if (!rawDimensionSchema) return undefined;

  const dimensions = rawDimensionSchema.dimensions;
  const dimensionMap: Map<string, IModelDimension> = Map(
    dimensions.map((dimension) => [dimension.key, dimension])
  );
  const levelCreditMap: Map<string, Dictionary<number>> = Map(
    dimensions.map((dimension) => [
      dimension.key,
      mapLevelCredit(dimension.practiceLevels),
    ])
  );
  const minMaxLevelMap: Map<string, { min: number; max: number }> = Map(
    dimensions.map((dimension) => [
      dimension.key,
      dimension.practiceLevels.reduce(
        (accum, practiceLevel) => {
          if (practiceLevel.value < accum.min) {
            accum.min = practiceLevel.value;
          }
          if (practiceLevel.value > accum.max) {
            accum.max = practiceLevel.value;
          }
          return accum;
        },
        { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER }
      ),
    ])
  );

  const aggregationMethod = unwrapDimensionAggregationMethod(
    rawDimensionSchema.aggregationMethod
  );

  return {
    ...rawDimensionSchema,
    aggregationMethod,
    dimensionMap,
    levelCreditMap,
    minMaxLevelMap,
  };
};
