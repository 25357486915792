import { Map } from 'immutable';
import { Reducer } from 'redux';

import { ITag } from 'common/databaseTypes';

type ITagState = Map<string, ITag>;

const initialState = Map<ITag>({});

function convertToMap(items: ITag[]) {
  return items.reduce((map, item) => map.set(item._id, item), Map<ITag>({}));
}

export const tagsReducer: Reducer<ITagState> = (
  state = initialState,
  action = { type: 'Fake' }
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return initialState;
    }
    case 'get_resource/tags':
      return convertToMap(action.response.result._items || []);
    default:
      break;
  }
  return state;
};
