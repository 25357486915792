// This file imports all of the fonts we use from font-awesome.
// Having a single file that imports them once means that they are
// only ever used once.  Once we move to webpack 4, we can remove
// this file.

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBitcoin, faBtc } from '@fortawesome/free-brands-svg-icons';

import {
  faAnalytics,
  faAngleDoubleLeft as faAngleDoubleLeftLight,
  faAngleDoubleRight as faAngleDoubleRightLight,
  faBallot,
  faBoxesAlt,
  faBrain as faBrainLight,
  faBriefcase,
  faBuilding as faBuildingLight,
  faBullseye,
  faCalendarAlt as faCalendarAltLight,
  faCalendarExclamation,
  faChartBar as faChartBarLight,
  faChartLine as faChartLineLight,
  faCheck as faCheckLight,
  faCircle as faCircleLight,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faCog,
  faColumns,
  faComments as faCommentsLight,
  faCrosshairs,
  faCrown,
  faDirections,
  faExclamationSquare,
  faFileAlt,
  faFile,
  faFileEdit,
  faFilePdf,
  faFileSearch,
  faFileSignature,
  faFileSpreadsheet as faFileSpreadsheetLight,
  faFileUpload as faFileUploadLight,
  faFilter,
  faFlag as faFlagLight,
  faFrown as faFrownLight,
  faGlobe as faGlobeLight,
  faGlobeAmericas,
  faGrinBeam as faGrinBeamLight,
  faGripVertical as faGripVerticalLight,
  faHeadSideBrain,
  faHistory as faHistoryLight,
  faHouseDamage,
  faInfoCircle,
  faLayerGroup,
  faLightbulb as faLightbulbLight,
  faMap as faMapLight,
  faMeh as faMehLight,
  faMoneyBillWaveAlt,
  faMoneyBillAlt as faMoneyBillAltLight,
  faOutdent,
  faPaperPlane,
  faPencilAlt as faPencilAltLight,
  faPercent,
  faPoll,
  faPlus as faPlusLight,
  faQuestionCircle as faQuestionCircleLight,
  faRepeat,
  faRuler as faRulerLight,
  faSearch as faSearchLight,
  faShieldCheck as faShieldCheckLight,
  faSlidersH as faSlidersHLight,
  faSmile as faSmileLight,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortDown as faSortDownLight,
  faSortNumericDown,
  faSortNumericUp,
  faSortUp as faSortUpLight,
  faSquirrel,
  faStar as faStarLight,
  faStopwatch as faStopwatchLight,
  faStoreSlash,
  faTable as faTableLight,
  faThumbsDown as faThumbsDownLight,
  faThumbsUp as faThumbsUpLight,
  faThumbtack,
  faTimes as faTimesLight,
  faTrash as faTrashLight,
  faTrafficLightGo,
  faUsdSquare as faUsdSquareLight,
  faUsers as faUsersLight,
  faUndo as faUndoLight,
  faWallet,
  faBadgeCheck,
} from '@fortawesome/pro-light-svg-icons';

import {
  faAmbulance,
  faBolt as faBoltRegular,
  faBullhorn,
  faCalendarAlt,
  faCheck as faCheckRegular,
  faCircle as faCircleRegular,
  faCity,
  faClawMarks,
  faClock,
  faComment,
  faCommentPlus,
  faClone,
  faCrutches,
  faEdit,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileContract,
  faFileDownload,
  faFlag as faFlagRegular,
  faGlasses,
  faHistory as faHistoryRegular,
  faLink,
  faLock,
  faLockOpen,
  faLongArrowUp,
  faMapMarkerAlt,
  faMoneyBillWave,
  faPaperclip,
  faPencilAlt as faPencilAltRegular,
  faPersonCarry,
  faSave,
  faSearch,
  faShieldAlt,
  faSpinnerThird,
  faTachometer,
  faTags as faTagsRegular,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
  faUndo,
  faUnlink,
  faUserPlus as faUserPlusRegular,
  faUsersClass,
  faUserSlash,
  faUserTimes,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faBars,
  faBan,
  faBell,
  faBolt as faBoltSolid,
  faBomb,
  faBuilding,
  faCalculator,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartBar as faChartBarSolid,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCircle as faCircleSolid,
  faClipboardList as faClipboardListSolid,
  faCogs,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopeOpenDollar,
  faExclamation,
  faExclamationCircle,
  faExternalLinkAlt,
  faExclamationTriangle as faExclamationTriangleSolid,
  faFileAlt as faFileAltSolid,
  faFire,
  faFlag,
  faGripVertical,
  faHandPointRight,
  faHandshake,
  faHistory as faHistorySolid,
  faLaptopCode,
  faLightbulbDollar,
  faMicrochip,
  faMinus,
  faMoneyBill,
  faNetworkWired,
  faPencilAlt as faPencilAltSolid,
  faPhoneSlash,
  faPlus,
  faPlusCircle,
  faPollH,
  faQuestion,
  faQuestionCircle,
  faScannerKeyboard,
  faServer,
  faShieldCheck,
  faSignInAlt,
  faSitemap,
  faSort as faSortSolid,
  faSortDown as faSortDownSolid,
  faSortUp as faSortUpSolid,
  faSpinner,
  faSquare,
  faStar as faStarSolid,
  faTags,
  faThLarge,
  faThList,
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash as faTrashSolid,
  faTrashAlt as faTrashAltSolid,
  faUmbrella,
  faUsdSquare,
  faUser,
  faUserCircle,
  faUserEdit,
  faUserPlus,
  faUserMinus,
  faUsers,
  faUserSecret,
  faUserTie,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faBullseyeArrow as faBullseyeArrowDuo,
  faClawMarks as faClawMarksDuo,
  faFunction as faFunctionDuo,
  faLambda as faLambdaDuo,
  faShield as faShieldDuo,
  faLock as faLockDuo,
  faUserLock,
  faFillDrip,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faAmbulance,
  faAnalytics,
  faAngleDoubleLeft,
  faAngleDoubleLeftLight,
  faAngleDoubleRight,
  faAngleDoubleRightLight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faBallot,
  faBan,
  faBars,
  faBell,
  faBitcoin,
  faBoltRegular,
  faBoltSolid,
  faBomb,
  faBoxesAlt,
  faBrainLight,
  faBriefcase,
  faBtc,
  faBuilding,
  faBuildingLight,
  faBullhorn,
  faBullseye,
  faBullseyeArrowDuo,
  faCalculator,
  faCalendarAlt,
  faCalendarAltLight,
  faCalendarExclamation,
  faCar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartBarLight,
  faChartBarSolid,
  faChartLine,
  faChartLineLight,
  faCheck,
  faCheckCircle,
  faCheckLight,
  faCheckRegular,
  faCircleLight,
  faCircleRegular,
  faCircleSolid,
  faCity,
  faClawMarks,
  faClawMarksDuo,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faClipboardListSolid,
  faClock,
  faCog,
  faCogs,
  faColumns,
  faComment,
  faCommentsLight,
  faCommentPlus,
  faClone,
  faCrosshairs,
  faCrown,
  faCrutches,
  faDirections,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopeOpenDollar,
  faExclamation,
  faExclamationCircle,
  faExclamationSquare,
  faExclamationTriangle,
  faExclamationTriangleSolid,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileEdit,
  faFileAltSolid,
  faFileContract,
  faFileDownload,
  faFilePdf,
  faFileSearch,
  faFileSignature,
  faFileSpreadsheetLight,
  faFileUploadLight,
  faFilter,
  faFire,
  faFlag,
  faFlagLight,
  faFlagRegular,
  faFrownLight,
  faFunctionDuo,
  faGlasses,
  faGlobeAmericas,
  faGlobeLight,
  faGrinBeamLight,
  faGripVertical,
  faGripVerticalLight,
  faHandPointRight,
  faHandshake,
  faHeadSideBrain,
  faHistoryLight,
  faHistoryRegular,
  faHistorySolid,
  faHouseDamage,
  faInfoCircle,
  faLambdaDuo,
  faLaptopCode,
  faLayerGroup,
  faLightbulbDollar,
  faLightbulbLight,
  faLink,
  faLock,
  faLockDuo,
  faLockOpen,
  faLongArrowUp,
  faMapLight,
  faMapMarkerAlt,
  faMehLight,
  faMicrochip,
  faMinus,
  faMoneyBill,
  faMoneyBillAltLight,
  faMoneyBillWave,
  faMoneyBillWaveAlt,
  faNetworkWired,
  faOutdent,
  faFillDrip,
  faPaperclip,
  faPaperPlane,
  faPencilAltLight,
  faPencilAltRegular,
  faPencilAltSolid,
  faPercent,
  faPersonCarry,
  faPhoneSlash,
  faPlus,
  faPlusCircle,
  faPlusLight,
  faPoll,
  faPollH,
  faQuestion,
  faQuestionCircle,
  faQuestionCircleLight,
  faRepeat,
  faRulerLight,
  faSave,
  faScannerKeyboard,
  faSearch,
  faSearchLight,
  faServer,
  faShieldAlt,
  faShieldDuo,
  faShieldCheck,
  faShieldCheckLight,
  faSignInAlt,
  faSitemap,
  faSlidersHLight,
  faSmileLight,
  faSort,
  faSortSolid,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortDownLight,
  faSortDownSolid,
  faSortNumericDown,
  faSortNumericUp,
  faSortUpLight,
  faSortUpSolid,
  faSpinner,
  faSpinnerThird,
  faSquare,
  faSquirrel,
  faStarLight,
  faStarSolid,
  faStopwatchLight,
  faStoreSlash,
  faTableLight,
  faTachometer,
  faTags,
  faTagsRegular,
  faThLarge,
  faThList,
  faThumbsDownLight,
  faThumbsDownSolid,
  faThumbsUpLight,
  faThumbsUpSolid,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTimesLight,
  faToggleOff,
  faToggleOn,
  faTools,
  faTrashAlt,
  faTrashAltSolid,
  faTrashLight,
  faTrafficLightGo,
  faTrashSolid,
  faUmbrella,
  faUndo,
  faUndoLight,
  faUnlink,
  faUsdSquare,
  faUsdSquareLight,
  faUser,
  faUserLock,
  faUserCircle,
  faUserEdit,
  faUserPlus,
  faUserPlusRegular,
  faUserMinus,
  faUsers,
  faUsersClass,
  faUsersLight,
  faUserSecret,
  faUserSlash,
  faUserTie,
  faUserTimes,
  faWallet,
  faBadgeCheck
);
