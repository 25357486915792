import { Tooltip } from '@blueprintjs/core';
import Bowser from 'bowser';
import Cookies from 'js-cookie';
import React from 'react';
import styled from 'styled-components/macro';

import { AxioModal } from './Dialogs/ModalDialogAxio';

const COOKIE_NAME = 'browser_warning';
const COOKIE_VALUE = 'dismissed';
const MIN_VERSIONS = {
  Chrome: 76, // 	2019-07-30
  'Microsoft Edge': 79, // 2020-01-15
  Firefox: 68, // 2019-07-09
  Safari: 11.1, // March 29, 2018	Bundled with macOS High Sierra 10.13.4.[67]
};

const BrowserSpan = styled.span`
  text-decoration: underline;
  color: gray;
`;

interface IState {
  show: boolean;
}

export default class BrowserWarning extends React.PureComponent<{}, IState> {
  state = { show: false };

  componentDidMount() {
    if (!this.supportedBrowser() && !this.alreadyDismissed()) {
      this.setState({ show: true });
    }
  }

  supportedBrowser = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.satisfies({
      // or in general
      chrome: `>=${MIN_VERSIONS['Chrome']}`,
      chromium: `>=${MIN_VERSIONS['Chrome']}`,
      edge: `>=${MIN_VERSIONS['Microsoft Edge']}`,
      firefox: `>=${MIN_VERSIONS['Firefox']}`,
      safari: `>=${MIN_VERSIONS['Safari']}`,
    });
  };

  alreadyDismissed = () => {
    return Cookies.get(COOKIE_NAME) === COOKIE_VALUE;
  };

  confirm = () => {
    Cookies.set(COOKIE_NAME, 'dismissed', { expires: 7 });
    this.close();
  };

  close = () => {
    this.setState({ show: false });
  };

  suggestion = () => {
    const browserInfo = Bowser.getParser(
      window.navigator.userAgent
    ).getBrowser();
    if (
      browserInfo.name ===
      ('Chrome' || 'Chromium' || 'Microsoft Edge' || 'Firefox' || 'Safari')
    ) {
      return this.upgradeMessage(browserInfo.name, browserInfo.version);
    }
    return this.unsupportedBrowserMessage();
  };

  upgradeMessage = (name: string, version?: string) => {
    return (
      <span>
        You appear to be running {name} {version}, but we only support version{' '}
        {MIN_VERSIONS[name]} and above. Please upgrade to the latest version of{' '}
        {name} or switch to one of our other
        {this.supportedBrowsers()} for full support.
      </span>
    );
  };

  supportedBrowsers = () => {
    return (
      <Tooltip
        position="bottom"
        interactionKind="hover"
        boundary="viewport"
        content={
          <>
            <strong>Google Chrome</strong>, version {MIN_VERSIONS['Chrome']} or
            higher
            <br />
            <strong>Microsoft Edge</strong>, version{' '}
            {MIN_VERSIONS['Microsoft Edge']} or higher
            <br />
            <strong>Mozilla Firefox</strong>, version {MIN_VERSIONS['Firefox']}{' '}
            or higher
            <br />
            <strong>Apple Safari</strong>, version {MIN_VERSIONS['Safari']} or
            higher
          </>
        }
      >
        <BrowserSpan>{' supported browsers'}</BrowserSpan>
      </Tooltip>
    );
  };

  unsupportedBrowserMessage = () => {
    return (
      <>
        <span>
          We suggest you download{' '}
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Chrome
          </a>{' '}
        </span>
        <span>or another of our </span>
        <span>{this.supportedBrowsers()}</span>
        <span> for the best experience.</span>
      </>
    );
  };

  render() {
    if (!this.state.show) {
      return null;
    }
    const bodyStyle = { maxWidth: '30em', cursor: 'default' };
    return (
      <AxioModal
        isOpen
        modalTitle="Unsupported Browser"
        textPrimaryButton="I understand"
        hideSecondaryButton
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onOK={this.confirm}
        onCancel={this.close}
        bodyStyle={bodyStyle}
      >
        <p>Your browser is not officially supported. {this.suggestion()}</p>
        <p>
          If you are unable to do so due to device restrictions or understand
          the risks and wish to continue using the application anyway, please
          confirm below.
        </p>
      </AxioModal>
    );
  }
}
