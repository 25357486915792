import { Button, Callout, FormGroup } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withTheme } from 'styled-components/macro';
import { v4 as guid } from 'uuid';

import { getSsoStatus, useUser } from 'common/utils/userContext';
import { IThemeInterface } from 'themes';
import {
  AuthForm,
  AuthPage,
  BottomLink,
  BottomLinks,
  LoginInput,
} from './shared';
import { ssoError } from 'common/constants';

const REQUIREMENTS = {
  length: 12,
};

const UserLambdaValidationException = 'UserLambdaValidationException';

export const validatePassword = (
  newPassword: string,
  confirmPassword: string
) => {
  if (newPassword !== confirmPassword) {
    return "Passwords don't match.";
  }
  if (newPassword.length < REQUIREMENTS.length) {
    return `Password must be at least ${REQUIREMENTS.length} characters long`;
  }
  return '';
};

export const RegisterPage = (
  props: RouteComponentProps<{}> & {
    theme: IThemeInterface;
  }
) => {
  const searchParams = new URLSearchParams(props.location.search);
  const emailFromSearch = searchParams.get('email') ?? '';
  const moduleFromSearch = searchParams.get('module') ?? '';
  const itemFromSearch = searchParams.get('item') ?? '';
  const initialFormValues = {
    email: emailFromSearch,
    password: '',
    confirmPassword: '',
  };

  const { register } = useUser();
  const [error, setError] = useState('');

  const onSubmit = async (
    { email, password, confirmPassword }: typeof initialFormValues,
    { setSubmitting }: FormikHelpers<typeof initialFormValues>
  ) => {
    //Check if their company has SSO
    const hasSso = await getSsoStatus(email);
    //if yes, then they should log in using SSO
    if (hasSso) {
      setSubmitting(false);
      setError(ssoError);
    }
    //if no, then they can continue registering as normal
    else {
      const passwordError = validatePassword(password, confirmPassword);
      if (passwordError) {
        setError(passwordError);
        setSubmitting(false);
      } else {
        setError('');
        try {
          const result = await register(email, password);
          if (result.user) {
            props.history.replace({
              pathname: '/authentication/verify',
              state: {
                username: result.generatedUsername,
                email,
                from: `/${moduleFromSearch}${itemFromSearch}`,
              },
            });
            return;
          }
          setError('Unknown error. Please retry.');
          setSubmitting(false);
        } catch (err: any) {
          if (err?.code === UserLambdaValidationException) {
            props.history.replace({
              pathname: '/authentication/verify',
              state: {
                username: guid(),
                email,
                from: `/${moduleFromSearch}${itemFromSearch}`,
              },
            });
            return;
          } else {
            setError(err.message);
            setSubmitting(false);
          }
        }
      }
    }
  };

  return (
    <AuthPage
      welcomeText="Register"
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<typeof initialFormValues>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, isValid, handleSubmit, handleChange }) => (
          <AuthForm onSubmit={handleSubmit} autoComplete="off" role="form">
            <FormGroup>
              <LoginInput
                name="email"
                type="email"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Email address"
                data-test="Email Input"
                disabled={isSubmitting}
                required
                large
              />
            </FormGroup>
            <FormGroup>
              <LoginInput
                name="password"
                type="password"
                autoComplete="new-password"
                value={values.password}
                onChange={handleChange}
                placeholder="Password"
                disabled={isSubmitting}
                required
                large
              />
            </FormGroup>
            <FormGroup helperText="Passwords should be at least 12 characters and must match">
              <LoginInput
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                value={values.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm Password"
                disabled={isSubmitting}
                required
                large
              />
            </FormGroup>
            {!!error && (
              <FormGroup>
                <Callout intent="danger">{error}</Callout>
              </FormGroup>
            )}
            {!!error && error === ssoError ? (
              <BottomLinks smallMargins>
                <BottomLink isWhite={true} to={'/authentication/sso'}>
                  Sign in with SSO
                </BottomLink>
              </BottomLinks>
            ) : (
              ''
            )}
            <Button
              text={isSubmitting ? 'Loading' : 'Register'}
              type="submit"
              disabled={isSubmitting || !isValid}
              fill
              large
            />
            <BottomLinks smallMargins={true}>
              <BottomLink
                isWhite={true}
                to={props.theme.eulaLink}
                target="_blank"
              >
                Terms of Service
              </BottomLink>
              <BottomLink isWhite={true} to="/">
                Return to login page
              </BottomLink>
            </BottomLinks>
          </AuthForm>
        )}
      </Formik>
    </AuthPage>
  );
};

export const RegisterForm = withTheme(RegisterPage);
