import { List, Map } from 'immutable';
import { groupBy, isEmpty } from 'lodash';
import { Reducer } from 'redux';

import Logger from 'common/utils/Logger';
import { networkErrorCodes } from 'common/actions/persistence';
import { ID_FIELD } from 'common/constants';
import { notEmpty } from 'common/utils/typeGuards';
import { ASSESSMENT_ID_FIELD, PRACTICE_ID_FIELD } from '../constants';
import { IResponse } from '../databaseTypes';
import {
  normalizePractice,
  normalizePractices,
} from '../utils/normalizePractices';
import { IModelDimension, IModelPractice } from './models/model';
import {
  NoteFieldsFragment,
  UpdateShouldAddHalfLevelCreditMutation,
} from 'common/graphql/graphql-hooks';

export type AssessmentId = string;
export type FQN = string;
export type PracticeId = string;
type IResponsesState = Map<AssessmentId, Map<PracticeId, IResponse>>;

interface IResponseNestedArrays {
  dimensions?: IModelDimension[];
  notes?: NoteFieldsFragment[];
}

const initialState: IResponsesState = Map({});

export const responsesReducer: Reducer<IResponsesState> = (
  state = initialState,
  action = { type: 'fake' }
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT':
      return initialState;

    case 'item_deleted/responses': {
      const assessments = groupBy(
        action.response.result._items,
        (item) => item[ASSESSMENT_ID_FIELD]
      );

      return state.withMutations((m) => {
        Object.keys(assessments).forEach((key) => {
          m.set(
            key,
            m
              .get(key, Map<IResponse>({}))
              .deleteAll(assessments[key].map((i) => i._practice_id))
          );
        });
      });
    }

    case 'item_replaced/responses': {
      const assessments = groupBy(
        action.response.result._items,
        (item) => item[ASSESSMENT_ID_FIELD]
      );
      // Since it's a replace, we're not merging, we're replacing.
      return state.withMutations((m) => {
        Object.keys(assessments).forEach((key) => {
          m.update(key, Map({}), () =>
            Map<string, IResponse>(
              normalizePractices(assessments[key]).responses
            )
          );
        });
      });
    }

    case 'REHYDRATE': {
      const assessmentId: AssessmentId = action[ASSESSMENT_ID_FIELD];
      const items =
        action.payload && action.payload.pendingUpdates
          ? action.payload.pendingUpdates
              .get(assessmentId, Map({}))
              .filter((item, k) => item && k)
          : Map({});
      if (items && items.size) {
        return state.update(assessmentId, Map({}), (responses) =>
          responses.mergeWith(practiceMerger, items)
        );
      }
      return state;
    }

    // 'resubscribe/responses' is emitted by the server as a push
    // message after the resubscribe itself returns.
    case 'resubscribe/responses':
    case 'multi_patch_resource/responses':
    case 'item_inserted/responses':
    case 'item_updated/responses':
    case 'get_resource/responses': {
      const assessments = groupBy(
        action.response.result._items,
        (item) => item[ASSESSMENT_ID_FIELD]
      );
      return state.withMutations((m) => {
        // action.response[ASSESSMENT_ID_FIELD] is a string for an single assessment,
        // or an array of strings if multiple assessments of responses are returned
        // We want to store an empty map if the assessment has no responses yet to know that we have tried to fetch
        const aIds = [].concat(action.response[ASSESSMENT_ID_FIELD]);
        aIds.forEach((key) => {
          if (!m.has(key)) {
            m.set(key, Map({}));
          }
        });

        Object.keys(assessments).forEach((key) => {
          m.update(key, Map({}), (responses) =>
            responses.mergeWith(
              practiceMerger,
              assessments[key]
                ? Map(normalizePractices(assessments[key]).responses)
                : Map({})
            )
          );
        });
      });
    }
    case 'APOLLO_QUERY_RESULT': {
      switch (action.operationName) {
        // nest action items in their requesite assesement responses
        case 'GetActionItemDetail': {
          let practiceActionItems = Map({});
          const { responses } = action.result.data.assessment;
          responses.forEach(({ practiceId, actionItems }) => {
            practiceActionItems = practiceActionItems.set(
              practiceId,
              Map({ actionItems: actionItems })
            );
          });

          const { assessmentId } = action.variables;

          return state.mergeDeep(
            Map({
              [assessmentId]: practiceActionItems,
            })
          );
        }
        default:
          break;
      }
      break;
    }
    case 'APOLLO_MUTATION_RESULT': {
      if (action.result.errors) {
        break;
      }
      switch (action.operationName) {
        case 'AddActionItem': {
          return state.mergeDeep(
            Map({
              [action.variables.newActionItem.assessmentId]: Map({
                [action.variables.newActionItem.practiceId]: {
                  _id: action.result.data.addActionItem.response.id,
                  _etag: action.result.data.addActionItem.response._etag,
                  _practice_id: action.variables.newActionItem.practiceId,
                  _assessment: action.variables.newActionItem.assessmentId,
                },
              }),
            })
          );
        }
        case 'createLinkWithoutResponse': {
          const payload = action.result?.data?.createLinkWithoutResponse;
          if (!payload || !payload.response) {
            Logger.warn(
              'Unexpected empty payload from createLinkWithoutResponse mutation'
            );
            return state;
          }
          const links = payload.link ? [payload.link.id] : [];
          const { response } = payload;
          const updatedResponse = {
            _assessment: response.assessment.id,
            _created: response.dateCreated,
            _etag: response._etag,
            _id: response.id,
            _practice_id: response.practiceId,
            _updated: response.dateLastUpdated,
            links,
          };
          return state.setIn(
            [response.assessment.id, response.practiceId],
            updatedResponse
          );
        }
        case 'UpdateActionItem':
        case 'UpdateActionItemDone': {
          const payload =
            action.result?.data?.updateActionItem ??
            action.result?.data?.updateActionItemDone;

          if (!payload) {
            Logger.warn(
              'Unexpected empty payload from UpdateActionItem or UpdateActionItemDone mutation'
            );
            return state;
          }

          const updatedActionItem = payload.actionItem;
          const assessment = payload.assessment;
          const response = payload.response;

          if (!updatedActionItem || !assessment || !response) {
            Logger.warn(
              'Skipped merging action items from UpdateActionItem(Done) mutation because of missing payload field'
            );
            return state;
          }

          const existingActionItems =
            state.getIn([assessment.id, response.practiceId, 'actionItems']) ??
            [];

          if (!Array.isArray(existingActionItems)) {
            Logger.warn(
              'Response actionItems in state not stored as an array!'
            );
            return state;
          }

          const normalizedActionItems = existingActionItems.map((item: any) =>
            item.id === updatedActionItem.id
              ? {
                  ...item,
                  ...updatedActionItem,
                }
              : item
          );

          if (normalizedActionItems.length === 0)
            normalizedActionItems.push(updatedActionItem);

          return state.setIn(
            [assessment.id, response.practiceId, 'actionItems'],
            normalizedActionItems
          );
        }
        case 'AddNote': {
          return state.mergeDeep(
            Map({
              [action.variables.newNote.assessmentId]: Map({
                [action.variables.newNote.practiceId]: {
                  _id: action.result.data.addNote.response.id,
                  _etag: action.result.data.addNote.response._etag,
                  _practice_id: action.variables.newNote.practiceId,
                  _assessment: action.variables.newNote.assessmentId,
                },
              }),
            })
          );
        }
        case 'SelectDelegatedResponse': {
          const _assessment =
            action.result.data.selectDelegatedResponse.assessment.id;
          const arrayValues: Array<[string, IResponseNestedArrays]> = [];
          const responses = action.result.data.selectDelegatedResponse.responses.map(
            (response) => {
              const {
                id: _id,
                practiceId: _practice_id,
                links: gqlLinks,
                assessment: { id: assessmentId },
                ...rest
              } = response;
              const links = gqlLinks.filter(notEmpty).map((link) => link.id);
              const delegatedAssessmentId =
                response.delegatedAssessment?.id ?? null;
              const assessmentName = response.delegatedAssessment?.name ?? null;
              arrayValues.push([
                _practice_id,
                {
                  notes: rest.notes,
                  dimensions: rest.dimensions,
                },
              ]);
              return [
                _practice_id,
                {
                  ...rest,
                  _id,
                  _practice_id,
                  delegatedAssessmentId,
                  assessmentName,
                  _assessment: assessmentId,
                  links,
                },
              ];
            }
          );

          const arrayValuesMap = Map(arrayValues);
          return state
            .mergeDeep(
              Map({
                [_assessment]: Map(responses),
              })
            )
            .withMutations((map) => {
              const entries = arrayValuesMap.entries() as IterableIterator<
                [string, IResponseNestedArrays]
              >;
              let result = entries.next();
              while (!result.done) {
                const pid = result.value[0];
                const arrays = result.value[1];
                map.setIn(
                  [_assessment, pid, 'dimensions'],
                  arrays.dimensions ?? []
                );
                map.setIn([_assessment, pid, 'notes'], arrays.notes ?? []);
                result = entries.next();
              }
            });
        }

        // Publish/unpublish response(s) and update their linked responses
        // accordingly in store. If a response(s) becomes unpublished, any
        // responses delegating to it become undelegated.
        case 'PublishResponse': {
          const formattedResponses = formatResponsesForUpdate(
            action.result.data.updateResponsePublishState.responses
          );
          const linkedResponses = formatResponsesForUpdate(
            action.result.data.updateResponsePublishState.linkedResponses
          );
          const allGroupedResponses = groupBy(
            formattedResponses.concat(linkedResponses),
            (response) => response[ASSESSMENT_ID_FIELD]
          );
          return state.withMutations((m) => {
            // We want to store an empty map if the assessment has no responses
            // yet to know that we have tried to fetch
            const assessmentIds = Object.keys(allGroupedResponses);
            assessmentIds.forEach((key) => {
              if (!m.has(key)) {
                m.set(key, Map({}));
              }
            });

            Object.keys(allGroupedResponses).forEach((key) => {
              m.update(key, Map({}), (responses) =>
                responses.mergeWith(
                  practiceMerger,
                  allGroupedResponses[key]
                    ? Map(
                        normalizePractices(allGroupedResponses[key]).responses
                      )
                    : Map({})
                )
              );
            });
          });
        }
        case 'UpdateShouldAddHalfLevelCredit': {
          const mutationResult: UpdateShouldAddHalfLevelCreditMutation =
            action.result.data;
          const {
            assessment,
            response,
          } = mutationResult.updateShouldAddHalfLevelCredit;
          const {
            id: responseId,
            practiceId,
            shouldAddHalfLevelCredit,
          } = response;
          const { id: assessmentId } = assessment;
          const stateResponse = state.get(assessmentId)?.get(practiceId);
          if (!stateResponse) {
            Logger.warn(
              `Could not update response in redux state, response inaccessible/not present. updatedPath: response.shouldAddHalfLevelCredit, assessmentId: ${assessmentId}, practiceId: ${practiceId}, responseId: ${responseId}`
            );
            return state;
          }
          return state.setIn(
            [assessmentId, practiceId, 'shouldAddHalfLevelCredit'],
            shouldAddHalfLevelCredit
          );
        }
        default:
          break;
      }
      break;
    }

    case 'post_resource/responses': {
      const items = action.response.result._items
        ? normalizePractices(action.response.result._items).responses // This is a multi-response from Eve.
        : normalizePractice(action.response.result).responses; // This is a single-response from Eve.
      return state.mergeDeep(
        Map<string, IResponse>(items).groupBy(
          (response) => response[ASSESSMENT_ID_FIELD]
        )
      );
    }
    case 'delete_resource/assessments': {
      const assessmentId: AssessmentId = action.request._id;
      return state.delete(assessmentId);
    }
    case 'error/multi_patch_resource/responses':
    case 'error/post_resource/responses': {
      // Action will either be multiple items or a single one.  First normalize that.
      const assessmentId: AssessmentId = action.request[ASSESSMENT_ID_FIELD];
      const result = action.response.result;
      const items: Array<IResponse & { code: number }> = result._items || [
        result,
      ];
      return state.update(assessmentId, Map({}), (responses) =>
        responses.withMutations((response) => {
          for (const item of items) {
            const {
              code,
              [ID_FIELD]: id,
              [PRACTICE_ID_FIELD]: practiceId,
              _etag,
            } = item;
            const localVersion = response.get(practiceId) as IResponse;
            if (
              code === networkErrorCodes.CONFLICT ||
              code === networkErrorCodes.PRECONDITION_FAILURE
            ) {
              if (!id) {
                throw new TypeError(
                  'Empty dbid in dbInfo reducer: error/post_resource'
                );
              }
              if (!practiceId) {
                throw new TypeError(
                  'Empty practiceID in dbInfo reducer: error/post_resource'
                );
              }
              if (!_etag) {
                throw new TypeError(
                  'Empty etag in dbInfo reducer: error/post_resource'
                );
              }
              response.set(practiceId, {
                ...localVersion,
                _etag,
                [ID_FIELD]: id,
              });
            }
          }
        })
      );
    }
    case 'CREATE_NOTE':
    case 'CREATE_LINK': {
      const itemType = action.type === 'CREATE_NOTE' ? 'notes' : 'links';
      return state.updateIn(
        [action[ASSESSMENT_ID_FIELD], action.id],
        {},
        (response) => {
          return {
            ...response,
            [itemType]: response[itemType]
              ? response[itemType].concat(action.key)
              : [action.key],
          };
        }
      );
    }
    case 'DELETE_NOTE':
    case 'DELETE_LINK': {
      const itemType = action.type === 'DELETE_NOTE' ? 'notes' : 'links';
      return state.updateIn(
        [action[ASSESSMENT_ID_FIELD], action.id],
        {},
        (response) => {
          return {
            ...response,
            [itemType]: response[itemType]
              ? response[itemType].filter((key) => key !== action.key)
              : [],
          };
        }
      );
    }
    case 'CHANGE_PRACTICE_CRITICAL':
    case 'CHANGE_PRACTICE_FLAGGED':
    case 'UPDATE_PRACTICE':
    default: {
      if (action.practiceFields) {
        const assessmentId = action[ASSESSMENT_ID_FIELD];
        const practice = state.getIn([assessmentId, action.id]) || {};
        return state.update(assessmentId, Map({}), (responses) =>
          responses.set(action.id, {
            _practice_id: action.id,
            [ASSESSMENT_ID_FIELD]: assessmentId,
            ...(practice as Object),
            ...action.practiceFields,
          })
        );
      }
      break;
    }
  }
  return state;
};

function mergeEmbeddedDocs(
  current: string[] = [],
  update: { [id: string]: object }
) {
  // deleted items are sent as empty objects
  const deletedItems = [] as string[];
  const insertedItems = [] as string[];
  Object.entries(update).forEach((v) => {
    const [key, value] = v;
    return isEmpty(value)
      ? deletedItems.push(key)
      : !current.includes(key) && insertedItems.push(key);
  });
  return current
    .filter((item) => !deletedItems.includes(item))
    .concat(insertedItems);
}

const practiceMerger = (
  argCurrent: IResponse,
  argUpdate: IResponse
): IResponse => {
  // Merges the pendingUpdates version of a practice with the client version
  // of the practice.

  const current = { ...argCurrent }; // Don't want to mutate args
  const update = { ...argUpdate }; // Don't want to mutate this either.

  if (update.links && !Array.isArray(update.links)) {
    update.links = mergeEmbeddedDocs(current.links, update.links);
  }

  // If we're trying to set the current targetLevel to -1, that means we don't
  // want targetLevel in the practice anymore.
  if (update.targetLevel === -1) {
    delete update.targetLevel;
    delete current.targetLevel;
  }

  if (update.level === -1) {
    delete update.level;
    delete current.level;
  }

  return { ...current, ...update };
};

const formatResponsesForUpdate = (responses: any[]): IResponse[] => {
  return responses.map((response) => {
    const {
      id: _id,
      _etag,
      isPublishedForDelegation,
      delegatedAssessmentId,
      practiceId: _practice_id,
      ...rest
    } = response;
    return {
      ...rest,
      _id,
      _etag,
      _practice_id,
      isPublishedForDelegation,
      _assessment: response.assessment.id,
      delegatedAssessmentId,
    };
  });
};

export function getResponses(
  state: IResponsesState,
  assessmentId: AssessmentId
) {
  return state.get(assessmentId);
}

export function isPracticeIncomplete(response?: IResponse) {
  return !response || !response.level || response.level <= 1;
}

export function getIncompleteModelPractices(
  responses: Map<PracticeId, IResponse>,
  modelPractices: List<IModelPractice>
) {
  return modelPractices.filter((p) =>
    isPracticeIncomplete(responses.get(p.id))
  );
}
