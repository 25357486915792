import { isEmpty, isNil } from 'lodash';
import Logger from 'common/utils/Logger';
import {
  IModelDomain,
  IModelPractice,
  IModelState,
} from 'assessments/reducers/models/model';
import { ModelScope } from 'assessments/databaseTypes';
import { Map } from 'immutable';

export const MODEL_SCOPE_TIER_PREFIX = 'tier';
export const MODEL_SCOPE_MIL_PREFIX = 'mil';

export type IModelStateTierInfo = Pick<
  IModelState,
  | 'data'
  | 'features'
  | 'milDomains'
  | 'milPracticeLists'
  | 'modelUuid'
  | 'practiceList'
  | 'tierDomains'
  | 'tierPracticeLists'
>;
export interface ModelTierMeta {
  isTieredModel: boolean;
  isMilScoped?: boolean;
  isTierScoped?: boolean;
  tier?: number;
}

export interface PracticeCountByDomain {
  domainId: string;
  practiceCount: number;
  objectivePracticeCount: ObjectivePracticeCountMap;
}

export type ObjectivePracticeCountMap = Map<string, number>;

export const getPracticeCountsByDomain = (
  model: IModelStateTierInfo,
  modelScope?: ModelScope
): Map<string, number> =>
  Map<string, number>(
    getPracticeCountsByDomainAndObjective(model, modelScope).map(
      (practiceCountByDomain, domainId): [string, number] =>
        [domainId, practiceCountByDomain.practiceCount] as [string, number]
    )
  );

export const getPracticeCountsByDomainAndObjective = (
  model: IModelStateTierInfo,
  modelScope?: ModelScope
): Map<string, PracticeCountByDomain> =>
  Map<string, PracticeCountByDomain>(
    domainsForModelScope(model, modelScope).reduce((mapData, domain): Array<
      [string, PracticeCountByDomain]
    > => {
      const { objectives } = domain;
      const practiceCountByDomain = objectives.reduce(
        (acc: PracticeCountByDomain, objective): PracticeCountByDomain => {
          acc.practiceCount += objective.practices.length;
          acc.objectivePracticeCount.set(
            objective.id,
            objective.practices.length
          );
          return acc;
        },
        {
          domainId: domain.id,
          practiceCount: 0,
          objectivePracticeCount: Map(),
        } as PracticeCountByDomain
      );
      return mapData.concat([[domain.id, practiceCountByDomain]]);
    }, [] as Array<[string, PracticeCountByDomain]>)
  );

export const domainsMapForModelScope = (
  model: IModelStateTierInfo,
  modelScope?: ModelScope
): Map<string, IModelDomain> =>
  Map<string, IModelDomain>(
    domainsForModelScope(model, modelScope).map((d) => [d.id, d])
  );

export const domainsForModelScope = (
  model: IModelStateTierInfo,
  modelScope?: ModelScope
): IModelDomain[] => {
  if (!isNil(modelScope) && modelScope === ModelScope.Quicklaunch)
    return model.milDomains[1];
  const modelTierMeta = getModelTierMetadata(model, modelScope);
  const { isTieredModel, tier } = modelTierMeta;
  if (isNil(modelScope) || !isTieredModel || isNil(tier))
    return model.data.domains;
  return model.features.hasMilScoping
    ? model.milDomains[tier]
    : model.tierDomains[tier];
};

export const practicesByModelScope = (
  model: IModelStateTierInfo,
  modelScope?: ModelScope
): IModelPractice[] => {
  const modelTierMeta = getModelTierMetadata(model, modelScope);
  const practiceArray = [...model.practiceList];
  if (!model.features.hasMilScoping && !model.features.hasTierScoping) {
    return practiceArray;
  }
  if (isNil(modelScope)) {
    Logger.warn(
      `Warning: model ${model.modelUuid} has mil or tiered scoping but no model scope provided`
    );
    return practiceArray;
  }
  const emptyPracticeList = [] as IModelPractice[];
  if (modelScope === ModelScope.Quicklaunch) {
    return [...(model.milPracticeLists[1] ?? emptyPracticeList)];
  }
  if (isNil(modelTierMeta.tier)) {
    return practiceArray;
  }
  const practiceList = model.features.hasTierScoping
    ? model.tierPracticeLists
    : model.milPracticeLists;
  return [...(practiceList[modelTierMeta.tier] ?? emptyPracticeList)];
};

export const getModelTierMetadata = (
  model: IModelStateTierInfo,
  modelScope = ''
): ModelTierMeta => {
  const res = { isTieredModel: false };
  if (modelScope === ModelScope.Quicklaunch) {
    return { isTieredModel: true, isMilScoped: true, tier: 1 };
  }
  if (!model.features.hasMilScoping && !model.features.hasTierScoping) {
    return res;
  }
  const tieredModelRes = { isTieredModel: true };
  if (isEmpty(modelScope)) {
    Logger.warn(
      `Error: model ${model.modelUuid} has tier scoping but no tier provided in modelScope: ${modelScope}`
    );
    return tieredModelRes;
  }
  const prefix = model.features.hasTierScoping
    ? MODEL_SCOPE_TIER_PREFIX
    : MODEL_SCOPE_MIL_PREFIX;
  try {
    const tier = parseInt(modelScope.substring(prefix.length), 10);
    if (!isNaN(tier)) {
      return model.features.hasMilScoping
        ? { ...tieredModelRes, isMilScoped: true, tier }
        : { ...tieredModelRes, isTierScoped: true, tier };
    }
  } catch (err) {
    Logger.warn(
      `Error: could not parse model tier from model scope: ${modelScope}, ${err}`
    );
  }
  return tieredModelRes;
};
