import { rpaAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { PracticeLevelHelpTextType } from 'common/databaseTypes';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';

export const rpaConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  factories: [
    {
      label: 'Axio Ransomware Preparedness Assessment',
      filterLabel: 'Ransomware',
      icon: icon,
      sortKey: 'RPA',
      modelUuid: LegacyModelIdEnum.Rpa,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.RPA_FULL,
    },
  ],
  practiceLevelHelpTextType: PracticeLevelHelpTextType.OBJECTIVE,
  shortTitle: 'Ransomware Assessment',
};
