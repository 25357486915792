import { Map } from 'immutable';

import { score, IPartialResponse, IScoreValue } from 'assessments/score/score';

import {
  ControlInitiativeFieldsFragment,
  ControlPractice,
} from 'common/graphql/graphql-hooks';
import { ILicensedModelsState } from 'assessments/reducers/models/modelReducer';

export interface MinimalPracticeBag {
  modelUuid: string;
  practiceIDs: Array<string | null> | null;
  scoreRanges: Array<{
    __typename: 'ScoreRange';
    rangeMin: number;
    rangeMax: number;
  } | null> | null;
}

interface MinimalResponse {
  practiceId: string;
  level?: number | null;
  targetLevel?: number | null;
}

interface MinimalScenarioCollection<
  T extends MinimalResponse = MinimalResponse
> {
  controlInitiatives?: Array<ControlInitiativeFieldsFragment | null> | null;
  linkedAssessment: { responses: Array<T | null> } | null;
}

export const getRelevantResponses = <T extends MinimalResponse>(
  responses: Array<T | null>,
  practiceBagIds: Array<string | null>
) => {
  return responses.filter((response): response is T => {
    if (response) {
      return practiceBagIds.includes(response.practiceId);
    }
    return false;
  });
};

export const getResponseKeys = <T extends MinimalResponse>(
  responses: Array<T | null>
) => {
  return responses.reduce<Record<string, boolean>>((result, item) => {
    // Used as a hash table. Values don't matter.
    if (item) {
      result[item.practiceId] = true;
    }
    return result;
  }, {});
};

export const getFormattedResponses = <T extends MinimalResponse>(
  responses: Array<T | null>
) => {
  return responses.reduce((result, item) => {
    if (item) {
      return result.set(item.practiceId, {
        ...item,
        _id: '',
        _practice_id: item.practiceId,
        level: item.level ?? undefined,
        targetLevel: item.targetLevel ?? undefined,
      });
    }
    return result;
  }, Map<string, IPartialResponse>());
};

const returnScoredResponses = <
  T extends MinimalResponse,
  U extends MinimalPracticeBag
>(
  modelsByUUid: ILicensedModelsState,
  relevantResponses: Array<T | null>,
  practiceBag: U
) => {
  if (!practiceBag.modelUuid) {
    return;
  }

  const responseKeys = getResponseKeys(relevantResponses);

  const filterFunction = (practice: { id: string }) => {
    return practice.id in responseKeys;
  };

  const formattedResponses = getFormattedResponses(relevantResponses);

  const modelReducer = modelsByUUid.get(practiceBag.modelUuid);

  if (!modelReducer) return;

  modelReducer.scoring.useScaling = false;
  return score(modelReducer, formattedResponses, null, 'level', filterFunction);
};

export const getLevelScore = <
  T extends MinimalPracticeBag,
  U extends MinimalScenarioCollection
>(
  modelsByIdentifier: ILicensedModelsState,
  practiceBag: T,
  scenarioCollection: U,
  controlInitiative?: ControlInitiativeFieldsFragment
) => {
  if (
    !scenarioCollection.linkedAssessment?.responses ||
    !practiceBag?.practiceIDs
  ) {
    return;
  }

  const relevantResponses = getRelevantResponses(
    scenarioCollection.linkedAssessment.responses,
    practiceBag.practiceIDs
  );
  const controlPracticeMap = controlInitiative
    ? getControlPracticeMap(modelsByIdentifier, controlInitiative)
    : null;
  // If control initiative has practice with future level, default to that
  // level for scoring responses
  const responsesWithFutureLevels = controlPracticeMap
    ? relevantResponses.map((response) => {
        // Recreate the response object because original response's level
        // property is read only
        const updatedResponse = { ...response };
        if (updatedResponse.practiceId in controlPracticeMap) {
          const futureLevel =
            controlPracticeMap[updatedResponse.practiceId].futureLevel;
          updatedResponse.level = futureLevel ?? updatedResponse.level;
        }
        return updatedResponse;
      })
    : relevantResponses;

  return returnScoredResponses(
    modelsByIdentifier,
    responsesWithFutureLevels,
    practiceBag
  );
};

const getControlPracticeMap = (
  modelsByUUid: ILicensedModelsState,
  controlInitiative: ControlInitiativeFieldsFragment
) => {
  return controlInitiative.practices?.reduce(
    (accum, practice: ControlPractice | null) => {
      if (practice) {
        const model = modelsByUUid.get(practice.modelUuid);

        if (!model) {
          return accum;
        }

        const practiceId = model.fqnToIdMap.get(practice.fqn);

        if (!practiceId) {
          return accum;
        }

        accum[practiceId] = practice;
      }
      return accum;
    },
    {}
  );
};

const getScoreFromResponses = <
  T extends MinimalResponse,
  U extends MinimalPracticeBag
>(
  modelsByIdentifier: ILicensedModelsState,
  practiceBag: U | null,
  responses: T[]
) => {
  if (!practiceBag || !practiceBag.practiceIDs || !responses) {
    return null;
  }

  const relevantResponses = getRelevantResponses(
    responses,
    practiceBag.practiceIDs
  );

  return returnScoredResponses(
    modelsByIdentifier,
    relevantResponses,
    practiceBag
  );
};

const calculateScore = <T extends MinimalPracticeBag>(
  practiceBag: T,
  levelScore?:
    | (IScoreValue & { domains: { [domain: string]: IScoreValue } })
    | null
) => {
  const maxSusceptibilityScore = 4;
  if (!!levelScore && !!practiceBag.scoreRanges) {
    const percentConversion = 100;
    const scoreAsPercent = Math.round(
      (percentConversion * levelScore?.filteredScore) / levelScore?.filteredMax
    );

    for (const item of practiceBag.scoreRanges) {
      if (
        item &&
        item.rangeMin <= scoreAsPercent &&
        scoreAsPercent <= item.rangeMax
      ) {
        return maxSusceptibilityScore - practiceBag.scoreRanges.indexOf(item);
      }
    }
  }
  // If there are no responses, default to very high susceptibility
  return maxSusceptibilityScore;
};

export const scorePracticeBagFromResponses = <
  T extends MinimalPracticeBag,
  U extends MinimalResponse
>(
  modelsByIdentifier: ILicensedModelsState,
  practiceBag: T | null,
  responses: U[] | null
) => {
  if (!practiceBag || !practiceBag.practiceIDs || !responses) {
    return null;
  }

  const levelScore = getScoreFromResponses(
    modelsByIdentifier,
    practiceBag,
    responses
  );
  if (levelScore?.filteredMax === 0) {
    return null;
  }

  return calculateScore(practiceBag, levelScore);
};

export const scoreModelByPracticeBag = <
  T extends MinimalPracticeBag,
  U extends MinimalScenarioCollection
>(
  modelsByIdentifier: ILicensedModelsState,
  practiceBag: T | null,
  scenarioCollection: U | null
) => {
  if (!practiceBag || !scenarioCollection?.linkedAssessment) {
    return null;
  }
  const levelScore = getLevelScore(
    modelsByIdentifier,
    practiceBag,
    scenarioCollection
  );

  return calculateScore(practiceBag, levelScore);
};

export const scoreControlsByPracticeBag = <
  T extends MinimalPracticeBag,
  U extends MinimalScenarioCollection
>(
  modelsByIdentifier: ILicensedModelsState,
  practiceBag: T | null,
  scenarioCollection: U | null
) => {
  if (!practiceBag || !scenarioCollection?.linkedAssessment) return null;
  return (
    scenarioCollection?.controlInitiatives?.map((ci: any) => {
      const levelScore = getLevelScore(
        modelsByIdentifier,
        practiceBag,
        scenarioCollection,
        ci
      );
      const susceptibility = calculateScore(practiceBag, levelScore);
      return {
        controlInitiativeId: ci.id,
        susceptibility,
      };
    }) ?? []
  );
};
