import { camelCase, isEmpty, isNil } from 'lodash';

const StringConstants = {
  NotApplicable: 'N/A',
  Yes: 'Yes',
  No: 'No',
};

const estimatedRecordRangeOptions = [
  '<100K',
  '100K-500K',
  '500K-1M',
  '1M-2M',
  '2M-5M',
  '5M-8M',
  '>8M',
];

const estimatedRecordRangeDropdownField = {
  type: 'dropdown',
  options: estimatedRecordRangeOptions,
  defaultValue: StringConstants.NotApplicable,
};

const yesNoNotApplicableRadioField = {
  type: 'radio',
  options: [
    StringConstants.Yes,
    StringConstants.No,
    StringConstants.NotApplicable,
  ],
  defaultValue: StringConstants.NotApplicable,
};

const companyInformation = {
  title: 'Company Information',
  text:
    'These questions will be used to capture relevant information about the assessment of a given portfolio company and help auto-populate the final report',
  prompt: '',
  responses: [
    {
      text: 'Company Name',
      type: 'text',
    },
    {
      text: 'Sector',
      type: 'text',
    },
    {
      text: 'Industry',
      type: 'text',
    },
    {
      field: 'naicsCode',
      text: 'NAICS Code',
      type: 'text',
    },
    {
      text: 'Company Founding Year',
      type: 'numeric',
    },
    {
      field: 'ownershipType',
      text: 'Ownership - Corporation, LLC, Partnership, etc.',
      type: 'text',
    },
    {
      text: 'Number of Employees',
      type: 'text',
    },
    {
      field: 'businessAddress',
      text: 'Business Address, City, State',
      type: 'text',
    },
    {
      text: 'Business Phone',
      type: 'text',
    },
    {
      text: 'Website Domain Names',
      type: 'text',
    },
    {
      field: 'operatingLocations',
      text: 'Operating Locations - Address, City, State',
      type: 'text',
      repeatable: true,
      defaultValue: [],
    },
    {
      text: 'Inherent Risk Score and Level',
      type: 'dropdown',
      options: ['1', '2', '3'],
    },
  ],
};

const revenueInformation = {
  title: 'Annual Revenue',
  text:
    'Please provide the following information prior, current and project year revenues.',
  responses: [
    {
      field: 'priorYearRevenue',
      text: 'Prior Year (US and Foreign)',
      type: 'text',
    },
    {
      field: 'currentYearRevenue',
      text: 'Current Year (US and Foreign)',
      type: 'text',
    },
    {
      field: 'nextYearRevenue',
      text: 'Next Year (US and Foreign)',
      type: 'text',
    },
  ],
};

const budgetInformation = {
  title: 'Annual Budget and Employees',
  responses: [
    {
      field: 'totalInformationTechnologyBudget',
      text: 'Total IT Budget',
      type: 'text',
    },
    {
      field: 'cyberSecurityInformationTechnologyBudget',
      text: 'Percentage of IT Budget for Cyber Security',
      type: 'text',
    },
    {
      text: 'Number of Cyber Security Team Employees',
      type: 'text',
    },
  ],
};

const subsidiaryInformation = {
  title: 'Subsidiaries',
  responses: [
    {
      field: 'subsidiaryName',
      text: 'Name of Subsidiary',
      type: 'text',
    },
    {
      field: 'subsidiaryLocation',
      text: 'Location',
      type: 'text',
    },
    {
      field: 'subsidiaryNatureOfBusiness',
      text: 'Nature of Business',
      type: 'text',
    },
    {
      field: 'applicantsPercentageOfOwnership',
      text: `Applicant's Percentage of Ownership`,
      type: 'text',
    },
  ],
};

const cyberSecurityInformation = {
  title: 'Personal Information',
  text:
    'Identify the types of personal information of individuals that you collect, process, or store and the estimated number of records for each (if none mark “n/a”)',
  responses: [
    {
      ...estimatedRecordRangeDropdownField,
      text: 'Social Security Numbers',
    },
    {
      ...estimatedRecordRangeDropdownField,
      text: 'Consumer Financial Information',
    },
    {
      ...estimatedRecordRangeDropdownField,
      text: 'Payment Card Information',
    },
    {
      ...estimatedRecordRangeDropdownField,
      text: 'Protected Health Information',
    },
    {
      ...estimatedRecordRangeDropdownField,
      text: 'Biometric Information',
    },
  ],
};

const paymentCardInformation = {
  title: 'Payment Card Information',
  text: 'If you collect, process, store or handle payment card information',
  responses: [
    {
      ...yesNoNotApplicableRadioField,
      field: 'pciDssCompliant',
      text: 'Are you PCI-DSS Compliant?',
    },
    {
      text: 'If yes, what is your PCI-DSS Compliance Level?',
      field: 'pciDssComplianceLevel',
      type: 'text',
      defaultDisabled: true,
      enableOnSelection: 'Yes',
    },
  ],
};

const biometricInformation = {
  title: 'Biometric Information',
  responses: [
    {
      ...yesNoNotApplicableRadioField,
      field: 'biometricInformationSurvey',
      text:
        'If you have biometric information records, have you reviewed your policies on collection, storage, and destruction with a qualified attorney and confirmed compliance with applicable federal, state, local and foreign laws?',
    },
  ],
};

const withFieldNames = (input) => ({ field: camelCase(input.text), ...input });

const withNumberedItems = (input, index) => ({
  ...input,
  number: `${index + 1}`,
});

const withNumberedResponsesAndFieldNames = (input, index) =>
  withFieldNames(withNumberedItems(input, index));

const reduceInitialValues = (config, input) => {
  const inputValue = input.repeatable ? [] : input.defaultValue ?? undefined;
  const { field } = input;
  config[field] = inputValue;
  return config;
};

const flattenQuestionsWithResponses = (
  acc,
  { title, text, prompt, responses = [] }
) => {
  let counter = acc.length;
  const responseGroups =
    !isNil(text) && !isEmpty(text)
      ? [
          {
            number: `${++counter}`,
            prompt,
            text,
            title,
            type: 'response_group',
          },
        ]
      : [];
  const numberedResponses = responses.map((response, index) =>
    withNumberedResponsesAndFieldNames(response, index + counter)
  );
  return [...acc, ...responseGroups, ...numberedResponses];
};

const processQuestionsAndResponses = (questionsAndResponses) => {
  const firstQuestion = withNumberedItems(questionsAndResponses[0], 0);
  const remainingQuestions = questionsAndResponses.slice(1);
  const firstResponses = firstQuestion.responses.map(
    withNumberedResponsesAndFieldNames
  );
  const responses = remainingQuestions.reduce(
    flattenQuestionsWithResponses,
    firstResponses
  );
  return [{ ...firstQuestion, responses }];
};

const orderedQuestions = [
  companyInformation,
  revenueInformation,
  budgetInformation,
  subsidiaryInformation,
  cyberSecurityInformation,
  paymentCardInformation,
  biometricInformation,
];

const questions = processQuestionsAndResponses(orderedQuestions);

const allResponses = orderedQuestions.reduce(
  (acc, { responses = [] }) => [...acc, ...responses.map(withFieldNames)],
  []
);

const initialValues = allResponses.reduce(reduceInitialValues, {});

const schema = {
  questions,
  initialValues,
};

export default schema;
