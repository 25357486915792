import { useEffect, useRef } from 'react';

export const useInterval = (callback: Function, delay: number | null) => {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay === null) {
      return;
    }
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};
