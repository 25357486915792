// This file holds the theme elements that are common across all modules.
import newClientLogo from './Axio360-demo.svg';
import clientLogo from './logo-axio360-demo.svg';

import { defaultTheme } from '../default';

export const demoTheme = {
  ...defaultTheme,
  authPoweredByLogo: clientLogo,
  poweredByLogo: newClientLogo,
  authLogo: newClientLogo,
  clientLogo,
};
