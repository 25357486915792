import { Map } from 'immutable';
import { AnyAction, Reducer } from 'redux';

type AssessmentId = string;
type IStatusHistoryData =
  | {
      loaded: false;
      changes?: any[];
    }
  | {
      loaded: true;
      changes?: any[];
    };
export type IState = Map<AssessmentId, IStatusHistoryData>;

const initialState: IState = Map({});

export const statusHistoryReducer: Reducer<IState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return initialState;
    }
    case 'get_resource/assessment_status_history':
      return addStatusHistory(state, action);

    case 'post_resource/assessments':
    case 'patch_resource/assessments': {
      const id =
        action.type === 'post_resource/assessments'
          ? action.response.result._id
          : action.request.item_id;
      const assessment = state.get(id) || {
        loaded: true,
        changes: [{ assessmentState: '' }],
      };

      const lastChange = assessment.changes?.slice(-1) || [];

      const newData = action.request.data;
      const stateChanges = assessment.changes || [];
      if (newData?.assessmentState !== lastChange[0]?.assessmentState) {
        const changesToPush = {
          assessmentState: newData.assessmentState,
          updated: new Date(),
          _updated_by: newData._updated_by,
        };
        stateChanges.push(changesToPush);
      }

      return state.set(id, {
        loaded: true,
        changes: stateChanges,
      });
    }
    default:
      break;
  }

  return state;
};

function addStatusHistory(state: IState, action: AnyAction) {
  const assessmentId = action.request.assessment;
  const changes = action.response.result._items.map((item) => ({
    assessmentState: item.assessmentState,
    updated: item._updated,
    _updated_by: item._updated_by,
  }));
  return state.set(assessmentId, {
    loaded: true,
    changes,
  });
}
