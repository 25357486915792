import { ColorTableEntry } from 'assessments/reducers/models/legacyModelConfig';
import { lighten } from 'polished';

const lightSelected = '#F8F8F8';
const darkSelected = '#202020';
const lightAmount = 0.22;

const nistBlue = '#12529a';
const nistPurple = '#622c7b';
const nistYellow = '#f5c400';
const nistRed = '#de061f';
const nistGreen = '#1c8837';

export const colorTable: Readonly<Record<string, ColorTableEntry>> = {
  '.ID': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, nistBlue),
    background: nistBlue,
  },
  '.PR': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, nistPurple),
    background: nistPurple,
  },
  '.DE': {
    selectedForeground: darkSelected,
    foreground: lighten(lightAmount, nistYellow),
    background: nistYellow,
  },
  '.RS': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, nistRed),
    background: nistRed,
  },
  '.RC': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, nistGreen),
    background: nistGreen,
  },
};
