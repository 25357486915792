import { cis18AssessmentIcon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';

const icon = cis18AssessmentIcon;

export const cisConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: false,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: true,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: true,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    useDropout: false,
    topOfDomain: 100,
  },
  factories: [
    {
      label: 'CIS Controls Assessment',
      filterLabel: 'CIS Controls',
      icon: icon,
      sortKey: 'CIS',
      modelUuid: LegacyModelIdEnum.Cis18,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.CIS18_FULL,
    },
  ],
};
