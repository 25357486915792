import { ModelScope, ScoreWidgetStyle } from 'common/graphql/graphql-hooks';

export enum PracticeLevelHelpTextType {
  MODEL = 'MODEL',
  DOMAIN = 'DOMAIN',
  OBJECTIVE = 'OBJECTIVE',
  PRACTICE = 'PRACTICE',
}

interface ILocation {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  postcode?: string;
}

interface IPhone {
  number?: string;
  ext?: string;
}

export interface LegacyModelLicense {
  key: string;
  options: ModelScope[];
}

export interface ILicense {
  customModels?: string[];
  models: LegacyModelLicense[];
  features: string[];
  modules: {
    assessment?: string;
    insurance?: string;
    quantification?: string;
    quickQuant?: string;
  };
}

export interface ISingletonAssessment {
  __typename: 'SingletonAssessment';
  _id: string;
  model: string;
  name: string;
  ownerId: string;
  ownerEmail: string;
  ownerName: string;
}

export interface ICompany {
  _id: string;
  id: string;
  _etag?: string;
  name: string;
  _created: string;
  _updated?: string;
  location: ILocation;
  phones?: IPhone[];
  tldFilters?: string[];
  external_ids?: Array<{ source: string; code: string }>;
  bitsightKey?: string;
  hasBitsightKey?: boolean;
  hasZendesk?: boolean;
  hasServiceNow?: boolean;
  hyperlinkFilters?: string[];
  activeIntegration?: string;
  license: ILicense;
  singletonAssessments?: ISingletonAssessment[];
  scoreWidgetStyle?: ScoreWidgetStyle;
  securityControls: string[];
  // only used in potential employer
  filter?: string;
  surveyQuestionSchema?: string;
  surveyUiSchema?: string;
  surveyData?: string;
  zendeskKey?: string;
  zendeskEmail?: string;
  zendeskSubdomain?: string;
  serviceNowSubdomain?: string;
  serviceNowUsername?: string;
  serviceNowPassword?: string;
  notes?: string[];
  benchmarkPeerGroup?: string;
}

export enum GranteeType {
  USER_GROUP = 'userGroup',
  USER = 'user',
}

export interface IUser {
  email: string;
  name: string;
  displayName: string;
  title: string;
  department: string;
  phone: IPhone;
  isNewUser: boolean;
  employer: ICompany | null;
  twoFactorAuthEnabled: boolean;
  roles: string[];
  type: GranteeType.USER;
  bitsightAPILoading?: boolean;
  bitsightKeyVerified?: number;
  bitsightServiceUnavailable?: boolean;
  active_assessment: string | null;
  last_assessment_type?: { modelUuid: string; modelScope: string } | null;
  last_filter: string;
  lastLogin?: string;
  last_sort: string[];
  _id: string;
  _etag: string;
  _created: string;
  _updated?: string;
  isDeleted: boolean | null;
  acknowledgedModelDeprecationMsgs?: string[];
  freemiumRestrictions?: { isFreeUser: boolean };
}

export const TagType = {
  DEFAULT: 'default' as const,
  ASSESSMENT: 'assessment' as const,
  SCENARIO_COLLECTION: 'scenarioCollection' as const,
  NOTE: 'note' as const,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TagType = typeof TagType[keyof typeof TagType];

export interface IReactTag {
  id: string;
  name: string;
  title: string;
  subtitle?: string;
  ancestorId: string | null;
  type: TagType;
  owner?: string | null;
}

export interface ITagTreeItem {
  id: string;
  title: string;
  subtitle: string;
  expanded: boolean;
  children: ITagTreeItem[];
}

export interface ITag {
  name: string;
  subtitle?: string;
  ancestor: string | null;
  type: TagType;
  owner?: string;
  _id: string;
  _etag: string;
  _created: string;
  _updated?: string;
}
