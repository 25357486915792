import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { graphqlServerPath } from 'common/utils/AxioUtilities';
import Logger from 'common/utils/Logger';
import { getIdToken } from 'common/utils/userContext';
import { getBitsightCSF } from '../selectors';

const isBitsightSuccess = (responseJson) => {
  return (
    responseJson &&
    responseJson.data &&
    responseJson.data.me &&
    responseJson.data.me.employer &&
    responseJson.data.me.employer.bitsightAssessment
  );
};

const firstBitsightError = (responseJson) => {
  return Array.isArray(responseJson.errors)
    ? responseJson.errors[0]
    : responseJson.errors;
};

const isValidationError = (error) => {
  return (
    error && error.message && error.message.startsWith('401: Unauthorized')
  );
};

const bitsightError = (responseJson) => {
  const error = firstBitsightError(responseJson);
  if (isValidationError(error)) {
    return {
      type: 'BITSIGHT_VALIDATION_ERROR',
      bitsightMessage:
        'BitSight Access Unauthorized.  Please verify your key or contact your company admin.',
    };
  }
  return {
    type: 'GET_BITSIGHT_PRACTICES_RESULTS_ERR',
    error: {
      message: 'Error contacting BitSight',
      code: 500,
    },
  };
};

export const displayBitsight = (display: boolean) => (
  dispatch: ThunkDispatch<any, never, AnyAction>,
  getState: () => any
) => {
  dispatch({
    type: 'TOGGLE_BITSIGHT_DISPLAY',
    display,
  });
  if (display) {
    // checking for CSF is fine here as the mapping always returns both models
    // using selectors/bitsight/getBitsightResponses results in a circular import that jest can't handle
    const currentBitsightResponses = getBitsightCSF(getState());
    if (!currentBitsightResponses) {
      dispatch(getCompanyBitsightPractices());
    }
  }
};

export const getCompanyBitsightPractices = () => async (
  dispatch: ThunkDispatch<any, void | never, AnyAction>,
  getState: () => any
) => {
  // Start by dispatching an action indicating that we're beginning to fetch...
  dispatch({
    type: 'GET_BITSIGHT_PRACTICES_INITIAL',
  });

  const cognitoIdToken = await getIdToken();
  try {
    const response = await fetch(graphqlServerPath(), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Location, X-Request-URL',
        Authorization: cognitoIdToken || '',
      },
      method: 'POST',
      body: JSON.stringify({
        operationName: 'GetCompanyBitsightData',
        query:
          'query GetCompanyBitsightData { me { id employer { id bitsightAssessment { C2M2 { id grade color summary reasons { title grade color } } CSF { id grade color summary reasons { title grade color } } } } }}',
      }),
    });
    const gqlResponse = await response.json();
    if (isBitsightSuccess(gqlResponse)) {
      dispatch({
        type: 'GET_BITSIGHT_PRACTICES_RESULTS',
        payload: gqlResponse.data.me.employer.bitsightAssessment,
      });
    } else {
      dispatch(bitsightError(gqlResponse));
    }
  } catch (error: any) {
    Logger.error(error);
    dispatch({
      type: 'GET_BITSIGHT_PRACTICES_RESULTS_ERR',
      error: {
        message: 'Error contacting BitSight',
        code: 500,
      },
    });
  }
};
