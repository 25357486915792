import { Colors } from '@blueprintjs/core';

// Style contants for the app
export const AXIO_LOGO_BLUE = '#222B65';
export const AXIO_EMPHASIS_BLUE = '#3A95D2';

//Background color of the auth screen (left column)
export const AXIO_LOGIN_GREEN = '#00cd8e';
export const BORDER_COLOR = '#9a9a9c';
export const GRAY_TEXT = '#808285';
export const LABEL_GRAY = '#555';
export const DARK_BACKGROUND_COLOR = '#6b6e74';
export const HEADER_OFFSET_MARGIN = 103;

export const HEADER_SHORTERNAME_WIDTH = 1420; // determines when to use the short v. long domain name in tabs
export const HEADER_SHORTNAME_WIDTH = 880; // determines when to use the short v. long domain name in tabs
export const PRIMARY_BLUE = '#2866b5';
export const PRIMARY_RED = '#d63654';
export const COVERAGE_GREEN = '#b9d2a3';
export const DARK_PURPLE = '#5e3c99';
export const DIMENSION_GRADIENT_FACTOR = 0.125;

// Styles for the various icon buttons...

export const OFFLINE_ERROR_MESSAGE =
  'You have gone offline! Please check your connection before making changes.';
export const NO_GRAPHQL_ERROR_MESSAGE =
  'Failed to connect to Axio360. Check your network before making changes. If the problem persists, contact support@axio.com.';
export const USER_ACCOUNT_ERROR_MESSAGE =
  'There seems to be a problem with your account. Please contact support@axio.com.';

export const ICON_BUTTON_COLOR = '#a8acb3';
export const ICON_BUTTON_HOVER_COLOR = '#062472';
export const LIGHT_ICON_BUTTON_COLOR = '#d6d8db';
export const LIGHT_ICON_BUTTON_HOVER_COLOR = PRIMARY_BLUE;
export const DISABLED_BUTTON_COLOR = '#a2a2a2';
export const ICON_BUTTON_COLOR_WARN = PRIMARY_RED;

export const OBJECTIVE_BADGE_COLOR = '#6B6E73';

export const ICON_BACKGROUND = 'rgb(40, 102, 181)';

// max-width: 1750px
export const PRACTICE_MEDIA_QUERY_WIDTH_EXTRA_LARGE = 800;
// max-width: 1600px
export const PRACTICE_MEDIA_QUERY_WIDTH_LARGE = 650;
// max-width: 1400px
export const PRACTICE_MEDIA_QUERY_WIDTH_MEDIUM = 550;
export const PRACTICE_NAME_LONG_THRESHOLD = 7;

export const QUANT_BORDER_COLOR = '#d6d6d6';

export const ASSESSMENTS_ICON_COLOR = PRIMARY_BLUE;
export const INSURANCE_ICON_COLOR = '#735fa4';
export const QUANT_ICON_COLOR = '#d63654';

export const DROPDOWN_SHADOW = '0px 8px 16px 0px rgba(0, 0, 0, 0.2)';
export const PROFILE_MENU_DROPDOWN_SHADOW =
  '0px 18px 16px 0px rgba(0, 0, 0, 0.2)';

export const CHART_ANIMATION_DURATION = 500;
export const BASE_CHART_ANIMATION = { duration: CHART_ANIMATION_DURATION };

export const ID_FIELD = '_id';

export const TOC_COLLAPSE_WIDTH = 1225; // determines when the TOC no longer shows.
export const TOC_WIDTH = 240;
export const TOC_PADDING = 15;
export const NAVBAR_WIDTH = 50;
export const SPACING = 8;

export const BIG_HEADER_NAV_HEIGHT = 46;
export const NORMAL_HEADER_HEIGHT = 70;

export const DOMAIN_ROW_HEIGHT = 126;
export const DOMAIN_HEADER_HEIGHT = 89;

export const DASHBOARD_URL = '/assessments/dashboard';
export const QL_CSF_URL = '/assessments/assessment';
export const ASSESSMENTS_PLANNING_URL = '/assessments/planning';
export const ASSESSMENTS_INTRO_URL = '/assessments/intro';
export const INSURANCE_URL = '/insurance';
export const INSURANCE_FULL_PATH =
  '/insurance/:component/:reviewId/:policyId/:fileId';
export const QUANTIFICATION_URL = '/quantification';
export const QUANTIFICATION_FULL_PATH =
  '/quantification/:component/:collectionId/:scenarioId';

export const INSURANCE_MODULE = 'insurance';
export const QUANTIFICATION_MODULE = 'quantification';
export const QUICKQUANT_URL = '/quickquant';

//somewhat hidden contraint we are putting on models that's not well documented
export const NA_LEVEL = -2;

export const SIZEME_OPTIONS = {
  monitorHeight: true,
};

export enum IconSize {
  SMALL = 16,
  LARGE = 24,
}

export enum Status {
  SUCCESS = 'SUCCESS',
  BAD = 'BAD',
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  NONE = 'NONE',
}

export const StatusFeedback = {
  SUCCESS: '#CFFDCC',
  BAD: '#FCC7BC',
  PRIMARY: '#D0E3F2',
  WARNING: '#FFF1C0',
  NONE: '#EBECF1',
};

export const StatusEmphasis = {
  SUCCESS: Colors.GREEN3,
  BAD: Colors.RED3,
  PRIMARY: Colors.BLUE3,
  WARNING: Colors.ORANGE3,
};

export const TOP_Z_INDEX = 999999;

export const TitlePosition = {
  CENTER: 'center' as const,
  LEFT: 'left' as const,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TitlePosition = typeof TitlePosition[keyof typeof TitlePosition];

export const InfoPosition = {
  TOP: 'top' as const,
  RIGHT: 'right' as const,
  BOTTOM: 'bottom' as const,
  LEFT: 'left' as const,
  TOPRIGHT: 'top-right' as const,
  TOPLEFT: 'top-left' as const,
  BOTTOMRIGHT: 'bottom-right' as const,
  BOTTOMLEFT: 'bottom-left' as const,
  LEFTTOP: 'left-top' as const,
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InfoPosition = typeof InfoPosition[keyof typeof InfoPosition];

export enum tourStepPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
  CENTER = 'center',
}
export const VICTORY_Z = 99;

export const annualRevenueAttributeTemplateId = '59';
export const employeeCountAttributeTemplateId = '60';

export const ssoError =
  'Your company has elected to use SSO login. Please click the link below to sign in or contact your SSO admin for more details.';
