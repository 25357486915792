import { cmmcAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';

export const cmmcv2Config: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: true,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    offset: -203,
  },
  shortTitle: 'CMMCV2',
  factories: [
    {
      label: 'CMMCV2 Assessment',
      filterLabel: 'CMMCV2 Level 2',
      icon: icon,
      sortKey: 'CMMCV2',
      modelUuid: LegacyModelIdEnum.Cmmcv2,
      modelScope: ModelScope.Mil2,
      requiresImporter: false,
      dashboardType: DashboardType.CMMCV2_FULL,
    },
  ],
};
