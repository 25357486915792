import { Classes, InputGroup } from '@blueprintjs/core';
import { darken, transparentize } from 'polished';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import { IThemeInterface } from 'themes';

export const Container = styled.div`
  display: flex;
  font-size: 16px;
  font-family: ${(props) => props.theme.font};

  * {
    box-sizing: border-box;
  }
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  color: white;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  word-wrap: break-word;
  a {
    text-decoration: unset;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

export const AuthForm = styled.form`
  width: 25rem;
  padding: ${(props) =>
    `${0.25 * props.theme.spacing}px ${props.theme.spacing}px`};
  color: white;
  > .${Classes.FORM_GROUP} .${Classes.FORM_HELPER_TEXT} {
    color: white;
  }
  .bp3-button.bp3-large {
    background-color: ${(props) => props.theme.colors.authButtonBackground};
    color: ${(props) => props.theme.colors.authButtonTextColor};
    background-image: none;
    :hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.colors.authButtonBackground)};
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  margin-top: 0;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: ${(props) => `${2 * props.theme.spacing}px`};
  }
`;

export const BottomLinks = styled.div<{ smallMargins?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin: ${(props) => (props.smallMargins ? '1em 0' : '3em auto')};
  font-weight: 400;
  font-size: 14px;
  align-self: flex-start;

  a :hover {
    color: white !important;
  }
`;

const LoginColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2.5rem;
  flex-wrap: wrap;
`;

const RightLoginColumn = styled(LoginColumn)`
  align-self: flex-end;
  width: 40%;
  background: ${(props) =>
    transparentize(
      props.theme.authPanelTransparency,
      darken(props.theme.authPanelDarken, props.theme.colors.authBackground)
    )};
  min-width: 40%;
  @media screen and (max-width: 1000px) {
    width: 68vw;
  }
  @media screen and (max-width: 610px) {
    background: ${(props) =>
      transparentize(1, props.theme.colors.authBackground)};
    width: 100%;
  }
`;

const LeftLoginColumn = styled(LoginColumn)`
  justify-content: left;
  align-self: flex-start;
  width: 60vw;
  @media screen and (max-width: 1000px) {
    width: 37vw;
  }
  @media screen and (max-width: 610px) {
    display: none;
  }
`;

type BottomLink = LinkProps & { isWhite?: boolean };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BottomLink = styled(({ isWhite, ...rest }: BottomLink) => (
  <Link {...rest} />
))`
  color: ${(props) => (props.isWhite ? 'white' : '#263170')};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const Panel = styled.div<{ panelWidth?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 0.5rem;
  padding: 1.25rem;
  color: #263170;
  width: ${(props) => (props.panelWidth ? props.panelWidth : 'initial')};

  .bp3-input {
    border: 1px solid white;
    outline: 0;
    background: transparent;
    box-shadow: none;
    color: white;
  }
  .bp3-input::placeholder {
    color: white;
  }
`;

export const LoginInput = styled(InputGroup)`
  .bp3-input::placeholder {
    opacity: 0.4;
  }
`;

export const PoweredBy = styled.div`
  position: absolute;
  font-family: 'Lato', Helvetica, sans-serif;
  font-size: 1em;
  font-weight: 300;
  display: ${(props) => (props.theme.poweredBy ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  bottom: ${(props) => `${props.theme.spacing}px`};
  right: ${(props) => `${props.theme.spacing}px`};
  width: 13.5rem;
  height: 3rem;
  color: #263170;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: ${(props) =>
    `${0.25 * props.theme.spacing}px ${0.5 * props.theme.spacing}px`};
`;

export const PoweredByImg = styled.img.attrs((props) => ({
  src: props.theme.authPoweredByLogo,
}))`
  height: 1.8rem;
  margin-bottom: 0.5rem;
  margin-left: 0.75rem;
`;

export const MainLogo = styled.div`
  display: flex;
  width: 100%;
  min-height: 150px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled.div`
  background: ${(props) =>
    `url('${props.theme.authBackground}') ${props.theme.backgroundAnchor} / contain no-repeat`};
  background-color: ${(props) => props.theme.colors.authBackground};
  background-size: cover;
  z-index: 2;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
`;

const defaultWelcomeTextStyles = {
  color: 'white',
  fontFamily: 'GTAmerica',
  fontSize: '4.2vw',
  marginRight: '150px',
  paddingTop: '1.5em',
  textAlign: 'center',
} as React.CSSProperties;

interface IAuthProps {
  children: React.ReactNode;
  theme: IThemeInterface;
  panelWidth?: string;
  mainLogoSize?: number;
  welcomeText?: string;
  welcomeTextStyles?: React.CSSProperties;
}

const AuthPageRaw: React.FunctionComponent<IAuthProps> = (props) => {
  return (
    <Container>
      <BackgroundImage />
      <Contents>
        <ColumnContainer>
          <LeftLoginColumn>
            <h4
              data-test="welcome-text"
              style={props.welcomeTextStyles ?? defaultWelcomeTextStyles}
            >
              {props.welcomeText}
            </h4>
          </LeftLoginColumn>
          <RightLoginColumn>
            <Panel panelWidth={props.panelWidth}>
              <MainLogo>
                <img
                  width={300}
                  src={props.theme.authLogo}
                  alt={props.theme.clientName}
                />
              </MainLogo>
              {props.children}
            </Panel>
          </RightLoginColumn>
        </ColumnContainer>
        <a href="http://axio.com" target="_blank" rel="noopener noreferrer">
          <PoweredBy>
            Powered by
            <PoweredByImg alt="Axio" />
          </PoweredBy>
        </a>
      </Contents>
    </Container>
  );
};

export const AuthPage = withTheme(AuthPageRaw);
