import { debounce } from 'lodash';
import { Dispatch } from 'redux';

import Logger from 'common/utils/Logger';

import { IRootState } from '../../reducers';
import { completeDownload } from '../actions/reporting';
import {
  ReportingServer,
  ReportingState,
  ReportType,
} from '../reducers/reporting';
import { getReporting } from '../selectors';
import { reportingServerPath } from './AxioUtilities';

export const processReportingError = (error: Error, dispatch: Dispatch) => {
  Logger.exception(error, {
    contexts: {
      location: { file: 'reporting.tx', function: 'processReportingError' },
    },
  });
  dispatch({
    type: 'REPORTING_FETCH_FAIL',
    response: error,
  });
};

const getStatus = (
  url: string,
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  fetch(url, {
    headers: {
      Accept: '*/*',
      'Content-Type': 'text/plain',
      'Access-Control-Request-Headers': 'Location, X-Request-URL',
    },
    method: 'GET',
  }).then(
    (response) => {
      if (!response.ok && !response.redirected) {
        return processReportingError(Error(response.statusText), dispatch);
      }
      const type = response.headers.get('content-type');
      const path = reportingServerPath();
      if (type && type.startsWith('application/json')) {
        // kb: does this url ever change for json in our flow?
        // might be unnecessary
        const urlFromJson =
          response.url ||
          qualifyUrl(path, response.headers.get('Location')) ||
          qualifyUrl(path, response.headers.get('X-Request-URL')) ||
          url;

        return response
          .json()
          .then((json) => processJson(urlFromJson, json, dispatch, getState));
      }

      const newUrl =
        qualifyUrl(path, response.headers.get('Location')) ||
        qualifyUrl(path, response.headers.get('X-Request-URL')) ||
        url;

      return processFile(newUrl, dispatch);
    },
    (error) => {
      return processReportingError(error, dispatch);
    }
  );
};

const debouncedGetStatus = debounce(getStatus, 1000); // Only call once per second

const processJson = (
  url: string,
  json: { [key: string]: string },
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const currentState = getReporting(getState());
  if (currentState.state === ReportingState.CANCELLED) {
    return; // I guess we're done.
  }

  if (json.state === 'FAILURE') {
    return processReportingError(Error(json.status), dispatch);
  }

  if (json.state === 'PENDING' || json.state === 'PROGRESS') {
    dispatch({
      type: 'REPORTING_FETCH_' + json.state,
      json,
    });
    return debouncedGetStatus(url, dispatch, getState);
  }

  Logger.error('Unhandled response from reporting server', {
    contexts: { json },
  });
};

const processFile = (url: string, dispatch: Dispatch) => {
  dispatch({
    type: 'REPORTING_FETCH_COMPLETE',
    url,
  });
};

const qualifyUrl = (path: string, location: string | null) => {
  // Verify that we don't have a fully qualified url already....
  if (!location || location.indexOf('://') > 0 || location.startsWith('//')) {
    return location;
  }

  // First, strip any path part off of the url.
  const doubleSlash = path.indexOf('//');
  const firstSlash = path.indexOf('/', doubleSlash >= 0 ? doubleSlash + 2 : 0);
  const server = path.substr(0, firstSlash >= 0 ? firstSlash : path.length);

  // If location starts with a slash, it's a server relative path.  Otherwise,
  // it's relative to the path we received.
  if (location.startsWith('/')) {
    return server + location;
  }

  const lastSlash = path.endsWith('/') ? '' : '/';
  return path + lastSlash + location;
};

export const sendReportToServer = (
  hash: number | string,
  data: object,
  reportType: ReportType,
  theme = '',
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  // Start by dispatching an action indicating that we're beginning to fetch...
  const path = reportingServerPath();
  const reportState = getReporting(getState());
  if (
    hash === reportState.hash &&
    reportType === reportState.reportType &&
    theme === reportState.theme &&
    reportState.url
  ) {
    window.open(reportState.url);
    dispatch(completeDownload()); // Close the window.
    return;
  }

  const server = ReportingServer.REPORTING;

  // Start by dispatching an action indicating that we're beginning to fetch...
  dispatch({
    type: 'REPORTING_FETCH_START',
    hash,
    reportType,
    server,
    theme,
  });

  fetch(path + `?type=${reportType}&theme=${theme}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Request-Headers': 'Location, X-Request-URL',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok && !response.redirected) {
        return processReportingError(Error(response.statusText), dispatch);
      }
      const location = qualifyUrl(path, response.headers.get('Location'));
      if (location) {
        dispatch({
          type: 'REPORTING_FETCH_INITIAL',
          location,
        });
        getStatus(location, dispatch, getState);
      } else {
        processReportingError(
          Error('Could not find location in server response'),
          dispatch
        );
      }
    })
    .catch((error) => {
      processReportingError(error, dispatch);
    });
};
