import { Map } from 'immutable';
import { Reducer } from 'redux';

import { ASSESSMENT_ID_FIELD } from '../constants';
import { IBenchmark } from '../databaseTypes';

type AssessmentId = string;
type IState = Map<AssessmentId, IBenchmark[]>;

const initialState = Map<AssessmentId, IBenchmark[]>([]);

export const benchmarksReducer: Reducer<IState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return initialState;
    }
    case 'get_resource/benchmarks':
      return state.withMutations((m) => {
        // Even though benchmarks aren't actually for a specific assessment,
        // they are for a model, modelscope and eventually will be for
        // specific scope tags.  AND... they don't take up too much space
        // so, we'll get/store them by assessment id.  Eventually, we could
        // create a hash out of the various assessment attributes and
        // store the benchmark by that.
        const assessments = action.response[ASSESSMENT_ID_FIELD];
        const allBenchmarks = action.response.result._items;
        assessments.forEach((assessment) => {
          m.set(
            assessment._id,
            allBenchmarks.filter(
              (benchmarks) =>
                benchmarks.modelUuid === assessment.modelUuid &&
                benchmarks.modelScope === assessment.modelScope
            )
          );
        });
      });
    default:
      break;
  }

  return state;
};
