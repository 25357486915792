import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { notEmpty } from 'common/utils/typeGuards';
import { getResponses, getSelectedAssessmentId } from '.';
import { IResponse } from '../databaseTypes';
import { getFoundationsWizardQuestions } from './model';
import { IAssessmentState } from 'assessments/reducers';
import { getSelectedAssessment } from './assessment';
import { hasSurveyQuestions } from 'common/selectors/company';
import { IWizardPosition } from 'assessments/qlTypes';
import { getOriginalResponses } from '.';

export const getWizardPosition = createSelector(
  [getSelectedAssessment, hasSurveyQuestions, getFoundationsWizardQuestions],
  (assessment, hasQuestions, questions): IWizardPosition => {
    const lastPosition = questions.length - 1;
    if (!assessment) {
      return {
        current: undefined,
        lastPosition,
      };
    }
    const asNumber = parseInt(assessment.quicklaunch_position as string, 10);
    if (!Number.isNaN(asNumber)) {
      return {
        current: asNumber,
        lastPosition,
      };
    }
    if (assessment.quicklaunch_position === 'survey' && !hasQuestions) {
      return {
        current: 'intro',
        lastPosition,
      };
    }
    return {
      current: assessment.quicklaunch_position,
      lastPosition,
    };
  }
);

export const createQLAnswersMap = createSelector(
  [
    getResponses,
    getSelectedAssessmentId,
    (state: IAssessmentState) => getFoundationsWizardQuestions(state),
  ],
  (responses, selectedAssessmentId, questions) => {
    return questions.reduce(
      (map, question) => {
        const nonExclusiveResponses = question.responses.filter(
          (r) => !r.exclusive
        );
        const exclusiveResponse = question.responses.find((r) => r.exclusive);

        const levels: Array<number | undefined> = [];
        let firstResponse: IResponse | undefined;
        const responsesWithLevels = nonExclusiveResponses
          .map((r, i) => {
            const response = responses.get(r.practice ?? '');
            levels.push(response?.level);
            if (i === 0) {
              firstResponse = response;
            }
            return response?.level && response.level > 0
              ? {
                  ...r,
                  level: response.level,
                }
              : null;
          })
          .filter(notEmpty);

        const selectedPractices =
          levels.every((level) => level === 0) && exclusiveResponse
            ? [exclusiveResponse.number]
            : responsesWithLevels.map((r) => r.number);

        return map.set(question.number, {
          selectedPractices,
          firstResponse,
          selectedAssessmentId,
        });
      },
      Map<
        string,
        {
          selectedPractices: string[];
          firstResponse?: IResponse;
          selectedAssessmentId: string;
        }
      >()
    );
  }
);

// Used by the wizard to compare original db values dispatch the
// SET_ORIGINAL_RESPONSES action first to refresh original values
export const getOriginalAssessmentResponses = createSelector(
  [getOriginalResponses],
  (originalResponses) =>
    Object.keys(originalResponses).length ? originalResponses : null
);
