import { Button, Callout, FormGroup } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';

import Logger from 'common/utils/Logger';
import { useUser } from 'common/utils/userContext';
import {
  AuthForm,
  AuthPage,
  BottomLink,
  BottomLinks,
  ButtonRow,
  LoginInput,
} from './shared';
import { validatePassword } from './RegisterForm';
import { withTheme } from 'styled-components/macro';
import { IThemeInterface } from '../../themes';

const initialFormValues = { code: '', newPassword: '', confirmNewPassword: '' };

type FormState = typeof initialFormValues;

export const ConfirmForgotPasswordPage = (
  props: RouteComponentProps<{}, StaticContext, { email?: string }> & {
    theme: IThemeInterface;
  }
) => {
  const { forgotPassword, forgotPasswordSubmit } = useUser();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const emailInCognito = props.location.state && props.location.state.email;
  if (!emailInCognito) {
    Logger.error('emailInCognito arg not supplied');
    return <Redirect to="/" />;
  }
  const onSubmit = async (
    { code, newPassword, confirmNewPassword }: FormState,
    { setSubmitting }: FormikHelpers<FormState>
  ) => {
    const passwordError = validatePassword(newPassword, confirmNewPassword);
    if (passwordError) {
      setError(passwordError);
      setMessage('');
      setSubmitting(false);
    } else {
      setError('');
      try {
        await forgotPasswordSubmit(emailInCognito, code, newPassword);
        props.history.replace({
          pathname: '/authentication',
          search: `email=${emailInCognito}`,
        });
      } catch (err: any) {
        setError(err.message);
        setMessage('');
        setSubmitting(false);
      }
    }
  };

  const onResend = async () => {
    try {
      await forgotPassword(emailInCognito);
      setError('');
      setMessage('Verification code has been resent.');
    } catch (err: any) {
      setError(err.message);
      setMessage('');
    }
  };

  return (
    <AuthPage
      welcomeText="Forgot Password"
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<FormState> initialValues={initialFormValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, isValid, handleSubmit, handleChange }) => (
          <AuthForm onSubmit={handleSubmit}>
            <Callout>
              An email containing a verification code was sent to
              <strong> {emailInCognito}</strong> if that address belongs to a
              registered Axio360 user.
              <br />
              Enter the code and choose a new password.
              <br />
              Click 'Resend' to email a new code if required.
            </Callout>
            <br />
            <FormGroup>
              <LoginInput
                name="code"
                placeholder="Verification code"
                autoComplete="off"
                value={values.code}
                onChange={handleChange}
                required
                large
              />
            </FormGroup>
            <FormGroup>
              <LoginInput
                name="newPassword"
                type="password"
                autoComplete="new-password"
                value={values.newPassword}
                onChange={handleChange}
                placeholder="New Password"
                disabled={isSubmitting}
                required
                large
              />
            </FormGroup>
            <FormGroup helperText="Passwords should be at least 12 characters and must match">
              <LoginInput
                name="confirmNewPassword"
                type="password"
                autoComplete="new-password"
                value={values.confirmNewPassword}
                onChange={handleChange}
                placeholder="Confirm New Password"
                disabled={isSubmitting}
                required
                large
              />
            </FormGroup>
            {!!error && (
              <FormGroup>
                <Callout intent="danger">{error}</Callout>
              </FormGroup>
            )}
            {!!message && (
              <FormGroup>
                <Callout intent="success">{message}</Callout>
              </FormGroup>
            )}
            <ButtonRow>
              <Button text="Resend" onClick={onResend} fill large />
              <Button
                type="submit"
                text="Submit"
                disabled={isSubmitting || !isValid}
                fill
                large
              />
            </ButtonRow>
            <BottomLinks smallMargins={true}>
              <BottomLink isWhite={true} to="/">
                Return to login page
              </BottomLink>
            </BottomLinks>
          </AuthForm>
        )}
      </Formik>
    </AuthPage>
  );
};

export const ConfirmForgotPasswordForm = withTheme(ConfirmForgotPasswordPage);
