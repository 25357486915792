import { ColorTableEntry } from 'assessments/reducers/models/legacyModelConfig';
import { darken } from 'polished';

export const nistSelectedTextColor = '#202020';
export const nistDarkAmount = 0.35;

export const nistDE = '#FAB746';
export const nistRS = '#F97367';
export const nistRC = '#7DF49F';
export const nistPR = '#9292EA';
export const nistID = '#4BB2E0';
export const nistGV = '#F9F49D';

export const nist2ColorTable: Readonly<Record<string, ColorTableEntry>> = {
  '.GV': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistGV),
    background: nistGV,
  },
  '.ID': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistID),
    background: nistID,
  },
  '.PR': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistPR),
    background: nistPR,
  },
  '.DE': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistDE),
    background: nistDE,
  },
  '.RS': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistRS),
    background: nistRS,
  },
  '.RC': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistRC),
    background: nistRC,
  },
};
