import React, { Suspense } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import { LoadingScreenRaw } from 'common/components/Loading/LoadingScreen';
import {
  DASHBOARD_URL,
  INSURANCE_URL,
  QUANTIFICATION_URL,
  QUICKQUANT_URL,
} from 'common/constants';
import { getUser } from 'common/selectors';
import {
  hasAssessmentAccess,
  hasInsuranceAccess,
  hasQuantAccess,
  hasQuickQuantAccess,
} from 'common/selectors/user';
import { IRootState } from '../reducers';
import { UpsellAssessments } from './UpsellAssessments';

function Loading() {
  return <LoadingScreenRaw isLoading={true} noDelay={false} />;
}

const AssessmentView = React.lazy(
  () =>
    import(/* webpackChunkName: "App", webpackPrefetch: true */ './views/App')
);
const Dashboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Dashboard", webpackPrefetch: true */ './views/Dashboard'
    )
);

const companyHasAssessments = connectedRouterRedirect<{}, IRootState>({
  // The url to redirect user to if they fail
  redirectPath: (state) => {
    // If the user does not have access to assessments BUT does have access
    // to either quant or insurance, we're going to go ahead and redirect
    // (v. showing an upsell)  We're doing this because we default to assessments
    // Once we have some sort of primary landing page, that page can do this
    // and we can take this code out.
    if (hasQuantAccess(state)) {
      return QUANTIFICATION_URL;
    }
    if (hasInsuranceAccess(state)) {
      return INSURANCE_URL;
    }
    if (hasQuickQuantAccess(state)) {
      return QUICKQUANT_URL;
    }
    return '/assessments/upsell';
  },
  authenticatingSelector: (state) => !getUser(state).get('email'),
  authenticatedSelector: (state) => hasAssessmentAccess(state),
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'CompanyHasAssessments',
});

// Main Component gets remounted if it is not defined outside of the render
// https://github.com/mjrussell/redux-auth-wrapper/issues/224
const AssessmentViewWithGuard = companyHasAssessments(AssessmentView);
const DashboardViewWithGuard = companyHasAssessments(Dashboard);

const render = () => (
  <Suspense fallback={<LoadingScreenRaw isLoading={true} noDelay={false} />}>
    <Switch>
      <Route exact path="/assessments/upsell" component={UpsellAssessments} />
      <Route
        exact
        path="/assessments/assessment/:assessmentId?"
        component={AssessmentViewWithGuard}
      />
      <Redirect
        exact
        from="/assessments/intro/:assessmentId"
        to={DASHBOARD_URL}
      />
      <Route
        path="/assessments/:component/:assessmentId?"
        component={DashboardViewWithGuard}
      />
      <Redirect exact from="/assessments" to={DASHBOARD_URL} />
    </Switch>
  </Suspense>
);

export default withRouter(render);
