import { AlliantIcon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import questionnaireSchema from 'assessments/questionnaires/alliant-questionnaire-schema';

const icon = AlliantIcon;

export const alliantConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: false,
    convertToFull: false,
    referenceAssessments: false,
    milestones: false,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    useDropout: false,
    topOfDomain: 100,
  },
  factories: [
    {
      label: 'Alliant Assessment',
      filterLabel: 'Alliant Assessment',
      requiredLicenseOption: ModelScope.Full,
      icon,
      sortKey: 'Alliant',
      modelUuid: LegacyModelIdEnum.Alliant,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.ALLIANT,
      hasQuestionnaire: true,
    },
  ],
  questionnaireSchema,
};
