export default {
  questions: [
    {
      responses: [
        {
          number: '1',
          text: 'Portfolio Company Name',
          type: 'text',
        },
        {
          number: '2',
          text: 'Private Equity Sponsor',
          type: 'text',
        },
        {
          number: '3',
          text: 'Industry',
          type: 'text',
        },
        {
          number: '4',
          text: 'Company Description',
          type: 'text',
        },
        {
          number: '5',
          text:
            'Company Locations (Headquarters, Offices, Manufacturing, Datacenters) and Total number of employees and percentage of workforce that is remote?',
          type: 'text',
        },
        {
          number: '6',
          text: 'Revenues',
          type: 'text',
        },
        {
          number: '7',
          text: 'Locations Targeted for this Assessment',
          type: 'text',
        },
        {
          number: '8',
          text: 'M&A Activity Over Past 12 months',
          type: 'text',
        },
        {
          number: '9',
          text: 'Inherent Risk Score and Level',
          type: 'dropdown',
          options: ['1', '2', '3'],
        },
        {
          number: '10',
          text: 'Target Maturity Score',
          type: 'dropdown',
          options: ['1', '2', '3', '4', '5'],
        },
        {
          number: '11',
          text: 'Stakeholders Interviewed and When',
          type: 'text',
        },
        {
          number: '12',
          text: 'Systems hosting sensitive information (PHI, PCI, PII, etc)',
          type: 'text',
        },
        {
          number: '13',
          text:
            'Results of Perimeter Network Vulnerability Scan (number of C, H, M, L)*',
          type: 'text',
        },
      ],
      number: 0,
      title: 'Company Information',
      text:
        'These questions will be used to capture relevant information about the assessment of a given portfolio company and help auto-populate the final report',
      prompt: '',
    },
  ],
};
