import { isEmpty } from 'lodash';
import { denormalize, normalize, schema } from 'normalizr';

import { stripMeta } from 'common/utils/AxioUtilities';
import { ILink, IResponse } from '../databaseTypes';

const linkSchema = new schema.Entity(
  'links',
  {},
  {
    idAttribute: '_id',
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        _responsePracticeId: parent._practice_id,
        _assessment: parent._assessment,
      };
    },
  }
);

interface IDenormalisedResponse {
  links?: ILink[];
}

const responseSchema = new schema.Entity(
  'responses',
  {
    links: new schema.Array(linkSchema),
  },
  {
    idAttribute: '_practice_id',
    processStrategy: (value: IDenormalisedResponse, parent, key) => {
      const modifiedValue = { ...value };

      if (value.links) {
        modifiedValue.links = Object.entries(value.links).reduce(
          (accum, linkEntry) => {
            return !isEmpty(linkEntry[1])
              ? accum.concat({ ...linkEntry[1], _id: linkEntry[0] })
              : accum;
          },
          [] as ILink[]
        );
      }
      return stripMeta(modifiedValue);
    },
  }
);
const responseReportingSchema = new schema.Entity(
  'responses',
  {
    links: new schema.Values(linkSchema),
  },
  {
    idAttribute: '_practice_id',
  }
);
const responsesArraySchema = new schema.Array<IResponse>(responseSchema);
const responsesMapSchema = new schema.Values<IResponse>(responseSchema);
const responsesReportingSchema = new schema.Values<IResponse>(
  responseReportingSchema
);

export function normalizePractice(items: any) {
  return normalize(items, responseSchema).entities;
}

interface Entities {
  links: { [id: string]: ILink };
  responses: { [id: string]: IResponse };
}
export const normalizePractices = (items: any): Entities => {
  if (Array.isArray(items)) {
    return normalize<IResponse, Entities>(items, responsesArraySchema).entities;
  }
  return normalize<IResponse, Entities>(items, responsesMapSchema).entities;
};

export const denormalizePractices = (
  responseMap: { [id: string]: string },
  // typed any here to compensate for the toJS() typing
  state: {
    links: { [id: string]: any };
    responses: { [id: string]: any };
  }
) => {
  return denormalize(responseMap, responsesReportingSchema, state);
};
