import flatCombineReducers from 'flat-combine-reducers';
import { History } from 'history';

import assessmentReducers, { IAssessmentState } from './assessments/reducers';
import commonReducers, { ICommonState } from './common/reducers';

// don't provide keys to reducers that don't supply them
// see: https://stackoverflow.com/questions/43290107/combine-redux-reducers-without-adding-nesting
const filterReducer = (reducer) => {
  let lastState;
  return (state, action) => {
    if (lastState === undefined || state === undefined) {
      lastState = reducer(state, action);
      return lastState;
    }
    const filteredState = {};
    Object.keys(lastState).forEach((key) => {
      filteredState[key] = state[key];
    });
    const newState = reducer(filteredState, action);
    lastState = newState;
    return newState;
  };
};

// we have decided to try keeping the reducers flat rather than adding new namespace for everything with
// as would happen with redux combineReducers
const options = { mergePrevState: false }; // don't take advantage of possibly simplifying (but breaking) behavior from flat-combine-reducers
const rootReducer = (history: History) =>
  flatCombineReducers(
    filterReducer(commonReducers(history)),
    filterReducer(assessmentReducers),
    options
  );

export interface IRootState extends IAssessmentState, ICommonState {}

export default rootReducer;
