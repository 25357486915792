import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import { IRootState } from '../../../reducers';
import { BLUE_SHADES } from '../../dashboardColors';
import { shouldNotDelay, shouldShowLoading } from '../../selectors/loading';
import { useInterval } from '../../utils/useInterval';

export const DELAY = 300;
export const DURATION = 300;

const Middle = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;

const Container = styled.div<{ show?: boolean }>`
  display: table;
  position: absolute;

  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity ${DURATION}ms ease-in;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-width: 100vw;

  z-index: 10000;

  .screener {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const CenteredContainer = styled.div<{ show?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #fefefe;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity ${DURATION}ms ease-in;
`;

export const Spinner = styled.div`
  color: ${BLUE_SHADES[3]};
  .fa-circle {
    color: ${BLUE_SHADES[1]};
  }
  .fa-spin {
    animation-duration: 1.5s;
  }
`;

interface SpinnerComponentProps {
  size?:
    | '1x'
    | '3x'
    | 'xs'
    | 'lg'
    | 'sm'
    | '2x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';
}
export const SpinnerComponent = (props: SpinnerComponentProps) => (
  <Spinner>
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon
        icon={['far', 'circle']}
        size={props.size || '3x'}
        fixedWidth={true}
      />
      <FontAwesomeIcon
        icon={['far', 'spinner-third']}
        size={props.size || '3x'}
        fixedWidth={true}
        spin={true}
      />
    </span>
  </Spinner>
);

export interface SpinnerProps {
  fadeIn?: boolean;
  style?: React.CSSProperties;
}

export const CenteredSpinner = (props: SpinnerProps) => {
  const [showSpinner, setShowSpinner] = useState(!props.fadeIn);
  useInterval(() => {
    setShowSpinner(true);
  }, DURATION);
  return (
    <CenteredContainer show={showSpinner} style={props.style}>
      <SpinnerComponent />
    </CenteredContainer>
  );
};

interface IProps {
  isLoading?: boolean;
  forceLoading?: boolean;
  noDelay: boolean;
}

interface IState {
  /** Whether the spinner should be actually shown, used for the fades */
  show: boolean;
  /** Whether the spinner is on the way out, used to show the fade out only */
  leaving: boolean;
}

export class LoadingScreenRaw extends React.PureComponent<IProps, IState> {
  static defaultProps = {
    noDelay: false,
  };

  state = { show: false, leaving: false };
  timer?: number;

  componentDidMount() {
    if (this.props.isLoading) {
      this.fadeIn();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
  }

  fadeIn = () => {
    this.setState({ show: false, leaving: false }, () => {
      if (this.props.noDelay) {
        this.setState({ show: true });
      } else {
        this.timer = window.setTimeout(() => {
          this.setState({ show: true });
        }, DELAY);
      }
    });
  };

  fadeOut = () => {
    this.setState(
      (state) => ({ show: false, leaving: state.show }),
      () => {
        this.timer = window.setTimeout(() => {
          this.setState({ leaving: false });
        }, DURATION);
      }
    );
  };

  componentDidUpdate(prevProps: IProps) {
    const shouldLoad = this.props.isLoading || this.props.forceLoading;
    const prevShouldLoad = prevProps.isLoading || prevProps.forceLoading;
    if (shouldLoad !== prevShouldLoad) {
      if (this.timer) {
        window.clearTimeout(this.timer);
      }
      if (this.props.isLoading) {
        this.fadeIn();
      } else {
        this.fadeOut();
      }
    }
  }

  render() {
    const { isLoading, forceLoading } = this.props;
    const { show, leaving } = this.state;
    const shouldLoad = isLoading || forceLoading;
    if (!shouldLoad && !show && !leaving) {
      return null;
    }
    return (
      <Container show={show || forceLoading}>
        <div data-test="Loading Spinny" className="screener" />
        <Middle>
          <SpinnerComponent />
        </Middle>
      </Container>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  const isLoading = shouldShowLoading(state);
  const noDelay = shouldNotDelay(state);
  return { isLoading, noDelay };
};

export const LoadingScreen = connect(mapStateToProps)(LoadingScreenRaw);
