import { ColorTableEntry } from 'assessments/reducers/models/legacyModelConfig';
import {
  nistDE,
  nistDarkAmount,
  nistGV,
  nistID,
  nistPR,
  nistRC,
  nistRS,
  nistSelectedTextColor,
} from './nist2ColorTable';
import { darken, lighten } from 'polished';

export const criEX = '#F67E00';

export const criV2ColorTable: Readonly<Record<string, ColorTableEntry>> = {
  '.GV': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistGV),
    background: nistGV,
  },
  '.ID': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistID),
    background: nistID,
  },
  '.PR': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistPR),
    background: nistPR,
  },
  '.DE': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistDE),
    background: nistDE,
  },
  '.RS': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistRS),
    background: nistRS,
  },
  '.RC': {
    selectedForeground: nistSelectedTextColor,
    foreground: darken(nistDarkAmount, nistRC),
    background: nistRC,
  },
  '.EX': {
    selectedForeground: nistSelectedTextColor,
    foreground: lighten(nistDarkAmount, criEX),
    background: criEX,
  },
};
