import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { createSelector } from 'reselect';

import { getLastAssessmentType } from 'common/selectors/user';
import { notEmpty } from 'common/utils/typeGuards';
import { ModelScope } from '../databaseTypes';
import { AssessmentView, SubscriptionState } from '../reducers/app';
import { getModelScope, getSelectedAssessment } from './assessment';
import {
  getAllAssessments,
  getAllMilestones,
  getApp,
  getLicensedModelsFactories,
} from '.';

import { IAssessmentFactory } from 'assessments/reducers/models/model';
import { hasSurveyQuestions } from 'common/selectors/company';
import { Position } from 'assessments/qlTypes';
import { getFoundationsWizardQuestions } from './model';
import { IAssessmentState } from 'assessments/reducers';

export const getSelectedAssessmentFactory = createSelector(
  [getApp],
  (app) => app.selectedAssessmentFactory
);

export const getSaveMessage = createSelector(
  [getApp],
  (app) => app.saveMessage
);

export const getSaveMessageClass = createSelector(
  [getApp],
  (app) => app.saveMessageClass
);

export const getArchivedNoteStatus = createSelector(
  [getApp, getSelectedAssessment],
  (app, selectedAssessment) =>
    app.archivedNoteAssessmentIdList.includes(selectedAssessment?._id ?? '') ??
    false
);

export const getSubscriptionState = createSelector(
  [getApp],
  (app) => app.subscribed
);

export const isNewMilestoneRequest = createSelector(
  [getApp],
  (app) => app.newMilestoneRequest
);

export const getSelectedDimensionKey = createSelector(
  [getApp],
  (app) => app.selectedDimensionKey
);

export const getSelectedPractice = createSelector(
  [getApp],
  (app) => app.selectedPracticeId
);

export const getSessionSelectedAssessments = createSelector(
  [getApp],
  (app) => app.sessionSelectedAssessments
);

export const getSelectedPracticePosition = createSelector(
  [
    getSelectedPractice,
    (state: IAssessmentState) => getFoundationsWizardQuestions(state),
  ],
  (selectedPractice, questions) => {
    if (selectedPractice) {
      const num = questions
        ?.map((question, index) =>
          question.responses.find((q) => q.practice === selectedPractice)
            ? index
            : undefined
        )
        .filter((i) => i !== undefined);
      return num.toString();
    }
    return '0';
  }
);

export const makeGetIsSelectedPractice = () =>
  createSelector(
    [getSelectedPractice, (_state: any, props: { id: string }) => props.id],
    (selectedPracticeId, currentId) => {
      return selectedPracticeId === currentId;
    }
  );

export const makeGetIsSelectedObjective = () =>
  createSelector(
    [getSelectedPractice, (_state: any, props: { id: string }) => props.id],
    (selectedPracticeId, currentObjectiveId) => {
      return !!(
        selectedPracticeId && selectedPracticeId.includes(currentObjectiveId)
      );
    }
  );

export const getAssessmentMode = createSelector([getApp], (app) => app.mode);

export const getTocExpanded = createSelector(
  [getApp],
  (app) => app.tocExpanded
);

export const getReferenceAssessmentIds = createSelector(
  [getApp],
  (app) => app.referenceAssessmentIds
);

export const getReferenceAssessmentsInfo = createSelector(
  [getAllAssessments, getAllMilestones, getReferenceAssessmentIds],
  (allAssessments, allMilestones, referenceAssessmentIds) =>
    referenceAssessmentIds
      .map((assessmentId) => {
        const [aId, mId] = assessmentId.split('.');
        const assessment = allAssessments.get(aId);
        if (!assessment) {
          return null;
        }
        const milestone = mId ? allMilestones.getIn([aId, mId]) : undefined;
        //@ts-expect-error Invalid Overload Call
        const name = milestone ? milestone.name : assessment.name;
        const milestoneIcon: IconProp = ['fal', 'history'];
        const targetIcon: IconProp = ['fal', 'crosshairs'];
        return {
          id: assessment._id,
          milestoneId: mId,
          name,
          owner: assessment.owner,
          scopetags: assessment.scopetags,
          targetProfile: !!assessment.targetProfile,
          milestone: !!milestone,
          icon: milestone
            ? milestoneIcon
            : assessment.targetProfile
            ? targetIcon
            : undefined,
        };
      })
      .filter(notEmpty)
);

/**
 * Three stage finder for currently selected assessment type
 * 1. last clicked assessment type from dashboard button
 * 2. last_assessment_type from user object
 */
export const getDefaultNewAssessmentFactory = createSelector(
  [
    getLicensedModelsFactories,
    getSelectedAssessmentFactory,
    getLastAssessmentType,
  ],
  (
    licensedFactories,
    selectedAssessmentFactoryFromApp,
    lastAssessmentTypeFromUser
  ): IAssessmentFactory | null => {
    //if we only license 1, that's the one
    if (licensedFactories.length === 1) {
      return licensedFactories[0];
    }

    //use the on selected by the split button -- assume licensed (otherwise how did it get on the button?)
    if (selectedAssessmentFactoryFromApp) {
      return selectedAssessmentFactoryFromApp;
    }

    //use the last assessment off user
    if (lastAssessmentTypeFromUser) {
      return (
        licensedFactories.find(
          (x) =>
            x.modelScope === lastAssessmentTypeFromUser.modelScope &&
            x.modelUuid === lastAssessmentTypeFromUser.modelUuid
        ) || null
      );
    }

    //new users can choose of none set on user object
    return null;
  }
);

export const getQLPosition = createSelector(
  [getSelectedAssessment, hasSurveyQuestions],
  (assessment, hasQuestions): Position => {
    if (!assessment) {
      return undefined;
    }
    const asNumber = parseInt(assessment.quicklaunch_position as string, 10);
    if (!Number.isNaN(asNumber)) {
      return asNumber;
    }
    if (assessment.quicklaunch_position === 'survey' && !hasQuestions) {
      return 'intro';
    }
    return assessment.quicklaunch_position;
  }
);

/** Determines the current assessment view - (WIZARD or QUICKLAUNCH or FULL).
 * @returns {AssessmentView} Current AssessmentView (WIZARD/QUICKLAUNCH/FULL)
 */
// In the future we likely want to seperate modelScope and view
// rather than rely on "finished"
export const getAssessmentView = createSelector(
  [getModelScope, getQLPosition],
  (modelScope, qlPosition) => {
    if (modelScope === ModelScope.Quicklaunch && qlPosition !== 'finished') {
      return AssessmentView.WIZARD;
    } else if (
      modelScope === ModelScope.Quicklaunch &&
      qlPosition === 'finished'
    ) {
      return AssessmentView.QUICKLAUNCH;
    }
    return AssessmentView.FULL;
  }
);

export const getMilModelValue = createSelector([getModelScope], (modelScope) =>
  modelScope.startsWith('MIL')
    ? parseInt(modelScope[modelScope.length - 1])
    : null
);

export const getTierValue = createSelector([getModelScope], (modelScope) =>
  modelScope.startsWith('TIER')
    ? parseInt(modelScope[modelScope.length - 1])
    : null
);

export const getDescriptumHistory = createSelector(
  [getApp],
  (app) => app.descriptumHistory
);

/** Current descriptum is the last item in the history of selected items
 * @return {string} state
 */
export const getCurrentDescriptum = createSelector(
  [getDescriptumHistory],
  (history) => history[history.length - 1]
);

/** Previous descriptum is the second to last item in the history of selected items
 * @return {string} state
 */
export const getPreviousDescriptum = createSelector(
  [getDescriptumHistory],
  (history) => history[history.length - 2]
);

export const getShowBitsight = createSelector(
  [getApp],
  (app) => app.showBitsight
);

export const isAboutToLogout = createSelector(
  [getApp],
  (app) => app.aboutToLogout
);

export const isSubscribed = createSelector(
  [getApp],
  (app) => app.subscribed === SubscriptionState.SUBSCRIBED
);

export const isNetworkSaving = createSelector(
  [getApp],
  (app) => app.networkSaving
);
