import { Button } from '@blueprintjs/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AuthPage } from 'authentication/views/shared';

const UpsellText = styled.span`
  max-width: 450px;
  margin-top: 20px;
  margin-left: 1rem;
  font-size: 18px;
  text-align: left;
  color: white;
  a {
    color: white;
    :hover {
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 25px;
  }
`;

export const UpsellInsurance = () => {
  return (
    <AuthPage welcomeText="Insurance Not Available">
      <UpsellText>
        <p>
          To purchase a license or to learn more about the Insurance Analysis
          and Stress Test module, please contact{' '}
          <a href="mailto:sales@axio.com" target="_top">
            sales@axio.com
          </a>
          .
        </p>
        <Link to="/">
          <Button text="Go back" intent="primary" large />
        </Link>
      </UpsellText>
    </AuthPage>
  );
};
