import { Position, Toaster, Intent, IToastProps } from '@blueprintjs/core';

const errorToaster = Toaster.create({
  className: 'error-toaster',
  position: Position.TOP,
  maxToasts: 1,
});

export const toasterError = (
  errorMessage: string,
  options?: Omit<IToastProps, 'message'>
) => {
  errorToaster.show({
    message: errorMessage,
    intent: Intent.DANGER,
    ...options,
  });
};
