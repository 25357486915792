export default {
  questions: [
    {
      responses: [
        {
          number: '1.a',
          practice: '.ACM.1.a',
          text:
            'We have an inventory of the IT and OT assets needed for operations, including computers and other system devices.',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.b',
          practice: '.ACM.1.b',
          text:
            'We have an inventory of important data, such as SCADA set points, customer information, and financial data.',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.c',
          practice: '.ACM.3.b',
          text: 'We log changes that are made to inventoried assets.',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.d',
          practice: '.ACM.3.a',
          text:
            'We evaluate or test changes to inventoried assets before the changes are made.',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.e',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '1',
      title: 'Cyber Asset Inventory',
      text:
        'A cybersecurity program needs to understand and control the IT, OT, and information assets that are necessary to sustain operations. Assets might be systems devices, including traditional IT computers, routers, and servers, but might also include OT equipment such as programmable logic controllers (PLCs) and other control system elements. Also, inventories need to be kept up to date throughout the lifecycle of such assets.',
      prompt:
        'Please select all the responses that best describe your current asset management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '2.a',
          practice: '.ACM.2.a',
          text:
            'We establish configuration baseline(s) where assets need to be configured in the same way.',
          exclusive: false,
          level: 3,
        },
        {
          number: '2.b',
          practice: '.ACM.2.b',
          text:
            'We use the configuration baseline(s) to configure assets at deployment.',
          exclusive: false,
          level: 3,
        },
        {
          number: '2.c',
          practice: undefined,
          text:
            'We do not establish configuration baselines for any inventoried IT or OT assets.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '2',
      title: 'Configuration Baseline',
      text:
        'To help keep track of changes to IT and OT assets, many organizations establish configuration baselines that define the software, hardware, and settings for these devices. Baselines can be used to identify unauthorized or unapproved changes to an asset, set up new assets consistently, and reset or restore an asset when needed.',
      prompt:
        'Please select all the responses that best describe how your organization currently uses configuration baselines. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '3.a',
          practice: '.IAM.1.a',
          text:
            'We create accounts for people and application services that require access to our IT and OT systems. These may include accounts that are shared by multiple entities.',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.b',
          practice: '.IAM.1.b',
          text:
            'We require the use of credentials for access, such as badges or physical keys for physical access and passwords, smart cards, or security tokens for electronic access.',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.c',
          practice: '.IAM.2.a',
          text:
            'We have developed physical and electronic access requirements.',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.d',
          practice: '.IAM.2.b',
          text:
            'We use access requirements to determine who is granted access and any limitations on their access.',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.e',
          practice: '.IAM.2.c',
          text:
            "We revoke physical and electronic access when no longer needed, which may include collecting the individual's badge or security token and disabling individual user accounts.",
          exclusive: false,
          level: 3,
        },
        {
          number: '3.f',
          practice: '.IAM.1.c',
          text:
            'We remove individual and shared accounts when they are no longer needed.',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.g',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '3',
      title: 'Access Control',
      text:
        'Controlling physical and electronic access to IT and OT assets and systems is an important step in securing the operating environment. This includes the process of creating user accounts and passwords and determining the requirements for access.',
      prompt:
        'Please select all the responses that best describe your access management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '4.a',
          practice: '.TVM.2.a',
          text:
            'We have identified source(s) for vulnerability information associated with our IT and OT systems and assets.',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.b',
          practice: '.TVM.2.b',
          text:
            'We gather information on vulnerabilities and review it for applicability to our assets and systems.',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.c',
          practice: '.TVM.2.c',
          text:
            'We address important vulnerabilities (e.g., by implementing patches or other changes).',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.d',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '4',
      title: 'Vulnerability Management',
      text:
        'Cybersecurity vulnerabilities are weaknesses or flaws in IT or OT systems (or in the procedures or controls used to protect those systems) that can be leveraged by adversaries. Managing these vulnerabilities is an important security protection. The most common vulnerability management techniques include regular patching cycles and network isolation.',
      prompt:
        'Please select all the responses that best describe your current cybersecurity vulnerability management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '5.a',
          practice: '.TVM.1.a',
          text: 'We have identified source(s) for threat information.',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.b',
          practice: '.TVM.1.b',
          text:
            'We gather threat information and review it for applicability to our organization.',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.c',
          practice: '.TVM.1.c',
          text:
            'We address important threats (including strengthening our security protections, increasing monitoring activities, and/or raising awareness throughout the organization).',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.d',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '5',
      title: 'Threat Management',
      text: [
        'Wholly distinct from vulnerabilities, cybersecurity threats are adversaries with some capability and motive to affect an organization through cyber means. Cybersecurity threats can also be events that would cause harm to the organization.',
        'A common way to describe the relationship is that threats (such as hackers) use vulnerabilities (such as system weaknesses) to attack organizations. Threat management activities include being aware of threats that are focusing on your sector, your region, or specific types of assets that you have. They might also include monitoring of recent events and analysis of how an event could be applicable.',
      ],
      prompt:
        'Please select all the responses that best describe your current cybersecurity threat management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '6.a',
          practice: '.RM.2.a',
          text:
            'We identify cybersecurity risks as they apply to our organization.',
          exclusive: false,
          level: 3,
        },
        {
          number: '6.b',
          practice: '.RM.2.b',
          text: 'We mitigate, accept, or transfer those identified risks.',
          exclusive: false,
          level: 3,
        },
        {
          number: '6.c',
          practice: undefined,
          exclusive: true,
          text: 'We do not currently identify or address cybersecurity risks.',
          level: 3,
        },
      ],
      number: '6',
      title: 'Cyber Risk Management',
      text: [
        "Cybersecurity risk is the potential harm to operations that could arise from unauthorized disclosure of the organization's information, misuse of its information, IT, or OT systems, and other cyber perils. Dependence on technology has resulted in an increase in cybersecurity risk.",
        'Risk is often viewed as the product of vulnerabilities, threats, and impacts. Vulnerability assessments are informative for analyzing weaknesses, but if there is no threat that can exploit the weakness, or if there is no way to have negative impacts due to that weakness, it may not be a notable risk. Identification of risks is a high-level governance discussion that combines technical knowledge with operational impacts knowledge.',
      ],
      prompt:
        'Please select the response(s) that most closely represent how your organization currently manages cybersecurity risk. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '7.a',
          practice: '.IR.1.a',
          text:
            'We have a point of contact to whom cybersecurity events can be reported. (This might be an IT help desk or any designated person or role.)',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.b',
          practice: '.IR.1.b',
          text: 'We report detected cybersecurity events.',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.c',
          practice: '.IR.1.c',
          text: 'We log and track detected cybersecurity events.',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.d',
          practice: '.IR.2.a',
          text:
            'We have criteria to determine whether cybersecurity events should be declared to be incidents.',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.e',
          practice: '.IR.2.b',
          text:
            'We analyze cybersecurity events to determine whether they should be declared to be incidents.',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.f',
          practice: undefined,
          text: 'We do not have cybersecurity event detection in place.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '7',
      title: 'Cyber Event Detection',
      text: [
        "A cybersecurity event is any occurrence that has a potential impact to the cybersecurity of the organization's IT or OT systems. Such events are often relatively minor (e.g., forgotten passwords), but can be escalating (e.g., an increasing number of users are unable to log in), or major (e.g., a network outage is preventing communications to remote assets). Detecting cybersecurity events requires knowledge of IT and OT assets and systems, as well as defined roles and capabilities to track events.",
        'Events that have the potential to significantly impact the organization are declared to be incidents and require a response to minimize the impact to operations or restore functionality.',
      ],
      prompt:
        'Please select the response(s) that best describe your cybersecurity event detection activities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '8.a',
          practice: '.IR.2.c',
          text: 'We log cybersecurity incidents and track any progress.',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.b',
          practice: '.IR.3.a',
          text:
            'We have a cybersecurity incident response team (or teams, or a designated person/role).',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.c',
          practice: '.IR.3.b',
          text:
            'We can respond to cybersecurity incidents to limit the impact and restore normal operations.',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.d',
          practice: '.IR.3.c',
          text:
            'We report (or would report) cybersecurity incidents internally and/or to appropriate external trusted parties such as a relevant ISAC.',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.e',
          practice: undefined,
          text:
            'We do not have cybersecurity incident response capabilities in place.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '8',
      title: 'Cyber Incident Response',
      text:
        'As previously mentioned, cybersecurity events can escalate to cybersecurity incidents. Recall that incidents have the potential to significantly impact the organization and require a response to minimize the impact to operations or restore functionality. Incident response capabilities require preplanning and knowledge of both security and engineering/operations.',
      prompt:
        'Please select the response(s) that best describe your cybersecurity incident response activities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '9.a',
          practice: '.IR.4.a',
          text: 'We know what we need to do to sustain minimal operations.',
          exclusive: false,
          level: 3,
        },
        {
          number: '9.b',
          practice: '.IR.4.b',
          text:
            'We know the sequence of implementing the IT and OT activities needed to return to normal operations.',
          exclusive: false,
          level: 3,
        },
        {
          number: '9.c',
          practice: '.IR.4.c',
          text:
            'We have developed continuity plans for how to sustain and restore operations.',
          exclusive: false,
          level: 3,
        },
        {
          number: '9.d',
          practice: undefined,
          text: 'We do not perform activities for continuity planning.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '9',
      title: 'Operational Resiliency',
      text:
        'Part of responding to a cybersecurity incident or other disruptive events is sustaining at least minimal operations while returning to normal operations. Doing so requires knowledge of business impacts and the systems needed to sustain minimal operations.',
      prompt:
        'Please select the response(s) that best describe your capabilities for sustaining and restoring operations. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '10.a',
          practice: '.SA.1.a',
          text:
            'We have enabled logging on assets that are needed for critical operations where possible.',
          exclusive: false,
          level: 3,
        },
        {
          number: '10.b',
          practice: '.SA.2.a',
          text:
            'We review our logs periodically, at least manually, or have a third party monitor and notify us.',
          exclusive: false,
          level: 3,
        },
        {
          number: '10.c',
          practice: '.SA.2.b',
          text:
            'We monitor our operations for irregular or anomalous activity as indicators of a cybersecurity incident.',
          exclusive: false,
          level: 3,
        },
        {
          number: '10.d',
          practice: undefined,
          text:
            'We do not perform logging or monitoring on our assets or equipment.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '10',
      title: 'Monitoring Cyber System Activity',
      text:
        'Logging and monitoring of IT and OT systems is a vital capability for detecting cyber events or incidents and for capturing information that can be used to analyze an event or incident. These capabilities, however, may not be possible for certain pieces of equipment based on your IT and OT environment.',
      prompt:
        'Please select the response(s) that best describe your security event logging and monitoring capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '11.a',
          practice: '.ISC.1.a',
          text:
            'We collect and provide cybersecurity information from/to selected organizations, trade associations, ISACs, or trusted individuals.',
          exclusive: false,
          level: 3,
        },
        {
          number: '11.b',
          practice: '.ISC.1.b',
          text:
            'We have assigned the responsibility for sharing cybersecurity information to designated personnel within our organization.',
          exclusive: false,
          level: 3,
        },
        {
          number: '11.c',
          practice: undefined,
          text: 'We do not share or collect cybersecurity information.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '11',
      title: 'Cyber Threat and Event Information Sharing',
      text:
        'Information sharing is a two-way flow of information between organizations, either directly or through a trusted third party (e.g. a trade association or ISAC). During a cyber event, you may use information sharing practices to inform other organizations so that they can be on alert for similar events. Your organization may benefit from information sharing by being alerted of escalating cybersecurity events or threat conditions in your sector.',
      prompt:
        'Please select the response(s) that best describe your information sharing capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '12.a',
          practice: '.EDM.1.b',
          text:
            'We know who our important customers are (e.g., hospitals, major industrial or commercial customers, customers with digital connectivity).',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.b',
          practice: '.EDM.1.a',
          text:
            'We know who our important suppliers are, including service providers and integrators, hardware manufacturers, software developers, and other roles important to our operations.',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.c',
          practice: '.EDM.2.a',
          text:
            'We identify and address significant cybersecurity risks from our supply chain and customer dependencies, including an analysis of how a cyber event at one of them could impact our operations, or an analysis of the "weakest links" in our supply chain.',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.d',
          practice: '.EDM.2.b',
          text:
            'We establish cybersecurity requirements when entering into relationships with new suppliers or other parties.',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.e',
          practice: undefined,
          text: 'We do not track supply chain or customer dependency risk.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '12',
      title: 'Supply Chain Risk',
      text:
        'Supply chain risk is an increasing concern for most organizations. Supply chain refers to how assets, systems, software, services, and materials are procured. This includes the purchase of new laptops, industrial control system equipment, consulting or maintenance services, raw materials, and even power. Each organization is also part of the supply chain and should identify its role as a supplier to others, especially in any cases where customer data or digital connectivity to a customer can pose risk to the organization or to the customer.',
      prompt:
        "Please select the response(s) that best describe your organization's supply chain risk management activities. Keep in mind that the activities may be performed in an ad hoc manner.",
    },
    {
      responses: [
        {
          number: '13.a',
          practice: '.WM.1.a',
          text:
            'We have identified cybersecurity responsibilities needed for our organization.',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.b',
          practice: '.WM.1.b',
          text:
            'We have assigned cybersecurity responsibilities to specific people.',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.c',
          practice: '.WM.3.a',
          text:
            'We provide cybersecurity training to the employees and contractors with cybersecurity responsibilities.',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.d',
          practice: '.WM.4.a',
          text:
            'We have cybersecurity awareness activities for all employees and contractors.',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.e',
          practice: '.WM.2.a',
          text:
            'We perform personnel vetting, such as background checks and drug tests, when we hire employees and contractors who will have access to assets that support critical operations.',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.f',
          practice: '.WM.2.b',
          text:
            'We address cybersecurity concerns during our employee terminations, such as removing physical and electronic access.',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.g',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '13',
      title: 'Workforce Management and Cybersecurity Training',
      text:
        'Despite all the technical discussions surrounding cybersecurity, employees and contractors represent some of the biggest risks to a security program. How workforce and cybersecurity training are managed will have a large impact on organizational security.',
      prompt:
        'Please select the response(s) that best describe your cybersecurity workforce capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '14.a',
          practice: '.CPM.1.a',
          text: 'We have a strategy for our cybersecurity program.',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.b',
          practice: '.CPM.2.a',
          text:
            'We have resources (people, funding, and tools) for our cybersecurity program.',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.c',
          practice: '.CPM.2.b',
          text:
            'We have senior management sponsorship for our cybersecurity program.',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.d',
          practice: '.CPM.3.a',
          text:
            'We have a strategy to keep our IT systems isolated from our OT control systems.',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.e',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
      number: '14',
      title: 'Cybersecurity Program Management',
      text:
        'A cybersecurity program is a managed set of activities designed to provide governance for the organization. Such a program typically includes objectives for improving cybersecurity over time and a foundational strategy for managing cybersecurity and would provide leadership and resources for cybersecurity activities.',
      prompt:
        'Please select the response(s) that best describe your cybersecurity program capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
  ],
};
