import AuthBackground from 'common/themes/appa/APPALoginImage.jpg';
import defaultTheme from './default';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';

export const APPA_BLUE = '#0c2e58';
export const APPA_RED = '#dd2335';
export const APPA_MED_YELLOW = '#fda912';
export const APPA_EMPHASIS_BLUE = '#3d6daa';

const LOGO_WIDTH = 240;

export default {
  ...defaultTheme, // Default overall Theme
  poweredBy: true,
  font: 'Raleway',
  logoWidth: LOGO_WIDTH + 'px',
  authBackground: AuthBackground,
  models: { [LegacyModelIdEnum.C2M2]: LegacyModelIdEnum.C2M2 },
  modelScopes: ModelScope,
  c2m2ExcelImport: true,
  hasOwnIntroScreen: true,
  poweredByBackground: true,
  colors: {
    ...defaultTheme.colors,
    headerBackground: 'transparent',
    headerText: 'black',
    nameplate: APPA_BLUE,
    qlNavButtonBackground: APPA_RED,
    qlBackground: APPA_BLUE,
    tocExpand: APPA_EMPHASIS_BLUE,
    primaryButton: APPA_RED,
    primaryButtonText: 'white',
    avatarColor: APPA_BLUE,
    avatarTextColor: 'white',
    avatarNameplateColor: APPA_BLUE,
    tocCollapseColor: APPA_MED_YELLOW,
    taglineColor: APPA_EMPHASIS_BLUE,
    tocBackgroundColor: APPA_BLUE,
  },
  hasIntroTour: false,
};
