import { fullAssessmentIcon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_c2m2v2';

export const c2m2v2Config: LegacyModelConfig = {
  icon: fullAssessmentIcon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: true,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
    deprecated: {
      effectiveDate: new Date(2023, 6, 1), // July 1st, 2023 @ 12 a.m.
      suggestedVersion: 'C2M2 v2.1',
    },
  },
  scoring: {
    useDropout: true,
    topOfDomain: 100,
  },
  factories: [
    {
      label: 'C2M2 Version 2 Assessment',
      filterLabel: 'C2M2 Version 2',
      icon: fullAssessmentIcon,
      sortKey: 'C2M2v2',
      modelUuid: LegacyModelIdEnum.C2M2V2,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.C2M2V2_FULL,
    },
  ],
  terms,
};
