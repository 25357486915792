import React from 'react';
import { QueryResult } from '@apollo/client';
import { Map } from 'immutable';
import { pick as _pick } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import { IUser } from 'common/databaseTypes';
import {
  processReportingError,
  sendReportToServer,
} from 'common/utils/reporting';
import { ReportingServer, ReportType } from 'common/reducers/reporting';
import { getUserJS } from 'common/selectors/user';
import { graphqlServerPath, hashFnv32a } from 'common/utils/AxioUtilities';
import { safeParseJson } from 'common/utils/jsonUtils';
import { getIdToken } from 'common/utils/userContext';
import { IRootState } from '../../../reducers';
import { getTheme } from '../../../themes';
import { ILink, IResponse, ModelScope } from '../../databaseTypes';
import { csfLevelsFromC2m2, score as modelScore } from '../../score/score';
import { IModelPractice } from '../../reducers/models/model';
import {
  getAllCompanyBenchmark,
  getAllLinks,
  getCoworkers,
  getPeerBenchmark,
  getResponses,
} from '../../selectors';
import {
  getOwnerInfo,
  getReactTagsFromAssessment,
  getSelectedAssessment,
} from '../../selectors/assessment';
import { getModel } from '../../selectors/model';
import { getSortedByDateMilestonesForAssessment } from '../../selectors/milestone';
import { getReferenceAssessmentResponsesKeyedByAssessment } from '../../selectors/responses';
import { denormalizePractices } from '../../utils/normalizePractices';
import {
  domainInfo,
  scoreAndInfo,
} from '../../views/Dashboard/DashboardMain/shared/dataProcessing';
import { getArchivedNoteStatus } from '../../selectors/app';
import {
  GetAssessmentDataQuery,
  GetAssessmentDataQueryVariables,
  LegacyModelIdEnum,
} from 'common/graphql/graphql-hooks';
import Logger from 'common/utils/Logger';
import { getReportMapping } from './reportMapper';

type Dispatch = ThunkDispatch<IRootState, void, AnyAction>;

interface IResultScopeTag {
  name: string;
}

interface IResultAssessmentsObject {
  id: string;
  name: string;
  modelUuid: string;
  modelScope: ModelScope;
  dateCreated: string;
  dateLastUpdated: string;
  owner: IUser;
  scopetags?: [IResultScopeTag];
  responses?: [IResponse];
}

const axioConvertToHtml = convertToHTML({
  blockToHTML: (block) => {
    if (block.type === 'code-block') {
      return <code />;
    }
    if (block.type === 'section') {
      return <div />;
    }
    if (block.type === 'unstyled') {
      return (block.text?.trim() ?? '') !== '' ? (
        <p style={{ padding: '0', margin: '0' }} />
      ) : (
        <p style={{ padding: '0', margin: '0 0 1em 0' }} />
      );
    }
    return undefined;
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'mention') {
      return (
        <span
          style={{
            display: 'inline-block',
            background: '#6ab6f6',
            paddingLeft: '3px',
            paddingRight: '3px',
            borderRadius: '2px',
          }}
        >
          {entity.data.name}
        </span>
      );
    }
    return originalText;
  },
});

export const getOwnerName = (assessment: IResultAssessmentsObject) => {
  return assessment.owner && assessment.owner.name ? assessment.owner.name : '';
};

export const getOwnerEmail = (assessment: IResultAssessmentsObject) => {
  return assessment.owner && assessment.owner.email
    ? assessment.owner.email
    : '';
};

export const getCompany = (assessment: IResultAssessmentsObject) => {
  return assessment.owner && assessment.owner.employer
    ? assessment.owner.employer.name
    : '';
};

export const getUserName = (user: IUser) => {
  return user.name
    ? user.name
    : user.displayName
    ? user.displayName
    : user.email;
};

export const getResponseFilter = (
  modelUuid: string,
  modelScope: ModelScope
) => {
  return modelScope === ModelScope.Quicklaunch
    ? (p: IModelPractice) => p.mil === 1
    : null;
};

export const getOwner = (
  assessment: IResultAssessmentsObject,
  ownerName: string,
  ownerEmail: string
) => {
  return assessment.owner
    ? {
        name: ownerName,
        email: ownerEmail,
      }
    : {
        name: '',
        email: '',
      };
};

export const processData = (
  results: any,
  modelUuid: string,
  modelScope: ModelScope,
  convertedC2m2 = false
) => {
  const { data, benchmarks, model } = results;
  const { assessment, user, peerBenchmark } = data;

  const hash = hashFnv32a(data);
  const convertedModelUuid = convertedC2m2 ? LegacyModelIdEnum.Csf : modelUuid;
  const report = getReportMapping({ model: convertedModelUuid, modelScope });

  const scope = assessment.name;
  const scopetags = assessment.scopetags.map((tag) => {
    return tag.name;
  });
  const now = new Date();
  const ownerName = getOwnerName(assessment);
  const ownerEmail = getOwnerEmail(assessment);
  const owner = getOwner(assessment, ownerName, ownerEmail);
  const company = getCompany(assessment);

  const userTimezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userName = getUserName(user);

  const responseMap = Map<string, IResponse>(assessment.responses);

  const processedResponses = convertResponseIfNecessary(
    responseMap,
    convertedC2m2
  );

  const derivation = processedResponses.derivation;
  const responses = processedResponses.responses;
  const responseFilter = getResponseFilter(modelUuid, modelScope);
  const scores = !model
    ? modelScore(model, undefined, null, undefined, null, null)
    : modelScore(
        model,
        //@ts-expect-error modelScore causes issues app-wide
        responses,
        assessment?.modelScope,
        'level',
        responseFilter,
        null
      );
  const targetScores = !model
    ? { score: 0, domains: {} }
    : modelScore(
        model,
        //@ts-expect-error modelScore causes issues app-wide
        responses,
        assessment?.modelScope,
        'targetLevel',
        responseFilter,
        null
      );

  const milScores = !model
    ? { mil1: {}, mil2: {}, mil3: {} }
    : [1, 2, 3].reduce(
        (accum, mil) => {
          accum[`mil${mil}`] = modelScore(
            model,
            //@ts-expect-error modelScore causes issues app-wide
            responses,
            assessment?.modelScope,
            'level',
            (p) => p.mil === mil,
            null
          );
          return accum;
        },
        { mil1: {}, mil2: {}, mil3: {} }
      );

  const themeData = getTheme();

  const milestonesArray = assessment.milestones;
  const milestonesWithResponses = milestonesArray.map((milestone) => {
    if (!milestone.responses) {
      return milestone;
    }
    const milestoneScoreData = scoreAndInfo(
      model,
      milestone.responses,
      assessment?.modelScope,
      'level',
      milestone.milestone_date,
      null
    );

    return {
      ...milestone,
      scores: milestoneScoreData,
    };
  });

  return {
    hash,
    data: {
      report,
      date: now.toISOString(),
      scope,
      derivation,
      scopetags,
      benchmarks,
      peerBenchmark,
      score: scores.score,
      milScores,
      milestones: milestonesWithResponses,
      targetScore: targetScores.score,
      targetDomainScores: targetScores.domains,
      domainScores: scores.domains,
      owner,
      company,
      userTimezone,
      userName,
      responses: responses.toObject(),
      theme: { name: themeData.name, clientNameLong: themeData.clientNameLong },
      dateCreated: assessment.dateCreated,
      dateLastUpdated: assessment.dateLastUpdated,
      preparedFor: assessment.preparedFor ?? '',
      milLevel: assessment.milLevel,
    },
  };
};

export const getAssessmentDataResults = async (state: IRootState) => {
  const assessment = getSelectedAssessment(state);
  if (!assessment) {
    throw new Error('No active assessment');
  }

  const model = getModel(state);
  const cognitoIdToken = await getIdToken();
  const assessmentId = assessment._id;
  const response = await fetch(graphqlServerPath(), {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Request-Headers': 'Location, X-Request-URL',
      Authorization: cognitoIdToken || '',
    },
    method: 'POST',
    body: JSON.stringify({
      operationName: 'GetAssessmentData',
      // Must match the query defined in the following file:
      // src/assessments/graphql/queries/GetAssessmentData.graphql
      query: `
          query GetAssessmentData($id: ID!) {
            assessment(id: $id) {
              id
              responses {
                id
                practiceId
                actionItems {
                  id
                  assignee {
                    __typename
                    ... on User {
                      id
                      name
                      email
                    }
                    ... on UserGroup {
                      id
                      name
                      email: name
                    }
                  }
                  author {
                    id
                    name
                    email
                  }
                  dueDate
                  isDone
                  text
                  dateLastUpdated
                }
                notes {
                  id
                  text
                  richText
                  author {
                    id
                    name
                    email
                  }
                  dateLastUpdated
                  tags {
                    id
                    type
                    name
                  }
                }
              }
            }
          }
        `,
      variables: {
        id: assessmentId,
      },
    }),
  });
  if (!response.ok) {
    throw new Error(
      `Error fetching report data - status ${response.statusText}`
    );
  }
  const {
    data,
    error,
  }: QueryResult<
    GetAssessmentDataQuery,
    GetAssessmentDataQueryVariables
  > = await response.json();
  if (error) {
    throw error;
  }
  return mergeResults(model, data, state);
};

const addAuthor = (
  items: Map<string, ILink>,
  coworkers: Map<string, IUser>
) => {
  return items.map((item) => ({
    ...item,
    author: _pick(coworkers.get(item.author), ['name', 'email']),
  }));
};

const filterResponsesByMILLevel = (domains, responses, milLevel) => {
  const filteredResponses = {};
  Object.values(responses).forEach((res: any) => {
    // All practice ids start with . e.g. .AC.C001.001
    const [dName, oName, pName] = res._practice_id.substring(1).split('.');
    const domain = domains.find((d) => d.name === dName);
    const obj = domain?.objectives.find((o) => o.name === oName);
    const practice = obj?.practices.find((p) => p.name === pName);
    if (practice?.mil <= milLevel) filteredResponses[res._practice_id] = res;
  });
  return filteredResponses;
};

const mergeResults = (
  model: ReturnType<typeof getModel>,
  assessmentData: GetAssessmentDataQuery | undefined,
  state: IRootState
) => {
  if (!assessmentData?.assessment) {
    Logger.error('mergeResults: No assessment data found');
  }

  const showArchivedNotes = getArchivedNoteStatus(state);
  const user = _pick(getUserJS(state), ['_id', 'id', 'displayName', 'email']);
  const assessment = getSelectedAssessment(state);
  const owner = _pick(getOwnerInfo(state), [
    '_id',
    'id',
    'name',
    'email',
    'displayName',
    'employer.name',
  ]);
  const scopetags = getReactTagsFromAssessment(state)?.map((tag) => ({
    name: tag.name,
  }));
  const benchmarks = getAllCompanyBenchmark(state);
  const peerBenchmark = getPeerBenchmark(state);
  const responses = getResponses(state);
  const allLinks = getAllLinks(state);
  const coworkers = getCoworkers(state);

  const keyedResponses =
    assessmentData?.assessment?.responses.reduce((accum, item) => {
      const actionItems = item.actionItems.reduce(
        (transformedActionItems, actionItem) => {
          const timestamp = actionItem.dateLastUpdated;
          const _id = actionItem.id;
          const _responsePracticeId = item.practiceId;
          transformedActionItems[actionItem.id] = {
            timestamp,
            _id,
            _responsePracticeId,
            ...actionItem,
          };
          return transformedActionItems;
        },
        {}
      );

      const notes = item.notes.reduce((obj, note) => {
        const noteHasArchivedTag = note.tags.some(
          (tag) => tag.name === 'Archived'
        );
        if (noteHasArchivedTag && !showArchivedNotes) return {};

        const timestamp = note.dateLastUpdated;
        const _id = note.id;
        const _responsePracticeId = item.practiceId;
        const htmlNote = note.richText
          ? axioConvertToHtml(convertFromRaw(safeParseJson(note.richText)))
          : '';

        // We have to send text and html text because the old latex reports
        // blow up without it.   Ultimately, once the latex reports are
        // junked, we can just deal with rich.
        const textNote = note.text;
        obj[note.id] = {
          timestamp,
          _id,
          _responsePracticeId,
          ...note,
          richText: htmlNote,
          text: textNote,
        };
        return obj;
      }, {});
      accum[item.practiceId] = { ...item, actionItems, notes };
      return accum;
    }, {}) ?? {};

  const responseKeys = responses
    .keySeq()
    .toArray()
    .reduce((accum, key) => {
      accum[key] = key;
      return accum;
    }, {});

  const mergedResponses = responses.map((resp) => {
    resp['actionItems'] =
      (keyedResponses[resp._practice_id] || { actionItems: [] }).actionItems ||
      [];
    resp['notes'] =
      (keyedResponses[resp._practice_id] || { notes: [] }).notes || [];
    return resp;
  });

  let responsesWithAuthors = denormalizePractices(responseKeys, {
    links: addAuthor(allLinks, coworkers).toJS(),
    responses: mergedResponses.toJS(),
  });

  // If assessment has mil scoping, only return responses that are within its mil level
  const modelScope = assessment?.modelScope;
  let milLevel = 5;
  if (model && modelScope?.startsWith('MIL')) {
    milLevel = parseInt(modelScope[modelScope.length - 1]);
    responsesWithAuthors = filterResponsesByMILLevel(
      model.data.domains,
      responsesWithAuthors,
      milLevel
    );
  }
  const info = model && domainInfo(model);
  const milestones = getSortedByDateMilestonesForAssessment(state);

  const data = {
    user,
    assessment: {
      modelUuid: assessment?.model,
      dateCreated: assessment?._created,
      dateLastUpdated: assessment?._updated,
      ...assessmentData?.assessment,
      ...assessment,
      responses: responsesWithAuthors,
      owner,
      scopetags,
      milestones,
      milLevel,
    },
    info,
    peerBenchmark,
  };

  return {
    data,
    benchmarks,
    model,
  };
};

export const getReportType = (
  modelUuid: string,
  reportStyle: 'full' | 'quicklaunch' | 'comparison'
) => `${modelUuid.toLowerCase()}-${reportStyle}` as ReportType;

const convertResponseIfNecessary = (
  responsesMap: Map<string, IResponse>,
  convertC2m2: boolean
) => {
  return !convertC2m2
    ? { responses: responsesMap, derivation: LegacyModelIdEnum.Csf }
    : {
        responses: csfLevelsFromC2m2(responsesMap),
        derivation: LegacyModelIdEnum.C2M2,
      };
};

export const getComparisonReport = (theme: string, modelUuid: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const reportType = getReportType(modelUuid, 'comparison');
  try {
    const fetchResults = await getAssessmentDataResults(getState());

    const { data } = processData(fetchResults, modelUuid, ModelScope.Full);
    if (!data) {
      processReportingError(new Error('No report data'), dispatch);
      return;
    }

    const referenceAssessmentResponses = getReferenceAssessmentResponsesKeyedByAssessment(
      getState()
    );

    const data2 = {
      date: new Date().toISOString(),
      assessments: [data, ...referenceAssessmentResponses],
    };

    const hash = hashFnv32a(data2);

    sendReportToServer(hash, data2, reportType, theme, dispatch, getState);
  } catch (err: any) {
    processReportingError(err, dispatch);
  }
};

const internalReport = async (
  theme: string,
  modelUuid: string,
  modelScope: 'full' | 'quicklaunch',
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const reportType = getReportType(modelUuid, modelScope);
  try {
    const fetchResults = await getAssessmentDataResults(getState());
    const { data, hash } = processData(
      fetchResults,
      modelUuid,
      ModelScope.Full
    );

    if (!data) {
      processReportingError(new Error('No report data'), dispatch);
      return;
    }
    sendReportToServer(hash, data, reportType, theme, dispatch, getState);
  } catch (err: any) {
    processReportingError(err, dispatch);
  }
};

export const getLegacyQLReport = (theme: string, modelUuid: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => internalReport(theme, modelUuid, 'quicklaunch', dispatch, getState);

export const getFullReport = (theme: string, modelUuid: string) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => internalReport(theme, modelUuid, 'full', dispatch, getState);

export const openHtmlReportDialog = (
  theme: string,
  modelUuid: string,
  argReportType: 'full' | 'quicklaunch' | 'comparison'
) => (dispatch: Dispatch) => {
  const reportType = getReportType(modelUuid, argReportType);
  dispatch({
    type: 'REPORTING_FETCH_START',
    hash: '',
    reportType,
    server: ReportingServer.HTML,
    theme,
  });
};
