// This file holds the theme elements that are common across all modules.
import newClientLogo from './Axio360-ca.svg';
import clientLogo from './logo-axio360-ca.svg';

import { defaultTheme } from '../default';

export const canadaTheme = {
  ...defaultTheme,
  authPoweredByLogo: clientLogo,
  poweredByLogo: newClientLogo,
  authLogo: newClientLogo,
  clientLogo,
};
