import numbro from 'numbro';
import React, { useEffect, useState, useRef } from 'react';

import { formatNumber } from 'common/utils/AxioUtilities';
import Logger from 'common/utils/Logger';
import { IInputGroupProps, IIntentProps } from '@blueprintjs/core';

const constrainedValueFn = (
  value?: number | string | null,
  min?: number | null,
  max?: number | null
) => {
  if ((value ?? undefined) === undefined) {
    return undefined;
  }
  const valueAsNumber = Number(value);
  if (Number.isNaN(valueAsNumber)) {
    return undefined;
  }
  if (typeof min === 'number' && valueAsNumber < min) {
    return min;
  }
  if (typeof max === 'number' && valueAsNumber > max) {
    return max;
  }
  return valueAsNumber;
};

interface IProps
  extends IIntentProps,
    Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'min' | 'max' | 'defaultValue' | 'value' | 'onBlur' | 'onChange'
    > {
  inputComponent?:
    | React.ComponentType<any>
    | React.ComponentType<IInputGroupProps>;
  hasError?: boolean;
  defaultValue?: number;
  value?: number | null;
  min?: number;
  max?: number;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  formatNumber?: typeof formatNumber;
  onBlur?: (
    value: number | undefined,
    event: React.FocusEvent<HTMLInputElement>
  ) => void;
  onChange?: (
    value: number | undefined,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const NumberInput = React.memo((props: IProps) => {
  const formatNumberFn = props.formatNumber || formatNumber;
  const initialValue = constrainedValueFn(
    typeof props.value === 'number' ? props.value : props.defaultValue,
    props.min,
    props.max
  );
  const [isFocused, setFocused] = useState(false);
  const resetSelectionRef = useRef(false);
  const focusWasMouse = useRef(false);
  const [value, setValue] = useState<number | undefined>(initialValue);
  const [displayValue, setDisplayValue] = useState<string>(
    typeof initialValue === 'number' ? formatNumberFn(initialValue) : ''
  );
  const { min, max } = props;
  if ('value' in props && !('onChange' in props)) {
    Logger.error(
      'Passed value without onChange handler. You probably meant to pass "defaultValue" instead.'
    );
  }

  useEffect(() => {
    const newValue = constrainedValueFn(props.value, props.min, props.max);
    if (!isFocused && typeof newValue === 'number' && newValue !== value) {
      setValue(newValue);
      setDisplayValue(formatNumberFn(newValue));
    }
    if (
      isFocused &&
      value === props.value &&
      resetSelectionRef?.current &&
      !focusWasMouse.current
    ) {
      // We just got tabbed into and our value changed so we need
      // to reset the selection to the new value's length.
      const possibleInput = window.getSelection()?.focusNode?.firstChild;
      if (possibleInput?.nodeName === 'INPUT') {
        (possibleInput as HTMLInputElement)?.select();
      }
    }
    resetSelectionRef.current = false;
    focusWasMouse.current = false;
  }, [isFocused, value, props.value, props.min, props.max, formatNumberFn]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if ('persist' in event) {
      event.persist();
    }
    setFocused(false);
    const parsedValue = numbro(value).valueOf();
    const constrainedValue = constrainedValueFn(parsedValue, min, max);
    if (constrainedValue !== undefined) {
      setDisplayValue(formatNumberFn(constrainedValue));
    }
    if (props.onBlur) {
      props.onBlur(constrainedValue, event);
    }
  };

  const onMouseDown = (event: React.MouseEvent) => {
    focusWasMouse.current = true;
    resetSelectionRef.current = false;
  };
  const onMouseUp = (event: React.MouseEvent) => {
    focusWasMouse.current = false;
    resetSelectionRef.current = false;
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if ('persist' in event) {
      event.persist();
    }
    setFocused(true);
    if ((value ?? undefined) !== undefined) {
      resetSelectionRef.current = true;
      setDisplayValue((value ?? '').toString());
    }
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ('persist' in event) {
      event.persist();
    }
    const parsedValue = numbro(event.target.value.toLowerCase()).valueOf();
    const constrainedValue = constrainedValueFn(parsedValue, min, max);
    setDisplayValue(event.target.value);
    setValue(constrainedValue);
    if (props.onChange) {
      if (typeof constrainedValue !== 'number' && event.target.value) {
        // raw value was not parse-able
        return;
      }
      props.onChange(constrainedValue, event);
    }
  };

  const {
    defaultValue: _omittedDefaultValue,
    formatNumber: _omittedFormatter,
    hasError,
    placeholder: rawPlaceholder,
    inputComponent,
    inputRef,
    intent,
    ...rest
  } = props;
  const isErrorClassName =
    value === undefined && displayValue !== '' ? 'error ' : '';
  const InputToUse = inputComponent || 'input';
  const refProp = inputComponent ? { inputRef: inputRef } : { ref: inputRef };
  const intentProp = inputComponent
    ? { intent: hasError ? 'danger' : intent ?? 'none' }
    : {};
  const placeholder = formatNumberFn(rawPlaceholder);
  return (
    <InputToUse
      {...rest}
      {...intentProp}
      {...refProp}
      placeholder={
        ['NaN', '0'].includes(placeholder) ? rawPlaceholder : placeholder
      }
      className={isErrorClassName.concat(rest.className || '')}
      value={displayValue}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    />
  );
});
