export default {
  questions: [
    {
      responses: [
        {
          number: '1.a',
          text:
            'Critical infrastructure institution under section 9 of Executive Order 13636',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.b',
          text: 'Global Systemically Important Bank (G-SIB)',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.c',
          text: 'Global Systemically Important Insurer (G-SII)',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.d',
          text:
            'Non-Bank Non-Insurer Global Systemically Important Financial Institution (NBNI G-SIFI)',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.e',
          text: 'Designated Financial Markets Utility (e.g., SIFMU’s, D-FMUs)',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.f',
          text: 'Multi-Regional Data Processing Servicer (MDPS)',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.g',
          text: 'Domestically Systemic Important Bank (D-SIB)',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.h',
          text: 'Regional Technology Service Provider (TSP)',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.i',
          text: 'Core clearing and settlement institution',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.j',
          text:
            'Financial institution that plays a significant role in critical financial markets',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '1.k',
          text: 'None of the above.',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 0,
      title: 'Question 1.1',
      text:
        'North American governments (the United States, in particular [“USG”]) have designated various Financial Services Sector institutions as critical and systemically important by way of several different designations through various different regulators and regulatory bodies. These designations imply a high level of systemic importance and therefore result in the alignment of designated institutions to the highest impact tier:',
      prompt:
        'Check the Box(es) that apply: Is your institution designated as systemically important to the Financial Services Sector under one of the following designations?',
    },
    {
      responses: [
        {
          number: '2.a',
          text: 'Federal Funds',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.b',
          text: 'Foreign Exchange',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.c',
          text: 'Commercial Paper',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.d',
          text: 'U.S. Government Securities',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.e',
          text: 'U.S. Agency Securities',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.f',
          text: 'Corporate Debt',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.g',
          text: 'Corporate Equity Securities',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.h',
          text: 'Derivatives',
          tier: 1,
          type: 'checkbox',
        },
        {
          number: '2.i',
          text: 'None of the above.',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 1,
      title: 'Question 1.2',
      text:
        'North American governments (the United States, in particular [“USG”]) have designated various Financial Services Sector institutions as critical and systemically important by way of several different designations through various different regulators and regulatory bodies. These designations imply a high level of systemic importance and therefore result in the alignment of designated institutions to the highest impact tier: ',
      prompt:
        'Does your institution consistently participate in (e.g., clear or settle) at least five percent of the value of transactions in a critical market? Check all that apply.',
    },
    {
      responses: [
        {
          number: '3.a',
          text:
            'Failure of your institution’s ability to meet its payment or settlement obligations, which could trigger a market disruption where other financial institutions might not be able to meet their settlement obligations; e.g., materially constraining business customers’ ability to maintain adequate liquidity.',
          tier: 2,
          type: 'checkbox',
        },
        {
          number: '3.b',
          text:
            'Failure or severe or prolonged disruption of a core payment and settlement system, which can be compromised at various endpoints, affecting multiple country and locations’ securities markets',
          tier: 2,
          type: 'checkbox',
        },
        {
          number: '3.c',
          text:
            'The loss or compromise of the availability and integrity of key financial data critical to the delivery of a product or service at other institutions.',
          tier: 2,
          type: 'checkbox',
        },
        {
          number: '3.d',
          text:
            'Widespread loss of trust and confidence in the payment and settlement systems.',
          tier: 2,
          type: 'checkbox',
        },
        {
          number: '3.e',
          text: 'None of the above.',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 2,
      title: 'Question 2.1',
      text:
        'The Financial Services Sector is characterized by a high degree of interconnectedness with certain institutions acting as key nodes within a complex network. The nature of services which institutions provide to others in the sector plays a significant role in determining the institution’s criticality. Specifically, those providing services critical to another institution’s operations represent a significant level of inherent risk, and thus, align to the second highest impact tier: ',
      prompt:
        'Does your institution provide a product or service to businesses in other industries or to other institutions in the financial services (FS) industry for which the compromise of your product’s or service’s confidentiality, integrity or availability is reasonably likely to cause one or more of the following impacts?',
    },
    {
      responses: [
        {
          number: '4.a',
          text: 'Yes',
          tier: 2,
          type: 'checkbox',
        },
        {
          number: '4.b',
          text: 'No',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 3,
      title: 'Question 2.2.A',
      text:
        'Geographical and Geopolitical Considerations – Certain geographical and geopolitical factors increase the risk profile for financial services institutions. Institutions wholly or partially owned by entities located within high risk countries are exposed to geopolitical factors (e.g., instability) which raise their risk profile. Also, institutions housing IT facilities in these areas are exposed to similar risk factors which elevate their risk profile.',
      prompt:
        'Is your institution owned or partially owned by a foreign held entity located in a country of particular interest (check via link above)?',
    },
    {
      responses: [
        {
          number: '5.a',
          text: 'Yes',
          tier: 2,
          type: 'checkbox',
        },
        {
          number: '5.b',
          text: 'No',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 4,
      title: 'Question 2.2.B',
      text:
        'Geographical and Geopolitical Considerations – Certain geographical and geopolitical factors increase the risk profile for financial services institutions. Institutions wholly or partially owned by entities located within high risk countries are exposed to geopolitical factors (e.g., instability) which raise their risk profile. Also, institutions housing IT facilities in these areas are exposed to similar risk factors which elevate their risk profile.',
      prompt:
        'Does your institution have facilities located within a country of particular interest (check via link above) that allow access to your network infrastructure from that facility?',
    },
    {
      responses: [
        {
          number: '6.a',
          text: 'Yes',
          tier: 2,
          type: 'checkbox',
        },
        {
          number: '6.b',
          text: 'No',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 5,
      title: 'Question 2.3',
      text:
        'Customer and Consumer Impact – An institution’s capacity to experience a cyber event that would have a potential to cause harm to customers and consumers plays a key role in determining a given institution’s importance to the Financial Services Sector. For the purposes of this question, customers and consumers include individual customers and business customers.',
      prompt:
        'Does the number of individuals whose data your institution processes exceed 5 million?',
    },
    {
      responses: [
        {
          number: '7.a',
          text:
            'Complete unavailability of your product or service would cause the degradation of mission capability to an extent of noticeably diminishing the efficacy and effectiveness of these primary functions but would NOT impact your institution’s ability to perform its primary functions; e.g., adversely impacting business customers’ ability to maintain liquidity.',
          tier: 3,
          type: 'checkbox',
        },
        {
          number: '7.b',
          text:
            'Your product or service collects/distributes data that may noticeably compromise another institution’s ability to deliver a product or service but would NOT completely eliminate such ability.',
          tier: 3,
          type: 'checkbox',
        },
        {
          number: '7.c',
          text:
            'Significant impact on FS industry trust and confidence or delivery of critical FS services rather than to settlement/payment.',
          tier: 3,
          type: 'checkbox',
        },
        {
          number: '7.d',
          text: 'None of the above.',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 6,
      title: 'Question 3.1',
      text:
        'The Financial Services Sector is characterized by a high degree of interconnectedness with certain institutions acting as key nodes within and for the sector. The nature of services that these institutions provide to others in the sector plays a significant role in determining those institutions’ criticality. This section specifically seeks out such institutions.',
      prompt:
        'Does your institution provide a product or service to other institutions within the FS industry for which the compromise of your product’s or service’s confidentiality, integrity or availability is reasonably likely to cause one or more of the following impacts?',
    },
    {
      responses: [
        {
          number: '8.a',
          text: 'Yes',
          tier: 3,
          type: 'checkbox',
        },
        {
          number: '8.b',
          text: 'No',
          tier: undefined,
          type: 'checkbox',
        },
      ],
      number: 7,
      title: 'Question 3.2.A',
      text:
        'Customer and Consumer Impact – An institution’s capacity to experience a cyber event that would have a potential to cause harm to customers and consumers plays a key role in determining a given institution’s impact to the Financial Services Sector. For the purposes of this question, customers and consumers include individual customers and business customers.',
      prompt:
        'Do you provide products or services to financial institutions that involve customer data processing or storage?',
    },
    {
      responses: [
        {
          number: '9.a',
          text: 'Yes',
          tier: 3,
          type: 'checkbox',
        },
        {
          number: '9.b',
          text: 'No',
          tier: 4,
          type: 'checkbox',
        },
      ],
      number: 8,
      title: 'Question 3.2.B',
      text:
        'Customer and Consumer Impact – An institution’s capacity to experience a cyber event that would have a potential to cause harm to customers and consumers plays a key role in determining a given institution’s impact to the Financial Services Sector. For the purposes of this question, customers and consumers include individual customers and business customers.',
      prompt:
        'Does the number of individuals whose data your institution processes fall between 1 million and 5 million?',
    },
  ],
};
