import {
  fullAssessmentIcon,
  qlAssessmentIcon,
} from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_c2m2v2v1';
import wizardSchema from 'assessments/models/c2m2v2v1-wizard-schema';

export const c2m2v2v1Config: LegacyModelConfig = {
  icon: fullAssessmentIcon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: true,
    hasMilScoping: false,
    hasImportResponses: true,
    hasTierScoping: false,
    reports: {
      mil1: true,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    useDropout: true,
    topOfDomain: 100,
  },
  shortTitle: 'C2M2v2.1',
  factories: [
    {
      label: 'C2M2 Version 2.1 Assessment',
      filterLabel: 'C2M2 Version 2.1',
      icon: fullAssessmentIcon,
      sortKey: 'C2M2v2v1',
      modelUuid: LegacyModelIdEnum.C2M2V2V1,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.C2M2V2V1_FULL,
    },
    {
      label: 'C2M2 Version 2.1 Foundations Assessment',
      filterLabel: 'C2M2 Version 2.1 Foundations',
      requiredLicenseOption: ModelScope.Quicklaunch,
      icon: qlAssessmentIcon,
      sortKey: 'C2M2v2v1-2',
      modelUuid: LegacyModelIdEnum.C2M2V2V1,
      modelScope: ModelScope.Quicklaunch,
      requiresImporter: false,
      description:
        'The C2M2 Foundations assessment is consists of the foundational(MIL1) C2M2 practices.   These practices are presented in a Wizard view initially. This foundational assessment can be expanded to a full C2M2 assessment at a later time (license permitting).',
      dashboardType: DashboardType.C2M2_QL,
    },
  ],
  terms,
  wizardSchema,
};
