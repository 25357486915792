import { Map } from 'immutable';
import { getSingletonClient } from 'setupApolloClient';
import { scorePracticeBagFromResponses } from 'quantification/views/Scenario/ScenarioDetail/CalculateSusceptibility';
import { notEmpty } from 'common/utils/typeGuards';
import { IResponse } from '../databaseTypes';
import {
  PracticeBagsToUpdateDocument,
  PracticeBagsToUpdateQuery,
  PracticeBagsToUpdateQueryVariables,
  UpdateSusceptibilityDueToAssessmentChangeDocument,
  UpdateSusceptibilityDueToAssessmentChangeMutation,
  UpdateSusceptibilityDueToAssessmentChangeMutationVariables,
} from 'common/graphql/graphql-hooks';
import { AssessmentId } from '../reducers/responses';
import { ILicensedModelsState } from 'assessments/reducers/models/modelReducer';

const fetchPracticeBags = async (assessmentId: AssessmentId) => {
  const client = getSingletonClient();
  if (!client) {
    return;
  }

  const results = await client.query<
    PracticeBagsToUpdateQuery,
    PracticeBagsToUpdateQueryVariables
  >({
    query: PracticeBagsToUpdateDocument,
    variables: {
      assessmentId,
    },
  });
  return results;
};

const updatePracticeBagScores = (
  assessmentId: AssessmentId,
  susceptibilityUpdates: Array<{
    susceptibilityUpdate: number;
    practiceBagId: string;
  }>
) => {
  if (!susceptibilityUpdates || !susceptibilityUpdates.length) {
    return;
  }
  const client = getSingletonClient();
  if (!client) {
    return;
  }

  client.mutate<
    UpdateSusceptibilityDueToAssessmentChangeMutation,
    UpdateSusceptibilityDueToAssessmentChangeMutationVariables
  >({
    mutation: UpdateSusceptibilityDueToAssessmentChangeDocument,
    variables: {
      assessmentId,
      susceptibilityUpdates,
    },
  });
};

export const updateScenarioSusceptibility = async (
  modelsByIdentifier: ILicensedModelsState,
  responses: Map<string, IResponse>
) => {
  const mappedResponses = responses
    .valueSeq()
    .toArray()
    .map((response) => ({
      ...response,
      __typename: 'Response',
      practiceId: response._practice_id,
      assessment: { __typename: 'Assessment', modelScope: 'FULL' },
      id: response._id,
    }));

  const assessmentId = mappedResponses[0]['_assessment'];
  const practiceBags = await fetchPracticeBags(assessmentId);

  if (!practiceBags?.data.practiceBagsToUpdate) {
    return;
  }
  const updateInputs = practiceBags.data.practiceBagsToUpdate
    .filter(notEmpty)
    .reduce((accumulator, bag) => {
      const newScore = scorePracticeBagFromResponses(
        modelsByIdentifier,
        bag,
        mappedResponses
      );
      if (newScore === null) {
        return accumulator;
      }
      const bagUpdate = {
        practiceBagId: bag.id,
        susceptibilityUpdate: newScore,
      };
      accumulator.push(bagUpdate);
      return accumulator;
    }, [] as Array<{ practiceBagId: string; susceptibilityUpdate: number }>);

  updatePracticeBagScores(assessmentId, updateInputs);
};
