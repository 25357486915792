export default {
  access:
    'Ability and means to enter a facility, to communicate with or otherwise interact with a system, to use system resources to handle information, to gain knowledge of the information the system contains, or to control system components and functions.',
  'access control':
    "Limiting access to organizational assets only to authorized entities (e.g., users, programs, processes, or other systems). See <a href='#' data-location='definition/asset'>asset</a>.",
  'access management':
    "Management processes to ensure that access granted to the organization's assets is commensurate with the risk to critical infrastructure and organizational objectives. See <a href='#' data-location='definition/access%20control'>access control</a> and <a href='#' data-location='definition/asset'>asset</a>.",
  'ad hoc':
    'In the context of this model, ad hoc (i.e., an ad hoc practice) refers to performing a practice in a manner that depends largely on the initiative and experience of an individual or team (and team leadership), without much in the way of organizational guidance in the form of a prescribed plan (verbal or written), policy, or training. The methods, tools, and techniques used, the priority given a particular instance of the practice, and the quality of the outcome may vary significantly depending on who is performing the practice, when it is performed, and the context of the problem being addressed. With experienced and talented personnel, high-quality outcomes may be achieved even though practices are ad hoc. However, because lessons learned are typically not captured at the organizational level, approaches and outcomes are difficult to repeat or improve across the organization.',
  'advanced metering infrastructure (AMI)':
    "Advanced Metering Infrastructure (AMI) refers to systems that measure, collect, and analyze energy usage, from advanced devices such as 'smart' electricity meters, gas meters, and/or water meters, through various communication media on request or on a predefined schedule.",
  'advanced metering infrastructure':
    "Advanced Metering Infrastructure (AMI) refers to systems that measure, collect, and analyze energy usage, from advanced devices such as 'smart' electricity meters, gas meters, and/or water meters, through various communication media on request or on a predefined schedule.",
  AMI:
    "Advanced Metering Infrastructure (AMI) refers to systems that measure, collect, and analyze energy usage, from advanced devices such as 'smart' electricity meters, gas meters, and/or water meters, through various communication media on request or on a predefined schedule.",
  'anomalous/anomaly':
    'Inconsistent with or deviating from what is usual, normal, or expected.',
  architecture: '>>> cybersecurity architecture',
  assessment: '>>> risk assessment',
  asset:
    'Something of value to the organization. Assets include many things, including technology, information, roles performed by personnel, and facilities. For the purposes of this model, assets to be considered are IT and OT hardware and software assets, as well as information essential to operating the function.',
  'asset owner':
    'A person or organizational unit, internal or external to the organization that has primary responsibility for the viability, productivity, and resilience of an organizational asset.',
  authentication:
    'Verifying the identity of a user, process, or device, often as a prerequisite to allowing access to resources in an IT or ICS.',
  authenticator:
    'The means used to confirm the identity of a user, processor, or device (e.g., user password or token).',
  availability:
    'Ensuring timely and reliable access to and use of information. For an asset, the quality of being accessible to authorized users (people, processes, or devices) whenever it is needed.',
  'business impact analysis':
    "A mission impact analysis that prioritizes the impact associated with the compromise of an organization's information assets, based on a qualitative or quantitative assessment of the sensitivity and criticality of those assets.",
  'change control (change management)':
    'A continuous process of controlling changes to information or technology assets, related infrastructure, or any aspect of services, enabling approved changes with minimum disruption.',
  'change control':
    'A continuous process of controlling changes to information or technology assets, related infrastructure, or any aspect of services, enabling approved changes with minimum disruption.',
  'change management':
    'A continuous process of controlling changes to information or technology assets, related infrastructure, or any aspect of services, enabling approved changes with minimum disruption.',
  'common operating picture':
    'Activities and technologies to collect, analyze, alarm, present, and use cybersecurity information, including status and summary information from the other model domains.',
  'computer security incident':
    "A computer security incident is a violation or imminent threat of violation of computer security policies, acceptable use policies, or standard security practices. An 'imminent threat of violation' refers to a situation in which the organization has a factual basis for believing that a specific incident is about to occur. For example, the antivirus software maintainers may receive a bulletin from the software vendor, warning them of new malware that is rapidly spreading across the Internet. Also, see <a href='#' data-location='definition/incident'>incident</a>.",
  confidentiality:
    'The preservation of authorized restrictions on information access and disclosure, including means for protecting personal privacy and proprietary information. For an information asset, confidentiality is the quality of being accessible only to authorized people, processes, and devices.',
  'configuration baseline':
    'A documented set of specifications for an IT or OT system or asset, or a configuration item within a system, that has been formally reviewed and agreed upon at a given point in time, and which should be changed only through change control procedures. The baseline configuration is used as a basis for future builds, releases, and/or changes.',
  'configuration management':
    'A collection of activities focused on establishing and maintaining the integrity of assets, through control of the processes for initializing, changing, and monitoring the configurations of those assets throughout their lifecycle.',
  'contingency plan':
    'Management policy and procedures used to guide an enterprise response to a perceived loss of mission capability. The contingency plan is the first plan used by the enterprise risk managers to determine what happened, why, and what to do. It may point to the continuity of operations plan or disaster recovery plan for major disruptions.',
  'continuous monitoring':
    'Maintaining ongoing awareness of the current cybersecurity state of the function throughout the operational environment by collecting, analyzing, alarming, presenting, and using OT system and cybersecurity information to identify anomalous activities, vulnerabilities, and threats to the function in order to support incident response and organizational risk management decisions.',
  controls:
    "The management, operational, and technical methods, policies, and procedures'manual or automated'(i.e., safeguards or countermeasures) prescribed for an IT and ICS to protect the confidentiality, integrity, and availability of the system and its information.",
  'critical infrastructure':
    'Assets that provide the essential services that underpin American society. The Nation possesses numerous key resources, whose exploitation or destruction by terrorists could cause catastrophic health effects or mass casualties comparable to those from the use of a weapon of mass destruction, or could profoundly affect our national prestige and morale. In addition, there is critical infrastructure so vital that its incapacitation, exploitation, or destruction through terrorist attack could have a debilitating effect on security and economic well-being.',
  current:
    "Updated at an organization-defined frequency (e.g., as in the asset inventory is kept 'current') that is selected such that the risks to critical infrastructure and organization objectives associated with being out-of-date by the maximum interval between updates are acceptable to the organization and its stakeholders.",
  'cyber attack':
    "An attack, via cyberspace, targeting an enterprise's use of cyberspace for the purpose of disrupting, disabling, destroying, or maliciously controlling a computing environment/infrastructure, or for destroying the integrity of the data or stealing controlled information.",
  cybersecurity:
    'The ability to protect or defend the use of cyberspace from cyber attacks. Measures taken to protect a computer or computerized system (IT and OT) against unauthorized access or attack.',
  'cybersecurity architecture':
    "How cybersecurity practices and controls are structured and implemented to maintain the confidentiality, integrity, and availability of the organization's assets and services. See <a href='#' data-location='definition/enterprise%20architecture'>enterprise architecture</a> and <a href='#' data-location='definition/network%20architecture'>network architecture</a>.",
  'cybersecurity event':
    "Any observable occurrence in a system or network that is related to a cybersecurity requirement (confidentiality, integrity, or availability). See also <a href='#' data-location='definition/event'>event</a>.",
  'cybersecurity impact':
    'The effect on the measures that are in place to protect from and defend against cyber attack.',
  'cybersecurity incident': '>>> incident',
  'cybersecurity incident lifecycle': '>>> incident lifecycle',
  'cybersecurity plan':
    'Formal document that provides an overview of the cybersecurity requirements for an IT and ICS and describes the cybersecurity controls in place or planned for meeting those requirements.',
  'cybersecurity policy':
    'A set of criteria for the provision of security services.',
  'cybersecurity program':
    'A cybersecurity program is an integrated group of activities designed and managed to meet cybersecurity objectives for the organization and/or the function. A cybersecurity program may be implemented at either the organization or the function level, but a higher-level implementation and enterprise viewpoint may benefit the organization by integrating activities and leveraging resource investments across the entire enterprise.',
  'cybersecurity program strategy':
    'A plan of action designed to achieve the performance targets that the organization sets to accomplish its mission, vision, values, and purpose for the cybersecurity program.',
  'cybersecurity requirements':
    'Requirements levied on an IT and OT that are derived from organizational mission and business case needs (in the context of applicable legislation, Executive Orders, directives, policies, standards, instructions, regulations, procedures) to ensure the confidentiality, integrity, and availability of the services being provided by the organization and the information being processed, stored, or transmitted.',
  'cybersecurity responsibilities':
    "Obligations for ensuring the organization's cybersecurity requirements are met.",
  'cybersecurity risk':
    "The risk to organizational operations (including mission, functions, image, reputation), resources, and other organizations due to the potential for unauthorized access, use, disclosure, disruption, modification, or destruction of information and/or IT and ICS. See <a href='#' data-location='definition/risk'>risk</a>.",
  'cybersecurity workforce management objectives':
    'Performance targets for personnel with cybersecurity responsibilities that the organization sets to meet cybersecurity requirements.',
  'defined practice':
    'A practice that is planned (i.e., described, explained, made definite and clear, and standardized) and is executed in accordance with the plan.',
  'dependency risk':
    "Dependency risk is measured by the likelihood and severity of damage if an IT or OT system is compromised due to a supplier or other external party on which delivery of the function depends. Evaluating dependency risk includes an assessment of the importance of the potentially compromised system and the impact of compromise on organizational operations and assets, individuals, other organizations, and the Nation. See <a href='#' data-location='definition/upstream%20dependencies'>upstream dependencies</a> and <a href='#' data-location='definition/supply%20chain%20risk'>supply chain risk</a>.",
  deprovisioning:
    "The process of revoking or removing an identity's access to organizational assets. See also <a href='#' data-location='definition/provisioning'>provisioning</a>.",
  distribution:
    'The delivery of energy to retail customers (e.g., homes, businesses, industry, government facilities).',
  domain:
    'In the context of the model structure, a domain is a logical grouping of cybersecurity practices.',
  'domain objectives':
    "The practices within each domain are organized into objectives. The objectives represent achievements that support the domain (such as 'Manage Asset Configuration' for the ASSET domain and 'Increase Cybersecurity Awareness' for the WORKFORCE domain). Each of the objectives in a domain comprises a set of practices, which are ordered by maturity indicator level.",
  'downstream activities':
    'Business process most commonly used in the petroleum industry to describe postproduction processes (e.g., refining, transportation, and marketing of petroleum products).',
  'downstream dependencies':
    'External parties dependent on the delivery of the function, such as customers and some operating partners.',
  'electricity sector information sharing and analysis center (E-ISAC)':
    "The Electricity Sector Information Sharing and Analysis Center (E-ISAC) shares critical information with industry participants about infrastructure protection. The ES-ISAC serves the electricity sector by facilitating communications between electricity sector participants, federal governments, and other critical infrastructures. It is the job of the ES-ISAC to promptly disseminate threat indications, vulnerabilities, analyses, and warnings, together with interpretations, to help electricity sector participants take protective actions. See <a href='#' data-location='definition/Information%20Sharing'>Information Sharing</a> and <a href='#' data-location='definition/Analysis%20Center%20%28ISAC%29'>Analysis Center (ISAC)</a>.",
  'electricity sector information sharing and analysis center':
    'The Electricity Sector Information Sharing and Analysis Center (E-ISAC) shares critical information with industry participants about infrastructure protection. The ES-ISAC serves the electricity sector by facilitating communications between electricity sector participants, federal governments, and other critical infrastructures. It is the job of the ES-ISAC to promptly disseminate threat indications, vulnerabilities, analyses, and warnings, together with interpretations, to help electricity sector participants take protective actions. See Information Sharing and Analysis Center (ISAC).',
  'E-ISAC':
    'The Electricity Sector Information Sharing and Analysis Center (E-ISAC) shares critical information with industry participants about infrastructure protection. The ES-ISAC serves the electricity sector by facilitating communications between electricity sector participants, federal governments, and other critical infrastructures. It is the job of the ES-ISAC to promptly disseminate threat indications, vulnerabilities, analyses, and warnings, together with interpretations, to help electricity sector participants take protective actions. See Information Sharing and Analysis Center (ISAC).',
  'electricity subsector':
    'A portion of the energy sector that includes the generation, transmission, and distribution of electricity.',
  enterprise:
    "The largest (i.e., highest-level) organizational entity to which the organization participating in the C2M2 survey belongs. For some participants, the organization taking the survey is the enterprise itself. See <a href='#' data-location='definition/organization'>organization</a>.",
  'enterprise architecture':
    "The design and description of an enterprise's entire set of IT and OT: how they are configured, how they are integrated, how they interface to the external environment at the enterprise's boundary, how they are operated to support the enterprise mission, and how they contribute to the enterprise's overall security posture. See <a href='#' data-location='definition/cybersecurity%20architecture'>cybersecurity architecture</a> and <a href='#' data-location='definition/network%20architecture'>network architecture</a>.",
  entity: 'Something having separate or distinct existence.',
  'establish and maintain':
    "The development and maintenance of the object of the practice (such as a program). For example, 'Establish and maintain identities' means that not only must identities be provisioned, but they also must be documented, have assigned ownership, and be maintained relative to corrective actions, changes in requirements, or improvements.",
  event:
    "Any observable occurrence in a system or network. Depending on their potential impact, some events need to be escalated for response. To ensure consistency, criteria for response should align with the organization's risk criteria.",
  function:
    'A subset of the operations of the organization that are being evaluated based on the C2M2 model.',
  generation:
    'The process of producing electric energy by transforming other forms of energy; also, the amount of electric energy produced, expressed in kilowatt-hours.',
  governance:
    'An organizational process of providing strategic direction for the organization while ensuring that it meets its obligations, appropriately manages risk, and efficiently uses financial and human resources. Governance also typically includes the concepts of sponsorship (setting the managerial tone), compliance (ensuring that the organization is meeting its compliance obligations), and alignment (ensuring that processes such as those for cybersecurity program management align with strategic objectives).',
  guidelines:
    "A set of recommended practices produced by a recognized authoritative source representing subject matter experts and community consensus, or internally by an organization. See <a href='#' data-location='definition/st'>st</a> and <a href='#' data-location='definition/ard'>ard</a>.",
  identity:
    "The set of attribute values (i.e., characteristics) by which an entity is recognizable and that, within the scope of an identity manager's responsibility, is sufficient to distinguish that entity from any other entity.",
  impact: 'Negative consequence to subsector functions.',
  incident:
    "An event (or series of events) that significantly affects (or has the potential to significantly affect) critical infrastructure and/or organizational assets and services and requires the organization (and possibly other stakeholders) to respond in some way to prevent or limit adverse impacts. See also <a href='#' data-location='definition/computer%20security%20incident'>computer security incident</a> and <a href='#' data-location='definition/event'>event</a>.",
  'incident lifecycle':
    'The stages of an incident from detection to closure. Collectively, the incident lifecycle includes the processes of detecting, reporting, logging, triaging, declaring, tracking, documenting, handling, coordinating, escalating and notifying, gathering and preserving evidence, and closing incidents. Escalated events also follow the incident lifecycle, even if they are never formally declared to be incidents.',
  'information assets':
    'Information or data that is of value to the organization, including diverse information such as operational data, intellectual property, customer information, and contracts.',
  'information sharing and analysis center (ISAC)':
    'An Information Sharing and Analysis Center (ISAC) shares critical information with industry participants on infrastructure protection. Each critical infrastructure industry has established an ISAC to communicate with its members, its government partners, and other ISACs about threat indications, vulnerabilities, and protective strategies. ISACs work together to better understand cross-industry dependencies and to account for them in emergency response planning. ',
  'information sharing and analysis center':
    'An Information Sharing and Analysis Center (ISAC) shares critical information with industry participants on infrastructure protection. Each critical infrastructure industry has established an ISAC to communicate with its members, its government partners, and other ISACs about threat indications, vulnerabilities, and protective strategies. ISACs work together to better understand cross-industry dependencies and to account for them in emergency response planning. ',
  ISAC:
    'An Information Sharing and Analysis Center (ISAC) shares critical information with industry participants on infrastructure protection. Each critical infrastructure industry has established an ISAC to communicate with its members, its government partners, and other ISACs about threat indications, vulnerabilities, and protective strategies. ISACs work together to better understand cross-industry dependencies and to account for them in emergency response planning. ',
  'information technology (IT)':
    'A discrete set of electronic information resources organized for the collection, processing, maintenance, use, sharing, dissemination, or disposition of information. In the context of this publication, the definition includes interconnected or dependent business systems and the environment in which they operate.',
  'information technology':
    'A discrete set of electronic information resources organized for the collection, processing, maintenance, use, sharing, dissemination, or disposition of information. In the context of this publication, the definition includes interconnected or dependent business systems and the environment in which they operate.',
  IT:
    'A discrete set of electronic information resources organized for the collection, processing, maintenance, use, sharing, dissemination, or disposition of information. In the context of this publication, the definition includes interconnected or dependent business systems and the environment in which they operate.',
  institutionalization:
    "The extent to which a practice or activity is ingrained into the way an organization operates. The more an activity becomes part of how an organization operates, the more likely it is that the activity will continue to be performed over time, with a consistently high level of quality. ('Incorporated into the ingrained way of doing business that an organization follows routinely as part of its corporate culture.' ' CERT RMM). See also <a href='#' data-location='definition/maturity%20indicator%20level'>maturity indicator level</a>.",
  integrity:
    'Guarding against improper information modification or destruction. Integrity includes ensuring information nonrepudiation and authenticity. For an asset, integrity is the quality of being in the condition intended by the owner and therefore continuing to be useful for the purposes intended by the owner.',
  'least privilege':
    'A security control that addresses the potential for abuse of authorized privileges. The organization employs the concept of least privilege by allowing only authorized access for users (and processes acting on behalf of users) who require it to accomplish assigned tasks in accordance with organizational missions and business functions. Organizations employ the concept of least privilege for specific duties and systems (including specific functions, ports, protocols, and services). The concept of least privilege is also applied to information system processes, ensuring that the processes operate at privilege levels no higher than necessary to accomplish required organizational missions and/or functions. Organizations consider the creation of additional processes, roles, and information system accounts as necessary to achieving least privilege. Organizations also apply least privilege concepts to the design, development, implementation, and operations of IT and OT systems.',
  logging:
    'Logging typically refers to automated recordkeeping (by elements of an IT or OT system) of system, network, or user activity. Logging may also refer to keeping a manual record (e.g., a sign-in sheet) of physical access by personnel to a protected asset or restricted area, although automated logging of physical access activity is commonplace. Regular review and audit of logs (manually or by automated tools) is a critical monitoring activity that is essential for situational awareness (e.g., through the detection of cybersecurity events or weaknesses).',
  'logical control':
    "A software, firmware, or hardware feature (i.e., computational logic, not a physical obstacle) within an IT or OT system that restricts access to and modification of assets only to authorized entities. For contrast, see <a href='#' data-location='definition/physical%20control'>physical control</a>.",
  Markets:
    'Venues where participants buy and sell products and services. In the context of this model, markets refers to trading involving wholesale electricity.',
  maturity:
    'The extent to which an organization has implemented and institutionalized the cybersecurity practices of the model.',
  'maturity indicator level (MIL)':
    "A measure of the cybersecurity maturity of an organization in a given domain of the model. The model currently defines four maturity indicator levels (MILs) and holds a fifth level in reserve for use in future versions of the model. Each of the four defined levels is designated by a number (0 through 3) and a name, for example, 'MIL3: managed.' A MIL is a measure of the progression within a domain from individual and team initiative, as a basis for carrying out cybersecurity practices, to organizational policies and procedures that institutionalize those practices, making them repeatable with a consistently high  level of quality. As an organization progresses from one MIL to the next, the organization will have more complete or more advanced implementations of the core activities in the domain.",
  'maturity indicator level':
    "A measure of the cybersecurity maturity of an organization in a given domain of the model. The model currently defines four maturity indicator levels (MILs) and holds a fifth level in reserve for use in future versions of the model. Each of the four defined levels is designated by a number (0 through 3) and a name, for example, 'MIL3: managed.' A MIL is a measure of the progression within a domain from individual and team initiative, as a basis for carrying out cybersecurity practices, to organizational policies and procedures that institutionalize those practices, making them repeatable with a consistently high  level of quality. As an organization progresses from one MIL to the next, the organization will have more complete or more advanced implementations of the core activities in the domain.",
  MIL:
    "A measure of the cybersecurity maturity of an organization in a given domain of the model. The model currently defines four maturity indicator levels (MILs) and holds a fifth level in reserve for use in future versions of the model. Each of the four defined levels is designated by a number (0 through 3) and a name, for example, 'MIL3: managed.' A MIL is a measure of the progression within a domain from individual and team initiative, as a basis for carrying out cybersecurity practices, to organizational policies and procedures that institutionalize those practices, making them repeatable with a consistently high  level of quality. As an organization progresses from one MIL to the next, the organization will have more complete or more advanced implementations of the core activities in the domain.",
  'midstream activities':
    'Business category involving the processing, storage, and transportation sectors of the petroleum industry.',
  monitoring:
    'Collecting, recording, and distributing information about the behavior and activities of systems and persons to support the continuous process of identifying and analyzing risks to organizational assets and critical infrastructure that could adversely affect the operation and delivery of services.',
  'monitoring requirements':
    'The requirements established to determine the information gathering and distribution needs of stakeholders.',
  'multifactor authentication':
    "Authentication using two or more factors to achieve authentication. Factors include (i) something you know (e.g., password/PIN), (ii) something you have (e.g., cryptographic identification device, token), (iii) something you are (e.g., biometric), or (iv) you are where you say you are (e.g., GPS token). See <a href='#' data-location='definition/authentication'>authentication</a>.",
  'network architecture':
    "A framework that describes the structure and behavior of communications among IT and/or OT assets and prescribes rules for interaction and interconnection. See <a href='#' data-location='definition/enterprise%20architecture'>enterprise architecture</a> and <a href='#' data-location='definition/cybersecurity%20architecture'>cybersecurity architecture</a>.",
  'objective(s)':
    "See <a href='#' data-location='definition/domain%20objectives'>domain objectives</a> and <a href='#' data-location='definition/organizational%20objectives'>organizational objectives</a>.",
  objective: 'See domain objectives and organizational objectives.',
  'operating picture':
    "Real-time (or near-real-time) awareness of the operating state of a system or function. An operating picture is formed from data collected from various trusted information sources that may be internal or external to the system or function (e.g. temperature, weather events and warnings, cybersecurity alerts). The operating picture may or may not be presented graphically. It involves the collection, analysis (including fusion), and distribution of what is important to know to make decisions about the operation of the system. A common operating picture (COP) is a single operating picture that is available to the stakeholders of the system or function so that all stakeholders can make decisions based on the same reported operating state. See <a href='#' data-location='definition/common%20operating%20picture'>common operating picture</a>.",
  'operational resilience':
    "The organization's ability to adapt to risk that affects its core operational capacities. Operational resilience is an emergent property of effective operational risk management, supported and enabled by activities such as security and business continuity. A subset of enterprise resilience, operational resilience focuses on the organization's ability to manage operational risk, whereas enterprise resilience encompasses additional areas of risk such as business risk and credit risk. See <a href='#' data-location='definition/the%20related%20term%20operational%20risk'>the related term operational risk</a>.",
  'operating states': '>>> predefined states of operation',
  'operational risk':
    'The potential impact on assets and their related services that could result from inadequate or failed internal processes, failures of systems or technology, the deliberate or inadvertent actions of people, or external events. In the context of this model, our focus is on operational risk from cybersecurity threats.',
  'operations technology (OT)':
    'Programmable systems or devices that interact with the physical environment (or manage devices that interact with the physical environment). Examples include industrial control systems, building management systems, fire control systems, and physical access control mechanisms.',
  'operations technology':
    'Programmable systems or devices that interact with the physical environment (or manage devices that interact with the physical environment). Examples include industrial control systems, building management systems, fire control systems, and physical access control mechanisms.',
  OT:
    'Programmable systems or devices that interact with the physical environment (or manage devices that interact with the physical environment). Examples include industrial control systems, building management systems, fire control systems, and physical access control mechanisms.',
  organization:
    'An organization of any size, complexity, or positioning within an organizational structure that is charged with carrying out assigned mission and business processes and that uses IT and OT in support of those processes. In the context of the model, the organization is the entity using the model or that is under examination.',
  'organizational objectives':
    "Performance targets set by an organization. See <a href='#' data-location='definition/strategic%20objectives'>strategic objectives</a>.",
  'periodic review/activity':
    'A review or activity that occurs at specified, regular time intervals, where the organization-defined frequency is commensurate with risks to organizational objectives and critical infrastructure.',
  'personal information':
    'Information that reveals details, either explicitly or implicitly, about a specific individual\'s household dwelling or other type of premises. This is expanded beyond the normal "individual" component because there are serious privacy impacts for all individuals living in one dwelling or premise. This can include items such as energy use patterns or other types of activities. The pattern can become unique to a household or premises just as a fingerprint or DNA is unique to an individual.',
  'physical control':
    'A type of control that prevents physical access to and modification of information assets or physical access to technology and facilities. Physical controls often include such artifacts as card readers and physical barrier methods.',
  plan: 'A detailed formulation of a program of action.',
  policy:
    'A high-level overall plan embracing the general goals and acceptable procedures of an organization.',
  'position description':
    'A set of responsibilities that describe a role or roles filled by an employee. Also known as a job description.',
  practice:
    'An activity described in the model that can be performed by an organization to support a domain objective. The purpose of these activities is to achieve and sustain an appropriate level of cybersecurity for the function, commensurate with the risk to critical infrastructure and organizational objectives.',
  'predefined states of operation':
    'Distinct operating modes (which typically include specific IT and OT configurations as well as alternate or modified procedures) that have been designed and implemented for the function and can be invoked by a manual or automated process in response to an event, a changing risk environment, or other sensory and awareness data to provide greater safety, resiliency, reliability, and/or cybersecurity. For example, a shift from the normal state of operation to a high-security operating mode may be invoked in response to a declared cybersecurity incident of sufficient severity. The high-security operating state may trade off efficiency and ease of use in favor of increased security by blocking remote access and requiring a higher level of authentication and authorization for certain commands until a return to the normal state of operation is deemed safe.',
  procedure: 'In this model, procedure is synonymous with process.',
  process:
    'A series of discrete activities or tasks that contribute to the fulfillment of a task or mission.',
  provisioning:
    "The process of assigning or activating an identity profile and its associated roles and access privileges. See also <a href='#' data-location='definition/deprovisioning'>deprovisioning</a>.",
  'recovery point objectives (RPO)':
    'The point in time to which data is restored after an incident. The point to which information used by the function must be restored to enable the activity to operate on resumption.',
  'recovery point objectives':
    'The point in time to which data is restored after an incident. The point to which information used by the function must be restored to enable the activity to operate on resumption.',
  RPO:
    'The point in time to which data is restored after an incident. The point to which information used by the function must be restored to enable the activity to operate on resumption.',
  'recovery time objectives (RTO)':
    'The period of time within which systems, applications, or functions must be recovered after an incident. RTO includes the time required for: assessment, execution and verification. The period of time following an incident within which a product or service or function or an activity must be resumed, or resources must be recovered.',
  'recovery time objectives':
    'The period of time within which systems, applications, or functions must be recovered after an incident. RTO includes the time required for: assessment, execution and verification. The period of time following an incident within which a product or service or function or an activity must be resumed, or resources must be recovered.',
  RTO:
    'The period of time within which systems, applications, or functions must be recovered after an incident. RTO includes the time required for: assessment, execution and verification. The period of time following an incident within which a product or service or function or an activity must be resumed, or resources must be recovered.',
  refining:
    'The control or management of any operation by which the physical or chemical characteristics of oil or products are changed, but exclusive of the operations of passing oil through separators to remove gas, placing oil in settling tanks to remove basic sediment and water, dehydrating oil, and generally cleaning and purifying oil.',
  risk:
    'A measure of the extent to which an organization is threatened by a potential circumstance or event, and typically a function of (1) the adverse impacts that would arise if the circumstance or event occurs and (2) the likelihood of occurrence.',
  'risk analysis':
    "A risk management activity focused on understanding the condition and potential consequences of risk, prioritizing risks, and determining a path for addressing risks. Determines the importance of each identified risk and is used to facilitate the organization's response to the risk.",
  'risk assessment':
    'The process of identifying risks to organizational operations (including mission, functions, image, reputation), resources, other organizations, and the Nation, resulting from the operation of an IT and ICS.',
  'risk criteria':
    'Objective criteria that the organization uses for evaluating, categorizing, and prioritizing operational risks based on impact, tolerance for risk, and risk response approaches.',
  "risk designation, as in 'position risk designation'":
    "An indication, such as high, medium, or low, of the position's potential for adverse impact to the efficiency, integrity, or availability of the organization's services.",
  'risk disposition':
    "A statement of the organization's intention for addressing an operational risk. Typically limited to 'accept,' 'transfer,' 'research,' or 'mitigate.'",
  'risk management program':
    'The program and supporting processes to manage cybersecurity risk to organizational operations (including mission, functions, image, reputation), resources, other organizations, and the Nation. It includes (1) establishing the context for risk-related activities, (2) assessing risk, (3) responding to risk once determined, and (4) monitoring risk over time.',
  'risk management strategy':
    "Strategic-level decisions on how senior executives manage risk to an organization's operations, resources, and other organizations.",
  'risk mitigation':
    'Prioritizing, evaluating, and implementing appropriate risk-reducing controls.',
  'risk mitigation plan':
    "A strategy for mitigating risk that see <a href='#' data-location='definition/ks%20to%20minimize%20the%20risk%20to%20an%20acceptable%20level'>ks to minimize the risk to an acceptable level</a>.",
  'risk parameter/risk parameter factors':
    'Organization-specific risk tolerances used for consistent measurement of risk across the organization. Risk parameters include risk tolerances and risk measurement criteria.',
  'risk register':
    'A structured repository where identified risks are recorded to support risk management.',
  'risk response':
    'Accepting, avoiding, mitigating, sharing, or transferring risk to organizational operations, resources, and other organizations.',
  'risk taxonomy':
    'The collection and cataloging of common risks that the organization is subject to and must manage. The risk taxonomy is a means for communicating these risks and for developing mitigation actions specific to an organizational unit or line-of-business if operational assets and services are affected by them.',
  role:
    'A group attribute that ties membership to function. When an entity assumes a role, the entity is given certain rights that belong to that role. When the entity leaves the role, those rights are removed. The rights given are consistent with the functionality that the entity needs to perform the expected tasks.',
  'secure software development':
    'Developing software using recognized processes, secure coding standards, best practices, and tools that have been demonstrated to minimize security vulnerabilities in software systems throughout the software development lifecycle. An essential aspect is to engage programmers and software architects who have been trained in secure software development.',
  'security zone':
    'Systems and components with similar cybersecurity requirements. Zone access is restricted by network and security devices.',
  'separation of duties':
    "[A security control that] 'addresses the potential for abuse of authorized privileges and helps to reduce the risk of malevolent activity without collusion. Separation of duties includes, for example: (i) dividing mission functions and information system support functions among different individuals and/or roles; (ii) conducting information system support functions with different individuals (e.g., system management, programming, configuration management, quality assurance and testing, and network security); and (iii) ensuring security personnel administering access control functions do not also administer audit functions. Organizations with significant personnel limitations may compensate for the separation of duty security control by strengthening the audit, accountability, and personnel security controls.'",
  'service level agreement (SLA)':
    "Defines the specific responsibilities of the service provider, including the satisfaction of any relevant cybersecurity requirements, and sets the customer's expectations regarding the quality of service to be provided.",
  'service level agreement':
    "Defines the specific responsibilities of the service provider, including the satisfaction of any relevant cybersecurity requirements, and sets the customer's expectations regarding the quality of service to be provided.",
  SLA:
    "Defines the specific responsibilities of the service provider, including the satisfaction of any relevant cybersecurity requirements, and sets the customer's expectations regarding the quality of service to be provided.",
  'situational awareness':
    "A sufficiently accurate and up-to-date understanding of the past, current, and projected future state of a system (including its cybersecurity safeguards), in the context of the threat environment and risks to the system's mission, to support effective decision making with respect to activities that depend on and/or affect how well a system functions. It involves the collection of data (e.g., via sensor networks), data fusion, and data analysis (which may include modeling and simulation) to support automated and/or human decision making (for example, concerning OT system functions). Situational awareness also involves the presentation of the results of the data analysis in a form (e.g., using data visualization techniques, appropriate use of alarms) that aids human comprehension and allows operators or other personnel to quickly grasp the key elements needed for good decision making.",
  sponsorship:
    "Enterprise-wide support of cybersecurity objectives by senior management as demonstrated by formal policy or by declarations of management's commitment to the cybersecurity program along with provision of resources. Senior management monitors the performance and execution of the cybersecurity program and is actively involved in the ongoing improvement of all aspects of the cybersecurity program.",
  stakeholder:
    "An external organization or an internal or external person or group that has a vested interest in the organization or function (that is being evaluated using this model) and its practices. Stakeholders involved in performing a given practice (or who oversee <a href='#' data-location='definition/%2C%20benefit%20from%2C%20or%20are%20dependent%20upon%20%20the%20quality%20with%20which%20the%20practice%20is%20performed%29%20could%20include%20those%20from%20within%20the%20function%2C%20from%20across%20the%20organization%2C%20or%20from%20outside%20the%20organization'>, benefit from, or are dependent upon  the quality with which the practice is performed) could include those from within the function, from across the organization, or from outside the organization</a>.",
  standard:
    "A standard is a document, established by consensus, which provides rules, guidelines, or characteristics for activities or their results. See <a href='#' data-location='definition/guidelines'>guidelines</a>.",
  'states of operation': '>>> predefined states of operation',
  'strategic objectives':
    'The performance targets that the organization sets to accomplish its mission, vision, values, and purpose.',
  'strategic planning':
    'The process of developing strategic objectives and plans for meeting these objectives.',
  'supply chain':
    "The set of organizations, people, activities, information, and resources for creating and moving a product or service (including its sub-elements) from suppliers through to an organization's customers. The supply chain encompasses the full product lifecycle and includes design, development, and acquisition of custom or commercial off-the-shelf (COTS) products, system integration, system operation (in its environment), and disposal. People, processes, services, products, and  the elements that make up the products wholly impact the supply chain.",
  'supply chain risk':
    "Supply chain risk is measured by the likelihood and severity of damage if an IT or OT system is compromised by a supply chain attack, and takes into account the importance of the system and the impact of compromise on organizational operations and assets, individuals, other organizations, and the Nation. Supply chain attacks may involve manipulating computing system hardware, software, or services at any point during the lifecycle. Supply chain attacks are typically conducted or facilitated by individuals or organizations that have access through commercial ties, leading to stolen critical data and technology, corruption of the system/ infrastructure, and/or disabling of mission-critical operations. See <a href='#' data-location='definition/risks'>risks</a> and <a href='#' data-location='definition/supply%20chain'>supply chain</a>.",
  threat:
    'Any circumstance or event with the potential to adversely impact organizational operations (including mission, functions, image, or reputation), resources, and other organizations through IT, OT, or communications infrastructure via unauthorized access, destruction, disclosure, modification of information, and/or denial of service.',
  'threat assessment':
    'The process of evaluating the severity of threat to an IT and ICS or organization and describing the nature of the threat.',
  'threat profile':
    'A characterization of the likely intent, capability, and targets for threats to the function. It is the result of one or more threat assessments across the range of feasible threats to the IT and OT of an organization and to the organization itself, delineating the feasible threats, describing the nature of the threats, and evaluating their severity.',
  'threat source':
    'An intent and method targeted at the intentional exploitation of a vulnerability or a situation, or a method that may accidentally exploit a vulnerability.',
  traceability:
    'The ability to determine whether or not a given attribute of the current state is valid (e.g., the current configuration of a system or the purported identity of a user) based on the evidence maintained in a historical record showing how the attribute was originally established and how it has changed over time.',
  transmission:
    'The movement or transfer of electric energy over an interconnected group of lines and associated equipment between points of supply and points at which it is transformed for delivery to consumers or is delivered to other electric systems. Transmission is considered to end when the energy is transformed for distribution to the consumer.',
  'upstream activities':
    'Business category of the petroleum industry involving exploration and production (e.g., offshore oil/gas production facility, drilling rig, intervention vessel).',
  'upstream dependencies':
    'External parties on which the delivery of the function depends, including suppliers and some operating partners.',
  validate:
    'Collect and evaluate evidence to confirm or establish the quality of something (e.g., information, a model, a product, a system, or component) with respect to its fitness for a particular purpose.',
  vulnerability:
    'A cybersecurity vulnerability is a weakness or flaw in IT, OT, or communications systems or devices, system procedures, internal controls, or implementation that could be exploited by a threat source. A vulnerability class is a grouping of common vulnerabilities.',
  'vulnerability assessment':
    'Systematic examination of an IT or product to determine the adequacy of cybersecurity measures, identify security deficiencies, provide data from which to predict the effectiveness of proposed cybersecurity measures, and confirm the adequacy of such measures after implementation.',
  'workforce lifecycle':
    'For the purpose of this model, the workforce lifecycle comprises the distinct phases of workforce management that apply to personnel both internal and external to the organization. Specific cybersecurity implications and requirements are associated with each lifecycle phase. The workforce lifecycle includes recruiting, hiring, onboarding, skill assessments, training and certification, assignment to roles (deployment), professional growth and development, re-assignment and transfers, promotions and demotions, succession planning, and termination or retirement. The phases may not be in strict sequences, and some phases (like training, re-assignment, and promotions) may recur.',
  'workforce management objectives':
    '>>> cybersecurity workforce management objectives',
};
