import { Map } from 'immutable';
import { Reducer } from 'redux';

import { IBitsightResponse } from '../databaseTypes';

type IState = Map<string, Map<string, IBitsightResponse>>;

interface IBitsightResult {
  type: 'GET_BITSIGHT_PRACTICES_RESULTS';
  payload: { CSF: IBitsightResponse[]; C2M2: IBitsightResponse[] };
}

const initialState: IState = Map({});

export const bitsightReducer: Reducer<IState, any | IBitsightResult> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'GET_BITSIGHT_PRACTICES_RESULTS_ERR': {
      if (state.get('CSF', Map<IBitsightResponse>({})).size) {
        return state;
      }
      return initialState;
    }
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      return initialState;
    }
    case 'patch_resource/companies': {
      // Reset if bitsight key changes
      if ('bitsightKey' in action.request.data) {
        return initialState;
      }
      return state;
    }
    case 'GET_BITSIGHT_PRACTICES_INITIAL': {
      return Map({
        CSF: Map<IBitsightResponse>({}),
        C2M2: Map<IBitsightResponse>({}),
      });
    }
    case 'GET_BITSIGHT_PRACTICES_RESULTS':
      return Map({
        CSF: Map<IBitsightResponse>(
          action.payload.CSF.filter(Boolean).map(
            (practice) => [practice.id, practice] as [string, IBitsightResponse]
          )
        ),
        C2M2: Map<IBitsightResponse>(
          action.payload.C2M2.filter(Boolean).map(
            (practice) => [practice.id, practice] as [string, IBitsightResponse]
          )
        ),
      });
    default:
      break;
  }

  return state;
};
