import Logger from './Logger';

export const safeParseJson = (jsonStr?: string) => {
  if (!jsonStr) {
    return {};
  }
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    Logger.exception(e, {
      contexts: {
        location: { file: 'jsonUtils.tx', function: 'safeParseJson' },
      },
    });
    return {};
  }
};
