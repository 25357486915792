import parse from 'html-react-parser';
import { Converter } from 'showdown';

const markdownConverter = new Converter();

export const markupTextToHtml = (text: string) => {
  // Going for the children so that we don't have an
  // enclosing p tag.
  const html: JSX.Element = parse(
    markdownConverter.makeHtml(text)
  ) as JSX.Element;
  return html.props?.children;
};
