import { criAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import { criV2ColorTable } from 'assessments/utils/criV2ColorTable';

const title = 'CRI V2 Tier 4';

export const criV2Tier4Config: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasImportResponses: true,
    hasMilScoping: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    useDropout: true,
    topOfDomain: 100,
  },
  shortTitle: title,
  factories: [
    {
      label: title,
      filterLabel: title,
      icon: icon,
      sortKey: title,
      modelUuid: LegacyModelIdEnum.Criv2Tier4,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.CRI_FULL,
    },
  ],
  colorTable: criV2ColorTable,
};
