import { Location } from 'history';
import React from 'react';
import { matchPath, RouteProps, StaticContext } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Admin from 'admin';
import Assessments from 'assessments';
import Authentication from 'authentication';
import { DASHBOARD_URL } from 'common/constants';
import { getUserEmail } from 'common/selectors/user';
import Insurance from 'insurance';
import Quantification from 'quantification';
import QuickQuant from 'quickquant';

export const MainRouter = () => {
  // Using email here to ensure the user has been returned from the back-end.
  // Using the presence of user from userContext would imply the user is logged in but we may not
  // have enough data to bother trying to render
  const hasUser = !!useSelector(getUserEmail);

  /**
   * Wrapper that redirects to assessments user is already logged in.
   * ***UNLESS*** user has requested "/authentication/logout" which ***always succeeds***
   */
  const reRouteIfAuthenticated = (
    destinationComponent: React.ReactNode,
    routeProps: RouteComponentProps<{}, StaticContext, { from?: Location }>
  ) => {
    return hasUser &&
      !matchPath(routeProps.location.pathname, {
        path: '/authentication/logout',
        exact: true,
      }) ? (
      <Redirect
        to={
          (routeProps.location.state && routeProps.location.state.from) || {
            pathname: DASHBOARD_URL,
          }
        }
      />
    ) : (
      destinationComponent
    );
  };

  const PrivateRoute = ({
    children,
    ...rest
  }: Omit<RouteProps, 'children'> & { children: React.ReactNode }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          hasUser ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/authentication',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  const renderAuth = (argProps: RouteComponentProps<any, any, any>) =>
    reRouteIfAuthenticated(<Authentication />, argProps);

  return (
    <Switch>
      <Route path="/authentication" render={renderAuth} />
      <PrivateRoute path="/assessments">
        <Assessments />
      </PrivateRoute>
      <PrivateRoute path="/insurance/:component?/:reviewId?/:policyId?/:fileId?">
        <Insurance />
      </PrivateRoute>
      <Redirect from="/quant/:id" to="/quantification/:id" />
      <Redirect from="/quant" to="/quantification" />
      <PrivateRoute path="/quantification/:component?/:collectionId?/:scenarioId?">
        <Quantification />
      </PrivateRoute>
      <PrivateRoute path="/admin/:component?/:id?">
        <Admin />
      </PrivateRoute>
      <PrivateRoute path="/quickquant">
        <QuickQuant />
      </PrivateRoute>
      {['/index.html', '/'].map((path) => (
        <Redirect exact from={path} to="/authentication" key={path} />
      ))}
      <Route path="*">
        <div>
          <h1>Oops!</h1>
          <p>You're looking for a page that doesn't exist.</p>
        </div>
      </Route>
    </Switch>
  );
};
