import { criAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import { lighten } from 'polished';
import questionnaireSchema from 'assessments/questionnaires/cri-questionnaire-schema';

const lightSelected = '#F8F8F8';
const darkSelected = '#202020';
const lightAmount = 0.22;

const colorTable = {
  '.GV': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#BFBFBF'),
    background: '#BFBFBF',
  },
  '.ID': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#0070C0'),
    background: '#0070C0',
  },
  '.PR': {
    selectedForeground: darkSelected,
    foreground: lighten(lightAmount, '#7030A0'),
    background: '#7030A0',
  },
  '.DE': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#f5c400'),
    background: '#f5c400',
  },
  '.RS': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#FF0000'),
    background: '#FF0000',
  },
  '.RC': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#00B050'),
    background: '#00B050',
  },
  '.DM': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#ED7D31'),
    background: '#ED7D31',
  },
};

export const criConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: true,
    hasImportResponses: false,
    hasMilScoping: false,
    hasTierScoping: true,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    useDropout: true,
    topOfDomain: 100,
  },
  shortTitle: 'CRI Assessment',
  factories: [
    {
      label: 'CRI Profile',
      filterLabel: 'CRI Tier 1',
      icon: icon,
      sortKey: 'CRI',
      modelUuid: LegacyModelIdEnum.Cri,
      modelScope: ModelScope.Tier1,
      requiresImporter: false,
      hasQuestionnaire: true,
      dashboardType: DashboardType.CRI_FULL,
    },
  ],
  flags: {
    hasQuestionnaire: true,
  },
  colorTable,
  questionnaireSchema,
};
