import { cmmcAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_c2m2';

export const cmmcConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: true,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    offset: -213,
  },
  shortTitle: 'CMMC',
  factories: [
    {
      label: 'CMMC Assessment',
      filterLabel: 'CMMC Level 5',
      icon: icon,
      sortKey: 'CMMC',
      modelUuid: LegacyModelIdEnum.Cmmc,
      modelScope: ModelScope.Mil5,
      requiresImporter: false,
      dashboardType: DashboardType.CMMC_FULL,
    },
  ],
  terms,
};
