import { csfAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { colorTable } from 'assessments/utils/nistColorTable';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_csf';
import wizardSchema from 'assessments/models/csf-wizard-schema';

export const csfConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: true,
    targetProfiles: true,
    convertToFull: true,
    referenceAssessments: true,
    milestones: true,
    wizard: true,
    hasMilScoping: false,
    hasImportResponses: true,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: true,
      board: true,
      boardAppendix: true,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    topOfDomain: 100,
  },
  shortTitle: 'NIST CSF Assessment',
  factories: [
    {
      label: 'CSF Full Assessment',
      filterLabel: 'CSF Assessment',
      requiredLicenseOption: ModelScope.Full,
      icon,
      sortKey: 'CSF-0',
      modelUuid: LegacyModelIdEnum.Csf,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      qualifier: 'FILIPINI',
      dashboardType: DashboardType.CSF_FULL,
    },
    {
      label: 'CSF Foundations Assessment',
      filterLabel: 'CSF Foundations',
      requiredLicenseOption: ModelScope.Quicklaunch,
      icon,
      sortKey: 'CSF-1',
      modelUuid: LegacyModelIdEnum.Csf,
      modelScope: ModelScope.Quicklaunch,
      requiresImporter: false,
      dashboardType: DashboardType.CSF_QL,
    },
  ],
  colorTable,
  terms,
  wizardSchema,
};
