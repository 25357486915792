import { cmmcAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { lighten } from 'polished';
import { LegacyModelConfig } from '..';

const lightSelected = '#F8F8F8';
const darkSelected = '#202020';
const lightAmount = 0.22;

const colorTable = {
  '.ID': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#12529a'),
    background: '#12529a',
  },
  '.PR': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#622c7b'),
    background: '#622c7b',
  },
  '.DE': {
    selectedForeground: darkSelected,
    foreground: lighten(lightAmount, '#fdd228'),
    background: '#fdd228',
  },
  '.RS': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#de061f'),
    background: '#de061f',
  },
  '.RC': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#1c8837'),
    background: '#1c8837',
  },
};

export const cmmiCsfConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: true,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  factories: [
    {
      label: 'CSF Assessment',
      filterLabel: 'CSF Assessment',
      icon: icon,
      sortKey: 'CSFCMMI',
      modelUuid: LegacyModelIdEnum.Cmmicsf,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      qualifier: 'CMMI',
      dashboardType: DashboardType.CMMICSF_FULL,
    },
  ],
  colorTable,
  shortTitle: 'NIST CSF Assessment (CMMI)',
};
