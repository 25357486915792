import { csfAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_csf';

export const fhbCsfConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: true,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  shortTitle: 'NIST CSF (FHB)',
  factories: [
    {
      label: 'NIST CSF Risk CMM 2017 (FHB)',
      filterLabel: 'NIST CSF (FHB)',
      icon,
      sortKey: 'CSFFHB',
      modelUuid: LegacyModelIdEnum.Fhbcsf,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.FHBCSF_FULL,
    },
  ],
  terms,
};
