// This file holds the theme elements that are common across all modules.
import authBackground from '../riverstone/RiverstoneLoginImage.jpg';

import { defaultTheme } from '../default';
import clientLogo from './RiverstoneLogoBig.png';
import poweredByLogo from '../default/Axio360.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';

export const RIVERSTONE_BLUE = 'black';
export const riverstoneTheme = {
  ...defaultTheme,
  title: 'Axio360',
  name: 'riverstone',
  poweredBy: true,
  tocPattern: defaultTheme.backgroundImage,
  homepage: 'https://www.riverstonellc.com',
  clientName: 'RIVERSTONE',
  clientNameLong: 'Riverstone',
  clientLogo,
  logoHeight: 43,
  authLogo: clientLogo,
  poweredByLogo: poweredByLogo,
  authPoweredByLogo: authPoweredByLogo,
  // eulaLink: '/riverstone_terms.html',
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: authBackground,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    primaryButton: RIVERSTONE_BLUE,
    authBackground: defaultTheme.colors.darkGray,
  },
};
