import { LegacyModelIdEnum } from 'common/graphql/graphql-hooks';

import { alliantConfig } from './modelConfigs/alliant';
import { c2m2Config } from './modelConfigs/c2m2';
import { c2m2v2Config } from './modelConfigs/c2m2v2';
import { c2m2v2v1Config } from './modelConfigs/c2m2v2v1';
import { cisConfig } from './modelConfigs/cis';
import { cisPlusConfig } from './modelConfigs/cisplus';
import { cmmcConfig } from './modelConfigs/cmmc';
import { cmmcv2Config } from './modelConfigs/cmmcv2';
import { cmmiCsfConfig } from './modelConfigs/cmmiCsf';
import { criConfig } from './modelConfigs/cri';
import { criV2Tier1Config } from './modelConfigs/criv2tier1';
import { criV2Tier2Config } from './modelConfigs/criv2tier2';
import { criV2Tier3Config } from './modelConfigs/criv2tier3';
import { criV2Tier4Config } from './modelConfigs/criv2tier4';
import { csfConfig } from './modelConfigs/csf';
import { csfGuidepointConfig } from './modelConfigs/csfGuidepoint';
import { cssaConfig } from './modelConfigs/cssa';
import { ehrCsfConfig } from './modelConfigs/ehrCsf';
import { ehrViewerConfig } from './modelConfigs/ehrViewer';
import { entergyCsfConfig } from './modelConfigs/entergyCsf';
import { esemStandardsConfig } from './modelConfigs/esemStandards';
import { ffiec1Config } from './modelConfigs/ffiec1';
import { fhbCsfConfig } from './modelConfigs/fhcCsf';
import { iso27001Config } from './modelConfigs/iso27001';
import { nist2CmmiConfig } from './modelConfigs/nist2cmmi';
import { nist2FilipiniConfig } from './modelConfigs/nist2filipini';
import { nist2GuidepointConfig } from './modelConfigs/nist2guidepoint';
import { nist2OHConfig } from './modelConfigs/nist2oh';
import { nistppConfig } from './modelConfigs/nistPP';
import { rc3Config } from './modelConfigs/rc3';
import { rpaConfig } from './modelConfigs/rpa';

import type { LegacyModelConfig } from './types';

export const getLegacyModelConfigs = (): ReadonlyMap<
  LegacyModelIdEnum,
  LegacyModelConfig
> =>
  new Map([
    [LegacyModelIdEnum.Alliant, alliantConfig],
    [LegacyModelIdEnum.C2M2, c2m2Config],
    [LegacyModelIdEnum.C2M2V2, c2m2v2Config],
    [LegacyModelIdEnum.C2M2V2V1, c2m2v2v1Config],
    [LegacyModelIdEnum.Cis18, cisConfig],
    [LegacyModelIdEnum.Cisplus, cisPlusConfig],
    [LegacyModelIdEnum.Cmmc, cmmcConfig],
    [LegacyModelIdEnum.Cmmcv2, cmmcv2Config],
    [LegacyModelIdEnum.Cmmicsf, cmmiCsfConfig],
    [LegacyModelIdEnum.Cri, criConfig],
    [LegacyModelIdEnum.Criv2Tier1, criV2Tier1Config],
    [LegacyModelIdEnum.Criv2Tier2, criV2Tier2Config],
    [LegacyModelIdEnum.Criv2Tier3, criV2Tier3Config],
    [LegacyModelIdEnum.Criv2Tier4, criV2Tier4Config],
    [LegacyModelIdEnum.Csf, csfConfig],
    [LegacyModelIdEnum.Csfguidepoint, csfGuidepointConfig],
    [LegacyModelIdEnum.Cssa, cssaConfig],
    [LegacyModelIdEnum.Ehrcsf, ehrCsfConfig],
    [LegacyModelIdEnum.Ehrviewer, ehrViewerConfig],
    [LegacyModelIdEnum.Entergycsf, entergyCsfConfig],
    [LegacyModelIdEnum.Esemstandards, esemStandardsConfig],
    [LegacyModelIdEnum.Ffiec1, ffiec1Config],
    [LegacyModelIdEnum.Fhbcsf, fhbCsfConfig],
    [LegacyModelIdEnum.Iso27001, iso27001Config],
    [LegacyModelIdEnum.Nist2Cmmi, nist2CmmiConfig],
    [LegacyModelIdEnum.Nist2Filipini, nist2FilipiniConfig],
    [LegacyModelIdEnum.Nist2Guidepoint, nist2GuidepointConfig],
    [LegacyModelIdEnum.Nist2Oh, nist2OHConfig],
    [LegacyModelIdEnum.Nistpp, nistppConfig],
    [LegacyModelIdEnum.Rc3, rc3Config],
    [LegacyModelIdEnum.Rpa, rpaConfig],
  ]);
