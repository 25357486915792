// This file holds the theme elements that are common across all modules.
import netfriendsBackground from './netfriends_background.png';

import { defaultTheme } from '../default';
import clientLogo from './Net_Friends_Logo_RGB_Color.png';
import poweredByLogo from '../default/Axio360.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';

export const NETFRIENDS_YELLOW = '#ffc629';
export const NETFRIENDS_GREEN = '#00ae42';
export const NETFRIENDS_BLACK = '#1d252c';
export const netfriendsTheme = {
  ...defaultTheme,
  title: 'Axio360',
  name: 'netfriends',
  poweredBy: true,
  tocPattern: defaultTheme.backgroundImage,
  homepage: 'https://www.netfriends.com/',
  clientName: 'NETFRIENDS',
  clientNameLong: 'Net Friends, Inc',
  clientLogo,
  logoHeight: 43,
  authLogo: clientLogo,
  poweredByLogo: poweredByLogo,
  authPoweredByLogo: authPoweredByLogo,
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: netfriendsBackground,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    primaryButton: NETFRIENDS_YELLOW,
    primaryButtonText: NETFRIENDS_BLACK,
    authBackground: defaultTheme.colors.darkGray,
    minimalButtonTextColor: NETFRIENDS_BLACK,
  },
};
