import {
  RIVERSTONE_BLUE,
  riverstoneTheme as baseTheme,
} from 'common/themes/riverstone';
import defaultTheme from './default';

const LOGO_WIDTH = 96;

export default {
  ...defaultTheme,
  poweredBy: true,
  hasOwnIntroScreen: false,
  authBackground: baseTheme.backgroundImage,
  logoWidth: LOGO_WIDTH + 'px',
  colors: {
    ...defaultTheme.colors,
    headerBackground: 'black',
    headerText: 'white',
    menuSubtext: 'lightgray',
    nameplate: RIVERSTONE_BLUE,
    qlNavButtonBackground: RIVERSTONE_BLUE,
    qlBackground: 'black',
    tocExpand: RIVERSTONE_BLUE,
    avatarColor: '#63a6dc',
    avatarNameplateColor: 'white',
    tocBackgroundColor: 'black',
    authButtonBackground: '#181818',
  },
  hasIntroTour: false,
};
