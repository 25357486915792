import { Map } from 'immutable';
import React from 'react';
import escapeHtml from 'escape-html';
import {
  aModel,
  anObjective,
  aPractice,
  aDimension,
  aDomain,
  Domain,
  Practice,
  Model,
  Objective,
  Dimension,
  ModelTerm,
  ModelTermType,
} from 'common/graphql/graphql-hooks';

import {
  createFQNToIDMap as createFqnToIdMap,
  IModelDomain,
  IModelFile,
} from './models/model';
import { Dictionary } from '../utils/Dictionary';
import { markupTextToHtml } from '../utils/markdownUtils';

const convertHref = (fqnMap: Map<string, string>, existinghRef: string) => {
  if (!existinghRef.startsWith('practice/')) {
    return existinghRef;
  }
  const [dict, name] = existinghRef.split('/');
  return dict + '/' + fqnMap.get(name, name);
};

const addOnClicksToAnchors = (fqnMap: Map<string, string>, node) => {
  if (!node?.map) {
    // Text isn't rendered as a text node, it's just the next.
    return node;
  }
  const node2 = node.map((el, i) => {
    if (el.type !== 'a') {
      return el;
    }
    return (
      <a key={i} href="# " data-location={convertHref(fqnMap, el.props.href)}>
        {el.props.children}
      </a>
    );
  });
  return node2;
};

export const toPracticeComponentMap = (
  state: IModelFile,
  terms: { [key: string]: string }
): {
  [PracticeId: string]: JSX.Element;
} => {
  const domains = state.domains;
  const fqnMap = createFqnToIdMap(state);
  const dicts = [
    new Dictionary('practice', createPracticeToTextDict(domains)),
    new Dictionary('definition', terms),
  ];

  const practiceMap = {};
  domains.forEach((d) =>
    d.objectives.forEach((o) =>
      o.practices.forEach((p) => {
        practiceMap[p.id] = (
          <React.Fragment key={p.id}>
            {annotatedPracticeText(
              fqnMap,
              dicts,
              // Too many consecutive breaks breaks stuff and \n is rendered as a
              // space anyway.
              p.text.replace(/\n+/g, ' ')
            )}
          </React.Fragment>
        );
      })
    )
  );

  return practiceMap;
};

function createPracticeToTextDict(domains: IModelDomain[]) {
  const dict = {};
  domains.forEach((d) => {
    d.objectives.forEach((o) =>
      o.practices.forEach((p) => {
        dict[d.fqn + '-' + o.name + p.name] = p.text;
      })
    );
  });
  return dict;
}

function annotatedPracticeText(
  fqnMap: Map<string, string>,
  dicts,
  initialText: string
) {
  return addOnClicksToAnchors(
    fqnMap,
    markupTextToHtml(Dictionary.annotate(dicts, escapeHtml(initialText)))
  );
}

export const capitalizeFirstLetter = (term: string) => {
  const word = term;
  const firstLetter = word.charAt(0);
  const firstLetterCap = firstLetter.toUpperCase();
  const remainingLetters = word.slice(1);

  return firstLetterCap + remainingLetters;
};

/**
 * Generates a random mock model using the gql schema definition
 * @param domainCount count of domains in this model
 * @param objectiveCount count of objective per domain in this model
 * @param practiceCount  count of practice per objective in this model
 * @param dimensionCount count of dimensions in this model
 * @returns a random model
 */
export const generateMockModel = (
  domainCount = 10,
  objectiveCount = 1,
  practiceCount = 10,
  dimensionCount = 1
): Model => {
  const mockDomains: Domain[] = [];

  for (let i = 0; i < domainCount; i++) {
    const mockObjectives: Objective[] = [];
    for (let k = 0; k < objectiveCount; k++) {
      const mockPractices: Practice[] = [];
      for (let j = 0; j < practiceCount; j++) {
        mockPractices.push(aPractice({ legacyIdentifier: `.A${i}${j}` }));
      }
      mockObjectives.push(
        anObjective({
          practices: mockPractices,
        })
      );
    }
    mockDomains.push(aDomain({ objectives: mockObjectives }));
  }

  const mockDimensions: Dimension[] = [];
  for (let l = 0; l < dimensionCount; l++) {
    mockDimensions.push(
      aDimension({
        key: `dimension${l}`,
        name: `Dimension ${l}`,
      })
    );
  }

  const modelTerms: ModelTerm[] = [
    {
      __typename: 'ModelTerm' as const,
      id: 1,
      type: ModelTermType.Domain,
      isPlural: true,
      term: 'Domains',
    },
    {
      __typename: 'ModelTerm' as const,
      id: 2,
      type: ModelTermType.Objective,
      isPlural: true,
      term: 'Objectives',
    },
    {
      __typename: 'ModelTerm' as const,
      id: 3,
      type: ModelTermType.Practice,
      isPlural: true,
      term: 'Practices',
    },
    {
      __typename: 'ModelTerm' as const,
      id: 4,
      type: ModelTermType.Dimension,
      isPlural: true,
      term: 'Dimensions',
    },
    {
      __typename: 'ModelTerm' as const,
      id: 5,
      type: ModelTermType.Domain,
      isPlural: false,
      term: 'Domain',
    },
    {
      __typename: 'ModelTerm' as const,
      id: 6,
      type: ModelTermType.Objective,
      isPlural: false,
      term: 'Objective',
    },
    {
      __typename: 'ModelTerm' as const,
      id: 7,
      type: ModelTermType.Practice,
      isPlural: false,
      term: 'Practice',
    },
    {
      __typename: 'ModelTerm' as const,
      id: 8,
      type: ModelTermType.Dimension,
      isPlural: false,
      term: 'Dimension',
    },
  ];

  return aModel({
    dimensions: mockDimensions,
    domains: mockDomains,
    terms: modelTerms,
  });
};
