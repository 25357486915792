import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import { ModelScope } from '../databaseTypes';

import {
  IModelDimension,
  IModelLevel,
  IModelPractice,
  IModelState,
  ModelTermLookupKey,
} from '../reducers/models/model';
import {
  getFilteredAssessmentsIncludeQuicklaunch,
  getFilteredSortedAssessments,
  getLicensedModels,
  getLicensedModelsFactories,
  getModels,
  getModelsByUuid,
} from '.';
import {
  getModelScope,
  getModelUuid,
  getSelectedAssessment,
} from './assessment';
import {
  domainsForModelScope,
  getModelTierMetadata,
  practicesByModelScope,
} from 'assessments/utils/practiceTiers';
import { isNil } from 'lodash';
import { LegacyModelIdEnum } from 'common/graphql/graphql-hooks';

const typeToModel = (
  models: ReturnType<typeof getModelsByUuid>,
  modelUuid: string | null
): IModelState | undefined => {
  if (!modelUuid) {
    return;
  }
  return models.get(modelUuid);
};

export const getFirstFilteredSortedAssessment = createSelector(
  [getFilteredSortedAssessments],
  (assessmentList) => {
    return assessmentList.first(null);
  }
);

export const getModelScopeForList = createSelector(
  [getFirstFilteredSortedAssessment, getFilteredAssessmentsIncludeQuicklaunch],
  (firstAssessment, assessmentsIncludeQuicklaunch) => {
    return assessmentsIncludeQuicklaunch
      ? ModelScope.Quicklaunch
      : firstAssessment?.modelScope ?? undefined;
  }
);

export const getModelForList = createSelector(
  [getModelsByUuid, getFirstFilteredSortedAssessment],
  (models, firstAssessment) => {
    return typeToModel(models, firstAssessment && firstAssessment.model);
  }
);

export const getPracticeListForList = createSelector(
  [getModelForList, getModelScopeForList],
  (model, modelScope): List<IModelPractice> | undefined => {
    if (!model || !modelScope) {
      return undefined;
    }
    return List(practicesByModelScope(model, modelScope));
  }
);

export const getModel = createSelector(
  [getModelsByUuid, getModelUuid],
  (models, modelUuid) => {
    return typeToModel(models, modelUuid);
  }
);

export const getPracticeDimensionMap = createSelector([getModel], (model) => {
  return model?.practiceDimensionsMap ?? Map<string, IModelDimension[]>();
});

export const getModelName = createSelector(
  [getModel, getModelScope],
  (model, modelScope) => {
    return model?.factories.find((f) => f.modelScope === modelScope)
      ?.filterLabel;
  }
);

export const getDomains = createSelector(
  [getModel, getModelScope],
  (model, modelScope) =>
    !model ? undefined : domainsForModelScope(model, modelScope)
);

export const getShouldHideObjectiveHeader = createSelector(
  [getModel],
  (model) => {
    // Two level models should hide the objective header
    return model?.isTwoLevelModel;
  }
);

export const isFullModelLicensed = createSelector(
  [getModel, getLicensedModelsFactories],
  (model, licensedFactories) =>
    licensedFactories?.some(
      (factory) =>
        factory.modelUuid === model?.modelUuid &&
        factory.modelScope === ModelScope.Full
    ) ?? false
);

export const getModelLevels = createSelector([getModel], (model) =>
  model ? model.data.practiceLevels : []
);

export const getHasVariableResponseLevels = createSelector(
  [getModel],
  (model) =>
    model?.data.domains.some((domain) =>
      domain.objectives.some((objective) =>
        objective.practices.some(
          (practice) => practice?.dimensions && practice.dimensions.length > 0
        )
      )
    )
);

export const getLevelsValueToTextMap = createSelector(
  [getModelLevels],
  (levels): Map<number, string> =>
    levels ? Map(levels.map((level) => [level.value, level.text])) : Map()
);

export const getPracticeCount = createSelector(
  [getModel, getModelScope],
  (model, modelScope) => {
    if (!model) return 0;
    if (modelScope === ModelScope.Full) return model.practiceCount;
    if (model.features.hasMilScoping) {
      // Calculate cumulative milCount up to and including the given level
      const milValue = parseInt(modelScope[modelScope.length - 1]);
      let totalMilCount = 0;
      for (let i = 1; i <= milValue; i++) {
        totalMilCount += model.milCounts[i] ?? 0;
      }
      return totalMilCount;
    } else if (model.features.hasTierScoping) {
      const tier = parseInt(modelScope[modelScope.length - 1]);
      return model.tierCounts[tier] ?? 0;
    }
    return model.milCounts ? model.milCounts[1] ?? 0 : 0;
  }
);

export const getPracticeIdToModelOrderMap = createSelector(
  [getModel],
  (model): Map<string, number> =>
    model ? model.practiceIdToModelOrderMap : Map<string, number>({})
);

export const getPracticeMap = createSelector([getModel], (model) =>
  model ? model.practiceMap : Map<IModelPractice>({})
);

export const getPracticeComponentMap = createSelector([getModel], (model) =>
  model ? model.practiceComponentMap : undefined
);

export const isModelRC3 = createSelector([getModel], (model) =>
  model ? model.modelUuid === LegacyModelIdEnum.Rc3 : false
);

export const getModelTerms = createSelector([getModel], (model) =>
  model ? model.modelTermsMap : Map<ModelTermLookupKey, [string, string]>({})
);

export const getModelTitle = createSelector([getModel], (model) =>
  model ? model.data.title : ''
);

export const getModelColors = createSelector([getModel], (model) => {
  return model ? model.modelColors : {};
});

export const getSingletonAssessmentTypes = createSelector(
  [getModels],
  (models) => {
    return models
      .filter((model) => model.singletonAssessment)
      .map((model) => model.modelUuid);
  }
);

export const getIsSingleObjectiveModel = createSelector(
  [getDomains],
  (domains) =>
    domains
      ? domains.reduce((accum, domain) => {
          if (domain.objectives.length > 1) {
            return false;
          }
          return accum;
        }, true)
      : false
);

export const getPracticeCountMap = createSelector(
  [getModel, getModelScope],
  (model, modelScope) => {
    if (!model) return undefined;
    if (modelScope === ModelScope.Quicklaunch) return model.milCountsMap[1];
    const modelTierMeta = getModelTierMetadata(model, modelScope);
    const { isTieredModel, tier } = modelTierMeta;
    if (!isTieredModel || isNil(tier)) {
      return model.practiceCountsByObjective;
    }
    return model.features.hasMilScoping
      ? model.milCountsMap[tier]
      : model.tierCountsMap[tier];
  }
);

export const getModelHasMil1Report = createSelector(
  [getModel],
  (model) => !!model && model.features.reports.mil1
);

export const getModelHasFullReport = createSelector(
  [getModel],
  (model) => !!model && model.features.reports.full
);

export const getModelHasComparisonReport = createSelector(
  [getModel],
  (model) => !!model && model.features.reports.comparison
);

export const getModelHasDerivedCSFReport = createSelector(
  [getModel],
  (model) => !!model && model.features.reports.derivedCSF
);

export const getModelSupportsBitsight = createSelector(
  [getModel],
  (model) => !!model && model.features.bitsight
);

export const getModelSupportsTargetProfiles = createSelector(
  [getModel],
  (model) => !!model && model.features.targetProfiles
);

export const getModelSupportsConvertToFull = createSelector(
  [getModel],
  (model) => !!model && model.features.convertToFull
);

export const getModelSupportsToggleWizard = createSelector(
  [getModel],
  (model) => !!model && model.features.wizard
);

export const getModelSupportsMilestones = createSelector(
  [getModel],
  (model) => !!model && model.features.milestones
);

export const getModelSupportsReferenceAssessments = createSelector(
  [getModel],
  (model) => !!model && model.features.referenceAssessments
);

export const getDomainIdPracticeIdMap = createSelector(
  [getModel, getSelectedAssessment],
  (model, assessment) => {
    if (!model) {
      return undefined;
    }
    const practiceList = practicesByModelScope(model, assessment?.modelScope);
    return Map<string, string[]>(
      model.orderedDomains.map((domain) => [
        domain,
        practiceList
          .filter((practice) => practice.id.includes(domain))
          .map((practice) => practice.id),
      ])
    );
  }
);

export const getObjectiveIdPracticeIdMap = createSelector(
  [getModel, getSelectedAssessment],
  (model, assessment) => {
    if (!model) {
      return undefined;
    }
    const practiceList = practicesByModelScope(model, assessment?.modelScope);
    return Map<string, string[]>(
      model.orderedDomainsAndObjectives.map((objective) => [
        objective,
        practiceList
          .filter((practice) => practice.id.includes(objective))
          .map((practice) => practice.id),
      ])
    );
  }
);

export const getFoundationsWizardQuestions = createSelector(
  [getModel],
  (model) => model?.wizardSchema?.questions ?? []
);

export const getModelDimensionSchema = createSelector(
  [getModel],
  (model) => model?.dimensionSchema
);

export const getDimensions = createSelector(
  [getModelDimensionSchema],
  (dimensionSchema) => dimensionSchema?.dimensions ?? []
);

export const getImmutableDimensions = createSelector(
  [getDimensions],
  (dimensions) => List<IModelDimension>(dimensions)
);

export const getHasDimensions = createSelector(
  [getDimensions],
  (dimensions) => dimensions.length > 1
);

export const getFirstDimensionKey = createSelector(
  [getDimensions],
  (dimensions) => dimensions.find(Boolean)?.key ?? null
);

export const getLastDimensionKey = createSelector(
  [getDimensions],
  (dimensions) => {
    const length = dimensions.length;
    return length ? dimensions[length - 1]?.key ?? null : null;
  }
);

export const getModelDimensionMinMaxMap = createSelector(
  [getModelDimensionSchema],
  (dimensionSchema) =>
    dimensionSchema?.minMaxLevelMap ??
    Map<string, { min: number; max: number }>()
);

export const getModelDimensionMap = createSelector(
  [getModelDimensionSchema],
  (dimensionSchema) =>
    dimensionSchema?.dimensionMap ?? Map<string, IModelDimension>()
);

export const getDimensionPracticeLevelsMap = createSelector(
  [getDimensions, getModelLevels],
  (dimensions, levels) =>
    Map<string, IModelLevel[]>(
      dimensions.length
        ? dimensions.map((dimension) => [
            dimension.key,
            dimension.practiceLevels,
          ])
        : [['default', levels]]
    )
);

export const getMaxDimensionLevel = createSelector(
  [getDimensionPracticeLevelsMap],
  (dimensionLevels) =>
    dimensionLevels.toArray().reduce((acc, [_key, levels]) => {
      const dimensionMax = Math.max(...levels.map((level) => level.value));
      return dimensionMax > acc ? dimensionMax : acc;
    }, -1)
);

export const getMaxModelLevel = createSelector(
  [getHasDimensions, getModelLevels, getMaxDimensionLevel],
  (hasDimensions, modelLevels, maxDimensionLevel) =>
    hasDimensions
      ? maxDimensionLevel
      : Math.max(...modelLevels.map((level) => level.value))
);

/**
 * Returns a map of shortTitles for all models a user is licensed for. The
 * map is of type Map<string, string | null>
 */
export const getLicensedModelShortTitles = createSelector(
  [getModelsByUuid, getLicensedModels],
  (models, licensedModels) => {
    const modelShortTitles = Map<string, string | null>(
      licensedModels.map((model) => {
        const tempModel = typeToModel(models, model.modelUuid);
        const title = tempModel?.data.shortTitle ?? null;
        return [model.modelUuid, title];
      })
    );
    return modelShortTitles;
  }
);
