import jwtDecode from 'jwt-decode';
import Logger from 'common/utils/Logger';

/* Functions for managing the processing of JWT tokens */

export function getExpirationMillisFromToken(token: string | null) {
  if (!token) {
    return 0;
  }
  const claims: { exp: number } = jwtDecode(token);
  return claims.exp * 1000;
}

export function tokenIsExpired(token: string | null) {
  if (!token) {
    return true;
  }
  const millis = getExpirationMillisFromToken(token);
  return Date.now() >= millis;
}

export function getCognitoGroupsFromToken(token: string | null): string[] {
  if (!token) {
    return [];
  }
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.REACT_APP_SKIP_COGNITO
  ) {
    return [];
  }
  return jwtDecode<{}>(token)['cognito:groups'];
}

export function getCognitoEmailFromToken(token: string): string | null {
  try {
    const claims: { email: string } = jwtDecode(token);
    return claims ? claims.email : null;
  } catch (e) {
    Logger.exception(e, {
      contexts: {
        location: { file: 'tokens.tsx', function: 'getCognitoEmailFromToken' },
      },
    });
    return null;
  }
}

export function getExpirationFromToken(token: string): number | null {
  try {
    const claims: { exp: number } = jwtDecode(token);
    return claims ? claims.exp : null;
  } catch (e) {
    Logger.exception(e, {
      contexts: {
        location: { file: 'tokens.tsx', function: 'getExpirationFromToken' },
      },
    });
    return null;
  }
}

export function getCognitoUsernameFromToken(token: string): string | null {
  let claims: any;
  try {
    claims = jwtDecode(token);
  } catch (e) {
    Logger.exception(e, {
      contexts: {
        location: {
          file: 'tokens.tsx',
          function: 'getCognitoUsernameFromToken',
        },
      },
    });
    return null;
  }
  return claims ? claims['cognito:username'] : null;
}

export function checkAndClearExpiredToken() {
  if (tokenIsExpired(tokenInLocalStorage())) {
    clearLocalStorageToken();
  }
}

function clearLocalStorageToken() {
  const clientID = (window as any).c2m2_env.C2M2_COGNITO_CLIENTID;
  const userUuidKey = `CognitoIdentityServiceProvider.${clientID}.LastAuthUser`;
  const userUuid = window.localStorage.getItem(userUuidKey);
  if (userUuid) {
    const tokenKey = `CognitoIdentityServiceProvider.${clientID}.${userUuid}.idToken`;
    window.localStorage.setItem(userUuidKey, '');
    window.localStorage.setItem(tokenKey, '');
  }
}
export function tokenInLocalStorage() {
  const clientID = (window as any).c2m2_env.C2M2_COGNITO_CLIENTID;
  const userUuidKey = `CognitoIdentityServiceProvider.${clientID}.LastAuthUser`;
  const userUuid = window.localStorage.getItem(userUuidKey);
  if (!userUuid) {
    return '';
  }
  const tokenKey = `CognitoIdentityServiceProvider.${clientID}.${userUuid}.idToken`;
  return window.localStorage.getItem(tokenKey) || '';
}
