import { desaturate, transparentize, darken } from 'polished';

export const makeColorScale = (base: string) => [
  transparentize(0.8, base),
  transparentize(0.55, base),
  transparentize(0.3, base),
  base,
  darken(0.1, base),
];

// colors for the wheels
export const BASE_BLUE = '#2966B5';
export const BASE_GREEN = '#00663D';
export const BLUE_SHADES = makeColorScale(BASE_BLUE);
export const GREEN_SHADES = makeColorScale(BASE_GREEN);
export const RING_COLORS = [desaturate(1, BASE_BLUE), '#ddd'];
export const NO_ANSWER_GRAY = '#a8acb4';

export const DASHBOARD_DARK_GRAY = '#707070';
export const DASHBOARD_AXIS_GRAY = '#909090';
export const DASHBOARD_LIGHT_GRAY = '#e0e0e0';
export const DASHBOARD_BACKGROUND = '#eaeaea';
export const DASHBOARD_GREEN_BACKGROUND = '#dbf4e4';
export const DASHBOARD_GREEN_BORDER = '#63c87c';

export const GRAY_SHADES = makeColorScale(DASHBOARD_DARK_GRAY);

export const UPCOMING_LIST_BLUE = '#ecf1f8';
export const UPCOMING_LIST_GREEN = '#eef6f3';

export const AXIO_GREEN = '#34934a';

export const DARK_APPROACH = '#e66101';
export const DARK_MANAGEMENT = '#5e3c99';

export const RED = '#ff0000';

export const LIGHT_BLUE = '#D0E3F2';

export const WHITE = '#FFFFFF';
