import { push } from 'connected-react-router';
import { debounce } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';

import { getIdToken } from 'common/utils/userContext';
import Logger from 'common/utils/Logger';
import {
  CANCEL_NETWORK_SAVE_ACTION,
  FLUSH_NETWORK_SAVE_ACTION,
  SOCKETIO_ACTION_TAG,
} from 'common/actions/persistence';
import { IRootState } from '../../reducers';
import { isNetworkSaving } from '../selectors/app';
import { unsentUpdateCount } from '../selectors/pendingUpdates';
import { save } from './persistence';

/*
 * Watches for changes to the persistence layer that result in unsent
 * server updates and calls a debounced dispatch function to save them.
 *
 * This was implemented as a middleware instead of a store listener because
 * we want to be able to handle CANCEL_NETWORK_SAVE and FLUSH_NETWORK_SAVE.
 * We intercept these events and interact with debounce.  I suppose we could have
 * watched for a magic value to get set on the state (looking for app.state.flushrequested
 * or something.) but this way seemed more straight forward.
 *
 * @param {number} bounceWait - The number of milliseconds that must pass
 *        between successive calls to dispatch.
 *
 * @param {Object} socket - A socket.io socket object.
 *
 */

const ignoreForSaveMap = {
  NETWORK_TIMEOUT: true,
  REQUEUE_UPDATE: true,
};

/**
 * Determines if the passed in action needs to have an
 * authentication token injected into it by:
 *
 * a) Verifying that the action is ultimately destined
 *    for the server as is indicated by the type starting
 *    with the SOCKETIO_ACTION_TAG and ...
 * b) Vefirying that the action contains a request
 *    object and that that request object contains a
 *    AuthenticationToken element.
 */
const needsAuthToken = (action: AnyAction) =>
  !!action.type &&
  !!action.request &&
  action.type.indexOf(SOCKETIO_ACTION_TAG) === 0 &&
  'Authorization' in action.request;

/**
 * Sets the Authorization element of the action.request
 * object.  It returns the action as a convenience for
 * chaining but modifies the action object directly.
 */
const injectAuthToken = async (action: AnyAction, state: IRootState) => {
  const newToken = await getIdToken();
  action.request.Authorization = newToken;
  return action;
};

export const persistenceManager = (
  bounceWait: number,
  socket: SocketIOClient.Socket
): ThunkMiddleware<IRootState> => (store) => {
  const debouncedDispatch = debounce(store.dispatch, bounceWait);

  return (next) => (action) => {
    // If a message needs an authentication token, grab one and inject it into
    // the message.  Auth tokens are json web tokens returned from our identity
    // provider and validated by the backend.
    if (needsAuthToken(action)) {
      injectAuthToken(action, store.getState()).then((mutatedAction) => {
        if (!mutatedAction.request.Authorization) {
          Logger.warn('Bad token, routing to logout');
          store.dispatch(push('/authentication/logout', { dontNotify: false }));
          return;
        }
        next(mutatedAction);
      });
    } else {
      next(action);
    }

    // next(action) could have mutated state so we need a new copy
    const state = store.getState();

    const count = unsentUpdateCount(state);

    // If we've got unsent updates, trigger the send action.
    if (action.type && action.type === CANCEL_NETWORK_SAVE_ACTION) {
      debouncedDispatch.cancel();
    } else if (count > 0 && !ignoreForSaveMap[action.type]) {
      if (isNetworkSaving(state)) {
        // We'll wait until the save finishes
        Logger.info('Save attempt while already saving');
        return;
      }

      debouncedDispatch(save(socket, next));
      if (action.type && action.type === FLUSH_NETWORK_SAVE_ACTION) {
        debouncedDispatch.flush();
      }
    }
  };
};
