import {
  IModelDomain,
  IModelObjective,
  IModelPractice,
} from '../reducers/models/model';

export const isDomainNode = (
  node: IModelDomain | IModelObjective | IModelPractice
): node is IModelDomain => 'objectives' in node;
export const isObjectiveNode = (
  node: IModelDomain | IModelObjective | IModelPractice
): node is IModelObjective => 'practices' in node;
export const isPracticeNode = (
  node: IModelDomain | IModelObjective | IModelPractice
): node is IModelPractice => !('practices' in node) && !('objectives' in node);
