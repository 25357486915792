import { desaturate, transparentize } from 'polished';

import {
  GUIDEPOINT_BLACK,
  GUIDEPOINT_PRIMARY,
  GUIDEPOINT_TERTIARY,
  GUIDEPOINT_WHITE,
  GUIDEPOINT_SECONDARY,
} from 'common/themes/guidepoint';
import guidepointBackground from 'common/themes/guidepoint/Splash_Welcome_Background-image1.jpg';
import defaultTheme from './default';

export default {
  ...defaultTheme,
  poweredBy: false,
  poweredByBackground: true,
  tocPattern: guidepointBackground,
  authBackground: guidepointBackground,
  colors: {
    ...defaultTheme.colors,
    headerBackground: 'transparent',
    headerText: GUIDEPOINT_BLACK,
    menuSubtext: 'lightgray',
    nameplate: GUIDEPOINT_BLACK,
    qlBackground: GUIDEPOINT_BLACK,
    tocExpand: GUIDEPOINT_TERTIARY,
    primaryButton: GUIDEPOINT_PRIMARY,
    primaryButtonText: GUIDEPOINT_WHITE,
    avatarColor: GUIDEPOINT_PRIMARY,
    avatarTextColor: GUIDEPOINT_WHITE,
    avatarNameplateColor: GUIDEPOINT_BLACK,
    tocCollapseColor: GUIDEPOINT_SECONDARY,
    qlAnswerHover: desaturate(0, transparentize(0.5, GUIDEPOINT_PRIMARY)),
    qlAnswerText: GUIDEPOINT_WHITE,
    qlAnswerBackground: GUIDEPOINT_PRIMARY,
    taglineColor: GUIDEPOINT_TERTIARY,
    tocBackgroundColor: GUIDEPOINT_BLACK,
    authButtonBackground: GUIDEPOINT_PRIMARY,
  },
};
