import { csfAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { colorTable } from 'assessments/utils/nistColorTable';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_csf';

export const entergyCsfConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: true,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: true,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  shortTitle: 'Entergy NIST CSF Assessment',
  factories: [
    {
      label: 'Entergy CSF Assessment',
      filterLabel: 'CSF Assessment',
      icon: icon,
      sortKey: LegacyModelIdEnum.Entergycsf,
      modelUuid: LegacyModelIdEnum.Entergycsf,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      qualifier: 'ENTERGY',
      dashboardType: DashboardType.ENTERGYCSF_FULL,
    },
  ],
  colorTable,
  terms,
};
