import { Map } from 'immutable';
import { AnyAction, Reducer } from 'redux';

import { IUser, GranteeType } from 'common/databaseTypes';
import { IAssessment, IGrantee, IUserGrantee } from '../databaseTypes';

export type IState = Map<string, IUser & { isInternalUser: boolean }>;
export type GranteeInfo = Array<{
  id: string;
  display: string;
  isDeleted: boolean | null;
  readOnly?: boolean;
}>;

export const initialState: IState = Map({});

export const coworkerLikeReducerFactory = (
  objectType: 'user' | 'userGroup'
): Reducer<IState> => (
  state = initialState,
  action = { type: 'FakeAction' }
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      return initialState;
    }
    case 'get_resource/users':
      return convertAction(state, action, true, objectType);
    case 'get_resource/coworkers_for_user':
      return convertAction(state, action, false, objectType);
    case 'subscribe':
    case 'get_resource/assessments_for_user': {
      if (objectType === 'userGroup') {
        // NOTE that external userGroups concept is explicitly being skipped here
        return state;
      }
      const assessments: IAssessment[] =
        action.type === 'subscribe'
          ? [action?.response?.assessment]
          : action?.response?.result?._items;
      if (!assessments) {
        return state;
      }
      const allUsers = assessments.reduce<{
        [key: string]: IUser & { isInternalUser: boolean; readOnly: boolean };
      }>((accum, assessment) => {
        //owners
        if (assessment.owner && assessment.ownerInfo) {
          accum[assessment.owner] = {
            ...assessment.ownerInfo,
            _id: assessment.owner,
            isInternalUser: false,
            readOnly: false,
          };
        }
        //grantees
        const userGrantees = assessment.grantees.filter(
          isUserGranteeAndNotCorrupt
        );

        return userGrantees.reduce<{
          [key: string]: IUser & { isInternalUser: boolean; readOnly: boolean };
        }>((userAccum, grantee) => {
          userAccum[grantee.grantee] = {
            ...grantee.user,
            _id: grantee.grantee,
            isInternalUser: false,
            readOnly: grantee.read_only,
          };
          return userAccum;
        }, accum);
      }, {});

      //@ts-expect-error Invalid Overload Call
      return state.mergeWith((current, update) => {
        // isInternalUser is set by the coworkers fetch.
        // We treat all users from assessments_for_user as external, and expect corkers to either come later and true up, or already be present
        const isCurrentlyInternal =
          current?.isInternalUser || update?.isInternalUser;
        return { ...current, ...update, isInternalUser: isCurrentlyInternal };
      }, allUsers);
    }
    default:
      break;
  }

  return state;
};

export const coworkersReducer = coworkerLikeReducerFactory('user');

export function convertAction(
  state: IState,
  action: AnyAction,
  isUser: boolean,
  type: 'user' | 'userGroup'
) {
  const coworkers = isUser
    ? // get_resource/users should never return an empty array, but if it does at least don't crash
      action.response.result._items[0]
      ? action.response.result._items[0].coworkers
      : []
    : action.response.result._items;
  return convertToMap(state, coworkers || [], type);
}

const defaultType = 'user';

function convertToMap(
  state: IState,
  items: IUser[],
  type: 'user' | 'userGroup'
): IState {
  // we intentionally overwrite existing coworkers with the new set of
  // users as the get_coworkers response include the current user
  if (items && items.length) {
    const filteredItems = type
      ? items.filter(
          // @ts-expect-error SAD VICTORY decide where this error actually needs to be fixed
          (item) => item.type === type || (!item.type && type === defaultType)
        )
      : items;
    return filteredItems.reduce<
      Map<string, IUser & { isInternalUser: boolean }>
    >(
      (map, item) => map.set(item._id, { ...item, isInternalUser: true }),
      state
    );
  }
  return state;
}

function isUserGranteeAndNotCorrupt(value: IGrantee): value is IUserGrantee {
  //filter type == user
  //AND we are excluding users without emails -- that can happen if grantees
  //are corrupt (pointing to users who no longer exist)
  return (
    value.grantee_type === GranteeType.USER &&
    !!value.user &&
    !!value.user.email
  );
}
