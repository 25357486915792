import Bowser from 'bowser';
import { routerMiddleware } from 'connected-react-router';
import * as Immutable from 'immutable';
import { AnyAction, applyMiddleware, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSocketIoMiddleware from 'redux-socket.io';
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import io from 'socket.io-client';

import { idleManager } from 'assessments/actions/idleMiddleware';
import { onConnect, onDisconnect } from 'assessments/actions/persistence';
import { persistenceManager } from 'assessments/actions/persistenceMiddleware';
import {
  SOCKET_IO_NAMESPACE,
  socketFunction,
} from 'assessments/actions/socketIOSupport';
import { SOCKETIO_ACTION_TAG } from 'common/actions/persistence';
import { IAxioWindow, storageServerPath } from 'common/utils/AxioUtilities';
import rootReducer, { IRootState } from './reducers';

// In the IE11 world, colors are not supported so we'll dump them
const browser = Bowser.getParser(window.navigator.userAgent);
const isIE = browser.getBrowserName() === 'Internet Explorer';
const colorConfig = isIE ? { colors: {} } : {};
// Transform Immutable (with combineReducers)
const logger = createLogger({
  ...colorConfig,
  predicate: (getState, action) =>
    action.type !== 'IDLE_C2M2-ASSESSMENT_ACTIVITY_DETECTION' &&
    action.type !== 'IDLE_C2M2-ASSESSMENT_ACTIVITY',
  collapsed: (getState, action) => true,
  stateTransformer: (state) => {
    const newState = {};
    for (const i of Object.keys(state)) {
      if (
        process.env.NODE_ENV !== 'production' &&
        Immutable.isImmutable(state[i])
      ) {
        newState[i] = state[i].toJS();
      } else if (process.env.NODE_ENV === 'production' && isIE) {
        newState[i] = '<object>';
      } else {
        newState[i] = state[i];
      }
    }
    return newState;
  },
});

export function configureStore(
  history: any,
  initialState?: any,
  argSocket: SocketIOClient.Socket | null = null,
  log: boolean | Middleware = true
) {
  const middleware: Array<ThunkMiddleware<IRootState>> = [];
  const socketUrl = storageServerPath();

  const socket =
    argSocket ||
    io(`${socketUrl}${SOCKET_IO_NAMESPACE}`, { transports: ['websocket'] });

  const socketIoMiddleware = createSocketIoMiddleware(
    socket,
    SOCKETIO_ACTION_TAG,
    {
      eventName: 'document_action',
      execute: socketFunction.bind(null, socket) as any,
    }
  );

  // Remember that the order is important.
  middleware.push(thunk); // Support functions as actions (v. simple objects)

  // Log all of the actions that get generated.
  // log is either a boolean - meaning log or do not, there is no try  OR
  // a function that takes a store, meaning "This function is the logger"
  if (typeof log === 'function') {
    middleware.push(log);
  } else if (log) {
    middleware.push(logger);
  }

  // Build the middleware for intercepting and dispatching navigation actions
  middleware.push(routerMiddleware(history));

  middleware.push(persistenceManager(500, socket)); // Auto-trigger saves when necessary. arg is debounce delay in milliseconds.
  // see: https://lodash.com/docs/4.17.4#debounce

  middleware.push(idleManager()); // Handles logging the user out if they're idle
  middleware.push(socketIoMiddleware); // On client, actions starting with 'server/' become socket.io.emit,
  // from server, 'document_action' messages get dispatched as actions.

  const store = createStore<
    IRootState,
    AnyAction,
    { dispatch: ThunkDispatch<IRootState, void, AnyAction> },
    {}
  >(
    rootReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware)
      // any other exhancers
    )
  );
  (window as Window & IAxioWindow).c2m2Store = store;

  socket.on('connect', () => {
    store.dispatch(onConnect(socket));
  });

  // on reconnection, reset the transports option, as the Websocket
  // connection may have failed (caused by proxy, firewall, browser, ...)
  socket.on('reconnect_attempt', () => {
    socket.io.opts.transports = ['polling', 'websocket'];
  });

  socket.on('disconnect', () => {
    store.dispatch(onDisconnect(socket));
  });

  socket.on('error', (e) => {
    store.dispatch({ type: 'socket.io/error', error: e });
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default(history);
      store.replaceReducer(nextRootReducer);
    });
  }

  (store as typeof store & { c2m2Socket: typeof socket }).c2m2Socket = socket; // Make available to anyone needing the store.

  return store;
}
