import { Map } from 'immutable';
import { createSelector } from 'reselect';

import Logger from 'common/utils/Logger';

import { IResponseForEve } from '../reducers/pendingUpdates';
import {
  getAllPendingUpdates,
  getResponses,
  getSelectedAssessmentId,
} from '../selectors';

export const getPendingUpdates = createSelector(
  [getAllPendingUpdates, getSelectedAssessmentId],
  (pendingUpdates, selectedAssessmentId) =>
    pendingUpdates.get(selectedAssessmentId, Map<IResponseForEve>({}))
);

export const unsentUpdateCount = createSelector(
  [getPendingUpdates],
  (pendingUpdates) => pendingUpdates.count()
);

export const getUnsentUpdates = createSelector(
  [getPendingUpdates, getResponses],
  (pendingUpdates, responses) =>
    // Our shape is two objects, one with the pending network requests and the other
    // with all of the database Ids and etags we've ever seen key'd by ID.

    // We're going to this into an array and move the id into the object itself
    // and stick the _etag onto the object as well
    // because that's more friendly for the REST API we use to save.
    {
      const finalUpdates: { [id: string]: IResponseForEve } = {};
      const ids = [] as string[];

      pendingUpdates.forEach((item, id) => {
        // It should never be the case that we have an item in unsent that is null
        // or has a null key.  BUT... if we got junk - maybe due to bad data in
        // a localStorage pendingUpates, we'll clean it up so as not to cause issues.
        if (item && id) {
          const response = responses.get(id);
          if (!response) {
            Logger.error('Trash in getUnsentUpdates', {
              contexts: { location: { file: 'pendingUpdates.ts', item, id } },
            });
            return;
          }
          const { _etag, _id } = response;
          finalUpdates[id] = { ...item, _etag, _id };
          ids.push(id);
        } else {
          Logger.error('Trash in getUnsentUpdates', {
            contexts: { location: { file: 'pendingUpdates.ts', item, id } },
          });
        }
      });

      return { items: finalUpdates, ids };
    }
);
