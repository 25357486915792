import { routerActions } from 'connected-react-router';
import { AnyAction, Store as BaseReduxStore } from 'redux';
import {
  createPersistoid,
  getStoredState,
  PersistConfig,
  Persistoid,
} from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import localStorage from 'redux-persist/lib/storage';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';

import { getIdToken } from 'common/utils/userContext';
import Logger from 'common/utils/Logger';
import u, {
  excelUploadServerPath,
  IAxioWindow,
} from 'common/utils/AxioUtilities';
import { getUserJS, hasAssessmentOverTimeLicense } from 'common/selectors/user';
import { getLoading, getUser } from 'common/selectors';
import { ID_FIELD } from 'common/constants';
import {
  fetchUserCoworkers,
  socketIOMessage,
} from 'common/actions/persistence';
import { IRootState } from '../../reducers';
import { ASSESSMENT_ID_FIELD, PRACTICE_ID_FIELD } from '../constants';
import { IAssessment, IMilestone, ModelScope } from '../databaseTypes';
import { Position, IWizardPosition } from '../qlTypes';
import { getLastUpdateDate } from '../reducers/getters';
import {
  ASSESSMENT_DOMAIN,
  ASSESSMENT_LIST_DOMAIN,
  GRANTEES_DOMAIN,
  IResponseForEve,
  JOURNAL_DOMAIN,
  MILESTONE_DOMAIN,
  PRACTICE_DOMAIN,
  SUBSIDIARY_LIST_DOMAIN,
  TAGS_DOMAIN,
} from '../reducers/pendingUpdates';
import {
  getAllAssessments,
  getAllBenchmarks,
  getAllCoworkers,
  getAllMilestones,
  getAllResponses,
  getAllTags,
  getAllCompanyBenchmark,
  getBitsightCSF,
  getComparisonAssessmentId,
  getComparisonAssessmentResponses,
  getFilteredAssessmentIds,
  getSelectedAssessmentId,
  hasFetchedLevelHistory,
  hasFetchedResponses,
  hasFetchedStatusHistory,
  getSelectedMilestoneId,
} from '../selectors';
import { isSubscribed } from '../selectors/app';
import {
  getModelUuid,
  getSelectedAssessment,
  getModelScope,
} from '../selectors/assessment';
import {
  getSelectedMilestone,
  hasFetchedSelectedMilestoneResponses,
  getMilestonesToDisplay,
  hasFetchedPastMilestoneResponses,
  getSelectedOrMostRecentPastMilestone,
} from '../selectors/milestone';
import { getUnsentUpdates } from '../selectors/pendingUpdates';
import { getCompanyBitsightPractices } from './bitsight';
import {
  FLUSH_NETWORK_SAVE_ACTION,
  setActiveAssessmentMessage,
  updateAssessmentMessage,
  updateMilestoneMessage,
} from './socketIOSupport';
import { setReferenceAssessments, turnOffModeSpinner } from '.';
import { toasterError } from 'common/notifications';
import { getWizardPosition } from 'assessments/selectors/quicklaunch';

type Dispatch = ThunkDispatch<IRootState, void, AnyAction>;
type AnyThunkAction = ThunkAction<any, IRootState, void, AnyAction>;

type Store = BaseReduxStore<IRootState, AnyAction> & {
  dispatch: ThunkDispatch<IRootState, void, AnyAction>;
};
export const RESPONSE_WAITTIME = 10000; // 10 seconds.

let persistor: any = null;

const arrayDedeup = <T>(arr: T[]) => {
  return arr.reduce((accum, current) => {
    if (!accum.includes(current)) {
      accum.push(current);
    }
    return accum;
  }, [] as T[]);
};

const baseIfThere = (state: IRootState, dispatch: Dispatch) => (
  checkerFunction: (state: IRootState) => boolean,
  loadingFlag: string,
  fetcherAction: AnyThunkAction
) => {
  const isLoading = getLoading(state).get(loadingFlag, false);
  if (!isLoading && checkerFunction(state)) {
    dispatch(fetcherAction);
  }
};

export const updateScope = (
  changes: Partial<IAssessment>,
  assessment: IAssessment | null = null,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const doc = assessment || getSelectedAssessment(state);
  if (!doc) {
    Logger.error('Attempt to call updateScope with no assessment');
    return;
  }

  // If they're there, dedup the scopetags.
  const tags = (changes.scopetags && arrayDedeup(changes.scopetags)) || null;
  const updateObject = { ...changes, _id: doc._id, _etag: doc._etag };
  if (tags) {
    updateObject.scopetags = tags;
  }
  dispatch(
    updateAssessmentMessage(
      updateObject,
      undefined,
      undefined,
      undefined,
      afterAction
    )
  );
};

export const updateModelScope = (
  scope: ModelScope,
  afterAction: AnyAction | ThunkAction<any, any, any, any> | null = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const doc = getSelectedAssessment(state);
  if (!doc) {
    Logger.error('Call to updateModelScope with no assessment');
    return;
  }

  dispatch(
    updateAssessmentMessage(
      {
        modelScope: scope,
        _id: doc._id,
        _etag: doc._etag,
      },
      undefined,
      undefined,
      undefined,
      afterAction
    )
  );
};

export const updateAssessmentOwner = (
  assessmentId: string,
  newOwner: string,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const doc = getAllAssessments(state).get(assessmentId);
  if (!doc) {
    Logger.error('Call to updateAssessmentOwner with no assessment');
    return;
  }

  dispatch(
    updateAssessmentMessage(
      {
        owner: newOwner,
        _id: doc._id,
        _etag: doc._etag,
      },
      undefined,
      undefined,
      undefined,
      afterAction
    )
  );
};

export const copyTargetLevelsFromExisting = (
  targetProfileAssessmentId: string | null = '',
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const doc = getSelectedAssessment(state);
  if (!doc) {
    Logger.error('Call to copyTargetLevelsFromExisting with no assessment');
    return;
  }

  dispatch(
    updateAssessmentMessage(
      { update_target_profile: true },
      doc._id,
      doc._etag,
      { target_profile: targetProfileAssessmentId },
      afterAction
    )
  );
};

export const makeAssessmentActive = (id: string, afterAction: any = null) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch({ type: 'ASSESSMENT_LOAD_START', assessment: id });
  const user = { ...getUserJS(getState()), active_assessment: id };
  dispatch(
    setActiveAssessmentMessage(user, afterAction, false, turnOffModeSpinner())
  );
};

export const changeQuicklaunchPosition = (
  update: (position: IWizardPosition) => Position
) => (
  dispatch: Dispatch,
  getState: () => IRootState,
  afterAction: any = null
) => {
  const state = getState();
  const assessment = getSelectedAssessment(state);
  if (!assessment) {
    Logger.error('Call to changeQuicklaunchPosition with no assessment');
    return;
  }
  const wizardPosition = getWizardPosition(state);
  const newPosition = update(wizardPosition);

  // Update the position in storage for the active assessment
  dispatch(
    updateAssessmentMessage(
      {
        _id: assessment._id,
        _etag: assessment._etag,
        quicklaunch_position: String(newPosition) as Position,
      },
      undefined,
      undefined,
      undefined,
      afterAction
    )
  );
};

export const basePersistConfig: PersistConfig = {
  version: 1,
  storage: localStorage,
  key: 'unknown',
  keyPrefix: 'c2m2:',
  whitelist: ['pendingUpdates'],
  transforms: [immutableTransform()],
};

export const beginPersistingChanges = (
  dispatch: Dispatch,
  store: Store = (window as Window & IAxioWindow).c2m2Store
) => {
  const assessmentId = getSelectedAssessmentId(store.getState());
  const config = { ...basePersistConfig, key: assessmentId || 'unknown' };

  // We only want one of these running, no matter how many times
  // we're called.
  if (persistor) {
    persistor.unsubscribe();
    persistor = null;
  }

  getStoredState(config)
    .then((state) => {
      dispatch({
        type: 'REHYDRATE',
        [ASSESSMENT_ID_FIELD]: assessmentId,
        payload: state,
      });
    })
    .catch((e: Error) => {
      Logger.exception(e, {
        contexts: {
          location: {
            file: 'persistence.ts',
            function: 'beginPersistingChanges',
          },
        },
      });
      dispatch({
        type: 'REHYDRATE',
        [ASSESSMENT_ID_FIELD]: assessmentId,
      });
    });

  const persistoid = createPersistoid(config);
  const unsubscribeFromStore = store.subscribe(() => {
    persistoid.update(store.getState());
  });

  return { persistor: persistoid, unsubscribe: unsubscribeFromStore };
};

const rehydrateThunk = () => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  persistor = beginPersistingChanges(dispatch);
};

export const createJournalEntry = (
  assessmentId: string,
  userId: string,
  occurred: Date,
  description: string,
  action: Record<string, any>,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const data = {
    [ASSESSMENT_ID_FIELD]: assessmentId,
    user: userId,
    occurred,
    description,
    action,
  };

  dispatch(
    socketIOMessage(
      getState(),
      'post_resource',
      { resource: JOURNAL_DOMAIN, data },
      afterAction
    )
  );
};

export const shareAssessment = (
  assessment: string,
  grantees: Array<{ id: string; type: string }>,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const data = grantees.map((grantee) => {
    return {
      item: assessment,
      type: 'assessment',
      grantee: grantee.id,
      grantee_type: grantee.type,
    };
  });
  dispatch(
    socketIOMessage(
      getState(),
      'post_resource',
      { resource: GRANTEES_DOMAIN, data },
      afterAction
    )
  );
};

export const deleteGrantee = (
  _id: string,
  _etag: string,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(
    socketIOMessage(
      getState(),
      'delete_resource',
      {
        resource: GRANTEES_DOMAIN,
        _id,
        etag: _etag,
        type: 'assessment',
      },
      afterAction
    )
  );
};

export const fetchAssessmentGrantees = (
  assessmentId: string,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  if (!assessmentId) {
    Logger.error('Attempt to call fetchAssessmentGrantees with no assessment');
  }

  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      {
        resource: GRANTEES_DOMAIN,
        lookup: { item: assessmentId, type: 'assessment' },
      },
      afterAction,
      true
    )
  );
};

export const setErrorMessage = (errorClass: string, errorMessage: string) => ({
  type: 'SET_ERROR_MESSAGE',
  errorClass,
  errorMessage,
});

export const conditionalFetchUserCoworkers = (afterAction: any = null) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();
  // Don't want to fetch if we have already requested
  const ifThere = baseIfThere(state, dispatch);
  ifThere(
    arentCoWorkers,
    'get_coworkers_for_user',
    fetchUserCoworkers(afterAction)
  );
};

export const fetchScopeTags = (afterAction: any = null) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();

  dispatch(
    socketIOMessage(
      state,
      'get_resource',
      {
        resource: TAGS_DOMAIN,
        type: { $in: ['default', 'assessment'] },
      },
      afterAction,
      true
    )
  );
};

export const fetchPractices = (
  assessmentId: string | string[],
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      {
        resource: PRACTICE_DOMAIN,
        [ASSESSMENT_ID_FIELD]: assessmentId,
      },
      afterAction,
      true
    )
  );
};

export const fetchMissingPractices = (assessmentIds: string[]) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();
  const allResponses = getAllResponses(state);
  const allMilestones = getAllMilestones(state);
  const assessmentsToFetch = assessmentIds
    .filter((assessmentId) => !assessmentId.includes('.'))
    .filter((assessmentId) => !allResponses.has(assessmentId));
  const milestonesToFetch = assessmentIds
    .filter((assessmentId) => assessmentId.includes('.'))
    .filter((assessmentId) => {
      const [aid, mid] = assessmentId.split('.');
      return !allMilestones.getIn([aid, mid, 'responses']);
    })
    .map((e) => {
      const [aid, mid] = e.split('.');
      return {
        _id: mid,
        [ASSESSMENT_ID_FIELD]: aid,
        _created: '',
        _etag: '',
        milestone_date: '',
        name: '',
      };
    });
  milestonesToFetch.forEach((e) => dispatch(fetchResponsesAtMilestone(e)));
  if (assessmentsToFetch.length) {
    dispatch(fetchPractices(assessmentsToFetch));
  }
};

export const loadReferenceAssessments = (assessmentIds: string[] = []) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(fetchMissingPractices(assessmentIds));
  dispatch(setReferenceAssessments(assessmentIds));
};

const thereAreThings = (state: IRootState, getterFunction) => {
  const thing = getterFunction(state);
  return thing && thing.size;
};
const arentTags = (state: IRootState) => {
  return !thereAreThings(state, getAllTags);
};

const arentCoWorkers = (state: IRootState) => {
  // If you don't have a company, we've already loaded coworkers for you.
  const user = getUser(state);
  if (!user || !user.get('employer', false)) {
    return false;
  }
  const coworkers = getAllCoworkers(state);
  return !coworkers || !coworkers.size;
};

const areAssessments = (state: IRootState) => {
  return thereAreThings(state, getAllAssessments);
};

const arentAssessments = (state: IRootState) => {
  return !areAssessments(state);
};

const areAssessmentsButNotResponsesAtMilestone = (state: IRootState) => {
  return areAssessments(state) && arentResponsesOnFetchedMilestone(state);
};
const arentResponsesOnFetchedMilestone = (state: IRootState) => {
  return (
    !!getSelectedOrMostRecentPastMilestone(state) &&
    !hasFetchedPastMilestoneResponses(state)
  );
};

const areAssessmentsButNotBenchmarks = (state: IRootState) => {
  return areAssessments(state) && arentBenchmarks(state);
};

const arentBenchmarks = (state: IRootState) => {
  // Can't use my "thereAreThings" call here because
  // benchmarks is an object, not a map.  (boohoo)
  const allCompanyBenchmarks = getAllCompanyBenchmark(state);
  return !allCompanyBenchmarks || !Object.keys(allCompanyBenchmarks).length;
};

const arentSelectedAssessmentResponses = (state: IRootState) => {
  return !hasFetchedResponses(state);
};

const isntLevelHistoryData = (state: IRootState) => {
  return !hasFetchedLevelHistory(state) && hasAssessmentOverTimeLicense(state);
};

const isntStatusHistoryData = (state: IRootState) => {
  return !hasFetchedStatusHistory(state);
};

const arentComparisonAssessmentResponses = (state: IRootState) => {
  return (
    !!getComparisonAssessmentId(state) &&
    !thereAreThings(state, getComparisonAssessmentResponses)
  );
};

const arentSelectedMilestoneResponses = (state: IRootState) => {
  return (
    !!getSelectedMilestoneId(state) &&
    !hasFetchedSelectedMilestoneResponses(state)
  );
};

const arentPastMilestoneResponses = (state: IRootState) => {
  return (
    !!getMilestonesToDisplay(state)?.past &&
    !getMilestonesToDisplay(state)?.past.isComparisonAssessment &&
    !hasFetchedPastMilestoneResponses(state)
  );
};

const arentBitsightResponsesButHasKey = (state: IRootState) => {
  const user = getUserJS(state);
  const hasBitsightKey = !!user?.employer?.hasBitsightKey;
  // only checking CSF responses, but as both models are always retrurned, either both are present or none are
  const bitsightCsfResponses = getBitsightCSF(state);
  const hasBitsightResponses = Boolean(bitsightCsfResponses);
  return hasBitsightKey && !hasBitsightResponses;
};

export const getInitialData = (assessmentId: string) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  // Anything we already have, we're not going to re-fetch.
  dispatch(
    fetchPractices(assessmentId, () => {
      dispatch(rehydrateThunk());
      dispatch(fetchUserCoworkers());
      const state = getState();
      const ifThere = (
        checkerFunction: (state: IRootState) => boolean,
        fetcherAction: AnyThunkAction
      ) => {
        if (checkerFunction(state)) {
          dispatch(fetcherAction);
        }
      };

      ifThere(isntLevelHistoryData, fetchLevelHistory(assessmentId));
      ifThere(arentTags, fetchScopeTags());
      ifThere(arentAssessments, fetchAssessments());
      ifThere(
        areAssessmentsButNotResponsesAtMilestone,
        fetchResponsesAtMilestone(getSelectedOrMostRecentPastMilestone(state))
      );

      ifThere(areAssessmentsButNotBenchmarks, fetchBenchmarks(assessmentId));
      ifThere(arentBitsightResponsesButHasKey, getCompanyBitsightPractices());
    })
  );
};

export const getAssessmentSummaryData = () => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();
  const assessmentId = getSelectedAssessmentId(state);
  if (!assessmentId) {
    return;
  }
  const comparisonId = getComparisonAssessmentId(state);

  // Don't want to fetch if we have already requested
  const ifThere = baseIfThere(state, dispatch);

  /*
  We assume that tags, coworkers and assessments were fetched by the top level
  dashboard.  This function is really get the data for the main section
  ifThere(arentTags, 'get_tags', fetchScopeTags());
  ifThere(arentCoWorkers, 'get_coworkers_for_user', fetchUserCoworkers());
  ifThere(
    arentAssessments,
    'get_assessments_for_user',
    fetchAssessments(
      // Chain the benchmarks fetch to the assessments fetch because
      // the benchmarks fetch requires the assessments list to be
      // retrieved before it will work.
      arentBenchmarks(state) ? fetchBenchmarks(assessmentId) : null
    )
  );
  */

  // If we had assessments (so skipped the fetch above) but
  // don't have benchmarks, we need to fetch just the benchmarks bit.
  ifThere(
    areAssessmentsButNotBenchmarks,
    'get_benchmarks',
    fetchBenchmarks(assessmentId)
  );

  ifThere(
    arentSelectedAssessmentResponses,
    'get_responses',
    fetchPractices(assessmentId)
  );

  ifThere(
    isntLevelHistoryData,
    'get_responses_level_history',
    fetchLevelHistory(assessmentId)
  );

  ifThere(
    arentComparisonAssessmentResponses,
    'get_responses',
    fetchPractices(comparisonId)
  );

  ifThere(
    arentSelectedMilestoneResponses,
    'get_responses_at_milestone',
    fetchResponsesAtMilestone(getSelectedMilestone(state))
  );

  ifThere(
    arentPastMilestoneResponses,
    'get_responses_at_milestone',
    fetchResponsesAtMilestone(getSelectedOrMostRecentPastMilestone(state))
  );

  ifThere(
    isntStatusHistoryData,
    'get_assessment_status_history',
    fetchAssessmentStatusHistory(assessmentId)
  );

  ifThere(
    arentBitsightResponsesButHasKey,
    'bitsightLoading',
    getCompanyBitsightPractices()
  );
};

export const getAssessmentPlanningData = () => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();

  const assessmentId = getSelectedAssessmentId(state);
  if (!assessmentId) {
    return;
  }
  // Don't want to fetch if we have already requested
  const ifThere = baseIfThere(state, dispatch);

  ifThere(
    arentSelectedAssessmentResponses,
    'get_responses',
    fetchPractices(assessmentId)
  );
};

export const getAggregateDashboardData = () => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();

  const assessmentIds = getFilteredAssessmentIds(state);
  const allBenchmarks = getAllBenchmarks(state);
  const missingBenchmarkIds = assessmentIds.filter(
    (id) => !allBenchmarks.has(id)
  );
  const allResponses = getAllResponses(state);
  const missingResponseIds = assessmentIds.filter(
    (id) => !allResponses.has(id)
  );

  // Don't want to fetch if we have already requested
  const ifNot = (
    check: boolean,
    loadingFlag: string,
    fetcherAction: AnyThunkAction
  ) => {
    const isLoading = getLoading(state).get(loadingFlag, false);
    if (check && !isLoading) {
      dispatch(fetcherAction);
    }
  };

  ifNot(
    Boolean(missingBenchmarkIds.length),
    'get_benchmarks',
    fetchBenchmarks(missingBenchmarkIds)
  );
  ifNot(
    Boolean(missingResponseIds.length),
    'get_responses',
    fetchPractices(missingResponseIds)
  );
};

export const enterAssessment = (
  assessmentId: string,
  practiceHash?: string
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const responses = getAllResponses(getState()).get(assessmentId);
  if (responses) {
    dispatch(resubscribe(assessmentId, rehydrateThunk()));
  }
  const optionalHash = practiceHash ? practiceHash : '';
  dispatch(
    routerActions.push(`/assessments/assessment/${assessmentId}${optionalHash}`)
  );
};

export const onDisconnect = (socketio: any) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  // If we disconnect, we'll just let other folks know.
  dispatch({ type: 'NETWORK_DISCONNECT' });
};

export const onConnect = (socketio: any) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  // First, we'll just dispatch the fact that a connect happened.  Then, we'll see if we
  // think we're subscribed.  If we do, we'll issue a resubscribe with the date of the
  // last update message we saw - so that the server can give us anything that's
  // happened in the mean time.
  dispatch({ type: 'NETWORK_CONNECT' });
  const state = getState();
  if (isSubscribed(state)) {
    // We need to know the date of the last update and the assessment
    // that we're currently subscribed to.
    const assessment = getSelectedAssessment(state);
    if (!assessment) {
      Logger.warn(
        "Subscribed without an active assessment. Can't resubscribe."
      );
      return;
    }
    dispatch(resubscribe(assessment._id));
  }
};

export const subscribe = (
  argRoom?: string | ((state: IRootState) => string),
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const room = typeof argRoom === 'function' ? argRoom(state) : argRoom || '';

  dispatch({ type: 'SUBSCRIBING', room });
  dispatch(socketIOMessage(state, 'subscribe', { room }, afterAction, true));
};

// We're going to undo the fiction that "room" can be something
// other than an assessment id.  If we later support re-subscribing
// to something else, we'll solve that problem then.
export const resubscribe = (
  argAssessmentId?: string | ((args: any) => string),
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const assessmentId =
    typeof argAssessmentId === 'function'
      ? argAssessmentId(state)
      : argAssessmentId || '';

  const lastUpdate = getLastUpdateDate(state, assessmentId);

  dispatch({ type: 'SUBSCRIBING', room: assessmentId });
  dispatch(
    socketIOMessage(
      state,
      'resubscribe',
      {
        room: assessmentId,
        lastUpdate,
      },
      afterAction,
      true
    )
  );
};

// Exported for testing
export function _unsubscribe(
  dispatch: Dispatch,
  persistorArg: {
    persistor: Persistoid | null;
    unsubscribe: () => void;
  } | null,
  action: AnyAction
) {
  if (persistorArg) {
    // Make sure we stop persisting changes to pending so that we have something
    // to restore.
    persistorArg.unsubscribe();
    persistor = null;
  }

  dispatch(action);
}

export const unsubscribe = (
  argRoom?: string | ((args: any) => string),
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const room = typeof argRoom === 'function' ? argRoom(state) : argRoom || '';

  _unsubscribe(
    dispatch,
    persistor,
    socketIOMessage(state, 'unsubscribe', { room }, afterAction)
  );
};

export const createJournalEntryAndNavigateToActiveAssessment = (
  journaledAction: Record<string, any>
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const assessmentId = getSelectedAssessmentId(getState());
  const user = getUser(getState());
  const userId = user && user.get('_id');
  dispatch(
    createJournalEntry(
      assessmentId,
      userId,
      new Date(),
      'Created new assessment.',
      journaledAction
    )
  );
  dispatch(routerActions.push(`/assessments/assessment/${assessmentId}`));
};

export const openNewAssessment = (
  model: string,
  name: string,
  argModelScope: ModelScope,
  preparedFor: string | null,
  scopeDescription: string | null,
  targetProfileAssessmentId: string | null,
  tags: string[] | null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();

  dispatch({ type: 'ASSESSMENT_LOAD_START', assessment: '' });

  const user = getUser(state);
  const userId = user && user.get('_id');
  const modelScope = argModelScope;
  const description = scopeDescription;

  const targetProfileId =
    modelScope === (ModelScope.Quicklaunch || ModelScope.Mil1) &&
    !targetProfileAssessmentId
      ? 'MIL1'
      : targetProfileAssessmentId;

  const action = {
    target_profile: targetProfileId,
    resource: ASSESSMENT_DOMAIN,
    userId,
    set_active: true,
    data: {
      name,
      model,
      modelScope,
      preparedFor,
      description,
      quicklaunch_position: 'intro',
      scopetags: tags,
    },
  };

  dispatch(createJournalEntryAndNavigateToActiveAssessment(action));
};

export const fetchAssessments = (afterAction: any = null) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      { resource: ASSESSMENT_LIST_DOMAIN },
      afterAction,
      true
    )
  );
};

export const fetchSubsidiaries = (afterAction: any = null) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      { resource: SUBSIDIARY_LIST_DOMAIN },
      afterAction,
      true
    )
  );
};

export const deleteAssessment = (
  assessmentId: string,
  etag: string,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(
    socketIOMessage(
      getState(),
      'delete_resource',
      { resource: ASSESSMENT_DOMAIN, _id: assessmentId, etag },
      afterAction
    )
  );
};

export const createMilestone = (
  milestoneName: string,
  milestoneDate: string,
  assessmentId?: string,
  files?: FileList | null
) => async (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const aId = assessmentId || getSelectedAssessmentId(state);
  if (!aId) {
    Logger.error('Attempt to call createMilestone with no assessment');
    return;
  }
  if (!files) {
    dispatch(
      socketIOMessage(getState(), 'post_resource', {
        resource: MILESTONE_DOMAIN,
        data: {
          [ASSESSMENT_ID_FIELD]: aId,
          name: milestoneName,
          milestone_date: milestoneDate,
        },
      })
    );
  } else {
    const authToken = getIdToken();
    const url =
      u.addEnvironmentToUrl(excelUploadServerPath()) +
      encodeURI(
        `?assessment=${aId}&milestoneName=${milestoneName}&milestoneDate=${milestoneDate}`
      );
    // onProgress probably doesn't even need to be supplied
    const onProgress = () => {
      return;
    }; // Do nothing on progress
    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const data = new FormData();
      data.append('file', files[0]);
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + (authToken || ''));
      xhr.upload.addEventListener('progress', onProgress);
      xhr.addEventListener('readystatechange', (event) => {
        if (xhr.readyState === 4) {
          resolve(null);
        }
      });
      xhr.upload.addEventListener('error', reject);
      xhr.send(data);
    });
  }
};

export const updateMilestone = (
  newData: { name: string; milestone_date: string },
  milestone: IMilestone,
  etag: string,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const {
    _id: milestoneId,
    _etag: milestoneEtag,
    [ASSESSMENT_ID_FIELD]: assessmentId,
  } = milestone;

  dispatch(
    updateMilestoneMessage(
      newData,
      milestoneId,
      milestoneEtag,
      assessmentId,
      afterAction
    )
  );
};

export const deleteMilestone = (
  milestone: IMilestone,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const {
    _id: milestoneId,
    _etag: etag,
    [ASSESSMENT_ID_FIELD]: assessmentId,
  } = milestone;

  dispatch(
    socketIOMessage(
      getState(),
      'delete_resource',
      {
        resource: MILESTONE_DOMAIN,
        _id: milestoneId,
        [ASSESSMENT_ID_FIELD]: assessmentId,
        etag,
      },
      afterAction
    )
  );
};

/**
 * Sent to the server to retrieve the level values for any day on which
 * level values changed.
 */
export const fetchLevelHistory = (
  assessmentId: string,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  const modelUuid = getModelUuid(state);
  const modelScope = getModelScope(state);
  // We're not going to try the fetch if we don't have
  // a modelUuid or scope.
  if (!modelUuid || !modelScope) {
    return;
  }
  dispatch(
    socketIOMessage(
      state,
      'get_resource',
      {
        resource: 'responses_level_history',
        [ASSESSMENT_ID_FIELD]: assessmentId,
        assessment: assessmentId,
        modelUuid,
        modelScope,
      },
      afterAction,
      true
    )
  );
};
/**
 * Sent to the server to retrieve the status history associated with
 * the selected assessment.
 */
export const fetchAssessmentStatusHistory = (
  assessmentId: string,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  // If there is no assessment ID (we're selected nothing)
  // you can't get status, so bail.
  if (!assessmentId) {
    return;
  }
  const currentAssessment = getAllAssessments(state)
    .filter((assessment) => assessmentId === assessment._id)
    .valueSeq()
    .toArray()[0];

  if (!currentAssessment) {
    return;
  }

  dispatch(
    socketIOMessage(
      state,
      'get_resource',
      {
        resource: 'assessment_status_history',
        [ASSESSMENT_ID_FIELD]: assessmentId,
        assessment: assessmentId,
      },
      afterAction,
      true
    )
  );
};

/**
 * Sent to the server to retrieve the benchmark data associated with
 * the selected assessment.  Right now, we get the overall data.  Later,
 * we'll get a more refined set of data.
 */
export const fetchBenchmarks = (
  assessmentId: string | string[],
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const state = getState();
  // If there is no assessment ID (we're selected nothing)
  // you can't get benchmarks, so bail.
  if (!assessmentId) {
    return;
  }
  const assessmentIdArr = Array.isArray(assessmentId)
    ? assessmentId
    : [assessmentId];
  const assessments = getAllAssessments(state)
    .filter((assessment) => assessmentIdArr.includes(assessment._id))
    .valueSeq()
    .toArray();
  const modelUuids = assessments.map((assessment) => assessment.model);
  const modelScopes = assessments.map((assessment) => assessment.modelScope);

  // We can't fetch benchmarks unless we have a type and scope.
  // If we do, we'll get back "undefined", which effectively
  // poisons the cache and means the next fetch of benchmarks
  // won't work.  So... don't do that.
  if (!modelUuids.length || !modelScopes.length) {
    Logger.warn('Attempt to get benchmarks with bad type or scope.', {
      contexts: {
        location: {
          file: 'persistence.ts',
          function: 'fetchBenchmarks',
          modelUuid: [...new Set(modelUuids)],
          modelScope: [...new Set(modelScopes)],
        },
      },
    });
    return;
  }

  dispatch(
    socketIOMessage(
      state,
      'get_resource',
      {
        resource: 'benchmarks',
        [ASSESSMENT_ID_FIELD]: assessments.map((assessment) => ({
          _id: assessment._id,
          modelUuid: assessment.model,
          modelScope: assessment.modelScope,
        })),
        modelUuid: [...new Set(modelUuids)],
        modelScope: [...new Set(modelScopes)],
        scopeTags: [],
      },
      afterAction,
      true
    )
  );
};

/*
 * Sent to the server to load all of the responses at a particular
 * milestone.  The expectation is that we'll be passed a milestone
 * object.
 */
export const fetchResponsesAtMilestone = (
  milestone: IMilestone | null,
  afterAction: any = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  if (!milestone) {
    return;
  }
  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      {
        resource: 'responses_at_milestone',
        milestone: milestone._id,
        [ASSESSMENT_ID_FIELD]: milestone[ASSESSMENT_ID_FIELD],
      },
      afterAction,
      true
    )
  );
};

/**
 * If someone calls save, we'll just dispatch an action "FLUSH_NETWORK_SAVE_ACTION".  The
 * expectation is that our persistenceManager middleware will see this and flush
 * pending saves by calling flush on the debounced function.
 *
 * @return {function} - Like all thunk actions, it returns a function that will be
 *          dispatched.
 */
export const saveNow = () => ({
  type: FLUSH_NETWORK_SAVE_ACTION,
});

/**
 * Creates the timer that indicates a network response never came back.
 */
function createNetworkTimeout(assessmentId, practicesMap) {
  return (
    socket: SocketIOClient.Socket,
    action: AnyAction,
    emit: any,
    next: Dispatch,
    dispatch: Dispatch,
    retries: number
  ) => {
    const timerId = window.setTimeout(() => {
      dispatch({
        type: 'NETWORK_TIMEOUT',
        unsent: { items: practicesMap },
        [ASSESSMENT_ID_FIELD]: assessmentId,
        networkTimeoutTimerId: timerId,
      });
    }, RESPONSE_WAITTIME);
    return timerId;
  };
}

function validateSavingPractice(assessmentId, practice) {
  // New practices have to have an assessment id and a model id.  Existing
  // practices should have either - since that isn't something we expect to
  // change as a part of a patch.

  if (!practice._etag) {
    // This is a new practice and so we must have an assessment ID and CANT'T have a database ID.
    if (!assessmentId) {
      Logger.error(
        'Attempt to save a new practice with no active assessment.',
        { contexts: { practice } }
      );
      return false;
    }
    if (practice[ID_FIELD]) {
      Logger.error(
        'Attempt to save a practice with no etag that already has a database id',
        { contexts: { practice } }
      );
      return false;
    }
  } else if (!practice[ID_FIELD]) {
    Logger.error('Attempt to save a practice with an etag but no datbase id', {
      contexts: { practice },
    });
    return false;
  }

  return true;
}

export function networkSaveStart(assessmentId: string | null, ids: string[]) {
  return {
    type: 'NETWORK_SAVE_START',
    [ASSESSMENT_ID_FIELD]: assessmentId,
    ids,
  };
}

/**
 * Dispatches actions for saving on the server.  These actions are picked up
 * by the socket.io middleware, which ultimately puts them on the socket.
 */
export const save = (socket: SocketIOClient.Socket, next: Dispatch) => async (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();
  const unsent = getUnsentUpdates(state);

  // We don't need to do anything if we got an empty practices array
  if (!unsent.ids.length) {
    return;
  }
  const idToken = await getIdToken();
  // We can't save if there is no user.
  if (!idToken) {
    Logger.warn('Attempt to save with no logged in user.');
    next({ type: 'NETWORK_SAVE_WHILE_NOTLOGGEDIN' });
    return;
  }

  // In the case that the machine's wifi is turned off or the network is unplugged,
  // the "onLine" attribute of the window navigator knows WAY before the socket.io
  // figures it out.  SO... we'll check both.
  if (!socket.connected || !window.navigator.onLine) {
    // We can't dispatch crap if we don't have network connection that's
    // live.
    Logger.warn(
      `Not processing outstanding saves because the backend isn't connected`
    );
    next({
      type: 'NETWORK_SAVE_WHILE_DISCONNECTED',
      pending: unsent.ids.length,
    });
    return;
  }

  const doc = getSelectedAssessment(state);

  const assessmentId = doc ? doc._id : null;

  // We're creating a "one time" function.  After
  // it's called the first time, it sets itself to a
  // noop.  This way, we'll only dispatch a start
  // before the first actual send.
  let dispatchStartIfNecessary = () => {
    // Tell everyone we're starting an update.
    dispatch(networkSaveStart(assessmentId, unsent.ids));
    dispatchStartIfNecessary = () => {
      return;
    };
  };

  const toCreateArray = [] as IResponseForEve[];
  const badIds = [] as string[];
  const toCreateMap = {}; // Map used by network timeout to requeue
  const toPatchMap = {}; // Map used by network timeout to requeue
  let havePatches = false;
  let haveNew = false;

  for (const practiceId of unsent.ids) {
    const practice = unsent.items[practiceId];

    if (!validateSavingPractice(assessmentId, practice)) {
      badIds.push(practiceId);
      toasterError(
        'There was an error saving the practice. Please refresh the page.'
      );
      continue;
    }

    if (!practice._etag) {
      // New items MUST have a practice ID and assessmentID.
      // We don't store the practice ID (RM.1.A) on the object but the server
      // needs them - so we load it up now.
      practice[PRACTICE_ID_FIELD] = practiceId;
      // Likewise, we don't store the assessment on the item, so we need to set
      // it now.
      if (assessmentId) {
        practice[ASSESSMENT_ID_FIELD] = assessmentId;
      }
      toCreateArray.push(practice);
      toCreateMap[practiceId] = practice;
      haveNew = true;
    } else {
      // Existing items CAN'T set the pracice id or assessment id
      // (because we don't support resetting it).
      delete practice[ASSESSMENT_ID_FIELD];
      delete practice[PRACTICE_ID_FIELD];
      toPatchMap[practiceId] = practice;
      havePatches = true;
    }
  } // for each practiceId to be sent

  if (havePatches) {
    dispatchStartIfNecessary();
    dispatch(
      socketIOMessage(
        state,
        'multi_patch_resource',
        {
          resource: PRACTICE_DOMAIN,
          [ASSESSMENT_ID_FIELD]: assessmentId,
          data: toPatchMap,
          lastRequest: toCreateArray.length === 0,
        },
        null,
        createNetworkTimeout(assessmentId, toPatchMap)
      )
    );
  } // if there are items to patch.

  if (haveNew) {
    dispatchStartIfNecessary();
    dispatch(
      socketIOMessage(
        state,
        'post_resource',
        {
          resource: PRACTICE_DOMAIN,
          [ASSESSMENT_ID_FIELD]: assessmentId,
          data: toCreateArray,
          lastRequest: true,
        },
        null,
        createNetworkTimeout(assessmentId, toCreateMap)
      )
    );
  } // if there are items to create.

  // Remove any bad ids that somehow became a part of the update.
  if (badIds.length) {
    dispatch({
      type: 'NETWORK_REMOVE_BAD_IDS',
      [ASSESSMENT_ID_FIELD]: assessmentId,
      ids: badIds,
    });
  }
}; // save
