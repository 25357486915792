/* Whenever a new condition occurs that causes us to need the loading screen to
   show, that condition should be added to the object being handled here.
*/
import { Map } from 'immutable';
import { Reducer } from 'redux';

const getInitialState = () => Map<boolean>({});

const dontNeedLoadingOnGet = {
  grantees: true,
  users: true,
};

const needLoadingOnPost = {
  // If we're posting a new assessment, it may take time and we
  // want a spinny.  Other posts happen too fast or don't impact
  // the UI and we don't want to see the screen flash.
  assessments: true,
};

function isGet(actionType) {
  return actionType === 'server/get_resource';
}

function isPost(actionType) {
  return actionType === 'server/post_resource';
}

export const loadingReducer: Reducer<Map<string, boolean>> = (
  state = getInitialState(),
  action = { type: 'FAKE TYPE' }
) => {
  if (action.type.startsWith('post_resource/')) {
    const resource = action.type.split('/')[1];
    return state.delete(`post_${resource}`);
  } else if (action.type.startsWith('get_resource/')) {
    const resource = action.type.split('/')[1];
    return state.delete(`get_${resource}`);
  } else if (action.type.startsWith('error/get_resource')) {
    const resource = action.type.split('/')[2] || action.request.resource;
    return state.delete(`get_${resource}`);
  } else if (action.type.startsWith('error/post_resource')) {
    const resource = action.type.split('/')[2] || action.request.resource;
    return state.delete(`post_${resource}`).delete('MAJOR_LOADING');
  }
  switch (action.type) {
    case 'server/post_resource': // Fall through
    case 'server/get_resource': {
      const resource = action.request.resource;
      // Getting some resources come back fast enough that
      // we don't want to flash.
      if (
        (isGet(action.type) && dontNeedLoadingOnGet[resource]) ||
        (isPost(action.type) && !needLoadingOnPost[resource])
      ) {
        return state;
      }
      const prefix = action.type.split('/')[1].split('_')[0];
      return state.set(`${prefix}_${resource}`, true);
    }
    case 'SENDING_VERIFICATION_CODE':
      return state.set('sendingVerification', true);
    case 'RESENDING_CONFIRMATION_CODE':
      return state.set('resendingConfirmation', true);
    case 'COGNITO_USER_UNCONFIRMED':
      return state.delete('resendingConfirmation');
    case 'COGNITO_FINISH_PASSWORD_RESET_FLOW': // Fall through
    case 'COGNITO_CONTINUE_PASSWORD_RESET_FLOW':
      return state.delete('sendingVerification');
    case '@@router/CALL_HISTORY_METHOD': // Fall through
    case 'COGNITO_LOGIN': // Fall through
    case 'COGNITO_LOGIN_FAILURE':
    case 'COGNITO_LOGIN_MFA_REQUIRED':
    case 'COGNITO_LOGIN_NEW_PASSWORD_REQUIRED':
      return state.delete('loggingIn');
    case 'COGNITO_LOGOUT':
      // On a logout, any action that was pending should be cancelled.
      return getInitialState();
    case 'SUBSCRIBING':
      return state.set('subscribing', true);
    case 'error/resubscribe': // fall-through
    case 'error/subscribe': // fall-through
    case 'subscribe':
    case 'resubscribe':
      return state.delete('subscribing');
    case 'ASSESSMENT_LOAD_START':
    case 'MODE_SPINNER_ON':
      return state.set('MAJOR_LOADING', true);
    case 'REHYDRATE':
    case 'MODE_SPINNER_OFF':
      return state.delete('MAJOR_LOADING');
    case 'ASSESSMENT_CONVERT_BEGIN':
      return state.set('converting', true);
    case 'patch_resource/assessments':
      return state.delete('converting');
    case 'error/patch_resource':
      if (action.request && action.request.resource === 'assessments') {
        return state.delete('converting');
      }
      break;
    default:
      break;
  }
  return state;
};
