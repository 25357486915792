import {
  alliantTheme as baseTheme,
  IMP_CHARTREUSE,
  IMP_SHERPA_BLUE,
} from 'common/themes/alliant';
import { desaturate, transparentize } from 'polished';
import defaultTheme from './default';

export default {
  ...defaultTheme,
  authBackground: baseTheme.backgroundImage,
  hasIntroTour: false,
  hasOwnIntroScreen: false,
  poweredBy: true,
  poweredByBackground: true,
  colors: {
    ...defaultTheme.colors,
    authButtonBackground: IMP_SHERPA_BLUE,
    avatarColor: IMP_SHERPA_BLUE,
    axioBlue: IMP_SHERPA_BLUE,
    lightAxioBlue: desaturate(0, transparentize(0.9, IMP_SHERPA_BLUE)),
    nameplate: IMP_SHERPA_BLUE,
    primaryButton: IMP_SHERPA_BLUE,
    profileSwitchBackground: IMP_SHERPA_BLUE,
    profileSwitchHover: desaturate(0, transparentize(0.5, IMP_SHERPA_BLUE)),
    profileSwitchLabel: IMP_SHERPA_BLUE,
    qlAnswerHover: desaturate(0, transparentize(0.5, IMP_SHERPA_BLUE)),
    qlDefaultAnswerBackground: IMP_SHERPA_BLUE,
    taglineColor: IMP_SHERPA_BLUE,
    tocCollapseColor: IMP_CHARTREUSE,
    tocExpand: IMP_CHARTREUSE,
  },
};
