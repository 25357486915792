import { Map } from 'immutable';
import { Reducer } from 'redux';

const getInitialState = () => Map<boolean>({});

/*
 * This reducer tracks all of the requests made to the
 * storage endpoint that have come back sucessfully.
 * It provides us a way to know whether an empty array/map/
 * whatever is empty because there is no data on the server
 * or if it's empty because we haven't tried asking for it
 * yet.
 */
export const loadedReducer: Reducer<Map<string, boolean>> = (
  state = getInitialState(),
  action = { type: 'FAKE TYPE' }
) => {
  switch (action.type) {
    case 'COGNITO_LOGOUT':
      // When we log out, nothing is loaded anymore.
      return getInitialState();
    case 'server/get_resource': {
      // server/get_resource is fired when loading starts.  SO...
      // we are no longer "loaded" but are instead "loading"
      const resource = action.request.resource;
      return state.delete(`get_${resource}`);
    }
    default:
      // get_resource comes back when the load is done.
      // This means the resource has been loaded, even if it's empty.
      if (action.type.startsWith('get_resource/')) {
        const resource = action.type.split('/')[1];
        return state.set(`get_${resource}`, true);
      } else if (action.type.startsWith('error/get_resource')) {
        // If an error came back, it's not loaded either.  This delete
        // is probably doing nothing (since the server/get_resource should
        // have deleted it already) BUT just to be safe.
        const resource = action.type.split('/')[2] || action.request.resource;
        return state.delete(`get_${resource}`);
      }
      break;
  }
  return state;
};
