import { Map } from 'immutable';
import { Reducer } from 'redux';
import { notEmpty } from 'common/utils/typeGuards';
import { ASSESSMENT_ID_FIELD } from '../constants';
import { ILink } from '../databaseTypes';
import { normalizePractices } from '../utils/normalizePractices';

export type IState = Map<string, ILink>;

const initialState: IState = Map({});

export const linksReducer: Reducer<IState> = (state = initialState, action) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT':
      return initialState;
    case 'item_deleted/responses': {
      const items = normalizePractices(action.response.result._items).links;
      const keys = items ? Object.keys(items) : [];
      return state.deleteAll(keys);
    }
    case 'REHYDRATE': {
      const assessmentId = action[ASSESSMENT_ID_FIELD];
      const items =
        action.payload && action.payload.pendingUpdates
          ? action.payload.pendingUpdates
              .get(assessmentId, Map({}))
              .filter((item, k) => item && k)
          : Map({});
      if (items && items.size) {
        return state.withMutations((currState) =>
          items
            .filter((item) => item.links)
            .forEach((item) => currState.merge(item.links))
        );
      }
      return state;
    }
    case 'delete_resource/assessments': {
      const assessmentId = action.request._id;
      return state.filter((item) => item[ASSESSMENT_ID_FIELD] !== assessmentId);
    }
    case 'get_resource/responses':
    case 'resubscribe/responses':
    case 'item_inserted/responses':
    case 'item_updated/responses':
    case 'item_replaced/responses': {
      const items = Map(
        normalizePractices(action.response.result._items).links
      );
      return state.merge(items);
    }
    case 'UPDATE_LINK':
    case 'CREATE_LINK': {
      const existingLink = state.get(action.key);
      const newLink = {
        ...existingLink,
        name: action.link.name,
        location: action.link.location,
        author: action.link.author,
        timestamp: new Date().toISOString(),
        _id: action.key,
        _responsePracticeId: action.id,
        [ASSESSMENT_ID_FIELD]: action[ASSESSMENT_ID_FIELD],
      };
      return state.set(newLink._id, newLink);
    }
    case 'DELETE_LINK':
      return state.delete(action.key);
    case 'APOLLO_QUERY_RESULT':
      if (action.result.errors) break;
      switch (action.operationName) {
        case 'GetResponseLinks': {
          // merge links from query into links state
          const links = action.result.data.getResponseLinks;
          return state.merge(
            Map<string, ILink>(links.map(({ id, ...link }) => [id, link]))
          );
        }
        default:
          break;
      }
      break;
    case 'APOLLO_MUTATION_RESULT': {
      if (action.result.errors) {
        break;
      }
      switch (action.operationName) {
        case 'SelectDelegatedResponse': {
          const links = action.result.data.selectDelegatedResponse.responses
            .map((response) => {
              const {
                assessment: { id: _assessment },
                practiceId: _responsePracticeId,
                links: gqlLinks,
              } = response;
              return gqlLinks.filter(notEmpty).map((link) => [
                link.id,
                {
                  ...link,
                  _id: link.id,
                  timestamp: link.dateLastUpdated,
                  author: link.author.id,
                  _assessment,
                  _responsePracticeId,
                },
              ]);
            })
            .flat(1);
          // links need to be replaced because merging will keep links from
          // both the original and published responses
          return Map(links);
        }
        case 'createLink':
        case 'updateLink': {
          const link = action.result.data[action.operationName].link;
          return state.set(link.id, link);
        }
        case 'deleteLink': {
          const linkId = action.result.data.deleteLink.id;
          return state.delete(linkId);
        }
        default:
          break;
      }
      break;
    }
    default:
      break;
  }
  return state;
};
