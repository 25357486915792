import FileSaver from 'file-saver';
import { Map, OrderedMap } from 'immutable';
import JSZip from 'jszip';
import { flatten, groupBy, isEmpty, isNil } from 'lodash';
import Moment from 'moment';

import { IAssessment, IMilestone, IResponse } from 'assessments/databaseTypes';
import {
  IResponseDisplay,
  IResponseDimensionDisplay,
} from 'assessments/displayTypes';

import {
  getPracticeCount,
  IModelState,
} from '../../../../reducers/models/model';
import {
  fullyPopulatedResponses,
  IPartialResponse,
  score,
} from '../../../../score/score';
import { FQN } from '../../../../reducers/responses';
import { getMilestonesForAssessment } from '../../../../selectors/milestone';
import { getFutureScores } from '../../../../selectors/responses';
import { convertWidgetToDataURI } from 'common/utils/AxioUtilities';
import {
  APPROACH_MANAGEMENT_SCORE_CIRCLE,
  ASSESSMENT_CHANGE_OVER_TIME,
  BENCHMARK_COMPARISON,
  CIS18_CONTROL_CHART,
  CURRENT_SCORE_PIE,
  IMPLEMENTED_SUBCATEGORIES_BY_CATEGORY,
  MIL_COMPLETION_BY_DOMAIN,
  NIST_CHART,
  PRACTICES_IMPLEMENTED_BY_DOMAIN,
  PROGRESS_HISTORY,
  SCORE_GAUGE,
  TARGET_SCORE_PIE,
} from 'assessments/constants';
import { ModelScope } from 'common/graphql/graphql-hooks';
import { ITimeSeriesInstance } from '../widgets/MilestonesOverTime';
import { IScoredHistory } from 'assessments/reducers/levelHistory';

export type LevelType = 'level' | 'targetLevel';

// As date is stored in ISO8601, string sorting works, and don't need the overhead of Moment
// Default date means unset dates are sorted last
export function byTargetDateOrder(
  a: IResponse & { fqn: string },
  b: IResponse & { fqn: string }
) {
  const defaultDate = '2999-01-01 00:00:00.000Z';
  const dateSort = (a.targetDate || defaultDate).localeCompare(
    b.targetDate || defaultDate
  );
  if (dateSort !== 0) {
    return dateSort;
  } else {
    // If the server hasn't had a chance to get a response from the backend,
    // then the _updated and _created dates may be undefined.  Calling "localeCompare"
    // with undefined values causes an explosion.  We're going to guard against
    // this case using a default date.
    return (a._updated || a._created || defaultDate).localeCompare(
      b._updated || b._created || defaultDate
    );
  }
}

export function scoreAndInfo(
  model: IModelState,
  responses: Immutable.Map<FQN, IPartialResponse>,
  modelScope: ModelScope | null = null,
  levelType: LevelType = 'level',
  modelAsOfDate: string | null,
  filterFn: ((IModelPractice) => boolean) | null
) {
  const scores = score(
    model,
    responses,
    modelScope,
    levelType,
    filterFn,
    modelAsOfDate
  );
  const milValue = modelScope?.startsWith('MIL')
    ? parseInt(modelScope.slice(-1))
    : null;
  const info = domainInfo(model, milValue);

  return {
    ...scores,
    domains: info.map((d) => ({
      ...d,
      ...scores.domains[d.name],
    })),
  };
}

export function emptyWheel(
  info: IDomainInfo[]
): ReturnType<typeof scoreAndInfo> {
  return {
    total: 0,
    max: 0,
    score: 0,
    filteredScore: 0,
    filteredMax: 0,
    includedCount: 0,
    excludedCount: 0,
    domains: info.map((d) => ({
      ...d,
      total: 0,
      max: 1,
      score: 0,
      filteredScore: 0,
      filteredMax: 0,
      includedCount: 0,
      excludedCount: 0,
      title: d.title ?? '',
      objectives: {},
    })),
  };
}

export interface IDomainInfo {
  id: string;
  name: string;
  title: string;
  shortTitle: string;
  size: number;
  total: number;
  max: number;
  score: number;
}

export const domainInfo = (
  model: IModelState,
  milValue: number | null = null
): IDomainInfo[] => {
  // orderedDomains is a list of domain IDs.  getPracticeCount
  // wants a domain name.
  return model.orderedDomains.reduce<IDomainInfo[]>((accum, domainId) => {
    const domain = model.domainsMap.get(domainId);
    const domainName = domain ? domain.name : '';
    const domainAbbrev = domain ? domain.shortTitle : '';
    const practiceCount = getPracticeCount(model, domainId, milValue);
    if (practiceCount) {
      accum.push({
        id: domainId,
        name: domainName,
        shortTitle: domainAbbrev,
        size: practiceCount,
        total: 0,
        max: 0,
        title: domain?.title ?? '',
        score: 0,
      });
    }
    return accum;
  }, []);
};

export function toMap<T extends IPartialResponse>(r: Map<string, T> | T[]) {
  return Array.isArray(r)
    ? OrderedMap<string, T>(r.map((p) => [p._practice_id, p] as [string, T]))
    : r;
}

export interface IDomainData {
  id: string;
  name: string;
  practices: {
    [key: string]: {
      level?: number;
      targetLevel?: number;
      mil?: number;
    };
  };
}

export function milestoneVisualizationData(
  model: IModelState,
  responses: Map<FQN, IResponse> | IResponse[]
): IDomainData[] {
  return model.orderedDomains.map((domain) => ({
    id: domain,
    name: domain.slice(1),
    practices: fullyPopulatedResponses(model, responses, domain).toObject(),
  }));
}

interface IMilCompletionByDomain {
  domain: string;
  mil: number;
  percent: number;
}

export function cappybaraPresentationFromDomainMilPercents(
  domains: IMilCompletionByDomain[]
) {
  //note: this function assumes all mils will be present
  const groupedDomains = groupBy(domains, (domain) => domain.domain);
  return Object.values(groupedDomains).map((domainMils) => {
    domainMils.sort((a, b) => a.mil - b.mil); //sort by mil
    return flatten(
      domainMils.map((domainMil) => [
        { x: domainMil.domain, y: domainMil.percent },
        { x: domainMil.domain, y: 1 - domainMil.percent },
      ])
    );
  });
}

export function milCompletionByDomain(
  binaryCutoff: number,
  domainsResponsesEtAl: IDomainData[],
  milRange = 3
) {
  return flatten(
    domainsResponsesEtAl.map((domain) => {
      const practicesByMil = groupBy(
        domain.practices,
        (practice) => practice.mil
      );
      //caveat: assumes three mils -- should use something like getMilRange(model);
      return [...Array(milRange + 1).keys()].slice(1).map((mil) => {
        const thisMil = practicesByMil[mil] || [];
        const totalPracticesForMil = thisMil.length;

        const achievedMilCount = thisMil.filter(
          (practice) => (practice.level ?? -1) >= binaryCutoff
        ).length;

        const percentComplete = totalPracticesForMil
          ? achievedMilCount / totalPracticesForMil
          : 0;

        return { domain: domain.name, mil: mil, percent: percentComplete };
      });
    })
  );
}

export function breakDownByMil(
  binaryCutoff: number,
  domainsResponsesEtAl: IDomainData[],
  milRange = 3
) {
  return cappybaraPresentationFromDomainMilPercents(
    milCompletionByDomain(binaryCutoff, domainsResponsesEtAl, milRange)
  );
}

export function calculateDelta(responses: Map<string, IResponse>): number {
  // calculate the difference between current and target profile
  return responses.reduce((accum, item) => {
    // !item.level = 0
    // -1 for item.level = 0
    // otherwise item.level
    const itemLevel = item.level ? (item.level < 0 ? 0 : item.level) : 0;
    accum += Math.max(0, (item.targetLevel || 0) - itemLevel);
    return accum;
  }, 0);
}

export function numberOfTargetedPractices(responses: Map<string, IResponse>) {
  return responses.reduce((accum, item) => {
    const targetLvl = item.targetLevel !== undefined ? 1 : 0;
    accum += targetLvl;
    return accum;
  }, 0);
}

export const exportGraphics = async (scope: string | null) => {
  const names = [
    APPROACH_MANAGEMENT_SCORE_CIRCLE,
    ASSESSMENT_CHANGE_OVER_TIME,
    BENCHMARK_COMPARISON,
    CIS18_CONTROL_CHART,
    CURRENT_SCORE_PIE,
    IMPLEMENTED_SUBCATEGORIES_BY_CATEGORY,
    MIL_COMPLETION_BY_DOMAIN,
    NIST_CHART,
    PRACTICES_IMPLEMENTED_BY_DOMAIN,
    PROGRESS_HISTORY,
    SCORE_GAUGE,
    TARGET_SCORE_PIE,
  ];

  const widgets = flatten(
    names.map((name) => {
      if (name === PRACTICES_IMPLEMENTED_BY_DOMAIN) {
        const el = document.querySelectorAll(`.${name}>div>svg`);
        if (el[0]) {
          return {
            name,
            element: el[0],
          };
        }
      }
      if (name === PROGRESS_HISTORY || name === CIS18_CONTROL_CHART) {
        const el = document.querySelectorAll(`.${name}>svg`);
        if (el[0]) {
          return {
            name,
            element: el[0],
          };
        }
      }
      return [
        ...((document.getElementsByClassName(name) as unknown) as Element[]),
      ].map((element, index, array) => ({
        name: array.length > 1 ? `${name} ${index + 1}` : name,
        element,
      }));
    })
  );

  const canvas = document.getElementById('hiddenCanvas') as HTMLCanvasElement;

  const rasterized = await Promise.all(
    widgets.map(async (widget) => ({
      ...widget,
      dataURI: await convertWidgetToDataURI(canvas, widget),
    }))
  );

  const zip = JSZip();
  rasterized.forEach((file) => {
    zip.file(file.name + '.png', file.dataURI.split(',')[1], { base64: true });
  });
  zip.generateAsync({ type: 'blob' }).then((blob) => {
    FileSaver.saveAs(blob, scope + '.zip');
  });
};

type ScoredHistory = IScoredHistory[];
type HistoryScore = Omit<ScoredHistory[0], 'date'> & {
  date: Date | '[No Date]';
};

interface Args {
  levelHistory: ScoredHistory;
  targetData: ReturnType<typeof getFutureScores>;
  milestones: ReturnType<typeof getMilestonesForAssessment>;
  previousMilestone?: Pick<
    IMilestone,
    '_id' | 'milestone_date' | 'name'
  > | null;
  currentMilestone?: Pick<IMilestone, '_id' | 'milestone_date' | 'name'> | null;
  nextMilestone?: Pick<IMilestone, '_id' | 'milestone_date' | 'name'> | null;
}

export const createTimeSeries = ({
  levelHistory,
  targetData,
  milestones,
  previousMilestone,
  currentMilestone,
  nextMilestone,
}: Args): ITimeSeriesInstance[] => {
  // We have to take the level history array and merge in the
  // milestones in the appropriate location.  The expectation
  // is that the milestones are sorted in date order as is
  // level history.

  const milestoneArray = milestones
    .valueSeq()
    .map((o) => ({ ...o, date: new Date(o.milestone_date) }))
    .sortBy((mstone) => (mstone && mstone.milestone_date) || '')
    .toArray();

  const selectedIds = [
    previousMilestone && previousMilestone._id,
    currentMilestone && currentMilestone._id,
    nextMilestone && nextMilestone._id,
  ].filter((arg) => !!arg);

  const mergeFunc = (argLvl, argMilestone) => {
    return {
      ...argLvl,
      date: argMilestone.date,
      milestoneName: argMilestone.name,
      isSelected: selectedIds.includes(argMilestone._id),
    };
  };

  const fnReduce = () => {
    let ndx = 0;
    const myShift = (argArray) => {
      if (argArray && argArray.length && ndx < argArray.length) {
        const i = ndx;
        ndx++;
        return argArray[i];
      }
      return null;
    };
    let milestone = myShift(milestoneArray);
    let workingLvl = null;

    return (accum, lvl) => {
      while (milestone && lvl.date.getTime() >= milestone.date.getTime()) {
        workingLvl =
          lvl.date.getTime() === milestone.date.getTime() ? lvl : workingLvl;
        if (workingLvl) {
          accum.push(mergeFunc(workingLvl, milestone));
        }
        milestone = myShift(milestoneArray);
      }
      if (workingLvl !== lvl) {
        accum.push(lvl);
      }
      workingLvl = lvl;
      return accum;
    };
  };

  const today =
    currentMilestone && currentMilestone.milestone_date
      ? new Date(currentMilestone.milestone_date)
      : new Date();

  const todayMillis = today.getTime();
  // "today" might be a milestone in the past.  So, we only want historical
  // records that are earlier than that value.
  const history: HistoryScore[] = levelHistory.filter(
    (itm) => itm.date.getTime() < todayMillis
  );
  const historicalNow: HistoryScore = levelHistory.find(
    (itm) => itm.date.getTime() === todayMillis
  ) || {
    currentScore: 0,
    ...levelHistory[levelHistory.length - 1],
    date: today,
  };

  const inTheFuture = (date) => {
    const newDate = Moment(date);
    newDate.add(1, 'months');
    return newDate.toDate();
  };

  // "targetData" is all targets, grouped by target date.  We only
  // want the values for ones in the future.  Also, we want to map
  // targets that don't have a target date to sometime after whatever
  // the max date is.  This way, they always push out into the future.
  const lastTarget = targetData[targetData.length - 1];
  const lastTargetScore =
    (lastTarget && lastTarget.projectedScore) ||
    historicalNow.targetScore ||
    historicalNow.currentScore;

  let lastDate = new Date();
  const future = targetData
    .filter(
      (itm) => itm.date === '[No Date]' || itm.date.getTime() > todayMillis
    )
    .map((itm) => {
      if (itm.date === '[No Date]') {
        return {
          ...itm,
          targetScore: itm.projectedScore,
          projectedScore: null,
          date: inTheFuture(lastDate),
        };
      }
      lastDate = itm.date > lastDate ? itm.date : lastDate;
      return { ...itm, targetScore: lastTargetScore };
    });

  const futureNow = targetData.find((itm) => itm.date === today) || {
    ...historicalNow,
    projectedScore: historicalNow.currentScore,
  };

  // Merge history, today and future into one result and incorporate any milestones.
  return history
    .concat(
      [{ ...historicalNow, projectedScore: futureNow.projectedScore }],
      future
    )
    .reduce(fnReduce(), []);
};

// The below functions are for calculating various aggregate scores for specifically CIS18 assessments

export interface IModelPracticeEntry {
  scale?: number;
  level: number;
}

export interface IObjectiveEntry {
  groups?: number[];
  practices: IModelPracticeEntry[];
}

export interface IBarChartData {
  objectiveCount: number;
  objectives: IObjectiveEntry[];
}

export const summer = (array: number[]) => {
  return array.reduce((a, b) => {
    return a + b;
  }, 0);
};

export const domainLevelAverager = (
  objectives: IObjectiveEntry[],
  dimension = 2,
  groupLimiter: number | null = null
): { average: number; objectiveCount: number } => {
  const objectivesToUse = objectives.filter((objective) => {
    return (
      (objective.groups?.some((groupItem) => groupItem === groupLimiter) ||
        groupLimiter === null) &&
      objective.practices.some((practice) => practice.scale === dimension)
    );
  });
  const sumOfLevelsAsPercentages = summer(
    objectivesToUse.map((objective) => {
      return (
        (objective.practices.find((practice) => {
          return practice.scale === dimension;
        })?.level ?? 0) / 4
      );
    })
  );
  return {
    average: sumOfLevelsAsPercentages / objectivesToUse.length || 0,
    objectiveCount: objectivesToUse.length,
  };
};

export const averagesAcrossDomains = (
  domains: IBarChartData[],
  dimension = 2,
  groupLimiter: number | null = null,
  lowCut = 0,
  highCut = 20
): Array<{ average: number; objectiveCount: number }> => {
  const slicedDomains = domains.slice(lowCut, highCut);
  const domainsToUse = slicedDomains.filter(
    (domain) =>
      !groupLimiter ||
      domain.objectives.some(
        (objective) =>
          !!objective.groups?.some((group) => group === groupLimiter)
      )
  );
  return domainsToUse.map((domain) => {
    return domainLevelAverager(domain.objectives, dimension, groupLimiter);
  });
};

export const UnspecifiedTargetDisplayValue = 'N/A';
export const UnspecifiedLevelRawValue = -2;

export const TargetMaturityScoreDisplayField = 'Target Maturity Score';

export const CurrentLevelDisplayMap = Map<number, number>([
  [-2, 0],
  [0, 1],
  [1, 2],
  [2, 3],
  [3, 4],
  [4, 5],
]);

const levelToDisplayLevel = (level?: number): number | undefined =>
  isNil(level) ? undefined : CurrentLevelDisplayMap.get(level);

export const displayLevelToLevel = (
  displayLevel?: number
): number | undefined =>
  displayLevel
    ? CurrentLevelDisplayMap.findKey((value) => value === displayLevel)
    : undefined;

const extractTargetDisplayLevel = (firmographicData: any = {}) =>
  isNil(firmographicData[TargetMaturityScoreDisplayField]) ||
  isEmpty(firmographicData[TargetMaturityScoreDisplayField])
    ? UnspecifiedTargetDisplayValue
    : firmographicData[TargetMaturityScoreDisplayField];

export const extractTargetDisplayLevelNumber = (
  firmographicData: any = {}
): number => {
  const targetDisplayLevel = extractTargetDisplayLevel(firmographicData);
  switch (typeof targetDisplayLevel) {
    case 'string':
      return parseInt(targetDisplayLevel);
    case 'number':
      return targetDisplayLevel;
    default:
      return NaN;
  }
};

export const withDisplayFields = (
  selectedAssessment: IAssessment,
  responses: Map<string, IResponse>
): Map<string, IResponseDisplay> => {
  const displayTargetLevel = extractTargetDisplayLevel(
    selectedAssessment.firmographicData
  );
  return Map<string, IResponseDisplay>(
    Array.from(responses.entries()).map(([key, response]) => {
      const responseDisplayLevel = levelToDisplayLevel(response.level);
      const displayDimensions = (response.dimensions ?? []).map((dimension) => {
        const displayLevel = levelToDisplayLevel(dimension.level);
        return {
          ...dimension,
          ...(!isNil(displayLevel) ? { displayLevel, displayTargetLevel } : {}),
        } as IResponseDimensionDisplay;
      });
      const displayResponse = {
        ...response,
        ...(!isNil(responseDisplayLevel)
          ? { displayLevel: responseDisplayLevel, displayTargetLevel }
          : {}),
        ...(!isEmpty(displayDimensions)
          ? { dimensions: displayDimensions }
          : {}),
      } as IResponseDisplay;
      return [key, displayResponse];
    })
  );
};
