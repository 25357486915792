import { FieldFunctionOptions, FieldPolicy } from '@apollo/client';

import { typedReadField } from '../cacheUtils/helpers';
import { UserGroup, UserGroupFragment } from '../graphql-hooks';

export const searchUserGroupsPolicy: FieldPolicy<UserGroupFragment[]> = {
  read: (existing, context) =>
    existing
      ? [...existing].sort((a, b) =>
          readUserGroupName(context, a).localeCompare(
            readUserGroupName(context, b)
          )
        )
      : undefined,
};

const readUserGroupName = (
  context: FieldFunctionOptions,
  userGroup: UserGroupFragment
) => typedReadField<UserGroup, 'name'>(context, 'name', userGroup);
