import { csfAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';
import { defaultModelFeatures } from '../../modelDefaults';
import { LegacyModelConfig } from '..';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { nist2ColorTable } from 'assessments/utils/nist2ColorTable';
import terms from 'assessments/utils/Dictionary/terms_csf';

const title = 'NIST CSF 2.0 (GP)';

export const nist2GuidepointConfig: LegacyModelConfig = {
  icon,
  features: {
    ...defaultModelFeatures,
  },
  shortTitle: title,
  factories: [
    {
      label: title,
      filterLabel: title,
      icon: icon,
      sortKey: title,
      modelUuid: LegacyModelIdEnum.Nist2Guidepoint,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.CSF_GP,
    },
  ],
  colorTable: nist2ColorTable,
  terms,
};
