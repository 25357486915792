// This is basically a typed version of the dead apollo-link-redux pkg
import { ApolloLink, FetchResult, NextLink, Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationTypeNode } from 'graphql';
import { Store } from 'redux';

export const APOLLO_QUERY_INIT = 'APOLLO_QUERY_INIT';
export const APOLLO_QUERY_RESULT = 'APOLLO_QUERY_RESULT';
export const APOLLO_MUTATION_INIT = 'APOLLO_MUTATION_INIT';
export const APOLLO_MUTATION_RESULT = 'APOLLO_MUTATION_RESULT';
export const APOLLO_SUBSCRIPTION_INIT = 'APOLLO_SUBSCRIPTION_INIT';
export const APOLLO_SUBSCRIPTION_RESULT = 'APOLLO_SUBSCRIPTION_RESULT';

const payload = ({ operationName, variables, query }: Operation) => ({
  operationName,
  variables,
  document: query,
});

const initAction = (
  type:
    | typeof APOLLO_QUERY_INIT
    | typeof APOLLO_MUTATION_INIT
    | typeof APOLLO_SUBSCRIPTION_INIT
) => (operation: Operation) => ({
  type,
  ...payload(operation),
});

const resultAction = (
  type:
    | typeof APOLLO_QUERY_RESULT
    | typeof APOLLO_MUTATION_RESULT
    | typeof APOLLO_SUBSCRIPTION_RESULT
) => (result: FetchResult, operation: Operation) => ({
  type,
  result,
  ...payload(operation),
});

export const queryInit = initAction(APOLLO_QUERY_INIT);
export const queryResult = resultAction(APOLLO_QUERY_RESULT);
export const mutationInit = initAction(APOLLO_MUTATION_INIT);
export const mutationResult = resultAction(APOLLO_MUTATION_RESULT);
export const subscriptionInit = initAction(APOLLO_SUBSCRIPTION_INIT);
export const subscriptionResult = resultAction(APOLLO_SUBSCRIPTION_RESULT);

const isQuery = (op: OperationTypeNode) => op === 'query';
const isMutation = (op: OperationTypeNode) => op === 'mutation';
const isSubscription = (op: OperationTypeNode) => op === 'subscription';

export class ReduxLink extends ApolloLink {
  store: Store;
  /**
   * @param store - redux store
   */
  constructor(store: Store) {
    super();
    this.store = store;
  }

  request(operation: Operation, nextLink: NextLink) {
    const observer = nextLink(operation);
    const definition = getMainDefinition(operation.query);
    if (
      definition.kind === 'OperationDefinition' &&
      isQuery(definition.operation)
    ) {
      this.store.dispatch(queryInit(operation));
    } else if (
      definition.kind === 'OperationDefinition' &&
      isMutation(definition.operation)
    ) {
      this.store.dispatch(mutationInit(operation));
    } else if (
      definition.kind === 'OperationDefinition' &&
      isSubscription(definition.operation)
    ) {
      this.store.dispatch(subscriptionInit(operation));
    }
    return observer.map((result) => {
      if (
        definition.kind === 'OperationDefinition' &&
        isQuery(definition.operation)
      ) {
        this.store.dispatch(queryResult(result, operation));
      } else if (
        definition.kind === 'OperationDefinition' &&
        isMutation(definition.operation)
      ) {
        this.store.dispatch(mutationResult(result, operation));
      } else if (
        definition.kind === 'OperationDefinition' &&
        isSubscription(definition.operation)
      ) {
        this.store.dispatch(subscriptionResult(result, operation));
      }
      return result;
    });
  }
}
