// This file holds the theme elements that are common across all modules.
import { defaultTheme } from '../default';
import clientLogo from './Pinnacle_logo_Tag_horiz_pos_RGB.png';
import PinnacleWelcome from './Welcome-Screen-Pinnacle-Option2.png';
import PinnacleToc from './Module-Splash-Option-2.png';

export const PINNACLE_PRIMARY = '#3dbaff';
//Ended up not needing this colour for theme.
//Leaving here so no one has to dig up older files
//export const PINNACLE_SECONDARY = '#DA1F28';
export const PINNACLE_TERTIARY = '#212425';
export const PINNACLE_BLACK = '#0F0F27';
export const PINNACLE_WHITE = '#FFFFFF';
export const PINNACLE_GREY = '#97999B';
export const pinnacleTheme = {
  ...defaultTheme,
  title: 'Pinnacle Business Systems',
  name: 'pinnacle',
  poweredBy: false,
  tocPattern: PinnacleToc,
  homepage: 'https://www.pbsnow.com',
  clientName: 'Pinnacle',
  clientNameLong: 'Pinnacle Business Systems, Inc.',
  clientLogo,
  logoHeight: 35,
  authLogo: clientLogo,
  authWelcomeText: '',
  poweredByLogo: '',
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: PinnacleWelcome,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    primaryButton: PINNACLE_PRIMARY,
    primaryButtonText: PINNACLE_WHITE,
    authBackground: defaultTheme.colors.darkGray,
    minimalButtonTextColor: PINNACLE_BLACK,
  },
};
