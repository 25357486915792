// This file holds the theme elements that are common across all modules.
import { defaultTheme } from '../default';
import clientLogo from './APPALogoBig.png';
import smallLogo from './appa.svg';
import background from './APPAOtherImage.jpg';
import poweredByLogo from '../default/Axio360.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';

export const APPA_BLUE = '#214172';

export const appaTheme = {
  ...defaultTheme,
  title: 'Public Power Cybersecurity Scorecard',
  name: 'appa',
  poweredBy: true,
  homepage: 'http://publicpower.org',
  clientName: 'APPA',
  clientNameLong: 'American Public Power Association',
  clientLogo: smallLogo,
  authLogo: clientLogo,
  authPoweredByLogo: authPoweredByLogo,
  poweredByLogo: poweredByLogo,
  eulaLink: '/appa_terms.html',
  twitterUsername: '',
  twitterScreenName: '',
  font: 'Raleway, arial, system-ui, sans-serif',
  backgroundImage: background,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0,
  authPanelTransparency: 0.15,
  logoHeight: 55,
  colors: { primaryButton: APPA_BLUE, authBackground: 'grey' },
  salesEmail: 'membership@publicpower.org',
};
