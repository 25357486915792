import { Map } from 'immutable';

import { IRootState } from '../../reducers';
import { getSelectedAssessmentId } from '../selectors';
import { AssessmentId, getResponses as p_getResponses } from './responses';

function getResponses(
  state: IRootState,
  assessmentId: AssessmentId = '',
  ifNotFound: Map<string, any> = Map({})
) {
  const aId = assessmentId || getSelectedAssessmentId(state);
  return (aId && p_getResponses(state.responses, aId)) || ifNotFound;
}

export function getLastUpdateDate(
  state: IRootState,
  argAssessmentId: string | null = null
) {
  // Last update date for an assessment is the
  // newest _updated date of any response on the assessment.
  const assessmentId = argAssessmentId || getSelectedAssessmentId(state);
  const responses = assessmentId && getResponses(state, assessmentId);
  const lastDate =
    responses &&
    //@ts-expect-error Invalid Overload Call
    responses.reduce((acc, r) => (acc < r._updated ? r._updated : acc), '');

  return lastDate;
}
