import moment from 'moment';
import Logger from './Logger';

export const minDate = new Date('1980-01-01');
export const maxDate = new Date('2050-12-31');

const supportedDateFormats = [
  'YYYY/M/D',
  'M/D/YYYY',
  'M/D/YY',
  'D/M/YYYY',
  'D/M/YY',
  'M/D',
  'YYYY-MM-DD',
];

const supportedDateFormatsOnImport = 'YYYY-MM-DD';

export const parseDate = (str: string) => {
  const parsedDate = moment(str, supportedDateFormats, true);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
};

export const parseDateOnImport = (str: string) => {
  const parsedDate = moment(str, supportedDateFormatsOnImport, true);
  return parsedDate.isValid() ? parsedDate.toDate() : null;
};

export const getDateImportStringFromDate = (date: Date) =>
  moment(date).format(supportedDateFormatsOnImport);

export const DATE_FORMAT_LONG = 'MMM. Do, YYYY - hh:mma';
export const DATE_FORMAT_FULL_MONTH = 'MMMM Do, YYYY';
export const DATE_FORMAT_MED = 'MMM. Do, YYYY';
export const DATE_FORMAT_SHORT = 'MM-DD-YYYY';
export const TIME_FORMAT = 'h:mm:ss a';

/**
 * Given a date, remove all time component, and return an ISO8601 string
 * @example Tue Jan 02 2018 01:00:00 GMT-0500 >> 2018-01-02T10:00:00.000Z
 * @example Tue Jan 02 2018 23:00:00 GMT+1100 >> 2018-01-02T10:00:00.000Z
 */
export const dateToIsoDay = (date: Date) =>
  moment(date).utcOffset(0).startOf('day').toISOString();

/** Given an ISO8601 date string, return a JS Date with the timezone time portion removed */
export const isoStringToLocalDay = (dateStr: string) =>
  new Date(dateStr.replace('Z', ''));

export const isoStringToFormattedLocal = (
  format: string,
  dateStr?: string | null
) =>
  dateStr ? moment.utc(dateStr).local().format(format).toString() : 'Unknown';

export const formatLongDateString = (dateStr?: string | null) =>
  dateStr ? moment.utc(dateStr).local().format(DATE_FORMAT_LONG) : 'Unknown';

export const prettyFormatDistanceToNow = (dateStr?: string | null) =>
  dateStr ? moment.utc(dateStr).local().fromNow() : 'Unknown';

export const isoNow = () => moment().toDate();

export const milestoneDateConstructor = (date?: Date | null) =>
  moment
    .utc(date || isoNow())
    .set({
      h: 23,
      m: 59,
      s: 59,
      ms: 0,
    })
    .utc()
    .toISOString();

const defaultDate = '2999-01-01 00:00:00.000Z';
export const sortByDateAscending = <T extends {}>(key: keyof T) => (
  a: T,
  b: T
) => {
  const firstValue = a[key] || defaultDate;
  const secondValue = b[key] || defaultDate;
  if (typeof firstValue !== 'string' || typeof secondValue !== 'string') {
    Logger.warn('Tried to sort non-string value', {
      contexts: {
        location: {
          file: 'common/utils/DateUtils',
          function: 'sortByDateAscending',
          firstValue,
          secondValue,
        },
      },
    });
    return 0;
  }
  return firstValue.localeCompare(secondValue);
};

export const sortByDateDescending = <T extends {}>(key: keyof T) => (
  a: T,
  b: T
) => {
  const firstValue = a[key] || defaultDate;
  const secondValue = b[key] || defaultDate;
  if (typeof firstValue !== 'string' || typeof secondValue !== 'string') {
    Logger.warn('Tried to sort non-string value', {
      contexts: {
        location: {
          file: 'common/utils/DateUtils',
          function: 'sortByDateAscending',
          firstValue,
          secondValue,
        },
      },
    });
    return 0;
  }
  return secondValue.localeCompare(firstValue);
};

export const validateDate = (testDate) => {
  const dateRegex = /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
  return dateRegex.test(testDate);
};

export const fakeOldDate = '2020-01-01T00:00:00.000Z';
export const fakeNewDate = '2020-02-01T00:00:00.000Z';

enum Quarter {
  ONE = 0,
  TWO = 3,
  THREE = 6,
  FOUR = 9,
}

export interface IQuarterWithYear {
  quarter: number;
  year: number;
}

export const getQuarter = (date: Date): IQuarterWithYear => {
  const month = date.getMonth();
  const year = date.getFullYear();
  return month <= 2
    ? { quarter: 1, year }
    : month <= 5
    ? { quarter: 2, year }
    : month <= 8
    ? { quarter: 3, year }
    : { quarter: 4, year };
};

export const getQuarterStart = (from: Date): Date => {
  const date = new Date(from);
  const month = from.getMonth();
  const quarter =
    month <= 2
      ? Quarter.ONE
      : month <= 5
      ? Quarter.TWO
      : month <= 8
      ? Quarter.THREE
      : Quarter.FOUR;
  date.setMonth(quarter, 1);
  date.setHours(0, 0, 0, 0);

  return date;
};

export const getPreviousDay = (from: Date): Date => {
  const date = new Date(from);
  const day = from.getDate();
  date.setDate(day - 1);
  return date;
};

// Get the date one year ago from the inputted date
export const rightNow = new Date();
export const oneYearAgoFromDate = (date) => {
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

export const getYearStart = (modifier = 0) =>
  new Date(new Date().getFullYear() + modifier, 0, 1);

export const getYearEnd = () => getYearStart(1);
