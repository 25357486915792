import { Map } from 'immutable';

import { GranteeType, IUser } from 'common/databaseTypes';
import { ActionItem, ModelScope } from 'common/graphql/graphql-hooks';
import { Position } from './qlTypes';
import { score } from './score/score';

export { ModelScope } from 'common/graphql/graphql-hooks';
export enum AssessmentModes {
  ASSESSMENT = 1,
  TARGET_PROFILE = 2,
}

export interface ILink {
  author: string;
  name?: string;
  location: string;
  timestamp: string;
  _assessment: string;
  _id: string;
  _responsePracticeId?: string;
}

export interface IRationale {
  author: string;
  rationale: string;
  timestamp: string;
}

export interface IResponseDimension {
  key: string;
  level?: number;
  targetLevel?: number;
  _sourceAssessment?: string;
}

export interface IResponse {
  _practice_id: string;
  isFlagged: boolean;
  isCritical?: boolean;
  shouldAddHalfLevelCredit?: boolean;
  links?: string[];
  level?: number;
  targetLevel?: number;
  targetDate?: string;
  assessmentName?: string;
  delegatedAssessmentId?: string;
  isPublishedForDelegation?: boolean;
  levelUpdatedBy?: string;
  _id: string;
  _etag: string;
  _assessment: string;
  _sourceAssessment?: string;
  _created: string;
  _updated?: string;
  _version?: string;
  _latest_version?: string;
  ownerRationale?: IRationale | null;
  reviewerRationale?: IRationale | null;
  dimensions?: IResponseDimension[];
  actionItems?: ActionItem[];
}

interface IBaseGrantee {
  grantee: string;
  type: string;
  read_only: boolean;
  dateCreated?: string;
  dateLastLogin?: string;
  _id: string;
  _etag: string;
  _created: string;
  _updated?: string;
  restrictedPracticeSet?: string[];
}
export interface IUserGrantee extends IBaseGrantee {
  grantee_type: GranteeType.USER;
  user: IUser;
}

export interface IGroupGrantee extends IBaseGrantee {
  grantee_type: GranteeType.USER_GROUP;
  user: {};
}

export type IGrantee = IUserGrantee | IGroupGrantee;

export interface IMilestone {
  milestone_date: string;
  name: string;
  _assessment: string;
  _id: string;
  _etag: string;
  _created: string;
  _updated?: string;
  responses?: Map<string, IResponse>;
}

export interface IJournalEntry {
  _assessment: string;
  _id: string;
  user: string;
  occurred: string;
  description: string;
  _etag: string;
  _created: string;
}
export enum AccessType {
  OWNER = 'owner',
  COWORKER = 'coworker',
  USER_GROUP = 'userGroup',
  TARGET_PROFILE = 'targetProfile',
  ADMIN = 'admin',
}

interface IFirmographicData {
  [key: string]: string;
}
export interface IAssessment {
  company?: { _id: string; name: string };
  name: string;
  preparedFor?: string;
  model: string;
  modelScope: ModelScope;
  assessmentState?: string;
  description?: string;
  quicklaunch_position: Position;
  fromImport?: boolean;
  importFileName?: string;
  lastEvent?: string;
  owner?: string;
  ownerInfo?: IUser;
  score?: ReturnType<typeof score>;
  targetScore?: ReturnType<typeof score>;
  _id: string;
  _etag: string;
  _created: string;
  _updated?: string;
  _updated_by?: string;
  _version?: number;
  grantees: IGrantee[];
  milestones: IMilestone[];
  hideNAPractices?: boolean;
  scopetags?: string[];
  targetProfile?: boolean;
  accessType: AccessType[];
  read_only: boolean;
  assigneeId?: string;
  isSingleton?: boolean;
  firmographicData?: IFirmographicData;
}

export interface IBitsightReason {
  title: string;
  grade: string;
  color: string;
}

export interface IBitsightResponse {
  id: string;
  model: string;
  grade: string;
  color: string;
  reasons?: IBitsightReason[];
}

export interface ISummaryByQuartile {
  min: number;
  max: number;
  median: number;
  q1: number;
  q3: number;
}
export interface IBenchmark {
  _created: string;
  _etag: string;
  _id: string;
  _updated: string;
  overall_current: ISummaryByQuartile;
  overall_target: ISummaryByQuartile;
  domains_current: { [domain: string]: ISummaryByQuartile };
  domains_target: { [domain: string]: ISummaryByQuartile };
  modelScope: ModelScope;
  modelUuid: string;
  cssa_peer_group?: string;
}

export enum HelpType {
  Help = 'help',
  Evidence = 'evidence',
}
