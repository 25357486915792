import { createGlobalStyle } from 'styled-components/macro';

import {
  CLASSNAME,
  SELECTED_CLASSNAME,
  TabInner,
} from 'assessments/components/tabs';

import { TOP_Z_INDEX } from 'common/constants';
import fonts from './fonts/fonts.js';

const GTAmericaWoff = fonts.GTAmericaWoff;
const GTAmericaWoff2 = fonts.GTAmericaWoff2;
const PORTAL_LEVEL_Z = Math.floor(TOP_Z_INDEX / 10);

export const GlobalStyle = createGlobalStyle`
    html, body {
    margin: 0px;
    padding: 0px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  * {
    outline: none;
    font-family: ${(props) => props.theme.font || 'Lato'};
    :focus{
      outline: none;
    }
  }

  input {
    :focus {
      outline: none;
    }
  }

  a {
    outline: none;
  }

  @font-face {
    font-family: 'GTAmerica';
    src: local('GTAmerica'), local('GTAmerica'),
    url(${GTAmericaWoff2}) format('woff2'),
    url(${GTAmericaWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
}

  #root {
    overflow: hidden;
  }

  /* TABS STYLES */
  .react-tabs {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: none;
  }
  .react-tabs__tab-panel.react-tabs__tab-panel--selected {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* make panel fill container but account for tab header height */
    height: calc(100% - 35px);
  }
  .react-tabs__tab-panel {
    display: none;
    box-sizing: border-box;
    background-color: white;
    overflow: hidden;

    z-index: 999;
    padding: 0.5em;
  }
  .react-tabs__tab.${CLASSNAME} {
    background-color: ${(props) => props.theme.colors.fullBackground};
    display: block;
    float: left;
    height: 2.18rem;
    padding: 0;
    position: relative;
    text-align: center;
    width: 50%;
    width: 9rem;
    z-index: 1;
  }

  .react-tabs__tab.${CLASSNAME}.react-tabs__tab--selected.${SELECTED_CLASSNAME} {
    border: none;
  
    ${TabInner} {
      opacity: 1;
      z-index: 1;
    }
  }

  .AxioTabList {
    height: 2.18rem;
    padding: 0;
    margin: 0;
    border-bottom: none;
    cursor: default;
    user-select: none;
    display: flex;
  }
  /* END TAB STYLES */

  /* MODAL STYLES */
  .ReactModal__Content {
  opacity: 0;
  transform: scale(0.7);
}

.ReactModal__Content--after-open {
  opacity: 1;
	transform: scale(1);
  transition: all 0.3s;
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s;
}
/* END MODAL STYLES */
/* React-grid-layout */
.react-grid-item.react-grid-placeholder {
  background-color: #2966b5;
}
/* END React-grid-layout */

/* START TOOLTIP STYLES*/
.rc-tooltip {
  opacity: 1 !important;
  /*The lib default is 0,0,0,.05*/
  background-color: transparent !important;
}

.rc-tooltip-arrow {
  display: none;
}

.rc-tooltip-inner {
  background-color: white !important;
  border: 1px solid lightgrey !important;
  color: black !important;
  border-radius: 4px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
  margin: 0 !important;
  min-height: unset !important;
}
/* END TOOLTIP STYLES*/

.Axio_Draggable_List_Portal {
    z-index:${PORTAL_LEVEL_Z};
    position: absolute;
  }
/* BLUEPRINT STYLES */
.bp3-portal{
  z-index:${PORTAL_LEVEL_Z - 1};
}
.bp3-toast-container{
  z-index:${TOP_Z_INDEX};
}

.bp3-dialog{
  background-color: white !important;
  resize: both !important;
  width: auto !important;
  height: auto !important;
  padding-bottom: 8px !important;
}

.bp3-multi-select-popover .bp3-menu, .bp3-select-popover .bp3-menu {
    max-height: 300px;
    overflow: auto;
}

.no-scroll > .bp3-overlay.bp3-overlay-scroll-container {
  overflow: hidden;
}

.confirm-dialog {
  z-index:${TOP_Z_INDEX};
}

.quant-practice-group-modal-suggest{
  max-height: 240px;
  overflow: hidden;
}

/* allow custom button text */
.bp3-button.bp3-intent-primary > span {
  color: ${(props) => props.theme.colors.primaryButtonText} !important;
}
.bp3-button.bp3-intent-primary {
  color: ${(props) => props.theme.colors.primaryButtonText} !important;
}


.bp3-tag.bp3-intent-primary {
  color: ${(props) => props.theme.colors.primaryButtonText} !important;
}

.bp3-button.bp3-minimal.bp3-intent-primary {
  color: ${(props) => props.theme.colors.minimalButtonTextColor} !important;
  .bp3-button-text {
    color: ${(props) => props.theme.colors.minimalButtonTextColor} !important;
  }

}

/* END BLUEPRINT STYLES */
`;
