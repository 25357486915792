// This file holds the theme elements that are common across all modules.
import limerockBackground from './limerock_background.jpg';

import { defaultTheme } from '../default';
import clientLogo from './limerock_logo.png';
import poweredByLogo from '../default/Axio360.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';

export const LIMEROCK_ORANGE = '#ffc618';
export const LIMEROCK_BROWN = '#524324';
export const LIMEROCK_BLACK = '#2c281f';
export const LIMEROCK_GREY = '#858585';

export const limerockTheme = {
  ...defaultTheme,
  title: 'Axio360',
  name: 'limerock',
  poweredBy: true,
  tocPattern: defaultTheme.backgroundImage,
  homepage: 'https://www.lrpartners.com',
  clientName: 'LIMEROCK',
  clientNameLong: 'Lime Rock Partners',
  logoHeight: 43,
  clientLogo,
  authLogo: clientLogo,
  poweredByLogo: poweredByLogo,
  authPoweredByLogo: authPoweredByLogo,
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: limerockBackground,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    primaryButton: LIMEROCK_ORANGE,
    primaryButtonText: LIMEROCK_BLACK,
    authBackground: defaultTheme.colors.darkGray,
    minimalButtonTextColor: LIMEROCK_BLACK,
  },
};
