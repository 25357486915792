import { Button, Card } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LABEL_GRAY } from 'common/constants';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75em;
  color: gray;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
  z-index: 2;
  padding-left: 0.5em;
  background-color: white;
  min-height: 24px;
  max-height: 48px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FilterButton = styled(Button)`
  .bp3-button-text {
    color: gray !important;
    font-size: 0.75em;
  }
`;

export const FilterIcon = styled(FontAwesomeIcon)<{ fontSize?: string }>`
  font-size: ${(props) => props.size};
`;

export const ScrollableCard = styled(Card)`
  max-height: 100vh;
  min-width: 300px;
  overflow: auto;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  min-width: 300px;
`;

export const TagFilterColumn = styled(FilterColumn)`
  border-left: 1px solid ${LABEL_GRAY};
`;

export const FilterColumnRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionContainer = styled.div`
  padding-left: 10px;
`;

export const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  .svg-inline--fa {
    margin-right: 20px;
  }
  margin-bottom: 10px;
`;

export const FilterKeywordContainer = styled.div`
  margin: 0 10px 10px 10px;
`;

export const FilterKeywordLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
