import defaultTheme from './default';
import {
  ARCHER_BLACK,
  ARCHER_GREEN,
  ARCHER_ORANGE,
} from 'common/themes/archer';
import archerAuthBackground from 'common/themes/archer/archer_auth_background.png';
import { transparentize, desaturate } from 'polished';

const LOGO_WIDTH = 100;

export default {
  ...defaultTheme,
  poweredBy: true,
  poweredByBackground: true,
  hasOwnIntroScreen: false,
  authBackground: archerAuthBackground,
  authWelcomeText: '',
  logoWidth: LOGO_WIDTH + 'px',
  tocPattern: archerAuthBackground,
  colors: {
    ...defaultTheme.colors,
    headerBackground: 'transparent',
    headerText: ARCHER_BLACK,
    menuSubtext: 'lightgray',
    nameplate: ARCHER_BLACK,
    background: ARCHER_BLACK,
    qlBackground: ARCHER_BLACK,
    tocExpand: ARCHER_GREEN,
    primaryButton: ARCHER_ORANGE,
    primaryButtonText: ARCHER_BLACK,
    avatarColor: ARCHER_ORANGE,
    avatarTextColor: ARCHER_BLACK,
    avatarNameplateColor: ARCHER_BLACK,
    tocCollapseColor: ARCHER_ORANGE,
    qlAnswerHover: desaturate(0, transparentize(0.5, ARCHER_ORANGE)),
    qlAnswerText: ARCHER_BLACK,
    qlAnswerBackground: ARCHER_ORANGE,
    taglineColor: ARCHER_GREEN,
    tocBackgroundColor: ARCHER_BLACK,
    authButtonBackground: ARCHER_ORANGE,
    authButtonTextColor: ARCHER_BLACK,
  },
  hasIntroTour: false,
};
