import { Button, Callout, FormGroup } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Redirect, RouteComponentProps, StaticContext } from 'react-router';

import Logger from 'common/utils/Logger';
import { useUser } from 'common/utils/userContext';
import {
  AuthForm,
  AuthPage,
  BottomLink,
  BottomLinks,
  LoginInput,
} from './shared';
import { IThemeInterface } from '../../themes';
import { withTheme } from 'styled-components/macro';

const initialFormValues = { code: '' };

type FormState = typeof initialFormValues;

export const SMSVerificationPage = (
  props: RouteComponentProps<
    {},
    StaticContext,
    { email?: string; from?: Location }
  > & { theme: IThemeInterface }
) => {
  const { confirmSignIn } = useUser();
  const [error, setError] = useState('');
  const emailInCognito = props.location.state.email;
  if (!emailInCognito) {
    Logger.error('emailInCognito arg not supplied');
    return <Redirect to="/" />;
  }
  const onSubmit = async (
    { code }: FormState,
    { setSubmitting }: FormikHelpers<FormState>
  ) => {
    setError('');
    try {
      await confirmSignIn(code);

      const redirectTo = (props.location.state &&
        props.location.state.from) || { pathname: '/' };
      props.history.replace(redirectTo);
    } catch (err: any) {
      setError(err.message);
      setSubmitting(false);
    }
  };

  return (
    <AuthPage
      welcomeText="Verify"
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<FormState> initialValues={initialFormValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, isValid, handleSubmit, handleChange }) => (
          <AuthForm onSubmit={handleSubmit}>
            <FormGroup>
              <LoginInput
                name="code"
                placeholder="Code"
                autoComplete="off"
                value={values.code}
                onChange={handleChange}
                required
                large
              />
            </FormGroup>
            {!!error && (
              <FormGroup>
                <Callout intent="danger">{error}</Callout>
              </FormGroup>
            )}

            <Button
              text="Verify"
              type="submit"
              disabled={isSubmitting || !isValid}
              fill
              large
            />

            <BottomLinks smallMargins={true}>
              <BottomLink isWhite={true} to="/">
                Return to login page
              </BottomLink>
            </BottomLinks>
          </AuthForm>
        )}
      </Formik>
    </AuthPage>
  );
};

export const SMSVerificationForm = withTheme(SMSVerificationPage);
