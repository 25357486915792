import { push } from 'connected-react-router';
import { History } from 'history';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { capitalize, debounce } from 'lodash';
import { Map } from 'immutable';

import { updateUser } from 'common/actions/user';
import { DASHBOARD_URL } from 'common/constants';
import { IUser } from 'common/databaseTypes';
import { mergeSortOrder } from 'common/components/SortFilterArea/utils';
import { getUserId, getUserJS } from 'common/selectors/user';
import Logger from 'common/utils/Logger';

import { IRootState } from '../../reducers';
import {
  IAssessmentFactory,
  IModelDimension,
  IModelLevel,
  IModelPractice,
} from 'assessments/reducers/models/model';
import {
  getDimensions,
  getModelDimensionSchema,
  getModelLevels,
  getPracticeMap,
} from 'assessments/selectors/model';
import { ALL_ASSESSMENT_STATES, ASSESSMENT_ID_FIELD } from '../constants';
import {
  AssessmentModes,
  ILink,
  IResponse,
  IResponseDimension,
} from '../databaseTypes';
import {
  getActiveSortCriteria,
  getFilteredSortedAssessments,
  getResponses,
  getSelectedAssessmentId,
} from '../selectors';
import { getSelectedAssessment } from '../selectors/assessment';
import { createJournalEntry, updateScope } from './persistence';
import { updateScenarioSusceptibility } from './responsesUpdateScenarios';
import { getSelectedDimensionKey } from 'assessments/selectors/app';
import { AssessmentView } from '../reducers/app';
import {
  getAssessmentView,
  getSelectedPracticePosition,
} from '../selectors/app';
import { finishQL } from './quickLaunch';

type Dispatch = ThunkDispatch<IRootState, void, AnyAction>;

export const changeAssessmentMode = (mode: AssessmentModes) => {
  return {
    type: 'CHANGE_ASSESSMENT_MODE',
    mode,
  };
};

export const setReferenceAssessments = (assessmentIds: string[]) => {
  return {
    type: 'SET_REFERENCE_ASSESSMENTS',
    assessmentIds,
  };
};

export function createPracticeItemAction(
  singular: string,
  suffix: string,
  assessmentId: string,
  practiceId: string,
  itemKey: string,
  item: ILink | null
) {
  return {
    type: 'CREATE_' + suffix,
    [ASSESSMENT_ID_FIELD]: assessmentId,
    id: practiceId,
    key: itemKey,
    [singular]: item,
  };
}

export function deletePracticeItemAction(
  singular: string,
  suffix: string,
  assessmentId: string,
  practiceId: string,
  itemKey: string
) {
  return {
    type: 'DELETE_' + suffix,
    [ASSESSMENT_ID_FIELD]: assessmentId,
    id: practiceId,
    key: itemKey,
    [singular]: null,
  };
}

export function changePracticeField(singular: string, actionSuffix: string) {
  return (practiceId: string, itemKey: string | null, item: ILink | null) => (
    dispatch: Dispatch,
    getState: () => IRootState
  ) => {
    if (!itemKey && !item) {
      Logger.error(
        `You can't change a(n) ${singular} with no key or ${singular}`
      );
      return;
    }

    const assessmentId = getSelectedAssessmentId(getState());

    if (!itemKey) {
      dispatch(
        createPracticeItemAction(
          singular,
          actionSuffix,
          assessmentId,
          practiceId,
          uuid(),
          item
        )
      );
    } else if (!item) {
      dispatch(
        deletePracticeItemAction(
          singular,
          actionSuffix,
          assessmentId,
          practiceId,
          itemKey
        )
      );
    } else {
      dispatch({
        type: 'UPDATE_' + actionSuffix,
        [ASSESSMENT_ID_FIELD]: assessmentId,
        id: practiceId,
        key: itemKey,
        [singular]: item,
      });
    }
  };
}

export const changeLink = changePracticeField('link', 'LINK');

/** "Non-Thunk" version of the update object.  Exported only to
   make test writing easier
*/

export const updatePracticeFields = (
  practiceFields: Record<string, any>,
  assessmentId: string,
  practiceId: string,
  blankExpected = false
) => {
  const result = {
    type: 'UPDATE_PRACTICE',
    [ASSESSMENT_ID_FIELD]: assessmentId,
    id: practiceId,
    practiceFields: practiceFields,
  };
  if (blankExpected) {
    result.practiceFields.expected = {};
  }
  return result;
};

const updateAssessmentIfAssigneeAltered = (
  dispatch: Dispatch,
  state: IRootState
) => {
  const assessment = getSelectedAssessment(state);
  const userId = getUserId(state);
  if (
    userId !== assessment?.owner &&
    assessment?.assessmentState === ALL_ASSESSMENT_STATES[0]
  ) {
    updateAssessmentState(dispatch, state, 1);
  }
};

const updateAssessmentState = (
  dispatch: Dispatch,
  state: IRootState,
  assessmentStateIndex: number
) => {
  const userId = getUserId(state);
  dispatch(
    updateScope({
      assessmentState: ALL_ASSESSMENT_STATES[assessmentStateIndex],
      _updated_by: userId,
    })
  );
};

const debounceDelayInMs = 1500;
const maxDelayInMs = 5000;
const debounceCalculation = debounce(
  updateScenarioSusceptibility,
  debounceDelayInMs,
  { maxWait: maxDelayInMs }
);

export function constructPracticeLevelChangedText(
  levels: IModelLevel[],
  practiceMap: Immutable.Map<string, IModelPractice>,
  practiceId: string,
  currentLevelOrdinalOrUndefined: number | undefined,
  nextLevelOrdinal: number,
  currentOrTarget = 'current'
) {
  const currentLevelOrdinal =
    currentLevelOrdinalOrUndefined === undefined
      ? -1
      : currentLevelOrdinalOrUndefined;
  const currentLevelText =
    levels.find((level) => level.value === currentLevelOrdinal)?.text ??
    '<unset>';
  //we use -1 for unset so lookup will fail and we'll use default
  const nextLevelText =
    levels.find((level) => level.value === nextLevelOrdinal)?.text ?? '<unset>';
  const fqn = practiceMap.getIn([practiceId, 'fqn']) || 'unknown';

  //use "" for current and add space if not current so formatting works
  const currentOrTargetText =
    currentOrTarget === 'current' ? '' : currentOrTarget + ' ';
  return `Changed ${currentOrTargetText}level of ${fqn} from ${currentLevelText} to ${nextLevelText}.`;
}

const journaledDimensionFields = ['level', 'targetLevel'];
export const constructDimensionChangedText = (
  practiceId: string,
  updatedDimension: IResponseDimension,
  oldDimension?: IResponseDimension,
  modelDimension?: IModelDimension
) => {
  const updatedField = oldDimension
    ? Object.entries(updatedDimension).find(
        ([field, value]) => value !== oldDimension[field]
      )?.[0]
    : Object.keys(updatedDimension).find((key) => key !== 'key');

  if (
    modelDimension &&
    updatedField &&
    journaledDimensionFields.includes(updatedField)
  ) {
    const oldValue = oldDimension?.[updatedField];
    const newValue = updatedDimension[updatedField];
    const oldValueText =
      modelDimension.practiceLevels.find((level) => level.value === oldValue)
        ?.text ?? '<unset>';
    const newValueText =
      modelDimension.practiceLevels.find((level) => level.value === newValue)
        ?.text ?? '<unset>';
    return `${capitalize(updatedField)} of '${
      modelDimension.text
    }' dimension on ${practiceId} updated from ${oldValueText} to ${newValueText}.`;
  } else {
    return '';
  }
};

export const updateDimensions = (
  practiceId: string,
  updatedDimension: IResponseDimension,
  argAssessmentId = ''
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const originalState = getState();
    const assessmentId =
      argAssessmentId || getSelectedAssessmentId(originalState);
    updateAssessmentIfAssigneeAltered(dispatch, originalState);
    const stateWithUpdatedScope = getState();
    const currentResponse = getResponses(stateWithUpdatedScope).get(practiceId);
    const currentDimensions = currentResponse?.dimensions ?? [];
    const oldDimension = currentDimensions.find(
      (dimension) => dimension.key === updatedDimension.key
    );
    const practice = getPracticeMap(stateWithUpdatedScope).get(practiceId);
    const disabledDimensions = practice?.disabledDimensions ?? [];

    if (disabledDimensions.includes(updatedDimension.key)) return;

    // The assessment mapping indicator field should be removed if the level changes
    const hasLevelChanged = oldDimension?.level !== updatedDimension.level;
    const isCurrentlyMapped =
      oldDimension && '_sourceAssessment' in oldDimension;
    if (hasLevelChanged && isCurrentlyMapped) {
      updatedDimension._sourceAssessment = undefined;
    }

    let mergedDimensions: IResponseDimension[] = [];
    if (oldDimension) {
      mergedDimensions = currentDimensions.map((currentDimension) =>
        updatedDimension.key === currentDimension.key
          ? updatedDimension
          : currentDimension
      );
    } else {
      mergedDimensions = [...currentDimensions, updatedDimension];
    }

    const updatePracticeFieldsAction = updatePracticeFields(
      { dimensions: mergedDimensions },
      assessmentId,
      practiceId
    );
    dispatch(updatePracticeFieldsAction);

    // Journal Entry
    const userId = getUserId(originalState);
    const modelDimensions = getDimensions(originalState);
    const description = constructDimensionChangedText(
      practiceId,
      updatedDimension,
      oldDimension,
      modelDimensions.find(
        (dimension) => dimension.key === updatedDimension.key
      )
    );
    if (description)
      dispatch(
        createJournalEntry(
          assessmentId,
          userId,
          new Date(),
          description,
          updatePracticeFieldsAction
        )
      );
  };
};

export const updatePracticeLevel = (
  id: string,
  level: number,
  assessmentId = ''
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const originalState = getState();
    const aId = assessmentId || getSelectedAssessmentId(originalState);
    const userId = getUserId(originalState);
    updateAssessmentIfAssigneeAltered(dispatch, originalState);
    const stateWithUpdatedScope = getState();
    const currentResponse = getResponses(stateWithUpdatedScope).get(id);

    const modelsByIdentifier = originalState.licensedModels;

    const updatePracticeFieldsAction = updatePracticeFields(
      {
        level: level,
        levelUpdatedBy: userId,
        _sourceAssessment: undefined,
      },
      aId,
      id
    );
    dispatch(updatePracticeFieldsAction);

    //now add the journal entry
    const currentLevelOrdinal = currentResponse?.level;
    const description = constructPracticeLevelChangedText(
      getModelLevels(stateWithUpdatedScope),
      getPracticeMap(stateWithUpdatedScope),
      id,
      currentLevelOrdinal,
      level
    );
    dispatch(
      createJournalEntry(
        aId,
        userId,
        new Date(),
        description,
        updatePracticeFieldsAction
      )
    );

    const stateWithUpdatedPractice = getState();
    const responses = getResponses(stateWithUpdatedPractice);

    debounceCalculation(modelsByIdentifier, responses);
  };
};

export const updatePracticeTargetLevel = (
  id: string,
  targetLevel: number,
  assessmentId = ''
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const state = getState();
    const aId = assessmentId || getSelectedAssessmentId(state);
    updateAssessmentIfAssigneeAltered(dispatch, state);
    const update = updatePracticeFields({ targetLevel: targetLevel }, aId, id);
    if (targetLevel === -1) {
      update.practiceFields.targetDate = null;
      update.practiceFields.lastSelectedDate = null;
    }
    dispatch(update);

    //now add the journal entry
    const currentResponse = getResponses(state).get(id);
    const currentLevelOrdinal = currentResponse?.level;
    const userId = getUserId(state);
    const description = constructPracticeLevelChangedText(
      getModelLevels(state),
      getPracticeMap(state),
      id,
      currentLevelOrdinal,
      targetLevel,
      'target'
    );
    dispatch(createJournalEntry(aId, userId, new Date(), description, update));
  };
};

/*
 * wrappingClamp: Takes a current value, a min, max and direction and
 * returns the next value such that moving past the max yields the min and
 * moving past the min yields the max.
 */
const wrappingClamp = (
  current: number | undefined,
  min: number,
  max: number,
  direction = 1
) => {
  const updated =
    current === undefined ? (direction === 1 ? min : max) : current + direction;
  return updated > max ? min : updated < min ? max : updated;
};

//This function is specifically built to update the dimension level if you
//are navigating the assessment page using the left and right arrow keys.
const updateDimensionLevel = (
  practiceId: string,
  inTargetMode: boolean,
  direction: 1 | -1
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const state = getState();
    const selectedDimensionKey = getSelectedDimensionKey(state);
    const currentResponse = getResponses(state).get(practiceId);
    const dimensionSchema = getModelDimensionSchema(state);
    const responseDimension =
      currentResponse?.dimensions?.find(
        (dimension) => dimension.key === selectedDimensionKey
      ) ?? ({ key: selectedDimensionKey } as IResponseDimension);

    if (!dimensionSchema || !selectedDimensionKey) return;

    updateAssessmentIfAssigneeAltered(dispatch, state);

    const { min, max } = dimensionSchema.minMaxLevelMap.get(
      selectedDimensionKey
    ) ?? { min: 0, max: 0 };

    const mode = inTargetMode ? 'targetLevel' : 'level';
    const currentValue = responseDimension[mode];
    const valueToUpdate = wrappingClamp(currentValue, min, max, direction);

    const updatedDimension = { ...responseDimension, [mode]: valueToUpdate };
    const updateDimensionsFunction = updateDimensions(
      practiceId,
      updatedDimension
    );

    updateDimensionsFunction(dispatch, getState);
  };
};

export const decrementDimensionLevel = (id: string, inTargetMode: boolean) => {
  return updateDimensionLevel(id, inTargetMode, -1);
};

export const incrementDimensionLevel = (id: string, inTargetMode: boolean) => {
  return updateDimensionLevel(id, inTargetMode, 1);
};

export const flagPractice = (
  id: string,
  isFlagged: boolean,
  assessmentId = ''
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const aId = assessmentId || getSelectedAssessmentId(getState());
    dispatch({
      type: 'CHANGE_PRACTICE_FLAGGED',
      [ASSESSMENT_ID_FIELD]: aId,
      id,
      practiceFields: { isFlagged },
    });
  };
};

export const setIsCritical = (
  id: string,
  isCritical: boolean,
  assessmentId = ''
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const aId = assessmentId || getSelectedAssessmentId(getState());
    dispatch({
      type: 'CHANGE_PRACTICE_CRITICAL',
      [ASSESSMENT_ID_FIELD]: aId,
      id,
      practiceFields: { isCritical },
    });
  };
};

export const updateTargetDate = (
  id: string,
  targetDate: string | null | undefined,
  assessmentId = '',
  resetEverything?
) => {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    const state = getState();
    const aId = assessmentId || getSelectedAssessmentId(state);
    updateAssessmentIfAssigneeAltered(dispatch, state);
    const newPracticeFields = { targetDate, resetEverything };
    dispatch({
      type: 'UPDATE_PRACTICE',
      [ASSESSMENT_ID_FIELD]: aId,
      id,
      practiceFields: newPracticeFields,
    });
  };
};

export const toggleWizardView = () => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();
  const assessmentView = getAssessmentView(state);
  const selectedPracticePosition = getSelectedPracticePosition(state);
  let position;
  if (assessmentView === AssessmentView.WIZARD) {
    finishQL();
    position = 'finished';
  } else if (assessmentView === AssessmentView.QUICKLAUNCH) {
    position = selectedPracticePosition ? selectedPracticePosition : '0';
    const responses = getResponses(state);
    dispatch(setOriginalResponses(responses));
  } else {
    // Switch from assessment to wizard. quicklaunch_position will always be 'finished'
    // so we can't retrieve its true position before the wizard was marked as 'finished'
    const responses = getResponses(state);
    dispatch(setOriginalResponses(responses));
    position = '0';
  }
  dispatch(
    updateScope({
      quicklaunch_position: position,
    })
  );
};

export const setOriginalResponses = (responses: Map<string, IResponse>) => {
  return {
    type: 'SET_ORIGINAL_RESPONSES',
    responses: responses ?? null,
  };
};

export const dispatchSetOriginalResponses = () => (
  dispatch: Dispatch,
  getState: () => IRootState
) => dispatch(setOriginalResponses(getResponses(getState())));

export const updateSelectedPractice = (practiceId: string) => {
  return {
    type: 'UPDATE_SELECTED_PRACTICE',
    practiceId,
  };
};

export const updateSelectedDimensionKey = (dimensionKey: string | null) => {
  return {
    type: 'UPDATE_SELECTED_DIMENSION',
    dimensionKey,
  };
};

export const newMilestoneRequest = () => {
  return {
    type: 'NEW_MILESTONE_REQUEST',
  };
};

export const assessmentConvertBegin = () => {
  return {
    type: 'ASSESSMENT_CONVERT_BEGIN',
  };
};

export const loadingAssessment = () => {
  return {
    type: 'LOADING_ASSESSMENT',
  };
};

export const turnOnModeSpinner = () => {
  return {
    type: 'MODE_SPINNER_ON',
  };
};

export const turnOffModeSpinner = () => {
  return {
    type: 'MODE_SPINNER_OFF',
  };
};

export const navigateTo = (location: string) => {
  return push(location);
};

export const userGoneIdle = () => {
  Promise.resolve().then(() => {
    const event = new CustomEvent('IDLE_USER_INACTIVE');
    window.dispatchEvent(event);
  });
  return {
    type: 'IDLE_USER_INACTIVE',
  };
};

export const userIsBack = (notifyOtherWindows = true) => {
  // the IdleMiddleware is listening and responding to this key.
  // This is to ensure any other also close the dialog and mark the user as back
  if (notifyOtherWindows) {
    localStorage.setItem('loginStatus', 'userIsBack');
    localStorage.removeItem('loginStatus');
  }
  Promise.resolve().then(() => {
    const event = new CustomEvent('IDLE_USER_IS_BACK');
    window.dispatchEvent(event);
  });
  return {
    type: 'IDLE_USER_IS_BACK',
  };
};

const internalSelectAssessment = (
  dispatch: Dispatch,
  assessmentId: string,
  pathname: string,
  history: History,
  updateUserMessage,
  afterAction: AnyAction | null = null
) => {
  // Make sure connected-react-router is f'd.  We need this to be right
  // before we start updating things in the react store because updating those
  // things can cause componentDidUpdate (which can cause all kinds of other
  // issues.)
  const newUrl =
    !!assessmentId && !pathname.includes('aggregatedashboard')
      ? `${pathname}/${assessmentId}`
      : pathname;

  // Get the internal assessment correctly selected.
  dispatch({
    type: 'SELECTED_ASSESSMENT',
    assessmentId,
  });

  // Not exactly "after" but no sense waiting on the user update to come back
  history.push(newUrl);
  if (afterAction) {
    dispatch(afterAction);
  }

  // The database keeps track of the last URL a user navigated to.  This updateuser is what
  // does that.
  dispatch(updateUserMessage);
};

export const selectAssessment = (
  assessmentId: string,
  pathname: string,
  history: History,
  afterAction: AnyAction | null = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const user = getUserJS(getState());
  if (!user._id) {
    // No user yet so don't try to set active_assessment
    // This can happen if navigating directly to an assessment and the user fetch has not yet resolved
    dispatch({
      type: 'SELECTED_ASSESSMENT',
      assessmentId,
    });
    if (afterAction) {
      dispatch(afterAction);
    }
    return;
  }

  internalSelectAssessment(
    dispatch,
    assessmentId,
    pathname,
    history,
    updateUser({
      _id: user._id,
      _etag: user._etag,
      active_assessment: assessmentId,
    }),
    afterAction
  );
};

export const setAssessmentSort = (
  sortOrder: string,
  user: IUser | null = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const userObj = (user === null ? getUserJS(getState()) : user) || {
    _id: undefined,
    _etag: undefined,
  };
  const existing = getActiveSortCriteria(getState());
  const sort = mergeSortOrder(existing, sortOrder);

  dispatch({
    type: 'ASSESSMENT_SORT',
    sort,
  });

  dispatch(
    updateUser({
      _id: userObj._id,
      _etag: userObj._etag,
      last_sort: sort,
    })
  );
};

export const setFilterCriteria = (
  filterCriteria: string,
  user: IUser | null = null,
  history?: History
) => (dispatch: Dispatch, getState: () => IRootState) => {
  const userObj = (user === null ? getUserJS(getState()) : user) || {
    _id: undefined,
    _etag: undefined,
  };
  // This feels the wrong way to solve this issue
  // 1. get currentlySelectedAssessmentId
  // 2. Make a new version of state with the new filter criteria
  // 3. Call filtered assessment list with new state
  // 4. Check list for assessment id

  const state = getState();
  const currentlySelectedAssessmentId = getSelectedAssessmentId(state);

  // If filterCriteria is '', we have to force the filter to "-ALL-".
  // "" (which also means all) causes the system to default to the user's
  // last active filter which isn't what we want to use here.
  const nextState = {
    ...state,
    app: { ...state.app, filterCriteria: filterCriteria || '-ALL-' },
  };

  const nextFilteredAssessmentList = getFilteredSortedAssessments(nextState);
  const needNewSelected = !nextFilteredAssessmentList.has(
    currentlySelectedAssessmentId
  );

  const selectedFragment = needNewSelected
    ? {
        active_assessment: (
          nextFilteredAssessmentList.find(
            (assessment) =>
              assessment && assessment._id !== currentlySelectedAssessmentId
          ) || { _id: '' }
        )._id,
      }
    : {};

  dispatch({
    type: 'FILTER_CRITERIA',
    filterCriteria,
  });

  const userUpdateMessage = updateUser({
    _id: userObj._id,
    _etag: userObj._etag,
    last_filter: filterCriteria,
    ...selectedFragment,
  });

  // If we've changed the selected assessment, we also need to
  // fix the URL and the redux store.
  if (history && needNewSelected) {
    // By the way.... this whole "pathname" parsing crap is utter junk.
    // If you're in here, hate it and want to do something else, knock yourself out.
    // I left this crap the way it was largely because we're ultimately dumping redux.
    // HOWEVER... we only expect the filter to change from the base dashboard and from
    // the aggregate dashboard.  SO...
    const pathname = window.location.pathname.includes('aggregatedashboard')
      ? window.location.pathname
      : DASHBOARD_URL;
    internalSelectAssessment(
      dispatch,
      selectedFragment.active_assessment || '',
      pathname,
      history,
      userUpdateMessage
    );
  } else {
    dispatch(userUpdateMessage);
  }
};

export const selectComparisonAssessment = (assessmentId: string) => {
  return {
    type: 'SELECTED_COMPARISON_ASSESSMENT',
    assessmentId,
  };
};

export const selectMilestone = (milestoneId: string) => {
  return {
    type: 'SELECTED_MILESTONE',
    milestoneId,
  };
};

export const selectMilestoneForEdit = (milestoneId: string) => {
  return {
    type: 'SELECTED_MILESTONE_FOR_EDIT',
    milestoneId,
  };
};

export const selectAssessmentCreation = (
  assessmentFactory: IAssessmentFactory
) => {
  return {
    type: 'SELECTED_ASSESSMENT_CREATION',
    assessmentFactory,
  };
};

export const clearErrorMessage = () => ({
  type: 'CLEAR_ERROR_MESSAGE',
});

export const toggleDescriptum = (descriptum: string) => {
  return {
    type: 'TOGGLE_DESCRIPTUM',
    descriptum,
  };
};

export const removeDescriptum = () => {
  return {
    type: 'REMOVE_DESCRIPTUM',
  };
};

export const clearDescriptumHistory = () => {
  return {
    type: 'CLEAR_DESCRIPTUM_HISTORY',
  };
};

export const selectSubsidiaryId = (subsidiaryId: string) => {
  return {
    type: 'SELECT_SUBSIDIARY_ID',
    subsidiaryId,
  };
};
