import { Map as ImmutableMap } from 'immutable';
import { AnyAction, Reducer } from 'redux';
import { QueryResult } from '@apollo/client';

import { IModelState } from './model';
import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  LegacyModelIdEnum,
  ModelFieldsFragment,
} from 'common/graphql/graphql-hooks';
import { getLegacyModelConfigs } from './legacyModelConfig';
import { modelStateFactory } from './modelStateFactory';

type QueryResultType = QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export type ILicensedModelsState = ImmutableMap<string, IModelState>;
const initialState = ImmutableMap({}) as ILicensedModelsState;

export const modelReducer: Reducer<ILicensedModelsState, AnyAction> = (
  state = initialState,
  action
) =>
  shouldUpdateModels(state, action) ? getModelStateFromAction(action) : state;

const shouldUpdateModels = (
  state: ILicensedModelsState,
  action: AnyAction
): boolean =>
  action.type === 'APOLLO_QUERY_RESULT' &&
  action.operationName === 'CurrentUser' &&
  didModelsChange(state, action.result as QueryResultType);

/* Note that we are only comparing model keys */
const didModelsChange = (
  existingState: ILicensedModelsState,
  incomingModelData: QueryResultType
): boolean => {
  const existingKeys = new Set(existingState.keys());

  const incomingModels = incomingModelData?.data?.getLicensedModels ?? [];
  const incomingKeys = new Set(incomingModels.map((model) => model.uuid));

  return (
    existingKeys.size !== incomingKeys.size ||
    ![...existingKeys].every((key) => incomingKeys.has(key))
  );
};

const getModelStateFromAction = (action: AnyAction): ILicensedModelsState => {
  const result = action.result as QueryResultType;
  return convertToMap(result.data?.getLicensedModels ?? []);
};

const convertToMap = (models: ModelFieldsFragment[]): ILicensedModelsState => {
  const legacyModelConfig = getLegacyModelConfigs();

  return models.reduce((map, model) => {
    const legacyConfig = legacyModelConfig.get(model.uuid as LegacyModelIdEnum);

    const modelState = modelStateFactory(model, legacyConfig);

    return map.set(model.uuid, modelState);
  }, ImmutableMap<string, IModelState>({}));
};
