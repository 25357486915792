import { mlsTheme as baseTheme, MLS_BLUE } from 'common/themes/mls';
import defaultTheme from './default';
import { NAVBAR_WIDTH, TOC_WIDTH } from 'common/constants';

const LOGO_WIDTH = TOC_WIDTH + NAVBAR_WIDTH + 5;

export default {
  ...defaultTheme,
  poweredBy: false,
  hasOwnIntroScreen: false,
  authBackground: baseTheme.backgroundImage,
  logoWidth: LOGO_WIDTH + 'px',
  hasIntroTour: false,
  colors: {
    authButtonBackground: '#e1e1e1',
    authButtonTextColor: MLS_BLUE,
  },
};
