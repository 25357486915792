import { Map } from 'immutable';
import { Reducer } from 'redux';
import { groupBy as _groupBy } from 'lodash';

import { IResponse } from '../databaseTypes';
import { ASSESSMENT_ID_FIELD } from 'assessments/constants';
import { IPartialResponse, score } from '../score/score';
import { IModelState } from './models/model';

type AssessmentId = string;
export interface IScoredHistory {
  date: Date;
  currentScore: number | null;
  targetScore: number | null;
  projectedScore: number | null;
}

interface HistoryResponse {
  _practice_id: string;
  level?: number | null;
  targetLevel?: number | null;
  targetDate?: string | null;
}

export interface IHistoryRecord {
  change_date: string;
  practiceMap: {
    [practiceId: string]: HistoryResponse;
  };
}

type IHistoricalData =
  | {
      loaded: false;
      tempResponses?: IResponse[];
    }
  | {
      loaded: true;
      tempModelUuid: string;
      tempHistory: IHistoryRecord[];
      tempResponses?: IResponse[];
    };
// | {
//     loaded: true;
//     // scoredHistory: IScoredHistory[];
//   };

export type IState = Map<AssessmentId, IHistoricalData>;

const initialState: IState = Map({});

export const levelHistoryReducer: Reducer<IState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return initialState;
    }
    case 'get_resource/responses': {
      const assessments = _groupBy(
        action.response.result._items,
        (item) => item[ASSESSMENT_ID_FIELD]
      );
      return state.withMutations((m) => {
        // action.response[ASSESSMENT_ID_FIELD] is a string for an single assessment,
        // or an array of strings if multiple asssessments of responses are returned
        // We want to store an empty map if the assessment has no responses yet to know that we have tried to fetch
        const aIds = [].concat(action.response[ASSESSMENT_ID_FIELD]);
        aIds.forEach((key) => {
          const existing: IHistoricalData = m.get(key, {
            loaded: false,
          });
          const newValue = { ...existing, tempResponses: assessments[key] };

          m.set(key, newValue);
        });
      });
    }

    case 'get_resource/responses_level_history': {
      const tempModelUuid = action.request.modelUuid;
      const assessmentId = action.request.assessment;
      const levelHistory = action.response.result._items;
      // If we have responses for this assessment, we can do the scoring.
      // we if don't, we need to just temporarily cache the history items
      // until we get responses.
      const existing = state.get(assessmentId, {
        loaded: false,
      });
      const newValue: IHistoricalData = {
        ...existing,
        loaded: true,
        tempModelUuid,
        tempHistory: [...levelHistory],
      };
      return state.set(assessmentId, newValue);
    }
    default:
      break;
  }
  return state;
};

const effectiveTargetLevel = (changeDate: string, obj: HistoryResponse) =>
  obj.targetDate && obj.targetDate > changeDate ? obj.level : obj.targetLevel;

const defaultTargets = (historyRecord: IHistoryRecord) => {
  return Object.keys(historyRecord.practiceMap).reduce<{
    [practiceId: string]: HistoryResponse;
  }>((accum, practiceId) => {
    const oldObj = historyRecord.practiceMap[practiceId];
    // If the target date of the level is greater than
    // the date of the change, we won't count it as a
    // target for that date.
    const targetLevel = effectiveTargetLevel(historyRecord.change_date, oldObj);
    accum[practiceId] = { ...oldObj, targetLevel };
    return accum;
  }, {});
};

/**
 * Strip the time component from a ISO8601 datetime
 *
 * ie. 2020-01-01T12:23:15.0221Z => 2020-01-01
 */
const groupDate = (dateTimeString?: string | null) =>
  (dateTimeString || '1970-01-01').substring(0, 10);

// This function returns an iteratable object that
// iterates over the items in the history array and
//
const dateMergeSort = (
  historyArray: IHistoryRecord[],
  currentArray: IHistoryRecord[]
) => {
  // We've got a set of responses that were updated on a particular date,
  // and a set of history records that were updated on a particular date.
  // So, we need to put them together, in order.  If a history record and
  // a current record are for the same date, lay the current on top of the
  // history.  We're assuming this is grouped data so neither array will
  // have more than one record for a given date.
  const next = (arr: IHistoryRecord[]) => {
    const iterator = arr.values();
    return () => {
      const val = iterator.next();
      return val.done
        ? null
        : {
            change_date: groupDate(val.value.change_date),
            practiceMap: val.value.practiceMap,
          };
    };
  };

  const blankHistory: IHistoryRecord = {
    change_date: '',
    practiceMap: {},
  };

  const dsort = <T extends { change_date: string }>(o1: T, o2: T) =>
    groupDate(o1.change_date).localeCompare(groupDate(o2.change_date));
  const nextHistory = next(historyArray.sort(dsort));
  const nextCurrent = next(currentArray.sort(dsort));

  // This could have been a generator with yield but
  // when I tried it, it blew up and I knew this would
  // work so I didn't spend too much time looking at it.
  return {
    history: nextHistory(),
    current: nextCurrent(),
    // Support for "iterable" protocol
    [Symbol.iterator]: function () {
      return this;
    },
    // Support array-like syntax
    values: function () {
      return this;
    },
    bumpHistory: function () {
      const v = this.history;
      this.history = nextHistory();
      return v || blankHistory;
    },
    bumpCurrent: function () {
      const v = this.current;
      this.current = nextCurrent();
      return v || blankHistory;
    },
    // Support for iteration protocol.
    next: function () {
      const history = this.history;
      const current = this.current;
      if (!history && !current) {
        // We've exhausted the history array and the current array,
        // so we're done.
        return { done: true, value: blankHistory };
      }

      if (history && (!current || history.change_date < current.change_date)) {
        // The active element in the history array is earlier than
        // the active element in the current array, so return the history one.
        return { value: this.bumpHistory() };
      }

      if (current && (!history || current.change_date < history.change_date)) {
        // The active element in the current array is earlier than
        // the active element in the history array, so return the current one.
        return { value: this.bumpCurrent() };
      }

      // The active element in the history array is the same date as the active
      // element in the current array, so return the merge of them.
      const historyMap = history?.practiceMap || blankHistory.practiceMap;
      const currentMap = current?.practiceMap || blankHistory.practiceMap;
      const change_date = current?.change_date || '';
      this.bumpHistory();
      this.bumpCurrent();
      return {
        value: {
          change_date,
          practiceMap: {
            ...historyMap,
            ...currentMap,
          },
        },
      };
    },
    map: function <U>(mapFunction: (value: IHistoryRecord) => U) {
      const result: U[] = [];
      for (;;) {
        const val = this.next();
        if (val.done) {
          return result;
        }
        result.push(mapFunction(val.value));
      }
    },
  };
};

export const scoreLevelHistory = (
  historicalData: IHistoricalData,
  model: IModelState
): IScoredHistory[] => {
  if (!('tempModelUuid' in historicalData)) {
    return [];
  }

  const { tempResponses, tempHistory } = historicalData;
  if (!model || !tempResponses || !tempHistory) {
    // We need responses and history to create the score object, so we'll
    // just return.
    return [];
  }

  // Convert the shape of the responses array to a shape that matches
  // the history data.  Begin by using lodash:groupBy to get an object,
  // keyed by date, where each date is an array of responses that were
  // updated on that date.
  const temp = _groupBy(tempResponses, (itm) => groupDate(itm._updated));

  // Now, convert the lodash shape into an array of IHistoryRecords.
  const responseData = Object.keys(temp).map((key) => ({
    change_date: key,
    practiceMap: temp[key].reduce<{ [practiceId: string]: HistoryResponse }>(
      (accum, itm) => {
        accum[itm._practice_id] = itm;
        return accum;
      },
      {}
    ),
  }));

  // historyData is an iteratable, mappable object that will
  // feather the tempHistory and responseData into something that
  // looks like a single array, in date order.
  const historyData = dateMergeSort(tempHistory, responseData);

  // Go through the array, laying each date on top of the one
  // before it and then scoring the resulting combination.
  const historicalResponses = {};
  const scoredHistory: IScoredHistory[] = historyData.map((day) => {
    Object.assign(historicalResponses, defaultTargets(day));
    const responses = Map<string, IPartialResponse>(historicalResponses);
    return {
      date: new Date(day.change_date),
      currentScore: (score(model, responses) || { score: 0 }).score,
      targetScore: (
        score(model, responses, null, 'targetLevel') || { score: 0 }
      ).score,
      projectedScore: null,
    };
  });

  return scoredHistory;
};
