import { Button } from '@blueprintjs/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AuthPage } from 'authentication/views/shared';
import { getTheme } from 'themes';

const UpsellText = styled.span`
  max-width: 450px;
  margin-top: 20px;
  margin-left: 1rem;
  font-size: 18px;
  text-align: left;
  color: white;
  a {
    color: white;
    :hover {
      text-decoration: underline;
    }
  }
`;

const UpsellContainer = styled.div`
  z-index: 3;
`;

const { salesEmail } = getTheme();

export const UpsellAssessments = () => {
  return (
    <UpsellContainer>
      <AuthPage welcomeText="Assessments Not Available">
        <UpsellText>
          <p>
            To purchase a license or to learn more about Assessments, please
            contact{' '}
            <a href={`mailto:${salesEmail}`} target="_top">
              {`${salesEmail}`}
            </a>
          </p>
          <p>
            If you believe you are seeing this message in error and wish to be
            provisioned, please contact{' '}
            <a href="mailto:support@axio.com" target="_top">
              support@axio.com
            </a>
          </p>
          <Link to="/">
            <Button text="Go back" large />
          </Link>
        </UpsellText>
      </AuthPage>
    </UpsellContainer>
  );
};
