import { FieldFunctionOptions, Reference, StoreObject } from '@apollo/client';
import { ReadFieldOptions } from '@apollo/client/cache/core/types/common';

type DeepReference<X> = X extends Record<string, any>
  ? X extends { id: string }
    ? Reference
    : {
        [K in keyof X]: DeepReference<X[K]>;
      }
  : X extends Array<{ id: string }>
  ? Reference[]
  : X;

export interface ReadFieldFunction {
  <T, K extends keyof T = keyof T>(
    context: FieldFunctionOptions,
    options: ReadFieldOptions
  ): DeepReference<T[K]>;

  <T, K extends keyof T = keyof T>(
    context: FieldFunctionOptions,
    fieldName: K,
    from?: Reference | StoreObject | undefined
  ): DeepReference<T[K]>;
}

export const typedReadField: ReadFieldFunction = (...args) => {
  const [context, ...restArgs] = args;
  return context.readField(...restArgs);
};
