import { csfAssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { colorTable } from 'assessments/utils/nistColorTable';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_csf';

export const csfGuidepointConfig: LegacyModelConfig = {
  icon,
  features: {
    bitsight: true,
    targetProfiles: true,
    convertToFull: true,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: true,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    topOfDomain: 100,
  },
  shortTitle: 'NIST GP Assessment (CMMI)',
  factories: [
    {
      label: 'Nist CSF (GP) Assessment',
      filterLabel: 'Nist CSF (GP)',
      requiredLicenseOption: ModelScope.Full,
      icon,
      sortKey: 'CSF-0',
      modelUuid: LegacyModelIdEnum.Csfguidepoint,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.CSF_GP,
      qualifier: 'CMMI',
    },
  ],
  colorTable,
  terms,
};
