import { Button, Callout, FormGroup } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import {
  Redirect,
  RouteComponentProps,
  StaticContext,
  useLocation,
} from 'react-router';
import Logger from 'common/utils/Logger';
import { useUser } from 'common/utils/userContext';
import {
  AuthForm,
  AuthPage,
  BottomLink,
  BottomLinks,
  ButtonRow,
  LoginInput,
} from './shared';
import { withTheme } from 'styled-components/macro';
import { IThemeInterface } from '../../themes';

const initialFormValues = { code: '' };

type FormState = typeof initialFormValues;

export const ConfirmPage = (
  props: RouteComponentProps<
    {},
    StaticContext,
    { username?: string; email?: string }
  > & { theme: IThemeInterface }
) => {
  const { confirmSignUp, resendSignUp } = useUser();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const { search } = useLocation();

  const {
    codeFromQueryParameters,
    emailFromQueryParameters,
    userNameFromQueryParameters,
  } = useMemo(() => {
    const searchParameters = new URLSearchParams(search);
    return {
      codeFromQueryParameters: searchParameters.get('code'),
      emailFromQueryParameters: searchParameters.get('email'),
      userNameFromQueryParameters: searchParameters.get('username'),
    };
  }, [search]);

  const hasAllQueryParameters =
    !!codeFromQueryParameters &&
    !!emailFromQueryParameters &&
    !!userNameFromQueryParameters;

  const usernameInCognito =
    props.location.state?.username ?? userNameFromQueryParameters;

  const emailInCognito =
    props.location.state?.email ?? emailFromQueryParameters;

  if (!usernameInCognito || !emailInCognito) {
    Logger.error('usernameInCognito or emailInCognito arg not supplied');
    return <Redirect to="/" />;
  }

  const onSubmit = async (
    { code }: FormState,
    { setSubmitting }: FormikHelpers<FormState>
  ) => {
    setError('');
    setMessage('');
    try {
      const result = await confirmSignUp(usernameInCognito, code);
      result
        ? props.history.replace({
            pathname: '/authentication',
            search: `email=${encodeURIComponent(emailInCognito)}`,
            state: props.location.state,
          })
        : setError('Unknown error');
    } catch (err: any) {
      if (err instanceof Error) {
        setError(err.message);
      }
      setMessage('');
      setSubmitting(false);
    }
  };

  const onResend = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      await resendSignUp(usernameInCognito);
      setError('');
      setMessage('Verification code has been resent.');
    } catch (err: any) {
      if (err instanceof Error) {
        setError(err.message);
      }
      setMessage('');
    }
  };

  const initialFormValuesWithCode = {
    ...initialFormValues,
    code: codeFromQueryParameters ?? initialFormValues.code,
  };

  const calloutContent = hasAllQueryParameters ? (
    <div>
      {' '}
      You reached this page via a link sent to
      <strong> {emailInCognito}</strong>.
      <br />
      Please click "Verify" to attempt account verification.
      <br />
      Click 'Resend' to email a new code if required.
    </div>
  ) : (
    <div>
      {' '}
      An email containing a verification code was sent to
      <strong> {emailInCognito}</strong>.
      <br />
      Check your spam folder if the message is not in your inbox.
      <br />
      Click 'Resend' to email a new code if required.
    </div>
  );

  return (
    <AuthPage
      welcomeText="Verify"
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<FormState>
        initialValues={initialFormValuesWithCode}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, isValid, handleSubmit, handleChange }) => (
          <AuthForm onSubmit={handleSubmit}>
            <Callout>{calloutContent}</Callout>
            <br />
            <FormGroup>
              <LoginInput
                name="code"
                placeholder="Verification code"
                autoComplete="off"
                value={values.code}
                onChange={handleChange}
                required
                large
              />
            </FormGroup>
            {!!error && (
              <FormGroup>
                <Callout intent="danger">{error}</Callout>
              </FormGroup>
            )}
            {!!message && (
              <FormGroup>
                <Callout intent="success">{message}</Callout>
              </FormGroup>
            )}
            <ButtonRow>
              <Button
                text="Verify"
                type="submit"
                disabled={isSubmitting || !isValid}
                fill
                large
              />
              <Button text="Resend" onClick={onResend} fill large />
            </ButtonRow>
            <BottomLinks smallMargins={true}>
              <BottomLink isWhite={true} to="/">
                Return to login page
              </BottomLink>
            </BottomLinks>
          </AuthForm>
        )}
      </Formik>
    </AuthPage>
  );
};

export const ConfirmForm = withTheme(ConfirmPage);
