// These must be the first lines in src/index.js
import 'core-js/features/symbol';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { ApolloProvider } from '@apollo/client';
import { FocusStyleManager } from '@blueprintjs/core';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';

import { checkAndClearExpiredToken } from 'authentication/utils/tokens';
import BrowserWarning from 'common/components/BrowserWarning';
import u, {
  googleAnalyticsPropertyID,
  enableLocalhostAnalytics,
} from 'common/utils/AxioUtilities';
import { UserProvider } from 'common/utils/userContext';
import * as serviceWorker from './serviceWorker';

import { GlobalStyle } from './GlobalStyle';
import { MainRouter } from './routes';
import { apolloClient, setSingletonClient } from './setupApolloClient';
import { configureStore } from './setupRedux';
import { getTheme } from './themes';
import './setupFontAwesome';
import Logger from 'common/utils/Logger';

import 'normalize.css/normalize.css';
import { GlobalLoadingIndicator } from 'common/components/Loading/GlobalLoadingIndicator';

FocusStyleManager.onlyShowFocusOnTabs();

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

const store = configureStore(history);
const client = apolloClient(store);
setSingletonClient(client);

const theme = getTheme();

Logger.init();

if (u.inDevelopmentMode) {
  (window as any).store = store;
}

ReactGA.initialize(googleAnalyticsPropertyID(), {
  testMode:
    process.env.NODE_ENV !== 'production' && !enableLocalhostAnalytics(),
});
ReactGA.set({
  hostname: window.location.hostname,
});

checkAndClearExpiredToken();

document.title = theme.title;

const Routes = (
  <ReduxProvider store={store}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <UserProvider>
            <>
              <GlobalLoadingIndicator />
              <GlobalStyle />
              <MainRouter />
            </>
          </UserProvider>
        </ConnectedRouter>
      </ThemeProvider>
    </ApolloProvider>
  </ReduxProvider>
);

const render = () => {
  Logger.info('Rendering app');
  ReactDOM.render(Routes, document.getElementById('root'));
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <BrowserWarning />
    </ThemeProvider>,
    document.getElementById('browserWarning')
  );
};

render();

serviceWorker.unregister();
