import React, { Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { LoadingScreenRaw } from 'common/components/Loading/LoadingScreen';

const Quantification = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Quantification", webpackPrefetch: true */ './views'
    )
);

const GuardQuant = React.memo((props: RouteComponentProps) => (
  <Suspense fallback={<LoadingScreenRaw isLoading />}>
    <Quantification {...props} />
  </Suspense>
));

export default withRouter(GuardQuant);
