// This file holds the theme elements that are common across all modules.
import background from './NMPP_Energy_Axio_platform_splash_background_color.jpg';

import { defaultTheme } from '../default';
import authLogo from './NMPP_Energy_Logo.png';
import clientLogo from './NMPP_Energy_Logo.png';
import poweredByLogo from '../default/Axio360.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';

export const NMPP_GREEN = '#78BE20';
export const NMPP_GRAY = '#97999B';
export const nmppTheme = {
  ...defaultTheme,
  title: 'Axio360',
  name: 'nmpp',
  poweredBy: true,
  tocPattern: defaultTheme.backgroundImage,
  homepage: 'https://www.nmppenergy.org/',
  clientName: 'NMPPEnergy',
  clientNameLong: 'NMPP Energy',
  clientLogo: clientLogo,
  logoHeight: 43,
  authLogo: authLogo,
  poweredByLogo: poweredByLogo,
  authPoweredByLogo: authPoweredByLogo,
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: background,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    authBackground: NMPP_GRAY,
  },
};
