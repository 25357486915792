import React from 'react';
import { StaticContext, withRouter } from 'react-router';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { ConfirmForgotPasswordForm } from './views/ConfirmForgotPasswordForm';
import { ConfirmForm } from './views/ConfirmForm';
import { ForgotPasswordForm } from './views/ForgotPasswordForm';
import { LoginForm } from './views/LoginForm';
import { LogoutPage } from './views/Logout';
import { NewPasswordRequiredForm } from './views/NewPasswordRequiredForm';
import { RegisterForm } from './views/RegisterForm';
import { SMSVerificationForm } from './views/SMSVerificationForm';
import { SsoForm } from './views/SsoForm';

const loginPage = (
  props: RouteComponentProps<{}, StaticContext, { from?: Location }>
) => <LoginForm {...props} />;
const ssoPage = (props: RouteComponentProps<{}>) => <SsoForm {...props} />;
const registerPage = (props: RouteComponentProps<{}>) => (
  <RegisterForm {...props} />
);
const forgotPassword = (props: RouteComponentProps<{}>) => (
  <ForgotPasswordForm {...props} />
);
const confirmForgotPassword = (
  props: RouteComponentProps<{}, StaticContext, { email?: string }>
) => <ConfirmForgotPasswordForm {...props} />;
const newPasswordRequired = (
  props: RouteComponentProps<{}, StaticContext, { email?: string }>
) => <NewPasswordRequiredForm {...props} />;
const confirmPage = (
  props: RouteComponentProps<
    {},
    StaticContext,
    { username?: string; email?: string }
  >
) => <ConfirmForm {...props} />;
const mfaPage = (
  props: RouteComponentProps<
    {},
    StaticContext,
    { email?: string; from?: Location }
  >
) => <SMSVerificationForm {...props} />;
const logoutPage = (
  props: RouteComponentProps<{}, StaticContext, { dontNotify: boolean }>
) => <LogoutPage {...props} />;

const render = () => (
  <div>
    <Switch>
      <Route exact path="/authentication" component={loginPage} />
      <Route exact path="/authentication/login" component={loginPage} />
      <Route exact path="/authentication/sso" component={ssoPage} />
      <Route exact path="/authentication/register" component={registerPage} />
      <Route exact path="/authentication/verify" component={confirmPage} />
      <Route exact path="/authentication/mfa" component={mfaPage} />
      <Route
        exact
        path="/authentication/newpassword"
        component={newPasswordRequired}
      />
      <Route exact path="/authentication/reset" component={forgotPassword} />
      <Route
        exact
        path="/authentication/verifyreset"
        component={confirmForgotPassword}
      />
      <Route exact path="/authentication/logout" component={logoutPage} />
      <Redirect to="/authentication" />
    </Switch>
  </div>
);

export default withRouter(render);
