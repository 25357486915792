import { createSelector } from 'reselect';

import { getUserEmployer } from './user';
import { ScoreWidgetStyle } from '../graphql/graphql-hooks';

export const hasSurveyQuestions = createSelector(
  [getUserEmployer],
  (employer) =>
    Boolean(
      employer && employer.surveyQuestionSchema && employer.surveyUiSchema
    )
);

export const getSingletonAssessments = createSelector(
  [getUserEmployer],
  (employer) => (employer ? employer.singletonAssessments : undefined)
);

export const getSecurityControls = createSelector(
  [getUserEmployer],
  (employer) => (employer ? employer.securityControls : undefined)
);

export const getScoreWidgetStyle = createSelector(
  [getUserEmployer],
  (employer) =>
    employer
      ? employer.scoreWidgetStyle ?? ScoreWidgetStyle.Pie
      : ScoreWidgetStyle.Pie
);

export const getHyperlinkFilters = createSelector(
  [getUserEmployer],
  (employer) => (employer ? employer.hyperlinkFilters : undefined)
);
