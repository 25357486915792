import { FieldPolicy } from '@apollo/client';
import { User } from '@sentry/browser';
import { notEmpty } from 'common/utils/typeGuards';
import { typedReadField } from '../cacheUtils/helpers';
import {
  QueryReadCompanyHelpTextsByModelForAdminArgs,
  Query,
  Company,
} from '../graphql-hooks';
import { matchesField } from './readCompanyHelpText';

export const readCompanyHelpTextsByModelForAdminPolicy: FieldPolicy = {
  read: (_helpTexts, context) => {
    const {
      model,
    } = context.args as QueryReadCompanyHelpTextsByModelForAdminArgs;
    const me = typedReadField<Query, 'me'>(context, 'me');
    if (!me) return undefined;
    const employer = typedReadField<User, 'employer'>(context, 'employer', me);
    if (!employer) return undefined;

    return typedReadField<Company, 'helpTexts'>(
      context,
      'helpTexts',
      employer
    ).filter(
      (help) =>
        matchesField('modelUuid', context, model)(help) && notEmpty(help)
    );
  },
};
