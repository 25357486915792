import { components } from 'react-select';
import { FilterIcon } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { OptionProps } from 'react-select/src/components/Option';
import { SingleValueProps } from 'react-select/src/components/SingleValue';

export const filterIcon: IconProp = ['fal', 'filter'];

export const iconFromString = (isDescending, _name) => (
  <span className="fa-layers fa-fw">
    <FontAwesomeIcon
      icon={[isDescending ? 'fal' : 'fas', 'sort-up']}
      fixedWidth={true}
    />
    <FontAwesomeIcon
      icon={[isDescending ? 'fas' : 'fal', 'sort-down']}
      fixedWidth={true}
    />
  </span>
);

export const SortOption = (
  props: OptionProps<{ label: string; value: string }, false>
) => {
  const { label, value } = props.data;
  const parts = value.split('_');
  const isDescending = parts[0] === 'D';
  const tooltip = `${label} - ${isDescending ? 'Descending' : 'Ascending'}`;
  return (
    <components.Option {...props}>
      <span title={tooltip}>
        {iconFromString(isDescending, parts[1])}
        &nbsp;&nbsp;
        {label}
      </span>
    </components.Option>
  );
};

export const SortSingleValue = (
  props: SingleValueProps<{ label: string; value: string }>
) => {
  const { children, data } = props;
  const parts = data.value.split('_');
  const isDescending = parts[0] === 'D';
  const tooltip = `${data.label} - ${
    isDescending ? 'Descending' : 'Ascending'
  }`;
  return (
    <div title={tooltip} data-test={tooltip}>
      {iconFromString(isDescending, parts[1])}
      &nbsp;&nbsp;
      {children}
    </div>
  );
};

export const FilterSingleValue = (
  props: SingleValueProps<{ label: string; value: string }>
) => {
  const { children } = props;
  return (
    <div>
      <FilterIcon fontSize={'.8em'} icon={filterIcon} fixedWidth />
      &nbsp;&nbsp;
      {children}
    </div>
  );
};

export const mergeSortOrder = (argExisting, newOrder) => {
  const parts = newOrder.split('_');
  const base = parts.length > 1 ? parts[1] : '';
  return [
    ...[newOrder],
    ...[...argExisting].filter((filter) => {
      const filterParts = filter.split('_');
      return (filterParts.length > 1 ? filterParts[1] : '') !== base;
    }),
  ];
};

export const compareMultiple = (
  o1: object,
  o2: object,
  orders: Array<(o1: object, o2: object) => number>
) => {
  let result = 0;
  orders.some((func) => {
    result = func(o1, o2);
    return !!result;
  });
  return result;
};

export const FilterConstants = {
  Keyword: '-FILTERKEYWORD-',
  Tags: '-TAGS-',
};
