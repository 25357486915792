import { useApolloClient } from '@apollo/client';
import { Callout } from '@blueprintjs/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, StaticContext } from 'react-router';

import { useUser } from 'common/utils/userContext';
import { AuthPage } from './shared';

export const LogoutPage = (
  props: RouteComponentProps<{}, StaticContext, { dontNotify: boolean }>
) => {
  const { logout } = useUser();
  const client = useApolloClient();
  const dispatch = useDispatch();

  logout(client).finally(() => {
    // the IdleMiddleware is listening and responding to these keys.
    // This is to ensure any other tabs are also logged out at this point
    if (!props.location.state || !props.location.state.dontNotify) {
      localStorage.setItem('loginStatus', 'logout');
    }
    dispatch({ type: 'COGNITO_LOGOUT' });
    props.history.replace('/authentication');
  });
  return (
    <AuthPage>
      <Callout>Logging you out. You will be redirected shortly.</Callout>
    </AuthPage>
  );
};
