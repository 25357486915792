// Socket IO support.  Code to handle receiving messages from Socket IO.
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { v4 as uuid } from 'uuid';

import { intersectObjectValues } from 'common/utils/AxioUtilities';
import { IUser } from 'common/databaseTypes';
import { USER_DOMAIN } from 'common/actions/user';
import {
  networkErrorCodes,
  SOCKETIO_ACTION_TAG,
  socketIOMessage,
  updateItemMessage,
} from 'common/actions/persistence';
import Logger from 'common/utils/Logger';
import { DASHBOARD_URL, ID_FIELD } from '../../common/constants';
import { IRootState } from '../../reducers';
import { ASSESSMENT_ID_FIELD, PRACTICE_ID_FIELD } from '../constants';
import { IAssessment, IMilestone } from '../databaseTypes';
import {
  ASSESSMENT_DOMAIN,
  MILESTONE_DOMAIN,
  PRACTICE_DOMAIN,
} from '../reducers/pendingUpdates';
import { getSelectedAssessmentId, getResponses } from '../selectors';

export const SOCKET_IO_NAMESPACE = '/c2m2';
export const FLUSH_NETWORK_SAVE_ACTION = 'persistence/flush_network_saves';
export const CANCEL_NETWORK_SAVE_ACTION = 'persistence/cancel_network_saves';
export const RESPONSE_WAITTIME = 20000; // 20 seconds.
export const AUTH_TOO_OLD_CUTOFF_SECONDS = 58 * 60 * 1000; // 58 minutes in millis
export const MAX_RETRIES = 5;

interface IEveError {
  code: string | number;
  status?: number;
  statusText?: string;
  url?: string;
  message?: string;
}

type PlainOrThunkAction =
  | ThunkAction<any, IRootState, {}, AnyAction>
  | AnyAction;
type Dispatch = (action: PlainOrThunkAction) => void;

/* prettier-ignore */
const errorHandlerMap = {
  [`error/patch_resource/responses:${networkErrorCodes.PRECONDITION_FAILURE}`]: handleConflict,
  [`error/multi_patch_resource/responses:*`]:                                   handleMultiPatchError,
  [`error/post_resource/responses:${networkErrorCodes.CONFLICT}`]:              handleConflict,
  [`error/patch_resource/responses:${networkErrorCodes.UNAUTHORIZED}`]:         handleConflict,
  [`error/post_resource/responses:${networkErrorCodes.UNAUTHORIZED}`]:          handleConflict,
  [`error/patch_resource/*:${networkErrorCodes.UNAUTHORIZED}`]:                 handleSingleConflict,
  [`error/patch_resource/*:${networkErrorCodes.PRECONDITION_FAILURE}`]:         handleSingleConflict,
  [`error/delete_resource/*:${networkErrorCodes.PRECONDITION_FAILURE}`]:        handleSingleConflict,
  [`error/subscribe:${networkErrorCodes.INTERNAL_SERVER_ERROR}`]:               backToDashboard,
  [`*:${networkErrorCodes.UNAUTHORIZED}`]:                                      genericUnauthorized
};

const unqueueableMap = {
  unsubscribe: true,
};

interface RequestTracker {
  timeoutId: number | null;
  resource?: string;
  request_id?: string;
}

const currentRequests = [] as RequestTracker[];

/**
 * Redux dispatch callbacks have the form (action: AnyAction) => void
 *
 * @callback dispatch
 * @param {Object} action
 */

/*
 * Handler function for redux-socketio
 * We intercept the server responses to the dispatched network actions and in turn dispatch them.
 * See https://github.com/itaylor/redux-socket.io for more info.  We've got both the "next" dispatcher
 * in the chain and a root dispatcher.  If we take the network reponse and pass it to the "next" dispatcher,
 * it looks to the reducers that the response was what was dispatched.  If we don't call next but instead
 * call dispatch, the reducers never see the request action, they only see the response action but both
 * actions appear in the log.  If we call next with the original action and dispatch with the response,
 * the reducers see both (and so do the rest of the middlewares.)
 *
 * See: http://redux.js.org/docs/advanced/Middleware.html for more info on dispatcher chains.
 *
 * We call next and then dispatch so that the reducers see both.
 *
 * @param {string} method     - The method to call on the server.
 * @param {Object} action     - The standard redux action object.
 * @param {Object} response   - The server response to the message that was dispatched.
 * @param {dispatch} next     - The next dispatcher in the chain
 * @param {dispatch} dispatch - The root dispatcher for the store.
 */
export function socketServerResponse(
  method: string,
  action: AnyAction,
  response: any,
  next: Dispatch,
  dispatch: Dispatch,
  timeoutId?: number | null
) {
  if (response) {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    if (action.request && action.request.request_id) {
      if (action.request.resource) {
        method += `/${action.request.resource}`;
      }
      for (let i = currentRequests.length - 1; i >= 0; --i) {
        const timeout = currentRequests[i];
        if (
          timeout.request_id === action.request.request_id &&
          timeout.timeoutId
        ) {
          window.clearTimeout(timeout.timeoutId);
          currentRequests.splice(i, 1);
        }
      }
    }
    const responseAction = isError(response.result)
      ? errorReponseAction(method, action, response, next, dispatch)
      : successResponseAction(method, action, response, next, dispatch);

    // The actions can return boolean true to indicate that they did
    // all the dispatching that needs to be done.
    if (responseAction !== true) {
      dispatch(responseAction);
    }

    // If this was the reponse to the last of a series of requests, we'll
    // raise the network end.
    if (action.request && action.request.lastRequest) {
      // Tell everyone we're starting an update.
      dispatch({
        type: 'NETWORK_SAVE_END',
      });
    }
  }

  return;
}

function isError(json: any) {
  return json && json._status === 'ERR';
}

function isRequeueableError(code: string) {
  const codeNumber = parseInt(code, 10);
  return (
    codeNumber === networkErrorCodes.CONFLICT ||
    codeNumber === networkErrorCodes.PRECONDITION_FAILURE ||
    codeNumber === networkErrorCodes.UNAUTHORIZED
  );
}

function errorReponseAction(
  method: string,
  action: AnyAction,
  response: any,
  next: Dispatch,
  dispatch: Dispatch
) {
  const err = normalizeError(response.result, {
    status: 500,
    statusText: 'Socket.IO Error',
    url: `${SOCKET_IO_NAMESPACE}/${PRACTICE_DOMAIN}/${method}`,
  });

  const wildCardError = `error/${method.split('/').slice(0, -1).join('/')}`;
  const errorMethod = `error/${method}`;
  const handler =
    errorHandlerMap[`${errorMethod}:${err.code}`] ||
    errorHandlerMap[`${wildCardError}/*:${err.code}`] ||
    errorHandlerMap[`${errorMethod}:*`] ||
    errorHandlerMap[`*:${err.code}`] ||
    unhandledError;

  const errorAction =
    handler(errorMethod, action, response, err, next, dispatch) ||
    defaultErrorHandler(errorMethod, action, response, err, next, dispatch);

  if (action.afterErrorMessage) {
    // Go ahead and let the initial action propagate, then return the afterSuccessMessage
    if (errorAction && errorAction !== true) {
      dispatch(errorAction);
    }
    return action.afterErrorMessage;
  }
  return errorAction;
}

function successResponseAction(
  method: string,
  action: AnyAction,
  response: any,
  next: Dispatch,
  dispatch: Dispatch
) {
  const msg = {
    type: method,
    request: action.request,
    response,
  };

  if (action.afterSuccessMessage) {
    // Go ahead and let the initial action propagate, then return the afterSuccessMessage
    dispatch(msg);
    return action.afterSuccessMessage;
  }

  return msg;
}

function normalizeError(json: any, response: any) {
  // Unfortunately, the shape of the error json is different depending on the request.
  // We'll try to normalize parts of it.
  const err = {
    code: response.status,
    message: response.statusText,
    statusText: response.statusText,
    url: response.url,
  };

  if (json._error && json._error.code) {
    err.code = json._error.code;
    err.message = json._error.message;
  } else if (json._issues) {
    const keys = Object.keys(json._issues);
    err.message = '';
    let comma = '';
    for (const k of keys) {
      err.message += comma + json._issues[k];
      comma = ', ';
    }
  }

  return err;
}

/* Updates a milestone item with the backend.
 *
 * milestone (Object): The milestone being updated
 * argItemId (?string): An optional string containing the item id if you
 *                      haven't provided it in the item itself.  When this is called
 *                      by the patch conflict, we no longer have the id on the object
 *                      and have to pass it in.
 * argEtag (?string): An optional string containing the item's etag if you
 *                    haven't provided it in the item itself.  When this is called
 *                     by the patch conflict, we no longer have the id on the object
 *                     and have to pass it in.
 */
export function updateMilestoneMessage(
  milestone: Partial<IMilestone>,
  argItemId: string | null = null,
  argEtag: string | null = null,
  argAssessmentId: string | null = null,
  afterAction: any = null
) {
  return updateItemMessage(
    MILESTONE_DOMAIN,
    milestone,
    argItemId,
    argEtag,
    { [ASSESSMENT_ID_FIELD]: argAssessmentId },
    afterAction,
    true
  );
}

/* Update the active assessessment for a user */
// This doesn't impact application behavior anymore but it's nice
// to know for audit purposes which assessment was mucked with last.
export function setActiveAssessmentMessage(
  user: IUser,
  afterSuccessMessage: any = null,
  retry = false,
  afterErrorMessage: any = null
) {
  const data = { active_assessment: user.active_assessment, new: false };
  const item_id = user[ID_FIELD];
  const etag = user._etag;

  return (dispatch: any, getState: any) => {
    dispatch(
      socketIOMessage(
        getState(),
        'patch_resource',
        { resource: USER_DOMAIN, item_id, etag, data },
        afterSuccessMessage,
        retry,
        afterErrorMessage
      )
    );
  };
}

/* Updates an Assessment item with the backend.
 *
 * assessment (Object): The user being updated
 * argItemId (?string): An optional string containing the item id if you
 *                      haven't provided it in the item itself.  When this is called
 *                      by the patch conflict, we no longer have the id on the object
 *                      and have to pass it in.
 * argEtag (?string): An optional string containing the item's etag if you
 *                    haven't provided it in the item itself.  When this is called
 *                     by the patch conflict, we no longer have the id on the object
 *                     and have to pass it in.
 * argExtraArgs (?Object): An optional object containing any extra props
 *                         you wish to pass
 * afterAction (?Object): An optional action to run on success
 */

export function updateAssessmentMessage(
  assessment: Partial<IAssessment> & { update_target_profile?: boolean },
  argItemId: string | null = null,
  argEtag: string | null = null,
  argExtraArgs: object | null = null,
  afterAction: any = null
) {
  return updateItemMessage(
    ASSESSMENT_DOMAIN,
    { ...assessment, new: false },
    argItemId,
    argEtag,
    argExtraArgs,
    afterAction,
    true
  );
}

/*
 * In the case that max retries has been reached, we need to raise a retry error for
 * the action that could no longer be retried.
 */
function raiseMaxRetriesError(
  method: string,
  action: AnyAction,
  next: Dispatch,
  dispatch: Dispatch
) {
  const err = {
    code: 504,
    message: 'Request timed out',
  };
  return dispatch(
    defaultErrorHandler(`error/${method}`, action, {}, err, next, dispatch)
  );
}

/*
 * Sends actions dispatched from redux on to the server.  Our server responds directly to
 * requests v. generating another emit that is supposed to be picked up.
 *
 * @param {Object} action - The redux action object.
 * @param {function} emit - The emit function off of socket.io
 * @param {function} next - The next dispatch in the chain
 * @param {function} dispatch - The dispatch object for the store - for explicit dispatching
 */
export function socketFunction(
  socket: SocketIOClient.Socket,
  action: AnyAction,
  emit: (method: string, ...args: any[]) => void,
  next: Dispatch,
  dispatch: Dispatch,
  retries = 0
) {
  // The action we want to send to the server is the string following the prefix.
  if (!(action.type.indexOf(SOCKETIO_ACTION_TAG) === 0)) {
    throw new TypeError(
      `socketFunction received an action type it doesn't understand: '${action.type}'`
    );
  }
  if (!action.request.request_id) {
    action.request['request_id'] = uuid();
  }
  next(action);

  const method = action.type.substr(SOCKETIO_ACTION_TAG.length);

  // Bit of a bodge here.  We're not going to try to dispatch messages that are market as "unqueueable"
  // if the socket is currently not connected
  if (
    (!socket.connected || !window.navigator.onLine) &&
    unqueueableMap[method]
  ) {
    Logger.warn(`Ignoring method: '${method}' while disconnected`);
    if (action.afterSuccessMessage) {
      // We're done so dispatch the afterSuccess.
      // One thing to note, we may have gotten a standard function
      // v. a thing that is actually an action.  We want to support
      // direct calling a standard function instead of dispatching it
      // so, if we have a function as our afterSuccessMessage and it's
      // length is 0 (meaning it doesn't need arguments), we know it
      // isn't a thunk and we can just call it.
      if (
        typeof action.afterSuccessMessage === 'function' &&
        action.afterSuccessMessage.length === 0
      ) {
        action.afterSuccessMessage();
      } else {
        dispatch(action.afterSuccessMessage);
      }
    }
    return;
  }

  // There are two different network timeouts, one for requests that we can retry
  // without having to do anything special - like merge.  Another for things at the action
  // level that require special processing of the save.  It may be possible to get rid
  // of this retry code in favor of the action level code.  We can't get rid of the
  // action level retry because it bundles one timeout for potentially a large number of
  // socket calls, only setting the timeout on the last one and retrying the entire
  // bundle if necessary.
  let timerId: number | null = null;

  if (
    action.retry &&
    (socket.connected || retries === 0) &&
    window.navigator.onLine
  ) {
    if (retries >= MAX_RETRIES) {
      raiseMaxRetriesError(method, action, next, dispatch);
      for (let i = currentRequests.length - 1; i >= 0; --i) {
        const timeout = currentRequests[i];
        if (
          timeout.request_id === action.request.request_id &&
          timeout.timeoutId
        ) {
          window.clearTimeout(timeout.timeoutId);
          currentRequests.splice(i, 1);
        }
      }
      return;
    }

    // If action.retry is a boolean, we'll use the generic retry.  Otherwise,
    // We'll assume it's a function that handles the retry all on it's own.
    if (action.retry === true) {
      timerId = genericRetryTimer(
        socket,
        action,
        emit,
        next,
        dispatch,
        retries
      );
    } else {
      timerId = action.retry(socket, action, emit, next, dispatch, retries);
    }
    // Having the resource as part of this object is not necesssary,
    // but it is helpful for debugging
    currentRequests.push({
      timeoutId: timerId,
      request_id: action?.request?.request_id,
      resource: action?.request?.resource,
    });
  }
  emit(method, action.request, (msg) => {
    socketServerResponse(method, action, msg, next, dispatch, timerId);
  });
} // socketFunction

function genericRetryTimer(
  socket: any,
  action: AnyAction,
  emit: (method: string, ...args: any[]) => void,
  next: Dispatch,
  dispatch: Dispatch,
  retries: number
) {
  return window.setTimeout(() => {
    // Don't try putting socketio functions here.  Having them causes
    // re-entrancy problems with socketIOMessage.
    dispatch({ type: 'NETWORK_GENERIC_RETRY', action, retries });
    socketFunction(socket, action, emit, next, dispatch, retries + 1);
  }, RESPONSE_WAITTIME);
}

// Error Handlers are functions that return an action to be dispatched
// for a given message/code combination.  If they return a non-truthy value
// then a default error action is dispatched.

function isRequeueShapeOk(
  method: string,
  action: AnyAction,
  checkForId = true
) {
  // Make sure that the shape of the item matches what we're expecting.
  if (
    !action ||
    !action.request ||
    !action.request.data ||
    (checkForId && !action.request[ID_FIELD])
  ) {
    Logger.error(
      `Unexpected action shape in ${method}.  Can't requeue the patch. action=${action}`
    );
    return false;
  }
  return true;
}

function accessDeniedReason(err: IEveError, response: any) {
  const code = parseInt(err.code.toString(), 10);
  const responseCode =
    response && response.result && parseInt(response.result.code, 10);

  return code === networkErrorCodes.UNAUTHORIZED ||
    responseCode === networkErrorCodes.UNAUTHORIZED ||
    code === networkErrorCodes.FORBIDDEN ||
    responseCode === networkErrorCodes.FORBIDDEN
    ? code
    : 0;
}

function possibleReque(
  requeueAction: PlainOrThunkAction,
  err: IEveError,
  response: any
) {
  // accessDeniedReason returns 401 or 403 if access is denied and
  // 0 otherwise
  const code = accessDeniedReason(err, response);
  if (code) {
    // If the fail was because we're 401 - UNAUTHORIZED, it means our
    // token was bad and we can try again with a new token.  If the fail was
    // 403 - FORBIDDEN, it means we tried to access a page we shouldn't, so
    // we'll just redirect to the dashboard.
    return code === networkErrorCodes.UNAUTHORIZED
      ? requeueAction
      : push(DASHBOARD_URL);
  }

  // return the action to requeue
  return requeueAction;
}

function handleSingleConflict(
  method: string,
  action: AnyAction,
  response: any,
  err: IEveError,
  next: Dispatch,
  dispatch: Dispatch
) {
  // Go ahead and let the error propigate, then send the requeue.
  dispatch(defaultErrorHandler(method, action, response, err, next, dispatch));
  // Format of method is literal "error"(0), slash, method causing error(1), slash, the resource(2)
  const newMethod = method.split('/')[1]; // error/the_method_causing_the_error/the_resource

  // Our new action is basically the old action with the new etag.
  const requeueAction = (
    requeueDispatch: Dispatch,
    getState: () => IRootState
  ) => {
    const request = {
      ...action.request,
      etag: response.result._etag || action.request.etag,
    };
    requeueDispatch(
      socketIOMessage(
        getState(),
        newMethod,
        request,
        action.afterSuccessMessage
      )
    );
  };
  return possibleReque(requeueAction, err, response);
}

function handleMultiPatchError(
  method: string,
  action: AnyAction,
  response: any,
  err: IEveError,
  next: Dispatch,
  dispatch: Dispatch
) {
  // With a multi-patch, we might have multiple different errors.
  // Some might be patch conflicts that we can resolve.  Some might be
  // successes an we don't have to do anything and some might be
  // errors that we can't really do anthing other than warn about.

  // Build an action with a response that has items of a matching type
  // We need to add the practice ID back into the item because on an error,
  // Eve doesn't put it back for us.
  const itemsByCode = {};
  const dataByCode = {};

  for (const item of response.result._items) {
    const practiceId = item.item_id;
    const key = String(item.code);
    if (!(key in itemsByCode)) {
      itemsByCode[key] = [];
    }
    if (!(key in dataByCode)) {
      dataByCode[key] = {};
    }
    itemsByCode[key].push({ [PRACTICE_ID_FIELD]: practiceId, ...item });
    dataByCode[key][practiceId] = action.request.data[practiceId];
  }

  // This loop is setup to leave the last action undispatched.  That's
  // because the expectation is that our caller dispatches the last action
  let toDispatch: any | null = null;

  for (const code in itemsByCode) {
    if (code in itemsByCode) {
      // The first item will have the necessary header info for the entire array.
      const newResponse = { ...response };
      const items = itemsByCode[code];
      newResponse.result = {
        _status: items[0]._status,
        message: (items[0]._error || {}).message || err.message,
        code,
        _items: items,
      };

      // Set the action to look like it just covered the items we're
      // Currently dealing with.
      const newAction: any = { ...action, response: newResponse };
      newAction.request.data = dataByCode[code];

      // If this group isn't an error, dispatch the success.
      if (!isError(newResponse.result)) {
        delete newResponse._error;
        // We've got to switch the message from "error/..." to just ...
        toDispatch = successResponseAction(
          method.substr(6),
          newAction,
          newResponse,
          next,
          dispatch
        );
      } else {
        // OK, this is either an error we can requeue or it isn't.  Start by dispatching the error -
        // BUT we need the data of the action filtered to just the items that are a part of this error
        const newError = {
          ...err,
          code,
          message: newResponse.result.message,
        };

        const dispatchAction = isRequeueableError(code)
          ? handleConflict
          : defaultErrorHandler;
        toDispatch = dispatchAction(
          method,
          newAction,
          newResponse,
          newError,
          next,
          dispatch
        );
      }

      if (toDispatch) {
        dispatch(toDispatch);
      }
    }
  }

  return true; // Tell the world that we did all the dispatching
}

function handleConflict(
  method: string,
  action: AnyAction,
  response: any,
  err: IEveError,
  next: Dispatch,
  dispatch: Dispatch
) {
  const requestHasId = method === 'error/patch_resource/responses';
  if (!isRequeueShapeOk('handleConflict', action, requestHasId)) {
    return;
  }

  // Go ahead and let the error propigate, then send the requeue.
  dispatch(defaultErrorHandler(method, action, response, err, next, dispatch));

  const requeueAction = !requestHasId
    ? requeueMultiUpdate(action)
    : requeueSingleUpdate(action);

  return possibleReque(requeueAction, err, response);
}

function backToDashboard(
  method: string,
  action: AnyAction,
  response: any,
  err: IEveError,
  next: Dispatch,
  dispatch: Dispatch
) {
  // Go ahead and let the error propigate, then send the requeue.
  dispatch(defaultErrorHandler(method, action, response, err, next, dispatch));
  return push(DASHBOARD_URL);
}

function genericUnauthorized(
  method: string,
  action: AnyAction,
  response: any,
  err: IEveError,
  next: Dispatch,
  dispatch: Dispatch
) {
  // Go ahead and let the error propigate, then send the requeue.
  dispatch(defaultErrorHandler(method, action, response, err, next, dispatch));
  return possibleReque(action, err, response);
}

function unhandledError(
  method: string,
  action: AnyAction,
  response: any,
  err: IEveError,
  next: Dispatch,
  dispatch: Dispatch
) {
  return false;
}

function defaultErrorHandler(
  method: string,
  action: AnyAction,
  response: any | object,
  err: IEveError,
  next: Dispatch,
  dispatch: Dispatch
) {
  return {
    type: method,
    request: action.request,
    response,
    error: err,
  };
}

function reconcileUpdate(responses, id, data) {
  let existingData = responses.get(id);

  if (!existingData) {
    // This is evidence of something weird.  We got a rejected update for a
    // practice we don't have any data on.  It shouldn't be possible.
    Logger.warn(`No existingData for '${id}'`);
    existingData = {}; // Set to an empty object to let the rest of the code work
  }

  delete data._etag;
  delete data[ID_FIELD];
  delete data[PRACTICE_ID_FIELD];

  return intersectObjectValues(data, existingData);
}

/*
 * When an update bounces due to a bad etag, if the server hasn't sent us a conflicting
 * change, we'll requeue it and send it again.
 *
 */
export const _requeueUpdateMessage = (
  assessmentId: string,
  update: object,
  afterSuccessMessage?: any
) => {
  return {
    type: 'REQUEUE_UPDATE',
    [ASSESSMENT_ID_FIELD]: assessmentId,
    update,
    afterSuccessMessage,
  };
};

const requeueSingleUpdate = (action: AnyAction) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  // Check to see if the values in our update still match the values in the responses store.
  // If they don't, it means that the user or server has since updated them and our
  // update shouldn't be requeued.
  const state = getState();
  const assessmentId = getSelectedAssessmentId(state);
  if (!assessmentId) {
    Logger.error('Call to requeueSingleUpdate with no assessment ID');
    return;
  }

  const responses = getResponses(state);
  const { item_id, data } = action.request;
  const newPatch = reconcileUpdate(responses, item_id, data);

  if (Object.keys(newPatch).length) {
    dispatch(
      _requeueUpdateMessage(
        assessmentId,
        {
          [item_id]: newPatch,
        },
        action.afterSuccessMessage
      )
    );
  }
};

const requeueMultiUpdate = (action: AnyAction) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  const state = getState();
  const assessmentId = getSelectedAssessmentId(state);
  if (!assessmentId) {
    Logger.error('Call to requeueMultiUpdate with no assessment');
    return;
  }

  const responses = getResponses(state);
  const data: any = action.request.data;

  // data will either be an array with the practice id
  // as an element of that array or an Object, keyed by
  // practice_id.
  const newData = {};

  const iteratingItems = Array.isArray(data);
  const iterator = iteratingItems ? data : Object.keys(data);

  for (const val of iterator) {
    const item = iteratingItems ? val : data[val];
    const id = iteratingItems ? item[PRACTICE_ID_FIELD] : val;
    const newItem = reconcileUpdate(responses, id, item);
    if (Object.keys(newItem).length) {
      newData[id] = newItem;
    }
  }

  if (Object.keys(newData).length) {
    dispatch(
      _requeueUpdateMessage(assessmentId, newData, action.afterSuccessMessage)
    );
  }
};
