import React from 'react';
import Textarea from 'react-textarea-autosize';
import styled, { css } from 'styled-components/macro';

export const DialogDescription = styled.p`
  font-size: 0.9em;
  margin: 0 0 1rem;
  font-weight: normal;
`;

export const DefaultInputCSS = css`
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  height: 1.25rem;
  box-sizing: initial;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: inherit;
  font-size: ${(props) => props.theme.textSizes.inputTwo}px;
  outline: none;
  :focus {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 10px 0 rgba(73, 107, 125, 0.3);
  }
  ::placeholder {
    font-weight: normal;
  }
`;

export const DefaultInput = styled.input`
  display: flex;
  width: calc(100% - 26px);
  ${DefaultInputCSS}

  &[disabled] {
    background-color: ${(props) => props.theme.colors.disabledGrayBackground};
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed;
  }
`;

export const DefaultTextArea = styled(Textarea)`
  display: flex;
  resize: none;
  width: calc(100% - 26px);
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: inherit;
  font-size: 0.7em;
  :focus {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 10px 0 rgba(73, 107, 125, 0.3);
  }
  ::placeholder {
    font-weight: normal;
  }
`;

export const DefaultLabel = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 1rem;
  font-size: 0.8em;
  line-height: 20px;
  ${DefaultInput}, ${DefaultTextArea} {
    font-size: ${(props) => props.theme.textSizes.inputTwo}px;
  }
  & input.bp3-input {
    font-size: 0.9em;
  }
`;

export const LargeLabel = styled(DefaultLabel)`
  font-size: 1.2em;
  line-height: 26px;
`;

export const DialogHelpText = styled.p`
  font-style: italic;
  color: grey;
  margin: 2px;
  font-size: ${(props) => props.theme.textSizes.paragraph}px;
`;

export const QuantDialogHelpText = styled(DialogHelpText)`
  font-size: 1em;
`;

export const FlashMessage = styled<any>(({ rgb, ...rest }) => (
  <div {...rest} />
)).attrs((props) => ({
  rgb: {
    error: '250, 117, 90',
    success: '47, 204, 115',
    info: '105, 105, 105',
  }[props.type || 'info'],
}))`
  background-color: ${(props) => `rgba(${props.rgb}, 0.5)`};
  border: 1px solid ${(props) => `rgb(${props.rgb})`};
  border-radius: 0.25rem;
  margin: 0.5rem 0;
  padding: 1rem;
  color: white;
  white-space: pre-line;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
`;

export const SelectRow = styled.div`
  display: flex;
  height: 36px;
  min-height: 36px;
`;

export const EditorContainer = styled.div<{
  disabled?: boolean;
  hasBlueprintStyles?: boolean;
}>`
  display: flex;
  flex-direction: column;
  overflow: auto;

  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border: ${(props) =>
    props.disabled && props.hasBlueprintStyles
      ? '1px solid transparent'
      : '1px solid #ccc'};
  border-radius: 4px;
  background-color: ${(props) =>
    props.disabled && props.hasBlueprintStyles
      ? props.theme.colors.disabledGrayBackground
      : props.disabled
      ? props.theme.colors.lightGray
      : 'white'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  font-size: ${(props) => props.theme.textSizes.inputTwo}px;
  font-weight: normal;
  color: ${(props) =>
    props.disabled && props.hasBlueprintStyles
      ? props.theme.colors.disabledGrayTextColor
      : 'inherit'};

  :global(.public-DraftEditor-content) {
    min-height: 8rem;
  }

  .public-DraftEditorPlaceholder-hasFocus {
    color: #737373;
  }

  .public-DraftEditorPlaceholder-root {
    color: ${(props) =>
      props.disabled && props.hasBlueprintStyles
        ? props.theme.colors.disabledGrayTextColor
        : '#737373'};
  }

  :focus-within {
    border: ${(props) =>
      !props.disabled &&
      props.hasBlueprintStyles &&
      `1px solid rgb(36, 89, 189)`};
    box-shadow: ${(props) =>
      props.disabled
        ? 'unset'
        : props.hasBlueprintStyles
        ? `0 0 0 2px rgba(189, 205, 229, 1)`
        : `inset 0 0 0 rgba(0, 0, 0, 0), 0 0 10px 0 rgba(73, 107, 125, 0.3)`};
  }
`;

export const ShowTagTree = styled.div`
  width: fit-content;
  padding: 0px 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: normal;
  font-style: italic;
  font-size: ${(props) => props.theme.textSizes.paragraph}px;
  margin-left: auto;
  height: 19px;
  :hover {
    background-color: #e6e6e6;
  }
`;

export const UploadMessage = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-radius: 5px;
  border: 2px dashed grey;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonThatLooksLikeALink = styled.button`
  display: inherit;
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  color: ${(props) => (props.disabled ? `grey` : `rgb(0, 0, 238)`)};
  cursor: ${(props) => (props.disabled ? `not-allowed` : `pointer`)};
  display: inherit;
  font: inherit;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
  @supports (-moz-appearance: none) {
    /* Mozilla-only */
    button::-moz-focus-inner {
      /* reset any predefined properties */
      border: none;
      padding: 0;
    }
    button:focus {
      /* add outline to focus pseudo-class */
      outline-style: dotted;
      outline-width: 1px;
    }
  }
`;
