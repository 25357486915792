import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { getUser } from 'common/selectors';
import { IResponse, ModelScope } from '../databaseTypes';

import {
  getActiveFilterCriteria,
  getAllAssessments,
  getAllResponses,
  getAllTags,
  getComparisonAssessmentId,
  getSelectedAssessmentId,
  getFilteredSortedAssessments,
  getCoworkers,
} from '.';
import { COMPLETE } from 'assessments/constants';

export const getSelectedAssessment = createSelector(
  [getAllAssessments, getSelectedAssessmentId],
  (assessments, assessmentId) => assessments.get(assessmentId, null)
);

export const getComparisonAssessment = createSelector(
  [getAllAssessments, getComparisonAssessmentId, getAllResponses],
  (assessments, assessmentId, allResponses) => {
    const assessment = assessments.get(assessmentId);
    if (!assessment) {
      return;
    }
    return {
      ...assessment,
      responses: allResponses
        .get(assessmentId, Map<IResponse>({}))
        .valueSeq()
        .toArray(),
    };
  }
);

export const getAssessmentCompanyName = createSelector(
  [getSelectedAssessment, getUser],
  (selectedAssessment, user) => {
    if (
      selectedAssessment &&
      selectedAssessment.company &&
      selectedAssessment.company._id !== user.getIn(['employer', '_id'])
    ) {
      return selectedAssessment.company.name;
    }
    return '';
  }
);

export const getScope = createSelector([getSelectedAssessment], (assessment) =>
  assessment ? assessment.name : null
);

export const getScopeTags = createSelector(
  [getSelectedAssessment],
  (assessment) => (assessment ? assessment.scopetags : null)
);

export const getModelUuid = createSelector(
  [getSelectedAssessment],
  (assessment) => (assessment ? assessment.model : null)
);

export const getModelScope = createSelector(
  [getSelectedAssessment],
  (assessment) => (!!assessment && assessment.modelScope) || ModelScope.Full
);

export const getOwnerInfo = createSelector(
  [getSelectedAssessment, getCoworkers],
  (assessment, coworkers) => {
    const unknownUser = {
      _id: '',
      id: '',
      name: 'Unknown User',
      email: 'unknown',
      employer: null,
      isDeleted: false,
    };
    if (assessment && assessment.owner) {
      const owner = coworkers.get(assessment.owner, unknownUser);
      const employer = assessment.company?._id
        ? assessment.company
        : owner.employer;
      return {
        ...owner,
        id: owner._id,
        employer,
      };
    }
    return unknownUser;
  }
);

export const doesUserOwnAssessment = createSelector(
  [getSelectedAssessment, getUser],
  (assessment, user) => !!assessment && assessment.owner === user.get('_id')
);

export const isAssessmentQuicklaunch = createSelector(
  [getModelScope],
  (modelScope) => modelScope === ModelScope.Quicklaunch
);

export const getReactTagsFromAssessment = createSelector(
  [getScopeTags, getAllTags],
  (assessmentScopeTags, allScopeTags) => {
    // name: is for the tag autocomplete pkg, title: is for the tag tree pkg
    return assessmentScopeTags
      ? allScopeTags
          .filter((tag) => assessmentScopeTags.includes(tag._id))
          .sortBy((tag) => tag.name)
          .map((tag) => ({
            id: tag._id,
            name: tag.name,
            title: tag.name,
            subtitle: tag.subtitle,
            ancestorId: tag.ancestor,
            type: tag.type,
            owner: tag.owner,
          }))
          .valueSeq()
          .toArray()
      : [];
  }
);

export const getIsAssessmentReadOnly = createSelector(
  [getSelectedAssessment],
  (assessment) =>
    Boolean(assessment?.read_only || assessment?.assessmentState === COMPLETE)
);

export const getAssessmentState = createSelector(
  [getSelectedAssessment],
  (assessment) => assessment?.assessmentState
);

// This selector is designed to give you back the assessment you
// should navigate to and whether or not the filter has to change
// to all in the event that you're deleting or unsharing a particular
// assessment.
export const getNextAssessmentAfterDelete = createSelector(
  [
    getSelectedAssessment,
    getFilteredSortedAssessments,
    getActiveFilterCriteria,
  ],
  (assessment, assessmentList, activeFilter) => {
    // Remember, we're "about" to delete something.  We haven't deleted it yet.
    // If there is more than one assessment in the current filter, we can pull the first
    // of the remaining assessment IDs.  If the filter is empty, we'll grab the
    // first id in the "ALL" assessments (still sorted by active sort though)
    const selectedAssessmentId = assessment ? assessment._id : '';
    const newActiveAssessment =
      assessmentList.size > 1
        ? assessmentList.find(
            (item) => item && item._id !== selectedAssessmentId
          )
        : null;
    const newActiveAssessmentId = newActiveAssessment
      ? newActiveAssessment._id
      : '';

    const switchFilterToAll = assessmentList.size <= 1 && !!activeFilter; // already at all.
    return {
      newActiveAssessmentId,
      switchFilterToAll,
    };
  }
);
