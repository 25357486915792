import { FieldPolicy } from '@apollo/client';

import { typedReadField } from '../cacheUtils/helpers';
import { Response } from '../graphql-hooks';

export const publishedResponsesPolicy: FieldPolicy<Response[]> = {
  keyArgs: ['args'],
  merge: false,
  read: (existing, context) =>
    existing?.filter((response) =>
      typedReadField<Response, 'isPublishedForDelegation'>(
        context,
        'isPublishedForDelegation',
        response
      )
    ),
};
