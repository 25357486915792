import { Button, Callout, FormGroup } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { getSsoStatus, useUser } from 'common/utils/userContext';
import {
  AuthForm,
  AuthPage,
  BottomLink,
  BottomLinks,
  LoginInput,
} from './shared';
import { ssoError } from 'common/constants';
import { IThemeInterface } from '../../themes';
import { withTheme } from 'styled-components/macro';

export const ForgotPasswordPage = (
  props: RouteComponentProps<{}> & { theme: IThemeInterface }
) => {
  const emailFromSearch = new URLSearchParams(props.location.search).get(
    'email'
  );
  const initialFormValues = {
    email: emailFromSearch || '',
  };

  const { forgotPassword } = useUser();
  const [error, setError] = useState('');
  const onSubmit = async (
    { email }: typeof initialFormValues,
    { setSubmitting }: FormikHelpers<typeof initialFormValues>
  ) => {
    setError('');
    try {
      const hasSso = await getSsoStatus(email);
      //if yes, then they should log in using SSO
      if (hasSso) {
        setSubmitting(false);
        setError(ssoError);
      } else {
        await forgotPassword(email);
        props.history.replace({
          pathname: '/authentication/verifyreset',
          state: {
            email,
          },
        });
      }
    } catch (err: any) {
      setError(err.message);
      setSubmitting(false);
    }
  };

  return (
    <AuthPage
      welcomeText="Forgot Password"
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<typeof initialFormValues>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, isValid, handleSubmit, handleChange }) => (
          <AuthForm onSubmit={handleSubmit}>
            <Callout>
              Enter your registered email and press "Send". A verification code
              will be sent (to that email address) that you can use to reset
              your password.
            </Callout>
            <br />
            <FormGroup>
              <LoginInput
                name="email"
                type="email"
                placeholder="Email address"
                autoComplete="email"
                value={values.email}
                onChange={handleChange}
                data-test="Password Input"
                disabled={isSubmitting}
                required
                large
              />
              {!!error && <Callout intent="danger">{error}</Callout>}
              {!!error && error === ssoError ? (
                <BottomLinks smallMargins>
                  <BottomLink isWhite={true} to={'/authentication/sso'}>
                    Sign in with SSO
                  </BottomLink>
                </BottomLinks>
              ) : (
                ''
              )}
            </FormGroup>

            <Button
              text="Send"
              type="submit"
              disabled={isSubmitting || !isValid}
              fill
              large
            />
            <BottomLinks smallMargins>
              <BottomLink to="/" isWhite>
                Return to login page
              </BottomLink>
            </BottomLinks>
          </AuthForm>
        )}
      </Formik>
    </AuthPage>
  );
};

export const ForgotPasswordForm = withTheme(ForgotPasswordPage);
