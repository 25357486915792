import { rc3AssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { lighten } from 'polished';
import { LegacyModelConfig } from '..';
import terms from 'assessments/utils/Dictionary/terms_rc3';

const lightSelected = '#F8F8F8';
const darkSelected = '#202020';
const lightAmount = 0.22;

const colorTable = {
  '.IDE': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#12529a'),
    background: '#12529a',
  },
  '.PRO': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#622c7b'),
    background: '#622c7b',
  },
  '.DET': {
    selectedForeground: darkSelected,
    foreground: lighten(lightAmount, '#fdd228'),
    background: '#fdd228',
  },
  '.RES': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#de061f'),
    background: '#de061f',
  },
  '.REC': {
    selectedForeground: lightSelected,
    foreground: lighten(lightAmount, '#1c8837'),
    background: '#1c8837',
  },
};

export const rc3Config: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true,
    convertToFull: false,
    referenceAssessments: true,
    milestones: true,
    wizard: false,
    hasMilScoping: false,
    hasImportResponses: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: true,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  factories: [
    {
      label: 'RC3 Assessment', //kb: defult to shortName?
      filterLabel: 'RC3',
      icon: icon,
      sortKey: 'RC3',
      modelUuid: LegacyModelIdEnum.Rc3,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.RC3_FULL,
    },
  ],
  colorTable,
  terms,
  shortTitle: 'RC3 Assessment',
};
