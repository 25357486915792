import { createSelector } from 'reselect';
import { getDefaultIndividualLicenseForUrl, isLicenseValid } from '../license';
import { getUser } from '.';
import { ModelScope } from 'assessments/databaseTypes';
import { LegacyModelLicense } from 'common/databaseTypes';

export const getUserJS = createSelector([getUser], (user) => user.toObject());

export const getUserId = createSelector([getUser], (user) =>
  user.get('_id', '')
);

export const getUserEmail = createSelector([getUser], (user) =>
  user.get('email', '')
);

export const getLastAssessmentType = createSelector([getUser], (user) =>
  user.get('last_assessment_type')
);

export const getUserEmployer = createSelector([getUser], (user) =>
  user.get('employer')
);

export const getLicense = createSelector([getUserEmployer], (employer) =>
  employer?.license ? employer.license : getDefaultIndividualLicenseForUrl()
);

export const getLicenseModelGrants = createSelector([getLicense], (license) => {
  const legacyModelGrants = license.models ?? [];
  const customModels = license.customModels ?? [];

  const customModelGrants = customModels.map<LegacyModelLicense>(
    (modelIdentifier) => ({ key: modelIdentifier, options: [ModelScope.Full] })
  );

  return legacyModelGrants.concat(customModelGrants);
});

export const getLicenseModelGrantsKeys = createSelector(
  [getLicenseModelGrants],
  (grants): string[] => grants.map((g) => g.key)
);

export const hasModelLicense = (modelUuid: string) =>
  createSelector([getLicenseModelGrantsKeys], (keys) =>
    keys.includes(modelUuid)
  );

export const hasAggregateDashboardLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('aggregateDashboard')
);

export const hasAlphaReportsLicense = createSelector([getLicense], (license) =>
  license.features.includes('alphaReportsInFooter')
);

export const hasBoardReportLicense = createSelector([getLicense], (license) =>
  license.features.includes('boardReport')
);

export const hasMilestoneFromExcelLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('milestoneFromExcel')
);

export const hasResponsesToCsvLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('responsesToCsv')
);

export const hasAssessmentOverTimeLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('assessmentOverTime')
);

export const hasEEIBenchmarksLicense = createSelector([getLicense], (license) =>
  license.features.includes('eeiBenchmarks')
);

export const hasDelegateResponsesLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('delegateResponses')
);

export const getHasReportPrepFirmLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('reportPrepFirm')
);

export const getHasMSWordReportsLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('MSWordReports')
);

export const getHasTicketingSystemIntegrationLicense = createSelector(
  [getLicense],
  (license) => license.features.includes('ticketingSystemIntegration')
);

// To support the use-case where the an individual user has been granted access to a
// specific assessment in a themed environment BUT isn't allowed to create any
// assessments in that environment, we're looking for an empty models array and
// no employer.  Ideally, we would STILL claim they had no access to assessments
// if there were no assessments they could access but at this point in the
// authentication chain, we haven't loaded assessments for them yet and so we
// can't do it.  Someday, if this needs fixing, we could manipulate a flag on the
// user object itself indicating that they had access to at least one assessment
// and incorporate that into the selector.  This isn't too hard because GraphQL can
// look and create the flag on the user programmatically.  BUT for right now, I
// wanted to restrict the number of files that needed changing.
export const hasAssessmentAccess = createSelector(
  [getLicense, getUserEmployer],
  (license, employer) =>
    isLicenseValid(license.modules.assessment) ||
    (license.models.length === 0 && !employer)
);

export const hasInsuranceAccess = createSelector([getLicense], (license) =>
  isLicenseValid(license.modules.insurance)
);

export const hasQuantAccess = createSelector([getLicense], (license) =>
  isLicenseValid(license.modules.quantification)
);

export const hasQuickQuantAccess = createSelector([getLicense], (license) =>
  isLicenseValid(license.modules.quickQuant)
);

export const isCompanyAdmin = createSelector([getUser], (user) =>
  Boolean(user.get('roles') && user.get('roles').includes('admin'))
);

export const isAxioUser = createSelector([getUserEmail], (email) =>
  email.endsWith('@axio.com')
);

export const isFreeTierRestrictedUser = createSelector(getUser, (user) =>
  Boolean(user.get('freemiumRestrictions')?.isFreeUser ?? false)
);
