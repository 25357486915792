import { defaultTheme } from '../default';
import logoNoBg from './Alliant_Colored_Logo_33000px.png';
import impAuthBg from './Splash_AND_Welcome_Background.jpg';

export const IMP_SHERPA_BLUE = '#002E42';
export const IMP_CHARTREUSE = '#BBD531';
export const ALLIANT_PRIMARY_COLOR = '#4BC8F1';
export const ALLIANT_SECONDARY_COLOR = '#002E42';

export const alliantTheme = {
  ...defaultTheme,
  authLogo: logoNoBg,
  backgroundAnchor: 'bottom left',
  // auth screen bg image
  backgroundImage: impAuthBg,
  clientLogo: logoNoBg,
  clientName: 'Alliant',
  clientNameLong: 'Alliant Insurance Services, Inc.',
  homepage: 'https://alliant.com',
  poweredBy: true,
  colors: {
    ...defaultTheme.colors,
    authBackground: '#FFFFFF', // white
    emphasisColor: IMP_CHARTREUSE,
    minimalButtonTextColor: IMP_SHERPA_BLUE,
    primaryButton: IMP_SHERPA_BLUE,
  },
};
