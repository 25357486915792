import { Map as ImmutableMap } from 'immutable';
import { ModelScope } from 'assessments/databaseTypes';
import {
  generateDimensionSchema,
  IModelState,
  init,
  ITermsMap,
  makeOrderedDomains,
  makeOrderedDomainsAndObjectives,
} from './model';

import { DashboardType } from 'assessments/constants';
import {
  capitalizeFirstLetter,
  toPracticeComponentMap,
} from 'assessments/reducers/utils';
import {
  ModelFieldsFragment,
  ModelTermType,
} from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from './legacyModelConfig';
import {
  defaultAssessmentIcon,
  defaultModelFeatures,
  defaultModelFlags,
  defaultModelTabs,
  defaultPracticeLevelHelpTextType,
} from './modelDefaults';
import { modelTransformer } from './modelTransformer';

export const modelStateFactory = (
  data: ModelFieldsFragment,
  legacyModelOverrides?: LegacyModelConfig
): IModelState => {
  const structuredData = modelTransformer(
    data,
    legacyModelOverrides?.shortTitle
  );

  const terms = legacyModelOverrides?.terms ?? {};
  const practiceComponentMap = toPracticeComponentMap(structuredData, terms);

  const modelTermsMap = getModelTerms(data);

  const icon = legacyModelOverrides?.icon
    ? legacyModelOverrides.icon
    : defaultAssessmentIcon;

  const features = legacyModelOverrides?.features
    ? legacyModelOverrides.features
    : defaultModelFeatures;

  const scoring = {
    useScaling: data.scoring?.useScaling ?? true,
    bottomOfScale: data.scoring?.bottomOfScale ?? 0,
    topOfScale: data.scoring?.topOfScale ?? 1000,

    bottomOfDomain: 0,
    topOfDomain: 100,
    useDropout: false,
    ...legacyModelOverrides?.scoring,
  };

  const { hasQuestionnaire, requiresAcknowledge, singletonAssessment } = {
    ...defaultModelFlags,
    ...legacyModelOverrides?.flags,
  };

  const modelUuid = data.uuid;
  const licenseKey = modelUuid;

  const hasTabs = {
    ...defaultModelTabs,
    ...legacyModelOverrides?.hasTabs,
  };

  const factories = legacyModelOverrides?.factories
    ? legacyModelOverrides.factories
    : [
        {
          label: data.title,
          filterLabel: data.title,
          requiredLicenseOption: ModelScope.Full,
          icon,
          sortKey: data.title,
          modelUuid,
          modelScope: ModelScope.Full,
          requiresImporter: false,
          dashboardType: DashboardType.SCORE_ONLY,
        },
      ];

  const orderedDomains = makeOrderedDomains(structuredData);
  const orderedDomainsAndObjectives = makeOrderedDomainsAndObjectives(
    structuredData
  );

  const dimensionSchema = generateDimensionSchema(
    structuredData.dimensionSchema
  );

  const modelColors = legacyModelOverrides?.colorTable ?? {};

  const practiceLevelHelpTextType =
    legacyModelOverrides?.practiceLevelHelpTextType ??
    defaultPracticeLevelHelpTextType;

  const questionnaireSchema = legacyModelOverrides?.questionnaireSchema;

  const wizardSchema = legacyModelOverrides?.wizardSchema;

  const initializedModelState = init({
    data: structuredData,
    dimensionSchema,
    factories,
    features,
    hasQuestionnaire,
    hasTabs,
    licenseKey,
    modelColors,
    modelTermsMap,
    modelUuid,
    orderedDomains,
    orderedDomainsAndObjectives,
    practiceComponentMap,
    practiceLevelHelpTextType,
    questionnaireSchema,
    requiresAcknowledge,
    scoring,
    singletonAssessment,
    terms,
    wizardSchema,
  });

  return initializedModelState;
};

const getModelTerms = (data: ModelFieldsFragment): ITermsMap => {
  if (!data.terms) {
    return ImmutableMap({
      domain: ['Domain', 'domain'] as [string, string],
      domains: ['Domains', 'domain'] as [string, string],
      objective: ['Objective', 'objective'] as [string, string],
      objectives: ['Objectives', 'objectives'] as [string, string],
      practice: ['Practice', 'practice'] as [string, string],
      practices: ['Practices', 'practices'] as [string, string],
      dimension: ['Dimension', 'dimension'] as [string, string],
      dimensions: ['Dimensions', 'dimensions'] as [string, string],
    });
  }

  const terms = data.terms.reduce(
    (record, { isPlural, term, type }) => {
      switch (type) {
        case ModelTermType.Domain:
          isPlural
            ? (record.pluralDomainTerm = term)
            : (record.singularDomainTerm = term);
          break;
        case ModelTermType.Dimension:
          isPlural
            ? (record.pluralDimensionTerm = term)
            : (record.singularDimensionTerm = term);
          break;
        case ModelTermType.Objective:
          isPlural
            ? (record.pluralObjectiveTerm = term)
            : (record.singularObjectiveTerm = term);
          break;
        case ModelTermType.Practice:
          isPlural
            ? (record.pluralPracticeTerm = term)
            : (record.singularPracticeTerm = term);
          break;
        default:
          break;
      }
      return record;
    },
    {
      singularDomainTerm: '',
      singularObjectiveTerm: '',
      singularPracticeTerm: '',
      singularDimensionTerm: '',
      pluralDomainTerm: '',
      pluralObjectiveTerm: '',
      pluralPracticeTerm: '',
      pluralDimensionTerm: '',
    }
  );

  return ImmutableMap({
    domain: terms.singularDomainTerm
      ? ([
          capitalizeFirstLetter(terms.singularDomainTerm),
          terms.singularDomainTerm.toLowerCase(),
        ] as [string, string])
      : (['Domain', 'domain'] as [string, string]),
    domains: terms.pluralDomainTerm
      ? ([
          capitalizeFirstLetter(terms.pluralDomainTerm),
          terms.pluralDomainTerm.toLowerCase(),
        ] as [string, string])
      : (['Domains', 'domains'] as [string, string]),
    objective: terms.singularObjectiveTerm
      ? ([
          capitalizeFirstLetter(terms.singularObjectiveTerm),
          terms.singularObjectiveTerm.toLowerCase(),
        ] as [string, string])
      : (['Objective', 'objective'] as [string, string]),
    objectives: terms.pluralObjectiveTerm
      ? ([
          capitalizeFirstLetter(terms.pluralObjectiveTerm),
          terms.pluralObjectiveTerm.toLowerCase(),
        ] as [string, string])
      : (['Objectives', 'objectives'] as [string, string]),
    practice: terms.singularPracticeTerm
      ? ([
          capitalizeFirstLetter(terms.singularPracticeTerm),
          terms.singularPracticeTerm.toLowerCase(),
        ] as [string, string])
      : (['Practice', 'practice'] as [string, string]),
    practices: terms.pluralPracticeTerm
      ? ([
          capitalizeFirstLetter(terms.pluralPracticeTerm),
          terms.pluralPracticeTerm.toLowerCase(),
        ] as [string, string])
      : (['Practices', 'practices'] as [string, string]),
    dimension: terms.singularDimensionTerm
      ? ([
          capitalizeFirstLetter(terms.singularDimensionTerm),
          terms.singularDimensionTerm.toLowerCase(),
        ] as [string, string])
      : (['Dimension', 'dimension'] as [string, string]),
    dimensions: terms.pluralDimensionTerm
      ? ([
          capitalizeFirstLetter(terms.pluralDimensionTerm),
          terms.pluralDimensionTerm.toLowerCase(),
        ] as [string, string])
      : (['Dimensions', 'dimensions'] as [string, string]),
  });
};
