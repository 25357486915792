// This object maps keys from the CSF model to contribution values in the
// CIS18 model.  Depending on how we want to handle the mapping, we can
// either set the contributions to add up to > 1 and give someone credit
// once they get to 1 or we can make sure the contributions add up to
// exactly 1, in which case you won't get NSF credit until you achieve all
// of the practices.  We also *can* decide whether or not to give
// partial credit.  In order to manage javacsript rounding issues, the numbers
// are stored in integer percents.  Divide by 100 to get the decimal bit.

export default {
  '.ID': 'IDENTIFY',
  '.ID.AM': 'Asset Management',
  '.ID.AM.1': {
    '.EA.1.1': 100,
  },
  '.ID.AM.2': {
    '.SW.2.1': 33,
    '.SW.2.2': 33,
    '.AS.16.4': 34,
  },
  '.ID.AM.3': {
    '.DP.3.8': 100,
  },
  '.ID.AM.4': {
    '.NI.12.4': 100,
  },
  '.ID.AM.5': {
    '.DP.3.2': 50,
    '.DP.3.7': 50,
  },
  '.ID.AM.6': {
    '.SA.14.1': 100,
  },

  '.ID.BE': 'Business Environment',
  '.ID.BE.1': {},
  '.ID.BE.2': {},
  '.ID.BE.3': {},
  '.ID.BE.4': {},
  '.ID.BE.5': {},

  '.ID.GV': 'Governance',
  '.ID.GV.1': {
    '.SA.14.1': 100,
  },
  '.ID.GV.2': {
    '.SP.15.2': 50,
    '.IR.17.4': 50,
  },
  '.ID.GV.3': {},
  '.ID.GV.4': {},

  '.ID.RA': 'Risk Assessment',
  '.ID.RA.1': {
    '.VM.7.1': 33,
    '.VM.7.2': 33,
    '.VM.7.4': 34,
  },
  '.ID.RA.2': {},
  '.ID.RA.3': {},
  '.ID.RA.4': {},
  '.ID.RA.5': {
    '.DP.3.7': 50,
    '.VM.7.6': 50,
  },
  '.ID.RA.6': {},

  '.ID.RM': 'Risk Management Strategy',
  '.ID.RM.1': {},
  '.ID.RM.2': {},
  '.ID.RM.3': {},

  '.ID.SC': 'Supply Chain Risk Management',
  '.ID.SC.1': {
    '.SP.15.2': 100,
  },
  '.ID.SC.2': {
    '.SP.15.1': 33,
    '.SP.15.3': 33,
    '.SP.15.5': 34,
  },
  '.ID.SC.3': {
    '.SP.15.4': 100,
  },
  '.ID.SC.4': {
    '.SP.15.5': 100,
  },
  '.ID.SC.5': {
    '.DR.11.1': 100,
  },

  '.PR': 'PROTECT',
  '.PR.AC': 'Identity Management, Authentication and Access Control',
  '.PR.AC.1': {
    '.SC.4.7': 10,
    '.AM.5.1': 10,
    '.AM.5.3': 10,
    '.AM.5.5': 10,
    '.AC.6.1': 10,
    '.AC.6.2': 10,
    '.AC.6.6': 10,
    '.AC.6.7': 10,
    '.NM.13.9': 10,
    '.SP.15.7': 10,
  },
  '.PR.AC.2': {},
  '.PR.AC.3': {
    '.SC.4.11': 20,
    '.AC.6.4': 20,
    '.AC.6.6': 20,
    '.NI.12.7': 20,
    '.NM.13.5': 20,
  },
  '.PR.AC.4': {
    '.DP.3.3': 33,
    '.AM.5.4': 33,
    '.AC.6.8': 34,
  },
  '.PR.AC.5': {
    '.DP.3.12': 12.5,
    '.EW.9.2': 12.5,
    '.EW.9.3': 12.5,
    '.EW.9.6': 12.5,
    '.NI.12.2': 12.5,
    '.NI.12.8': 12.5,
    '.NM.13.4': 12.5,
    '.AS.16.14': 12.5,
  },
  '.PR.AC.6': {},
  '.PR.AC.7': {
    '.AC.6.3': 15,
    '.AC.6.4': 15,
    '.AC.6.5': 14,
    '.NI.12.3': 14,
    '.NI.12.6': 14,
    '.NI.12.7': 14,
    '.NM.13.5': 14,
  },

  '.PR.AT': 'Awareness and Training',
  '.PR.AT.1': {
    '.SA.14.1': 9,
    '.SA.14.2': 9,
    '.SA.14.3': 9,
    '.SA.14.4': 9,
    '.SA.14.5': 9,
    '.SA.14.6': 9,
    '.SA.14.7': 9,
    '.SA.14.8': 9,
    '.SA.14.9': 9,
    '.AS.16.9': 9,
    '.IR.17.3': 10,
  },
  '.PR.AT.2': {
    '.SA.14.9': 50,
    '.AS.16.9': 50,
  },
  '.PR.AT.3': {
    '.SP.15.4': 100,
  },
  '.PR.AT.4': {
    '.SA.14.9': 100,
  },
  '.PR.AT.5': {
    '.SA.14.9': 100,
  },

  '.PR.DS': 'Data Security',
  '.PR.DS.1': {
    '.DP.3.11': 50,
    '.AS.16.11': 50,
  },
  '.PR.DS.2': {
    '.DP.3.10': 25,
    '.NI.12.3': 25,
    '.NI.12.6': 25,
    '.AS.16.11': 25,
  },
  '.PR.DS.3': {
    '.EA.1.1': 50,
    '.DP.3.5': 50,
  },
  '.PR.DS.4': {},
  '.PR.DS.5': {
    '.DP.3.13': 50,
    '.AS.16.14': 50,
  },
  '.PR.DS.6': {
    '.DR.11.5': 100,
  },
  '.PR.DS.7': {
    '.AS.16.8': 100,
  },
  '.PR.DS.8': {
    '.AS.16.14': 100,
  },

  '.PR.IP': 'Information Protection Processes and Procedures',
  '.PR.IP.1': {
    '.SW.2.7': 12.5,
    '.SC.4.1': 12.5,
    '.SC.4.2': 12.5,
    '.SC.4.3': 12.5,
    '.EW.9.1': 12.5,
    '.EW.9.4': 12.5,
    '.AS.16.1': 12.5,
    '.AS.16.7': 12.5,
  },
  '.PR.IP.2': {
    '.AS.16.5': 33,
    '.AS.16.10': 33,
    '.AS.16.12': 34,
  },
  '.PR.IP.3': {},
  '.PR.IP.4': {
    '.DR.11.2': 50,
    '.DR.11.3': 50,
  },
  '.PR.IP.5': {},
  '.PR.IP.6': {
    '.DP.3.1': 50,
    '.DP.3.5': 50,
  },
  '.PR.IP.7': {
    '.AS.16.14': 50,
    '.PT.18.1': 50,
  },
  '.PR.IP.8': {},
  '.PR.IP.9': {
    '.DR.11.1': 25,
    '.IR.17.1': 25,
    '.IR.17.3': 25,
    '.IR.17.4': 25,
  },
  '.PR.IP.10': {
    '.IR.17.7': 100,
  },
  '.PR.IP.11': {
    '.AC.6.2': 100,
  },
  '.PR.IP.12': {
    '.VM.7.6': 100,
  },

  '.PR.MA': 'Maintenance',
  '.PR.MA.1': {},
  '.PR.MA.2': {
    '.NM.13.5': 100,
  },

  '.PR.PT': 'Protective Technology',
  '.PR.PT.1': {
    '.AL.8.2': 25,
    '.AL.8.4': 25,
    '.AL.8.8': 25,
    '.AL.8.11': 25,
  },
  '.PR.PT.2': {
    '.DP.3.9': 50,
    '.MD.10.3': 50,
  },
  '.PR.PT.3': {
    '.SW.2.7': 50,
    '.NM.13.10': 50,
  },
  '.PR.PT.4': {},
  '.PR.PT.5': {
    '.DR.11.4': 100,
  },

  '.DE': 'DETECT',
  '.DE.AE': 'Anomalies and Events',
  '.DE.AE.1': {
    '.DP.3.8': 100,
  },
  '.DE.AE.2': {
    '.AL.8.11': 100,
  },
  '.DE.AE.3': {
    '.AL.8.2': 17,
    '.AL.8.5': 17,
    '.AL.8.6': 17,
    '.AL.8.7': 17,
    '.AL.8.8': 16,
    '.AL.8.12': 16,
  },
  '.DE.AE.4': {},
  '.DE.AE.5': {
    '.NM.13.11': 100,
  },

  '.DE.CM': 'Security Continuous Monitoring',
  '.DE.CM.1': {
    '.AL.8.5': 17,
    '.NM.13.2': 17,
    '.NM.13.3': 17,
    '.NM.13.6': 17,
    '.NM.13.7': 16,
    '.NM.13.8': 16,
  },
  '.DE.CM.2': {},
  '.DE.CM.3': {},
  '.DE.CM.4': {
    '.EW.9.7': 15,
    '.MD.10.1': 15,
    '.MD.10.2': 14,
    '.MD.10.4': 14,
    '.MD.10.5': 14,
    '.MD.10.6': 14,
    '.MD.10.7': 14,
  },
  '.DE.CM.5': {},
  '.DE.CM.6': {
    '.SP.15.6': 100,
  },
  '.DE.CM.7': {
    '.EA.1.3': 11,
    '.EA.1.4': 11,
    '.EA.1.5': 11,
    '.SW.2.3': 11,
    '.SW.2.4': 11,
    '.SW.2.5': 11,
    '.SW.2.6': 11,
    '.EW.9.6': 11,
    '.NM.13.5': 12,
  },
  '.DE.CM.8': {
    '.VM.7.5': 100,
  },

  '.DE.DP': 'Detection Processes',
  '.DE.DP.1': {
    '.IR.17.1': 50,
    '.IR.17.4': 50,
  },
  '.DE.DP.2': {},
  '.DE.DP.3': {},
  '.DE.DP.4': {
    '.IR.17.5': 100,
  },
  '.DE.DP.5': {},

  '.RS': 'RESPOND',
  '.RS.RP': 'Response Planning',
  '.RS.RP.1': {},

  '.RS.CO': 'Communications',
  '.RS.CO.1': {
    '.IR.17.2': 50,
    '.IR.17.4': 50,
  },
  '.RS.CO.2': {
    '.IR.17.5': 100,
  },
  '.RS.CO.3': {
    '.IR.17.5': 100,
  },
  '.RS.CO.4': {
    '.IR.17.5': 100,
  },
  '.RS.CO.5': {},

  '.RS.AN': 'Analysis',
  '.RS.AN.1': {
    '.AL.8.11': 33,
    '.AS.16.3': 33,
    '.AS.16.6': 34,
  },
  '.RS.AN.2': {},
  '.RS.AN.3': {},
  '.RS.AN.4': {
    '.IR.17.9': 100,
  },
  '.RS.AN.5': {
    '.AS.16.2': 100,
  },

  '.RS.MI': 'Mitigation',
  '.RS.MI.1': {},
  '.RS.MI.2': {},
  '.RS.MI.3': {},

  '.RS.IM': 'Improvements',
  '.RS.IM.1': {
    '.IR.17.8': 100,
  },
  '.RS.IM.2': {
    '.IR.17.8': 100,
  },

  '.RC': 'RECOVER',
  '.RC.RP': 'Recovery Planning',
  '.RC.RP.1': {
    '.DR.11.1': 50,
    '.IR.17.9': 50,
  },

  '.RC.IM': 'Improvements',
  '.RC.IM.1': {
    '.IR.17.8': 100,
  },
  '.RC.IM.2': {
    '.IR.17.8': 100,
  },

  '.RC.CO': 'Communications',
  '.RC.CO.1': {
    '.IR.17.5': 100,
  },
  '.RC.CO.2': {},
  '.RC.CO.3': {
    '.IR.17.6': 100,
  },
};
