// This object maps keys from the CSF model to contribution values in the
// CRI model.  Depending on how we want to handle the mapping, we can
// either set the contributions to add up to > 1 and give someone credit
// once they get to 1 or we can make sure the contributions add up to
// exactly 1, in which case you won't get NSF credit until you achieve all
// of the practices.  We also *can* decide whether or not to give
// partial credit.  In order to manage javascript rounding issues
// (see warning: in MDN's Number/toFixed docs to deal with js using binary64
// for numbers), the numbers are stored in integer percents.  Divide by 100 to
// get the decimal bit.

export default {
  '.ID': 'IDENTIFY',
  '.ID.AM': 'Asset Management',
  '.ID.AM.1': {
    '.ID.1.1': 100,
  },
  '.ID.AM.2': {
    '.ID.1.2': 100,
  },
  '.ID.AM.3': {
    '.ID.1.3': 33,
    '.ID.1.4': 33,
    '.ID.1.5': 34,
  },
  '.ID.AM.4': {
    '.ID.1.6': 100,
  },
  '.ID.AM.5': {
    '.ID.1.7': 50,
    '.ID.1.8': 50,
  },
  '.ID.AM.6': {
    '.ID.1.9': 100,
  },

  '.ID.BE': 'Business Environment',
  '.ID.BE.1': {
    '.DM.2.16': 25,
    '.DM.2.17': 25,
    '.DM.2.18': 25,
    '.DM.2.19': 25,
  },
  '.ID.BE.2': {
    '.DM.2.16': 17,
    '.DM.2.17': 17,
    '.DM.2.18': 17,
    '.DM.2.19': 17,
    '.DM.4.1': 16,
    '.DM.4.2': 16,
  },
  '.ID.BE.3': {
    '.GV.1.1': 20,
    '.GV.1.2': 20,
    '.GV.1.3': 20,
    '.GV.1.4': 20,
    '.GV.1.5': 20,
  },
  '.ID.BE.4': {
    '.DM.4.3': 50,
    '.DM.4.4': 50,
  },
  '.ID.BE.5': {
    '.DM.BE-3.1': 100,
  },

  '.ID.GV': 'Governance',
  '.ID.GV.1': {
    '.GV.3.1': 50,
    '.GV.3.2': 50,
  },
  '.ID.GV.2': {
    '.GV.4.1': 100,
  },
  '.ID.GV.3': {
    '.GV.3.6': 33,
    '.GV.3.7': 33,
    '.GV.3.8': 34,
  },
  '.ID.GV.4': {
    '.GV.1.7': 33,
    '.GV.1.8': 33,
    '.GV.1.9': 34,
  },

  '.ID.RA': 'Risk Assessment',
  '.ID.RA.1': {
    '.ID.2.1': 100,
  },
  '.ID.RA.2': {
    '.ID.2.2': 100,
  },
  '.ID.RA.3': {
    '.ID.2.3': 33,
    '.ID.2.4': 33,
    '.ID.2.5': 34,
  },
  '.ID.RA.4': {
    '.ID.2.6': 100,
  },
  '.ID.RA.5': {
    '.ID.2.7': 17,
    '.ID.2.8': 17,
    '.ID.2.9': 17,
    '.ID.2.10': 17,
    '.ID.2.11': 16,
    '.ID.2.12': 16,
  },
  '.ID.RA.6': {
    '.ID.2.13': 50,
    '.ID.2.14': 50,
  },

  '.ID.RM': 'Risk Management Strategy',
  '.ID.RM.1': {
    '.GV.1.1': 9,
    '.GV.1.2': 9,
    '.GV.1.3': 9,
    '.GV.1.4': 9,
    '.GV.1.5': 9,
    '.GV.2.1': 9,
    '.GV.2.2': 9,
    '.GV.2.3': 9,
    '.GV.2.4': 9,
    '.GV.2.5': 9,
    '.GV.2.6': 10,
  },
  '.ID.RM.2': {
    '.GV.2.7': 33,
    '.GV.2.8': 33,
    '.GV.2.9': 34,
  },
  '.ID.RM.3': {
    '.GV.1.10': 100,
  },

  '.ID.SC': 'Supply Chain Risk Management',
  '.ID.SC.1': {
    '.DM.2.4': 20,
    '.DM.2.5': 20,
    '.DM.2.6': 20,
    '.DM.2.7': 20,
    '.DM.2.8': 20,
  },
  '.ID.SC.2': {
    '.DM.2.16': 25,
    '.DM.2.17': 25,
    '.DM.2.18': 25,
    '.DM.2.19': 25,
  },
  '.ID.SC.3': {
    '.DM.2.20': 14,
    '.DM.2.21': 14,
    '.DM.2.22': 14,
    '.DM.2.23': 14,
    '.DM.2.24': 14,
    '.DM.2.25': 15,
    '.DM.2.26': 15,
  },
  '.ID.SC.4': {
    '.DM.2.27': 25,
    '.DM.2.28': 25,
    '.DM.2.29': 25,
    '.DM.2.30': 25,
  },
  '.ID.SC.5': {
    '.DM.3.4': 20,
    '.DM.3.5': 20,
    '.DM.3.6': 20,
    '.DM.3.7': 20,
    '.DM.3.8': 20,
  },

  '.PR': 'PROTECT',
  '.PR.AC': 'Identity Management, Authentication and Access Control',
  '.PR.AC.1': {
    '.PR.1.1': 33,
    '.PR.1.2': 33,
    '.PR.1.3': 34,
  },
  '.PR.AC.2': {
    '.PR.1.4': 100,
  },
  '.PR.AC.3': {
    '.PR.1.5': 50,
    '.PR.1.6': 50,
  },
  '.PR.AC.4': {
    '.PR.1.7': 33,
    '.PR.1.8': 33,
    '.PR.1.9': 34,
  },
  '.PR.AC.5': {
    '.PR.1.10': 50,
    '.PR.1.11': 50,
  },
  '.PR.AC.6': {
    '.PR.1.12': 100,
  },
  '.PR.AC.7': {
    '.PR.1.13': 50,
    '.PR.1.14': 50,
  },

  '.PR.AT': 'Awareness and Training',
  '.PR.AT.1': {
    '.PR.2.1': 33,
    '.PR.2.2': 33,
    '.PR.2.3': 34,
  },
  '.PR.AT.2': {
    '.PR.2.4': 33,
    '.PR.2.5': 33,
    '.PR.2.6': 34,
  },
  '.PR.AT.3': {
    '.PR.2.7': 33,
    '.PR.2.8': 33,
    '.PR.2.9': 34,
  },
  '.PR.AT.4': {
    '.PR.2.10': 50,
    '.PR.2.11': 50,
  },
  '.PR.AT.5': {
    '.PR.2.12': 100,
  },

  '.PR.DS': 'Data Security',
  '.PR.DS.1': {
    '.PR.3.1': 50,
    '.PR.3.2': 50,
  },
  '.PR.DS.2': {
    '.PR.3.3': 50,
    '.PR.3.4': 50,
  },
  '.PR.DS.3': {
    '.PR.3.5': 100,
  },
  '.PR.DS.4': {
    '.PR.3.6': 100,
  },
  '.PR.DS.5': {
    '.PR.3.7': 100,
  },
  '.PR.DS.6': {
    '.PR.3.8': 100,
  },
  '.PR.DS.7': {
    '.PR.3.9': 100,
  },
  '.PR.DS.8': {
    '.PR.3.10': 100,
  },

  '.PR.IP': 'Information Protection Processes and Procedures',
  '.PR.IP.1': {
    '.PR.4.1': 33,
    '.PR.4.2': 33,
    '.PR.4.3': 34,
  },
  '.PR.IP.2': {
    '.PR.4.4': 33,
    '.PR.4.5': 33,
    '.PR.4.6': 34,
  },
  '.PR.IP.3': {
    '.PR.4.7': 100,
  },
  '.PR.IP.4': {
    '.PR.4.8': 25,
    '.PR.4.9': 25,
    '.PR.4.10': 25,
    '.PR.4.11': 25,
  },
  '.PR.IP.5': {
    '.PR.4.12': 100,
  },
  '.PR.IP.6': {
    '.PR.4.13': 100,
  },
  '.PR.IP.7': {
    '.PR.4.14': 100,
  },
  '.PR.IP.8': {
    '.PR.4.15': 100,
  },
  '.PR.IP.9': {
    '.PR.4.16': 50,
    '.PR.4.17': 50,
  },
  '.PR.IP.10': {
    '.PR.4.18': 25,
    '.PR.4.19': 25,
    '.PR.4.20': 25,
    '.PR.4.21': 25,
  },
  '.PR.IP.11': {
    '.PR.4.22': 33,
    '.PR.4.23': 33,
    '.PR.4.24': 34,
  },
  '.PR.IP.12': {
    '.PR.4.25': 25,
    '.PR.4.26': 25,
    '.PR.4.27': 25,
    '.PR.4.28': 25,
  },

  '.PR.MA': 'Maintenance',
  '.PR.MA.1': {
    '.PR.5.1': 100,
  },
  '.PR.MA.2': {
    '.PR.5.2': 100,
  },

  '.PR.PT': 'Protective Technology',
  '.PR.PT.1': {
    '.PR.6.1': 50,
    '.PR.6.2': 50,
  },
  '.PR.PT.2': {
    '.PR.6.3': 100,
  },
  '.PR.PT.3': {
    '.PR.6.4': 100,
  },
  '.PR.PT.4': {
    '.PR.6.5': 100,
  },
  '.PR.PT.5': {
    '.PR.6.6': 100,
  },

  '.DE': 'DETECT',
  '.DE.AE': 'Anomalies and Events',
  '.DE.AE.1': {
    '.DE.1.1': 100,
  },
  '.DE.AE.2': {
    '.DE.1.2': 100,
  },
  '.DE.AE.3': {
    '.DE.1.3': 50,
    '.DE.1.4': 50,
  },
  '.DE.AE.4': {
    '.DE.1.5': 100,
  },
  '.DE.AE.5': {
    '.DE.1.6': 100,
  },

  '.DE.CM': 'Security Continuous Monitoring',
  '.DE.CM.1': {
    '.DE.2.1': 25,
    '.DE.2.2': 25,
    '.DE.2.3': 25,
    '.DE.2.4': 25,
  },
  '.DE.CM.2': {
    '.DE.2.5': 100,
  },
  '.DE.CM.3': {
    '.DE.2.6': 33,
    '.DE.2.7': 33,
    '.DE.2.8': 34,
  },
  '.DE.CM.4': {
    '.DE.2.9': 50,
    '.DE.2.10': 50,
  },
  '.DE.CM.5': {
    '.DE.2.11': 100,
  },
  '.DE.CM.6': {
    '.DE.2.12': 33,
    '.DE.2.13': 33,
    '.DE.2.14': 34,
  },
  '.DE.CM.7': {
    '.DE.2.15': 25,
    '.DE.2.16': 25,
    '.DE.2.17': 25,
    '.DE.2.18': 25,
  },
  '.DE.CM.8': {
    '.DE.2.19': 50,
    '.DE.2.20': 50,
  },

  '.DE.DP': 'Detection Processes',
  '.DE.DP.1': {
    '.DE.3.1': 100,
  },
  '.DE.DP.2': {
    '.DE.3.2': 100,
  },
  '.DE.DP.3': {
    '.DE.3.3': 100,
  },
  '.DE.DP.4': {
    '.DE.3.4': 50,
    '.DE.3.5': 50,
  },
  '.DE.DP.5': {
    '.DE.3.6': 100,
  },

  '.RS': 'RESPOND',
  '.RS.RP': 'Response Planning',
  '.RS.RP.1': {
    '.RS.1.1': 100,
  },

  '.RS.CO': 'Communications',
  '.RS.CO.1': {
    '.RS.2.1': 33,
    '.RS.2.2': 33,
    '.RS.2.3': 34,
  },
  '.RS.CO.2': {
    '.RS.2.4': 25,
    '.RS.2.5': 25,
    '.RS.2.6': 25,
    '.RS.2.7': 25,
  },
  '.RS.CO.3': {
    '.RS.2.8': 50,
    '.RS.2.9': 50,
  },
  '.RS.CO.4': {
    '.RS.2.10': 100,
  },
  '.RS.CO.5': {
    '.RS.2.11': 33,
    '.RS.2.12': 33,
    '.RS.2.13': 34,
  },

  '.RS.AN': 'Analysis',
  '.RS.AN.1': {
    '.RS.3.1': 100,
  },
  '.RS.AN.2': {
    '.RS.3.2': 50,
    '.RS.3.3': 50,
  },
  '.RS.AN.3': {
    '.RS.3.4': 100,
  },
  '.RS.AN.4': {
    '.RS.3.5': 100,
  },
  '.RS.AN.5': {
    '.RS.3.6': 33,
    '.RS.3.7': 33,
    '.RS.3.8': 34,
  },

  '.RS.MI': 'Mitigation',
  '.RS.MI.1': {
    '.RS.4.1': 50,
    '.RS.4.2': 50,
  },
  '.RS.MI.2': {
    '.RS.4.3': 100,
  },
  '.RS.MI.3': {
    '.RS.4.4': 50,
    '.RS.4.5': 50,
  },

  '.RS.IM': 'Improvements',
  '.RS.IM.1': {
    '.RS.5.1': 33,
    '.RS.5.2': 33,
    '.RS.5.3': 34,
  },
  '.RS.IM.2': {
    '.RS.5.4': 100,
  },

  '.RC': 'RECOVER',
  '.RC.RP': 'Recovery Planning',
  '.RC.RP.1': {
    '.RC.1.1': 17,
    '.RC.1.2': 17,
    '.RC.1.3': 17,
    '.RC.1.4': 17,
    '.RC.1.5': 16,
    '.RC.1.6': 16,
  },

  '.RC.IM': 'Improvements',
  '.RC.IM.1': {
    '.RC.2.1': 100,
  },
  '.RC.IM.2': {
    '.RC.2.1': 100,
  },

  '.RC.CO': 'Communications',
  '.RC.CO.1': {
    '.RC.3.1': 50,
    '.RC.3.2': 50,
  },
  '.RC.CO.2': {
    '.RC.3.3': 100,
  },
  '.RC.CO.3': {
    '.RC.3.4': 100,
  },
};
