export default {
  access:
    'Ability and means to enter a facility, to communicate with or otherwise interact with a system, to use system resources to handle information, to gain knowledge of the information the system contains, or to control system components and functions.',
  'access control':
    "Limiting access to organizational assets only to authorized entities (e.g., users, programs, processes, or other systems). See <a href='#' data-location='definition/asset'>asset</a>.",
  'access management':
    "Management processes to ensure that access granted to the organization’s assets is commensurate with the risk to critical infrastructure and organizational objectives. See <a href='#' data-location='definition/access%20control'>access control</a> and <a href='#' data-location='definition/asset'>asset</a>.",
  'ad hoc':
    'In the context of this model, ad hoc (i.e., an ad hoc practice) refers to performing a practice in a manner that depends largely on the initiative and experience of an individual or team (and team leadership), with little organizational guidance in the form of prescribed plans (verbal or written), policies, or training. The methods, tools, and techniques used, the priority given a particular instance of the practice, and the quality of the outcome may vary significantly depending on who is performing the practice, when it is performed, and the context of the problem being addressed. With experienced and talented personnel, high-quality outcomes may be achieved even though practices are ad hoc. However, because lessons learned are typically not captured at the organizational level, approaches and outcomes are difficult to repeat or improve across the organization.',
  architecture: '>>> cybersecurity architecture',
  assessment: '>>> risk assessment',
  asset:
    'Something of value to the organization. Assets include many things, including technology, information, roles performed by personnel, and facilities. For the purposes of this model, assets to be considered are information technology (IT) and operations technology (OT) hardware and software assets, as well as information essential to operating the function.',
  'asset owner':
    'A person or organizational unit, internal or external to the organization, that has primary responsibility for the viability, productivity, and resilience of an organizational asset.',
  authentication:
    'Verifying the identity of a user, process, or device, often as a prerequisite to allowing access to resources in an IT or industrial control system (ICS).',
  availability:
    'Ensuring timely and reliable access to and use of information. For an asset, the quality of being accessible to authorized users (people, processes, or devices) whenever the asset is needed.',
  'business impact analysis':
    'A mission impact analysis that prioritizes the impact associated with the compromise of an organization’s information assets, based on a qualitative or quantitative assessment of the sensitivity and criticality of those assets.',
  'change control (change management)':
    'A continuous process of controlling changes to information or technology assets, related infrastructure, or any aspect of services, enabling approved changes with minimum disruption.',
  'change control':
    'A continuous process of controlling changes to information or technology assets, related infrastructure, or any aspect of services, enabling approved changes with minimum disruption.',
  'change management':
    'A continuous process of controlling changes to information or technology assets, related infrastructure, or any aspect of services, enabling approved changes with minimum disruption.',
  'common operating picture (COP)':
    'Information aggregated and correlated through various sources and activities that provides real-time or near-real-time visibility into the operational status and cybersecurity posture of the function.',
  'common operating picture':
    'Information aggregated and correlated through various sources and activities that provides real-time or near-real-time visibility into the operational status and cybersecurity posture of the function.',
  COP:
    'Information aggregated and correlated through various sources and activities that provides real-time or near-real-time visibility into the operational status and cybersecurity posture of the function.',
  'computer security incident':
    "A computer security incident is a violation or imminent threat of violation of computer security policies, acceptable use policies, or standard security practices. An “imminent threat of violation” refers to a situation in which the organization has a factual basis for believing that a specific incident is about to occur. For example, the antivirus software maintainers may receive a bulletin from the software vendor, warning them of new malware that is rapidly spreading across the Internet. See <a href='#' data-location='definition/incident'>incident</a>.",
  confidentiality:
    'The preservation of authorized restrictions on information access and disclosure, including means for protecting personal privacy and proprietary information. For an information asset, confidentiality is the quality of being accessible only to authorized people, processes, and devices.',
  'configuration baseline':
    'A documented set of specifications for an IT or OT system or asset, or a configuration item within a system, that has been formally reviewed and agreed upon at a given point in time, and which should be changed only through change control procedures. The baseline configuration is used as a basis for future builds, releases, and/or changes.',
  'configuration management':
    'A collection of activities focused on establishing and maintaining the integrity of assets, through control of the processes for initializing, changing, and monitoring the configurations of those assets throughout their lifecycle.',
  'contingency plan':
    'Management policy and procedures used to guide an enterprise response to a perceived loss of mission capability. The contingency plan is the first plan used by the enterprise risk managers to determine what happened, why, and what to do. It may point to the continuity of operations plan or disaster recovery plan for major disruptions.',
  'continuous monitoring':
    'Maintaining ongoing awareness of the current cybersecurity state of the function throughout the operational environment by collecting, analyzing, alarming, presenting, and using power system and cybersecurity information to identify anomalous activities, vulnerabilities, and threats to the function in order to support incident response and organizational risk management decisions.',
  controls:
    'The management, operational, and technical methods, policies, and procedures—manual or automated—(i.e., safeguards or countermeasures) prescribed for an IT system or ICS to protect the confidentiality, integrity, and availability of the system and its information.',
  'critical infrastructure':
    'Assets that provide the essential services that underpin American society. The Nation possesses numerous key resources, whose exploitation or destruction by terrorists could cause catastrophic health effects or mass casualties comparable to those from the use of a weapon of mass destruction, or could profoundly affect our national prestige and morale. In addition, there is critical infrastructure so vital that its incapacitation, exploitation, or destruction through terrorist attack could have a debilitating effect on security and economic well-being.',
  current:
    'Updated at an organization-defined frequency (e.g., as in the asset inventory is kept “current”) that is selected such that the risks to critical infrastructure and organization objectives associated with being out-of-date by the maximum interval between updates are acceptable to the organization and its stakeholders.',
  'cyber attack':
    'An attack, via cyberspace, targeting an enterprise’s use of cyberspace for the purpose of disrupting, disabling, destroying, or maliciously controlling a computing environment/infrastructure, or for destroying the integrity of the data or stealing controlled information.',
  cybersecurity:
    'The ability to protect or defend the use of cyberspace from cyber attacks. Measures taken to protect a computer or computerized system (IT and OT) against unauthorized access or attack.',
  'cybersecurity architecture':
    "An integral part of the enterprise architecture that describes the structure and behavior for an enterprise’s security processes, cybersecurity systems, personnel, and subordinate organizations, showing their alignment with the organization’s mission and strategic plans. See <a href='#' data-location='definition/enterprise%20architecture'>enterprise architecture</a> and <a href='#' data-location='definition/network%20architecture'>network architecture</a>.",
  'cybersecurity event':
    "Any observable occurrence in a system or network that is related to a cybersecurity requirement (confidentiality, integrity, or availability). See <a href='#' data-location='definition/event'>event</a>.",
  'cybersecurity impact':
    'The effect on the measures that are in place to protect from and defend against cyber attack.',
  'cybersecurity incident':
    "The set of attribute values (i.e., characteristics) by which an entity is recognizable and that, within the scope of an identity manager’s responsibility, is sufficient to distinguish that entity from any other entity. See <a href='#' data-location='definition/incident'>incident</a>.",
  'cybersecurity incident lifecycle': '>>> incident lifecycle',
  'cybersecurity plan':
    'A formal document that provides an overview of the cybersecurity requirements for an IT system or ICS and describes the cybersecurity controls in place or planned for meeting those requirements.',
  'cybersecurity policy':
    'A set of criteria for the provision of security services.',
  'cybersecurity program':
    'An integrated group of activities designed and managed to meet cybersecurity objectives for the organization and/or the function. A cybersecurity program may be implemented at either the organization or the function level, but a higher level implementation and enterprise viewpoint may benefit the organization by integrating activities and leveraging resource investments across the entire enterprise.',
  'cybersecurity program strategy':
    'A plan of action that sets forth the purpose, objectives and activities, and performance targets for the cybersecurity program. At higher levels of maturity, the strategy will also include priorities, a governance approach, and structure and organization for the program.',
  'cybersecurity requirements':
    'Requirements levied on IT and OT that are derived from organizational mission and business case needs (in the context of applicable legislation, Executive Orders, directives, policies, standards, instructions, regulations, and procedures) to ensure the confidentiality, integrity, and availability of the services being provided by the organization and the information being processed, stored, or transmitted.',
  'cybersecurity responsibilities':
    'Obligations for ensuring that the organization’s cybersecurity requirements are met.',
  'cybersecurity risk':
    "The risk to organizational operations (including mission, functions, image, and reputation), resources, and other organizations due to the potential for unauthorized access, use, disclosure, disruption, modification, or destruction of information and/or IT system or ICS. See <a href='#' data-location='definition/risk'>risk</a>.",
  'cybersecurity workforce management objectives':
    'Performance targets for personnel with cybersecurity responsibilities that the organization sets to meet cybersecurity requirements.',
  'defined practice':
    'A practice that is planned (i.e., described, explained, made definite and clear, and standardized) and is executed in accordance with the plan.',
  'dependency risk':
    "Dependency risk is measured by the likelihood and severity of damage if an IT or OT system is compromised due to a supplier or other external party on which delivery of the function depends. Evaluating dependency risk includes an assessment of the importance of the potentially compromised system and the impact of compromise on organizational operations and assets, individuals, other organizations, and the Nation. See <a href='#' data-location='definition/upstream%20dependencies'>upstream dependencies</a> and <a href='#' data-location='definition/supply%20chain%20risk'>supply chain risk</a>.",
  domain:
    'In the context of the model structure, a domain is a logical grouping of cybersecurity practices.',
  'domain objectives':
    'The practices within each domain are organized into objectives. The objectives represent achievements that support the domain (such as “Manage Asset Configuration” for the ACM domain and “Increase Cybersecurity Awareness” for the WM domain). Each of the objectives in a domain comprises a set of practices, which are ordered by maturity indicator level.',
  'downstream dependencies':
    'External parties dependent on the delivery of the function, such as customers and some operating partners.',
  'electricity subsector':
    'A portion of the energy sector that includes the generation, transmission, and distribution of electricity.',
  enterprise:
    "The largest (i.e., highest level) organizational entity to which the organization participating in the C2M2 survey belongs. For some participants, the organization taking the survey is the enterprise itself. See <a href='#' data-location='definition/organization'>organization</a>.",
  'enterprise architecture':
    "The design and description of an enterprise’s entire set of IT and OT: how they are configured, how they are integrated, how they interface to the external environment at the enterprise’s boundary, how they are operated to support the enterprise mission, and how they contribute to the enterprise’s overall security posture. See <a href='#' data-location='definition/cybersecurity%20architecture'>cybersecurity architecture</a> and <a href='#' data-location='definition/network%20architecture'>network architecture</a>.",
  entity: 'Something having separate or distinct existence.',
  'establish and maintain':
    'The development and maintenance of the object of the practice (such as a program). For example, “Establish and maintain identities” means that not only must identities be provisioned, but they also must be documented, have assigned ownership, and be maintained relative to corrective actions, changes in requirements, or improvements.',
  event:
    'Any observable occurrence in a system or network. Depending on their potential impact, some events need to be escalated for response. To ensure consistency, criteria for response should align with the organization’s risk criteria.',
  function:
    'The high-level electricity system activity or set of activities performed by the utility to which the model is being applied. Generally, the function will be generation, transmission, distribution, and/or markets. When using the C2M2 evaluation survey, the function is the organizational line of business (generation, transmission, distribution, or markets) that is being evaluated by completing the model.',
  governance:
    'An organizational process of providing strategic direction for the organization while ensuring that it meets its obligations, appropriately manages risk, and efficiently uses financial and human resources. Governance also typically includes the concepts of sponsorship (setting the managerial tone), compliance (ensuring that the organization is meeting its compliance obligations), and alignment (ensuring that processes such as those for cybersecurity program management align with strategic objectives).',
  guidelines:
    "A set of recommended practices produced by a recognized authoritative source representing subject matter experts and community consensus or internally by an organization. See <a href='#' data-location='definition/standard'>standard</a>.",
  identity:
    'The set of attribute values (i.e., characteristics) by which an entity is recognizable and that, within the scope of an identity manager’s responsibility, is sufficient to distinguish that entity from any other entity.',
  impact: 'Negative consequence to subsector functions.',
  incident:
    "An event (or series of events) that significantly affects (or has the potential to significantly affect) critical infrastructure and/or organizational assets and services and requires the organization (and possibly other stakeholders) to respond in some way to prevent or limit adverse impacts. See <a href='#' data-location='definition/computer%20security%20incident'>computer security incident</a> and <a href='#' data-location='definition/event'>event</a>.",
  'incident lifecycle':
    'The stages of an incident from detection to closure. Collectively, the incident lifecycle includes the processes of detecting, reporting, logging, triaging, declaring, tracking, documenting, handling, coordinating, escalating and notifying, gathering and preserving evidence, and closing incidents. Escalated events also follow the incident lifecycle, even if they are never formally declared to be incidents.',
  'information assets':
    'Information or data that is of value to the organization, including diverse information such as operational data, intellectual property, customer information, and contracts.',
  'information sharing': '>>> ISAC',
  'Information Sharing and Analysis Center': '>>> ISAC',
  ISAC:
    'An Information Sharing and Analysis Center (ISAC) shares critical information with industry participants on infrastructure protection. Each critical infrastructure industry has established an ISAC to communicate with its members, its government partners, and other ISACs about threat indications, vulnerabilities, and protective strategies. ISACs work together to better understand cross-industry dependencies and to account for them in emergency response planning.',
  'Information Sharing and Communications':
    'The C2M2 domain with the purpose to establish and maintain relationships with internal and external entities to collect and provide cybersecurity information, including threats and vulnerabilities, to reduce risks and to increase operational resilience, commensurate with the risk to critical infrastructure and organizational objectives.',
  'information technology':
    'A discrete set of electronic information resources organized for the collection, processing, maintenance, use, sharing, dissemination, or disposition of information, including interconnected or dependent business systems and the environment in which they operate.',
  IT:
    'A discrete set of electronic information resources organized for the collection, processing, maintenance, use, sharing, dissemination, or disposition of information, including interconnected or dependent business systems and the environment in which they operate.',
  institutionalization:
    "The extent to which a practice or activity is ingrained into the way an organization operates. The more an activity becomes part of how an organization operates, the more likely it is that the activity will continue to be performed over time, with a consistently high level of quality. (“Incorporated into the ingrained way of doing business that an organization follows routinely as part of its corporate culture.” – CERT-RMM). See <a href='#' data-location='definition/maturity%20indicator%20evel'>maturity indicator level</a>.",
  integrity:
    'Guarding against improper information modification or destruction. Integrity includes ensuring information nonrepudiation and authenticity. For an asset, integrity is the quality of being in the condition intended by the owner and therefore continuing to be useful for the purposes intended by the owner.',
  logging:
    'Logging typically refers to automated recordkeeping (by elements of an IT or OT system) of system, network, or user activity. Logging may also refer to keeping a manual record (e.g., a sign-in sheet) of physical access by personnel to a protected asset or restricted area, although automated logging of physical access activity is commonplace. Regular review and audit of logs (manually or by automated tools) is a critical monitoring activity that is essential for situational awareness (e.g., through the detection of cybersecurity events or weaknesses).',
  'logical control':
    "A software, firmware, or hardware feature (i.e., computational logic, not a physical obstacle) within an IT or OT system that restricts access to and modification of assets only to authorized entities. For contrast, See <a href='#' data-location='definition/physical%20control'>physical control</a>.",
  markets:
    'Venues where participants buy and sell products and services. In the context of this model, markets refer to trading involving wholesale electricity.',
  maturity:
    'The extent to which an organization has implemented and institutionalized the cybersecurity practices of the model.',
  'maturity indicator level (MIL)':
    'A measure of the cybersecurity maturity of an organization in a given domain of the model. The model currently defines four maturity indicator levels (MILs) and holds a fifth level in reserve for use in future versions of the model. Each of the four defined levels is designated by a number (0 through 3) and a name; for example, “MIL3: managed.” A MIL is a measure of the progression within a domain from individual and team initiative, as a basis for carrying out cybersecurity practices, to organizational policies and procedures that institutionalize those practices, making them repeatable with a consistently high level of quality. As an organization progresses from one MIL to the next, the organization will have more complete or more advanced implementations of the core activities in the domain.',
  'maturity indicator level':
    'A measure of the cybersecurity maturity of an organization in a given domain of the model. The model currently defines four maturity indicator levels (MILs) and holds a fifth level in reserve for use in future versions of the model. Each of the four defined levels is designated by a number (0 through 3) and a name; for example, “MIL3: managed.” A MIL is a measure of the progression within a domain from individual and team initiative, as a basis for carrying out cybersecurity practices, to organizational policies and procedures that institutionalize those practices, making them repeatable with a consistently high level of quality. As an organization progresses from one MIL to the next, the organization will have more complete or more advanced implementations of the core activities in the domain.',
  MIL:
    'A measure of the cybersecurity maturity of an organization in a given domain of the model. The model currently defines four maturity indicator levels (MILs) and holds a fifth level in reserve for use in future versions of the model. Each of the four defined levels is designated by a number (0 through 3) and a name; for example, “MIL3: managed.” A MIL is a measure of the progression within a domain from individual and team initiative, as a basis for carrying out cybersecurity practices, to organizational policies and procedures that institutionalize those practices, making them repeatable with a consistently high level of quality. As an organization progresses from one MIL to the next, the organization will have more complete or more advanced implementations of the core activities in the domain.',
  'model objectives':
    'The goal of this model is to support ongoing development and measurement of cybersecurity capabilities through the following four objectives:',
  monitoring:
    'Collecting, recording, and distributing information about the behavior and activities of systems and persons to support the continuous process of identifying and analyzing risks to organizational assets and critical infrastructure that could adversely affect the operation and delivery of services.',
  'monitoring requirements':
    'The requirements established to determine the information gathering and distribution needs of stakeholders.',
  'network architecture':
    "A framework that describes the structure and behavior of communications among IT and/or OT assets and prescribes rules for interaction and interconnection. See <a href='#' data-location='definition/enterprise%20architecture'>enterprise architecture</a> and <a href='#' data-location='definition/cybersecurity%20architecture'>cybersecurity architecture</a>.",
  objective: '>>> domain objectives',
  'operating picture':
    'Real-time (or near-real-time) awareness of the operating state of a system or function. An operating picture is formed from data collected from various trusted information sources that may be internal or external to the system or function (e.g. temperature, weather events and warnings, cybersecurity alerts). The operating picture may or may not be presented graphically. It involves the collection, analysis (including fusion), and distribution of what is important to know to make decisions about the operation of the system.',
  'operational resilience':
    "The organization’s ability to adapt to risk that affects its core operational capacities. Operational resilience is an emergent property of effective operational risk management, supported and enabled by activities such as security and business continuity. A subset of enterprise resilience, operational resilience focuses on the organization’s ability to manage operational risk, whereas enterprise resilience encompasses additional areas of risk such as business risk and credit risk. See <a href='#' data-location='definition/operational%20risk'>operational risk</a>.",
  'operating states': '>>> predefined states of operation',
  'operational risk':
    'The potential impact on assets and their related services that could result from inadequate or failed internal processes, failures of systems or technology, the deliberate or inadvertent actions of people, or external events. In the context of this model, our focus is on operational risk from cybersecurity threats.',
  'operations technology (OT)':
    'Programmable systems or devices that interact with the physical environment (or manage devices that interact with the physical environment). Examples include industrial control systems, building management systems, fire control systems, and physical access control mechanisms.',
  'operations technology':
    'Programmable systems or devices that interact with the physical environment (or manage devices that interact with the physical environment). Examples include industrial control systems, building management systems, fire control systems, and physical access control mechanisms.',
  OT:
    'Programmable systems or devices that interact with the physical environment (or manage devices that interact with the physical environment). Examples include industrial control systems, building management systems, fire control systems, and physical access control mechanisms.',
  organization:
    'An organization of any size, complexity, or positioning within an organizational structure that is charged with carrying out assigned mission and business processes and that uses IT and OT in support of those processes. In the context of the model, the organization is the entity using the model or that is under examination.',
  'organizational objectives':
    "Performance targets set by an organization. See <a href='#' data-location='definition/strategic%20objectives'>strategic objectives</a>.",
  'periodic review/activity':
    'A review or activity that occurs at specified, regular time intervals, where the organization-defined frequency is commensurate with risks to organizational objectives and critical infrastructure.',
  'periodic review':
    'A review or activity that occurs at specified, regular time intervals, where the organization-defined frequency is commensurate with risks to organizational objectives and critical infrastructure.',
  'periodic activity':
    'A review or activity that occurs at specified, regular time intervals, where the organization-defined frequency is commensurate with risks to organizational objectives and critical infrastructure.',
  'physical control':
    'A type of control that prevents physical access to and modification of information assets or physical access to technology and facilities. Physical controls often include such artifacts as card readers and physical barrier methods.',
  plan: 'A detailed formulation of a program of action.',
  policy:
    'A high-level overall plan embracing the general goals and acceptable procedures of an organization.',
  practice:
    'An activity described in the model that can be performed by an organization to support a domain objective. The purpose of these activities is to achieve and sustain an appropriate level of cybersecurity for the function, commensurate with the risk to critical infrastructure and organizational objectives.',
  'predefined states of operation':
    'Distinct operating modes (which typically include specific IT and OT configurations as well as alternate or modified procedures) that have been designed and implemented for the function and can be invoked by a manual or automated process in response to an event, a changing risk environment, or other sensory and awareness data to provide greater safety, resiliency, reliability, and/or cybersecurity. For example, a shift from the normal state of operation to a high-security operating mode may be invoked in response to a declared cybersecurity incident of sufficient severity. The high-security operating state may trade off efficiency and ease of use in favor of increased security by blocking remote access and requiring a higher level of authentication and authorization for certain commands until a return to the normal state of operation is deemed safe.',
  procedure: 'In this model, procedure is synonymous with process.',
  process:
    'A series of discrete activities or tasks that contribute to the fulfillment of a task or mission.',
  provisioning:
    "The process of assigning or activating an identity profile and its associated roles and access privileges. See <a href='#' data-location='definition/deprovisioning'>deprovisioning</a>.",
  'recovery point objective':
    'Establishes the point to which an information or technology asset (typically an application system) must be restored to allow recovery of the asset and associated services after a disruption.',
  'recovery time objectives':
    'Documented goals and performance targets the organization sets for recovery of an interrupted function in order to meet critical infrastructure and organizational objectives.',
  risk:
    'A measure of the extent to which an organization is threatened by a potential circumstance or event, and typically a function of (1) the adverse impacts that would arise if the circumstance or event occurs and (2) the likelihood of occurrence.',
  'risk analysis':
    'A risk management activity focused on understanding the condition and potential consequences of risk, prioritizing risks, and determining a path for addressing risks. Determines the importance of each identified risk and is used to facilitate the organization’s response to the risk.',
  'risk assessment':
    'The process of identifying risks to organizational operations (including mission, functions, image, and reputation), resources, other organizations, and the Nation, resulting from the operation of IT systems and ICS.',
  'risk criteria':
    'Objective criteria that the organization uses for evaluating, categorizing, and prioritizing operational risks based on areas of impact.',
  'risk designation, as in “position risk designation”':
    'For a position, an indication, such as high, medium, or low, of the position’s potential for adverse impact to the efficiency, integrity, or availability of the organization’s services. ',
  'risk designation':
    'For a position, an indication, such as high, medium, or low, of the position’s potential for adverse impact to the efficiency, integrity, or availability of the organization’s services. ',
  'risk disposition':
    'A statement of the organization’s intention for addressing an operational risk. Typically limited to “accept,” “transfer,” “research,” or “mitigate.”',
  'risk management program':
    'The program and supporting processes to manage cybersecurity risk to organizational operations (including mission, functions, image, and reputation), resources, other organizations, and the Nation. It includes (1) establishing the context for risk-related activities, (2) assessing risk, (3) responding to risk once determined, and (4) monitoring risk over time.',
  'risk management strategy':
    'Strategic-level decisions on how senior executives manage risk to an organization’s operations, resources, and other organizations.',
  'risk mitigation':
    'Prioritizing, evaluating, and implementing appropriate risk-reducing controls.',
  'risk mitigation plan':
    'A strategy for mitigating risk that seeks to minimize the risk to an acceptable level.',
  'risk parameter/risk parameter factors':
    'Organization-specific risk tolerances used for consistent measurement of risk across the organization. Risk parameters include risk tolerances and risk measurement criteria.',
  'risk parameter':
    'Organization-specific risk tolerances used for consistent measurement of risk across the organization. Risk parameters include risk tolerances and risk measurement criteria.',
  'risk parameter factors':
    'Organization-specific risk tolerances used for consistent measurement of risk across the organization. Risk parameters include risk tolerances and risk measurement criteria.',
  'risk register':
    'A structured repository where identified risks are recorded to support risk management.',
  'risk response':
    'Accepting, avoiding, mitigating, sharing, or transferring risk to organizational operations, resources, and other organizations.',
  'risk taxonomy':
    'The collection and cataloging of common risks that the organization is subject to and must manage. The risk taxonomy is a means for communicating these risks and for developing mitigation actions specific to an organizational unit or line of business if operational assets and services are affected by them.',
  role:
    'A group attribute that ties membership to function. When an entity assumes a role, the entity is given certain rights that belong to that role. When the entity leaves the role, those rights are removed. The rights given are consistent with the functionality that the entity needs to perform the expected tasks.',
  'secure software development':
    'Developing software using recognized processes, secure coding standards, best practices, and tools that have been demonstrated to minimize security vulnerabilities in software systems throughout the software development lifecycle. An essential aspect is to engage programmers and software architects who have been trained in secure software development.',
  'separation of duties':
    '[A security control that] “addresses the potential for abuse of authorized privileges and helps to reduce the risk of malevolent activity without collusion. Separation of duties includes, for example: (i) dividing mission functions and information system support functions among different individuals and/or roles; (ii) conducting information system support functions with different individuals (e.g., system management, programming, configuration management, quality assurance and testing, and network security); and (iii) ensuring security personnel administering access control functions do not also administer audit functions. Organizations with significant personnel limitations may compensate for the separation of duty security control by strengthening the audit, accountability, and personnel security controls.”',
  'service level agreement (SLA)':
    'Defines the specific responsibilities of the service provider, including the satisfaction of any relevant cybersecurity requirements, and sets the customer’s expectations regarding the quality of service to be provided.',
  'service level agreement':
    'Defines the specific responsibilities of the service provider, including the satisfaction of any relevant cybersecurity requirements, and sets the customer’s expectations regarding the quality of service to be provided.',
  SLA:
    'Defines the specific responsibilities of the service provider, including the satisfaction of any relevant cybersecurity requirements, and sets the customer’s expectations regarding the quality of service to be provided.',
  'service level agreements':
    'Defines the specific responsibilities of the service provider, including the satisfaction of any relevant cybersecurity requirements, and sets the customer’s expectations regarding the quality of service to be provided.',
  'situational awareness':
    'A sufficiently accurate and up-to-date understanding of the past, current, and projected future state of a system (including its cybersecurity safeguards), in the context of the threat environment and risks to the system’s mission, to support effective decision making with respect to activities that depend on and/or affect how well a system functions. It involves the collection of data (e.g., via sensor networks), data fusion, and data analysis (which may include modeling and simulation) to support automated and/or human decision making (for example, concerning power system functions). Situational awareness also involves the presentation of the results of the data analysis in a form (e.g., using data visualization techniques, appropriate use of alarms) that aids human comprehension and allows operators or other personnel to quickly grasp the key elements needed for good decision making.',
  sponsorship:
    'Enterprise-wide support of cybersecurity objectives by senior management as demonstrated by formal policy or by declarations of management’s commitment to the cybersecurity program along with provision of resources. Senior management monitors the performance and execution of the cybersecurity program and is actively involved in the ongoing improvement of all aspects of the cybersecurity program.',
  stakeholder:
    'An external organization or an internal or external person or group that has a vested interest in the organization or function (that is being evaluated using this model) and its practices. Stakeholders involved in performing a given practice (or who oversee, benefit from, or are dependent upon the quality with which the practice is performed) could include those from within the function, from across the organization, or from outside the organization.',
  standard:
    "A standard is a document, established by consensus, that provides rules, guidelines, or characteristics for activities or their results. See <a href='#' data-location='definition/guidelines'>guidelines</a>.",
  'states of operation': '>>> predefined states of operation',
  'strategic objectives':
    'The performance targets that the organization sets to accomplish its mission, vision, values, and purpose.',
  'strategic planning':
    'The process of developing strategic objectives and plans for meeting these objectives.',
  'supply chain':
    'The set of organizations, people, activities, information, and resources for creating and moving a product or service (including its sub-elements) from suppliers through to an organization’s customers.',
  'supply chain risk':
    'Supply chain risk is measured by the likelihood and severity of damage if an IT or OT system is compromised by a supply chain attack, and takes into account the importance of the system and the impact of compromise on organizational operations and assets, individuals, other organizations, and the Nation.',
  threat:
    'Any circumstance or event with the potential to adversely impact organizational operations (including mission, functions, image, or reputation), resources, and other organizations through IT, OT, or communications infrastructure via unauthorized access, destruction, disclosure, modification of information, and/or denial of service.',
  threats:
    'Any circumstances or events with the potential to adversely impact organizational operations (including mission, functions, image, or reputation), resources, and other organizations through IT, OT, or communications infrastructure via unauthorized access, destruction, disclosure, modification of information, and/or denial of service.',
  'threat assessment':
    'The process of evaluating the severity of threat to an IT system or ICS or organization and describing the nature of the threat.',
  'threat profile':
    'A characterization of the likely intent, capability, and targets for threats to the function. It is the result of one or more threat assessments across the range of feasible threats to the IT and OT of an organization and to the organization itself, delineating the feasible threats, describing the nature of the threats, and evaluating their severity.',
  'threat source':
    'An intent and method targeted at the intentional exploitation of a vulnerability or a situation, or a method that may accidentally exploit a vulnerability.',
  traceability:
    'The ability to determine whether a given attribute of the current state is valid (e.g., the current configuration of a system or the purported identity of a user) based on the evidence maintained in a historical record showing how the attribute was originally established and how it has changed over time.',
  'upstream dependencies':
    'External parties on which the delivery of the function depends, including suppliers and some operating partners.',
  validate:
    'Collect and evaluate evidence to confirm or establish the quality of something (e.g., information, a model, a product, a system, or component) with respect to its fitness for a particular purpose.',
  vulnerability:
    'A cybersecurity vulnerability is a weakness or flaw in IT, OT, or communications systems or devices, system procedures, internal controls, or implementation that could be exploited by a threat source. A vulnerability class is a grouping of common vulnerabilities.',
  'vulnerability assessment':
    'Systematic examination of an IT or product to determine the adequacy of cybersecurity measures, identify security deficiencies, provide data from which to predict the effectiveness of proposed cybersecurity measures, and confirm the adequacy of such measures after implementation.',
  'workforce lifecycle':
    'For the purpose of this model, the workforce lifecycle comprises the distinct phases of workforce management that apply to personnel both internal and external to the organization. Specific cybersecurity implications and requirements are associated with each lifecycle phase. The workforce lifecycle includes recruiting, hiring, onboarding, skill assessments, training and certification, assignment to roles (deployment), professional growth and development, re-assignment and transfers, promotions and demotions, succession planning, and termination or retirement. The phases may not be in strict sequences, and some phases (like training, re-assignment, and promotions) may recur.',
  'workforce management objectives':
    '>>> cybersecurity workforce management objectives',
};
