import { Map as ImmutableMap } from 'immutable';

import { IModelDimension } from 'assessments/reducers/models/model';
import { IResponse } from 'assessments/databaseTypes';

/**
 * Update the address bar and fire the attached callback
 * @param {string} practiceId The string that identifies the practice to navigate to
 * @param {?Function} onUpdate The callback to supply the practiceId to
 * @param {?string} displayHash The string to set the location hash to. If not set the practiceId will be used. This may be a subset of the practiceId (e.g. RM-1 vs .RM.1.a)
 */
export function navigateToPractice(
  practiceId: string,
  onUpdate?: (practiceId: string) => void,
  displayHash?: string
) {
  const newHash = displayHash || practiceId;
  if (window.location.hash === '#' + newHash) {
    // This hack forces the window to actually scroll even if the hash
    // hasn't changed by replacing where the browser thinks it is with
    // somewhere else.  We'll replace it next with the old hash next.
    // Using replaceState doesn't add to the browser stack and
    // doesn't generate a hashchange event.
    window.history.replaceState(null, '', '#' + newHash + '\u2063'); // unicode invisible sep.
  }
  window.location.hash = newHash;
  if (onUpdate) {
    onUpdate(practiceId);
  }
}

/**
 * Sort responses by model order
 * @param {ImmutableMap<string, number>} order An object in which the keys are the practice ids and the values contain their index in an ordered array
 * @param {Key<string>} key The key to sort by e.g., _practice_id
 */

export function createModelOrderSortFn(
  order: ImmutableMap<string, number>,
  key: string
) {
  return function (a, b) {
    return order.get(a[key], -1) - order.get(b[key], -1);
  };
}

/**
 * Sort responses by model order
 * @param {ImmutableMap<string, number>} order An object in which the keys are the practice ids and the values contain their index in an ordered array
 * @param {Array<any>} sortableItems The list of items we are sorting by a specific key
 * @param key
 */
export function sortByModelOrder(
  order: ImmutableMap<string, number>,
  sortableItems: any[],
  key: string
) {
  const sortFn = createModelOrderSortFn(order, key);
  return sortableItems.sort(sortFn);
}

export const areAllPracticeDimensionLevelsSet = (
  dimensions?: IModelDimension[],
  response?: IResponse,
  mode: 'level' | 'targetLevel' = 'level',
  disabledDimensions: string[] = []
) => {
  // Filter out disabled dimensions
  const enabledDimensions =
    dimensions?.filter(
      (dimension) => !disabledDimensions.includes(dimension.key)
    ) ?? [];

  // Check for consistency between the expected and available dimensions
  const hasExpectedNumberOfDimensions =
    dimensions?.length === enabledDimensions.length + disabledDimensions.length;

  // Function to check if a level is considered set based on the practiceLevels definition
  const isLevelSet = (responseDimension, modelDimension) => {
    // Retrieve the response level based on the mode ('level' or 'targetLevel')
    const levelValue = responseDimension[mode];

    // Check if this level is defined within the modelDimension's practiceLevels
    return modelDimension.practiceLevels.some(
      (level) => level.value === levelValue
    );
  };

  /*
   * All dimensions are set iff:
   * The response contains a valid level for each enabled dimension based on the practiceLevels.
   */
  return enabledDimensions.length && hasExpectedNumberOfDimensions
    ? enabledDimensions.every((modelDimension) =>
        response?.dimensions?.some(
          (responseDimension) =>
            responseDimension.key === modelDimension.key &&
            isLevelSet(responseDimension, modelDimension)
        )
      )
    : false;
};

export const areAllDimensionLevelsSet = (
  dimensions?: IModelDimension[],
  response?: IResponse,
  mode: 'level' | 'targetLevel' = 'level',
  disabledDimensions: string[] = []
) => {
  const enabledDimensions =
    dimensions?.filter(
      (dimension) => !disabledDimensions.includes(dimension.key)
    ) ?? [];

  const hasExpectedNumberOfDomains =
    dimensions?.length === enabledDimensions.length + disabledDimensions.length;

  /*
   * All dimensions are set iff:
   * The response contains a valid level (-2 or >= 0) for each enabled dimension
   */
  return enabledDimensions.length && hasExpectedNumberOfDomains
    ? enabledDimensions.every((modelDimension) =>
        response?.dimensions?.some(
          (responseDimension) =>
            responseDimension.key === modelDimension.key &&
            ((responseDimension?.[mode] ?? -1) >= 0 ||
              responseDimension?.[mode] === -2) // Special-case for N/A values
        )
      )
    : false;
};
