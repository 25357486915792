import { Button, Callout, ControlGroup, FormGroup } from '@blueprintjs/core';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import styled, { withTheme } from 'styled-components/macro';

import { AnimatedEllipsisWithPrefix } from 'common/components/AnimatedEllipsis';
import { getSsoStatus, useUser } from 'common/utils/userContext';
import { IThemeInterface } from 'themes';
import {
  AuthForm,
  AuthPage,
  BottomLink,
  BottomLinks,
  LoginInput,
} from './shared';

const NotificationWrapper = styled.div<{ show: boolean }>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  .bp3-callout {
    background-color: transparent;
    color: white;
    text-align: center;
  }
`;

export const LoginPage = (
  props: RouteComponentProps<
    {},
    StaticContext,
    { email?: string; from?: Location }
  > & {
    theme: IThemeInterface;
  }
) => {
  const emailFromSearch = new URLSearchParams(props.location.search).get(
    'email'
  );
  const initialFormValues = {
    email: emailFromSearch || '',
    password: '',
  };
  localStorage.removeItem('loginStatus');
  const { login } = useUser();
  const [error, setError] = useState('');
  const onSubmit = async (
    { email, password }: typeof initialFormValues,
    { setSubmitting }: FormikHelpers<typeof initialFormValues>
  ) => {
    setError('');
    const hasSso = await getSsoStatus(email);
    if (hasSso) {
      props.history.replace({
        pathname: 'authentication/sso',
        state: {
          email,
        },
        search: `email=${encodeURIComponent(email)}&info=true`,
      });
    } else {
      try {
        await login(email, password);
        const redirectTo = (props.location.state &&
          props.location.state.from) || { pathname: '/' };
        props.history.replace(redirectTo);
      } catch (err: any) {
        if (err.code === 'SmsConfirmationRequiredException') {
          // MFA is enabled, sign-in should be confirmed with the confirmation code
          props.history.replace({
            pathname: '/authentication/mfa',
            state: {
              email,
              from: props.location.state && props.location.state.from,
            },
          });
        } else if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          props.history.replace({
            pathname: '/authentication/verify',
            state: {
              email,
            },
          });
        } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          props.history.replace({
            pathname: '/authentication/verifyreset',
            state: {
              email,
            },
          });
        } else if (err.code === 'NewPasswordRequiredException') {
          props.history.replace({
            pathname: '/authentication/newpassword',
            state: {
              email,
            },
          });
        } else {
          setError(err.message);
          setSubmitting(false);
        }
      }
    }
  };

  return (
    <AuthPage
      welcomeText={props.theme.authWelcomeText}
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<typeof initialFormValues>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, handleSubmit, handleChange }) => {
          const ssoRoute = {
            pathname: 'authentication/sso',
            search: values.email
              ? `email=${encodeURIComponent(values.email)}`
              : '',
          };
          return (
            <AuthForm onSubmit={handleSubmit}>
              {!!emailFromSearch && !error && (
                <Callout intent="success">
                  Please enter your password for {emailFromSearch}
                </Callout>
              )}
              <FormGroup>
                <LoginInput
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email address"
                  data-test="Email Input"
                  disabled={isSubmitting}
                  required
                  large
                />
              </FormGroup>
              <FormGroup>
                <ControlGroup fill>
                  <LoginInput
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Password"
                    data-test="Password Input"
                    disabled={isSubmitting}
                    required
                    large
                  />
                </ControlGroup>
              </FormGroup>
              <Button
                type="submit"
                data-test="Submit Login"
                disabled={isSubmitting}
                text={
                  isSubmitting ? (
                    <AnimatedEllipsisWithPrefix
                      prefix="Loading&nbsp;"
                      ellipsisStyle={{ color: 'inherit' }}
                    />
                  ) : (
                    'Sign In'
                  )
                }
                fill
                large
              />
              <BottomLinks smallMargins>
                <BottomLink isWhite to="authentication/register">
                  Register
                </BottomLink>
                <BottomLink isWhite to="authentication/reset">
                  Forgot password
                </BottomLink>
                <BottomLink isWhite to={ssoRoute}>
                  Sign in with SSO
                </BottomLink>
              </BottomLinks>

              {!!error && (
                <FormGroup>
                  <Callout data-test="auth-error" intent="danger">
                    {error}
                  </Callout>
                </FormGroup>
              )}
              <ReactIsCapsLockActive>
                {(active) => (
                  <NotificationWrapper show={active}>
                    <Callout>
                      <span>Caps Lock is on</span>
                    </Callout>
                  </NotificationWrapper>
                )}
              </ReactIsCapsLockActive>
            </AuthForm>
          );
        }}
      </Formik>
    </AuthPage>
  );
};

export const LoginForm = withTheme(LoginPage);
