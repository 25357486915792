export default {
  questions: [
    {
      number: '1',
      title: 'Cyber Asset Inventory',
      text:
        'A cybersecurity program needs to understand and control the IT, OT, and information assets that are necessary to sustain operations. Assets might be systems devices, including traditional IT, such as computers, routers, and servers, but might also include OT equipment such as programmable logic controllers (PLCs) and other control system elements. Also, inventories need to be kept up to date throughout the lifecycle of such assets.',
      prompt:
        'Please select all the responses that best describe your current asset management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '1.a',
          practice: '.ACM.1.a',
          text:
            'We have an inventory of the IT and OT assets needed for operations, including computers and other system devices',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.b',
          practice: '.ACM.2.a',
          text:
            'We have an inventory of the important data such as SCADA set points, customer information, and financial data ',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.c',
          practice: '.ACM.4.a',
          text: 'We evaluate and approve changes prior to implementation',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.d',
          practice: '.ACM.4.b',
          text: 'We document changes that are made to inventoried assets',
          exclusive: false,
          level: 3,
        },
        {
          number: '1.e',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '2',
      title: 'Configuration Baselines',
      text:
        'To help keep track of changes to IT and OT assets, many organizations establish configuration baselines that define the software, hardware, and settings for these devices. Baselines can be used to set up new assets consistently, identify unauthorized or unapproved changes to an asset, and reset or restore an asset when needed.',
      prompt:
        'Please select all the responses that best describe how your organization currently uses configuration baselines. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '2.a',
          practice: '.ACM.3.a',
          text:
            'We establish configuration baselines where assets need to be configured in the same way',
          exclusive: false,
          level: 3,
        },
        {
          number: '2.b',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '3',
      title: 'Vulnerability Management',
      text:
        'Cybersecurity vulnerabilities are weaknesses or flaws in IT or OT systems (or in the procedures or controls used to protect those systems) that can be leveraged by adversaries. Managing these vulnerabilities is an important security protection. The most common vulnerability management techniques include regular patching cycles and network isolation.',
      prompt:
        'Please select all the responses that best describe your current cybersecurity vulnerability management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '3.a',
          practice: '.TVM.1.a',
          text:
            'We have identified sources for vulnerability information associated with our IT and OT systems and assets',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.b',
          practice: '.TVM.1.b',
          text:
            'We gather information on vulnerabilities and review it for applicability to our assets and systems ',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.c',
          practice: '.TVM.1.c',
          text: 'We perform vulnerability assessments',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.d',
          practice: '.TVM.1.d',
          text:
            'We address important vulnerabilities (e.g. by implementing patches or other changes)',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.e',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '4',
      title: 'Threat Management',
      text:
        'Wholly distinct from vulnerabilities, cybersecurity threats are adversaries with potential capability and motive to affect an organization through cyber means. Cybersecurity threats can also be events that would cause harm to the organization.\n\nA common way to describe the relationship between threats and vulnerabilities is that threats (such as hackers) use vulnerabilities (such as system weaknesses) to attack organizations. Threat management activities include being aware of threats that are relevant to your sector, your region, or specific types of assets that you have. They might also include review of recent events and analysis of how an event could be applicable to your organization.',
      prompt:
        'Please select all the responses that best describe your current cybersecurity threat management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '4.a',
          practice: '.TVM.2.a',
          text:
            'We have identified internal and external sources for threat information',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.b',
          practice: '.TVM.2.b',
          text:
            'We gather threat information and review it for applicability to our organization',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.c',
          practice: '.TVM.2.c',
          text:
            'We have identified the objectives of potential threat actors that are most relevant for our organization',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.d',
          practice: '.TVM.2.d',
          text:
            'We address important threats (including strengthening our security protections, increasing monitoring activities, and/or raising awareness throughout our organization)',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.e',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '5',
      title: 'Cyber Risk Management',
      text:
        'Cybersecurity risk is the potential harm to an organization or its operations that could arise from unauthorized disclosure of  sensitive information, misuse or loss of IT or OT systems, and other cyber incidents. Dependence on technology has resulted in an increase in cybersecurity risk.\n\nRisk is often viewed as the product of vulnerabilities, threats, and impacts. Vulnerability assessments are informative for analyzing weaknesses, but if there is no threat that can exploit the weakness, or if there is no way to have negative impacts due to that weakness, it may not present a notable risk. ',
      prompt:
        'Please select the responses that most closely represent how your organization currently manages cybersecurity risk. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '5.a',
          practice: '.RM.1.a',
          text: 'We have a cyber risk management strategy',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.b',
          practice: '.RM.2.a',
          text: 'We identify cyber risks as they apply to our organization',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.c',
          practice: '.RM.3.a',
          text: 'We prioritize cyber risks based on estimated impact',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.d',
          practice: '.RM.4.a',
          text: 'We mitigate, accept or transfer those identified risks',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.e',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '6',
      title: 'Logical Access',
      text:
        'Controlling logical access to IT and OT assets and systems is an important step in securing the operating environment. This includes the process of creating user accounts and passwords, determining the requirements for access, and removing access when it is longer required.',
      prompt:
        'Please select the responses that best describe your logical access management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '6.a',
          practice: '.IAM.1.a',
          text:
            'We create accounts for people and application services that require access to our IT and OT systems. These may include accounts that are shared by multiple entities',
          exclusive: false,
          level: 3,
        },
        {
          number: '6.b',
          practice: '.IAM.1.b',
          text:
            'We require the use of credentials for access, such as password, smart cards, or security tokens for electronic access',
          exclusive: false,
          level: 3,
        },
        {
          number: '6.c',
          practice: '.IAM.1.c',
          text:
            'We remove individual and shared accounts when they are no longer needed',
          exclusive: false,
          level: 3,
        },
        {
          number: '6.d',
          practice: '.IAM.2.a',
          text:
            'We use access requirements to determine who is granted access and what they can access',
          exclusive: false,
          level: 3,
        },
        {
          number: '6.e',
          practice: '.IAM.2.b',
          text:
            'We remove individual and shared accounts when they are no longer needed',
          exclusive: false,
          level: 3,
        },
        {
          number: '6.f',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '7',
      title: 'Physical Access',
      text:
        'Controlling physical access is important to securing IT, OT, and systems is information assets. This includes the use of credentials for physical access such as badges or physical keys, the uses controls such as fences, locks and signage, and revoking access when no longer required.',
      prompt:
        'Please select the responses that best describe your physical access management activities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '7.a',
          practice: '.IAM.3.a',
          text:
            'We control physical access (such as fences, locks, and signage)',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.b',
          practice: '.IAM.3.b',
          text: 'We revoke physical access when no longer needed',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.c',
          practice: '.IAM.3.c',
          text: 'We maintain physical access logs',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.d',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '8',
      title: 'Monitoring Cyber System Activity',
      text:
        'Logging and monitoring of IT and OT systems is a vital capability for detecting cyber events or incidents and for capturing information that can be used to analyze an event or incident. These capabilities may not be possible, however, for certain pieces of equipment based on your IT and OT environment.',
      prompt:
        'Please select the responses that best describe your security event logging and monitoring capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '8.a',
          practice: '.SA.1.a',
          text:
            'We have enabled logging on assets that are need for critical operations where possible',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.b',
          practice: '.SA.2.a',
          text:
            'We review our logs periodically, at least manually or have a third party monitor and notify us',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.c',
          practice: '.SA.2.b',
          text:
            'We monitor our operations for irregular or anomalous activity as indicators of a cybersecurity incident',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.d',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '9',
      title: 'Cyber Event Detection',
      text:
        "A cybersecurity event is any occurrence that has a potential impact to the cybersecurity of the organization's IT or OT systems. Such events are often relatively minor (e.g., forgotten passwords), but can be escalating (e.g., an increasing number of users are unable to log in), or major (e.g., a network outage is preventing communications to remote assets). Detecting cybersecurity events requires knowledge of IT and OT assets and systems, as well as defined roles and capabilities to track events.\n\nEvents that have the potential to significantly impact the organization are declared to be incidents and require a response to minimize the impact to operations or restore functionality.",
      prompt:
        'Please select the responses that best describe your cybersecurity event detection activities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '9.a',
          practice: '.IR.1.a',
          text:
            'We have a point of contact to whom cybersecurity events can be reported. (This might be an IT help desk or any designated person or role',
          exclusive: false,
          level: 3,
        },
        {
          number: '9.b',
          practice: '.IR.2.a',
          text:
            'We have criteria to determine whether cybersecurity events should be declared incidents',
          exclusive: false,
          level: 3,
        },
        {
          number: '9.c',
          practice: '.IR.2.b',
          text:
            'We analyze cybersecurity events to determine whether they should be declared incidents',
          exclusive: false,
          level: 3,
        },
        {
          number: '9.d',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '10',
      title: 'Cyber Incident Response ',
      text:
        'As previously mentioned, cybersecurity events can escalate to cybersecurity incidents. Incidents have the potential to significantly impact the organization and require a response to minimize the impact to operations or restore functionality. Incident response capabilities require preplanning and knowledge of both security and engineering/operations.',
      prompt:
        'Please select the responses that best describe your cybersecurity incident response activities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '10.a',
          practice: '.IR.3.a',
          text:
            'We have a cybersecurity incident response team (or teams, or designated person/role)',
          exclusive: false,
          level: 3,
        },
        {
          number: '10.b',
          practice: '.IR.3.b',
          text:
            'We respond to cybersecurity incidents to limit the impact and restore normal operations',
          exclusive: false,
          level: 3,
        },
        {
          number: '10.c',
          practice: '.IR.3.c',
          text:
            'We report (or would report) cybersecurity incidents internally and/or to appropriate external trusted parties such as a relevant ISAC',
          exclusive: false,
          level: 3,
        },
        {
          number: '10.d',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '11',
      title: 'Continuity of Operations',
      text:
        'Part of responding to a cybersecurity incident or other disruptive events is sustaining at least minimal operations while returning to normal operations. Doing so requires knowledge of the systems needed to sustain minimal operations and having backups and spares available.',
      prompt:
        'Please select the responses that best describe your capabilities for sustaining and restoring operations. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '11.a',
          practice: '.IR.4.a',
          text:
            'We have developed continuity plans for how to sustain and restore operations',
          exclusive: false,
          level: 3,
        },
        {
          number: '11.b',
          practice: '.IR.4.b',
          text: 'We back up our data and test our backups',
          exclusive: false,
          level: 3,
        },
        {
          number: '11.c',
          practice: '.IR.4.c',
          text: 'We identify IT and OT assets that require spares',
          exclusive: false,
          level: 3,
        },
        {
          number: '11.d',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '12',
      title: 'Third Party Risk Management ',
      text:
        'As the interdependencies among infrastructures, operating partners, suppliers, and service providers increase, establishing and maintaining a comprehensive understanding of key relationships and managing their associated cyber risks are essential for the secure, reliable, and resilient delivery of the products and services. ',
      prompt:
        "Please select the responses that best describe your organization's third party risk management activities. Keep in mind that the activities may be performed in an ad hoc manner.",
      responses: [
        {
          number: '12.a',
          practice: '.TP.1.a',
          text:
            'We know our important third party dependencies (that is, internal and external parties on which the delivery of the function depends, including operating partners)',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.b',
          practice: '.TP.1.b',
          text:
            'We know which third parties have access to, control of, or custody of our important IT, OT, and information assets',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.c',
          practice: '.TP.2.a',
          text:
            'We consider cybersecurity qualification when selecting suppliers and third parties ',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.d',
          practice: '.TP.2.b',
          text:
            'We consider cybersecurity capabilities when selecting products and services',
          exclusive: false,
          level: 3,
        },
        {
          number: '12.e',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '13',
      title: 'Workforce Management and Cybersecurity Training',
      text:
        'Despite all the technical discussions surrounding cybersecurity, employees and contractors represent some of the biggest risks to a security program. How workforce and cybersecurity training are managed has a significant impact on organizational security.',
      prompt:
        'Please select the responses that best describe your cybersecurity workforce capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '13.a',
          practice: '.WM.1.a',
          text:
            'We perform personnel vetting, such as background checks and drug tests, when we hire employees and contractors ',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.b',
          practice: '.WM.1.b',
          text:
            'We address cybersecurity concerns during our employee terminations, such as removing physical and electronic access',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.c',
          practice: '.WM.2.a',
          text:
            'We have cybersecurity awareness activities for all employees and contractors',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.d',
          practice: '.WM.3.a',
          text:
            'We have identified cybersecurity responsibilities need for our organization',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.e',
          practice: '.WM.3.b',
          text:
            'We have assigned cybersecurity responsibilities for specific people',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.f',
          practice: '.WM.4.a',
          text:
            'We provide cybersecurity training to the employees and contractors with cybersecurity responsibilities',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.g',
          practice: '.WM.4.b',
          text:
            'We identify cybersecurity knowledge, skill, and ability requirements and gaps ',
          exclusive: false,
          level: 3,
        },
        {
          number: '13.h',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '14',
      title: 'Cybersecurity Architecture ',
      text:
        'The cybersecurity architecture helps an organization plan for how security is to be engineered in a way that transcends specific solutions and enables an organization to detect, resist, react to, and recover from attacks. The cybersecurity architecture should be comprehensive of organizational systems and include consideration for network protections, end-point protections, data security, and secure software development practices. ',
      prompt:
        'Please select the responses that best describe your cybersecurity architecture capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '14.a',
          practice: '.ARCH.1.a',
          text: 'We have a strategy for cybersecurity architecture',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.b',
          practice: '.ARCH.2.a',
          text: 'We have network protections in place',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.c',
          practice: '.ARCH.2.b',
          text: 'We segment our IT systems from our OT systems',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.d',
          practice: '.ARCH.3.a',
          text: 'We implement cybersecurity controls on important assets',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.e',
          practice: '.ARCH.3.b',
          text:
            'We have endpoint protections such as secure configuration, security applications, and host monitoring',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.f',
          practice: '.ARCH.5.a',
          text: 'We protect sensitive data at rest',
          exclusive: false,
          level: 3,
        },
        {
          number: '14.g',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
    {
      number: '15',
      title: 'Cyber Program Management ',
      text:
        'A cybersecurity program is a managed set of activities designed to provide governance for the organization. Such a program typically includes objectives for improving cybersecurity over time and a foundational strategy for managing cybersecurity and would provide leadership and resources for cybersecurity activities.',
      prompt:
        'Please select the responses that best describe your cybersecurity program capabilities. Keep in mind that the activities may be performed in an ad hoc manner.',
      responses: [
        {
          number: '15.a',
          practice: '.CPM.1.a',
          text: 'We have a strategy for our cybersecurity program',
          exclusive: false,
          level: 3,
        },
        {
          number: '15.b',
          practice: '.CPM.2.a',
          text:
            'We have senior management sponsorship for our cybersecurity program',
          exclusive: false,
          level: 3,
        },
        {
          number: '15.c',
          text: 'None of the above.',
          exclusive: true,
          level: 3,
        },
      ],
    },
  ],
};
