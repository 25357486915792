/**
 * Manages a map of terms and their definitions.  It also supports the functions necessary for noticing
 * terms exist in a string of text and wrapping them in an indicator.  Another method converts text
 * wrapped in an indicator into a link for displaying the term.
 *
 */
import TriePrefixTree from 'trie-prefix-tree';

/**
 * A class for managing a list of terms and their definitions.  It supports loading json that contains
 * the terms, parsing a string to look for those terms - returning delimiters as necesary and
 * generating an array of html (text and terms wrapped in a component) based on the terms
 *
 * @constructor Dictionary
 * @param  {string|Object}  The path to a json file containing the defined terms or the json object itself
 * @return {Object}  The constructed object
 */
export class Trie {
  tree: any;

  constructor(words: string[]) {
    this.tree = TriePrefixTree(words);
  }

  search(text: string) {
    let candidateEndPos = 1;
    const candidates = [] as string[];
    while (candidateEndPos <= text.length) {
      const candidate = text.slice(0, candidateEndPos);
      if (this.tree.isPrefix(candidate)) {
        candidates.push(candidate); // keep a list
        candidateEndPos++;
      } else {
        break;
      }
    }

    for (let i = candidates.length - 1; i > 0; i--) {
      // checking to see if candidate is a word
      const candidate = candidates[i];
      if (this.tree.hasWord(candidate)) {
        return candidate;
      }
    }

    return undefined;
  }
}
