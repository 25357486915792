import moment from 'moment';
import { get as _get } from 'lodash';
import { matchPath } from 'react-router';
import { AnyAction, Reducer } from 'redux';
import ReactGA from 'react-ga';
import { Map } from 'immutable';

import { networkErrorCodes } from 'common/actions/persistence';
import { IAssessmentFactory } from 'assessments/reducers/models/model';
import { TIME_FORMAT } from 'common/utils/DateUtils';
import { AssessmentModes, IResponse } from '../databaseTypes';
import { AddAssessmentPayload } from 'common/graphql/graphql-hooks';
import { NewAssessmentDialogDetailsState } from 'assessments/components/Dialogs/NewAssessmentDialog';

export enum AssessmentView {
  FULL = 1,
  QUICKLAUNCH = 2,
  WIZARD = 3,
}

export enum SubscriptionState {
  UNSUBSCRIBED = 'unsubscribed',
  SUBSCRIBING = 'subscribing',
  SUBSCRIBED = 'subscribed',
}

export interface IAppState {
  mode: AssessmentModes;
  view: string;
  subscribed: SubscriptionState;
  networkConnected: boolean;
  networkSaving: boolean;
  showAcknowledge: boolean;
  archivedNoteAssessmentIdList: string[];
  showActionItemsDetail: boolean;
  showBoardReport: boolean;
  showSSPReport: boolean;
  showPOAMReport: boolean;
  showInsurabilityReport: boolean;
  showConvertToFull: boolean;
  showCopyTargetLevels: boolean;
  showDeleteAssessment: boolean;
  showDeleteGrantee: boolean;
  showExportToXLSX: boolean;
  showDeleteMilestone: boolean;
  showMilestone: boolean;
  showNewAssessment: boolean;
  showNISTComparisonDetail: boolean;
  showReferencing: boolean;
  showDetails: boolean;
  showActivityLog: boolean;
  showSharing: boolean;
  showUpcomingTargetsDetail: boolean;
  showUploadExcelAssessment: boolean;
  showUser: boolean;
  showCompanyQuestions: boolean;
  showModelLimit: boolean;
  showNotes: boolean;
  showPreQuestionnaire: boolean;
  showPowerPointReportLoadingDialog: boolean;
  tocExpanded: boolean;
  saveMessage: string;
  saveMessageClass: string;
  errorMessage?: string;
  descriptumHistory: string[];
  aboutToLogout: boolean;
  progress: object;
  comparisonAssessmentId: string;
  newMilestoneRequest: boolean;
  referenceAssessmentIds: string[];
  selectedAssessmentId: string;
  lastSelectedAssessmentId: string;
  selectedAssessmentFactory?: IAssessmentFactory | null;
  selectedSubsidiaryId: string;
  filterCriteria: string;
  assessmentSort: string[];
  selectedMilestoneId: string;
  selectedMilestoneForEditId: string;
  selectedPracticeId: string;
  selectedDimensionKey: string | null;
  sessionSelectedAssessments: Map<string, string | undefined>;
  showBitsight: boolean;
  showDomainLevelBenchmarksDialog: boolean;
  originalResponses: Map<string, IResponse | undefined>;
  newAssessment?: NewAssessmentDialogDetailsState | null;
  dialogProps?: Record<string, any> | null;
}

const initialState: IAppState = {
  mode: AssessmentModes.ASSESSMENT,
  view: '',
  subscribed: SubscriptionState.UNSUBSCRIBED,
  networkConnected: false,
  networkSaving: false,
  newMilestoneRequest: false,
  showAcknowledge: false,
  showActionItemsDetail: false,
  showBoardReport: false,
  archivedNoteAssessmentIdList: [],
  showConvertToFull: false,
  showExportToXLSX: false,
  showCopyTargetLevels: false,
  showDeleteAssessment: false,
  showDeleteGrantee: false,
  showDeleteMilestone: false,
  showMilestone: false,
  showNewAssessment: false,
  showSSPReport: false,
  showPOAMReport: false,
  showInsurabilityReport: false,
  showNISTComparisonDetail: false,
  showReferencing: false,
  showDetails: false,
  showActivityLog: false,
  showSharing: false,
  showUpcomingTargetsDetail: false,
  showUploadExcelAssessment: false,
  showUser: false,
  showCompanyQuestions: false,
  showNotes: false,
  showPreQuestionnaire: false,
  showPowerPointReportLoadingDialog: false,
  tocExpanded: true,
  selectedPracticeId: '',
  saveMessage: '',
  saveMessageClass: '',
  aboutToLogout: false,
  progress: {},
  selectedAssessmentId: '',
  lastSelectedAssessmentId: '',
  filterCriteria: '',
  assessmentSort: [],
  comparisonAssessmentId: '',
  selectedMilestoneId: '',
  selectedMilestoneForEditId: '',
  selectedSubsidiaryId: '',
  selectedDimensionKey: null,
  sessionSelectedAssessments: Map(),
  descriptumHistory: [],
  referenceAssessmentIds: [],
  showBitsight: false,
  showModelLimit: false,
  showDomainLevelBenchmarksDialog: false,
  originalResponses: Map(),
  newAssessment: null,
};

// This is a function rather than an immediate object, because progress is
// mutated in this reducer and we need to replace it with a fresh object
// whenever the state is reset.
export const getInitialState = (overrides: Partial<IAppState> = {}) => ({
  ...initialState,
  ...overrides,
});

function isResourceUpdateError(actionType: string): boolean {
  return (
    actionType.startsWith('error/post_resource') ||
    actionType.startsWith('error/patch_resource') ||
    actionType.startsWith('error/multi_patch_resource')
  );
}

function itemTypeFromActionType(actionType: string): string {
  const parts = actionType.split('/');
  let resource = parts[parts.length - 1];
  if (resource.endsWith('s')) {
    resource = resource.slice(0, -1);
  }
  return resource;
}

// We want to explicitly set progress to a new object because a shallow copy
export function getApp(state: IAppState) {
  return state;
}

export function getIsSubscribed(state: IAppState) {
  return state.subscribed === SubscriptionState.SUBSCRIBED;
}

function shouldRaiseError(action: AnyAction) {
  const isPost = action.type.indexOf('error/post_resource') === 0;
  // we expect errors caused by 401/409/412 to be automatically retried
  // We want to prevent error flashing during these retries
  return (
    action &&
    action.error &&
    action.error.code !== networkErrorCodes.UNAUTHORIZED &&
    action.type !== 'COGNITO_LOGIN_FAILURE' &&
    action.error.message !== 'xhr poll error' &&
    ((!isPost &&
      action.error.code !== networkErrorCodes.PRECONDITION_FAILURE) ||
      (isPost && action.error.code !== networkErrorCodes.CONFLICT))
  );
}

function retryErrorMessage(state: IAppState) {
  return state.networkConnected
    ? "The server isn't responding.  Retrying"
    : "The server isn't connected.  Please contact support at support@axio.com";
}

const getSelectedAssessmentIdFromPath = (pathname: string) => {
  const match = matchPath<{ assessmentId: string }>(pathname, {
    path: '/assessments/*/:assessmentId',
  });
  return match && match.params ? match.params.assessmentId : '';
};

export const appReducer: Reducer<IAppState> = (
  state = getInitialState(),
  action = { type: 'FAKE_ACTION' }
) => {
  // Any change to the assessment invalidates our cached url, no matter what the action is.
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const pathname = _get(action, ['payload', 'location', 'pathname'], '');
      const newId = pathname && getSelectedAssessmentIdFromPath(pathname);

      // notify our analytics boi when we change where we're at
      ReactGA.set({
        location: window.location.href,
      });
      if (window.location.hash) {
        ReactGA.pageview(window.location.pathname + '#' + window.location.hash);
      } else {
        ReactGA.pageview(window.location.pathname);
      }

      if (!newId) {
        return state;
      }
      if (pathname.includes('assessments/assessment')) {
        return {
          ...state,
          showAcknowledge: true,
          selectedAssessmentId: newId,
          lastSelectedAssessmentId: newId,
        };
      }
      return {
        ...state,
        selectedAssessmentId: newId,
        lastSelectedAssessmentId: newId,
      };
    }

    case 'IDLE_USER_IS_BACK':
      return {
        ...state,
        aboutToLogout: false,
      };
    case 'IDLE_USER_INACTIVE':
      return {
        ...state,
        aboutToLogout: true,
      };

    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        saveMessage: action.errorMessage || '',
        saveMessageClass: action.errorClass || '',
      };
    case 'CLEAR_ERROR_MESSAGE':
      return {
        ...state,
        saveMessage: '',
        saveMessageClass: '',
      };
    case 'APOLLO_MUTATION_RESULT': {
      if (action.result.errors) {
        return {
          ...state,
          saveMessage: action.result.errors[0].message || 'Update failed',
          saveMessageClass: 'error',
        };
      }
      switch (action.operationName) {
        case 'AddAssessment': {
          const result: AddAssessmentPayload | undefined =
            action.result?.data?.addAssessment;
          return result?.assessment
            ? {
                ...state,
                selectedAssessmentId: result.assessment.id,
              }
            : state;
        }
      }
      break;
    }
    case 'TOGGLE_DESCRIPTUM':
      if (
        state.descriptumHistory[state.descriptumHistory.length - 1] !==
        action.descriptum
      ) {
        return {
          ...state,
          descriptum: action.descriptum,
          descriptumHistory: [...state.descriptumHistory, action.descriptum],
        };
      }
    /* Else fall through and pop */
    case 'REMOVE_DESCRIPTUM':
      return {
        ...state,
        descriptum: state.descriptumHistory[state.descriptumHistory.length - 2],
        descriptumHistory: state.descriptumHistory.slice(0, -1),
      };
    case 'CLEAR_DESCRIPTUM_HISTORY':
      return {
        ...state,
        descriptum: undefined,
        descriptumHistory: [],
      };

    case 'SUBSCRIBING': // fall through
      // This action starts the subscription process.  We'll set our state to
      return { ...state, subscribed: SubscriptionState.SUBSCRIBING };

    case 'NETWORK_SAVE_START':
      return { ...state, networkSaving: true };
    case 'NETWORK_GENERIC_RETRY':
      if (state.saveMessageClass !== 'error') {
        return {
          ...state,
          saveMessage: retryErrorMessage(state),
          saveMessageClass: 'error',
        };
      }
      return state;
    case 'NETWORK_TIMEOUT':
      return {
        ...state,
        networkSaving: false,
        saveMessage: 'Network timeout',
        saveMessageClass: 'error',
      };
    case 'NETWORK_SAVE_END':
      return { ...state, networkSaving: false };

    case 'subscribe': {
      const isAssessmentNew =
        action.response &&
        action.response.assessment &&
        action.response.assessment.new;
      const showDetails = isAssessmentNew || state.showDetails;
      return {
        ...state,
        showDetails,
        subscribed: SubscriptionState.SUBSCRIBED,
      };
    }
    case 'resubscribe':
      return {
        ...state,
        subscribed: SubscriptionState.SUBSCRIBED,
      };
    case 'error/subscribe':
      return {
        ...state,
        saveMessage: (action.error || {}).message || '',
        saveMessageClass: 'error',
      };

    case 'ASSESSMENT_LOAD_START':
      // Don't change the selected assessment so we don't
      // force a redraw
      return getInitialState({
        subscribed: state.subscribed,
        selectedAssessmentId: state.selectedAssessmentId,
        showBitsight: state.showBitsight,
        selectedAssessmentFactory: state.selectedAssessmentFactory,
        lastSelectedAssessmentId: state.selectedAssessmentId,
        comparisonAssessmentId: state.comparisonAssessmentId,
        selectedMilestoneId: state.selectedMilestoneId,
        networkConnected: !!state.networkConnected,
        sessionSelectedAssessments: state.sessionSelectedAssessments,
      });

    case 'server/unsubscribe':
    case 'server/subscribe': {
      const subState =
        action.type === 'server/unsubscribe'
          ? SubscriptionState.UNSUBSCRIBED
          : SubscriptionState.SUBSCRIBING;
      return getInitialState({
        subscribed: subState,
        selectedAssessmentId: state.selectedAssessmentId,
        lastSelectedAssessmentId: state.selectedAssessmentId,
        comparisonAssessmentId: state.comparisonAssessmentId,
        selectedMilestoneId: state.selectedMilestoneId,
        saveMessageClass: state.saveMessageClass || '',
        saveMessage: state.saveMessage || '',
        networkConnected: !!state.networkConnected,
        showBitsight: state.showBitsight,
        showAcknowledge: state.showAcknowledge,
        originalResponses: state.originalResponses,
        sessionSelectedAssessments: state.sessionSelectedAssessments,
      });
    }

    case 'COGNITO_LOGIN': // Fall through
    case 'COGNITO_LOGOUT': // Fall through
      return getInitialState({
        networkConnected: !!state.networkConnected,
        showAcknowledge: state.showAcknowledge,
      });

    case 'NEW_MILESTONE_REQUEST':
      return {
        ...state,
        saveMessage: '',
        saveMessageClass: '',
        newMilestoneRequest: true,
        showMilestone: true,
      };

    case 'error/post_resource/assessments':
      if (action.error.code === networkErrorCodes.UNPROCESSABLE_ENTITY) {
        return {
          ...state,
          showModelLimit: true,
          saveMessage: '',
          saveMessageClass: '',
        };
      }
      break;
    case 'TOGGLE_DIALOG': // Fall through
    case 'SHOW_HIDE_DIALOG': {
      const prop = `show${action.name}`;
      const show =
        action.type === 'SHOW_HIDE_DIALOG' ? action.show : !state[prop];
      // Showing or hiding the dialog will also clear whatever message the user is looking at.
      return {
        ...state,
        newMilestoneRequest: false,
        [prop]: show,
        saveMessage: '',
        saveMessageClass: '',
        dialogProps: action.payload,
      };
    }
    case 'CHANGE_ASSESSMENT_MODE':
      return { ...state, mode: action.mode };
    case 'CHANGE_ASSESSMENT_TYPE':
      return { ...state, type: action.assessmentType };
    case 'CHANGE_TOC_EXPANSION':
      return { ...state, tocExpanded: action.expanded };
    case 'SET_REFERENCE_ASSESSMENTS':
      return { ...state, referenceAssessmentIds: action.assessmentIds };
    case 'UPDATE_SELECTED_DIMENSION':
      return {
        ...state,
        selectedDimensionKey: action.dimensionKey,
      };
    case 'UPDATE_SELECTED_PRACTICE': {
      const selectedAssessmentId = state.selectedAssessmentId;
      const sessionSelectedAssessments = state.sessionSelectedAssessments;
      const updatedSessionSelectedAssessments = sessionSelectedAssessments.set(
        selectedAssessmentId,
        window.location.hash
      );

      return {
        ...state,
        selectedPracticeId: action.practiceId,
        sessionSelectedAssessments: updatedSessionSelectedAssessments,
      };
    }
    case 'SET_ORIGINAL_RESPONSES':
      return { ...state, originalResponses: action.responses };
    case 'delete_resource/assessments':
      return action.request._id === state.selectedAssessmentId
        ? {
            ...state,
            selectedAssessmentId: '',
            lastSelectedAssessmentId: '',
            comparisonAssessmentId: '',
            selectedMilestoneId: '',
          }
        : state;
    case 'SELECTED_ASSESSMENT':
      return {
        ...state,
        selectedAssessmentId: action.assessmentId,
        lastSelectedAssessmentId: action.assessmentId,
        comparisonAssessmentId: '',
        selectedMilestoneId: '',
      };
    case 'CLEAR_SELECTED_ASSESSMENT':
      return {
        ...state,
        selectedAssessmentId: '',
        comparisonAssessmentId: '',
        selectedMilestoneId: '',
      };
    case 'FILTER_CRITERIA':
      return {
        ...state,
        filterCriteria: action.filterCriteria,
      };
    case 'ASSESSMENT_SORT':
      return {
        ...state,
        assessmentSort: action.sort,
      };
    case 'SELECTED_COMPARISON_ASSESSMENT':
      return {
        ...state,
        comparisonAssessmentId: action.assessmentId,
        selectedMilestoneId: '',
      };
    case 'delete_resource/milestones':
      return action.request._id === state.selectedMilestoneId
        ? {
            ...state,
            selectedMilestoneId: '',
          }
        : state;

    case 'SELECTED_MILESTONE':
      return {
        ...state,
        comparisonAssessmentId: '',
        selectedMilestoneId: action.milestoneId,
      };
    case 'SELECTED_MILESTONE_FOR_EDIT':
      return {
        ...state,
        selectedMilestoneForEditId: action.milestoneId,
      };
    case 'SELECTED_ASSESSMENT_CREATION':
      return {
        ...state,
        selectedAssessmentFactory: action.assessmentFactory,
      };
    case 'SELECT_SUBSIDIARY_ID':
      return {
        ...state,
        selectedSubsidiaryId: action.subsidiaryId,
      };
    case 'NETWORK_CONNECT':
      return {
        ...state,
        networkConnected: true,
        saveMessage: '',
        saveMessageClass: '',
      };
    case 'NETWORK_DISCONNECT':
    case 'NETWORK_SAVE_WHILE_DISCONNECTED':
      if (action.pending > 1) {
        return {
          ...state,
          saveMessage: `Storage disconnnected.${
            action.pending && ' ' + action.pending + ' pending.'
          }`,
          saveMessageClass: 'error',
          networkConnected: false,
        };
      } else {
        return { ...state, networkConnected: false };
      }
    case 'get_resource/users': {
      // In the case that there is one user in the response, we're going
      // assume that this is the logged in user and we should set the
      // selected assessment to the one on their record.
      const items = _get(action, ['response', 'result', '_items'], []);
      if (items.length === 1 && !state.selectedAssessmentId) {
        return {
          ...state,
          selectedAssessmentId: items[0].active_assessment || '',
          lastSelectedAssessmentId: items[0].active_assessment || '',
        };
      }
      break;
    }
    case 'server/post_resource': // Fall through
    case 'server/multi_patch_resource':
    case 'server/patch_resource': {
      return {
        ...state,
        saveMessage: '',
        saveMessageClass: '',
      };
    }
    case 'post_resource/assessments': {
      return {
        ...state,
        selectedAssessmentId: action.response.result._id,
        lastSelectedAssessmentId: action.response.result._id,
      };
    }

    case 'put_resource/responses': // Fall through
    case 'patch_resource/responses': // Fall through
    case 'multi_patch_resource/responses': // Fall through
    case 'post_resource/responses':
      return {
        ...state,
        saveMessage: `Saved - ${moment().format(TIME_FORMAT)}`,
        saveMessageClass: '',
      };
    case 'TOGGLE_BITSIGHT_DISPLAY': {
      return {
        ...state,
        showBitsight: action.display,
      };
    }
    case 'TOGGLE_ARCHIVE_NOTES': {
      let archivedNoteList;
      if (
        state.archivedNoteAssessmentIdList.includes(
          action.archivedNoteAssessment
        )
      ) {
        archivedNoteList = state.archivedNoteAssessmentIdList.filter(
          (a) => a !== action.archivedNoteAssessment
        );
      } else {
        archivedNoteList = state.archivedNoteAssessmentIdList.concat(
          action.archivedNoteAssessment
        );
      }
      return {
        ...state,
        archivedNoteAssessmentIdList: archivedNoteList,
      };
    }

    case 'QUESTIONNAIRE_FETCH': {
      return {
        ...state,
        showPreQuestionnaire: true,
        newAssessment: action.newAssessment,
      };
    }
    case 'GET_BITSIGHT_PRACTICES_RESULTS_ERR': {
      return {
        ...state,
        showBitsight: false,
        saveMessage: action.error.message,
        saveMessageClass: 'error',
      };
    }
    default:
      if (shouldRaiseError(action)) {
        if (isResourceUpdateError(action.type)) {
          return {
            ...state,
            saveMessage: `Failure saving ${itemTypeFromActionType(
              action.type
            )}. [${action.error.message}]`,
            saveMessageClass: 'error',
          };
        }

        return {
          ...state,
          saveMessage: `${action.error.message}. Try refreshing the page or notifying support@axio.com`,
          saveMessageClass: 'error',
        };
      }
      break;
  }

  return state;
};
