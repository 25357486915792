import { useApolloClient } from '@apollo/client';
import {
  CognitoUser,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import { Auth, Cache } from 'aws-amplify';
import React, { createContext, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as guid } from 'uuid';

import {
  getCognitoEmailFromToken,
  getCognitoUsernameFromToken,
  getExpirationFromToken,
} from 'authentication/utils/tokens';
import { IRootState } from 'reducers';
import {
  CurrentUserDocument,
  CurrentUserQuery,
} from 'common/graphql/graphql-hooks';
import { fireHubSpotEvent, graphqlIdpPath, IAxioWindow } from './AxioUtilities';
import Logger from './Logger';

type Dispatch = ThunkDispatch<IRootState, void, AnyAction>;

interface IUserContext {
  user?: CognitoUser | null;
  login: (
    usernameOrEmail: string,
    password: string
  ) => Promise<CognitoUser | null>;
  federatedLogin: (
    idToken: string,
    expiresIn?: number
  ) => Promise<CognitoUser | null>;
  logout: (apolloClient: ReturnType<typeof useApolloClient>) => Promise<void>;
  confirmSignIn: (code: string) => Promise<CognitoUser | null>;
  register: (
    usernameOrEmail: string,
    password: string
  ) => Promise<ISignUpResult & { generatedUsername: string }>;
  confirmSignUp: (username: string, code: string) => Promise<boolean>;
  resendSignUp: (username: string) => Promise<string>;
  forgotPassword: (usernameOrEmail: string) => Promise<any>;
  forgotPasswordSubmit: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<any>;
  forceNewPassword: (password: string) => Promise<any>;
  getMfaStatus: () => Promise<string>;
  setMfaStatus: (enable: boolean) => Promise<void>;
}

const initialState = {
  // User is initialized as undefined so we can tell the difference between
  // a request that has not resolved and no user(null)
  user: undefined,
  login: () => Promise.resolve(null),
  federatedLogin: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
  confirmSignIn: () => Promise.resolve(null),
  register: () => Promise.resolve({} as any),
  confirmSignUp: () => Promise.resolve(false),
  resendSignUp: () => Promise.resolve(''),
  forgotPassword: () => Promise.resolve(null),
  forgotPasswordSubmit: () => Promise.resolve(null),
  forceNewPassword: () => Promise.resolve(null),
  getMfaStatus: () => Promise.resolve(''),
  setMfaStatus: () => Promise.resolve(),
};

export const UserContext = createContext<IUserContext>(initialState);

const c2m2_env = (window as Window & IAxioWindow).c2m2_env;
if (
  !c2m2_env ||
  !c2m2_env.C2M2_COGNITO_IDENTITY_POOL ||
  !c2m2_env.C2M2_COGNITO_REGION ||
  !c2m2_env.C2M2_COGNITO_USER_POOL ||
  !c2m2_env.C2M2_COGNITO_CLIENTID ||
  !c2m2_env.COGNITO_POOL_SITE_HOST
) {
  throw new Error('Required cognito variables missing');
}

const ssoDomain =
  'cognito-idp.' +
  c2m2_env.C2M2_COGNITO_REGION +
  '.amazonaws.com/' +
  c2m2_env.C2M2_COGNITO_USER_POOL;
const tokenEndpoint = `https://${c2m2_env.COGNITO_POOL_SITE_HOST}.auth.${c2m2_env.C2M2_COGNITO_REGION}.amazoncognito.com/oauth2/token`;
const federatedInfoKey = 'federatedInfo';
const federatedRefreshToken = 'federatedRefreshToken';

export const UserProvider: React.FunctionComponent<{}> = ({ children }) => {
  // User is initialized as undefined so we can tell the difference between
  // a request that has not resolved and no user(null)
  const [user, setUser] = useState<CognitoUser | null | undefined>(undefined);
  const dispatch = useDispatch<Dispatch>();
  const tempCognitoUser = useRef<CognitoUser | null>(null);
  const apolloClient = useApolloClient();
  const userNotFoundException = 'UserNotFoundException';

  const logout = useCallback(
    async (innerClient: ReturnType<typeof useApolloClient>) => {
      try {
        // if the user has already logged out in a different session this call can throw in weird ways
        await Auth.signOut();
      } catch (err: any) {
        Logger.exception(err, {
          contexts: {
            location: { file: 'userContext.tsx', function: 'logout' },
          },
        });
      }
      localStorage.removeItem(federatedRefreshToken);
      // don't call dispatch within here. We get into an infinite look for some reason
      setUser(null);
      innerClient
        .resetStore()
        .catch((err: Error) =>
          Logger.error(
            `Failure to reset store on logout with this error: ${err.message}`
          )
        );
      tempCognitoUser.current = null;
    },
    []
  );

  const finishSignIn = useCallback(
    async (
      newUser: CognitoUser,
      innerDispatch: Dispatch,
      innerClient: ReturnType<typeof useApolloClient>,
      innerLogout: (
        apolloClient: ReturnType<typeof useApolloClient>
      ) => Promise<void>
    ) => {
      const userNotFoundError = { code: userNotFoundException };
      innerDispatch({ type: 'COGNITO_LOGIN' });

      try {
        const { data: loggedInUser, errors } = await innerClient.query<
          CurrentUserQuery,
          {}
        >({
          query: CurrentUserDocument,
          variables: {},
          // https://www.apollographql.com/docs/react/advanced/caching.html#ignore
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        });
        if (errors?.some((err) => err.message === 'Cannot access user')) {
          // user is marked deleted in back-end
          throw userNotFoundError;
        } else {
          setUser(newUser);

          try {
            // Only inform HubSpot if the user is a free tool user
            if (loggedInUser.me && !loggedInUser.me.employer) {
              fireHubSpotEvent(loggedInUser.me.email, 'User logged in');
            }
          } catch {
            Logger.error('Failed send login to HubSpot');
          }
        }
      } catch (error) {
        await innerLogout(innerClient);
        // If the user is federated, there may not be a signOut member on the CognitoUser
        if (newUser.signOut) {
          newUser.signOut();
        }
        innerDispatch({ type: 'COGNITO_LOGOUT' });
        throw userNotFoundError;
      }
    },
    []
  );

  const finishSignOut = useCallback((innerDispatch: Dispatch) => {
    setUser(null);
    innerDispatch({ type: 'COGNITO_LOGOUT' });
  }, []);

  const refreshSsoToken = useCallback(async () => {
    const result = await internalRefreshSsoToken();
    if (!result.id_token) {
      finishSignOut(dispatch);
      throw new Error('Error. Could not refresh tokens');
    }
    return result;
  }, [dispatch, finishSignOut]);

  // fetch the info of the user that may be already logged in
  React.useEffect(() => {
    // Configure the AWS Auth module
    Auth.configure({
      identityPoolId: c2m2_env.C2M2_COGNITO_IDENTITY_POOL,
      region: c2m2_env.C2M2_COGNITO_REGION,
      userPoolId: c2m2_env.C2M2_COGNITO_USER_POOL,
      userPoolWebClientId: c2m2_env.C2M2_COGNITO_CLIENTID,
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
      refreshHandlers: {
        [ssoDomain]: refreshSsoToken,
      },
    });
    if (
      process.env.NODE_ENV !== 'production' &&
      process.env.REACT_APP_SKIP_COGNITO
    ) {
      // Note this awful stub. Certain parts of the app are likely to crash if they try
      // to access cognito stuff - i.e. checking mfa status on the user dialog
      finishSignIn(true as any, dispatch, apolloClient, logout);
      return;
    }
    // attempt to fetch the info of the user if already logged in
    Auth.currentAuthenticatedUser()
      .then((awsUser) => {
        // federated users have a top-level email key. standard users have an attributes key
        const userIsFederated = !!awsUser.email;
        const email: string = userIsFederated
          ? awsUser.email
          : awsUser.attributes && awsUser.attributes.email;
        // No email, there's nothing we can do with this user.  Let's hope
        // starting login again fixes it.
        if (!email) {
          finishSignOut(dispatch);
          return;
        }
        // Cache.getItem returns null when the federated info has
        // expired.  If the user is federated but we can't get the info,
        // it means we've got expired creds.  We'll try refreshing and
        // if that doesn't work, we'll be logged out.
        if (userIsFederated && !Cache.getItem(federatedInfoKey)) {
          refreshSsoToken();
        }
        finishSignIn(awsUser, dispatch, apolloClient, logout);
      })
      .catch((errorStr: string) => {
        errorStr !== 'not authenticated'
          ? Logger.warn('currentAuthenticatedUser threw', {
              contexts: { location: { file: 'userContext.tsx', errorStr } },
            })
          : Logger.info(
              "Call to currentAuthenticatedUser with a 'not authenticated' user -- this is expected sometimes.",
              { contexts: { location: { file: 'userContext.tsx', errorStr } } }
            );

        setUser(null);
      });
  }, [
    dispatch,
    refreshSsoToken,
    apolloClient,
    finishSignIn,
    finishSignOut,
    logout,
  ]);

  const login = async (email: string, password: string) => {
    try {
      const cognitoUser = await Auth.signIn(email.toLowerCase(), password);
      const challengeName: string | undefined = cognitoUser.challengeName;

      if (challengeName === 'SMS_MFA') {
        // MFA is enabled, sign-in should be confirmed with the confirmation code
        tempCognitoUser.current = cognitoUser;
        // eslint-disable-next-line no-throw-literal
        throw {
          code: 'SmsConfirmationRequiredException',
          message: 'SMS confirmation code required to complete login',
          name: 'SmsConfirmationRequiredException',
        };
      } else if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        tempCognitoUser.current = cognitoUser;

        // eslint-disable-next-line no-throw-literal
        throw {
          code: 'NewPasswordRequiredException',
          message:
            'There is an issue with your account. Please contact support@axio.com',
          name: 'NewPasswordRequiredException',
        };
      } else {
        // Happy path - the user is now logged in
        await finishSignIn(cognitoUser, dispatch, apolloClient, logout);
        return cognitoUser;
      }
    } catch (err: any) {
      if (
        err.code === userNotFoundException ||
        err.code === 'NotAuthorizedException'
      ) {
        // Supply generic error so as not to be a password oracle
        err.message = 'Invalid username or password';
      }
      throw err;
    }
  };

  const federatedLogin = async (code: string) => {
    const tokenRequestBody = {
      grant_type: 'authorization_code',
      client_id: c2m2_env.C2M2_COGNITO_CLIENTID || '',
      code,
      redirect_uri: `${window.location.origin}/authentication/sso`,
    };
    const resp = await fetch(tokenEndpoint, {
      method: 'POST',
      body: new URLSearchParams(tokenRequestBody),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const {
      id_token: idToken,
      expires_in: expiresIn,
      refresh_token: refreshToken,
    } = await resp.json();
    if (!idToken || !expiresIn || !refreshToken) {
      throw new Error('Bad token received. Please try again.');
    }
    localStorage.setItem(federatedRefreshToken, refreshToken);
    const cognitoUsername = getCognitoUsernameFromToken(idToken);
    const emailFromToken = getCognitoEmailFromToken(idToken);
    const expirationFromToken = getExpirationFromToken(idToken);

    if (!cognitoUsername || !emailFromToken || !expirationFromToken) {
      throw new Error('Bad token received. Please try again.');
    }
    try {
      await Auth.federatedSignIn(
        ssoDomain,
        { token: idToken, expires_at: expirationFromToken },
        { name: cognitoUsername, email: emailFromToken }
      );

      Cache.setItem(
        federatedInfoKey,
        {
          provider: ssoDomain,
          token: idToken,
          user,
          expires_at: expirationFromToken,
          identity_id: emailFromToken,
        },
        { priority: 1 }
      );

      const cognitoUser = await Auth.currentAuthenticatedUser();
      await finishSignIn(cognitoUser, dispatch, apolloClient, logout);
      return cognitoUser;
    } catch (err: any) {
      // federated errors are generally user hostile and cryptic
      throw new Error(
        'Login failed. Please try again or contact support@axio.com'
      );
    }
  };

  const confirmSignIn = async (code: string) => {
    if (!tempCognitoUser.current) {
      // AWS is ever consistent. Auth.confirmSignIn wants a CognitoUser, not username or email.
      // Since it doesn't seem there's any easy way to get it mid login, this is effectively
      // a side-effect from Auth.signIn
      throw Error('Unknown error. Please return to login and try again.');
    }
    return Auth.confirmSignIn(
      tempCognitoUser.current,
      code.trim(),
      'SMS_MFA'
    ).then((cognitoUser) => {
      finishSignIn(cognitoUser, dispatch, apolloClient, logout);
      tempCognitoUser.current = null;
      return cognitoUser;
    });
  };

  const register = async (email: string, password: string) => {
    const generatedUsername = guid(); // username is required even if using email alias -- using guid
    const signUpResult = await Auth.signUp({
      username: generatedUsername,
      password,
      attributes: {
        email: email.toLowerCase(),
      },
    });
    // This is likely an edge case, but if a logged in user registers, then they are treated as logged out after this
    setUser(null);
    return { ...signUpResult, generatedUsername };
  };

  const confirmSignUp = (username: string, code: string) =>
    Auth.confirmSignUp(username, code.trim()).then((confirmResult) => {
      setUser(null);
      return confirmResult === 'SUCCESS';
    });

  const resendSignUp = (username: string) =>
    Auth.resendSignUp(username).then((confirmResult) => {
      setUser(null);
      return confirmResult;
    });

  const forgotPassword = (email: string) =>
    Auth.forgotPassword(email.toLowerCase()).catch((err) => {
      // don't be a user oracle
      if (err.code === userNotFoundException) {
        return;
      }
      throw err;
    });

  const forgotPasswordSubmit = async (
    email: string,
    code: string,
    newPassword: string
  ) =>
    Auth.forgotPasswordSubmit(
      email.toLowerCase(),
      code.trim(),
      newPassword
    ).catch((err) => {
      // invalid users get the same error as valid users with bad codes to prevent user oracle
      if (err.code === userNotFoundException) {
        // eslint-disable-next-line no-throw-literal
        throw {
          code: 'CodeMismatchException',
          name: 'CodeMismatchException',
          message: 'Invalid verification code provided, please try again.',
        };
      }
      throw err;
    });

  const forceNewPassword = async (password: string) => {
    if (!tempCognitoUser.current) {
      // AWS is ever consistent. Auth.confirmSignIn wants a CognitoUser, not username or email.
      // Since it doesn't seem there's any easy way to get it mid login, this is effectively
      // a side-effect from Auth.signIn
      throw new Error('Unknown error. Please return to login and try again.');
    }
    const cognitoUser = await Auth.completeNewPassword(
      tempCognitoUser.current,
      password,
      {}
    );
    finishSignIn(cognitoUser, dispatch, apolloClient, logout);
    return cognitoUser;
  };

  const getMfaStatus = async () =>
    // aws-amplify Auth.getPreferredMFA requires a full cognito user.
    // SSO users are signed in using Auth.federatedSignIn, which may not return a full cognito user.
    // Catching here is to hide this error from the end user so login can continue.
    Auth.getPreferredMFA(await Auth.currentAuthenticatedUser()).catch(
      (error) => {
        Logger.info(`Cognito user's MFA status unavailable: ${error}`);
        return '';
      }
    );

  const setMfaStatus = async (enable: boolean) => {
    Auth.setPreferredMFA(
      await Auth.currentAuthenticatedUser(),
      enable ? 'SMS' : 'NOMFA'
    );
  };

  // Make sure to not force a re-render on the components that are reading these values,
  // unless the `user` value has changed.
  const values = React.useMemo(
    () => ({
      user,
      login,
      federatedLogin,
      logout,
      confirmSignIn,
      register,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      forceNewPassword,
      getMfaStatus,
      setMfaStatus,
    }),
    // This might be a bug. Moving the declaration of 'confirmSignIn' and 'login' inside the memo might be the fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      '`useUser` hook must be used within a `UserProvider` component'
    );
  }
  return context;
};

const refreshFailure = {
  id_token: null,
  expires_at: null,
  identity_id: null,
};

const internalRefreshSsoToken = async () => {
  const refreshToken = localStorage.getItem(federatedRefreshToken);
  if (!refreshToken) {
    return refreshFailure;
  }
  const tokenRequestBody = {
    grant_type: 'refresh_token',
    client_id: c2m2_env.C2M2_COGNITO_CLIENTID || '',
    refresh_token: refreshToken,
  };
  const resp = await fetch(tokenEndpoint, {
    method: 'POST',
    body: new URLSearchParams(tokenRequestBody),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const { id_token } = await resp.json();
  const tokenExpirationDate = getExpirationFromToken(id_token);
  const emailFromToken = getCognitoEmailFromToken(id_token);
  if (!id_token || !tokenExpirationDate || !emailFromToken) {
    return refreshFailure;
  }
  const federatedInfo = Cache.getItem(federatedInfoKey);
  Cache.setItem(federatedInfoKey, {
    ...federatedInfo,
    token: id_token,
    expires_at: tokenExpirationDate,
  });

  return {
    id_token,
    expires_at: tokenExpirationDate,
    identity_id: emailFromToken,
  };
};

/*
 * Returns the current token for the user or null if the user isn't logged in.
 */
export const getIdToken = async (): Promise<string | null> => {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.REACT_APP_SKIP_COGNITO
  ) {
    return 'dev-fake-cognito-token';
  }
  const federatedInfo = Cache.getItem(federatedInfoKey);
  if (federatedInfo) {
    // expires_at is in seconds since epoch, getTime() is in milliseconds since epoch
    if (federatedInfo.expires_at * 1000 <= new Date().getTime()) {
      const { id_token } = await internalRefreshSsoToken();
      return id_token;
    }
    // user authenticated via SSO
    return federatedInfo.token;
  }

  let session: CognitoUserSession;
  try {
    session = await Auth.currentSession();
  } catch (err: any) {
    Logger.warn('getIdToken Auth.currentSession() threw', {
      contexts: { err },
    });
    window.location.replace('/authentication/logout');
    return null;
  }

  try {
    return session.getIdToken().getJwtToken();
  } catch (err: any) {
    Logger.warn('getIdToken session.getIdToken().getJwtToken() threw', {
      contexts: { err },
    });
    return null;
  }
};

export const getSsoStatus = async (email: string) => {
  try {
    const url = graphqlIdpPath();
    const data = { email: email };
    const ssoResp = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Location, X-Request-URL',
      },
      method: 'POST',
      body: JSON.stringify({
        data,
      }),
    });
    const { data: hasSso } = await ssoResp.json();
    return !!hasSso;
  } catch (err: any) {
    return false;
  }
};
