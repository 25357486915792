export default {
  CMA: 'Cyber Mutual Assistance',
  CRISP: 'Cybersecurity Risk Information Sharing Program',
  DHS: 'Department of Homeland Security',
  'DHS AIS':
    'Department of Homeland Security Automated Indicator Sharing Program',
  'DHS S&T': 'Department of Homeland Security Office of Science & Technology',
  DOE: 'Department of Energy',
  'DOE CEDS':
    'Department of Energy Cybersecurity for Energy Delivery Systems Program',
  'E-ISAC': 'Electricity Information Sharing & Analysis Center',
  EPRI: 'Electric Power Research Institute',
  'FBI DSAC':
    'Federal Bureau of Investigation Domestic Security Alliance Council',
  IT: 'Information Technology',
  OSAC: '(State Department) Overseas Security Advisory Council',
  OT: 'Operational Technology',
};
