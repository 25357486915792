import React, { Suspense } from 'react';

import { LoadingScreenRaw } from 'common/components/Loading/LoadingScreen';
import { useCurrentUserRolesQuery } from 'common/graphql/graphql-hooks';
import { NonAdminWarning } from './NonAdminWarning';

const AdminView = React.lazy(
  () => import(/* webpackChunkName: "Admin", webpackPrefetch: true */ './views')
);

const Admin = React.memo(() => {
  const { loading, data } = useCurrentUserRolesQuery();
  if (loading) {
    return <LoadingScreenRaw isLoading />;
  }
  if (!data?.me?.roles?.includes('admin')) {
    return <NonAdminWarning />;
  }
  return (
    <Suspense fallback={<LoadingScreenRaw isLoading />}>
      <AdminView />
    </Suspense>
  );
});

export default Admin;
