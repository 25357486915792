import { desaturate, transparentize } from 'polished';

import axioSquaresPattern from 'common/themes/default/axio-squares-pattern.png';
import {
  AXIO_EMPHASIS_BLUE,
  DARK_BACKGROUND_COLOR,
  DARK_PURPLE,
  NAVBAR_WIDTH,
  PRIMARY_BLUE,
  TOC_WIDTH,
} from 'common/constants';
import { defaultTheme as baseTheme } from 'common/themes/default';
import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';

const LOGO_WIDTH = TOC_WIDTH + NAVBAR_WIDTH + 5;
const HEADER_HEIGHT = 70;
const FOOTER_HEIGHT = 60;
const DASH_FOOTER_HEIGHT = 85;

export default {
  authBackground: baseTheme.backgroundImage,
  c2m2ExcelImport: true,
  colors: {
    axioBlue: PRIMARY_BLUE,
    lightAxioBlue: desaturate(0, transparentize(0.8, PRIMARY_BLUE)),
    headerBackground: 'transparent',
    menuSubtext: 'gray',
    nameplate: PRIMARY_BLUE,
    qlNavButtonBackground: '#2866b5',
    fullBackground: '#e1e2e7',
    lightBackground: '#EEEFF1',
    qlBackground: '#032572',
    dashboardGreenBorder: '#63c87c',
    target: '#63c87c',
    darkPurple: DARK_PURPLE,
    avatarColor: PRIMARY_BLUE,
    avatarTextColor: 'white',
    avatarNameplateColor: 'black',
    tocCollapseColor: '#808eb6',
    headerText: '',
    taglineColor: PRIMARY_BLUE, // Color of branded entities "tagline" not used for our theme.
    tocExpand: AXIO_EMPHASIS_BLUE,
    profileSwitchBackground: PRIMARY_BLUE,
    profileSwitchText: 'white',
    profileSwitchLabel: PRIMARY_BLUE,
    profileSwitchHandle: 'white',
    profileSwitchHover: desaturate(0, transparentize(0.5, PRIMARY_BLUE)),
    qlAnswerHover: desaturate(0, transparentize(0.5, PRIMARY_BLUE)),
    qlAnswerText: 'black',
    qlDefaultAnswerBackground: PRIMARY_BLUE,
    qlOriginalAnswerBackground: DARK_BACKGROUND_COLOR,
    tocBackgroundColor: '#032572',
    authButtonBackground: PRIMARY_BLUE,
    authButtonTextColor: 'white',
  },
  dashboard: {
    headerHeight: `${HEADER_HEIGHT}px`,
    footerHeight: DASH_FOOTER_HEIGHT + 'px',
    centerOffset: '130px',
    widgetOffset: `${HEADER_HEIGHT}px`,
    showInfoCutoff: '450px',
  },
  font: 'Lato',
  hasIntroTour: true,
  hasOwnIntroScreen: true,
  headerFooterOffset: HEADER_HEIGHT + FOOTER_HEIGHT,
  logoWidth: LOGO_WIDTH + 'px',
  modelScopes: ModelScope,
  models: LegacyModelIdEnum,
  poweredBy: false,
  poweredByBackground: false,
  profileDropdownTop: `${HEADER_HEIGHT}px`,
  tocPattern: axioSquaresPattern,
};
