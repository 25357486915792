import { FieldPolicy, Reference } from '@apollo/client';
import { notEmpty } from 'common/utils/typeGuards';
import { typedReadField } from '../cacheUtils/helpers';
import {
  Company,
  CompanyHelpText,
  Query,
  QueryReadCompanyHelpTextArgs,
  User,
} from '../graphql-hooks';

export const readCompanyHelpTextPolicy: FieldPolicy = {
  read: (_helpText, context) => {
    const {
      practiceId,
      model,
      helpType,
    } = context.args as QueryReadCompanyHelpTextArgs;
    const me = typedReadField<Query, 'me'>(context, 'me');
    if (!me) return undefined;
    const employer = typedReadField<User, 'employer'>(context, 'employer', me);
    if (!employer) return undefined;
    const ancestor = typedReadField<Company, 'ancestor'>(
      context,
      'ancestor',
      employer
    );

    const getUniqueHelpText = (company: Reference) =>
      typedReadField<Company, 'helpTexts'>(context, 'helpTexts', company)
        ?.filter(
          (help: Reference | null) =>
            matchesField('practice', context, practiceId)(help) &&
            matchesField('modelUuid', context, model)(help) &&
            matchesField('helpType', context, helpType)(help) &&
            notEmpty(help)
        )
        .slice(0, 1)?.[0] ?? undefined;

    return ancestor ? getUniqueHelpText(ancestor) : getUniqueHelpText(employer);
  },
};

export const matchesField = (
  field: 'practice' | 'modelUuid' | 'helpType',
  context: any,
  value: string
) => (help: Reference | null) =>
  help
    ? typedReadField<CompanyHelpText, typeof field>(context, field, help) ===
      value
    : false;
