import defaultTheme from './default';
import {
  NETFRIENDS_BLACK,
  NETFRIENDS_GREEN,
  NETFRIENDS_YELLOW,
} from 'common/themes/netfriends';
import netfriendsAuthBackground from 'common/themes/netfriends/netfriends_auth_background.png';
import { transparentize, desaturate } from 'polished';
const LOGO_WIDTH = 100;

export default {
  ...defaultTheme,
  poweredBy: true,
  poweredByBackground: true,
  hasOwnIntroScreen: false,
  authBackground: netfriendsAuthBackground,
  authWelcomeText: '',
  logoWidth: LOGO_WIDTH + 'px',
  tocPattern: netfriendsAuthBackground,
  colors: {
    ...defaultTheme.colors,
    headerBackground: 'transparent',
    headerText: NETFRIENDS_BLACK,
    menuSubtext: 'lightgray',
    nameplate: NETFRIENDS_BLACK,
    qlBackground: NETFRIENDS_BLACK,
    tocExpand: NETFRIENDS_GREEN,
    primaryButton: NETFRIENDS_YELLOW,
    primaryButtonText: NETFRIENDS_BLACK,
    avatarColor: NETFRIENDS_YELLOW,
    avatarTextColor: NETFRIENDS_BLACK,
    avatarNameplateColor: NETFRIENDS_BLACK,
    tocCollapseColor: NETFRIENDS_YELLOW,
    qlAnswerHover: desaturate(0, transparentize(0.5, NETFRIENDS_YELLOW)),
    qlAnswerText: NETFRIENDS_BLACK,
    qlAnswerBackground: NETFRIENDS_YELLOW,
    taglineColor: NETFRIENDS_GREEN,
    tocBackgroundColor: NETFRIENDS_BLACK,
    authButtonBackground: NETFRIENDS_YELLOW,
    authButtonTextColor: NETFRIENDS_BLACK,
  },
  hasIntroTour: false,
};
