// This object maps keys from the CSF model to contribution values in the
// C2M2 model.  Depending on how we want to handle the mapping, we can
// either set the contributions to add up to > 1 and give someone credit
// once they get to 1 or we can make sure the contributions add up to
// exactly 1, in which case you won't get NSF credit until you achieve all
// of the practices.  We also *can* decide whether or not to give
// partial credit.  In order to manage javacsript rounding issues, the numbers
// are stored in integer percents.  Divide by 100 to get the decimal bit.

export default {
  '.ID': 'IDENTIFY',
  '.ID.AM': 'Asset Management',
  '.ID.AM.1': {
    '.ACM.1.a': 25,
    '.ACM.1.c': 25,
    '.ACM.1.e': 25,
    '.ACM.1.f': 25,
  },
  '.ID.AM.2': {
    '.ACM.1.a': 25,
    '.ACM.1.c': 25,
    '.ACM.1.e': 25,
    '.ACM.1.f': 25,
  },
  '.ID.AM.3': {
    '.RM.2.g': 50,
    '.ACM.1.e': 50,
  },
  '.ID.AM.4': {
    '.EDM.1.a': 20,
    '.EDM.1.c': 20,
    '.EDM.1.e': 20,
    '.EDM.1.g': 20,
    '.RM.1.c': 20,
  },
  '.ID.AM.5': {
    '.ACM.1.a': 25,
    '.ACM.1.b': 25,
    '.ACM.1.c': 25,
    '.ACM.1.d': 25,
  },
  '.ID.AM.6': {
    '.WM.1.a': 34, //kb wonders why not 33.33333 ?
    '.WM.1.b': 34, //why not 33
    '.WM.1.c': 32, //""  ""   ""
  },

  '.ID.BE': 'Business Environment',
  '.ID.BE.1': {
    '.EDM.1.b': 20,
    '.EDM.1.d': 20,
    '.EDM.1.f': 20,
    '.EDM.1.g': 20,
    '.RM.1.c': 20,
  },
  '.ID.BE.2': {
    '.EDM.1.b': 17,
    '.EDM.1.d': 17,
    '.EDM.1.f': 17,
    '.CPM.1.c': 17,
    '.EDM.1.g': 16,
    '.RM.1.c': 16,
  },
  '.ID.BE.3': {
    '.RM.3.b': 50,
    '.RM.1.c': 50,
  },
  '.ID.BE.4': {
    '.ACM.1.a': 10,
    '.ACM.1.b': 9,
    '.EDM.1.a': 9,
    '.ACM.1.c': 9,
    '.ACM.1.d': 9,
    '.EDM.1.c': 9,
    '.EDM.1.e': 9,
    '.ACM.1.e': 9,
    '.ACM.1.f': 9,
    '.RM.1.c': 9,
    '.EDM.1.g': 9,
  },
  '.ID.BE.5': {
    '.IR.4.a': 25,
    '.IR.4.b': 25,
    '.IR.4.c': 25,
    '.IR.4.e': 25,
  },

  '.ID.GV': 'Governance',
  '.ID.GV.1': {
    '.CPM.2.g': 34,
    '.CPM.5.d': 33,
    '.RM.3.e': 33,
  },
  '.ID.GV.2': {
    '.WM.1.a': 12,
    '.WM.1.b': 11,
    '.WM.1.c': 11,
    '.WM.2.d': 11,
    '.WM.5.b': 11,
    '.ISC.2.b': 11,
    '.WM.1.e': 11,
    '.WM.1.f': 11,
    '.WM.1.g': 11,
  },
  '.ID.GV.3': {
    '.CPM.2.k': 10,
    '.IR.3.n': 9,
    '.RM.3.f': 9,
    '.ACM.4.f': 9,
    '.IAM.3.f': 9,
    '.TVM.3.f': 9,
    '.SA.4.f': 9,
    '.ISC.2.f': 9,
    '.IR.5.f': 9,
    '.EDM.3.f': 9,
    '.WM.5.f': 9,
  },
  '.ID.GV.4': {
    '.RM.2.a': 17,
    '.RM.2.b': 17,
    '.RM.2.h': 17,
    '.RM.3.e': 17,
    '.RM.1.c': 16,
    '.RM.1.e': 16,
  },

  '.ID.RA': 'Risk Assessment',
  '.ID.RA.1': {
    '.TVM.2.a': 9,
    '.TVM.2.b': 9,
    '.TVM.2.d': 9,
    '.TVM.2.e': 9,
    '.TVM.2.f': 8,
    '.RM.1.c': 8,
    '.RM.2.j': 8,
    '.TVM.2.i': 8,
    '.TVM.2.j': 8,
    '.TVM.2.k': 8,
    '.TVM.2.l': 8,
    '.TVM.2.m': 8,
  },
  '.ID.RA.2': {
    '.TVM.1.a': 20,
    '.TVM.1.b': 20,
    '.TVM.2.a': 20,
    '.TVM.2.b': 20,
    '.TVM.2.d': 20,
  },
  '.ID.RA.3': {
    '.TVM.1.a': 17,
    '.TVM.1.b': 17,
    '.TVM.1.d': 17,
    '.TVM.1.e': 17,
    '.RM.2.j': 16,
    '.TVM.1.j': 16,
  },
  '.ID.RA.4': {
    '.TVM.1.d': 25,
    '.TVM.1.f': 25,
    '.RM.1.c': 25,
    '.TVM.1.i': 25,
  },
  '.ID.RA.5': {
    '.RM.1.c': 34,
    '.RM.2.j': 33,
    '.TVM.2.m': 33,
  },
  '.ID.RA.6': {
    '.RM.2.e': 20,
    '.TVM.1.d': 20,
    '.RM.1.c': 20,
    '.RM.2.j': 20,
    '.IR.3.m': 20,
  },

  '.ID.RM': 'Risk Management Strategy',
  '.ID.RM.1': {
    '.RM.2.a': 5,
    '.RM.2.b': 5,
    '.RM.1.a': 5,
    '.RM.1.b': 5,
    '.RM.2.c': 5,
    '.RM.2.d': 5,
    '.RM.2.e': 5,
    '.RM.2.g': 5,
    '.RM.3.a': 5,
    '.RM.3.b': 5,
    '.RM.3.c': 5,
    '.RM.3.d': 5,
    '.RM.1.c': 5,
    '.RM.1.d': 5,
    '.RM.1.e': 5,
    '.RM.2.h': 5,
    '.RM.2.j': 5,
    '.RM.3.g': 5,
    '.RM.3.h': 5,
    '.RM.3.i': 5,
  },
  '.ID.RM.2': {
    '.RM.1.c': 50,
    '.RM.1.e': 50,
  },
  '.ID.RM.3': {
    '.RM.1.b': 50,
    '.RM.1.c': 50,
  },

  /* These come from the v1.1 model and were mapped by Axio (David White) */
  '.ID.SC': 'Supply Chain Risk Management',
  '.ID.SC.1': {
    '.EDM.1.c': 10,
    '.EDM.1.d': 10,
    '.EDM.2.e': 10,
    '.EDM.3.a': 40,
    '.EDM.3.d': 10,
    '.EDM.3.e': 10,
    '.EDM.3.f': 10,
  },
  '.ID.SC.2': {
    '.EDM.1.a': 12.5,
    '.EDM.1.b': 12.5,
    '.EDM.1.c': 12.5,
    '.EDM.1.d': 12.5,
    '.EDM.1.e': 12.5,
    '.EDM.1.f': 12.5,
    '.EDM.1.g': 12.5,
    '.EDM.2.g': 12.5,
  },
  '.ID.SC.3': {
    '.EDM.2.b': 5,
    '.EDM.2.d': 10,
    '.EDM.2.e': 5,
    '.EDM.2.f': 55,
    '.EDM.2.h': 10,
    '.EDM.2.k': 5,
    '.EDM.2.l': 10,
  },
  '.ID.SC.4': {
    '.EDM.2.i': 100,
  },
  '.ID.SC.5': {
    '.IR.3.e': 20,
    '.IR.3.j': 20,
    '.IR.3.l': 60,
  },
  /* End of v1.1 mapping */

  '.PR': 'PROTECT',
  '.PR.AC': 'Identity Management, Authentication and Access Control',
  '.PR.AC.1': {
    '.IAM.1.a': 12.5,
    '.IAM.1.b': 12.5,
    '.IAM.1.c': 12.5,
    '.IAM.1.d': 12.5,
    '.IAM.1.e': 12.5,
    '.IAM.1.f': 12.5,
    '.RM.1.c': 12.5,
    '.IAM.1.g': 12.5,
  },
  '.PR.AC.2': {
    '.IAM.2.a': 15,
    '.IAM.2.b': 15,
    '.IAM.2.c': 14,
    '.IAM.2.d': 14,
    '.IAM.2.e': 14,
    '.IAM.2.f': 14,
    '.IAM.2.g': 14,
  },
  '.PR.AC.3': {
    '.IAM.2.a': 15,
    '.IAM.2.b': 15,
    '.IAM.2.c': 14,
    '.IAM.2.d': 14,
    '.IAM.2.e': 14,
    '.IAM.2.f': 14,
    '.IAM.2.g': 14,
  },
  '.PR.AC.4': {
    '.IAM.2.d': 100,
  },
  '.PR.AC.5': {
    '.CPM.3.a': 25,
    '.CPM.3.b': 25,
    '.CPM.3.c': 25,
    '.CPM.3.d': 25,
  },
  /* These come from the v1.1 model and were mapped by Axio (David White) */
  '.PR.AC.6': {
    '.IAM.1.a': 15,
    '.IAM.1.b': 15,
    '.IAM.1.d': 55,
    '.IAM.1.e': 15,
  },
  '.PR.AC.7': {
    '.IAM.1.g': 60,
    '.IAM.2.f': 10,
    '.IAM.2.h': 10,
    '.IAM.3.d': 10,
    '.IAM.3.f': 10,
  },
  /* End of v1.1 mapping */

  '.PR.AT': 'Awareness and Training',
  '.PR.AT.1': {
    '.WM.3.a': 12.5,
    '.WM.4.a': 12.5,
    '.WM.3.b': 12.5,
    '.WM.3.c': 12.5,
    '.WM.3.d': 12.5,
    '.WM.3.g': 12.5,
    '.WM.3.h': 12.5,
    '.WM.3.i': 12.5,
  },
  '.PR.AT.2': {
    '.WM.1.a': 15,
    '.WM.1.b': 15,
    '.WM.1.c': 14,
    '.WM.1.d': 14,
    '.WM.1.e': 14,
    '.WM.1.f': 14,
    '.WM.1.g': 14,
  },
  '.PR.AT.3': {
    '.WM.1.a': 15,
    '.WM.1.b': 15,
    '.WM.1.c': 14,
    '.WM.1.d': 14,
    '.WM.1.e': 14,
    '.WM.1.f': 14,
    '.WM.1.g': 14,
  },
  '.PR.AT.4': {
    '.WM.1.a': 15,
    '.WM.1.b': 15,
    '.WM.1.c': 14,
    '.WM.1.d': 14,
    '.WM.1.e': 14,
    '.WM.1.f': 14,
    '.WM.1.g': 14,
  },
  '.PR.AT.5': {
    '.WM.1.a': 15,
    '.WM.1.b': 15,
    '.WM.1.c': 14,
    '.WM.1.d': 14,
    '.WM.1.e': 14,
    '.WM.1.f': 14,
    '.WM.1.g': 14,
  },

  '.PR.DS': 'Data Security',
  '.PR.DS.1': {
    '.TVM.1.c': 50,
    '.TVM.2.c': 50,
  },
  '.PR.DS.2': {
    '.TVM.1.c': 50,
    '.TVM.2.c': 50,
  },
  '.PR.DS.3': {
    '.ACM.3.a': 9,
    '.ACM.3.b': 9,
    '.ACM.3.c': 9,
    '.ACM.3.d': 9,
    '.ACM.4.a': 8,
    '.ACM.4.b': 8,
    '.ACM.4.c': 8,
    '.ACM.4.d': 8,
    '.ACM.3.f': 8,
    '.ACM.4.e': 8,
    '.ACM.4.f': 8,
    '.ACM.4.g': 8,
  },
  '.PR.DS.4': {
    '.TVM.1.c': 34,
    '.TVM.2.c': 33,
    '.CPM.3.b': 33,
  },
  '.PR.DS.5': {
    '.TVM.1.c': 25,
    '.TVM.2.c': 25,
    '.CPM.3.b': 25,
    '.TVM.2.n': 25,
  },
  '.PR.DS.6': {
    '.SA.2.e': 50,
    '.SA.2.i': 50,
  },
  '.PR.DS.7': {
    '.ACM.3.c': 50,
    '.ACM.3.e': 50,
  },
  /* These come from the v1.1 model and were mapped by Axio (David White) */
  '.PR.DS.8': {
    '.ACM.2.d': 100,
  },
  /* End of v1.1 mapping */

  '.PR.IP': 'Information Protection Processes and Procedures',
  '.PR.IP.1': {
    '.ACM.2.a': 20,
    '.ACM.2.b': 20,
    '.ACM.2.c': 20,
    '.ACM.2.d': 20,
    '.ACM.2.e': 20,
  },
  '.PR.IP.2': {
    '.ACM.3.d': 100,
  },
  '.PR.IP.3': {
    '.ACM.3.a': 12.5,
    '.ACM.3.b': 12.5,
    '.ACM.3.c': 12.5,
    '.ACM.3.d': 12.5,
    '.ACM.4.a': 12.5,
    '.ACM.3.e': 12.5,
    '.ACM.3.f': 12.5,
    '.ACM.4.e': 12.5,
  },
  '.PR.IP.4': {
    '.IR.4.a': 50,
    '.IR.4.b': 50,
  },
  '.PR.IP.5': {
    '.ACM.4.f': 50,
    '.RM.3.f': 50,
  },
  '.PR.IP.6': {
    '.ACM.3.d': 100,
  },
  '.PR.IP.7': {
    '.CPM.1.g': 100,
  },
  '.PR.IP.8': {
    '.ISC.1.a': 8,
    '.ISC.1.b': 8,
    '.ISC.1.c': 8,
    '.ISC.1.d': 8,
    '.ISC.1.e': 8,
    '.ISC.1.f': 8,
    '.ISC.1.g': 8,
    '.ISC.2.b': 8,
    '.ISC.1.h': 8,
    '.ISC.1.i': 7,
    '.ISC.1.j': 7,
    '.ISC.1.k': 7,
    '.ISC.1.l': 7,
  },
  '.PR.IP.9': {
    '.IR.4.c': 6,
    '.IR.3.f': 6,
    '.IR.4.d': 6,
    '.IR.4.f': 6,
    '.IR.5.a': 6,
    '.IR.5.b': 6,
    '.IR.5.d': 6,
    '.TVM.1.d': 6,
    '.IR.3.k': 6,
    '.IR.3.m': 6,
    '.IR.4.i': 5,
    '.IR.4.j': 5,
    '.IR.5.e': 5,
    '.IR.5.f': 5,
    '.IR.5.g': 5,
    '.IR.5.h': 5,
    '.IR.5.i': 5,
    '.RM.1.c': 5,
  },
  '.PR.IP.10': {
    '.IR.3.e': 20,
    '.IR.4.f': 20,
    '.IR.3.k': 20,
    '.IR.4.i': 20,
    '.IR.4.j': 20,
  },
  '.PR.IP.11': {
    '.WM.2.a': 12.5,
    '.WM.2.b': 12.5,
    '.WM.2.c': 12.5,
    '.WM.2.d': 12.5,
    '.WM.2.e': 12.5,
    '.WM.2.f': 12.5,
    '.WM.2.g': 12.5,
    '.WM.2.h': 12.5,
  },
  '.PR.IP.12': {
    '.TVM.3.a': 50,
    '.TVM.3.e': 50,
  },

  '.PR.MA': 'Maintenance',
  '.PR.MA.1': {
    '.ACM.3.b': 34,
    '.ACM.4.c': 33,
    '.ACM.3.f': 33,
  },
  '.PR.MA.2': {
    '.SA.1.a': 10,
    '.IR.1.c': 10,
    '.IAM.2.a': 10,
    '.IAM.2.b': 10,
    '.IAM.2.c': 10,
    '.IAM.2.d': 10,
    '.IAM.2.e': 10,
    '.IAM.2.f': 10,
    '.IAM.2.g': 10,
    '.IAM.2.h': 10,
  },

  '.PR.PT': 'Protective Technology',
  '.PR.PT.1': {
    '.SA.1.a': 9,
    '.SA.2.a': 9,
    '.SA.1.b': 9,
    '.SA.1.c': 9,
    '.SA.2.e': 8,
    '.SA.4.a': 8,
    '.SA.1.d': 8,
    '.SA.1.e': 8,
    '.SA.3.d': 8,
    '.SA.4.e': 8,
    '.SA.4.f': 8,
    '.SA.4.g': 8,
  },
  '.PR.PT.2': {
    '.IAM.2.a': 20,
    '.IAM.2.b': 20,
    '.IAM.2.c': 20,
    '.IAM.3.e': 20,
    '.IAM.3.f': 20,
  },
  '.PR.PT.3': {
    '.IAM.2.a': 12,
    '.IAM.2.b': 11,
    '.IAM.2.c': 11,
    '.IAM.2.d': 11,
    '.IAM.2.e': 11,
    '.IAM.2.f': 11,
    '.IAM.2.g': 11,
    '.IAM.2.h': 11,
    '.IAM.2.i': 11,
  },
  '.PR.PT.4': {
    '.CPM.3.a': 25,
    '.CPM.3.b': 25,
    '.CPM.3.c': 25,
    '.CPM.3.d': 25,
  },
  /* These come from the v1.1 model and were mapped by Axio (David White) */
  '.PR.PT.5': {
    '.IR.4.a': 50,
    '.SA.3.f': 50,
  },
  /* End of v1.1 mapping */

  '.DE': 'DETECT',
  '.DE.AE': 'Anomalies and Events',
  '.DE.AE.1': {
    '.SA.2.a': 100,
  },
  '.DE.AE.2': {
    '.IR.1.f': 34,
    '.IR.2.i': 33,
    '.IR.3.h': 33,
  },
  '.DE.AE.3': {
    '.IR.1.e': 34,
    '.IR.1.f': 33,
    '.IR.2.i': 33,
  },
  '.DE.AE.4': {
    '.IR.2.b': 20,
    '.IR.2.d': 20,
    '.TVM.1.d': 20,
    '.IR.2.g': 20,
    '.RM.2.j': 20,
  },
  '.DE.AE.5': {
    '.IR.2.a': 17,
    '.IR.2.d': 17,
    '.TVM.1.d': 17,
    '.SA.2.d': 17,
    '.IR.2.g': 16,
    '.RM.2.j': 16,
  },

  '.DE.CM': 'Security Continuous Monitoring',
  '.DE.CM.1': {
    '.SA.2.a': 15,
    '.SA.2.b': 15,
    '.SA.2.e': 14,
    '.SA.2.f': 14,
    '.TVM.1.d': 14,
    '.SA.2.g': 14,
    '.SA.2.i': 14,
  },
  '.DE.CM.2': {
    '.SA.2.a': 25,
    '.SA.2.b': 25,
    '.SA.2.e': 25,
    '.SA.2.i': 25,
  },
  '.DE.CM.3': {
    '.SA.2.a': 25,
    '.SA.2.b': 25,
    '.SA.2.e': 25,
    '.SA.2.i': 25,
  },
  '.DE.CM.4': {
    '.SA.2.a': 20,
    '.SA.2.b': 20,
    '.SA.2.e': 20,
    '.CPM.4.a': 20,
    '.SA.2.i': 20,
  },
  '.DE.CM.5': {
    '.SA.2.a': 20,
    '.SA.2.b': 20,
    '.SA.2.e': 20,
    '.SA.2.h': 20,
    '.SA.2.i': 20,
  },
  '.DE.CM.6': {
    '.EDM.2.a': 17,
    '.SA.2.a': 17,
    '.SA.2.b': 17,
    '.SA.2.e': 17,
    '.EDM.2.j': 16,
    '.EDM.2.n': 16,
  },
  '.DE.CM.7': {
    '.SA.2.a': 15,
    '.SA.2.b': 15,
    '.SA.2.e': 14,
    '.SA.2.f': 14,
    '.TVM.1.d': 14,
    '.SA.2.g': 14,
    '.SA.2.i': 14,
  },
  '.DE.CM.8': {
    '.TVM.2.e': 20,
    '.TVM.2.i': 20,
    '.TVM.2.j': 20,
    '.TVM.2.k': 20,
    '.RM.1.c': 20,
  },

  '.DE.DP': 'Detection Processes',
  '.DE.DP.1': {
    '.WM.1.a': 34,
    '.WM.1.d': 33,
    '.WM.1.f': 33,
  },
  '.DE.DP.2': {
    '.IR.1.d': 15,
    '.IR.5.a': 15,
    '.TVM.1.d': 14,
    '.IR.1.g': 14,
    '.IR.5.f': 14,
    '.RM.1.c': 14,
    '.RM.2.j': 14,
  },
  '.DE.DP.3': {
    '.IR.3.e': 50,
    '.IR.3.j': 50,
  },
  '.DE.DP.4': {
    '.IR.1.b': 12.5,
    '.IR.3.c': 12.5,
    '.ISC.1.a': 12.5,
    '.ISC.1.c': 12.5,
    '.ISC.1.d': 12.5,
    '.IR.3.n': 12.5,
    '.ISC.1.h': 12.5,
    '.ISC.1.j': 12.5,
  },
  '.DE.DP.5': {
    '.IR.3.h': 50,
    '.IR.3.k': 50,
  },

  '.RS': 'RESPOND',
  '.RS.RP': 'Response Planning',
  '.RS.RP.1': {
    '.IR.3.d': 100,
  },

  '.RS.CO': 'Communications',
  '.RS.CO.1': {
    '.IR.3.a': 50,
    '.IR.5.b': 50,
  },
  '.RS.CO.2': {
    '.IR.1.a': 50,
    '.IR.1.b': 50,
  },
  '.RS.CO.3': {
    '.ISC.1.a': 15,
    '.ISC.1.b': 15,
    '.IR.3.d': 14,
    '.ISC.1.c': 14,
    '.ISC.1.d': 14,
    '.IR.3.i': 14,
    '.IR.3.l': 14,
  },
  '.RS.CO.4': {
    '.IR.3.d': 50,
    '.IR.5.b': 50,
  },
  '.RS.CO.5': {
    '.ISC.1.a': 10,
    '.ISC.1.c': 10,
    '.ISC.1.d': 10,
    '.ISC.1.e': 10,
    '.ISC.1.f': 10,
    '.ISC.1.h': 10,
    '.ISC.1.i': 10,
    '.ISC.1.j': 10,
    '.ISC.1.k': 10,
    '.ISC.1.l': 10,
  },

  '.RS.AN': 'Analysis',
  '.RS.AN.1': {
    '.IR.1.e': 50,
    '.IR.1.f': 50,
  },
  '.RS.AN.2': {
    '.IR.2.d': 25,
    '.TVM.1.d': 25,
    '.IR.2.g': 25,
    '.RM.2.j': 25,
  },
  '.RS.AN.3': {
    '.IR.3.d': 34,
    '.IR.3.h': 33,
    '.IR.3.i': 33,
  },
  '.RS.AN.4': {
    '.IR.2.a': 34,
    '.IR.1.d': 33,
    '.IR.1.e': 33,
  },
  /* These come from the v1.1 model and were mapped by Axio (David White) */
  '.RS.AN.5': {
    '.TVM.2.a': 5,
    '.TVM.2.b': 5,
    '.TVM.2.c': 5,
    '.TVM.2.d': 40,
    '.TVM.2.f': 15,
    '.TVM.2.g': 15,
    '.TVM.3.a': 15,
  },
  /* End of v1.1 mapping */

  '.RS.MI': 'Mitigation',
  '.RS.MI.1': {
    '.IR.3.b': 100,
  },
  '.RS.MI.2': {
    '.IR.3.b': 100,
  },
  '.RS.MI.3': {
    '.TVM.2.c': 17,
    '.TVM.2.f': 17,
    '.TVM.2.g': 17,
    '.RM.2.j': 17,
    '.TVM.2.m': 16,
    '.TVM.2.n': 16,
  },

  '.RS.IM': 'Improvements',
  '.RS.IM.1': {
    '.IR.3.h': 100,
  },
  '.RS.IM.2': {
    '.IR.3.h': 50,
    '.IR.3.k': 50,
  },

  '.RC': 'RECOVER',
  '.RC.RP': 'Recovery Planning',
  '.RC.RP.1': {
    '.IR.3.b': 25,
    '.IR.3.d': 25,
    '.IR.3.o': 25,
    '.IR.4.k': 25,
  },

  '.RC.IM': 'Improvements',
  '.RC.IM.1': {
    '.IR.3.h': 34,
    '.IR.4.i': 33,
    '.IR.3.k': 33,
  },
  '.RC.IM.2': {
    '.IR.3.h': 50,
    '.IR.3.k': 50,
  },

  '.RC.CO': 'Communications',
  '.RC.CO.1': {
    '.RM.1.c': 100,
  },
  '.RC.CO.2': {
    '.IR.3.d': 100,
  },
  '.RC.CO.3': {
    '.IR.3.d': 100,
  },
};
