import { Map as ImmutableMap } from 'immutable';
import { get as _get, flatten, sortBy, groupBy } from 'lodash';
import { createSelector } from 'reselect';

import { compareMultiple } from 'common/components/SortFilterArea/utils';
import { getUser } from 'common/selectors';
import {
  getUserJS,
  getUserEmployer,
  getLicenseModelGrants,
  getLicenseModelGrantsKeys,
} from 'common/selectors/user';
import { IAssessmentState } from '../reducers';
import { IAssessmentFactory } from '../reducers/models/model';
import { score } from '../score/score';
import {
  AccessType,
  IAssessment,
  ModelScope,
  IBenchmark,
} from '../databaseTypes';

import type { AssessmentFragment } from 'common/graphql/graphql-hooks';
import type { IUser, ICompany } from 'common/databaseTypes';

export const getApp = (state: IAssessmentState) => state.app;
export const getSubsidiaries = (state: IAssessmentState) => state.subsidiaries;
export const getAllAssessments = (state: IAssessmentState) => state.assessments;
export const getAllBenchmarks = (state: IAssessmentState) => state.benchmarks;
export const getAllLevelHistory = (state: IAssessmentState) =>
  state.levelHistory;
export const getAllStatusHistory = (state: IAssessmentState) =>
  state.statusHistory;
export const getAllMilestones = (state: IAssessmentState) => state.milestones;
export const getAllResponses = (state: IAssessmentState) => state.responses;
export const getOriginalResponses = (state: IAssessmentState) =>
  state.app['originalResponses'];
export const getUserGroups = (state: IAssessmentState) => state.userGroups;
export const getAllCoworkers = (state: IAssessmentState) => state.coworkers;
export const getAllTags = (state: IAssessmentState) => state.tags;
export const getAllLinks = (state: IAssessmentState) => state.links;
export const getLoaded = (state: IAssessmentState) => state.loaded;
export const getJournal = (state: IAssessmentState) => state.journal;

export const getModelsByUuid = (state: IAssessmentState) =>
  state.licensedModels;

export const getModels = createSelector([getModelsByUuid], (modelsByType) => {
  return [...modelsByType.values()];
});

export const getLicensedModels = createSelector(
  [getModels, getLicenseModelGrantsKeys],
  (models, keys) => models.filter((x) => keys.includes(x.licenseKey))
);

export const getModelsFactories = createSelector([getModels], (models) =>
  models.flatMap((model) => {
    return sortBy(flatten(model.factories), 'sortKey');
  })
);

const getLicensedModelsFactoriesNotFlattened = createSelector(
  [getLicensedModels, getLicenseModelGrants],
  (licensedModels, modelGrants) =>
    licensedModels.map((model) => {
      //find grant for this model
      const grant = modelGrants.find(
        (modelGrant) => model.licenseKey === modelGrant.key
      );
      //filter out ones that are null
      return model.factories.filter((factory) =>
        factory.requiredLicenseOption
          ? grant?.options.includes(factory.requiredLicenseOption)
          : true
      );
    })
);

export const getLicensedModelsFactories = createSelector(
  [getLicensedModelsFactoriesNotFlattened],
  (factories) => {
    const sortedFactories: IAssessmentFactory[] = sortBy(
      flatten(factories),
      'sortKey'
    );
    const duplicateFilterLabelledFactories = groupBy(
      sortedFactories,
      'filterLabel'
    );
    for (const duplicateFilterLabel in duplicateFilterLabelledFactories) {
      const factoryList =
        duplicateFilterLabelledFactories[duplicateFilterLabel];
      if (factoryList.length > 1) {
        factoryList.forEach((factory) => {
          if (factory.qualifier) {
            factory.filterLabel = `${factory.filterLabel} (${factory.qualifier})`;
          }
        });
      }
    }
    return sortedFactories;
  }
);

export const getLicensedModelsFactoriesWithoutImports = createSelector(
  [getLicensedModelsFactories],
  (factories) => factories.filter((x) => !x.requiresImporter)
);

export const getAllPendingUpdates = (state: IAssessmentState) =>
  state.pendingUpdates;

type Coworker = IUser & { employer: ICompany | null };
export type CoworkerMap = ImmutableMap<string, Coworker>;
export const getCoworkers = createSelector(
  [getUserJS, getAllCoworkers],
  (user, coworkers): CoworkerMap => {
    // add current user into coworkers list if missing
    return coworkers
      .set(user._id, { ...user, isInternalUser: true })
      .map<Coworker>((coworker) => ({
        ...coworker,
        employer: coworker?.isInternalUser ? user.employer : null,
      }));
  }
);

export const getSelectedMilestoneId = createSelector(
  [getApp],
  (app) => app.selectedMilestoneId
);

export const getSelectedMilestoneForEditId = createSelector(
  [getApp],
  (app) => app.selectedMilestoneForEditId
);

export const getComparisonAssessmentId = createSelector(
  [getApp],
  (app) => app.comparisonAssessmentId
);

export const getAllTargetProfileAssessments = createSelector(
  getAllAssessments,
  (assessments) =>
    assessments.filter((assessment) => !!assessment?.targetProfile)
);

const isRealAssessment = (targetProfile: boolean, accessType: string[]) => {
  return (
    !targetProfile &&
    (accessType.length > 1 ||
      (accessType.length > 0 && accessType[0] !== AccessType.ADMIN))
  );
};

export const getAllRealAssessments = createSelector(
  [getAllAssessments],
  (assessments) =>
    assessments.filter((assessment) =>
      isRealAssessment(
        !!assessment?.targetProfile,
        assessment?.accessType ?? []
      )
    )
);

export const getAreAssessmentsMultiCompany = createSelector(
  [getAllRealAssessments],
  (assessments) => {
    const companyId = _get(assessments.first(), ['company', '_id'], '');
    return assessments.some(
      (assessment) => _get(assessment, ['company', '_id'], '') !== companyId
    );
  }
);

export const getAvailableFilterTypes = createSelector(
  [getAllRealAssessments],
  (assessments) => {
    return Array.from(
      assessments.reduce((accum, assessment) => {
        assessment?.accessType ??
          []
            .filter((type) => type !== AccessType.ADMIN)
            .forEach((type) => accum.add(type));
        accum.add(`ModelSpec:${assessment.model}:${assessment.modelScope}`);
        return accum;
      }, new Set<string>())
    ).sort();
  }
);

export const getAssessmentListAsArray = createSelector(
  [getAllRealAssessments],
  (assessments) => assessments.valueSeq().toArray()
);

export const getAssessmentListFilterCriteria = createSelector(
  [getApp],
  (app) => app.filterCriteria
);

export const getAssessmentListSortCriteria = createSelector(
  [getApp],
  (app) => app.assessmentSort
);

export const getActiveFilterCriteria = createSelector(
  [getUser, getAssessmentListFilterCriteria],
  (user, filterCriteria) => {
    // Make sure this function always returns a string.  Callers
    // of it do a "criteria.startsWith" which is of course unhappy
    // if criteria is null or undefined
    return filterCriteria || user.get('last_filter') || '';
  }
);

// Model
export const getModelFilterFn = (criteria: string[]) => {
  const modelPrefix = 'ModelSpec:';
  const modelCriteria = criteria
    .filter((c) => c.startsWith(modelPrefix))
    .map((c) => {
      const [, modelUuid, modelScope] = c.split(':');
      return { modelUuid, modelScope };
    });

  const modelFilterFn = (modelUuid: string, modelScope: ModelScope) => {
    if (!modelCriteria.length) {
      return true;
    }

    return modelCriteria.some(
      (mc) => modelUuid === mc.modelUuid && modelScope === mc.modelScope
    );
  };

  return modelFilterFn;
};

//Lifecycle
export const getlifecycleFilterFn = (criteria: string[]) => {
  const lifecyclePrefix = '-STATE-';
  const lifecycleStates = criteria
    .filter((c) => c.startsWith(lifecyclePrefix))
    .map((c) => c.split(lifecyclePrefix)[1]);
  const lifecycleFilterFn = (assessment: IAssessment | AssessmentFragment) => {
    if (!lifecycleStates.length) {
      return true;
    }
    return lifecycleStates.some((state) =>
      assessment?.assessmentState?.includes(state)
    );
  };
  return lifecycleFilterFn;
};

//FilterKeyword
export const getfilterKeywordFilterFn = (criteria: string[]) => {
  const filterByKeywordPrefix = '-FILTERKEYWORD-';
  const filterKeyword = criteria
    .filter((c) => c.startsWith(filterByKeywordPrefix))
    .map((c) => {
      const [, searchString] = c.split(':');
      return searchString;
    });
  const filterKeywordFilterFn = (
    assessment: IAssessment | AssessmentFragment
  ) => {
    if (!filterKeyword.length) {
      return true;
    }
    return filterKeyword.some((keyword) =>
      assessment?.name?.toLowerCase().includes(keyword.toLowerCase())
    );
  };
  return filterKeywordFilterFn;
};

export const filterAssessmentList = (
  user: ReturnType<typeof getUser>,
  assessments: ReturnType<typeof getAllRealAssessments>,
  criteria: ReturnType<typeof getActiveFilterCriteria>
) => {
  const employerId = user.getIn(['employer', '_id']);
  const splitCriteriaIntoArray = criteria.split(',');

  //Access type - myCompany, AccessType..., UserGroup:...
  const accessTypeSimpleOptions = new Set<string>([
    AccessType.OWNER,
    AccessType.COWORKER,
    AccessType.TARGET_PROFILE,
  ]);
  const userGroupPrefix = `${AccessType.USER_GROUP}:`;
  const simpleAccessCriteria = splitCriteriaIntoArray.filter<AccessType>(
    (c): c is AccessType => accessTypeSimpleOptions.has(c)
  );
  const userGroupCriteria: AccessType[] = splitCriteriaIntoArray.filter<AccessType>(
    (c): c is AccessType => c.startsWith(userGroupPrefix)
  );
  const includeCompanyMatch = splitCriteriaIntoArray.includes('MyCompany');
  const accessTypeFilterFn = (assessment: IAssessment) => {
    if (
      !simpleAccessCriteria.length &&
      !userGroupCriteria.length &&
      !includeCompanyMatch
    ) {
      return true;
    }
    if (!assessment.accessType) {
      return true;
    }

    const simpleMatch = simpleAccessCriteria.some((at) =>
      assessment.accessType.includes(at)
    );
    if (simpleMatch) {
      return true;
    }
    const companyMatch =
      includeCompanyMatch && assessment.company?._id === employerId;
    if (companyMatch) {
      return true;
    }
    return userGroupCriteria.some((at) => assessment.accessType.includes(at));
  };

  //Tags
  const tagPrefix = '-TAGS-';
  const tagIds = splitCriteriaIntoArray
    .filter((c) => c.startsWith(tagPrefix))
    .map((c) => c.split(tagPrefix)[1]);
  const tagFilterFn = (assessment: IAssessment) => {
    if (!tagIds.length) {
      return true;
    }
    return !!assessment?.scopetags?.some((t) => tagIds.includes(t));
  };

  const modelFilterFn = getModelFilterFn(splitCriteriaIntoArray);
  const lifecycleFilterFn = getlifecycleFilterFn(splitCriteriaIntoArray);
  const filterKeywordFilterFn = getfilterKeywordFilterFn(
    splitCriteriaIntoArray
  );

  return assessments.filter(
    (assessment) =>
      modelFilterFn(assessment.model, assessment.modelScope) &&
      lifecycleFilterFn(assessment) &&
      filterKeywordFilterFn(assessment) &&
      tagFilterFn(assessment) &&
      accessTypeFilterFn(assessment)
  );
};

export const getFilteredAssessmentList = createSelector(
  [getUser, getAllRealAssessments, getActiveFilterCriteria],
  filterAssessmentList
);

//could consider all scores around but that might be too much calculation
export const getFilteredAssessmentScoreLookup = createSelector(
  [getFilteredAssessmentList, getAllResponses, getModelsByUuid],
  (assessmentList, allResponses, modelsByType) =>
    assessmentList.reduce(
      (accum, assessment) => {
        const responsesForAssessment = allResponses.get(assessment._id);
        if (responsesForAssessment) {
          const model = modelsByType.get(assessment.model);
          if (!model) {
            return accum;
          }

          const assessmentScore = score(
            model,
            //@ts-expect-error score causes app-wide issues
            responsesForAssessment,
            assessment.modelScope,
            'level',
            null
          );
          const assessmentTargetScore = score(
            model,
            //@ts-expect-error score causes app-wide issues
            responsesForAssessment,
            assessment.modelScope,
            'targetLevel'
          );
          accum['score'][assessment._id] = assessmentScore;
          accum['targetScore'][assessment._id] = assessmentTargetScore;
        }
        return accum;
      },
      { score: {}, targetScore: {} }
    )
);

export const getFilteredAssessmentListWithScores = createSelector(
  [getFilteredAssessmentList, getFilteredAssessmentScoreLookup],
  (filteredSortedAssessmentList, scoreLookup) =>
    filteredSortedAssessmentList.map((assessment) => {
      // scoreLookup is empty during initial render
      if (!Object.values(scoreLookup).length) return assessment;
      const assessmentScore = scoreLookup['score'][assessment._id];
      const assessmentTargetScore = scoreLookup['targetScore'][assessment._id];
      if (!assessmentScore || !assessmentTargetScore) {
        return assessment;
      }
      return {
        ...assessment,
        score: assessmentScore,
        targetScore: assessmentTargetScore,
      };
    })
);

export const getFilterTypeFromCriteria = (criteria: string) => {
  return criteria.includes('-AND-') ? 'AND' : 'OR';
};

export const getAssessmentGroupFromFirmographicData = createSelector(
  [getFilteredAssessmentList],
  (assessmentList) => {
    const filteredAssessments = (level: string) =>
      assessmentList.filter(
        (assessment) =>
          assessment.firmographicData?.['Inherent Risk Score and Level'] ===
          level
      );
    return {
      riskLevelOne: filteredAssessments('1'),
      riskLevelTwo: filteredAssessments('2'),
      riskLevelThree: filteredAssessments('3'),
    };
  }
);

const filterAssessmentId = (assessments: ImmutableMap<string, IAssessment>) => {
  return assessments
    .map((assessment) => assessment._id)
    .valueSeq()
    .toArray();
};

export const getFilteredAssessmentIdsFromRiskGroup = createSelector(
  [getAssessmentGroupFromFirmographicData],
  (assessmentGroup) => {
    const riskGroupOne = filterAssessmentId(assessmentGroup.riskLevelOne);
    const riskGroupTwo = filterAssessmentId(assessmentGroup.riskLevelTwo);
    const riskGroupThree = filterAssessmentId(assessmentGroup.riskLevelThree);

    return {
      riskGroupOne,
      riskGroupTwo,
      riskGroupThree,
    };
  }
);

export const getFilteredResponsesFromRiskGroup = createSelector(
  [getAllResponses, getFilteredAssessmentIdsFromRiskGroup],
  (responses, assessments) => {
    const riskGroupOne = responses.filter((_value, key) =>
      assessments.riskGroupOne.includes(key)
    );
    const riskGroupTwo = responses.filter((_value, key) =>
      assessments.riskGroupTwo.includes(key)
    );
    const riskGroupThree = responses.filter((_value, key) =>
      assessments.riskGroupThree.includes(key)
    );

    return { riskGroupOne, riskGroupTwo, riskGroupThree };
  }
);

export const getFilteredAssessmentIds = createSelector(
  [getFilteredAssessmentList],
  (assessments) => {
    return filterAssessmentId(assessments);
  }
);

export const getFilteredResponses = createSelector(
  [getAllResponses, getFilteredAssessmentIds],
  (responses, assessments) => {
    return responses.filter((_value, key) => assessments.includes(key));
  }
);

export const getAreFilteredAssessmentsOfSameModelAndScope = createSelector(
  [getFilteredAssessmentList],
  (assessments) => {
    // Check to make sure the assessment type of the first element
    // matches every other item. For our purposes, an empty
    // list counts as "not homogenous", since we can't aggregate
    // nothing.
    const firstAssessment: IAssessment = assessments.first();
    if (!firstAssessment) {
      return false;
    }
    // For now, we need both the model scope and the model type
    // to match.  Later, we might be able to aggregate if just
    // the model type matches.
    return assessments.every(
      (assessment) =>
        assessment.model === firstAssessment.model &&
        assessment.modelScope === firstAssessment.modelScope
    );
  }
);

export const getAreFilteredAssessmentsOfSameModel = createSelector(
  [getFilteredAssessmentList],
  (assessments) => {
    // Check to make sure the assessment model of the first element
    // matches every other item. For our purposes, an empty
    // list counts as "not homogenous", since we can't aggregate
    // nothing.
    const firstAssessment: IAssessment = assessments.first();
    if (!firstAssessment) {
      return false;
    }
    return assessments.every(
      (assessment) => assessment.model === firstAssessment.model
    );
  }
);

/* kb: C2M2-QL and C2M2-Foundations don't aggregate together well.
do we need agg dashboard for cmmc */
export const getFilteredAssessmentsIncludeQuicklaunch = createSelector(
  [getFilteredAssessmentList],
  (assessments) => {
    return assessments.some(
      (assessment) => assessment.modelScope === ModelScope.Quicklaunch
    );
  }
);

export const sortFunctionForOrder = (order: string) => {
  const parts = order.split('_');
  const isDescending = !order || parts[0] === 'D';
  const method = !order || parts.length < 2 ? 'DateUpdated' : parts[1];
  switch (method) {
    case 'AssessmentName':
      return (o1, o2) => {
        const d1 = o1.name || '';
        const d2 = o2.name || '';
        return isDescending ? d2.localeCompare(d1) : d1.localeCompare(d2);
      };
    case 'CompanyName':
      return (o1, o2) => {
        const d1 = _get(o1, ['company', 'name'], '');
        const d2 = _get(o2, ['company', 'name'], '');
        return isDescending ? d2.localeCompare(d1) : d1.localeCompare(d2);
      };
    case 'Score':
      return (o1, o2) => {
        const d1 = _get(o1, ['score', 'score'], 0);
        const d2 = _get(o2, ['score', 'score'], 0);
        return isDescending ? d2 - d1 : d1 - d2;
      };
    case 'TargetScore':
      return (o1, o2) => {
        const d1 = _get(o1, ['targetScore', 'score'], 0);
        const d2 = _get(o2, ['targetScore', 'score'], 0);
        return isDescending ? d2 - d1 : d1 - d2;
      };
    case 'DateUpdated':
    default:
      return (o1, o2) => {
        const d1 =
          o1._updated ??
          o1._created ??
          o1['dateLastUpdated'] ??
          o1['dateCreated'];
        const d2 =
          o2._updated ??
          o2._created ??
          o2['dateLastUpdated'] ??
          o2['dateCreated'];
        return isDescending ? d2.localeCompare(d1) : d1.localeCompare(d2);
      };
  }
};

export const getActiveSortCriteria = createSelector(
  [getUser, getAssessmentListSortCriteria],
  (user, sortCriteria) => {
    return sortCriteria.length ? sortCriteria : user.get('last_sort', []);
  }
);

export const sortAssessmentsByCriteria = (
  assessments: ImmutableMap<string, IAssessment>,
  criteria: string[]
) => {
  if (!criteria || !Array.isArray(criteria)) {
    return assessments
      .sortBy((assessment) => assessment._updated || assessment._created)
      .reverse();
  }
  const funcs = criteria.map((order) => sortFunctionForOrder(order));
  return assessments.sort((assessmentA, assessmentB) =>
    compareMultiple(assessmentA, assessmentB, funcs)
  );
};

export const getFilteredSortedAssessments = createSelector(
  [getFilteredAssessmentListWithScores, getActiveSortCriteria],
  (assessments, criteria) => sortAssessmentsByCriteria(assessments, criteria)
);

export const getSelectedAssessmentId = createSelector(
  [getApp],
  (app) => app.selectedAssessmentId
);

export const getSelectedSubsidiaryId = createSelector(
  [getApp],
  (app) => app.selectedSubsidiaryId
);

export const getResponses = createSelector(
  [getAllResponses, getSelectedAssessmentId],
  (allResponses, assessmentId) =>
    getFromMap(allResponses, assessmentId, ImmutableMap({}))
);

export const hasFetchedResponses = createSelector(
  [getAllResponses, getSelectedAssessmentId],
  (allResponses, assessmentId) => allResponses.has(assessmentId)
);

export const getComparisonAssessmentResponses = createSelector(
  [getAllResponses, getComparisonAssessmentId],
  (responses, assessmentId) =>
    getFromMap(responses, assessmentId, ImmutableMap({}))
);

export const getAllCompanyBenchmark = createSelector(
  [getAllBenchmarks, getSelectedAssessmentId],
  (benchmarks, assessmentId) => {
    const allBenchmarksForAssessment = getFromMap(
      // @ts-expect-error ImmutableJS ImmutableMap Method Type Mismatch
      benchmarks,
      assessmentId,
      null
    )?.filter((benchmark) => !('cssa_peer_group' in benchmark));

    return allBenchmarksForAssessment
      ? allBenchmarksForAssessment[0]
      : ({} as IBenchmark);
  }
);

export const getPeerBenchmark = createSelector(
  [getAllBenchmarks, getSelectedAssessmentId, getUserEmployer],
  (benchmarks, assessmentId, employer) => {
    // @ts-expect-error ImmutableJS ImmutableMap Method Type Mismatch
    const allBenchmarks = getFromMap(benchmarks, assessmentId, null) ?? [];
    const peerBenchmark = allBenchmarks.find(
      (benchmark) => benchmark.cssa_peer_group === employer?.benchmarkPeerGroup
    );
    return peerBenchmark ?? ({} as IBenchmark);
  }
);

export const getHistoricalData = createSelector(
  [getAllLevelHistory, getSelectedAssessmentId],
  (levelHistory, assessmentId) => {
    return getFromMap(levelHistory, assessmentId, {
      loaded: true,
      tempModelUuid: '',
      tempHistory: [],
      tempResponses: [],
    });
  }
);

export const getStatusHistory = createSelector(
  [getAllStatusHistory, getSelectedAssessmentId],
  (statusHistory, assessmentId) => {
    return getFromMap(statusHistory, assessmentId, {
      loaded: true,
      changes: [],
    });
  }
);

export const hasFetchedLevelHistory = createSelector(
  [getAllLevelHistory, getSelectedAssessmentId],
  (levelHistory, assessmentId) => {
    const t = levelHistory.get(assessmentId, { loaded: false });
    return t.loaded;
  }
);

export const hasFetchedStatusHistory = createSelector(
  [getAllStatusHistory, getSelectedAssessmentId],
  (statusHistory, assessmentId) => {
    const t = statusHistory.get(assessmentId, { loaded: false });
    return t.loaded;
  }
);

export const getBitsightCSF = (state: IAssessmentState) =>
  state.bitsight.get('CSF');
export const getBitsightC2M2 = (state: IAssessmentState) =>
  state.bitsight.get('C2M2');

export const getNewAssessment = createSelector(
  [getApp],
  (app) => app.newAssessment
);

////////////////////////////////////////////////////////////////////////////////
// Helpers

export function getFromMap<T>(
  map: ImmutableMap<string, T>,
  key: string,
  defaultValue: T
) {
  return map && key ? map.get(key, defaultValue) : defaultValue;
}
