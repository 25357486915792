// This file holds the theme elements that are common across all modules.
import archerBackground from './archer_background.png';

import { defaultTheme } from '../default';
import clientLogo from './Archer_Logo_RGB_Color.png';
import poweredByLogo from '../default/Axio360.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';

export const ARCHER_ORANGE = '#dc6d29';
export const ARCHER_GREEN = '#3a9e41';
export const ARCHER_BLACK = '#000000';
export const archerTheme = {
  ...defaultTheme,
  title: 'Axio360',
  name: 'archer',
  poweredBy: true,
  tocPattern: defaultTheme.backgroundImage,
  homepage: 'https://archerint.com/',
  clientName: 'ARCHER',
  clientNameLong: 'Archer',
  logoHeight: 43,
  clientLogo,
  authLogo: clientLogo,
  poweredByLogo: poweredByLogo,
  authPoweredByLogo: authPoweredByLogo,
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: archerBackground,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    primaryButton: ARCHER_ORANGE,
    primaryButtonText: ARCHER_BLACK,
    authBackground: defaultTheme.colors.darkGray,
    minimalButtonTextColor: ARCHER_BLACK,
  },
};
