import { Map } from 'immutable';
import { AnyAction, Reducer } from 'redux';

import { GranteeType, ITag } from 'common/databaseTypes';
import { stripMeta } from 'common/utils/AxioUtilities';
import { ASSESSMENT_ID_FIELD } from '../constants';
import { AccessType, IAssessment } from '../databaseTypes';
import { Position } from '../qlTypes';
import { ASSESSMENT_DOMAIN } from './pendingUpdates';
import {
  AddAssessmentPayload,
  Assessment,
  Grantee,
  LegacyModelIdEnum,
  ModelScope,
  User,
} from 'common/graphql/graphql-hooks';
import { notEmpty } from 'common/utils/typeGuards';

export function getModelUuid(assessment: IAssessment) {
  return assessment ? assessment.model || LegacyModelIdEnum.C2M2 : undefined;
}

export const scopeTagsForAssessment = (
  assessment: IAssessment | undefined,
  allScopeTags: Map<string, ITag>
): Map<string, ITag> => {
  if (!!assessment && !!assessment.scopetags) {
    const scopetagsForFlow = assessment.scopetags;
    return allScopeTags.filter((x) => {
      return x ? scopetagsForFlow.includes(x._id) : false;
    }) as any;
  } else {
    return Map({});
  }
};

export const scopeTagNamesForAssessment = (
  assessment: IAssessment | undefined,
  allScopeTags: Map<string, ITag>
) => {
  const currentScopeTags = scopeTagsForAssessment(assessment, allScopeTags);

  return currentScopeTags
    ? currentScopeTags
        .valueSeq()
        .toArray()
        .map((value) => value.name)
    : [];
};

const initialState = Map<IAssessment>({});

const getAssessmentDefaults = () => {
  return {
    company: {},
    milestones: [],
    grantees: [],
    read_only: false,
  };
};

function extractQLPosition(action: AnyAction): Position {
  return (
    (action &&
      action.request &&
      action.request.resource === ASSESSMENT_DOMAIN &&
      action.request.data &&
      action.request.data.quicklaunch_position) ||
    undefined
  );
}

export const assessmentsReducer: Reducer<Map<string, IAssessment>> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'APOLLO_MUTATION_RESULT': {
      if (action.result.errors) {
        break;
      }
      switch (action.operationName) {
        case 'AddAssessment': {
          const result: AddAssessmentPayload | undefined =
            action.result?.data?.addAssessment;
          if (!result) return state;

          const assessment: Assessment | null = result.assessment;
          const grantees: Grantee[] = result.grantees
            ? result.grantees.filter(notEmpty)
            : [];
          const owner: User | null = result.owner;

          return assessment
            ? state.set(assessment.id, {
                _id: assessment.id,
                _etag: assessment._etag,
                name: assessment.name,
                description: assessment.description,
                owner: owner ? owner.id : undefined,
                ownerInfo: owner
                  ? {
                      _id: owner.id,
                      email: owner.email,
                      name: owner.displayName,
                      displayName: '',
                      title: '',
                      department: '',
                      phone: '',
                      isNewUser: false,
                      employer: null,
                      twoFactorAuthEnabled: false,
                      roles: [],
                      type: GranteeType.USER,
                      active_assessment: null,
                      last_filter: '',
                      last_sort: [],
                      _etag: '',
                      _created: '',
                      isDeleted: null,
                    }
                  : undefined,
                model: assessment.modelUuid,
                modelScope: assessment.modelScope,
                quicklaunch_position: assessment.quicklaunchPosition,
                scopetags: assessment.scopetags
                  ? assessment.scopetags.filter(notEmpty).map((tag) => tag.id)
                  : [],
                _created: assessment.dateCreated,
                grantees: grantees.map((grantee) => ({
                  _id: grantee.id,
                  _etag: '',
                  grantee: grantee.grantee.id,
                  type: grantee.__typename,
                  read_only: grantee.readOnly,
                  grantee_type:
                    (grantee.__typename as string) === 'UserGroup'
                      ? GranteeType.USER_GROUP
                      : GranteeType.USER,
                  user: grantee.grantee,
                  _created: grantee.dateCreated,
                })),
                milestones: [],
                accessType: [assessment.accessType as string],
                read_only: false,
              } as IAssessment)
            : state;
        }
        case 'UpdateFirmographicData': {
          const result = action.result?.data?.updateFirmographicData ?? {};
          if (!result.id || !result.firmographicData) {
            return state;
          }
          const { id: assessmentId, firmographicData } = result;
          const assessment = state.get(assessmentId);
          if (!assessment) {
            return state;
          }
          return state.set(assessmentId, { ...assessment, firmographicData });
        }
        default:
          break;
      }
      break;
    }
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return initialState;
    }
    case 'CHANGE_QL_POSITION': {
      const id = action.assessmentId;
      const assessment = state.get(id);
      if (!assessment) {
        return state;
      }

      const newPosition = action.payload.quicklaunch_position;
      return state.set(id, {
        ...assessment,
        quicklaunch_position: newPosition,
      });
    }
    case 'subscribe': {
      // In the case of an Excel import, the assessment doesn't
      // exist in the assessments state slice but WILL be fetched
      // with the subscribe.  We need to merge the assessment into
      // the state slice.
      const newAssessment = action.response.assessment;
      const id = action.response.assessment._id;
      const assessment = state.get(id);
      const newUser = action.response.user;
      const userOwnsAssessment = newAssessment.owner === newUser._id;
      const userAccessViaCoworker = newAssessment.grantees.some(
        (item) =>
          item.grantee === newUser._id && item.grantee_type === 'userGroup'
      );
      const accessType = assessment
        ? assessment.accessType
        : newAssessment.accessType
        ? newAssessment.accessType
        : [
            userOwnsAssessment
              ? AccessType.OWNER
              : userAccessViaCoworker
              ? AccessType.COWORKER
              : AccessType.USER_GROUP,
          ];

      return state.set(id, {
        ...assessment,
        ...newAssessment,
        accessType,
      });
    }
    case 'item_replaced/assessments':
    case 'item_updated/assessments': {
      const newData = action.response.result;
      const id = action.response.result._id;
      const assessment = state.get(id) || { _id: id };
      return state.set(id, {
        ...assessment,
        ...newData,
      });
    }

    case 'server/patch_resource': {
      const qlPosition = extractQLPosition(action);
      if (qlPosition || qlPosition === 0) {
        const id = action.request.item_id;
        const assessment = state.get(id) || {
          ...(getAssessmentDefaults() as any),
          owner: action.request.userId,
          _id: id,
        };
        return state.set(id, {
          ...assessment,
          quicklaunch_position: qlPosition,
        });
      }
      return state;
    }
    case 'post_resource/assessments':
    case 'patch_resource/assessments': {
      const id =
        action.type === 'post_resource/assessments'
          ? action.response.result._id
          : action.request.item_id;
      const assessment = state.get(id) || {
        ...getAssessmentDefaults(),
        owner: action.request.userId,
        accessType: ['owner'],
        _id: id,
      };

      const newData = action.request.data;

      return state.set(id, {
        ...assessment,
        ...newData,
        _etag: action.response.result._etag,
        _created: action.response.result._created,
        _updated: action.response.result._updated,
        _version: action.response.result._version,
      });
    }

    case 'delete_resource/assessments': {
      const assessmentId = action.request._id;
      return state.delete(assessmentId);
    }

    case 'get_resource/grantees': {
      // This command pulls all the grantee information for a particular assessment.
      // If this is for our particular assessment, add the grantee info in.  Otherwise
      // ignore.  Because the get call doesn't include an assessment id,
      // the action.response[ASSESSMENT_ID_FIELD] isn't right.  It's the
      // ID of the active assessment.  What we need is the id of the assessment
      // requested, which is in the lookup of the action
      const { item: id, type } = action.request.lookup;
      if (type !== 'assessment') {
        return state;
      }
      const currentAssessment = state.get(id);
      if (!currentAssessment) {
        return state;
      }
      const assessment = {
        ...currentAssessment,
        _id: id,
        grantees: action.response.result._items,
      };
      return state.set(id, assessment);
    }

    case 'get_resource/assessments_for_user':
      return Map(
        action.response.result._items.map((assessment) => [
          assessment._id,
          {
            ...assessment,
            name: assessment.name || 'Untitled',
            modelScope: assessment.modelScope || ModelScope.Full,
          },
        ])
      );
    case 'post_resource/milestones': {
      const newMilestone = action.request.data;
      const newMilestoneMeta = action.response.result;
      return state.update(newMilestone[ASSESSMENT_ID_FIELD], (assessment) => {
        return {
          ...assessment,
          milestones: assessment.milestones.concat({
            ...stripMeta(newMilestoneMeta),
            ...newMilestone,
          }),
        };
      });
    }
    case 'patch_resource/milestones': {
      const assessmentId = action.request[ASSESSMENT_ID_FIELD];
      const updatedMilestone = action.request.data;
      const updatedMilestoneMeta = action.response.result;

      return state.update(assessmentId, (assessment) => {
        return {
          ...assessment,
          milestones: assessment.milestones
            .filter((milestone) => milestone._id !== updatedMilestoneMeta._id)
            .concat({
              [ASSESSMENT_ID_FIELD]: assessmentId,
              ...stripMeta(updatedMilestoneMeta),
              ...updatedMilestone,
            }),
        };
      });
    }
    case 'delete_resource/milestones': {
      const assessmentId = action.request[ASSESSMENT_ID_FIELD];
      const deletedMilestoneId = action.response.item_id;
      return state.update(assessmentId, (assessment) => {
        return {
          ...assessment,
          milestones: assessment.milestones.filter(
            (milestone) => milestone._id !== deletedMilestoneId
          ),
        };
      });
    }

    default:
      break;
  }

  return state;
};
