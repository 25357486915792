import React from 'react';
import styled from 'styled-components/macro';

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

// from https://codepen.io/davidangel/pen/NqPYdW
const Container = styled.div`
  color: ${(props) => props.theme.colors.axioBlue};
  margin-right: 5px;

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .one {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0s;
  }

  .two {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
  }

  .three {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
  }
`;

type DivProps = React.HTMLAttributes<HTMLDivElement>;

export const AnimatedEllipsis = (props: DivProps) => (
  <Container {...props}>
    <span className="one">.</span>
    <span className="two">.</span>
    <span className="three">.</span>
  </Container>
);

interface PrefixProps extends DivProps {
  prefix: string;
  ellipsisStyle?: React.CSSProperties;
}

export const AnimatedEllipsisWithPrefix = ({
  prefix,
  ellipsisStyle,
  ...rest
}: PrefixProps) => (
  <Row {...rest}>
    <span>{prefix}</span>
    <AnimatedEllipsis style={ellipsisStyle} />
  </Row>
);
