import { ffiec1AssessmentIcon as icon } from 'assessments/components/AssessmentTypeDropdown/assessmentIcons';
import { DashboardType } from 'assessments/constants';

import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';
import { LegacyModelConfig } from '..';

export const ffiec1Config: LegacyModelConfig = {
  icon,
  features: {
    bitsight: false,
    targetProfiles: true, //this is a choice that doesn't require code to impelment
    convertToFull: false,
    referenceAssessments: true, //this is a choice that doesn't require code to impelment
    milestones: true, //this is a choice that doesn't require code to impelment
    wizard: false,
    hasImportResponses: false,
    hasMilScoping: false,
    hasTierScoping: false,
    reports: {
      mil1: false,
      full: false,
      board: false,
      boardAppendix: false,
      comparison: false,
      derivedCSF: false,
    },
  },
  scoring: {
    topOfDomain: 100,
  },
  shortTitle: 'FFIEC CAT Part 1',
  factories: [
    {
      label: 'FFIEC Part 1 Assessment (Alpha)',
      filterLabel: 'FFIEC',
      icon: icon,
      sortKey: 'FFIEC',
      modelUuid: LegacyModelIdEnum.Ffiec1,
      modelScope: ModelScope.Full,
      requiresImporter: false,
      dashboardType: DashboardType.FFIEC1,
    },
  ],
};
