import { Map } from 'immutable';
import moment from 'moment';
import { AnyAction, Reducer } from 'redux';

import { ID_FIELD } from 'common/constants';
import { ASSESSMENT_ID_FIELD } from '../constants';
import { AssessmentId, FQN } from './responses';

export const ASSESSMENT_DOMAIN = 'assessments';
export const MILESTONE_DOMAIN = 'milestones';
export const ASSESSMENT_LIST_DOMAIN = 'assessments_for_user';
export const SUBSIDIARY_LIST_DOMAIN = 'subsidiaries_for_user';
export const GRANTEES_DOMAIN = 'grantees';
export const JOURNAL_DOMAIN = 'journal';
export const PRACTICE_DOMAIN = 'responses';
export const TAGS_DOMAIN = 'tags';

export interface IResponseForEve {
  _practice_id?: string;
  isFlagged: boolean;
  links?: {
    [id: string]: {
      author: string;
      name?: string;
      location: string;
      timestamp: string;
      _id: string;
    };
  };
  level?: number;
  notes?: {
    [id: string]: {
      author: string;
      text: string;
      timestamp: string;
      _id: string;
    };
  };
  targetLevel?: number;
  targetDate?: string;
  _id: string;
  _etag: string;
  _assessment?: string;
  _created: string;
  _updated: string;
  _version?: string;
  _latest_version?: string;
}
type IState = Map<AssessmentId, Map<FQN, IResponseForEve>>;

const initialState: IState = Map({});

// We behave exactly like the base responses, it's just that we delete our values once they've been persisted.
export const pendingReducer: Reducer<IState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    // Pending updates (unlike the other reducers) MUSTN'T drill itself on
    // LOGOUT.  The ONLY reason we would have anything in us
    // is if there are unsent changes and we don't want to kill those.
    case 'COGNITO_LOGOUT':
      return state;

    case 'COGNITO_LOGIN':
    case 'server/unsubscribe':
    case 'server/subscribe': {
      // OK, bye bye data.
      return initialState;
    }

    case 'NETWORK_REMOVE_BAD_IDS':
    case 'NETWORK_SAVE_START':
      return removeObjectsFromPending(
        state,
        action[ASSESSMENT_ID_FIELD],
        action
      );
    case 'REQUEUE_UPDATE':
      return requeueUpdate(state, action[ASSESSMENT_ID_FIELD], action.update);
    case 'NETWORK_TIMEOUT':
      return requeueUpdate(
        state,
        action[ASSESSMENT_ID_FIELD],
        action.unsent.items
      );
    case 'DELETE_NOTE':
    case 'DELETE_LINK':
      return deleteEmbeddedItem(state, action[ASSESSMENT_ID_FIELD], action);

    // This code is here because it has to be
    // reduced by both responses and pending updates.
    case 'REHYDRATE': {
      const assessmentId: AssessmentId = action[ASSESSMENT_ID_FIELD];
      const items = itemsFromAction(action, assessmentId);
      if (items && items.size) {
        // NOT mergeWith here.  We need the -1 and empty objects to trigger server deletes
        const result = state.update(assessmentId, Map({}), (responses) =>
          responses.mergeDeep(items)
        );
        return result;
      }
      return state;
    }

    case 'UPDATE_NOTE':
    case 'CREATE_NOTE': {
      const assessmentId = action[ASSESSMENT_ID_FIELD];
      return state.update(assessmentId, Map({}), (responses) =>
        createOrUpdatePracticeField('note', responses, action)
      );
    }
    case 'UPDATE_LINK':
    case 'CREATE_LINK': {
      const assessmentId = action[ASSESSMENT_ID_FIELD];
      return state.update(assessmentId, Map({}), (responses) =>
        createOrUpdatePracticeField('link', responses, action)
      );
    }

    default: {
      if (action.practiceFields) {
        const assessmentId = action[ASSESSMENT_ID_FIELD];
        const practice = state.getIn([assessmentId, action.id]) || {};
        return state.update(assessmentId, Map({}), (responses) =>
          responses.set(action.id, {
            ...(practice as Object),
            ...action.practiceFields,
          })
        );
      }
      break;
    }
  }
  return state;
};

const removeObjectsFromPending = (
  state: IState,
  assessmentId: string,
  action: AnyAction
) => {
  // Network has started for a set of ids so kill those ids.
  // return state.deleteAll(action.ids);
  return state.update(assessmentId, Map({}), (responses) =>
    responses.withMutations((map) => {
      for (const id of action.ids) {
        map.remove(id);
      }
    })
  );
};

const requeueUpdate = (state: IState, assessmentId: string, updates: any) => {
  // Merge this update in with any we already have.
  const kvPairs: Array<[string, any]> = Object.entries(updates);
  return state.update(assessmentId, Map({}), (responses) =>
    responses.withMutations((map) => {
      kvPairs.forEach(([id, next]) => {
        const merged = { ...next, ...map.get(id) };
        delete merged._etag;
        delete merged[ID_FIELD];
        map.set(id, merged);
      });
    })
  );
};

function createOrUpdatePracticeField(
  field: 'actionItem' | 'note' | 'link',
  state: Map<FQN, IResponseForEve>,
  action: AnyAction
) {
  const practice = state.get(action.id);
  const { _id, _responsePracticeId, ...itemWithoutKey } = action[field];
  return state.set(action.id, {
    ...(practice ? practice : ({} as any)),
    [field + 's']: {
      ...(practice ? practice[field + 's'] : {}),
      [action.key]: {
        ...itemWithoutKey,
        timestamp: moment.utc().toISOString(),
      },
    },
  });
}

function deleteEmbeddedItem(
  state: IState,
  assessmentId: string,
  action: AnyAction
) {
  const field = action.type === 'DELETE_NOTE' ? 'notes' : 'links';
  const practice = state.getIn([assessmentId, action.id], {});
  //@ts-expect-error Invalid Overload Call
  const values = { ...(practice[field] || {}), [action.key]: {} };
  return state.update(assessmentId, Map({}), (responses) =>
    //@ts-expect-error Invalid Overload Call
    responses.set(action.id, { ...practice, [field]: values })
  );
}

function itemsFromAction(
  action: AnyAction,
  assessmentId: AssessmentId
): Map<FQN, IResponseForEve> {
  const items =
    action.payload && action.payload.pendingUpdates
      ? action.payload.pendingUpdates
          .get(assessmentId, Map({}))
          .filter((item, k) => item && k)
      : Map({});
  return items;
}
