import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { differenceWith, intersection, isEqual, omit } from 'lodash';

import {
  CoverageInput,
  FileInput,
  Layer,
  Sublimit,
} from 'common/graphql/graphql-hooks';
import { newMarker } from './views/MetadataForm/shared';

/**
 * Diff two objects and return any keys fron new that are not in old
 * @param {Object} oldObject
 * @param {Object} newObject
 * @param {Object} options object with two keys that contain arrays of strings:
 * include lists any key that should always be included, even when it has not changed
 * fields lists any key that should be checked for changes
 * @returns {Object} Returns an object of diffs.
 */
export const diff = (
  oldObject: object,
  newObject: object,
  options: { include: string[]; fields: string[] }
): object => {
  const { include, fields } = options;
  const result = {};
  for (const field in newObject) {
    if (
      include.includes(field) ||
      (fields.includes(field) &&
        oldObject &&
        !isEqual(newObject[field], oldObject[field]))
    ) {
      result[field] = Array.isArray(oldObject[field])
        ? diffArrays(oldObject[field], newObject[field])
        : newObject[field];
    }
  }
  return result;
};

const diffArrays = (
  oldArr: Array<CoverageInput | FileInput>,
  newArr: Array<CoverageInput | FileInput>
) => {
  // Remove coverage rows without a type
  const filteredNewArr = newArr.filter((item) =>
    'excess' in item ? item.type : true
  );
  const arrDff = differenceWith(filteredNewArr, oldArr, isEqual);
  // Coverages use fake ids for React reconciliation that we don't want to send to the server
  return arrDff.map((item) =>
    item.id && item.id.startsWith(newMarker)
      ? omit(item, ['id', '__typename', 'downloadUrl'])
      : omit(item, ['__typename', 'downloadUrl'])
  );
};

// The synonyms and extra types are because we allow user defined coverage types
const iconMap = new Map([
  ['fire', ['fas', 'fire']],
  ['cyber', ['fas', 'server']],
  ['property', ['fas', 'building']],
  ['general liability', ['far', 'shield-alt']],
  ['product liability', ['fal', 'boxes-alt']],
  ['products liability', ['fal', 'boxes-alt']],
  ['fiduciary liability', ['fas', 'money-bill']],
  ['employment practices liability', ['fas', 'handshake']],
  ['crime', ['fas', 'user-secret']],
  ['kidnap and ransom', ['fab', 'btc']],
  ['errors and omissions', ['far', 'exclamation-triangle']],
  ['terrorism', ['fas', 'bomb']],
  ['directors and officers', ['fas', 'user-tie']],
  ['terror', ['fas', 'bomb']],
  ['gl', ['far', 'shield-alt']],
  ['theft', ['far', 'person-carry']],
  ['commercial crime', ['far', 'person-carry']],
  ['k and r', ['fab', 'btc']],
  ['k&r', ['fab', 'btc']],
  ['d and o', ['fas', 'user-tie']],
  ['d&o', ['fas', 'user-tie']],
  ['vehicle', ['fas', 'car']],
  ['auto', ['fas', 'car']],
  ['business interruption', ['fas', 'phone-slash']],
  ['business income', ['fas', 'phone-slash']],
  ['disaster', ['fas', 'bolt']],
  ['catastrophe', ['fas', 'bolt']],
  ['health', ['far', 'ambulance']],
  ['medical', ['far', 'ambulance']],
  ['healthcare', ['far', 'ambulance']],
  ['umbrella', ['fas', 'umbrella']],
  ['workers compensation', ['far', 'crutches']],
]);

export const resolveIconFromString = (coverage?: string): IconProp => {
  if (coverage && iconMap.has(coverage.toLowerCase())) {
    return iconMap.get(coverage.toLowerCase()) as IconProp;
  } else {
    return ['far', 'file-contract'];
  }
};

interface ICoverageObject {
  id: string;
  layer?: Layer | null;
  excess: number;
}

export const sortByLayerThenExcess = <T extends ICoverageObject>(
  a: T | null,
  b: T | null
) => {
  if (a === null || b === null) {
    return 0;
  }
  const aIsPrimary = a.layer === Layer.Primary;
  const bIsPrimary = b.layer === Layer.Primary;
  // true == 1; false == 0
  const primaryComparison = Number(bIsPrimary) - Number(aIsPrimary);
  if (primaryComparison !== 0) {
    return primaryComparison;
  }
  const excessDifference = a.excess - b.excess;
  if (excessDifference !== 0) {
    return excessDifference;
  }
  // If layer and name are the same, then sort by id
  return a.id.localeCompare(b.id);
};

export const userIsInsuranceReviewer = (roles: string[]) =>
  !!intersection(['axio-insurance-analyst'], roles).length;

// FOR NOW we dont wanna accept anything thats not a pdf
// so if there is a file type that doesnt contain pdf
// in the type we just die for now
export const hasNonAcceptedFileType = (files: FileList | null) =>
  files &&
  Array.from(files)
    .map((file) => {
      return file.type.includes('pdf');
    })
    .includes(false);

export const labelValueObjectBuilder = (type: string) => ({
  label: type,
  value: type,
});

export const getFilteredSublimitOptions = (
  sublimits: Sublimit[],
  allowedSublimits: Set<string>
) => {
  const sublimitOptions: Array<{ label: string; value: string }> = [];
  const selectedSublimits = new Set(
    sublimits.map((sublimit) => sublimit?.type)
  );

  // Each coverage type may only have one of each sublimit type
  allowedSublimits.forEach((sublimit) => {
    if (!selectedSublimits.has(sublimit)) {
      sublimitOptions.push({ label: sublimit, value: sublimit });
    }
  });

  sublimitOptions.sort((sublimitA, sublimitB) =>
    sublimitA.value > sublimitB.value ? 1 : -1
  );
  return sublimitOptions;
};
