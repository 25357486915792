import Immutable from 'immutable';
import {
  filterDomainPractices,
  IModelDomain,
  IModelFile,
  IModelPractice,
} from './model';
import { Map } from 'immutable';

export const calcPracticeCountsPerGrouping = (
  data: IModelFile,
  filterFn: null | ((practice: IModelPractice) => boolean) = null
) => {
  // build index
  let totalCount = 0;
  const groupedCountsObj = data.domains.reduce<any>((dAccum, domain) => {
    let domainTotal = 0;
    domain.objectives.reduce((oAccum, objective) => {
      const practices = filterFn
        ? objective.practices.filter(filterFn)
        : objective.practices;
      oAccum[objective.id] = practices.length;
      domainTotal += oAccum[objective.id];
      totalCount += oAccum[objective.id];
      return oAccum;
    }, dAccum);
    dAccum[domain.id] = domainTotal;
    return dAccum;
  }, {});
  const groupedCounts = Map<string, number>(groupedCountsObj);
  return { totalCount, groupedCounts };
};

// Mil Model Helpers

export const calcMilRange = (
  practiceMap: Immutable.OrderedMap<string, IModelPractice>
) => {
  const firstVal: IModelPractice = practiceMap.first();
  // Traverse the practice map and figure out what the mil range is (min - max).
  return practiceMap.reduce<{ min: number; max: number }>(
    (accum: any, p) => {
      if (!p) return accum;
      if (p.mil && accum.min > p.mil) {
        accum.min = p.mil;
      }
      if (p.mil && accum.max < p.mil) {
        accum.max = p.mil;
      }
      return accum;
    },
    {
      min: !!firstVal && !!firstVal.mil ? firstVal.mil : -1,
      max: !!firstVal && !!firstVal.mil ? firstVal.mil : -1,
    }
  );
};

export const calcMilDomains = (domains: IModelDomain, milArr: number[]) => {
  const milDomains = {};
  milArr.forEach((mil) => {
    milDomains[mil] = filterDomainPractices({
      domains,
      filterFn: (p) => p.get('mil') <= mil,
    });
  });
  return milDomains;
};

export const calcMilPracticeLists = (
  model0: Map<keyof IModelFile, any>,
  milArr: number[]
) => {
  const milPracticeLists = {};
  milArr.forEach((mil) => {
    milPracticeLists[mil] = model0
      .get('domains')
      .map((d) =>
        d.get('objectives').map((o) =>
          o
            .get('practices')
            .filter((p) => p.get('mil') <= mil)
            .map((p) => ({
              id: p.get('id'),
              name: p.get('name'),
              fqn: p.get('fqn'),
              priority: p.get('priority'),
              recommendation_id: p.get('recommendation_id'),
              text: p.get('text'),
            }))
        )
      )
      .flatten();
  });
  return milPracticeLists;
};

export const calcMilCounts = (
  practiceMap: Immutable.OrderedMap<string, IModelPractice>,
  milRange: { min: number; max: number }
) => {
  const milCounts = {};
  if (milRange.max >= 0) {
    for (let i: number = milRange.min; i <= milRange.max; i++) {
      milCounts[i] = practiceMap.filter((practice) => {
        return practice?.mil === i;
      }).size;
    }
  }
  return milCounts;
};

export const calcMilCountsMap = (data, milArray: number[]) => {
  const milCountsMap = {};
  milArray.forEach((mil) => {
    const { groupedCounts: milCountMap } = calcPracticeCountsPerGrouping(
      data,
      (p) => (p.mil ? p.mil <= mil : false)
    );
    milCountsMap[mil] = milCountMap;
  });
  return milCountsMap;
};

// Tier Model Helpers
// Shares a lot of logic with calcMil functions. Maybe they could be combined
// into a single function that needs a filter function param?

export const calcTiersArray = (
  practiceMap: Immutable.OrderedMap<string, IModelPractice>
) => {
  return practiceMap.reduce((accum: any, p) => {
    if (p?.tiers) {
      p.tiers.forEach((t) => {
        if (!(t in accum)) accum[t] = t;
      });
    }
    return accum;
  }, {});
};

export const calcTierDomains = (domains: IModelDomain, tierArray: number[]) => {
  const tierDomains = {};
  tierArray.forEach((t) => {
    tierDomains[t] = filterDomainPractices({
      domains,
      filterFn: (p) => p.get('tiers').includes(t),
    });
  });
  return tierDomains;
};

export const calcTierPracticeLists = (
  model0: Map<keyof IModelFile, any>,
  tierArray: number[]
) => {
  const tierPracticeLists = {};
  tierArray.forEach((tier) => {
    tierPracticeLists[tier] = model0
      .get('domains')
      .map((d) =>
        d.get('objectives').map((o) =>
          o
            .get('practices')
            .filter((p) => p.get('tiers').includes(tier))
            .map((p) => ({
              id: p.get('id'),
              name: p.get('name'),
              fqn: p.get('fqn'),
              priority: p.get('priority'),
              recommendation_id: p.get('recommendation_id'),
              text: p.get('text'),
            }))
        )
      )
      .flatten();
  });
  return tierPracticeLists;
};

export const calcTierCounts = (
  practiceMap: Immutable.OrderedMap<string, IModelPractice>,
  tierArray: number[]
) => {
  const tierCounts = {};
  if (tierArray.length) {
    tierArray.forEach((t) => {
      tierCounts[t] = practiceMap.filter((p) =>
        p ? !!p.tiers?.includes(t) : false
      ).size;
    });
  }
  return tierCounts;
};

export const calcTierCountsMap = (data, tierArray: number[]) => {
  const tierCountsMap = {};
  if (tierArray.length) {
    tierArray.forEach((tier) => {
      const { groupedCounts: tierCountMap } = calcPracticeCountsPerGrouping(
        data,
        (p) => !!p?.tiers?.includes(tier)
      );
      tierCountsMap[tier] = tierCountMap;
    });
  }
  return tierCountsMap;
};
