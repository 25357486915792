import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import {
  USER_ACCOUNT_ERROR_MESSAGE,
  NO_GRAPHQL_ERROR_MESSAGE,
  OFFLINE_ERROR_MESSAGE,
} from 'common/constants';
import { toasterError } from 'common/notifications';
import Logger from 'common/utils/Logger';

const UNAUTHORIZED = '401';

const determineIfErrorIsDueToDeletedUser = (
  graphQLErrors: readonly GraphQLError[]
) => {
  const forbiddenResponse = graphQLErrors.find((err) =>
    err.extensions?.code.includes('FORBIDDEN')
  );
  const meQuery = graphQLErrors.find((err) => err.path?.includes('me'));
  return meQuery && forbiddenResponse;
};

const determineIfCurrentUserServerError = (
  graphQLErrors: readonly GraphQLError[] | undefined,
  networkErrorName: string | undefined,
  operationName: string | undefined
) => {
  // Message string must match the constant set in graphql
  const cognitoSubjectAlreadySetError = graphQLErrors?.find((err) =>
    err.message.includes('Unauthorized user identifier.')
  );

  const isServerError = networkErrorName === 'ServerError';

  const operationWasCurrentUSer = operationName === 'CurrentUser';

  return (
    cognitoSubjectAlreadySetError && isServerError && operationWasCurrentUSer
  );
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // We are looking for a specific error thrown at login
    // when there is a mismatch between cognito IDs.
    // We will not call errorFunction for this error so
    // that the user only sees a generic password error.
    const isCurrentUserServerError = determineIfCurrentUserServerError(
      graphQLErrors,
      networkError?.name,
      operation?.operationName
    );

    // This block should silently retry operations one more time that fail due
    // to expired tokens or whatever causes the generic error 'NetworkError when ...'
    // All other network error handling and retrying will be handled by the lower
    // block and the retryLink. Thus this block is really here to prevent spamming
    // the user and sentry with resolvable errors
    if (
      (networkError?.message || '').includes(UNAUTHORIZED) ||
      (networkError?.message || '').startsWith(
        'NetworkError when attempting to fetch resource'
      )
    ) {
      // The context call on the headers link should automatically
      // attempt to re-fetch the headers.  All we need to do here is retry.
      return forward(operation);
    }
    if (graphQLErrors) {
      // We are hiding errors for a very specific call used only during login in order to mimic what a user
      // would see if they tried to login with an incorrect username/password combo.
      const shouldHideError = determineIfErrorIsDueToDeletedUser(graphQLErrors);

      graphQLErrors.forEach(({ message, locations, path }) => {
        const error = [`[GraphQL error]: Message: ${message}, Path: ${path}`];
        locations?.forEach((loc) => {
          error.push('Location Line: ' + loc.line);
          error.push('Location Column: ' + loc.column);
        });

        if (!shouldHideError) {
          Logger.error(error.join('\r\n'), {
            contexts: {
              location: {
                file: 'setupApollo.tsx',
                function: 'gql graphQL error',
                message,
                locations: JSON.stringify(locations),
                path,
                name: operation.operationName,
                variables: operation.variables,
              },
            },
          });
        }
      });
      const message = graphQLErrors.map((err) => err.message).join(',');

      if (!shouldHideError && !isCurrentUserServerError) {
        toasterError(message);
      }
    }
    if (networkError) {
      Logger.exception(networkError, {
        contexts: {
          location: {
            file: 'setupApollo.tsx',
            function: 'gql network error',
          },
        },
      });
      if (!isCurrentUserServerError) {
        const online = window.navigator.onLine;
        const message = !online
          ? OFFLINE_ERROR_MESSAGE
          : networkError.message === 'Failed to fetch'
          ? NO_GRAPHQL_ERROR_MESSAGE
          : networkError.message ===
              'Response not successful: Received status code 400' &&
            window.location.pathname.includes('authentication')
          ? USER_ACCOUNT_ERROR_MESSAGE
          : networkError.message;
        toasterError(message, { timeout: 0 });
      }
    }
    return;
  }
);
