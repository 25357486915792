import { Map } from 'immutable';
import { Reducer } from 'redux';

import { ICompany } from 'common/databaseTypes';

interface ISubsidiaryCompany extends ICompany {
  assessmentsShared: number;
  latestUpdate: string;
}

export type IState = Map<string, ISubsidiaryCompany>;

export const initialState = Map<ISubsidiaryCompany>({});

export const subsidiaryReducer: Reducer<IState> = (
  state = initialState,
  action = { type: 'FakeAction' }
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      return initialState;
    }
    case 'get_resource/subsidiaries_for_user':
      return convertAction(state, action);
    case 'APOLLO_MUTATION_RESULT': {
      if (
        (action.operationName !== 'AddCompanyNote' &&
          action.operationName !== 'DeleteCompanyNote') ||
        action.result.errors
      ) {
        break;
      }
      if (action.operationName === 'AddCompanyNote') {
        const subsidiaryId = action.variables.newCompanyNote.companyId;
        const company = state.get(subsidiaryId);
        return state.merge(
          Map({
            [subsidiaryId]: {
              ...company,
              notes: [
                ...(company?.notes ?? []),
                action.result.data.addCompanyNote.note.id,
              ],
            },
          })
        );
      }
      if (action.operationName === 'DeleteCompanyNote') {
        const subsidiaryId = action.result.data.deleteCompanyNote.company.id;
        const company = state.get(subsidiaryId);
        const notes = company?.notes?.filter(
          (id) => id !== action.result.data.deleteCompanyNote.note.id
        );
        return state.merge(
          Map({
            [subsidiaryId]: {
              ...company,
              notes,
            },
          })
        );
      }
      break;
    }
    default:
      break;
  }
  return state;
};

function convertAction(state: IState, action) {
  const subsidiaries = action.response.result._items[0].subsidiaries;

  if (subsidiaries && subsidiaries.length) {
    return subsidiaries.reduce(
      (map, item) => map.set(item._id, item),
      Map<ISubsidiaryCompany>({})
    );
  }
  return state;
}
