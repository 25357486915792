import { omitBy } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { USER_DOMAIN } from 'common/actions/user';
import { ID_FIELD } from 'common/constants';
import Logger from 'common/utils/Logger';

import { IRootState } from '../../reducers';
import { ASSESSMENT_ID_FIELD } from '../../assessments/constants';

type Dispatch = ThunkDispatch<IRootState, void, AnyAction>;

export const SOCKETIO_ACTION_TAG = 'server/';
export const SOCKET_IO_NAMESPACE = '/c2m2';
export const FLUSH_NETWORK_SAVE_ACTION = 'persistence/flush_network_saves';
export const CANCEL_NETWORK_SAVE_ACTION = 'persistence/cancel_network_saves';
export const RESPONSE_WAITTIME = 10000; // 10 seconds.
export const AUTH_TOO_OLD_CUTOFF_SECONDS = 58 * 60 * 1000; // 58 minutes in millis
export const MAX_RETRIES = 5;

export const networkErrorCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  CONFLICT: 409,
  PRECONDITION_FAILURE: 412,
  INTERNAL_SERVER_ERROR: 500,
  UNPROCESSABLE_ENTITY: 422,
};

export const fetchUser = () => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  // Because the general security requirements force the
  // user to be "us", we don't need to supply an email here
  // and not supplying it creates a faster lookup.
  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      {
        resource: USER_DOMAIN,
        embed: '{"employer":1, "coworkers": 1}',
      },
      null,
      true
    )
  );
};

export const fetchUserCoworkers = (afterAction: AnyAction | null = null) => (
  dispatch: Dispatch,
  getState: () => IRootState
) => {
  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      { resource: 'coworkers_for_user' },
      afterAction,
      true
    )
  );
};

export const fetchJournalEntries = (
  assessmentId: string,
  afterAction: AnyAction | null = null
) => (dispatch: Dispatch, getState: () => IRootState) => {
  dispatch(
    socketIOMessage(
      getState(),
      'get_resource',
      {
        resource: 'journal',
        [ASSESSMENT_ID_FIELD]: assessmentId,
      },
      afterAction,
      true
    )
  );
};

/* Updates a non-practice item with the backend.  Right now, it's used
 * for the user update (name and phone), the assessment scope update
 * and for copying target levels from another assessment
 *
 * domain (string): The item type (User, Assessment, whatever)
 * item (Object): The object being updated
 * argItemId (?string): An optional string containing the item id if you
 *                      haven't provided it in the item itself.
 * argEtag (?string): An optional string containing the item's etag if you
 *                    haven't provided it in the item itself.
 * argExtraArgs (?Object): An optional object containing any extra props
 *                         you wish to pass
 * metaFields (function): An optional function for filtering out the fields
 *                        that are on the item that you don't want sent to
 *                        the backend as a part of the update.
 * afterAction (?Object): An optional action to run on success
 */
export function updateItemMessage(
  domain: string,
  item: any,
  argItemId: string | null = null,
  argEtag: string | null = null,
  argExtraArgs: object | null = null,
  afterAction: any = null,
  retry: boolean | ((args: any) => boolean) = false,
  stripFields?: (value: any, key: string) => boolean
) {
  const item_id = argItemId || item[ID_FIELD];
  const etag = argEtag || item._etag;

  // If we don't have an item ID and etag, we can't do an update/patch.
  // This condition represents a programming bug on the client somewhere.
  // Rather than let the db blow up, we're going to stop it now.
  if (!item_id || !etag) {
    const errorString = `BUG: Missing item_id or etag.  item_id: '${
      item_id || ''
    }', etag: '${etag || ''}'`;
    Logger.error(errorString);
    return updateItemError(domain, item, item_id, etag, errorString);
  }
  // Eve gets cranky if you include it's meta-fields in the update request.
  const data = stripFields ? omitBy(item, stripFields) : item;
  const extra = argExtraArgs || {};
  return patchThunk(
    { resource: domain, ...extra, item_id, etag, data },
    afterAction,
    retry
  );
}

function patchThunk(
  message: object,
  afterAction: any = null,
  retry: boolean | ((args: any) => boolean) = false
) {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch(
      socketIOMessage(getState(), 'patch_resource', message, afterAction, retry)
    );
  };
}

function updateItemError(
  domain: string,
  item: object,
  argItemId: string | null,
  argEtag: string | null,
  error: string
) {
  return (dispatch: Dispatch, getState: () => IRootState) => {
    dispatch({
      type: `error/patch_resource/${domain}`,
      request: item,
      response: {},
      error: { status: 500, statusText: error, url: '' },
    });
  };
}

/*
 * Wrapper to form the message to the server in the structure it's expecting and to
 * make sure that the elements that *must* be there (like resource) are there.
 *
 * @param {string} method - The server method you're building a message for
 * @param {Object} [argRequest] - Any data that needs to be sent along with the request.
 *
 * @returns {Object} - The message to send to the server.
 */

function getReferrer() {
  return window.location.hostname;
}

export function socketIOMessage(
  state: IRootState,
  method: string,
  argRequest?: any | null,
  afterSuccessMessage?: any,
  retry: boolean | any = false,
  afterErrorMessage: any = null
) {
  const request = {
    Authorization: null,
    referrer: getReferrer(),
    ...argRequest,
  };

  const result = {
    type: `${SOCKETIO_ACTION_TAG}${method}`,
    request,
    retry,
    afterSuccessMessage,
    afterErrorMessage,
  };

  return result;
}
