import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

const IconContainer = styled.div`
  height: 100%;
`;

const getCMMCIcon = (id: string) => {
  const cmmcAssessmentIconStyle = { display: 'grid', placeItems: 'center' };
  return (
    <IconContainer className="" style={cmmcAssessmentIconStyle}>
      <svg
        id={id}
        width="35"
        height="35"
        viewBox="0 0 205 205"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <circle
            stroke="#564DAA"
            strokeWidth="10"
            cx="99.5"
            cy="99.5"
            r="94.5"
          />
          <g id="Group" transform="translate(27.000000, 29.000000)">
            <g id="verified_user_black_24dp">
              <polygon id="Path" points="0 0 146 0 146 146 0 146" />
              <path
                d="M73.5,6 L19,30.3636364 L19,66.9090909 C19,100.713636 42.2533333,132.325455 73.5,140 C104.746667,132.325455 128,100.713636 128,66.9090909 L128,30.3636364 L73.5,6 Z M115.888889,66.9090909 C115.888889,94.44 97.8433333,119.839091 73.5,127.391818 C49.1566667,119.839091 31.1111111,94.44 31.1111111,66.9090909 L31.1111111,38.2818182 L73.5,19.3390909 L115.888889,38.2818182 L115.888889,66.9090909 Z"
                id="Shape"
                fill="#564DAA"
                fillRule="nonzero"
              />
            </g>
            <path
              d="M73.5,58 C78.7467051,58 83,62.2532949 83,67.5 C83,71.1184173 80.9770298,74.2643446 78.000386,75.8684853 L78,90 C78,92.209139 76.209139,94 74,94 L73,94 C70.790861,94 69,92.209139 69,90 L69.0006062,75.8690199 C66.0234198,74.2650437 64,71.1188194 64,67.5 C64,62.2532949 68.2532949,58 73.5,58 Z"
              id="Combined-Shape"
              fill="#564DAA"
            />
          </g>
        </g>
      </svg>
    </IconContainer>
  );
};

const clipboardStyle = { top: '-1px' };
const clipboardIcon: IconProp = ['fal', 'clipboard'];
const clipboard = (
  <FontAwesomeIcon
    style={clipboardStyle}
    icon={clipboardIcon}
    transform="grow-14"
  />
);

const fullAssessmentIconStyle = { top: '-2px' };
const fullAssessmentFaIcon: IconProp = ['far', 'check'];
export const fullAssessmentIcon = (
  <IconContainer className="fa-layers fa-fw">
    {clipboard}
    <FontAwesomeIcon
      style={fullAssessmentIconStyle}
      icon={fullAssessmentFaIcon}
      transform="down-2"
    />
  </IconContainer>
);

const qlAssessmentIconStyle = { top: '-1px' };
const qlAssessmentFaIcon: IconProp = ['far', 'bolt'];
export const qlAssessmentIcon = (
  <IconContainer className="fa-layers fa-fw">
    {clipboard}
    <FontAwesomeIcon
      style={qlAssessmentIconStyle}
      icon={qlAssessmentFaIcon}
      transform="down-2"
    />
  </IconContainer>
);

const cis18AssessmentIconStyle = { top: '-1px' };
const cis18AssessmentFaIcon: IconProp = ['fal', 'stopwatch'];
export const cis18AssessmentIcon = (
  <IconContainer className="fa-layers fa-fw">
    {clipboard}
    <FontAwesomeIcon
      style={cis18AssessmentIconStyle}
      icon={cis18AssessmentFaIcon}
      transform="down-2"
    />
  </IconContainer>
);

export const criAssessmentIcon = (
  <IconContainer style={{ display: 'grid', placeItems: 'center' }}>
    <svg viewBox="0 0 64 61" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <polygon
          id="path-1"
          points="0 0.0057518797 38.8320263 0.0057518797 38.8320263 44.9796992 0 44.9796992"
        />
      </defs>
      <g id="logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="CRI_horizontal_fullcolor"
          transform="translate(11.000000, 9.000000)"
        >
          <g id="Group-4">
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path
              d="M38.8320263,6.59503759 C34.5351842,2.34575188 28.8230526,0.0057518797 22.7469211,0.0057518797 C21.7664474,0.0057518797 20.7996579,0.0676691729 19.8513421,0.187105263 L19.8513421,25.7619925 L25.7981579,25.7619925 L25.7981579,6.16330827 C29.1237632,6.76353383 32.1852632,8.33921053 34.6268684,10.753985 L38.8320263,6.59503759"
              id="Fill-1"
              fill="#A1233C"
              mask="url(#mask-2)"
            />
            <path
              d="M22.7469211,39.1185338 C13.4833947,39.1185338 5.94681579,31.6647744 5.94681579,22.5030451 C5.94681579,15.5737218 10.2590526,9.62458647 16.3676842,7.13503759 L16.3676842,0.907781955 C6.92044737,3.64195489 0,12.2877068 0,22.5030451 C0,34.9078195 10.2043158,45 22.7469211,45 C28.8230526,45 34.5351842,42.66 38.8320263,38.4107143 L34.6268684,34.2517669 C31.4538421,37.3902632 27.2346579,39.1185338 22.7469211,39.1185338"
              id="Fill-3"
              fill="#2E2354"
              mask="url(#mask-2)"
            />
          </g>
        </g>
      </g>
    </svg>
  </IconContainer>
);

export const cmmcAssessmentIcon = getCMMCIcon('CMMC');
export const cmmcV2AssessmentIcon = getCMMCIcon('CMMCV2');

const ffiec1AssessmentIconStyle = { top: '-1px' };
const ffiec1AssessmentFaIcon: IconProp = ['fal', 'meh'];
export const ffiec1AssessmentIcon = (
  <IconContainer className="fa-layers fa-fw">
    {clipboard}
    <FontAwesomeIcon
      style={ffiec1AssessmentIconStyle}
      icon={ffiec1AssessmentFaIcon}
      transform="down-2"
    />
  </IconContainer>
);

const ISO27001IconStyle = { top: '-1px' };
const ISO27001FaIcon: IconProp = ['fal', 'paper-plane'];
export const ISO27001Icon = (
  <IconContainer className="fa-layers fa-fw">
    {clipboard}
    <FontAwesomeIcon
      style={ISO27001IconStyle}
      icon={ISO27001FaIcon}
      transform="down-2"
    />
  </IconContainer>
);

const AlliantIconStyle = { top: '-1px' };
const AlliantFaIcon: IconProp = ['fal', 'crown'];
export const AlliantIcon = (
  <IconContainer className="fa-layers fa-fw">
    <FontAwesomeIcon
      style={AlliantIconStyle}
      icon={AlliantFaIcon}
      transform="down-2"
    />
  </IconContainer>
);

// For inline svg functionality its pretty easy
// just copy use an svg tag like this
//
// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   id="svg2"
//   viewBox="0 0 45 45"
//   style={{
//     position: 'absolute',
//     top: '6.5px',
//     right: '-6.5px',
//     pointerEvents: 'none'
//   }}
// >
//
// Copy and paste the contents of the inner SVG tag in the .svg
// Then remove any invalid tags like something:something='something'
// </svg>
//
// Then adjust the viewbox/id/style component to meet your svgs needs.
export const rc3AssessmentIcon = (
  <IconContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="rc3"
      role="img"
      viewBox="0 0 321.000000 321.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs id="defs74" />
      <g
        transform="matrix(0.1,0,0,-0.1,0,321)"
        id="g68"
        fill="rgb(238, 78, 75)"
        stroke="none"
      >
        <path
          d="m 1330,3190 c -147,-26 -272,-65 -400,-123 -62,-28 -193,-101 -215,-119 -5,-5 -31,-23 -57,-41 C 478,2782 289,2563 182,2355 57,2111 12,1937 3,1652 -8,1301 75,1007 267,713 c 21,-32 43,-63 48,-70 6,-6 26,-31 45,-55 44,-55 173,-184 228,-228 24,-19 49,-40 55,-45 20,-17 139,-91 212,-132 79,-44 257,-112 355,-137 187,-46 431,-58 621,-31 273,40 486,126 734,297 72,50 277,255 333,333 130,180 194,318 272,580 35,119 47,480 22,645 -28,182 -98,376 -195,545 -22,39 -43,72 -46,75 -4,3 -19,25 -35,50 -83,129 -257,298 -416,405 -139,93 -350,185 -510,224 -186,45 -478,54 -660,21 z"
          id="path3771"
        />
        <path
          fillOpacity="0.6"
          fill="rgb(247, 199, 184)"
          d="m 2685,2059 c 194,-72 238,-316 73,-409 l -40,-22 39,-18 c 49,-22 100,-82 114,-135 24,-86 -7,-190 -73,-251 -73,-67 -148,-94 -262,-94 -124,0 -220,36 -288,107 -35,36 -81,120 -73,133 2,4 48,17 101,31 81,20 98,22 102,10 3,-8 20,-30 38,-50 32,-35 34,-36 109,-36 72,0 78,2 101,28 24,28 32,84 18,121 -12,32 -71,57 -151,64 l -78,7 -3,78 -3,77 h 43 c 121,2 178,35 178,104 -1,63 -37,96 -105,96 -50,0 -89,-23 -109,-63 l -16,-34 -62,13 c -102,22 -138,34 -138,45 0,22 41,87 77,123 96,96 266,127 408,75 z"
          id="path3769"
        />
        <path
          fill="#ffffff"
          d="m 1819,2059 c 35,-5 89,-22 120,-37 58,-28 165,-124 159,-143 -2,-5 -44,-41 -93,-79 l -90,-69 -35,33 c -53,51 -91,66 -163,66 -53,0 -71,-5 -103,-26 -74,-50 -99,-102 -99,-204 0,-62 4,-83 25,-119 39,-69 87,-102 157,-108 76,-7 119,7 178,59 34,30 51,39 61,32 134,-91 173,-120 174,-126 0,-4 -27,-35 -59,-68 -179,-183 -489,-183 -670,-1 -91,91 -131,192 -131,331 0,139 40,240 132,331 112,113 262,157 437,128 z"
          id="path3767"
        />
        <path
          fill="#ffffff"
          d="m 945,2036 c 160,-42 241,-146 238,-304 -2,-99 -48,-186 -125,-237 -23,-16 -45,-31 -47,-32 -2,-2 42,-70 97,-152 56,-82 102,-152 102,-155 0,-3 -65,-6 -144,-6 H 922 l -88,135 -89,135 h -52 -53 v -135 -136 l -127,3 -128,3 -3,448 -2,447 h 257 c 183,0 272,-4 308,-14 z"
          id="path64"
        />
        <path
          d="m 640,1725 v -108 l 108,5 c 119,5 151,18 172,69 17,40 3,86 -33,113 -24,18 -46,22 -138,24 l -109,4 z"
          id="path66"
        />
      </g>
    </svg>
  </IconContainer>
);

const rpaIconStyle = { top: '-1px' };
const rpaFaIcon: IconProp = ['fab', 'btc'];
export const rpaAssessmentIcon = (
  <IconContainer className="fa-layers fa-fw">
    <FontAwesomeIcon
      style={rpaIconStyle}
      icon={rpaFaIcon}
      transform="grow-12 down-2"
    />
  </IconContainer>
);

export const cssaAssessmentIcon = (
  <IconContainer>
    <svg
      width="44"
      height="44"
      version="1.1"
      viewBox="0 0 32.737811 29.876687"
      xmlns="http://www.w3.org/2000/svg"
      id="CSSA"
      role="img"
    >
      <g transform="translate(-92.997, -195.2)">
        <g transform="translate(.26458 -.26458)" fill="#0074b6">
          <path d="m91.997 206.73v14.686h11.917v-3.4615h-7.8885v-2.329h7.0496v-3.2103h-7.0496v-2.2447h7.7837v-3.4406z" />
          <path d="m106.01 206.73v14.686h11.917v-3.4615h-7.8888v-2.329h7.0496v-3.2103h-7.0496v-2.2447h7.7837v-3.4406z" />
          <path d="m120.13 221.42h4.0704v-14.686h-4.0704z" />
        </g>
      </g>
    </svg>
  </IconContainer>
);

const nistStyle: React.CSSProperties = {
  strokeWidth: '0',
  shapeRendering: 'auto',
};
const nistNStyle: React.CSSProperties = {
  opacity: 1,
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: 'none',
  strokeWidth: 0.45388064,
  strokeLinecap: 'round',
  strokeLinejoin: 'miter',
  strokeMiterlimit: 4,
  strokeDasharray: 'none',
  strokeDashoffset: 0,
  strokeOpacity: 1,
};
export const csfAssessmentIcon = (
  <IconContainer className="fa-layers fa-fw">
    <svg xmlns="http://www.w3.org/2000/svg" id="CSF" viewBox="0 0 40 40">
      <g id="g3774">
        <g
          transform="matrix(0.10336305,0,0,0.10336305,-4.5476103,-4.5358829)"
          id="g12"
        >
          <path
            id="NISTC1"
            className="nistc_path"
            style={nistStyle}
            role="presentation"
            transform="translate(200,200)"
            d="M 7.8635969,-149.79374 A 150,150 0 0 1 140.03233,-53.767536 L 92.381162,-38.284734 A 100,100 0 0 0 7.8635969,-99.69034 Z"
          />
          <path
            id="NISTC2"
            className="nistc_path"
            style={nistStyle}
            role="presentation"
            transform="translate(200,200)"
            d="M 144.8923,-38.810086 A 150,150 0 0 1 94.408333,116.56357 L 64.958295,76.029073 a 100,100 0 0 0 32.282837,-99.356357 z"
          />
          <path
            id="NISTC3"
            className="nistc_path"
            style={nistStyle}
            role="presentation"
            transform="translate(200,200)"
            d="m 81.684766,125.80779 a 150,150 0 0 1 -163.369532,0 l 29.450038,-40.534505 a 100,100 0 0 0 104.469456,0 z"
          />
          <path
            id="NISTC4"
            className="nistc_path"
            style={nistStyle}
            role="presentation"
            transform="translate(200,200)"
            d="M -94.408333,116.56357 A 150,150 0 0 1 -144.8923,-38.810086 l 47.651168,15.482802 a 100,100 0 0 0 32.282837,99.356357 z"
          />
          <path
            id="NISTC5"
            className="nistc_path"
            style={nistStyle}
            role="presentation"
            transform="translate(200,200)"
            d="M -140.03233,-53.767536 A 150,150 0 0 1 -7.8635969,-149.79374 v 50.1034 a 100,100 0 0 0 -84.5175651,61.405606 z"
          />
        </g>
        <g id="layer1" transform="translate(-159.97613,-408.06422)">
          <path
            id="NISTN"
            className="nistn_path"
            style={nistNStyle}
            d="m 172.15586,418.94211 c -1.34829,0 -2.4426,1.19553 -2.4426,2.66854 l 0.019,7.41262 h 2.17122 l -0.0189,-4.93122 v -2.4814 c 0,-0.15341 0.10746,-0.2813 0.24428,-0.29649 0.009,-0.001 0.0176,0 0.0271,0 0.075,0 0.14256,0.0336 0.19172,0.0871 l 5.69631,6.82761 c 0.44213,0.48303 1.05264,0.78204 1.72682,0.78204 1.3483,0 2.4426,-1.19548 2.4426,-2.66853 v -7.41261 h -2.1712 v 4.76074 2.65187 c 0,0.003 10e-5,0.007 0,0.0111 -0.005,0.15839 -0.12509,0.28536 -0.2714,0.28536 -0.075,10e-6 -0.14255,-0.0336 -0.19171,-0.0871 l -5.69632,-6.82766 c -0.44213,-0.48299 -1.05264,-0.78204 -1.72682,-0.78204 z"
          />
        </g>
      </g>
    </svg>
  </IconContainer>
);

export const getAssessmentIcon = (factories, modelUuid, modelScope) => {
  if (factories) {
    const factory = factories.find(
      (f) => f.modelUuid === modelUuid && f.modelScope === modelScope
    );
    // Just in case factory is undefined, although it never should be
    return factory ? factory.icon : fullAssessmentIcon;
  } else {
    return fullAssessmentIcon;
  }
};
