import { Map } from 'immutable';
import { isNumber, omit } from 'lodash';
import moment from 'moment';
import { AnyAction, Reducer } from 'redux';

import { IMilestone, IResponse } from 'assessments/databaseTypes';
import { dateStringsMatch, stripMeta } from 'common/utils/AxioUtilities';
import { ASSESSMENT_ID_FIELD } from '../constants';

type AssessmentId = string;
type MilestoneId = string;

type IState = Map<AssessmentId, Map<MilestoneId, IMilestone>>;

const initialState: IState = Map({});

export const milestonesReducer: Reducer<IState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return initialState;
    }
    case 'get_resource/responses_at_milestone':
      return addMilestoneResponses(state, action);
    case 'get_resource/assessments_for_user':
      return addAllAssessmentsMilestoneMetadata(state, action);
    case 'subscribe':
      return addSingleAssessmentMilestoneMetadata(state, action);
    case 'patch_resource/milestones': {
      return updateMilestone(state, action);
    }
    case 'delete_resource/milestones':
      return removeMilestoneMetadata(state, action);
    case 'post_resource/milestones':
      return addNewMilestone(state, action);
    default:
      break;
  }

  return state;
};

const replaceLevelWithTargetLevel = (asOfDate?: string) => (
  response: IResponse
) => {
  if (!asOfDate) {
    return response;
  }
  if (
    !isNumber(response.targetLevel) ||
    !response.targetDate ||
    moment(asOfDate).isBefore(response.targetDate) ||
    (isNumber(response.level) && response.targetLevel < response.level)
  ) {
    return response;
  }
  return {
    ...response,
    level: response.targetLevel,
  };
};

function addMilestoneResponses(state: IState, action: AnyAction) {
  const assessmentId: string = action.request[ASSESSMENT_ID_FIELD];
  const milestoneId: string = action.request.milestone;
  //@ts-expect-error Invalid Overload Call
  const milestone: IMilestone = state.getIn([assessmentId, milestoneId], {});
  const isFutureMilestone = moment().isBefore(milestone.milestone_date);
  const responses: IResponse[] = action.response.result._items;
  const maybeMutatedResponses = isFutureMilestone
    ? responses.map(replaceLevelWithTargetLevel(milestone.milestone_date))
    : responses;
  const milestoneResponses = Map<string, IResponse>(
    maybeMutatedResponses.map((resp) => {
      return [resp._practice_id, { ...resp }];
    })
  );

  return state.update(assessmentId, Map({}), (milestones) =>
    milestones.update(milestoneId, (milestoneFromState) => ({
      ...milestoneFromState,
      responses: milestoneResponses,
    }))
  );
}

function addAllAssessmentsMilestoneMetadata(state: IState, action: AnyAction) {
  return action.response.result._items.reduce(
    (s, assessment) =>
      s.update(assessment._id, (milestones = Map({})) =>
        assessment.milestones.reduce(
          (m, milestone) =>
            m.update(milestone._id, {}, (existingMilestone) => ({
              ...existingMilestone,
              ...milestone,
            })),
          milestones
        )
      ),
    state
  );
}

function addSingleAssessmentMilestoneMetadata(
  state: IState,
  action: AnyAction
) {
  const assessment = action.response.assessment;
  return state.update(assessment._id, (milestones = Map({})) =>
    assessment.milestones.reduce(
      (m, milestone) =>
        m.update(milestone._id, {}, (existingMilestone) => ({
          ...existingMilestone,
          ...milestone,
        })),
      milestones
    )
  );
}

function removeMilestoneMetadata(state: IState, action: AnyAction) {
  return state.map((assessment) => assessment.delete(action.response.item_id));
}

function addNewMilestone(state: IState, action: AnyAction) {
  const milestoneData = action.request.data;
  const milestoneMetadata = action.response.result;
  return state.update(
    milestoneData[ASSESSMENT_ID_FIELD],
    Map({}),
    (milestones) =>
      milestones.set(milestoneMetadata._id, {
        ...stripMeta(milestoneMetadata),
        ...milestoneData,
      })
  );
}

function updateMilestone(state: IState, action: AnyAction) {
  const assessmentId = action.request[ASSESSMENT_ID_FIELD];
  const updatedMilestone = action.request.data;
  const updatedMilestoneMeta = action.response.result;
  return state.update(assessmentId, Map({}), (milestones) =>
    milestones.update(updatedMilestoneMeta._id, (item) => {
      // Remove responses if milestone date changes
      const currentMilestone = dateStringsMatch(
        item.milestone_date,
        updatedMilestone.milestone_date
      )
        ? item
        : omit(item, ['responses']);
      return {
        ...currentMilestone,
        ...stripMeta(updatedMilestoneMeta),
        ...updatedMilestone,
      };
    })
  );
}
