import React, { Suspense } from 'react';
import { withRouter } from 'react-router';

import { LoadingScreenRaw } from 'common/components/Loading/LoadingScreen';
import { UpsellInsurance } from './UpsellInsurance';
import { useCurrentUserInsuranceLicenseQuery } from 'common/graphql/graphql-hooks';

const Insurance = React.lazy(
  () =>
    import(/* webpackChunkName: "Insurance", webpackPrefetch: true */ './views')
);

const GuardInsurance = React.memo((props: any) => {
  const { data, loading } = useCurrentUserInsuranceLicenseQuery();
  const doesNotHaveInsuranceAccess = !data?.me?.hasInsuranceAccess;

  return (
    <Suspense fallback={<LoadingScreenRaw isLoading={true} />}>
      {loading ? (
        <LoadingScreenRaw isLoading={true} />
      ) : doesNotHaveInsuranceAccess ? (
        <UpsellInsurance />
      ) : (
        <Insurance {...props} />
      )}
    </Suspense>
  );
});

export default withRouter(GuardInsurance);
