// Data is pulled from commonCurrency.json from
// https://github.com/alien9996/react-native-currency-picker/

export const currencyOptions = [
  {
    label: 'USD ($)',
    value: 'USD',
  },
  {
    label: 'CAD ($)',
    value: 'CAD',
  },
  {
    label: 'EUR (€)',
    value: 'EUR',
  },
  {
    label: 'AED (د.إ.‏)',
    value: 'AED',
  },
  {
    label: 'AFN (؋)',
    value: 'AFN',
  },
  {
    label: 'ALL (Lek)',
    value: 'ALL',
  },
  {
    label: 'AMD (դր.)',
    value: 'AMD',
  },
  {
    label: 'ARS ($)',
    value: 'ARS',
  },
  {
    label: 'AUD ($)',
    value: 'AUD',
  },
  {
    label: 'AZN (ман.)',
    value: 'AZN',
  },
  {
    label: 'BAM (KM)',
    value: 'BAM',
  },
  {
    label: 'BDT (৳)',
    value: 'BDT',
  },
  {
    label: 'BGN (лв.)',
    value: 'BGN',
  },
  {
    label: 'BHD (د.ب.‏)',
    value: 'BHD',
  },
  {
    label: 'BIF (FBu)',
    value: 'BIF',
  },
  {
    label: 'BND ($)',
    value: 'BND',
  },
  {
    label: 'BOB (Bs)',
    value: 'BOB',
  },
  {
    label: 'BRL (R$)',
    value: 'BRL',
  },
  {
    label: 'BWP (P)',
    value: 'BWP',
  },
  {
    label: 'BYR (BYR)',
    value: 'BYR',
  },
  {
    label: 'BZD ($)',
    value: 'BZD',
  },
  {
    label: 'CDF (FrCD)',
    value: 'CDF',
  },
  {
    label: 'CHF (CHF)',
    value: 'CHF',
  },
  {
    label: 'CLP ($)',
    value: 'CLP',
  },
  {
    label: 'CNY (CN¥)',
    value: 'CNY',
  },
  {
    label: 'COP ($)',
    value: 'COP',
  },
  {
    label: 'CRC (₡)',
    value: 'CRC',
  },
  {
    label: 'CVE (CV$)',
    value: 'CVE',
  },
  {
    label: 'CZK (Kč)',
    value: 'CZK',
  },
  {
    label: 'DJF (Fdj)',
    value: 'DJF',
  },
  {
    label: 'DKK (kr)',
    value: 'DKK',
  },
  {
    label: 'DOP (RD$)',
    value: 'DOP',
  },
  {
    label: 'DZD (د.ج.‏)',
    value: 'DZD',
  },
  {
    label: 'EEK (kr)',
    value: 'EEK',
  },
  {
    label: 'EGP (ج.م.‏)',
    value: 'EGP',
  },
  {
    label: 'ERN (Nfk)',
    value: 'ERN',
  },
  {
    label: 'ETB (Br)',
    value: 'ETB',
  },
  {
    label: 'GBP (£)',
    value: 'GBP',
  },
  {
    label: 'GEL (GEL)',
    value: 'GEL',
  },
  {
    label: 'GHS (GH₵)',
    value: 'GHS',
  },
  {
    label: 'GNF (FG)',
    value: 'GNF',
  },
  {
    label: 'GTQ (Q)',
    value: 'GTQ',
  },
  {
    label: 'HKD ($)',
    value: 'HKD',
  },
  {
    label: 'HNL (L)',
    value: 'HNL',
  },
  {
    label: 'HRK (kn)',
    value: 'HRK',
  },
  {
    label: 'HUF (Ft)',
    value: 'HUF',
  },
  {
    label: 'IDR (Rp)',
    value: 'IDR',
  },
  {
    label: 'ILS (₪)',
    value: 'ILS',
  },
  {
    label: 'INR (টকা)',
    value: 'INR',
  },
  {
    label: 'IQD (د.ع.‏)',
    value: 'IQD',
  },
  {
    label: 'IRR (﷼)',
    value: 'IRR',
  },
  {
    label: 'ISK (kr)',
    value: 'ISK',
  },
  {
    label: 'JMD ($)',
    value: 'JMD',
  },
  {
    label: 'JOD (د.أ.‏)',
    value: 'JOD',
  },
  {
    label: 'JPY (￥)',
    value: 'JPY',
  },
  {
    label: 'KES (Ksh)',
    value: 'KES',
  },
  {
    label: 'KHR (៛)',
    value: 'KHR',
  },
  {
    label: 'KMF (FC)',
    value: 'KMF',
  },
  {
    label: 'KRW (₩)',
    value: 'KRW',
  },
  {
    label: 'KWD (د.ك.‏)',
    value: 'KWD',
  },
  {
    label: 'KZT (тңг.)',
    value: 'KZT',
  },
  {
    label: 'LBP (ل.ل.‏)',
    value: 'LBP',
  },
  {
    label: 'LKR (SL Re)',
    value: 'LKR',
  },
  {
    label: 'LTL (Lt)',
    value: 'LTL',
  },
  {
    label: 'LVL (Ls)',
    value: 'LVL',
  },
  {
    label: 'LYD (د.ل.‏)',
    value: 'LYD',
  },
  {
    label: 'MAD (د.م.‏)',
    value: 'MAD',
  },
  {
    label: 'MDL (MDL)',
    value: 'MDL',
  },
  {
    label: 'MGA (MGA)',
    value: 'MGA',
  },
  {
    label: 'MKD (MKD)',
    value: 'MKD',
  },
  {
    label: 'MMK (K)',
    value: 'MMK',
  },
  {
    label: 'MOP (MOP$)',
    value: 'MOP',
  },
  {
    label: 'MUR (MURs)',
    value: 'MUR',
  },
  {
    label: 'MXN ($)',
    value: 'MXN',
  },
  {
    label: 'MYR (RM)',
    value: 'MYR',
  },
  {
    label: 'MZN (MTn)',
    value: 'MZN',
  },
  {
    label: 'NAD (N$)',
    value: 'NAD',
  },
  {
    label: 'NGN (₦)',
    value: 'NGN',
  },
  {
    label: 'NIO (C$)',
    value: 'NIO',
  },
  {
    label: 'NOK (kr)',
    value: 'NOK',
  },
  {
    label: 'NPR (नेरू)',
    value: 'NPR',
  },
  {
    label: 'NZD ($)',
    value: 'NZD',
  },
  {
    label: 'OMR (ر.ع.‏)',
    value: 'OMR',
  },
  {
    label: 'PAB (B/.)',
    value: 'PAB',
  },
  {
    label: 'PEN (S/.)',
    value: 'PEN',
  },
  {
    label: 'PHP (₱)',
    value: 'PHP',
  },
  {
    label: 'PKR (₨)',
    value: 'PKR',
  },
  {
    label: 'PLN (zł)',
    value: 'PLN',
  },
  {
    label: 'PYG (₲)',
    value: 'PYG',
  },
  {
    label: 'QAR (ر.ق.‏)',
    value: 'QAR',
  },
  {
    label: 'RON (RON)',
    value: 'RON',
  },
  {
    label: 'RSD (дин.)',
    value: 'RSD',
  },
  {
    label: 'RUB (руб.)',
    value: 'RUB',
  },
  {
    label: 'RWF (FR)',
    value: 'RWF',
  },
  {
    label: 'SAR (ر.س.‏)',
    value: 'SAR',
  },
  {
    label: 'SDG (SDG)',
    value: 'SDG',
  },
  {
    label: 'SEK (kr)',
    value: 'SEK',
  },
  {
    label: 'SGD ($)',
    value: 'SGD',
  },
  {
    label: 'SOS (Ssh)',
    value: 'SOS',
  },
  {
    label: 'SYP (ل.س.‏)',
    value: 'SYP',
  },
  {
    label: 'THB (฿)',
    value: 'THB',
  },
  {
    label: 'TND (د.ت.‏)',
    value: 'TND',
  },
  {
    label: 'TOP (T$)',
    value: 'TOP',
  },
  {
    label: 'TRY (TL)',
    value: 'TRY',
  },
  {
    label: 'TTD ($)',
    value: 'TTD',
  },
  {
    label: 'TWD (NT$)',
    value: 'TWD',
  },
  {
    label: 'TZS (TSh)',
    value: 'TZS',
  },
  {
    label: 'UAH (₴)',
    value: 'UAH',
  },
  {
    label: 'UGX (USh)',
    value: 'UGX',
  },
  {
    label: 'UYU ($)',
    value: 'UYU',
  },
  {
    label: 'UZS (UZS)',
    value: 'UZS',
  },
  {
    label: 'VEF (Bs.F.)',
    value: 'VEF',
  },
  {
    label: 'VND (₫)',
    value: 'VND',
  },
  {
    label: 'XAF (FCFA)',
    value: 'XAF',
  },
  {
    label: 'XOF (CFA)',
    value: 'XOF',
  },
  {
    label: 'YER (ر.ي.‏)',
    value: 'YER',
  },
  {
    label: 'ZAR (R)',
    value: 'ZAR',
  },
  {
    label: 'ZMK (ZK)',
    value: 'ZMK',
  },
];

export const currencyMap = {
  USD: {
    label: 'USD ($)',
    value: 'USD',
    symbol: '$',
    locale: 'en-US',
  },
  CAD: {
    label: 'CAD ($)',
    value: 'CAD',
    symbol: '$',
    locale: 'en-CA',
  },
  EUR: {
    label: 'EUR (€)',
    value: 'EUR',
    symbol: '€',
    locale: 'de-DE',
  },
  AED: {
    label: 'AED (د.إ.‏)',
    value: 'AED',
    symbol: 'د.إ.‏',
    locale: 'ar-AE',
  },
  AFN: {
    label: 'AFN (؋)',
    value: 'AFN',
    symbol: '؋',
    locale: 'fa-AF',
  },
  ALL: {
    label: 'ALL (Lek)',
    value: 'ALL',
    symbol: 'Lek',
    locale: 'sq-AL',
  },
  AMD: {
    label: 'AMD (դր.)',
    value: 'AMD',
    symbol: 'դր.',
    locale: 'hy-AM',
  },
  ARS: {
    label: 'ARS ($)',
    value: 'ARS',
    symbol: '$',
    locale: 'es-AR',
  },
  AUD: {
    label: 'AUD ($)',
    value: 'AUD',
    symbol: '$',
    locale: 'en-AU',
  },
  AZN: {
    label: 'AZN (ман.)',
    value: 'AZN',
    symbol: 'ман.',
    locale: 'az-AZ',
  },
  BAM: {
    label: 'BAM (KM)',
    value: 'BAM',
    symbol: 'KM',
    locale: 'bs-BA',
  },
  BDT: {
    label: 'BDT (৳)',
    value: 'BDT',
    symbol: '৳',
    locale: 'bn-BD',
  },
  BGN: {
    label: 'BGN (лв.)',
    value: 'BGN',
    symbol: 'лв.',
    locale: 'bg-BG',
  },
  BHD: {
    label: 'BHD (د.ب.‏)',
    value: 'BHD',
    symbol: 'د.ب.‏',
    locale: 'ar-BH',
  },
  BIF: {
    label: 'BIF (FBu)',
    value: 'BIF',
    symbol: 'FBu',
    locale: 'fr-BI',
  },
  BND: {
    label: 'BND ($)',
    value: 'BND',
    symbol: '$',
    locale: 'ms-BN',
  },
  BOB: {
    label: 'BOB (Bs)',
    value: 'BOB',
    symbol: 'Bs',
    locale: 'es-BO',
  },
  BRL: {
    label: 'BRL (R$)',
    value: 'BRL',
    symbol: 'R$',
    locale: 'es-BR',
  },
  BWP: {
    label: 'BWP (P)',
    value: 'BWP',
    symbol: 'P',
    locale: 'en-BW',
  },
  BYR: {
    label: 'BYR (BYR)',
    value: 'BYR',
    symbol: 'BYR',
    locale: 'ru-BY',
  },
  BZD: {
    label: 'BZD ($)',
    value: 'BZD',
    symbol: '$',
    locale: 'es-BZ',
  },
  CDF: {
    label: 'CDF (FrCD)',
    value: 'CDF',
    symbol: 'FrCD',
    locale: 'fr-CD',
  },
  CHF: {
    label: 'CHF (CHF)',
    value: 'CHF',
    symbol: 'CHF',
    locale: 'gsw-FR',
  },
  CLP: {
    label: 'CLP ($)',
    value: 'CLP',
    symbol: '$',
    locale: 'es-CL',
  },
  CNY: {
    label: 'CNY (¥)',
    value: 'CNY',
    symbol: '¥',
    locale: 'zh-CN',
  },
  COP: {
    label: 'COP ($)',
    value: 'COP',
    symbol: '$',
    locale: 'es-CO',
  },
  CRC: {
    label: 'CRC (₡)',
    value: 'CRC',
    symbol: '₡',
    locale: 'es-CR',
  },
  CVE: {
    label: 'CVE (CV$)',
    value: 'CVE',
    symbol: 'CV$',
    locale: 'pt-CV',
  },
  CZK: {
    label: 'CZK (Kč)',
    value: 'CZK',
    symbol: 'Kč',
    locale: 'cs-CZ',
  },
  DJF: {
    label: 'DJF (Fdj)',
    value: 'DJF',
    symbol: 'Fdj',
    locale: 'fr-DJ',
  },
  DKK: {
    label: 'DKK (kr)',
    value: 'DKK',
    symbol: 'kr',
    locale: 'da-DK',
  },
  DOP: {
    label: 'DOP (RD$)',
    value: 'DOP',
    symbol: 'RD$',
    locale: 'es-DO',
  },
  DZD: {
    label: 'DZD (د.ج.‏)',
    value: 'DZD',
    symbol: 'د.ج.‏',
    locale: 'ar-DZ',
  },
  EEK: {
    label: 'EEK (kr)',
    value: 'EEK',
    symbol: 'kr',
    locale: 'et-EE',
  },
  EGP: {
    label: 'EGP (ج.م.)',
    value: 'EGP',
    symbol: 'ج.م.',
    locale: 'ar-EG',
  },
  ERN: {
    label: 'ERN (Nfk)',
    value: 'ERN',
    symbol: 'Nfk',
    locale: 'en-ER',
  },
  ETB: {
    label: 'ETB (Br)',
    value: 'ETB',
    symbol: 'Br',
    locale: 'am-ET',
  },
  GBP: {
    label: 'GBP (£)',
    value: 'GBP',
    symbol: '£',
    locale: 'en-UK',
  },
  GEL: {
    label: 'GEL (GEL)',
    value: 'GEL',
    symbol: 'GEL',
    locale: '',
  },
  GHS: {
    label: 'GHS (GH₵)',
    value: 'GHS',
    symbol: 'GH₵',
    locale: '',
  },
  GNF: {
    label: 'GNF (FG)',
    value: 'GNF',
    symbol: 'FG',
    locale: '',
  },
  GTQ: {
    label: 'GTQ (Q)',
    value: 'GTQ',
    symbol: 'Q',
    locale: '',
  },
  HKD: {
    label: 'HKD ($)',
    value: 'HKD',
    symbol: '$',
    locale: '',
  },
  HNL: {
    label: 'HNL (L)',
    value: 'HNL',
    symbol: 'L',
    locale: '',
  },
  HRK: {
    label: 'HRK (kn)',
    value: 'HRK',
    symbol: 'kn',
    locale: '',
  },
  HUF: {
    label: 'HUF (Ft)',
    value: 'HUF',
    symbol: 'Ft',
    locale: '',
  },
  IDR: {
    label: 'IDR (Rp)',
    value: 'IDR',
    symbol: 'Rp',
    locale: '',
  },
  ILS: {
    label: 'ILS (₪)',
    value: 'ILS',
    symbol: '₪',
    locale: '',
  },
  INR: {
    label: 'INR (টকা)',
    value: 'INR',
    symbol: 'টকা',
    locale: '',
  },
  IQD: {
    label: 'IQD (د.ع.‏)',
    value: 'IQD',
    symbol: 'د.ع.‏',
    locale: '',
  },
  IRR: {
    label: 'IRR (﷼)',
    value: 'IRR',
    symbol: '﷼',
    locale: '',
  },
  ISK: {
    label: 'ISK (kr)',
    value: 'ISK',
    symbol: 'kr',
    locale: '',
  },
  JMD: {
    label: 'JMD ($)',
    value: 'JMD',
    symbol: '$',
    locale: '',
  },
  JOD: {
    label: 'JOD (د.أ.‏)',
    value: 'JOD',
    symbol: 'د.أ.‏',
    locale: '',
  },
  JPY: {
    label: 'JPY (￥)',
    value: 'JPY',
    symbol: '￥',
    locale: 'jp-JP',
  },
  KES: {
    label: 'KES (Ksh)',
    value: 'KES',
    symbol: 'Ksh',
    locale: '',
  },
  KHR: {
    label: 'KHR (៛)',
    value: 'KHR',
    symbol: '៛',
    locale: '',
  },
  KMF: {
    label: 'KMF (FC)',
    value: 'KMF',
    symbol: 'FC',
    locale: '',
  },
  KRW: {
    label: 'KRW (₩)',
    value: 'KRW',
    symbol: '₩',
    locale: '',
  },
  KWD: {
    label: 'KWD (د.ك.‏)',
    value: 'KWD',
    symbol: 'د.ك.‏',
    locale: '',
  },
  KZT: {
    label: 'KZT (тңг.)',
    value: 'KZT',
    symbol: 'тңг.',
    locale: '',
  },
  LBP: {
    label: 'LBP (ل.ل.‏)',
    value: 'LBP',
    symbol: 'ل.ل.‏',
    locale: '',
  },
  LKR: {
    label: 'LKR (SL Re)',
    value: 'LKR',
    symbol: 'S',
    locale: '',
  },
  LTL: {
    label: 'LTL (Lt)',
    value: 'LTL',
    symbol: 'Lt',
    locale: '',
  },
  LVL: {
    label: 'LVL (Ls)',
    value: 'LVL',
    symbol: 'Ls',
    locale: '',
  },
  LYD: {
    label: 'LYD (د.ل.‏)',
    value: 'LYD',
    symbol: 'د.ل.‏',
    locale: '',
  },
  MAD: {
    label: 'MAD (د.م.‏)',
    value: 'MAD',
    symbol: 'د.م.‏',
    locale: '',
  },
  MDL: {
    label: 'MDL (MDL)',
    value: 'MDL',
    symbol: 'MDL',
    locale: '',
  },
  MGA: {
    label: 'MGA (MGA)',
    value: 'MGA',
    symbol: 'MGA',
    locale: '',
  },
  MKD: {
    label: 'MKD (MKD)',
    value: 'MKD',
    symbol: 'MKD',
    locale: '',
  },
  MMK: {
    label: 'MMK (K)',
    value: 'MMK',
    symbol: 'K',
    locale: '',
  },
  MOP: {
    label: 'MOP (MOP$)',
    value: 'MOP',
    symbol: 'MOP$',
    locale: '',
  },
  MUR: {
    label: 'MUR (MURs)',
    value: 'MUR',
    symbol: 'MURs',
    locale: '',
  },
  MXN: {
    label: 'MXN ($)',
    value: 'MXN',
    symbol: '$',
    locale: '',
  },
  MYR: {
    label: 'MYR (RM)',
    value: 'MYR',
    symbol: 'RM',
    locale: '',
  },
  MZN: {
    label: 'MZN (MTn)',
    value: 'MZN',
    symbol: 'MTn',
    locale: '',
  },
  NAD: {
    label: 'NAD (N$)',
    value: 'NAD',
    symbol: 'N$',
    locale: '',
  },
  NGN: {
    label: 'NGN (₦)',
    value: 'NGN',
    symbol: '₦',
    locale: '',
  },
  NIO: {
    label: 'NIO (C$)',
    value: 'NIO',
    symbol: 'C$',
    locale: '',
  },
  NOK: {
    label: 'NOK (kr)',
    value: 'NOK',
    symbol: 'kr',
    locale: '',
  },
  NPR: {
    label: 'NPR (नेरू)',
    value: 'NPR',
    symbol: 'नेरू',
    locale: '',
  },
  NZD: {
    label: 'NZD ($)',
    value: 'NZD',
    symbol: '$',
    locale: 'en-NZ',
  },
  OMR: {
    label: 'OMR (ر.ع.‏)',
    value: 'OMR',
    symbol: 'ر.ع.‏',
    locale: '',
  },
  PAB: {
    label: 'PAB (B/.)',
    value: 'PAB',
    symbol: 'B/.',
    locale: '',
  },
  PEN: {
    label: 'PEN (S/.)',
    value: 'PEN',
    symbol: 'S/.',
    locale: '',
  },
  PHP: {
    label: 'PHP (₱)',
    value: 'PHP',
    symbol: '₱',
    locale: '',
  },
  PKR: {
    label: 'PKR (₨)',
    value: 'PKR',
    symbol: '₨',
    locale: '',
  },
  PLN: {
    label: 'PLN (zł)',
    value: 'PLN',
    symbol: 'zł',
    locale: '',
  },
  PYG: {
    label: 'PYG (₲)',
    value: 'PYG',
    symbol: '₲',
    locale: '',
  },
  QAR: {
    label: 'QAR (ر.ق.‏)',
    value: 'QAR',
    symbol: 'ر.ق.‏',
    locale: '',
  },
  RON: {
    label: 'RON (RON)',
    value: 'RON',
    symbol: 'RON',
    locale: '',
  },
  RSD: {
    label: 'RSD (дин.)',
    value: 'RSD',
    symbol: 'дин.',
    locale: '',
  },
  RUB: {
    label: 'RUB (руб.)',
    value: 'RUB',
    symbol: 'руб.',
    locale: '',
  },
  RWF: {
    label: 'RWF (FR)',
    value: 'RWF',
    symbol: 'FR',
    locale: '',
  },
  SAR: {
    label: 'SAR (ر.س.‏)',
    value: 'SAR',
    symbol: 'ر.س.‏',
  },
  SDG: {
    label: 'SDG (SDG)',
    value: 'SDG',
    symbol: 'SDG',
    locale: '',
  },
  SEK: {
    label: 'SEK (kr)',
    value: 'SEK',
    symbol: 'kr',
    locale: '',
  },
  SGD: {
    label: 'SGD ($)',
    value: 'SGD',
    symbol: '$',
    locale: '',
  },
  SOS: {
    label: 'SOS (Ssh)',
    value: 'SOS',
    symbol: 'Ssh',
    locale: '',
  },
  SYP: {
    label: 'SYP (ل.س.‏)',
    value: 'SYP',
    symbol: 'ل.س.‏',
    locale: '',
  },
  THB: {
    label: 'THB (฿)',
    value: 'THB',
    symbol: '฿',
    locale: '',
  },
  TND: {
    label: 'TND (د.ت.‏)',
    value: 'TND',
    symbol: 'د.ت.‏',
    locale: '',
  },
  TOP: {
    label: 'TOP (T$)',
    value: 'TOP',
    symbol: 'T$',
    locale: '',
  },
  TRY: {
    label: 'TRY (TL)',
    value: 'TRY',
    symbol: 'TL',
    locale: '',
  },
  TTD: {
    label: 'TTD ($)',
    value: 'TTD',
    symbol: '$',
    locale: '',
  },
  TWD: {
    label: 'TWD (NT$)',
    value: 'TWD',
    symbol: 'NT$',
    locale: '',
  },
  TZS: {
    label: 'TZS (TSh)',
    value: 'TZS',
    symbol: 'TSh',
    locale: '',
  },
  UAH: {
    label: 'UAH (₴)',
    value: 'UAH',
    symbol: '₴',
    locale: '',
  },
  UGX: {
    label: 'UGX (USh)',
    value: 'UGX',
    symbol: 'USh',
    locale: '',
  },
  UYU: {
    label: 'UYU ($)',
    value: 'UYU',
    symbol: '$',
    locale: '',
  },
  UZS: {
    label: 'UZS (UZS)',
    value: 'UZS',
    symbol: 'UZS',
    locale: '',
  },
  VEF: {
    label: 'VEF (Bs.F.)',
    value: 'VEF',
    symbol: 'Bs.F.',
    locale: '',
  },
  VND: {
    label: 'VND (₫)',
    value: 'VND',
    symbol: '₫',
    locale: '',
  },
  XAF: {
    label: 'XAF (FCFA)',
    value: 'XAF',
    symbol: 'FCFA',
  },
  XOF: {
    label: 'XOF (CFA)',
    value: 'XOF',
    symbol: 'CFA',
    locale: '',
  },
  YER: {
    label: 'YER (ر.ي.‏)',
    value: 'YER',
    symbol: 'ر.ي.‏',
    locale: '',
  },
  ZAR: {
    label: 'ZAR (R)',
    value: 'ZAR',
    symbol: 'R',
    locale: '',
  },
  ZMK: {
    label: 'ZMK (ZK)',
    value: 'ZMK',
    symbol: 'ZK',
    locale: '',
  },
};
