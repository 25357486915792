import React from 'react';
import styled from 'styled-components/macro';
import { Button, Dialog, IDialogProps, Intent } from '@blueprintjs/core';

import { SPACING } from 'common/constants';

import { StatusFeedback } from '../../constants';

const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid ${StatusFeedback.NONE};
`;

export const Body = styled.div`
  flex: 1;
  max-height: 65vh;
  max-width: 80vw;
  padding: ${2 * SPACING}px;
  overflow: auto;
  & label:first-child {
    margin-top: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  padding: ${SPACING}px ${2 * SPACING}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 50%;
  align-items: center;
`;

const LeftButtons = styled(ButtonContainer)`
  justify-content: flex-start;
  > :not(:last-child) {
    margin-right: 8px;
  }
`;

const RightButtons = styled(ButtonContainer)`
  justify-content: flex-end;
  > :not(:first-child) {
    margin-left: 8px;
  }
`;

export interface IModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  modalTitle: string | React.ReactNode;
  className?: string;
  portalClassName?: string;
  /** @default 'OK' */
  textPrimaryButton?: string | JSX.Element;
  /** @default false */
  disabledPrimaryButton?: boolean;
  /** @default 'primary' */
  intentPrimaryButton?: Intent;
  hidePrimaryButton?: boolean;
  /** @default false */
  hideSecondaryButton?: boolean;
  /** @default true */
  isCloseButtonShown?: boolean;
  /** @default true */
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  closeTimeoutMS?: number;
  leftButtons?: JSX.Element[] | null;
  rightButtons?: JSX.Element[] | null;
  bodyStyle?: React.CSSProperties;
  formStyle?: React.CSSProperties;
  submitRef?: any;
  'data-test'?: string;
  onOK?: () => void;
  onCancel?: () => void;
  dialogProps?: Partial<IDialogProps>;
}

export class AxioModal extends React.PureComponent<IModalProps> {
  static defaultProps = {
    disabledPrimaryButton: false,
    isCloseButtonShown: true,
    textPrimaryButton: 'OK',
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEsc: true,
    closeTimeoutMS: 0,
    leftButtons: null,
    intentPrimaryButton: 'primary',
  };
  state = { didOpen: false };
  primaryButton: HTMLElement | Button | null = null;
  body: HTMLElement | Button | null = null;

  onOK = (
    e: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (typeof this.props.onOK === 'function') {
      this.props.onOK();
    }
  };

  onCancel = (e: React.SyntheticEvent<HTMLElement, Event> | undefined) => {
    e?.preventDefault();
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  };

  onOpenHandler = () => {
    this.setState({ didOpen: true });
  };

  render() {
    const {
      leftButtons,
      rightButtons,
      hidePrimaryButton,
      hideSecondaryButton,
      isCloseButtonShown,
      submitRef,
      textPrimaryButton,
      disabledPrimaryButton,
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc,
      className,
      bodyStyle,
      isOpen,
      modalTitle,
      children,
      formStyle,
      intentPrimaryButton,
      portalClassName,
      dialogProps,
    } = this.props;

    const leftButtonComponent = leftButtons ? (
      <LeftButtons>{leftButtons}</LeftButtons>
    ) : null;

    const rightButtonComponent = (
      <RightButtons>
        {rightButtons ? (
          rightButtons
        ) : (
          <>
            {!hideSecondaryButton && (
              <Button
                text="Cancel"
                data-test="Modal Cancel"
                onClick={this.onCancel}
                large
              />
            )}
            <Button
              key="Modal Submit"
              disabled={disabledPrimaryButton}
              intent={intentPrimaryButton}
              data-test="Modal Submit"
              type="submit"
              text={textPrimaryButton}
              elementRef={
                submitRef ? submitRef : (node) => (this.primaryButton = node)
              }
              large
            />
          </>
        )}
      </RightButtons>
    );

    const buttons = (
      <>
        {leftButtonComponent}
        {rightButtonComponent}
      </>
    );
    const overlayClickValue = this.state.didOpen
      ? shouldCloseOnOverlayClick
      : false;
    const dataTest = this.props['data-test'];
    const dataTestInclude = dataTest ? { 'data-test': dataTest } : {};

    return (
      <Dialog
        isOpen={isOpen}
        canOutsideClickClose={overlayClickValue}
        canEscapeKeyClose={shouldCloseOnEsc}
        className={className}
        isCloseButtonShown={isCloseButtonShown}
        title={modalTitle}
        onClose={this.onCancel}
        onOpened={this.onOpenHandler}
        portalClassName={portalClassName}
        autoFocus
        {...dialogProps}
      >
        <DialogForm
          style={formStyle}
          id="modal"
          action="#"
          onSubmit={this.onOK}
          {...dataTestInclude}
        >
          <Body ref={(node) => (this.body = node)} style={bodyStyle}>
            {children}
          </Body>
          {(!hidePrimaryButton || !!rightButtons) && <Footer>{buttons}</Footer>}
        </DialogForm>
      </Dialog>
    );
  }
}
