import { Map } from 'immutable';
import { createSelector } from 'reselect';
import moment from 'moment';

import { milestoneDateConstructor } from 'common/utils/DateUtils';

import { CURRENT, TARGET } from '../constants';
import { IMilestone, IResponse } from '../databaseTypes';
import {
  domainInfo,
  emptyWheel,
  scoreAndInfo,
  toMap,
} from '../views/Dashboard/DashboardMain/shared/dataProcessing';
import {
  getAllMilestones,
  getFromMap,
  getSelectedAssessmentId,
  getSelectedMilestoneId,
  getSelectedMilestoneForEditId,
  getResponses,
} from '.';
import { getComparisonAssessment, getModelScope } from './assessment';
import { getModel } from './model';
import { getResponseFilter } from '../actions/reporting/reporting';

export const getMilestonesForAssessment = createSelector(
  [getAllMilestones, getSelectedAssessmentId],
  (milestones, assessmentId) => {
    return getFromMap(milestones, assessmentId, Map({}));
  }
);

export const getSelectedMilestone = createSelector(
  [getMilestonesForAssessment, getSelectedMilestoneId],
  (milestones, selectedMilestoneId) => milestones.get(selectedMilestoneId, null)
);

const selectedMilestoneIsPast = (milestone: IMilestone) =>
  moment(milestone.milestone_date) <= moment(milestoneDateConstructor());

export const getPastSelectedMilestone = createSelector(
  [getMilestonesForAssessment, getSelectedMilestoneId],
  (milestones, selectedMilestoneId) =>
    milestones.filter(selectedMilestoneIsPast).get(selectedMilestoneId, null)
);

export const getMostRecentPastMilestone = createSelector(
  [getMilestonesForAssessment],
  (milestones) =>
    milestones
      .filter(selectedMilestoneIsPast)
      .maxBy((milestone) => milestone.milestone_date) ?? null
);

export const getSelectedOrMostRecentPastMilestone = createSelector(
  [getPastSelectedMilestone, getMostRecentPastMilestone],
  (selectedPastMilestone, mostRecentPastMilestone) =>
    selectedPastMilestone ?? mostRecentPastMilestone
);

export const getComparisonAssessmentOrPastMilestone = createSelector(
  [getComparisonAssessment, getSelectedOrMostRecentPastMilestone],
  (comparisonAssessment, pastMilestone) => {
    const comparisonAssessmentMapResponses = comparisonAssessment && {
      ...comparisonAssessment,
      responses: toMap(comparisonAssessment?.responses),
    };
    return comparisonAssessmentMapResponses ?? pastMilestone;
  }
);

export const getFutureSelectedMilestone = createSelector(
  [getMilestonesForAssessment, getSelectedMilestoneId],
  (milestones, selectedMilestoneId) =>
    milestones
      .filter((milestone) => !selectedMilestoneIsPast(milestone))
      .get(selectedMilestoneId, null)
);

export const getMilestonesToDisplay = createSelector(
  [
    getComparisonAssessmentOrPastMilestone,
    getFutureSelectedMilestone,
    getModel,
    getModelScope,
    getResponses,
    getSelectedAssessmentId,
  ],
  (
    comparisonAssessmentOrPastMilestone,
    futureMilestone,
    model,
    modelScope,
    responses,
    selectedAssessmentId
  ) => {
    if (!model || !modelScope) {
      return null;
    }

    const responseFilter = getResponseFilter(model.modelUuid, modelScope);
    const getScores = (
      responsesForScoring: Map<string, IResponse>,
      useTarget = false,
      filterFn: ((IModelPractice) => boolean) | null
    ) =>
      scoreAndInfo(
        model,
        //@ts-expect-error scoreAndInfo causes app-wide issues
        responsesForScoring,
        modelScope,
        useTarget ? 'targetLevel' : undefined,
        null,
        filterFn
      );

    const milValue = modelScope.startsWith('MIL')
      ? parseInt(modelScope.slice(-1))
      : null;
    const info = domainInfo(model, milValue);
    const currentResponses = toMap(responses);
    const currentScores = getScores(currentResponses, false, responseFilter);
    const targetScores = getScores(currentResponses, true, responseFilter);
    const emptyMap = Map<string, IResponse>({});

    // If there is a past milestone for the selected assessment that's set to
    // the current date, use current responses b/c they are the source of
    // truth. This gets around the issue where milestone responses don't get
    // fetched in assessments persistence after a response is updated
    // (e.g. delegating or changing level) until refresh.
    const pastMilestoneDate =
      comparisonAssessmentOrPastMilestone &&
      !!comparisonAssessmentOrPastMilestone['milestone_date']
        ? comparisonAssessmentOrPastMilestone['milestone_date']
        : null;
    const milestoneIsCurrentDate = pastMilestoneDate
      ? new Date(pastMilestoneDate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
      : false;
    const isSelectedAssessmentMilestone =
      comparisonAssessmentOrPastMilestone &&
      '_assessment' in comparisonAssessmentOrPastMilestone
        ? comparisonAssessmentOrPastMilestone['_assessment'] ===
          selectedAssessmentId
        : false;
    const comparisonOrPastResponses =
      milestoneIsCurrentDate && isSelectedAssessmentMilestone
        ? currentResponses
        : comparisonAssessmentOrPastMilestone?.responses || emptyMap;

    const past = comparisonAssessmentOrPastMilestone
      ? {
          title: comparisonAssessmentOrPastMilestone.name,
          scores: getScores(comparisonOrPastResponses, false, responseFilter),
          responses: comparisonOrPastResponses,
          isComparisonAssessment:
            'model' in comparisonAssessmentOrPastMilestone,
        }
      : {
          title: 'No Milestone',
          scores: emptyWheel(info),
          responses: emptyMap,
        };

    const current = {
      title: CURRENT,
      scores: currentScores,
      responses: currentResponses,
    };

    const targetResponsesAsLevels = currentResponses.map((value) => ({
      ...value,
      level: value.targetLevel,
    }));
    const future = futureMilestone
      ? {
          title: futureMilestone.name,
          scores: getScores(
            futureMilestone.responses ?? emptyMap,
            false,
            responseFilter
          ),
          responses: futureMilestone.responses ?? emptyMap,
        }
      : {
          title: TARGET,
          scores: targetScores,
          responses: targetResponsesAsLevels,
        };

    return {
      past,
      current,
      future,
    };
  }
);

export const getSelectedMilestoneForEdit = createSelector(
  [getMilestonesForAssessment, getSelectedMilestoneForEditId],
  (milestones, selectedMilestoneId) =>
    //@ts-expect-error Invalid Overload Call
    getFromMap(milestones, selectedMilestoneId, null)
);

export const hasFetchedSelectedMilestoneResponses = createSelector(
  [getSelectedMilestone],
  (milestone) => !!milestone && 'responses' in milestone
);

export const hasFetchedPastMilestoneResponses = createSelector(
  [getSelectedOrMostRecentPastMilestone],
  (milestone) => !!milestone && 'responses' in milestone
);

export const getSortedByDateMilestonesForAssessment = createSelector(
  [getMilestonesForAssessment],
  (milestones) => {
    return milestones
      .valueSeq()
      .toArray()
      .sort((msa, msb) => (msa.milestone_date > msb.milestone_date ? 1 : -1));
  }
);
