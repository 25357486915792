/**
 * Utilities class for various static functions we don't want associated with
 * an object but may need in multiple places.
 *
 * (I know, I know.  Generally the existence of a utilities class is evidence
 *  of a code organization problem)
 *
 */
import { Colors, Intent } from '@blueprintjs/core';
import { Map } from 'immutable';
import { isArray, isEqual, isObject, omit, transform } from 'lodash';
import numbro from 'numbro';
import Logger from 'common/utils/Logger';
import { IProps as AppProps } from 'assessments/views/App';
import { currencyMap } from '../../admin/views/Localization/constants';
import {
  GetUserCurrencyDocument,
  GetUserCurrencyQuery,
} from '../graphql/graphql-hooks';
import { ApolloClient } from '@apollo/client';
import { getIdToken } from './userContext';

declare global {
  interface Window {
    _hsq: any;
  }
}

export const fireHubSpotEvent = (email: string, eventId: string) => {
  const _hsq = (window._hsq = window._hsq || []);

  _hsq.push([
    'identify',
    {
      email: email,
    },
  ]);

  _hsq.push([
    'trackEvent',
    {
      id: eventId,
    },
  ]);
};

// This is copied from databasetypes on purpose.  I'm trying to
// limit the number of cross includes and it seems smart to
// keep utils and databasetypes from including each other.
export type TermsMapType = Map<string, [string, string]>;

// Determine if the client has internet access
export const clientIsOnline = () => {
  if (window.navigator.onLine) {
    return true;
  } else {
    Logger.warn(
      'You appear to be disconnected from the internet so the file was not uploaded. Please reconnect to the internet and try again.'
    );
    return false;
  }
};

/*
 * Intentinoally named something short because we may be using it a bunch in
 * code.  Looks up a given term in a map and supplies the term itself if
 * it's missing.
 */
export function term(
  lookup: string,
  termsMap: TermsMapType,
  capitalize = true
) {
  return termsMap.get(lookup.toLowerCase(), [lookup, lookup.toLowerCase()])[
    capitalize ? 0 : 1
  ];
}

/**
 * Removes any items from obj1 that don't equal the same key in obj2.
 */
export function intersectObjectValues(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return obj1;
  }
  if (obj1 instanceof Date) {
    return isEqual(obj1, obj2) ? obj1 : {};
  }

  const obj3 = { ...obj1 };
  const keys = Object.keys(obj3);

  for (const key of keys) {
    if (key in obj2) {
      if (isObject(obj3[key])) {
        obj3[key] = intersectObjectValues(obj3[key], obj2[key]);
        if (isEqual(obj3[key], {})) {
          delete obj3[key];
        }
      } else if (!isEqual(obj3[key], obj2[key])) {
        delete obj3[key];
      }
    } else {
      delete obj3[key];
    }
  }

  return obj3;
}

/**
 * Returns the server path where workbooks are supposed to be posted when
 * creating an assessment or milestone from a workbook
 */
export const excelUploadServerPath = () => {
  const w: Window & IAxioWindow = window;
  return w && w.c2m2_env && w.c2m2_env.C2M2_STORAGE_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.C2M2_STORAGE_URL) +
        '/workbooks'
    : '';
};

export const enableLocalhostAnalytics = () => {
  const analytics = (
    process.env.REACT_APP_ENABLE_GA_ANALYTICS || ''
  ).toUpperCase();
  return (
    analytics.startsWith('Y') || analytics.startsWith('T') || analytics === '1'
  );
};

export const googleAnalyticsPropertyID = () => {
  const w: Window & IAxioWindow = window;
  return (w && w.c2m2_env && w.c2m2_env.GA_PROPERTY_ID) || '';
};

/**
 * Returns the server path for graphql
 */
export const graphqlServerPath = () => {
  const w: Window & IAxioWindow = window;
  return w && w.c2m2_env && w.c2m2_env.AXIO360_STORAGE_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.AXIO360_STORAGE_URL).concat(
        '/graphql'
      )
    : '';
};

export const reportGenServerPath = () => {
  const w: Window & IAxioWindow = window;
  return w && w.c2m2_env && w.c2m2_env.AXIO360_STORAGE_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.AXIO360_STORAGE_URL).concat(
        '/api/reporting'
      )
    : '';
};

/**
 * Returns the server path used to check if an email corresponds to SSO
 */
export const graphqlIdpPath = () => {
  const w: Window & IAxioWindow = window;
  return w && w.c2m2_env && w.c2m2_env.AXIO360_STORAGE_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.AXIO360_STORAGE_URL).concat(
        '/idp'
      )
    : '';
};

/**
 * Returns the server path where reports are generated by the html reporting server
 */
export const htmlReportServerPath = () => {
  const w: Window & IAxioWindow = window;
  return w && w.c2m2_env && w.c2m2_env.AXIO360_HTMLREPORTING_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.AXIO360_HTMLREPORTING_URL)
    : '';
};

/**
 * Returns the server path where reports are generated by the latex reporting server
 */
export const reportingServerPath = () => {
  const w: Window & IAxioWindow = window;
  return w && w.c2m2_env && w.c2m2_env.C2M2_REPORTING_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.C2M2_REPORTING_URL)
    : '';
};

/**
 * Returns the server path for storage
 */
export const storageServerPath = () => {
  const w: Window & IAxioWindow = window;
  return w && w.c2m2_env && w.c2m2_env.C2M2_STORAGE_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.C2M2_STORAGE_URL)
    : '';
};

export const redesignUrl = () => {
  const w: Window & IAxioWindow = window;
  return w?.c2m2_env?.AXIO_REDESIGN_URL
    ? AxioUtilities.addEnvironmentToUrl(w.c2m2_env.AXIO_REDESIGN_URL)
    : '';
};

/**
 * Return a path given param values and a pathname
 * @param pathname The path you want to generate i.e. '/insurance/:component/:reviewId/:policyId/:fileId'
 * @param values The value sor params you want to try passing into the url i.e. { reviewId: 1, component: dashboard }
 */
export const generatePath = (
  pathname: string,
  values: {
    [key: string]: string | undefined | null;
  }
) => {
  const pathValues = pathname.split('/').filter((value) => !!value);
  let undefinedNotFound = true;
  const pathObject = pathValues.reduce((accum, value) => {
    while (undefinedNotFound) {
      if (value.startsWith(':')) {
        if (!values[value.substr(1)]) {
          undefinedNotFound = false;
          return accum;
        }
        accum[value] = values[value.substr(1)];
        return accum;
      }
      accum[value] = value;
      return accum;
    }
    return accum;
  }, {} as any);
  const path = pathValues.reduce((accum, value) => {
    if (pathObject[value] !== undefined) {
      return accum + pathObject[value] + '/';
    }
    return accum;
  }, '/');
  return path.length > 1 ? path.substr(0, path.length - 1) : path;
};

/**
 * Removes various database metadata fields that we don't want to store.
 * [code, _status, _links, _latest_version]
 */
export function stripMeta(item: any) {
  return omit(item, ['code', '_status', '_links', '_latest_version']);
}

/**
 * Compare two ISO-8601 date strings up to the second. Ignore microseconds
 * MongoDB/EVE/React/Moment seem to differ on how many d.p. to track time to
 */
export function dateStringsMatch(date1: string, date2: string) {
  return (
    (!date1 && !date2) ||
    !!(date1 && date2 && date1.split('.')[0] === date2.split('.')[0])
  );
}

/**
 * Compare the cannonical version of two URLS and return true if they
 * match and false if they don't.  This check effectively determines
 * whether or not two URLS end up in the same place if you were
 * to navigate to them.  SO... "asdf/" === "asdf" === "http://asdf" etc...
 * @param url1  The first URL to check
 * @param url2  The other URL to check
 */
export function urlsMatch(url1: string, url2: string) {
  const base = window.location.protocol + '//' + window.location.hostname;
  const canonicalUrl1 = new URL(url1, base).toString();
  const canonicalUrl2 = new URL(url2, base).toString();
  // (n)o (t)railing (s)lash
  const nts = (a) => (a.endsWith('/') ? a.slice(0, -1) : a);
  return nts(canonicalUrl1) === nts(canonicalUrl2);
}

/**
 * Moves an item from one place in an array to another.
 * @param {array} arr array of values that need to be re-arranged
 * @param {number} oldIndex The old index of the item to move
 * @param {number} newIndex The new place in trhe array for the item
 */
export const arrayMove = <T extends any>(
  arr: Array<T | undefined>,
  oldIndex: number,
  newIndex: number
) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr; // for testing
};

/**
 * Returns true if one dom element is an ancestor (somewhere in the
 * tree) of another dom element.
 * @param {Node} elemParent The node which "might" contain the other node.
 * @param {Node} elemChild The node which "might" be contained.
 */
export const isDomAncestor = (elemParent, elemChild) => {
  // Null/empty nodes contain nothing and aren't contained
  // and for our purposed, every node contains itself.
  if (!(elemParent instanceof Node) || !(elemChild instanceof Node)) {
    return false;
  }

  try {
    return elemParent === elemChild || elemParent.contains(elemChild);
  } catch (ex) {
    // most likely: https://bugzilla.mozilla.org/show_bug.cgi?id=208427
    return false;
  }
};

/**
 * Returns true if the onblur is causing a true focus loss v.
 * moving focus from one item contained in a container to another item
 * in the same container
 * @param {React.FocusEvent} event The event object raised in the on blur or null
 * @param {string[]} ignoreClasses List of classes that if the related target
 *        has it as a class, the function will always return "false" - basically,
 *        ignore it if the target has this class.
 */
export const willCauseFocusLoss = (
  event?: React.FocusEvent,
  ignoreClasses: string[] = []
) => {
  const elemParent = event && event.currentTarget;
  const elemChild = event && event.relatedTarget;
  if (
    elemChild &&
    ignoreClasses.some((className) =>
      (elemChild as Element).classList.contains(className)
    )
  ) {
    return false;
  }
  return !isDomAncestor(elemParent, elemChild);
};

/**
 * Return a number in terms of human readable suffixes - e.g. 1200 becoames 1.2k
 * @param {string|number} value value to parse
 * @param prefix
 * @param totalLength
 */
export const formatNumber = (
  value?: string | number,
  prefix = '',
  totalLength = 3
) => {
  if (typeof value === 'undefined') {
    return '';
  }
  if (!value) {
    return prefix + '0';
  }
  const formattedNumbroOptions = {
    average: true,
    totalLength,
    mantissa: 2,
    optionalMantissa: true,
    trimMantissa: true,
  };
  // The .add(0) is a hack to make sure passing an unparseable string
  // e.g. "Est." doesn't crash the app. Instead we get back "NaN"
  return prefix + numbro(value).add(0).format(formattedNumbroOptions);
};

/**
 * Return a number in terms of human readable suffixes - e.g. 1200 becoames $1.2k
 * @param {string|number} dollarValue value to parse
 * @param client
 */
export const formatDollars = (
  dollarValue?: string | number,
  client?: ApolloClient<any>
) => {
  const userCurrencyData = client
    ? client.readQuery<GetUserCurrencyQuery>({
        query: GetUserCurrencyDocument,
      })
    : null;
  const currency = userCurrencyData?.me?.employer?.currency ?? 'USD';
  const symbol =
    currency && currency in currencyMap
      ? currencyMap[currency].symbol ?? '$'
      : '$';
  return formatNumber(dollarValue, symbol);
};

// Should we ever decide to add the correct formatting for currencies
// that don't follow the symbol prefix + amount format, we can leverage
// the Intl.NumberFormat method which requires the correct locale
// (e.g. cs-CZ for CZK).  In that case, we can assign a default locale for
// each currency in the currencyMap (see constants.ts).  See the
// ISO 4217 - Currency Code Maintenance standard for supported currencies.

/**
 * Returns a currency amount formatted by Intl.NumberFormat to the correct
 * locale and convention (e.g. 12345.67 for CZK becomes 12 345,67 Kč)
 * @param amount
 * @param client defaults to USD
 */
export const formatCurrency = (
  amount?: string | number,
  client?: ApolloClient<any>
) => {
  if (typeof amount === 'undefined') return '';

  const userCurrencyData = client
    ? client.readQuery<GetUserCurrencyQuery>({
        query: GetUserCurrencyDocument,
      })
    : null;
  const currency = userCurrencyData?.me?.employer?.currency ?? 'USD';
  // Defaults to locale found in browser's navigator if no locale is found
  // in the currency map.  Can use localeplanet.com/java/ to try to find the
  // correct locale to currency mapping.
  const locale =
    currency && currency in currencyMap
      ? currencyMap[currency].locale
      : navigator.languages[0];
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency ?? 'USD',
  }).format(Number(amount));
};

/* eslint-disable */

var c2m2_env = c2m2_env === undefined ? {} : c2m2_env;
/* eslint-enable  */

const _env = {
  C2M2_STORAGE_URL: 'http://{sitehost}:8043',
  AXIO360_STORAGE_URL: 'http://{sitehost}:4000',
  AXIO360_HTMLREPORTING_URL: 'http://{sitehost}:4001',
  C2M2_REPORTING_URL: 'http://{sitehost}:8001/reports',
  GA_PROPERTY_ID: 'UA-93455881-2', // default to the dev property
  AXIO_REDESIGN_URL: 'http://{sitehost}:3000',
  ENABLE_DATADOG: '', //default to blank
  C2M2_ENV: '',
  ...c2m2_env,
};
const optionalPortOverrideMappings = {
  [_env.C2M2_STORAGE_URL]: process.env.REACT_APP_STORAGE_PORT,
  [_env.C2M2_REPORTING_URL]: process.env.REACT_APP_REPORTING_PORT,
  [_env.AXIO360_STORAGE_URL]: process.env.REACT_APP_GRAPHQL_PORT,
  [_env.AXIO360_HTMLREPORTING_URL]: process.env.REACT_APP_HTMLREPORTING_PORT,
  [_env.AXIO_REDESIGN_URL]: process.env.REACT_APP_REDESIGN_PORT,
};

export interface IAxioEnvList {
  C2M2_COGNITO_USER_POOL?: string;
  C2M2_COGNITO_CLIENTID?: string;
  C2M2_COGNITO_REGION?: string;
  C2M2_COGNITO_IDENTITY_POOL?: string;
  C2M2_STORAGE_URL?: string;
  AXIO360_STORAGE_URL?: string;
  AXIO360_HTMLREPORTING_URL?: string;
  C2M2_REPORTING_URL: string;
  GA_PROPERTY_ID: string;
  ENABLE_DATADOG: string;
  C2M2_ENV: string;
  DATADOG_CLIENT_TOKEN: string;
  COGNITO_POOL_SITE_HOST: string;
  AXIO_REDESIGN_URL: string;
}
export interface IAxioWindow {
  c2m2_env?: IAxioEnvList;
  c2m2Store?: any;
}

export class AxioUtilities {
  /* Converts a URl with a "use localhost" marker into a url that has
     whatever site served up the client page.
  */
  static addEnvironmentToUrl(baseUrl: string) {
    const newUrl = baseUrl.replace(/{sitehost}/g, window.location.hostname);
    return AxioUtilities.envBasedPort(newUrl, baseUrl);
  }

  /**
   * Allow the node environment to override the port assignment. This is only used to support automated testing.
   */
  static envBasedPort(activeUrl: string, baseUrl: string) {
    if (!process || !process.env) {
      return activeUrl;
    }
    return AxioUtilities.swapPort(
      activeUrl,
      optionalPortOverrideMappings[baseUrl]
    );
  }

  static swapPort(urlString: string, newPort: string | undefined) {
    // We're not using the "new URL" method here - which is admittedly much
    // more robust, because IE11 doesn't support it and there isn't a
    // mix-in that we get for free with CRA.  I'm also not using string
    // interpolation (``) because  that's not supported in IE11 either
    // (even though) there is a "for free" mixin.
    if (!newPort) {
      return urlString;
    }
    return urlString.replace(
      /^(.*:\/\/)?([^/:]*)?(:\d+)?(\/?)(.*)/,
      '$1$2:' + newPort + '$4$5'
    );
  }

  // Test to see if we're in development mode.
  static get inDevelopmentMode(): boolean {
    return process && process.env && process.env.NODE_ENV === 'development';
  }

  static addDebuggingFeatures(app) {
    if (AxioUtilities.inDevelopmentMode) {
      app.generateFakeData = (
        currentLevel = -1,
        targetLevel = -1,
        milLevel: number | null = null
      ) => {
        const { actions, model } = app.props as AppProps;

        const domains = model.data.domains;
        const dimensions = model.data.dimensionSchema?.dimensions ?? [];
        const hasDimensions = !!dimensions?.length;
        domains.forEach((domain) =>
          domain.objectives.forEach((objective) =>
            objective.practices.forEach((practice) => {
              if ((milLevel && practice.mil === milLevel) || !milLevel) {
                if (hasDimensions) {
                  dimensions.forEach((dimension) =>
                    actions.updateDimensions(practice.id, {
                      key: dimension.key,
                      level:
                        currentLevel === -1
                          ? Math.round(Math.random() * 3)
                          : currentLevel,
                      targetLevel:
                        targetLevel === -1
                          ? Math.round(Math.random() * 3)
                          : targetLevel,
                    })
                  );
                } else {
                  actions.updatePracticeLevel(
                    practice.id,
                    currentLevel === -1
                      ? Math.round(Math.random() * 3)
                      : currentLevel
                  );
                  actions.updatePracticeTargetLevel(
                    practice.id,
                    targetLevel === -1
                      ? Math.round(Math.random() * 3)
                      : targetLevel
                  );
                }
              }
            })
          )
        );
        return;
      };
      (window as any).axioUtilities = app;
    }
  } // addDebuggingFeatures
} // AxioUtilities

export default AxioUtilities;

/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 */
export const hashFnv32a = (
  value: Record<string, unknown> | string | null
): number | string => {
  /*jshint bitwise:false */
  const str = typeof value === 'string' ? value : JSON.stringify(value);
  const l = str.length;

  let hval = 0x811c9dc5;

  for (let i = 0; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval +=
      (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }

  return hval >>> 0;
};

/**
 * Takes a canvas element and a widget (which should have a name and an svg element)
 * and converts it to a URI which can be used for exporting images
 */
export const convertWidgetToDataURI = async (
  canvasElement: HTMLCanvasElement,
  widget: {
    element: Element;
    // these are other properties that could be on the object
    // but this function does not use. Still might be worth the dev
    // being aware
    _width?: number;
    _name?: string;
    _height?: number;
  },
  forDashboard?: boolean
) => {
  const { element } = widget;
  // serialize the SVG
  const svgString = new XMLSerializer().serializeToString(element);
  // create a blob from the serialization
  const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });

  const w = window.self;
  // give the blob an Object URL
  const domURL = w.URL || w.webkitURL || w;

  const url = domURL.createObjectURL(svg);

  // create an image that loads the blob
  const image = new Image();

  // wait for the image to load
  await new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (e) => {
      reject(e);
    };

    image.src = url;
  });

  // create a canvas context the size of the image
  const context = canvasElement.getContext('2d');

  if (!context) {
    return '';
  }

  const width = element.clientWidth;
  const height = element.clientHeight;
  context.canvas.width = forDashboard ? 1500 : width && width > 0 ? width : 400;
  context.canvas.height = forDashboard
    ? 1500
    : height && height > 0
    ? height
    : 400;

  // draw the image onto the canvas
  context.drawImage(image, 0, 0);

  // export the canvas to a data URI
  const dataURI = canvasElement.toDataURL('image/png');

  // clean up the Object URL
  domURL.revokeObjectURL(url);

  return dataURI;
};

// provides the length of text (when provided both text and font) for use in UI sizing/positioning
export class TextWidthGetter {
  private context: CanvasRenderingContext2D | null;

  constructor() {
    const canvas = document.createElement('canvas');
    this.context = canvas.getContext('2d');
  }

  getTextWidth(text: string, font: string): number {
    if (this.context) {
      this.context.font = font;
      const metrics = this.context.measureText(text);
      return metrics.width;
    } else {
      return text.length;
    }
  }
}

export const colorForIntent = (intent: Intent) => {
  switch (intent) {
    case Intent.PRIMARY:
      return Colors.BLUE3;
    case Intent.SUCCESS:
      return Colors.GREEN3;
    case Intent.WARNING:
      return Colors.ORANGE3;
    case Intent.DANGER:
      return Colors.RED3;
    default:
      return Colors.GRAY3;
  }
};

export const createPatch = <T extends {}>(modified: T, initial: T) => {
  return transform(
    modified,
    function (diff: Partial<T>, value: T[keyof T], key) {
      if (!isEqual(value, initial[key])) {
        diff[key] =
          isObject(value) &&
          isObject(initial[key]) &&
          !isArray(value) &&
          !isArray(initial)
            ? createPatch(value, initial[key])
            : value;
      }
    }
  );
};

interface GQLRequestProps {
  variables: any;
  operationName: string;
  query: string;
  callback?: (response: any, error: any) => void;
}

/**
 * make an ad-hoc request to GQL
 * @param variables
 * @param operationName
 * @param query
 * @param callback
 */
export async function makeGQLQuery({
  variables,
  operationName,
  query,
  callback = (_resp, error) => {
    if (error) {
      Logger.error(error);
    }
  },
}: GQLRequestProps) {
  try {
    const cognitoToken = await getIdToken();
    return fetch(graphqlServerPath(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Request-Headers': 'Location, X-Request-URL',
        Authorization: cognitoToken ?? '',
      },
      body: JSON.stringify({
        operationName,
        query,
        variables,
      }),
    })
      .then((r) => r.json())
      .then((response) => callback(response, null));
  } catch (error) {
    return callback(null, error);
  }
}
