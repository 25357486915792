import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { DASHBOARD_URL } from 'common/constants';
import { hasSurveyQuestions } from 'common/selectors/company';
import Logger from 'common/utils/Logger';
import { IRootState } from '../../reducers';
import { Position, IWizardPosition } from '../qlTypes';
import { getResponses } from '../selectors';
import {
  getIsAssessmentReadOnly,
  getSelectedAssessment,
} from '../selectors/assessment';
import { assessmentConvertBegin, updatePracticeLevel } from '.';
import { changeQuicklaunchPosition, saveNow } from './persistence';
import { getFoundationsWizardQuestions } from '../selectors/model';
import { getWizardPosition } from '../selectors/quicklaunch';

export const quickLaunchNext = () => (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>,
  getState: () => IRootState
) => {
  const state = getState();
  const isAssessmentReadOnly = getIsAssessmentReadOnly(state);
  const hasSurvey = hasSurveyQuestions(state);
  if (isAssessmentReadOnly) {
    dispatch(unpersistedQuickLaunchNext(hasSurvey));
    return;
  }
  const { current: quicklaunchPosition, lastPosition } = getWizardPosition(
    state
  );

  if (quicklaunchPosition === lastPosition) {
    const questions = getFoundationsWizardQuestions(state);
    const wizardResponses = questions
      .flatMap((question) => question.responses)
      .filter((response) => response.level !== 0);
    const responses = getResponses(state);

    wizardResponses.forEach((r) => {
      if (r.practice) {
        const response = responses.get(r.practice);
        if (response && (!response.level || response.level === -1)) {
          // set a level for all unselected QL responses
          dispatch(updatePracticeLevel(r.practice, 0));
        }
      }
    });
  }
  dispatch(persistedQuickLaunchNext(hasSurvey));
};

export const quickLaunchBack = () => (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>,
  getState: () => IRootState
) => {
  const state = getState();
  const isAssessmentReadOnly = getIsAssessmentReadOnly(state);
  const hasSurvey = hasSurveyQuestions(state);
  if (isAssessmentReadOnly) {
    dispatch(unpersistedQuickLaunchBack(hasSurvey));
    return;
  }
  dispatch(persistedQuickLaunchBack(hasSurvey));
};

export const persistedQuickLaunchNext = (hasSurvey: boolean) => {
  return changeQuicklaunchPosition(next(hasSurvey));
};

export const persistedQuickLaunchBack = (hasSurvey: boolean) => {
  return changeQuicklaunchPosition(back(hasSurvey));
};

export const unpersistedQuickLaunchNext = (hasSurvey: boolean) => {
  return unpersistedChangeQuicklaunchPosition(next(hasSurvey));
};

export const unpersistedQuickLaunchBack = (hasSurvey: boolean) => {
  return unpersistedChangeQuicklaunchPosition(back(hasSurvey));
};

const unpersistedChangeQuicklaunchPosition = (
  update: (position: IWizardPosition) => Position
) => (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>,
  getState: () => IRootState
) => {
  const state = getState();
  const assessment = getSelectedAssessment(state);
  if (!assessment) {
    Logger.error('Call to changeQuicklaunchPosition with no assessment');
    return;
  }

  const wizardPosition = getWizardPosition(state);
  const newPosition = update(wizardPosition);

  dispatch({
    type: 'CHANGE_QL_POSITION',
    assessmentId: assessment._id,
    payload: {
      quicklaunch_position: String(newPosition) as Position,
    },
  });
};

export const next = (hasSurvey: boolean) => (
  position: IWizardPosition
): Position => {
  switch (position.current) {
    case 'intro':
      return hasSurvey ? 'survey' : 0;
    case 'survey':
      return 0;
    case position.lastPosition:
    case 'finished':
      return 'finished';
    default:
      return typeof position.current === 'number'
        ? position.current + 1
        : position.current;
  }
};

export const back = (hasSurvey: boolean) => (
  position: IWizardPosition
): Position => {
  switch (position.current) {
    case 'intro':
    case 'survey':
      return 'intro';
    case 0:
      return hasSurvey ? 'survey' : 'intro';
    case 'finished':
      return position.lastPosition;
    default:
      return typeof position.current === 'number'
        ? position.current - 1
        : position.current;
  }
};

export const finishQL = () => (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>,
  getState: () => IRootState
) => {
  const state = getState();
  const isAssessmentReadOnly = getIsAssessmentReadOnly(state);
  if (isAssessmentReadOnly) {
    dispatch(push(DASHBOARD_URL));
    return;
  }
  dispatch(quickLaunchNext());
  // We are doing an explict save because the UNSUBSCRIBE action will get called when navigating to the dashboard
  // which results in the pendingUpdates to not be saved in particular for the last batch of UPDATE_SELECTED_PRACTICE actions
  dispatch(saveNow());
  dispatch(assessmentConvertBegin());
  dispatch(push(DASHBOARD_URL));
};
