import { FieldFunctionOptions, FieldPolicy } from '@apollo/client';

import { typedReadField } from '../cacheUtils/helpers';
import { User, SearchUsersResultFragment } from '../graphql-hooks';

export const searchUsersPolicy: FieldPolicy<SearchUsersResultFragment[]> = {
  read: (existing, context) =>
    existing
      ? [
          ...existing.filter((user) => !readUserIsDeleted(context, user)),
        ].sort((a, b) =>
          readUserDisplayName(context, a).localeCompare(
            readUserDisplayName(context, b)
          )
        )
      : undefined,
};

const readUserDisplayName = (
  context: FieldFunctionOptions,
  user: SearchUsersResultFragment
) => typedReadField<User, 'displayName'>(context, 'displayName', user);

const readUserIsDeleted = (
  context: FieldFunctionOptions,
  user: SearchUsersResultFragment
) => typedReadField<User, 'isDeleted'>(context, 'isDeleted', user);
