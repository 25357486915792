import { Button, Callout, Classes, FormGroup } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withTheme } from 'styled-components/macro';

import { IAxioWindow } from 'common/utils/AxioUtilities';
import { getSsoStatus, useUser } from 'common/utils/userContext';
import { IThemeInterface } from 'themes';
import {
  AuthForm,
  AuthPage,
  BottomLink,
  BottomLinks,
  LoginInput,
} from './shared';

interface Props extends RouteComponentProps<{}> {
  theme: IThemeInterface;
}
export const SsoPage = (props: Props) => {
  const emailFromSearch = new URLSearchParams(props.location.search).get(
    'email'
  );
  const initialFormValues = {
    email: emailFromSearch || '',
  };

  const { federatedLogin } = useUser();
  const [error, setError] = useState('');

  const ssoCode = new URLSearchParams(props.location.search).get('code');
  const showInfo = new URLSearchParams(props.location.search).get('info');
  const loginStarted = new URLSearchParams(props.location.search).get(
    'loginStarted'
  );
  if (ssoCode) {
    if (!loginStarted) {
      federatedLogin(ssoCode).catch((err) => {
        setError(err.message);
        props.history.replace({
          pathname: '/authentication/sso',
          search: emailFromSearch
            ? `email=${encodeURIComponent(emailFromSearch)}`
            : '',
        });
      });
      // mark login has already started so any later renders don't also try and login
      const newSearchParams = new URLSearchParams(props.location.search);
      newSearchParams.append('loginStarted', 'true');
      props.history.replace({
        pathname: '/authentication/sso',
        search: String(newSearchParams),
      });
    }
    return (
      <AuthPage
        welcomeText="Single Sign-On"
        welcomeTextStyles={
          props.theme.authWelcomeTextStyles as React.CSSProperties
        }
      >
        <Callout icon="cloud">Logging you in to Axio360</Callout>
      </AuthPage>
    );
  }
  const onSubmit = async (
    { email }: typeof initialFormValues,
    { setSubmitting }: FormikHelpers<typeof initialFormValues>
  ) => {
    const hasSso = await getSsoStatus(email);
    if (hasSso) {
      const w: typeof window & IAxioWindow = window;
      if (w.c2m2_env) {
        const emailBase = email.split('@').slice(-1).pop();
        // window.location.href seems like it should work but also includes any hash or search stuff that will confuse cognito checks
        const redirectUri = window.location.origin + window.location.pathname;
        window.location.assign(
          `https://${w.c2m2_env.COGNITO_POOL_SITE_HOST}.auth.${w.c2m2_env.C2M2_COGNITO_REGION}.amazoncognito.com/authorize?idp_identifier=${emailBase}&response_type=code&client_id=${w.c2m2_env.C2M2_COGNITO_CLIENTID}&redirect_uri=${redirectUri}`
        );
      }
    } else {
      setSubmitting(false);
      setError(
        'Your account does not have Single Sign-On enabled. To enable this feature please contact support@axio.com.'
      );
    }
  };
  return (
    <AuthPage
      welcomeText="Single Sign-On"
      welcomeTextStyles={
        props.theme.authWelcomeTextStyles as React.CSSProperties
      }
    >
      <Formik<typeof initialFormValues>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, handleSubmit, handleChange }) => {
          return (
            <AuthForm onSubmit={handleSubmit} autoComplete="off" role="form">
              {!!showInfo && (
                <Callout
                  icon={
                    <FontAwesomeIcon
                      className={Classes.ICON}
                      icon={['fal', 'info-circle']}
                      size="lg"
                    />
                  }
                >
                  Your company has enabled Single Sign-On. <br /> Please use
                  your company assigned email to login.
                </Callout>
              )}
              <FormGroup>
                <LoginInput
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email address"
                  data-test="Email Input"
                  disabled={isSubmitting}
                  required
                  large
                />
              </FormGroup>
              <Button
                text="Sign In"
                type="submit"
                data-test="Submit Login"
                disabled={isSubmitting}
                fill
                large
              />
              {!!error && <Callout intent="danger">{error}</Callout>}
              <BottomLinks smallMargins={true}>
                <BottomLink
                  isWhite={true}
                  to={props.theme.eulaLink}
                  target="_blank"
                >
                  Terms of Service
                </BottomLink>
                <BottomLink isWhite={true} to="/">
                  Return to login page
                </BottomLink>
              </BottomLinks>
            </AuthForm>
          );
        }}
      </Formik>
    </AuthPage>
  );
};

export const SsoForm = withTheme(SsoPage);
