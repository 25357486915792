import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { loadingReducer } from './loading';
import { reportingReducer } from './reporting';
import { userReducer } from './user';

const rootReducer = (history) =>
  combineReducers({
    loading: loadingReducer,
    reporting: reportingReducer,
    router: connectRouter(history),
    user: userReducer,
  });

export type ICommonState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
