import { toArray } from 'lodash';

export function spliceString(
  str: string,
  i: number,
  count: number,
  insert: string
) {
  const arr = toArray(str);
  arr.splice(i, count, insert);
  return arr.join('');
}
