import hoist from 'hoist-non-react-statics';
import React from 'react';
import {
  Tab as OriginalTab,
  TabList as OriginalTabList,
  TabPanel,
  Tabs,
} from 'react-tabs';
import styled from 'styled-components/macro';

export const CLASSNAME = 'AxioTab__tab';
export const SELECTED_CLASSNAME = CLASSNAME + '--selected';

const CustomTabList = styled(OriginalTabList).attrs(() => ({
  className: 'react-tabs__tab-list AxioTabList',
}))`
  background-color: ${(props) => props.theme.colors.fullBackground};
`;

export const TabInner = styled.div`
  height: 2.25rem;
  background-color: #fff;
  border-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer;
  margin: 0 0.625rem 0;
  transform: perspective(5.5rem) rotateX(30deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.33;
`;

export const TabText = styled.span`
  display: block;
  z-index: 100;
  position: relative;
  line-height: 2.5rem;
  font-size: 1em;
  cursor: pointer;
  transform: translateZ(10px);
`;

function CustomTab(props) {
  return (
    <OriginalTab
      className={'react-tabs__tab ' + CLASSNAME}
      selectedClassName={'react-tabs__tab--selected ' + SELECTED_CLASSNAME}
      {...props}
    >
      <TabInner />
      <TabText>{props.children}</TabText>
    </OriginalTab>
  );
}

const Tab = hoist(CustomTab, OriginalTab);
const TabList = hoist(CustomTabList, OriginalTabList);

export { Tabs, TabList, TabPanel, Tab };
