export default {
  questions: [
    {
      responses: [
        {
          number: '1.a',
          practice: '.ID.AM.1',
          text:
            'We have an inventory of important physical assets, including computers and other system devices.',
          exclusive: false,
          level: 2,
        },
        {
          number: '1.b',
          practice: '.ID.AM.2',
          text:
            'We have an inventory of important software platforms and applications.',
          exclusive: false,
          level: 2,
        },
        {
          number: '1.c',
          practice: '.ID.AM.4',
          text:
            'We maintain a list of external information systems (e.g., cloud-based HR applications, third-party supply ordering systems, and external threat information feeds).',
          exclusive: false,
          level: 1,
        },
        {
          number: '1.d',
          practice: '.PR.IP.1',
          text:
            'We maintain baseline configurations for information technology and industrial control systems.',
          exclusive: false,
          level: 1,
        },
        {
          number: '1.e',
          practice: '.PR.IP.3',
          text:
            'We evaluate and log changes to configurations before they are implemented.',
          exclusive: false,
          level: 2,
        },
        {
          number: '1.f',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '1',
      title: 'Asset Management',
      text: [
        'A foundational activity for any cybersecurity program is identifying what assets need to be protected to sustain operations. Assets to be inventoried may include, for example, IT desktop and laptop computers, networking equipment, applications, and servers, and also equipment such as process control system devices and components, building management systems, and programmable logic controllers (PLCs).',
        'Changes to assets should be evaluated prior to implementation to fully understand their impact on the functionality and security of the systems they support. Any changes made to an inventoried asset should be logged to support troubleshooting and incident response activities. ',
        'To help keep track of changes to assets, many organizations establish configuration baselines that define the settings for software and hardware. Baselines can be used to identify unauthorized or unapproved changes to an asset, set up new assets consistently, and reset or restore an asset when needed.',
      ],
      prompt:
        'Please select all the responses that best describe your current asset management. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '2.a',
          practice: '.PR.AC.1',
          text:
            'We issue, manage, and revoke identities and credentials for authorized devices, users and processes.',
          exclusive: false,
          level: 1,
        },
        {
          number: '2.b',
          practice: '.PR.AC.2',
          text: 'We protect and manage access to physical assets.\n\n',
          exclusive: false,
          level: 3,
        },
        {
          number: '2.c',
          practice: '.PR.AC.3',
          text: 'We manage remote access to assets.',
          exclusive: false,
          level: 3,
        },
        {
          number: '2.d',
          practice: '.PR.AC.4',
          text: 'We manage access permissions and authorizations.',
          exclusive: false,
          level: 1,
        },
        {
          number: '2.e',
          practice: '.PR.AC.6',
          text: 'We ensure credentials are matched to the correct identities.',
          exclusive: false,
          level: 2,
        },
        {
          number: '2.f',
          practice: '.PR.AC.7',
          text: 'We authenticate users, devices, and other assets.',
          exclusive: false,
          level: 1,
        },
        {
          number: '2.g',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '2',
      title: 'Identity and Access Management',
      text:
        'Controlling physical and electronic access to assets is an important step in securing the organization and its operations. This includes the process of creating identities in accounts and associated passwords, determining the requirements for access, and matching credentials with identities. ',
      prompt:
        'Please select all the responses that best describe your current identity and access management. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '3.a',
          practice: '.PR.IP.9',
          text:
            'We have a basic plan for how to respond to and recover from cybersecurity incidents.',
          exclusive: false,
          level: 1,
        },
        {
          number: '3.b',
          practice: '.RS.CO.2',
          text:
            'We report (or would report) cybersecurity incidents internally and/or to appropriate external trusted parties such as a relevant ISAC.',
          exclusive: false,
          level: 2,
        },
        {
          number: '3.c',
          practice: '.RS.CO.4',
          text: 'We perform incident coordination with stakeholders.',
          exclusive: false,
          level: 2,
        },
        {
          number: '3.d',
          practice: '.RS.AN.1',
          text: 'We investigate notifications from detection systems.',
          exclusive: false,
          level: 3,
        },
        {
          number: '3.e',
          practice: '.RS.AN.2',
          text: 'We analyze incidents to determine impact.',
          exclusive: false,
          level: 2,
        },
        {
          number: '3.f',
          practice: '.RS.MI.1',
          text: 'We take action to contain the impact of incidents.',
          exclusive: false,
          level: 2,
        },
        {
          number: '3.g',
          practice: '.RS.MI.2',
          text: 'We take action to mitigate incidents.',
          exclusive: false,
          level: 1,
        },
        {
          number: '3.h',
          practice: '.RC.RP.1',
          text:
            'We take action to return operations to normal following an incident.',
          exclusive: false,
          level: 2,
        },
        {
          number: '3.i',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '3',
      title: 'Incident Response',
      text: [
        'The organization should create a plan describing incident management procedures so that response activities will be repeatable. The organization may want to consult existing guidance or outside expertise for information about incident management best practices. Incident response activities described in the plan might include containing the impact of an incident, collecting evidence, and developing and implementing corrective actions and controls. ',
        'Incidents should be recorded in some way to provide a history and knowledge base for responding to future incidents.',
        'Examples of when incidents would be reported externally would be to satisfy industry regulators or state reporting requirements for data breaches.',
      ],
      prompt:
        'Please select all the responses that best describe your current incident response. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '4.a',
          practice: '.DE.AE.3',
          text: 'We collect event data from multiple sources and sensors.',
          exclusive: false,
          level: 2,
        },
        {
          number: '4.b',
          practice: '.DE.AE.4',
          text: 'We analyze events to determine possible impact.',
          exclusive: false,
          level: 2,
        },
        {
          number: '4.c',
          practice: '.DE.DP.4',
          text: 'We report events to relevant stakeholders.',
          exclusive: false,
          level: 2,
        },
        {
          number: '4.d',
          practice: '.PR.PT.1',
          text: 'We perform logging and auditing for important assets.',
          exclusive: false,
          level: 2,
        },
        {
          number: '4.e',
          practice: '.DE.CM.1',
          text:
            'We conduct network monitoring to detect potential cybersecurity events.',
          exclusive: false,
          level: 3,
        },
        {
          number: '4.f',
          practice: '.DE.CM.2',
          text:
            'We monitor the physical environment to detect potential cybersecurity events.',
          exclusive: false,
          level: 2,
        },
        {
          number: '4.g',
          practice: '.DE.CM.4',
          text: 'We attempt to detect malicious code.',
          exclusive: false,
          level: 2,
        },
        {
          number: '4.h',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '4',
      title: 'Monitoring and Event Detection',
      text: [
        "A cybersecurity event is any occurrence that has a potential impact to the cybersecurity of the organization's assets. Such events are often relatively minor (e.g., forgotten passwords), but can be escalating (e.g., an increasing number of users are unable to log in), or major (e.g., a network outage is preventing communications to remote assets). ",
        'Events can be detected through multiple sources such as users reporting actual or suspected cyber events to a help desk or other collection point, business partners or vendors, and IT and cybersecurity staff. Logging and monitoring activities should also be designed for identifying events. Logging might reveal for example changes to assets that deviate from baseline configurations and unexpected or suspicious activity.',
        'Once events are detected, organizations should perform analysis to better undersatnd the potential impact the event may have on operations and assets. Those events that have an operational impact should be reported to relevant stakeholders.',
      ],
      prompt:
        'Please select all the responses that best describe your current monitoring and event detection. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '5.a',
          practice: '.PR.AC.5',
          text:
            'We protect network integrity through segmentation and other means.\n',
          exclusive: false,
          level: 2,
        },
        {
          number: '5.b',
          practice: '.PR.DS.1',
          text: 'We protect data at rest.',
          exclusive: false,
          level: 1,
        },
        {
          number: '5.c',
          practice: '.PR.DS.2',
          text: 'We protect data in transit.',
          exclusive: false,
          level: 1,
        },
        {
          number: '5.d',
          practice: '.PR.DS.7',
          text:
            'When we conduct development we maintain separate development and testing environments and production environments.',
          exclusive: false,
          level: 3,
        },
        {
          number: '5.e',
          practice: '.PR.IP.4',
          text: 'We maintain backups of important data.',
          exclusive: false,
          level: 1,
        },
        {
          number: '5.f',
          practice: '.PR.PT.2',
          text: 'We control the use of removable media.',
          exclusive: false,
          level: 2,
        },
        {
          number: '5.g',
          practice: '.PR.PT.4',
          text: 'We protect communications and control networks.',
          exclusive: false,
          level: 1,
        },
        {
          number: '5.h',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '5',
      title: 'Network and Data Protection',
      text: [
        'Organizations should consider separating different environments through physical or logical means. Some environments that an organization may consider isoating are industrial controls networks, PCI environments, and building management systems. Logical segmentation includes traditional firewalls and subnetting of networks.',
        'Sensitive data should be protected both at rest on computers or storage devices and while in trasint across local and wide area networks. Examples of how organizations protect data at rest are encryption, access controls, and data masking. Data in transit can  be protected through encryption or encrypted virtual private networks (VPN).',
        'Organizations should implement fundamental network security practices such as firewalls, access control lists for network appliances, and restricting physical access to network devices.',
      ],
      prompt:
        'Please select all the responses that best describe your current network and data protection. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '6.a',
          practice: '.ID.BE.4',
          text:
            'We have identified dependancies for delivery of our critical services (e.g., service providers and vendors). \n',
          exclusive: false,
          level: 1,
        },
        {
          number: '6.b',
          practice: '.ID.SC.2',
          text:
            'We use a cyber supply chain risk assessment process to identify important suppliers and third-party partners of information systems, components, and services.',
          exclusive: false,
          level: 1,
        },
        {
          number: '6.c',
          practice: '.ID.GV.3',
          text:
            'We have identified regulatory requirements relating to cybersecurity that we are subject to, including privacy obligations.',
          exclusive: false,
          level: 2,
        },
        {
          number: '6.d',
          practice: '.ID.RA.6',
          text:
            'We identify action plans for mitigating, accepting, or transfering identified risks.',
          exclusive: false,
          level: 2,
        },
        {
          number: '6.e',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '6',
      title: 'Risk Management',
      text: [
        'Cybersecurity risk is the exposure to danger, harm, or loss related to the use of or dependence on computers, electronic data, or electronic communications (including the Internet). Typically it involves unauthorized access or unauthorized use of information or assets.',
        'Identification of risks should consider external sources such as supply chain risk, which is an increasing concern for most organizations. Supply chain refers to how assets, systems, software, services, and materials are procured.',
        'Once risks are identified, the organization should decide how to respond to those risks. Risk mitigation involves taking active steps to minimize a risk. Risk acceptance involves making a decision not to invest resources to mitigate the risk. Risk transfer is the contractual shifting of a risk from one party to another through a contract, such as through an insurance policy, a liability waiver with a client, or an indemnification agreement with a supplier.',
      ],
      prompt:
        'Please select all the responses that best describe your current risk management. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '7.a',
          practice: '.ID.RA.1',
          text:
            'We gather information on vulnerabilities and review it for applicability to our assets and systems.',
          exclusive: false,
          level: 2,
        },
        {
          number: '7.b',
          practice: '.ID.RA.2',
          text:
            'We gather threat information from various sources (e.g., industry associations, federal briefings, and threat information providers).',
          exclusive: false,
          level: 2,
        },
        {
          number: '7.c',
          practice: '.ID.RA.3',
          text: 'We identify internal and external threats.',
          exclusive: false,
          level: 2,
        },
        {
          number: '7.d',
          practice: '.ID.RA.4',
          text:
            'We identify potential business impacts of cybersecurity threats.',
          exclusive: false,
          level: 1,
        },
        {
          number: '7.e',
          practice: '.DE.CM.8',
          text: 'We perform vulnerability scanning.',
          exclusive: false,
          level: 2,
        },
        {
          number: '7.f',
          practice: '.RS.AN.5',
          text:
            'We receive, analyze, and respond to vulnerabilities disclosed to the organization from internal and external sources.',
          exclusive: false,
          level: 2,
        },
        {
          number: '7.g',
          practice: '.RS.MI.3',
          text:
            'We mitigate newly identified vulnerabilities or document them as accepted risks.',
          exclusive: false,
          level: 3,
        },
        {
          number: '7.h',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '7',
      title: 'Vulnerability and Threat Management',
      text: [
        'Cybersecurity vulnerabilities are weaknesses or flaws in assets that can be exploited by adversaries. Managing these vulnerabilities is an important security activity. The most common vulnerability management techniques include scanning for known vulnerabilities, patching significant vulnerabilities, and applying mitigating controls.',
        'A threat is any actor, circumstance, or event with the potential to adversely affect operations. Threats can be either internal to the organization such as an employee misusing privileges or external such as a ransomware attack. A common way to describe the relationship between threats and vulnerabilities is that threats (such as hackers) exploit vulnerabilities (such as system weaknesses) to attack organizations. Threat management activities include being aware of threats that are focusing on your sector, your region, or specific types of assets that you have as well as understanding the potential impact of those threats.',
      ],
      prompt:
        'Please select all the responses that best describe your current vulnerability and threat management. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
    {
      responses: [
        {
          number: '8.a',
          practice: '.ID.AM.6',
          text:
            'We have identified key cybersecurity roles and responsibilities needed for our organization.\n',
          exclusive: false,
          level: 1,
        },
        {
          number: '8.b',
          practice: '.PR.AT.1',
          text: 'We provide awareness and training for users.',
          exclusive: false,
          level: 1,
        },
        {
          number: '8.c',
          practice: '.PR.AT.2',
          text:
            'Personnel who are granted administrative or other privileged access understand their roles and responsibilities.',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.d',
          practice: '.PR.AT.3',
          text:
            'Critical third-party stakeholders (e.g., suppliers) understand their roles and responsibilities.',
          exclusive: false,
          level: 1,
        },
        {
          number: '8.e',
          practice: '.PR.AT.5',
          text:
            'Physical security and cybersecurity personnel understand their roles and responsibilities.',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.f',
          practice: '.PR.IP.11',
          text:
            'We address cybersecurity in our human resources practices (e.g., deprovisioning, personnel screening).',
          exclusive: false,
          level: 2,
        },
        {
          number: '8.g',
          practice: '.RS.CO.1',
          text:
            'We assign cybersecurity event response roles and activities to personnel.',
          exclusive: false,
          level: 3,
        },
        {
          number: '8.h',
          practice: undefined,
          text: 'None of the above.',
          exclusive: true,
          level: 0,
        },
      ],
      number: '8',
      title: 'Workforce Management',
      text: [
        'Despite all the technical discussions surrounding cybersecurity, employees and contractors represent some of the biggest risks to a security program. How workforce and cybersecurity training are managed will have a large impact on organizational security. ',
        'Clearly assigning cybersecurity responsibilities to roles establishes expectations for the tasks that personnel in those roles will perform. These roles may be explicitly cybersecurity-focused (network administrator, help hesk, CISO, etc.) or may be other roles that contribute to cybersecurity activities, including roles filled by third-party stakeholders.',
      ],
      prompt:
        'Please select all the responses that best describe your current workforce management. Keep in mind that the activities may be performed in an ad hoc manner.',
    },
  ],
};
