import { LegacyModelIdEnum, ModelScope } from 'common/graphql/graphql-hooks';

enum AssessmentTypes {
  ALLIANT = 'ALLIANT FULL',
  C2M2_FULL = 'C2M2 FULL',
  C2M2_QUICKLAUNCH = 'C2M2 QUICKLAUNCH',
  C2M2V2_FULL = 'C2M2V2 FULL',
  C2M2V2V1_FULL = 'C2M2V2V1 FULL',
  C2M2V2V1_QUICKLAUNCH = 'C2M2V2V1 QUICKLAUNCH',
  CIS18_FULL = 'CIS Controls',
  CISPLUS_FULL = 'CIS+',
  CMMC_FULL = 'CMMC FULL',
  CMMC_MIL1 = 'CMMC Level 1',
  CMMC_MIL2 = 'CMMC Level 2',
  CMMC_MIL3 = 'CMMC Level 3',
  CMMC_MIL4 = 'CMMC Level 4',
  CMMC_MIL5 = 'CMMC Level 5',
  CMMCV2_FULL = 'CMMCV2 FULL',
  CMMCV2_MIL1 = 'CMMCV2 Level 1',
  CMMCV2_MIL2 = 'CMMCV2 Level 2',
  CMMICSF_FULL = 'CMMICSF FULL',
  CRI_FULL = 'CRI FULL',
  CRI_TIER1 = 'CRI Tier 1',
  CRI_TIER2 = 'CRI Tier 2',
  CRI_TIER3 = 'CRI Tier 3',
  CRI_TIER4 = 'CRI Tier 4',
  CSF_FULL = 'CSF FULL',
  CSF_QUICKLAUNCH = 'CSF QUICKLAUNCH',
  CSF_GUIDEPOINT_FULL = 'CSF GUIDEPOINT FULL',
  CSSA_FULL = 'COS-SA FULL',
  EHR_VIEWER = 'EHR VIEWER',
  EHRCSF_FULL = 'EHRCSF FULL',
  ENTERGYCSF_FULL = 'ENTERGYCSF FULL',
  ESEMSTANDARDS_FULL = 'ESEMSTANDARDS FULL',
  EXCEL = 'C2M2 FULL Excel',
  FFIEC1 = 'FFIEC1 FULL',
  FHBCSF_FULL = 'FHBCSF FULL',
  ISO27001 = 'ISO27001 FULL',
  RC3_FULL = 'RC3 FULL',
  RPA_FULL = 'RPA FULL',
  NISTPP_FULL = 'NISTPP_FULL',
  NIST2CMMI_FULL = 'NIST2CMMI FULL',
  NIST2FILIPINI_FULL = 'NIST2FILIPINI FULL',
  NIST2OH_FULL = 'NIST2OH FULL',
}

const modelAndScopeToTypeMatrix = {
  [LegacyModelIdEnum.C2M2]: {
    [ModelScope.Full]: AssessmentTypes.C2M2_FULL,
    [ModelScope.Quicklaunch]: AssessmentTypes.C2M2_QUICKLAUNCH,
  },
  [LegacyModelIdEnum.C2M2V2]: {
    [ModelScope.Full]: AssessmentTypes.C2M2V2_FULL,
  },
  [LegacyModelIdEnum.C2M2V2V1]: {
    [ModelScope.Full]: AssessmentTypes.C2M2V2V1_FULL,
    [ModelScope.Quicklaunch]: AssessmentTypes.C2M2V2V1_QUICKLAUNCH,
  },
  [LegacyModelIdEnum.Cssa]: {
    [ModelScope.Full]: AssessmentTypes.CSSA_FULL,
  },
  [LegacyModelIdEnum.Cis18]: {
    [ModelScope.Full]: AssessmentTypes.CIS18_FULL,
  },
  [LegacyModelIdEnum.Cisplus]: {
    [ModelScope.Full]: AssessmentTypes.CISPLUS_FULL,
  },
  [LegacyModelIdEnum.Cmmc]: {
    [ModelScope.Full]: AssessmentTypes.CMMC_FULL,
    [ModelScope.Mil1]: AssessmentTypes.CMMC_MIL1,
    [ModelScope.Mil2]: AssessmentTypes.CMMC_MIL2,
    [ModelScope.Mil3]: AssessmentTypes.CMMC_MIL3,
    [ModelScope.Mil4]: AssessmentTypes.CMMC_MIL4,
    [ModelScope.Mil5]: AssessmentTypes.CMMC_MIL5,
  },
  [LegacyModelIdEnum.Cmmcv2]: {
    [ModelScope.Full]: AssessmentTypes.CMMCV2_FULL,
    [ModelScope.Mil1]: AssessmentTypes.CMMCV2_MIL1,
    [ModelScope.Mil2]: AssessmentTypes.CMMCV2_MIL2,
  },
  [LegacyModelIdEnum.Cmmicsf]: {
    [ModelScope.Full]: AssessmentTypes.CMMICSF_FULL,
  },
  [LegacyModelIdEnum.Csf]: {
    [ModelScope.Full]: AssessmentTypes.CSF_FULL,
    [ModelScope.Quicklaunch]: AssessmentTypes.CSF_QUICKLAUNCH,
  },
  [LegacyModelIdEnum.Csfguidepoint]: {
    [ModelScope.Full]: AssessmentTypes.CSF_GUIDEPOINT_FULL,
  },
  [LegacyModelIdEnum.Ehrcsf]: {
    [ModelScope.Full]: AssessmentTypes.EHRCSF_FULL,
  },
  [LegacyModelIdEnum.Entergycsf]: {
    [ModelScope.Full]: AssessmentTypes.ENTERGYCSF_FULL,
  },
  [LegacyModelIdEnum.Ffiec1]: {
    [ModelScope.Full]: AssessmentTypes.FFIEC1,
  },
  [LegacyModelIdEnum.Iso27001]: {
    [ModelScope.Full]: AssessmentTypes.ISO27001,
  },
  [LegacyModelIdEnum.Alliant]: {
    [ModelScope.Full]: AssessmentTypes.ALLIANT,
  },
  [LegacyModelIdEnum.Rc3]: {
    [ModelScope.Full]: AssessmentTypes.RC3_FULL,
  },
  [LegacyModelIdEnum.Rpa]: {
    [ModelScope.Full]: AssessmentTypes.RPA_FULL,
  },
  [LegacyModelIdEnum.Cri]: {
    [ModelScope.Full]: AssessmentTypes.CRI_FULL,
    [ModelScope.Tier1]: AssessmentTypes.CRI_TIER1,
    [ModelScope.Tier2]: AssessmentTypes.CRI_TIER2,
    [ModelScope.Tier3]: AssessmentTypes.CRI_TIER3,
    [ModelScope.Tier4]: AssessmentTypes.CRI_TIER4,
  },
  [LegacyModelIdEnum.Fhbcsf]: {
    [ModelScope.Full]: AssessmentTypes.FHBCSF_FULL,
  },
  [LegacyModelIdEnum.Ehrviewer]: {
    [ModelScope.Full]: AssessmentTypes.EHR_VIEWER,
  },
  [LegacyModelIdEnum.Esemstandards]: {
    [ModelScope.Full]: AssessmentTypes.ESEMSTANDARDS_FULL,
  },
  [LegacyModelIdEnum.Nistpp]: {
    [ModelScope.Full]: AssessmentTypes.NISTPP_FULL,
  },
  [LegacyModelIdEnum.Nist2Cmmi]: {
    [ModelScope.Full]: AssessmentTypes.NIST2CMMI_FULL,
  },
  [LegacyModelIdEnum.Nist2Filipini]: {
    [ModelScope.Full]: AssessmentTypes.NIST2FILIPINI_FULL,
  },
  [LegacyModelIdEnum.Nist2Oh]: {
    [ModelScope.Full]: AssessmentTypes.NIST2OH_FULL,
  },
};

export const getReportMapping = (props: {
  model?: string;
  modelScope?: ModelScope;
}): AssessmentTypes => {
  const model = props.model || LegacyModelIdEnum.C2M2;
  const modelScope = props.modelScope || ModelScope.Full;

  return modelAndScopeToTypeMatrix?.[model]?.[modelScope];
};
