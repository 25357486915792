export default {
  AES: 'Advanced encryption standard',
  AMI: 'Advanced metering infrastructure',
  AMR: 'Automatic meter reading',
  CEO: 'Chief executive officer',
  CFO: 'Chief financial officer',
  DCS: 'Distributed control system',
  DES: 'Data encryption standard',
  DET: 'Detect',
  DMZ: 'Demilitarized zone ',
  DOE: 'Department of Energy',
  'E-ISAC': 'Electricity Information Sharing and Analysis Center',
  FAN: 'Field Area Network',
  GM: 'General manager',
  HR: 'Human resources',
  HTTPS: 'Hyper Text Transfer Protocol Secure',
  IaaS: 'Infrastructure as a service',
  HVAC: 'Heating, ventilation, and air conditioning',
  ICS: 'Industrial control system',
  'ICS-CERT': 'Industrial Control Systems Cyber Emergency Response Team',
  IDE: 'Identify',
  IDS: 'Intrusion detection system',
  IT: 'Information technology',
  LAN: 'Local area network',
  MAC: 'Media access control',
  MDM: 'Meter data management',
  OMS: 'Outage management system',
  NIST: 'National Institute of Standards and Technology ',
  NRECA: 'National Rural Electric Cooperative Association',
  OT: 'Operational technology',
  PII: 'Personally identifiable information',
  PLC: 'Programmable logic controller',
  PIN: 'Personal identification number',
  RC3: 'Rural Cooperative Cybersecurity Capabilities',
  PRO: 'Protect',
  REC: 'Recover',
  RES: 'Respond',
  RTU: 'Remote terminal unit',
  SaaS: 'Software as a service',
  SCADA: 'Supervisory control and data acquisition',
  SSN: 'Social Security number',
  TLS: 'Transport layer security',
  WAN: 'Wide area network',
  WEP: 'Wired equivalent privacy',
  WPA: 'Wi-Fi protected access',
  ANSI: 'American National Standards Institute',
  CEA: 'Cybersecurity Enhancement Act of 2014',
  CIS: 'Center for Internet Security',
  COBIT: 'Control Objectives for Information and Related Technology',
  CPS: 'Cyber-Physical Systems',
  CSC: 'Critical Security Control',
  DHS: 'Department of Homeland Security',
  EO: 'Executive Order',
  IEC: 'International Electrotechnical Commission',
  IoT: 'Internet of Things',
  IR: 'Interagency Report',
  ISA: 'International Society of Automation',
  ISAC: 'Information Sharing and Analysis Center',
  ISAO: 'Information Sharing and Analysis Organization',
  ISO: 'International Organization for Standardization',
  RFI: 'Request for Information',
  RMP: 'Risk Management Process',
  SCRM: 'Supply Chain Risk Management',
  SP: 'Special Publication',
  Buyer: 'The people or organizations that consume a given product or service.',
  Category:
    'The subdivision of a Function into groups of cybersecurity outcomes, closely tied to programmatic needs and particular activities. Examples of Categories include “Asset Management,” “Identity Management and Access Control,” and “Detection Processes.”',
  'Critical Infrastructure':
    'Systems and assets, whether physical or virtual, so vital to the United States that the incapacity or destruction of such systems and assets would have a debilitating impact on cybersecurity, national economic security, national public health or safety, or any combination of those matters.',
  Cybersecurity:
    'The process of protecting information by preventing, detecting, and responding to attacks.',
  'Cybersecurity Event':
    'A cybersecurity change that may have an impact on organizational operations (including mission, capabilities, or reputation).',
  'Cybersecurity Incident':
    'A cybersecurity event that has been determined to have an impact on the organization prompting the need for response and recovery.',
  'Detect (function)':
    'Develop and implement the appropriate activities to identify the occurrence of a cybersecurity event.',
  Framework:
    'A risk-based approach to reducing cybersecurity risk composed of three parts: the Framework Core, the Framework Profile, and the Framework Implementation Tiers. Also known as the “Cybersecurity Framework.”',
  'Framework Core':
    'A set of cybersecurity activities and references that are common across critical infrastructure sectors and are organized around particular outcomes. The Framework Core comprises four types of elements: Functions, Categories, Subcategories, and Informative References.',
  'Framework Implementation Tier':
    'A lens through which to view the characteristics of an organization’s approach to risk—how an organization views cybersecurity risk and the processes in place to manage that risk.',
  'Framework Profile':
    'A representation of the outcomes that a particular system or organization has selected from the Framework Categories and Subcategories.',
  Function:
    'One of the main components of the Framework. Functions provide the highest level of structure for organizing basic cybersecurity activities into Categories and Subcategories. The five functions are Identify, Protect, Detect, Respond, and Recover.',
  'Identify (function)':
    'Develop the organizational understanding to manage cybersecurity risk to systems, assets, data, and capabilities.',
  'Informative Reference':
    'A specific section of standards, guidelines, and practices common among critical infrastructure sectors that illustrates a method to achieve the outcomes associated with each Subcategory. An example of an Informative Reference is ISO/IEC 27001 Control A.10.8.3, which supports the “Data-in-transit is protected” Subcategory of the “Data Security” Category in the “Protect” function.',
  'Mobile Code':
    'A program (e.g., script, macro, or other portable instruction) that can be shipped unchanged to a heterogeneous collection of platforms and executed with identical semantics.',
  'Protect (function)':
    'Develop and implement the appropriate safeguards to ensure delivery of critical infrastructure services.',
  'Privileged User':
    'A user that is authorized (and, therefore, trusted) to perform security-relevant functions that ordinary users are not authorized to perform.',
  'Recover (function)':
    'Develop and implement the appropriate activities to maintain plans for resilience and to restore any capabilities or services that were impaired due to a cybersecurity event.',
  'Respond (function)':
    'Develop and implement the appropriate activities to take action regarding a detected cybersecurity event.',
  Risk:
    'A measure of the extent to which an entity is threatened by a potential circumstance or event, and typically a function of: (i) the adverse impacts that would arise if the circumstance or event occurs; and (ii) the likelihood of occurrence.',
  'Risk Management':
    'The process of identifying, assessing, and responding to risk.',
  Subcategory:
    'The subdivision of a Category into specific outcomes of technical and/or management activities. Examples of Subcategories include “External information systems are catalogued,” “Data-at-rest is protected,” and “Notifications from detection systems are investigated.”',
  Supplier:
    'Product and service providers used for an organization’s internal purposes (e.g., IT infrastructure) or integrated into the products of services provided to that organization’s Buyers.',
  Taxonomy: 'A scheme of classification.',
};
