/**
 * Manages a map of terms and their definitions.  It also supports the functions necessary for noticing
 * terms exist in a string of text and wrapping them in an indicator.  Another method converts text
 * wrapped in an indicator into a link for displaying the term.
 *
 */
import { spliceString } from '../string';
import { Trie } from './Trie';

/**
 * A class for managing a list of terms and their definitions.  It supports loading json that contains
 * the terms, parsing a string to look for those terms - returning delimiters as necesary and
 * generating an array of html (text and terms wrapped in a component) based on the terms
 *
 * @constructor Dictionary
 * @param  {string|Object}  The path to a json file containing the defined terms or the json object itself
 * @return {Object}  The constructed object
 */
export class Dictionary {
  static annotate(argDict: Dictionary | Dictionary[], text: string) {
    const dicts = Array.isArray(argDict) ? argDict : [argDict];
    return dicts.reduce(
      (accum: string, dict: Dictionary) => dict.doWrap(accum),
      text
    );
  }

  tree: Trie;
  name: string;
  dictionary: { [term: string]: string };
  preservedCaseMap: { [term: string]: string };

  constructor(name: string, dictionary: { [term: string]: string }) {
    this.name = name;
    this.dictionary = dictionary;
    this.tree = this.processDictionary(dictionary);
    // map -- so we can match on lowercase and then use preserved case in lookup
    this.preservedCaseMap = Object.keys(dictionary).reduce((map, x) => {
      map[x.toLowerCase()] = x;
      return map;
    }, {});
  }

  /** Returns a term definition from the dictionary
   *
   * @param term: string
   *
   * @return string - The definition
   */
  lookup(term?: string): string {
    if (!term) {
      return 'no term specified';
    }

    const definition = this.dictionary[term];
    return definition ? definition : 'no defintion available';
  }

  processDictionary(dict: object) {
    const keys = Object.keys(dict);
    const lowerkeys = keys.map((x) => x.toLowerCase());
    return new Trie(lowerkeys);
  }

  /**
   * Searches a piece of text for strings that match our definitions and wraps them in
   * indicators.  The idea is that a later step could look for pieces of text wrapped in
   * indicators and then replace it with a link to the defintion.
   *
   * @param text: {string} - The text to search for terms in
   * @param pfx: {string} - The beginning character or characters of the wrapping
   * @param sfx: {string} - The ending character or characters of the wrapping
   *
   * @returns {string} - The original text with terms wrapped in pfx and sfx
   */
  doWrap(text: string, pfx = '[', sfx = ']'): string {
    const matches = this.getMatches(text);

    // splice in the markdown links and update delta of position of subsequent inserts
    const annotatedText = matches.reduce(
      ([textAccum, delta], match) => {
        const originalMatchText = textAccum.slice(
          match.position + delta,
          match.position + delta + match.text.length
        );
        const preservedCaseTerm = this.preservedCaseMap[match.text];

        // special case for all upper case terms -- must match case exactly (e.g. IT should not match it)
        if (
          preservedCaseTerm === preservedCaseTerm.toUpperCase() &&
          originalMatchText !== preservedCaseTerm
        ) {
          return [textAccum, delta] as [string, number];
        }

        const subst = `[${originalMatchText}](${encodeURI(
          this.name + '/' + preservedCaseTerm
        )})`;

        const newDelta = delta + subst.length - match.text.length;

        return [
          spliceString(
            textAccum,
            match.position + delta,
            match.text.length,
            subst
          ),
          newDelta,
        ] as [string, number];
      },
      [text, 0] as [string, number]
    )[0];

    return annotatedText;
  }

  getMatches(text: string) {
    if (!text) return [];

    const matches = [] as Array<{ text: string; position: number }>;
    // Starting from the current word, we keep looking for longer and longer strings until we stop
    // finding them.  Then, if there's a definition, we wrap.  If there isn't, we move on to the next
    // word and continue.
    const len = text.length;

    const lowercaseText = text.toLowerCase();

    const phrase = lowercaseText;

    let pos = 0;
    while (pos < len) {
      const restOfPhrase = phrase.slice(pos, len);
      const match = this.tree.search(restOfPhrase);
      if (!match) {
        // if we hit a link, it's an already-linked practice and should be skipped
        if (restOfPhrase.startsWith('[')) {
          const newSlice = restOfPhrase.slice(1, len);
          const endBrackets = newSlice.search(/]/);
          const openParens = endBrackets + 2;
          if (endBrackets !== -1 && restOfPhrase.charAt(openParens) === '(') {
            // make sure this is a link and not just brackets
            const parenSlice = restOfPhrase.slice(openParens, len);
            const endParens = parenSlice.search(/\)/);
            if (endParens !== -1) {
              pos += openParens + endParens + 3;
              continue;
            }
          }
        } // no else - if any of this fails we just keep going

        // if we're on an alpha skip to the next non alpha
        if (String(restOfPhrase.charAt(0)).search(/[^a-zA-Z]+/) === -1) {
          // advance to next word (non A-Z)
          const slice2 = restOfPhrase.slice(1, len); // shift would be faster
          const nextNonAlpha = slice2.search(/[^a-zA-Z]+/);
          if (nextNonAlpha === -1) {
            pos = len;
            break;
          }
          pos += nextNonAlpha + 2; // +1 for next char +1 to skip over non-alpha we found
          continue;
        } else {
          // were still in non-alpha land just advance by 1
          pos++;
          continue;
        }
      }

      matches.push({ text: match, position: pos });

      pos += match.length;
    }

    return matches;
  }
}
