export const marginConst = 8;
export const unTargetedId = '.unTargetedId';
export const milestonePieSize = 140;
export const columnWidth = 330;
export const cardWidth = 320;
export const assessmentListWidth = 280;
export const navBarSize = 50;
export const paddingForCalcs = 10;
export const notImplemented = 'Not Implemented';
export const notYetTargeted = 'Not Yet Targeted';
