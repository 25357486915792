import { datadogLogs } from '@datadog/browser-logs';
import { IAxioWindow } from 'common/utils/AxioUtilities'; //circular dependency?

let env;
let initializeDD = '';
let clientToken = '';
// //NOTE: if running in node we either need to catch this reference error
// //or configure webpack to make it {}.  Choosing the quicker option for now.
// //Our node code is just build tools.
try {
  const w = window as Window & IAxioWindow;
  initializeDD = (w && w.c2m2_env && w.c2m2_env.ENABLE_DATADOG) || '';
  env = (w && w.c2m2_env && w.c2m2_env.C2M2_ENV) || '';
  clientToken = (w && w.c2m2_env && w.c2m2_env.DATADOG_CLIENT_TOKEN) || '';

  /* eslint-disable no-console */
  console.log('env', env);
  /* eslint-disable no-console */
  console.log('initializeDD', initializeDD);
} catch (e) {
  if (e instanceof ReferenceError) {
    // eating referenceError for window.  This is normal in node (e.g. cooker).
    datadogLogs.logger.error('reference error', { name: 'error', e });
  }
}

export const initDatadog = () => {
  if (initializeDD) {
    datadogLogs.init({
      clientToken,
      site: 'us5.datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: `axio-legacy-client-${env}`,
      env,
      forwardConsoleLogs: ['debug', 'info', 'warn', 'error'],
      forwardReports: 'all',
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Warning: Datadog logging is off (no DATADOG_LOGGING configured). This is normal on local.'
    );
  }
};

datadogLogs.createLogger('setUser', {
  level: 'info',
  handler: 'http',
  context: { env: env || 'local' },
});

const logInfo = (message: string, additionalData?: any) => {
  if (initializeDD) {
    datadogLogs.logger.log(message, { ...additionalData }, 'info');
  }
  // eslint-disable-next-line no-console
  console.info(message, additionalData);
};

const logWarning = (message: string, additionalData?: any) => {
  if (initializeDD) {
    datadogLogs.logger.warn(
      'warning',
      { ...additionalData },
      { name: 'warn', message }
    );
  }
  // eslint-disable-next-line no-console
  console.warn(message, additionalData);
};

const logError = (message: string, additionalData?: any) => {
  if (initializeDD) {
    datadogLogs.logger.error(
      'error',
      { ...additionalData },
      { name: 'error', message }
    );
  }
  // eslint-disable-next-line no-console
  console.error(message, additionalData);
};

const setUserContext = (userId: string) => {
  const setUserContextLogger = datadogLogs.getLogger('setUser');
  if (initializeDD && setUserContextLogger) {
    setUserContextLogger.info(JSON.stringify({ id: userId }));
  }
};

const logException = (exception: Error, additionalData?: any) => {
  if (initializeDD) {
    datadogLogs.logger.error('error', { ...additionalData }, exception);
  }
};

const Logger = {
  init: initDatadog,
  info: logInfo,
  warn: logWarning,
  error: logError,
  exception: logException,
  setUserContext: setUserContext,
};

export default Logger;
