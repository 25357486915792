import React, { Suspense } from 'react';

import { LoadingScreenRaw } from 'common/components/Loading/LoadingScreen';
import { UpsellQuickQuant } from './UpsellQuickQuant';
import Logger from 'common/utils/Logger';
import { useCurrentUserQuickQuantLicenseQuery } from 'common/graphql/graphql-hooks';

const QuickQuant = React.lazy(
  () =>
    import(
      /* webpackChunkName: "QuickQuant", webpackPrefetch: true */ './views'
    )
);

const GuardQuickQuant = React.memo(() => {
  const { data, loading, error } = useCurrentUserQuickQuantLicenseQuery({
    variables: { withQuickQuants: true },
  });
  if (!data?.me) {
    if (loading) {
      return <LoadingScreenRaw isLoading />;
    }
    Logger.error('Missing data', {
      contexts: { location: { function: 'QuickQuant', error } },
    });
    return null;
  }

  const hasQuickQuantLicense = data?.me?.hasQuickQuantAccess;

  const activeQuickQuantId = data.me.activeQuickQuant?.id;
  const firstQuickQuantId = data.quickQuants?.[0]?.id;

  const matchedQuickQuant = data.quickQuants?.find(
    (qq) => qq && qq.id === activeQuickQuantId
  )?.id;

  // Admins can access any quick quant by `id` despite it not being in the quickQuants list
  // To handle this edge case we will always set the selectedQuickQuantId to null if the id
  // does not appear in the quick quants list (which populates the scrollable list in the edit view)
  // Alternatively, we choose to allow admins to pull all quickQuants into the list.
  const selectedQuickQuantId = matchedQuickQuant || firstQuickQuantId || null;

  return (
    <Suspense fallback={<LoadingScreenRaw isLoading />}>
      {hasQuickQuantLicense ? (
        <QuickQuant selectedQuickQuantId={selectedQuickQuantId} />
      ) : (
        <UpsellQuickQuant />
      )}
    </Suspense>
  );
});

export default GuardQuickQuant;
