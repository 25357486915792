// This file holds the theme elements that are common across all modules.
import { Colors } from '@blueprintjs/core';
import {
  AXIO_EMPHASIS_BLUE,
  AXIO_LOGO_BLUE,
  GRAY_TEXT,
  ICON_BUTTON_COLOR,
  ICON_BUTTON_HOVER_COLOR,
  PRIMARY_BLUE,
} from 'common/constants';
import clientLogo from './logo-axio360.svg';
import newClientLogo from './Axio360.svg';
import deprecationMsgLogo from './logo_dep.svg';

import axioSquaresPattern from './axio-squares-pattern.png';

import {
  StatusEmphasis as statusEmphasis,
  StatusFeedback as statusFeedback,
} from '../../constants';

export const textSizes = {
  hero: 32,
  tOne: 24,
  tTwo: 16,
  subTitle: 16,
  paragraph: 13,
  inputOne: 16,
  inputTwo: 14,
  buttonOne: 16,
  buttonTwo: 13,
  linkOne: 16,
  linkTwo: 13,
};

export const textColors = {
  hero: '#002277',
  tOne: '#002277',
  tTwo: '#002277',
  subTitle: '#294936',
  paragraph: '#383F51',
  inputOne: '#383F51',
  inputTwo: '#383F51',
  buttonOne: '#FFFFFF',
  buttonTwo: '#FFFFFF',
  linkOne: '#2766B5',
  linkTwo: '#2766B5',
};

// can be dropped in a styled component in one line
// ${props => props.theme.styleStrings.hero}
// would be a nice way to seed a default set of components
// const HeroSpan = styled.span`
//   ${props => props.theme.styleStrings.hero}
// `;
const styleStrings = {
  hero: `font-size: ${textSizes.hero}px; font-weight: bold; color: ${textColors.hero}`,
  tOne: `font-size: ${textSizes.tOne}px; font-weight: bold; color: ${textColors.tOne}`,
  tTwo: `font-size: ${textSizes.tTwo}px; font-weight: bold; color: ${textColors.tTwo}`,
  subTitle: `font-size: ${textSizes.subTitle}px; color: ${textColors.subTitle}`,
  paragraph: `font-size: ${textSizes.paragraph}px; color: ${textColors.paragraph}`,
  inputOne: `font-size: ${textSizes.inputOne}px; color: ${textColors.inputOne}`,
  inputTwo: `font-size: ${textSizes.inputTwo}px; color: ${textColors.inputTwo}`,
  buttonOne: `font-size: ${textSizes.buttonOne}px; color: ${textColors.buttonOne}`,
  buttonTwo: `font-size: ${textSizes.buttonTwo}px; color: ${textColors.buttonTwo}`,
  linkOne: `font-style: italic; font-size: ${textSizes.linkOne}px; color: ${textColors.linkOne}`,
  linkTwo: `font-style: italic; font-size: ${textSizes.linkTwo}px; color: ${textColors.linkTwo}`,
};

export const defaultTheme = {
  title: 'Axio360',
  name: 'default',
  poweredBy: false,
  supportLink: '//support.axio.com',
  homepage: 'https://axio.com',
  clientName: 'Axio',
  clientNameLong: 'Axio Global, Inc.',
  authPoweredByLogo: clientLogo,
  poweredByLogo: newClientLogo,
  clientLogo,
  logoHeight: 35,
  authLogo: newClientLogo,
  authWelcomeText: 'Welcome',
  authWelcomeTextStyles: {
    color: 'white',
    fontFamily: 'GTAmerica',
    fontSize: '4.2vw',
    marginRight: '150px',
    paddingTop: '1.5em',
    textAlign: 'center',
  },
  deprecationMsgLogo,
  backgroundImage: axioSquaresPattern,
  backgroundAnchor: 'center',
  authPanelDarken: 0.2,
  authPanelTransparency: 0.3,
  eulaLink: '//axio.com/axio-license-agreement',
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  font: 'Lato, arial, system-ui, sans-serif',
  spacing: 8,
  colors: {
    errorRed: '#E60202',
    primaryButton: PRIMARY_BLUE,
    primaryButtonText: 'white',
    lightGray: '#f1f1f1',
    darkGray: '#707070',
    emphasisColor: AXIO_EMPHASIS_BLUE,
    buttonColor: ICON_BUTTON_COLOR,
    buttonHoverColor: ICON_BUTTON_HOVER_COLOR,
    axioLogoBlue: AXIO_LOGO_BLUE,
    grayText: GRAY_TEXT,
    authBackground: PRIMARY_BLUE,
    textColors,
    statusFeedback,
    statusPrimary: statusEmphasis.PRIMARY,
    statusBad: statusEmphasis.BAD,
    statusGood: statusEmphasis.SUCCESS,
    statusWarning: statusEmphasis.WARNING,
    //matches blueprint disabled styles
    disabledGrayBackground: 'rgba(206, 217, 224, 0.5)',
    disabledGrayTextColor: 'rgba(92, 112, 128, 0.6)',
    minimalButtonTextColor: PRIMARY_BLUE,
  },
  textSizes,
  styleStrings,
  salesEmail: 'sales@axio.com',
};

export const dataVisualizationColorSet = [
  Colors.TURQUOISE1,
  Colors.VIOLET2,
  Colors.ROSE3,
  Colors.INDIGO4,
  Colors.GOLD5,
  Colors.LIME1,
  Colors.SEPIA2,
  Colors.VERMILION3,
  Colors.ORANGE4,
  Colors.COBALT5,
];

export const pastelColorScheme = [
  '#66C5CC',
  '#F6CF71',
  '#F89C74',
  '#DCB0F2',
  '#87C55F',
  '#9EB9F3',
  '#FE88B1',
  '#C9DB74',
  '#8BE0A4',
  '#B497E7',
  '#D3B484',
  '#B3B3B3',
];
