import { Map } from 'immutable';
import { groupBy as _groupBy } from 'lodash';
import { Reducer } from 'redux';

import { IJournalEntry } from 'assessments/databaseTypes';
import { ASSESSMENT_ID_FIELD } from '../constants';

type AssessmentId = string;

type IJournalData =
  | {
      loaded: false;
      data: undefined;
    }
  | {
      loaded: true;
      data: IJournalEntry[];
    };
export type IState = Map<AssessmentId, IJournalData>;

const initialState: IState = Map({});

export const journalReducer: Reducer<IState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return initialState;
    }
    case 'get_resource/journal':
      return state.withMutations((mutableState) => {
        // action.response[ASSESSMENT_ID_FIELD] is a string for an single assessment,
        // or an array of strings if multiple assessments of responses are returned
        // We want to store an empty map if the assessment has no responses yet to know that we have tried to fetch
        const assessmentIds = [].concat(action.response[ASSESSMENT_ID_FIELD]);
        assessmentIds.forEach((assessmentId) => {
          const existing = mutableState.get(assessmentId, {
            loaded: false as const,
          });
          const journalEntries = _groupBy(
            action.response.result._items,
            (item) => item[ASSESSMENT_ID_FIELD]
          );
          const newValue = {
            ...existing,
            loaded: true as const,
            data: (journalEntries[assessmentId] as IJournalEntry[]) || [],
          };

          mutableState.set(assessmentId, newValue);
        });
      });
    default:
      break;
  }

  return state;
};
