import { Reducer } from 'redux';

export enum ReportingServer {
  HTML = 'html-reporting',
  REPORTING = 'reporting',
}

export enum ReportingState {
  STARTED = 'started',
  COMPLETE = 'complete',
  FAILED = 'failed',
  CANCELLED = 'canceled',
  CLOSED = 'closed',
}

export enum ReportType {
  C2M2_FULL = 'c2m2-full',
  C2M2V2_FULL = 'c2m2v2-full',
  C2M2V2V1_FULL = 'c2m2v2v1-full',
  C2M2V2V1_QL = 'c2m2v2v1-quicklaunch',
  CSF_FULL = 'csf-full',
  RC3_FULL = 'rc3-full',
  RPA_FULL = 'rpa-full',
  CSSA_FULL = 'cssa-full',
  C2M2_QL = 'c2m2-quicklaunch',
  C2M2_COMPARISON = 'c2m2-comparison',
  CMMC_FULL = 'cmmc-full',
  CMMCV2_FULL = 'cmmcv2-full',
  CMMICSF_FULL = 'cmmicsf-full',
}

export interface IReportingState {
  state: ReportingState;
  percentComplete: number;
  stageDescription: string;
  hash: string | null;
  reportType: ReportType | '';
  theme: string;
  error: string;
  url: string;
  reportServer: string;
}

const initialState = {
  state: ReportingState.CLOSED,
  percentComplete: 0,
  hash: null,
  reportType: '' as const,
  theme: '',
  stageDescription: '',
  error: '',
  url: '',
  reportServer: '',
};

export const reportingReducer: Reducer<IReportingState> = (
  state = initialState,
  action
) => {
  // Any change to the assessment invalidates our cached url, no matter what the action is.
  switch (action.type) {
    case 'COGNITO_LOGIN':
    case 'COGNITO_LOGOUT':
    case 'server/subscribe':
    case 'server/unsubscribe': {
      // OK, bye bye data.  It will get restored from localstorage if you
      // login as the same user.
      return initialState;
    }

    case 'REPORTING_FETCH_START':
      return {
        ...state,
        state: ReportingState.STARTED,
        percentComplete: 0,
        hash: action.hash,
        reportType: action.reportType,
        theme: action.theme,
        reportServer: action.server,
        error: '',
        url: '',
      };

    case 'REPORTING_FETCH_CANCELED':
      return { ...state, hash: '', state: ReportingState.CANCELLED };

    case 'REPORTING_FETCH_PENDING':
      return state; // Do nothing right now.  Just included for doc

    case 'REPORTING_FETCH_PROGRESS': {
      if (state.state !== ReportingState.STARTED) {
        // it's possible a status check may be on the wire when generation is cancelled
        // we don't want to re-open the dialog
        return state;
      }

      const json = action.json;
      const percentComplete = json.total
        ? Math.round((json.current / json.total) * 100)
        : 0;
      return {
        ...state,
        state: ReportingState.STARTED,
        percentComplete,
        stageDescription: json.status,
      };
    }
    case 'REPORTING_FETCH_FAIL':
      return {
        ...state,
        hash: '',
        state: ReportingState.FAILED,
        error: action.response.message,
      };

    case 'REPORTING_FETCH_COMPLETE':
      return {
        ...state,
        state: ReportingState.COMPLETE,
        percentComplete: 100,
        url: action.url,
      };

    case 'REPORTING_FETCH_DONE':
      return {
        ...state,
        state: ReportingState.CLOSED,
        percentComplete: 0,
      };

    default:
      break;
  }

  return state;
};
