import defaultTheme from './default';
import {
  LIMEROCK_BLACK,
  LIMEROCK_BROWN,
  LIMEROCK_GREY,
  LIMEROCK_ORANGE,
} from 'common/themes/limerock';
import { NAVBAR_WIDTH, TOC_WIDTH } from 'common/constants';

import limerockAuthBackground from 'common/themes/limerock/limerock_background.jpg';
import limerockTocPattern from 'common/themes/limerock/limerock_toc_pattern.jpg';
import { transparentize, desaturate } from 'polished';

const LOGO_WIDTH = TOC_WIDTH + NAVBAR_WIDTH + 5;

export default {
  ...defaultTheme,
  poweredBy: true,
  poweredByBackground: true,
  hasOwnIntroScreen: false,
  authBackground: limerockAuthBackground,
  logoWidth: LOGO_WIDTH + 'px',
  tocPattern: limerockTocPattern,
  colors: {
    ...defaultTheme.colors,
    headerBackground: 'transparent',
    headerText: LIMEROCK_BLACK,
    menuSubtext: 'lightgray',
    nameplate: LIMEROCK_BLACK,
    background: LIMEROCK_BLACK,
    qlBackground: LIMEROCK_BLACK,
    tocExpand: LIMEROCK_BROWN,
    primaryButton: LIMEROCK_ORANGE,
    primaryButtonText: LIMEROCK_BLACK,
    avatarColor: LIMEROCK_ORANGE,
    avatarTextColor: LIMEROCK_BLACK,
    avatarNameplateColor: LIMEROCK_BLACK,
    tocCollapseColor: LIMEROCK_ORANGE,
    qlAnswerHover: desaturate(0, transparentize(0.5, LIMEROCK_ORANGE)),
    qlAnswerText: LIMEROCK_BLACK,
    qlAnswerBackground: LIMEROCK_ORANGE,
    taglineColor: LIMEROCK_BROWN,
    tocBackgroundColor: LIMEROCK_GREY,
  },
  hasIntroTour: false,
};
