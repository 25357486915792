import { defaultTheme } from '../default';
import poweredByLogo from '../default/Axio360.svg';
import authPoweredByLogo from '../default/logo-axio360.svg';
import authBackground from './mls_auth_background.svg';
import authLogo from './mls_auth_logo.png';
import clientLogo from './mls_client_logo.svg';

export const MLS_BLUE = '#001F5B';

export const mlsTheme = {
  ...defaultTheme,
  title: 'Axio360',
  name: 'mls',
  poweredBy: true,
  tocPattern: defaultTheme.backgroundImage,
  homepage: 'https://mlssoccer.com/',
  clientName: 'MajorLeagueSoccer',
  clientNameLong: 'Major League Soccer',
  clientLogo: clientLogo,
  logoHeight: 43,
  authLogo: authLogo,
  authWelcomeTextStyles: {
    ...defaultTheme.authWelcomeTextStyles,
    marginTop: 0,
    paddingTop: 0,
  },
  poweredByLogo: poweredByLogo,
  authPoweredByLogo: authPoweredByLogo,
  twitterUsername: 'Axio',
  twitterScreenName: 'axio',
  backgroundImage: authBackground,
  backgroundAnchor: 'bottom left',
  authPanelDarken: 0.1,
  authPanelTransparency: 0.1,
  colors: {
    ...defaultTheme.colors,
    authBackground: MLS_BLUE,
  },
};
