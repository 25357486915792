import { Record, RecordOf } from 'immutable';
import { Reducer } from 'redux';
import { IUser, GranteeType } from '../databaseTypes';
import { networkErrorCodes } from 'common/actions/persistence';
import Logger from 'common/utils/Logger';

function userObjectFromRequest(state: any, argUser: IUser, etag: string) {
  return {
    ...argUser,
    isNewUser: false,
    _etag: etag,
  };
}

const mergeFn = (oldVal: Partial<IUser>, newVal: Partial<IUser>) =>
  typeof oldVal === 'object' && !!oldVal && !Array.isArray(oldVal)
    ? { ...oldVal, ...newVal }
    : newVal;

const userRecord = Record({
  email: '',
  name: '',
  displayName: '',
  title: '',
  department: '',
  phone: {
    number: '',
    ext: '',
  },
  isNewUser: false,
  employer: null,
  twoFactorAuthEnabled: false,
  roles: [],
  type: GranteeType.USER as const,
  bitsightAPILoading: false,
  bitsightKeyVerified: 0,
  bitsightServiceUnavailable: false,
  active_assessment: null,
  last_assessment_type: null,
  last_filter: '',
  last_sort: [],
  _id: '',
  _etag: '',
  _created: '',
  _updated: '',
  isDeleted: false,
  acknowledgedModelDeprecationMsgs: undefined,
  freemiumRestrictions: { isFreeUser: true },
});

// @ts-expect-error TS and Immutable not coercing types properly
export const userReducer: Reducer<RecordOf<IUser>> = (
  // @ts-expect-error TS and Immutable not coercing types properly
  argState = userRecord(),
  action
) => {
  switch (action.type) {
    case 'COGNITO_LOGIN': // Fall through
    case 'COGNITO_LOGOUT': {
      // OK, bye bye data.
      return userRecord();
    }
    case 'FILTER_CRITERIA':
      // Consider this an optimistic update.  Eventually,
      // the return from the server save will set this/reset it
      // In the mean time, we'll go ahead and update it now.
      return argState.set('last_filter', action.filterCriteria);

    case 'patch_resource/users': {
      const user = userObjectFromRequest(
        argState,
        {
          ...action.request.data,
        },
        action.response.result._etag
      );
      // @ts-expect-error Immutable expects unknowns in mergeFn parameters
      return argState.mergeWith(mergeFn, user);
    }
    case 'patch_resource/companies': {
      const userEmployer = argState.get('employer');

      if (!userEmployer) {
        return argState;
      }
      const hasBitsightKey = !!action.request.data.bitsightKey;
      const hasZendesk =
        !!action.request.data.zendeskEmail &&
        !!action.request.data.zendeskKey &&
        !!action.request.data.zendeskSubdomain;
      const hasServiceNow =
        !!action.request.data.serviceNowUsername &&
        !!action.request.data.serviceNowPassword &&
        !!action.request.data.serviceNowSubdomain;
      const updatedEmployerFields = {
        employer: {
          ...userEmployer,
          hasBitsightKey,
          hasZendesk,
          hasServiceNow,
          ...action.request.data,
          ...action.response.result,
        },
      };
      return argState.merge(updatedEmployerFields);
    }
    case 'get_resource/users': {
      // If the coworkers are present on the user object, drill it.  We use that in
      // other reducers.
      const fetchedUser = { ...action.response.result._items[0] };
      delete fetchedUser.coworkers;
      // @ts-expect-error Immutable expects unknowns in mergeFn parameters
      return argState.mergeWith(mergeFn, fetchedUser);
    }
    case 'error/post_resource/assessments': {
      if (action.error.code !== networkErrorCodes.UNPROCESSABLE_ENTITY) {
        break;
      }
      const data = JSON.parse(action.error.message);
      const employer = argState.get('employer');
      if (!employer) {
        break;
      }
      const singletons = (employer.singletonAssessments
        ? employer.singletonAssessments
        : []
      ).concat({
        _id: data._id,
        model: data.Type,
        name: data.AssessmentName,
        ownerId: data.OwnerId,
        ownerEmail: data.OwnerEmail,
        ownerName: data.OwnerName,
        __typename: 'SingletonAssessment',
      });
      return argState.setIn(['employer', 'singletonAssessments'], singletons);
    }
    case 'delete_resource/assessments': {
      const assessmentId = action.request._id;
      const employer = argState.get('employer');
      if (!employer) {
        break;
      }
      const singletons = employer.singletonAssessments
        ? employer.singletonAssessments
        : [];

      const filteredSingletonAssessments = singletons.filter((item) => {
        return item._id !== assessmentId;
      });

      return argState.setIn(
        ['employer', 'singletonAssessments'],
        filteredSingletonAssessments
      );
    }
    case 'APOLLO_QUERY_RESULT': {
      const noUserRecordReturned = !action.result.data?.me;
      if (
        !['CurrentUser', 'NavBarQuery'].includes(action.operationName) ||
        noUserRecordReturned
      ) {
        break;
      }

      //NOTE: using apollo return rather than eve and apollo to avoid any inconsistency
      Logger.setUserContext(action.result.data.me.id); //set to user id (not seeing name e.g.)

      // Graphql returns id, but eve and the rest of the codebase is expecting _id
      // @ts-expect-error Immutable expects unknowns in mergeFn parameters
      return argState.mergeWith(mergeFn, {
        ...action.result.data.me,
        ...(action.result.data.me.freemiumRestrictions
          ? {
              freemiumRestrictions: {
                ...action.result.data.me.freemiumRestrictions,
              },
            }
          : { freemiumRestrictions: { isFreeUser: false } }),
        _id: action.result.data.me.id,
        ...(action.result.data.me.employer
          ? {
              employer: {
                ...action.result.data.me.employer,
                _id: action.result.data.me.employer.id,
              },
            }
          : { employer: null }),
      });
    }
    case 'APOLLO_MUTATION_RESULT': {
      const companyRecordReturned =
        action.result.data?.updateMyCompany?.company;
      if (
        !['UpdateMyCompany'].includes(action.operationName) ||
        !companyRecordReturned ||
        argState.employer?._id !== companyRecordReturned.id
      ) {
        break;
      }

      // Graphql returns id, but eve and the rest of the codebase is expecting _id
      // @ts-expect-error Immutable expects unknowns in mergeFn parameters
      return argState.mergeWith(mergeFn, {
        employer: {
          ...companyRecordReturned,
          _id: companyRecordReturned.id,
        },
      });
    }
    case 'subscribe': {
      // @ts-expect-error Immutable expects unknowns in mergeFn parameters
      return argState.mergeWith(mergeFn, action.response.user);
    }
    default:
      break;
  }

  return argState;
};
